import { render, staticRenderFns } from "./AddWormCompoundDialog.vue?vue&type=template&id=34fbc3a4&scoped=true&lang=pug&"
import script from "./AddWormCompoundDialog.vue?vue&type=script&lang=js&"
export * from "./AddWormCompoundDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddWormCompoundDialog.vue?vue&type=style&index=0&id=34fbc3a4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34fbc3a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAlert,VBtn,VCheckbox,VForm,VList,VListItem,VListItemGroup,VSkeletonLoader,VSpacer,VTreeview})
