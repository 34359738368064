<template lang="pug">
app-dialog( v-model='store.changeCommentDialog.show' :header='$t("module.drawing.addComment.placeholder")' )
  app-section
    app-textarea.mb-2( v-model="store.changeCommentDialog.comment.text" :label='$t("module.drawing.addComment.placeholder")' outlined hide-details)
    app-select( v-if='store.changeCommentDialog.comment.task' v-model='store.changeCommentDialog.comment.task' :items='tasks' item-text='title' :label='$t("module.drawing.task.chooseTask")' )
    .text-color-grey(v-else)
      .font-weight-regular.mb-2 {{$t("module.drawing.task.addToTask")}}
      .tasks-loading-panel( v-if='store.tasksIsLoading' )
        v-progress-circular(color='accent' indeterminate)
      app-select( v-else v-model='store.changeCommentDialog.comment.task' :items='tasks' item-text='title' :label='$t("module.drawing.task.chooseTask")' )
  template(v-slot.footer)
    .d-flex.align-center.mt-2
      v-btn( icon small @click='removeCommentAndHidePanel(store.changeCommentDialog.comment)')
        v-icon(color='#e5e5e5') mdi-delete-outline
      v-btn.ml-auto( color='accent' @click='changeComment(store.changeCommentDialog.comment)') {{ $t("button.save") }}
</template>

<script>
import { useDrawingsStore } from '@/pinia';
import { useDrawingCommentState } from '@/pinia';
import { mapState } from 'vuex'

export default {
  name: 'DrawingEditCommentDialog',

  computed: {
    ...mapState('task', ['tasks']),

    store: () => useDrawingsStore(),
    state: () => useDrawingCommentState()
  }, 

  methods: {
    removeCommentAndHidePanel(comment){
      this.state.removeComment(comment)
      this.store.changeCommentDialog.show = false
    }, 

    changeComment(comment) {
      this.store.editDrawingComment(comment).then(() => {
        this.store.changeCommentDialog.show = false
      })
    },
  },
}
</script>

<style scoped>
.text-color-grey{
  color: #666666;
}
</style>