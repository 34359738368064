<template lang='pug'>
app-panel.mr-2( ref='task-details-panel' ident='taskdetails' right )
  div.d-flex.flex-column.fill-height
    task-header.mb-2( :title="selectedTask.name" headerItemsCenter mainTitle )
      template( #headerBefore )
        app-icon-button.mr-1( icon="mdi-arrow-left" @click='closeTask()' )

        toolbar-menu(v-if="headerMenu.length > 0" basic :menu="headerMenu" :iconSize="15" )

      template( #headerAfter )
        task-status.mr-2( ref='task-status-menu' :task="selectedTask" useMenu test-id="task-status" )
        task-priority.mr-1( ref='task-priority-menu' :task="selectedTask" useMenu test-id="task-priority" )

    div.flex.overflow-hidden
      task-info.fill-height( :task='selectedTask' @click:subtask='setSelectedSubTask' )
  
  task-comment-attachment-dialog( v-model='showAttachmentDialog' :task='selectedTask' )
  set-task-responder-dialog( v-model='showResponderDialog' :task='selectedTask' )
  set-task-model-dialog( v-model='showModelDialog' :task='selectedTask' )
  workspace-select-dialog( v-model='showWorkspaceDialog' :workspace='projectSettings.workSpace' :header='$t("module.task.changeWorkspace")' @confirm='(workspace) => changeWorkspace({ uuid: selectedTask.uuid, value: workspace })')
  reassign-task-dialog( v-model='showReassignTaskDialog' :task='selectedTask')
  task-form-type-dialog( v-model='showTaskChangeTypeDialog' :selectedTask='selectedTask' )
  app-dialog-confirm( v-model="showTaskDeleteDialog" @confirm="deleteTask(selectedTask)" ) 
    app-text {{ $t('module.task.confirmDeleteTask', { name: selectedTask.title }) }} 
  app-dialog-confirm( v-model='showTaskChangeTypeConfirmDialog' @confirm='showTaskChangeTypeDialog = true' ) 
    app-text {{ $t('module.task.confirmChangeTaskType', { name: selectedTask.title }) }}

</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'

import TaskHeader from '@/components/task/TaskHeader.vue'
import TaskInfo from '@/components/task/TaskInfo.vue'
import ToolbarMenu from '@/components/project/panel/left/floors/ToolbarMenu.vue'
import TaskStatus from '@/components/task/TaskStatus.vue'
import TaskPriority from '@/components/task/TaskPriority.vue'
import TaskCommentAttachmentDialog from '@/components/task/taskDetailsSections/taskComments/TaskCommentAttachmentDialog.vue'
import SetTaskResponderDialog from '@/components/task/SetTaskResponderDialog.vue'
import SetTaskModelDialog from '@/components/task/SetTaskModelDialog.vue'
import WorkspaceSelectDialog from '@/components/task/WorkspaceSelectDialog.vue'
import ReassignTaskDialog from '@/components/task/ReassignTaskDialog.vue'
import { api } from '@/api'
import TaskFormTypeDialog from '@/components/task/taskDetailsSections/TaskFormTypeDialog.vue'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { TaskScreenshot } from '@/components/task/taskScreenshot'

import { panelNames } from '@/store/sections/LayoutRepository'

export default {
  name: "TaskDetailsPanel",

  components: {
    TaskHeader,
    TaskInfo,
    ToolbarMenu,
    TaskPriority,
    TaskStatus,
    TaskCommentAttachmentDialog,
    SetTaskResponderDialog,
    SetTaskModelDialog,
    WorkspaceSelectDialog,
    ReassignTaskDialog,
    TaskFormTypeDialog,
  },

  data() {
    return {
      showAttachmentDialog: false,
      showResponderDialog: false,
      showModelDialog: false,
      showWorkspaceDialog: false,
      showTaskDeleteDialog: false,
      showReassignTaskDialog: false,
      showTaskChangeTypeConfirmDialog: false,
      showTaskChangeTypeDialog: false,
    }
  },
  
  computed: {
    ...mapState('task', ['selectedTask', 'taskDisplay', 'subTasksOpen', 'taskDisplay', 'prevTaskPanel']),
    ...mapGetters('layoutControler', ['panelDataById']),
    ...mapState('layoutControler', ['fromCollision']),
    ...mapGetters('forms', ['forms']),
    ...mapState('project', ['project', 'projectSettings']),

    ...mapGetters('projectPermissions', ['hasTaskCreate', 'hasTaskDelete', 'hasTaskClosed', 'hasTaskStatusPriority', 'hasTaskResponder', 'hasTaskCreateCommentAttach', 'hasTaskDownloadFile']),

    headerMenu() {
      let menu = []

      if (this.hasTaskStatusPriority) {
        menu.push(
          { title: this.$t('module.task.changeStatus'), action: this.showStatusMenu },
          { title: this.$t('module.task.changePriority'), action: this.showPriorityMenu }
        )
      } else if (this.hasTaskClosed) {
        menu.push(
          { title: this.$t('module.task.changeStatus'), action: this.showStatusMenu }
        )
      }

      // if (this.hasTaskResponder) {
      //   menu.push({
      //     title: this.$t('module.task.assignResponder'), action: () => this.showResponderDialog = true
      //   })
      // }

      if (this.hasTaskCreateCommentAttach) {
        menu.push(
          { title: this.$t('module.task.add3D'), action: this.takeScreenshotToTask },
        )
      }

      if (this.hasTaskDownloadFile) {
        menu.push(
          { title: this.$t('module.task.addAttachment'), action: () => this.showAttachmentDialog = true },
        )
      }

      if (this.hasTaskCreate) {
        menu.push(
          // { title: this.$t('module.task.addCollision'), action: this.dev, disabled: true },
          { title: this.$t('module.task.addModel'), action: () => this.showModelDialog = true },
          { title: this.$t('module.task.changeWorkspace'), action: () => this.showWorkspaceDialog = true },
          { title: this.$t('module.task.reassignTask'), action: () => this.showReassignTaskDialog = true }
        )
        if (this.selectedTask.taskType && this.selectedTask.taskType.form) {
          menu.push(
            { title: this.$t('module.task.changeTaskType'), action: () => this.showTaskChangeTypeConfirmDialog = true }
          )
        }
        else if (this.forms.filter(form => form.formType?.name === "TASK").length) {
          menu.push(
            { title: this.$t('module.task.specifyTaskType'), action: () => this.showTaskChangeTypeDialog = true  }
          )
        }
      }


      if (this.hasTaskDelete) {
        menu.push(
          { title: this.$t('module.task.deleteTask'), action: () => this.showTaskDeleteDialog = true }
        )
      }
      
      return menu
    },
  },
  
  mounted() {
    const taskDetailsPanel = this.$refs['task-details-panel']
    const taskPanel = this.panelDataById('tasks')

    const panelWidth = taskPanel?.width ?? window.innerWidth / 6

    taskDetailsPanel.setSize(panelWidth)
  },

  beforeDestroy() {
    TaskBimAnnotations.showTaskBimAnnotations()

    if (this.taskDisplay === 'tree' || this.taskDisplay === 'diagram') {
      TaskBimAnnotations.noSelectedTaskBimAnnotation()
    }
    this.setFromCollision(false)
    this.closeTask()
  },

  methods: {
    ...mapMutations('task', ['setSelectedTask', 'delSubTaskOpen', 'addSubTaskOpen', 'setSelectedTaskByUuid', 'delAllSubTaskOpen', 'setTaskDisplay', 'setDashboardWidth']),
    ...mapMutations('project', ['setTopUnit']),
    ...mapActions('task', ['changeWorkspace', 'deleteTask']),
    ...mapMutations('layoutControler', ['setFromCollision', 'setWidthData']),

    closeTask() {
      if (this.subTasksOpen.length > 1) {
        this.delSubTaskOpen()
        this.setSelectedTaskByUuid(this.subTasksOpen[this.subTasksOpen.length - 1])
        TaskBimAnnotations.showTaskBimAnnotations()
      }

      else {
        TaskBimAnnotations.noSelectedTaskBimAnnotation()
        this.delSubTaskOpen()
        this.setSelectedTask(null)
      }
      
      if (this.fromCollision) {
        this.setTaskDisplay(this.prevTaskPanel.panelName)
        this.setWidthData({ id: panelNames.TASKS, width: this.prevTaskPanel.width })
      }
    },

    showStatusMenu(event) {
      const statusMenu = this.$refs['task-status-menu']
      statusMenu.showStatusMenu(event)
    },

    showPriorityMenu(event) {
      const priorityMenu = this.$refs['task-priority-menu']
      priorityMenu.showPriorityMenu(event)
    },

    // TODO МЕДЛЕННО
    async takeScreenshotToTask() {
      await TaskScreenshot.showCreateTaskScreenshotDialog({
        taskUuid: this.selectedTask.uuid,
        screenshotComment: this.comment
      })
    },
    
    setSelectedSubTask(task) {
      this.addSubTaskOpen(task.uuid)
      this.setSelectedTask(task)
      TaskBimAnnotations.showTaskBimAnnotations()
    },
    
    loadTaskType(task) {
      if (task.uuid && task.taskType && !task.taskType.uuid) {
        api.forms.dataList(task.uuid).then(data => {
          if(data.length > 0){
            this.$store.commit('task/setSelectedTaskType', {taskType: data[data.length-1]})
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>