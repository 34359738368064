<template lang='pug'>
   div
      h2( style="color: #505254" ) {{ $t("statistics.datTable") }}
      v-data-table( 
        light
        :headers="headersDatTable" 
        style="min-heigth: 48; max-heigth: 288" 
        :items="preparedMembers" 
        :items-per-page="paginationItems.itemsPerPage" )
          div( v-for='mem in members' ) {{ mem }}
</template>

<script>
import { mapGetters} from 'vuex';

export default {
   name: 'MembersTable',

   data() {
      return {
        paginationItems: {
        page: 1,
        pageCount: 0,
        pageCountList: [
          {text: "5", value: 5}, {text: "10", value: 10}, {text: "15", value: 15}, {text: "Все", value: -1}
        ],
        itemsPerPage: 5,
        },
      }
   },

   computed: {
      ...mapGetters("project", ["projectShortcut"]),

    members() {
      return this.projectShortcut.membership
    },
 
    headersDatTable(){
      return [
        { text: this.$t("statistics.datTableHeader.userName"), value: 'name', width: '20%'},
        { text: this.$t("statistics.datTableHeader.role"), value: 'roles', width: '30%'},
        { text: this.$t("statistics.datTableHeader.orgRoles"), value: 'orgRoles', width: '30%' },
        { text: this.$t("statistics.datTableHeader.dateVisit"), value: 'lastVizited', width: '20%'},
      ]
    },
 
    preparedMembers() {
      const preparedMembers =  this.members.map(mem => {
        return {
          name: mem.profile.name || mem.orgGroup.title,
          lastVizited: mem.lastVizited ? mem.lastVizited.slice(8, 10) + '.' + mem.lastVizited.slice(5, 7) + '.' + mem.lastVizited.slice(0, 4) +
          " " + mem.lastVizited.slice(11, 16) + ':00' : '-', 
          roles: mem.memberReference.map(memRef => memRef.roles.map(role => role.title)).flat().join(', ') || '-',
          orgRoles: mem.memberReference.map(memRef => memRef.orgRoles.map(role => role.title)).flat().join(', ') || '-'
        }
      })
      return preparedMembers
    } 
  }
   
}
</script>

<style>

</style>