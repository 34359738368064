<template lang='pug'>
div.tasks-flat-list.fill-height.base-scroll-body(:class="{'no-tasks' : !uniqTasks.length}")
  task-card.mr-1( v-for='(task, index) in uniqTasks' :task='task' :key='task.uuid' :class='{"mb-2": index < tasks.length - 1}' @click.native='handleSelect(task)' )
  span( v-if="!uniqTasks.length" ) {{ $t("module.task.emptyList") }}
</template>

<script>
import { mapState } from 'vuex'
import TaskCard from '@/components/project/common/TaskCard.vue'
import _ from 'lodash'
import { DateTransform, SortEnum } from '@/assets/utils/dateTransform.js'

export default {
  name: 'TasksFlatList',

  components: {
    TaskCard,
  },

  props: {
    tasks: Array,
    selectedTaskUuid: String, 
  },

  computed: {
    ...mapState('task', ['sortDes', 'sortAsc']),

    // FIXME На обсуждение фильтра
    uniqTasks() {
      const uniqTasks = _.uniqBy(this.tasks, task => task.uuid)

      if(this.sortDes) {
        return DateTransform.sortTaskDate(uniqTasks, SortEnum.DESC)
      }
      else if(this.sortAsc) {
        return DateTransform.sortTaskDate(uniqTasks, SortEnum.ASC)
      }
      else {
        return uniqTasks  
      }
    },
  },

  methods: {
    handleSelect(task) {
      this.$emit('select', task)
    },
  }
}
</script>

<style scoped>
  .overflow-auto {
    overflow-x: hidden !important;
  }
  .no-tasks {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .base-scroll-body {
    overflow-x: hidden;
  }
</style>