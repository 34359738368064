<template lang="pug">
  .selected-task.mb-3(v-if='selectedTask')
    task-info-section.mt-1(:selectedTask="selectedTask" @progress='dialog.progress = true')
    task-smeta-section.section-selection.mt-2(:task="selectedTask")
    task-sub-tasks-section.section-selection.mt-1(:selectedTask="selectedTask")
    task-appointed-section.section-selection.mt-1(:selectedTask="selectedTask")
    task-models-section.section-selection.mt-1(:selectedTask="selectedTask")
    task-collisions-section.section-selection.mt-1(:selectedTask="selectedTask")
    task-attachments-section.section-selection.mt-1(:selectedTask="selectedTask")
    task-comments-section.section-selection.mt-1(:selectedTask="selectedTask")
    task-activity-section.section-selection.mt-1(:selectedTask="selectedTask")


    .t-taskComment.text-right.pa-2.mt-auto
      .t-taskComment-input
        task-comment-markdown-textarea(
          ref="taskComment"
          v-model="taskComment" :label="$t('module.task.writeMessage')" background-color="#FFFFFF" color="#6B6B6B" 
          rows="2"
          hide-details light solo flat dense onlyEditor
          @click="createComment" notBlur focusIcoButtons
        )
          template(v-slot:focusIcoButtons)
            task-button-action.ml-1(icon="project/tools/add_a_photo.svg" size=16 @click="takeScreenshotToTask")
            task-button-action.ml-1(icon="project/tools/attach_file.svg" size=16 )

    app-dialog( v-model="dialog.attachment" :header="$t('module.task.addAttachment')" @confirm="prepareAttachment" :confirmText="$t('button.add')" )
      app-section 
        app-file-input( v-model="attachment.file" :max-size="10000000" :formats="[]" @input="updateFileName"  )
        app-textarea( v-model="taskComment" :label="$t('module.task.taskComment')" )
</template>

<script>
import TaskSubTasksSection from '@/components/task/taskDetailsSections/TaskSubTasksSection'
import TaskInfoSection from '@/components/task/taskDetailsSections/TaskInfoSection'
import TaskSmetaSection from '@/components/task/taskDetailsSections/TaskSmetaSection'
import TaskAppointedSection from '@/components/task/taskDetailsSections/TaskAppointedSection'
import TaskModelsSection from '@/components/task/taskDetailsSections/TaskModelsSection'
import TaskCollisionsSection from '@/components/task/taskDetailsSections/TaskCollisionsSection'
import TaskAttachmentsSection from '@/components/task/taskDetailsSections/TaskAttachmentsSection'
import TaskCommentsSection from '@/components/task/taskDetailsSections/taskComments/TaskCommentsSection'
import TaskActivitySection from '@/components/task/taskDetailsSections/TaskActivitySection'
import { mapState } from 'vuex'
import { TaskScreenshot } from '../taskScreenshot'
import TaskCommentMarkdownTextarea from '@/components/task/taskDetailsSections/taskComments/TaskCommentMarkdownTextarea.vue';
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  name: "TaskTreeTaskDetails",
  components: {
    TaskSubTasksSection,
    TaskInfoSection,
    TaskAppointedSection,
    TaskModelsSection,
    TaskCollisionsSection,
    TaskAttachmentsSection,
    TaskCommentsSection,
    TaskActivitySection,
    TaskSmetaSection,
    TaskCommentMarkdownTextarea,
    TaskButtonAction
  },
  data() {
    return {
      taskComment: "",
      dialog: {
        attachment: false
      },
      attachment: {
        file: null,
        name: null
      }
    }
  },
  computed: {
    ...mapState('task', ['selectedTask'])
  },
  methods: {
    createComment () {
      this.addComment({ uuid: this.selectedTask.uuid, text: this.taskComment })
      this.taskComment = ''
      this.isNewComment = true
    },

    async takeScreenshotToTask() {
      await TaskScreenshot.showCreateTaskScreenshotDialog({
        taskUuid: this.selectedTask.uuid,
        screenshotComment: this.comment
      })
    },
    
    showAttachmentDialog () {
      this.dialog.attachment = true
    },

    prepareAttachment () {
      const { name, file } = this.attachment
      this.addAttachment({ uuid: this.selectedTask.uuid, name: name || file.name, file })
        .then( () => {
          if (this.taskComment.trim()) this.createComment()
        })
      this.attachment.name = ''
      this.attachment.file = null
    },

    updateFileName (file) {
      this.attachment.name = file.name
    },
  }
}
</script>

<style lang="scss" scoped>
  .selected-task{
    flex: 1;
    background: #e5e5e5;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
  }
  .t-taskComment {
    background: #E5E5E5;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 3px 3px;
  }
  .t-taskComment-input{
    background: #FFFFFF;
    border: 1px solid #757575;
    box-sizing: border-box;
    border-radius: 3px;
  }
</style>