<template lang='pug'>
app-dialog(v-model='show' :header="$t('module.mnemoschemas.receivers.dataSource.creation')")
    div.d-flex.flex-column(v-if='localSchema')
      app-section
        app-text-field(:label="$t('module.mnemoschemas.receivers.dataSource.name')" outlined hide-details)
        app-select.mt-2(:label="$t('module.mnemoschemas.receivers.receiverProtocol')" outlined)
        //- app-textarea.mt-2(:label="$t('module.mnemoschemas.receivers.receiverOptions')" height=100 outlined)
        v-textarea.mt-2(  :label="$t('module.mnemoschemas.receivers.receiverOptions')" outlined light )
        app-select.selector(clearable :label="$t('module.mnemoschemas.receivers.dataConverter')"  outlined item-text="name" item-value="uuid" )
      v-btn.ml-auto.mt-2(:disabled='!localSchema.title' color='accent' elevation='0' @click='save()') {{ $t('button.save') }}
</template>

<script>

const defaultSchema = {
  title: null,
  project: null,
  schema: null
}

export default {
  name: "SaveDataSourceDialog",
  
  props: {
    value: Boolean,
    schema: Object
  },

  data() {
    return {
      localSchema: null,
      listDatasource: []
    }
    
  },

  computed: {

    show: {
      get() {
        return this.value
      },
      
      set() {
        this.close()
      }
    }
  },

  beforeUpdate() {
    if (!this.initialized) {
      this.init()
    }
    
    this.initialized = this.show
  },

  methods: {

    save() {
      this.close()
    },

    close() {
      this.$emit('input', false)
    },

    init() {
      this.initialized = true
      this.localSchema = this.schema ? { ...this.schema } : { ...defaultSchema, project: this.projectUuid }
    }
  }
}
</script>

<style>

</style>
<style scoped>
  .selector{
    margin-top: -20px;
  }
</style>