<template lang="pug">
app-panel.px-1.py-1(ident='passport')
  app-panel-header.my-1( :title="$t('toolbarMenu.passport')" showClose )

  app-section

    .edit-project__key {{ $t('project.form.key') }}
    .edit-project__key-value.mt-3( v-line-clamp="1" ) {{ projectShortcut.alias }}
    
    v-card.my-3.bg-white
      v-img.image.ma-0.pa-0( :src="projectPreview" height="160" )
      v-btn.edit( min-width="24" :disabled="!meOwner" @click="$refs.localfile.click()" color="var(--v-primary-darken1)" small text )
        v-icon( small ) edit
      input( id="upload-preview" type="file" style="display: none" ref="localfile" :disabled="!meOwner" :accept="['.png', '.jpeg', '.jpg']" @input="preparePreview" )

    v-tabs.tabs(v-model="tabs" style="display: none" grow color="transparent" height="35")
      v-tab.tab__item.text-none(style="display: none; background: white")
      //- v-tab.mx-2.tab__item.text-none {{ $t('project.form.tabs.coords') }}
      //- v-tab.tab__item.text-none {{ $t('project.form.tabs.membership') }}
      //- v-tab.tab__item.text-none {{ $t('project.form.tabs.tokens') }}
    
    v-tabs-items.tabs__items(v-model="tabs" light)
        
      //- Project
      v-tab-item
        app-text-field.mt-4( v-model.trim="shortcut.title" :disabled="!meOwner || !isProjectLoaded" :label="$t('project.form.name')"  test-id="passport-input-title"  )
        v-chip-group( v-model="shortcut.color" mandatory column) 
            //- v-chip( :value="null" color="primary lighten-3" filter small ) без метки
            span.primary--text.text-caption.mt-1.mr-2 {{ $t('project.form.noMark') }}
            v-chip( :value="null" color="primary lighten-3" :disabled="!meOwner || !isProjectLoaded" :label="shortcut.color == 0" filter small)
            v-spacer
            span.primary--text.text-caption.mt-1.mr-2 {{ $t('project.form.mark') }}
            v-chip( v-for="c in projectColors" :key="c" :value="c" :color="c" :disabled="!meOwner || !isProjectLoaded" :label="shortcut.color === c" filter small test-id="passport-mark" )
         
        app-textarea( v-model.trim="shortcut.description" :disabled="!meOwner || !isProjectLoaded" test-id="passport-input-description" :label="$t('project.form.description')" outlined)

        app-select.select(
            :disabled="!meOwner || !isProjectLoaded"
            v-if="isMyOrgsLoaded"
            v-model="shortcut.org" 
            :items="orgList"
            item-value="uuid"
            item-text="title" 
            :label="$t('project.form.organization')"
          )

    .d-flex.mt-4
      .align-content-start
        v-btn.text-none.edit-project__footer-button(color='error' :disabled="!meOwner || !isProjectLoaded" @click='cancelEditting()' light) {{ $t('button.cancel') }}
      v-spacer
      .align-content-end
        v-btn.text-none.edit-project__footer-button( color="accent" :disabled="!shortcut.title || !meOwner" @click="saveChanges()" light test-id="save-passport") {{ $t('button.save') }}      

</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import { SourcePath } from '@app/SourcePath'
import { App } from '@app/App'
import { projectService } from '@/_services'
export default {
  name: "Passport",
  components: {
    SourcePath
  },

  data() {
    return {
      shortcut: {},

      tabs: null,
      preview: '',
      updatePassport: null,
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapGetters("project", ["projectShortcut", "isProjectLoaded"]),
    ...mapGetters("corp", ["myOrgs", "isMyOrgsLoaded"]),
    ...mapGetters("authUser", ["myUUID"]),

    meOwner() {
      return this.shortcut.membership.length > 0 ? this.shortcut.membership.find((member) => member.profile.uuid == this.myUUID)?.owner : true
    },

    projectPreview() {
      return this.shortcut.preview ? SourcePath.preview(this.shortcut.preview) : "/img/common/bim_default_preview_2.jpeg"
    },

    projectColors() {
      return App.assets.colors
    },

    archiveOrgName(){
      return this.project.organization?.title + this.$t("organization.archived")
    },

    orgList(){
      if(this.project.organization && !this.myOrgs.some(el => el.uuid == this.project.organization.uuid)){
        let organisationsList = [...this.myOrgs]
        let projectOrganization = {...this.project.organization}
        if(projectOrganization.archived){
          projectOrganization.title = this.archiveOrgName
          projectOrganization.disabled = true
        }
        organisationsList.push(projectOrganization)
        return organisationsList
      }
      return this.myOrgs
    }

  },

  beforeMount() {
    this.shortcut = Object.assign({}, this.projectShortcut)
    this.$store.dispatch('corp/LOAD_MY_ORGS')
  },

  updated() {
    if (this.projectShortcut.title && !this.updatePassport) {
      this.shortcut = { ...this.projectShortcut };
      this.updatePassport = true;
    }
  },

  methods: {
    ...mapActions('project', ['setProjectPreview', 'setProjectOrg', 'postPanelsStatus']),
    ...mapMutations('project', ['setActiveGlobalTab', 'setProjectTitle', 'setProjectDescription', 'setProjectColor', 'setProjectOrgObj', 'changeProjectPreview']),
    ...mapActions('tokens', ['LOAD_TOKENS', 'deleteToken']),

    async preparePreview() {
      var imagefile = document.getElementById('upload-preview')
      if (imagefile.files[0] == null) return
      await this.setProjectPreview({ uuid: this.shortcut.uuid, file: imagefile.files[0] }).then(preview => {
        this.shortcut.preview = preview
      })
    },

    saveChanges() {
      projectService.editProject(this.shortcut).then(() => {
        this.setProjectTitle(this.shortcut.title)
        this.setProjectDescription(this.shortcut.description)
        this.setProjectColor(this.shortcut.color)
        this.setProjectOrg(this.shortcut.org)

        if (this.shortcut.org) {
          let org = this.myOrgs.find(e => e.uuid == this.shortcut.org)
          let pOrg = { uuid: org.uuid, title: org.title }
          this.setProjectOrgObj(pOrg)
        }

        this.changeProjectPreview(this.shortcut.preview)
      })
      this.setActiveGlobalTab("")
      this.postPanelsStatus()
    },

    cancelEditting() {
      this.setActiveGlobalTab("")
      this.postPanelsStatus()
    }

  }
}
</script>
<style scoped>
.edit-project__key {
  font-size: 14px;
  line-height: 18px;
  color: var(--v-primary-base);
}
.edit-project__key-value {
  font-size: 18px;
  line-height: 18px;
  color: #1B93AD;
}

.bg-white {
  background-color: white;
}

::v-deep .select .v-select__selection--disabled {
  color: red
}
</style>