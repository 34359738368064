<template lang='pug'>
  app-dialog(v-model='show' :header='$t(headerTitle)')
    div.d-flex.flex-column
      app-section.d-flex.flex-column(v-if='localChannel')
        v-progress-linear(v-if='isLoading' indeterminate color='accent')
        div.mb-1(v-else)

        app-text-field.mt-2(v-model='localChannel.title' :label='$t("module.mnemoschemas.receivers.channel.channelTitle")' outlined hide-details)
        v-textarea.mt-2(:label='$t("module.mnemoschemas.receivers.channel.channelNote")' outlined light)
        app-select.mt-2( v-model="localChannel.convertType" :items='convertTypeList' item-text='title' item-value='value' :label="$t('module.mnemoschemas.receivers.dataConverter')"  outlined return-object)
        app-select.mt-2( v-model="localChannel.roundType" :items='roundTypeList' item-text='title' item-value='value' :label='$t("module.mnemoschemas.receivers.channel.typeRounding")'  outlined return-object)
        app-select.mt-2( v-model="localChannel.roundCount" :items='decimalPlacesList' item-text='title' item-value='value' :label='$t("module.mnemoschemas.receivers.channel.roundUpTo")'  outlined)
        //- app-text-field.mt-2( v-model.number="localChannel.roundCount" type="number" :label='$t("module.mnemoschemas.receivers.channel.roundUpTo")' outlined )
        app-select.mt-2(v-model='localChannel.equipment' :label='$t("module.mnemoschemas.receivers.channel.equipment")' :items='preparedTreeFlatList' item-text='title' item-value='value' outlined)
        
      app-button.mt-2.ml-auto(:disabled='!isChannelValid' color='accent' @click='save()') {{ $t('button.save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

const defaultChannel = {
  title: null,
  receiver: null,
  equipment: null,
  roundCount: null,
  roundType: 0,
}

export default {
  name: "CreateChannelDialog",

  props: {
    value: Boolean,
    channel: Object,
  },

  data: () => ({
    localChannel: null,
    isLoading: false,
    decimalPlacesList: [
      {title: '0', value: 0},
      {title: '1', value: 1},
      {title: '2', value: 2},
      {title: '3', value: 3},
      {title: '4', value: 4},
      {title: '5', value: 5},
      {title: '6', value: 6},
    ]
  }),

  computed: {
    ...mapGetters('maintenance', ['treeFlatList']),
    ...mapState('receiver', ['roundTypeList', 'convertTypeList']),

    show: {
      get() {
        this.value && this.init()
        return this.value
      },

      set() {
        this.close()
      }
    },
    
    headerTitle() {
      return this.localChannel?.uuid ? 'module.mnemoschemas.receivers.channel.channelEditing' : 'module.mnemoschemas.receivers.channel.channelCreating'
    },

    isChannelValid() {
      return !!(this.localChannel?.receiver && this.localChannel?.title)
    },

    // Только элементы, не являющиеся группами
    preparedTreeFlatList() {
      return this.treeFlatList.filter(item => !item.isGroup).map(item => ({title: item.name, value: item.uuid}))
    }
  },

  methods: {
    ...mapActions('receiver', ['createChannel', 'loadReceivers']),

    close() {
      this.$emit('input', false)
    },

    init() {
      this.localChannel = this.channel ? {...this.channel} : {...defaultChannel}
    },

    async save() {
      this.isLoading = true
      await this.createChannel(this.localChannel)
      this.isLoading = false
      
      if (this.localChannel.equipment) {
        this.$root.$emit('updateEquipmentChannels', this.localChannel.equipment)
      }

      this.close()
      this.loadReceivers()
    }
  }
}
</script>

<style>

</style>