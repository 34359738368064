<template lang="pug">
  app-dialog( v-model="visible" :header="$t('section.collision.settings')" )
    app-section
      draggable( v-model="settingsColumns" handle=".table-settings-item_handle" )
        .table-settings-item( v-for="h in settingsColumns" :key="h.value" )
          v-checkbox( v-model="selectedHeaders" :label="$t('section.collision.table.headers.' + h.value)" :value="h.value" color="#3B93AF" light dense hide-details )
          v-icon.table-settings-item_handle( light dense ) dehaze
    app-section.mt-2(v-if="showSettings")
      v-switch( v-model="loadElement.isNeedWait" label="Ждать загрузки элемента" color="accent" dense light )
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  
  data () {
    return {
      visible: false,
    }
  },

  computed: {
    ...mapGetters('collision/table', ['sortedHeaders', 'visibleHeaders']),
    ...mapState('collision/search', ['loadElement']),

    selectedTableParameters() {
      return this.sortedHeaders.some(element => element.view === true);
    },

    settingsColumns: {
      get () {
        return this.sortedHeaders
      },
      set (val) {
        this.reorderColumns(val)
      }
    },

    selectedHeaders: {
      get () {
        return this.visibleHeaders.map(el => el.value)
      },
      set (val) {
        this.selectColumns(val)
      }
    },

    showSettings () {
      return window.settings.showSettingsWaitCollisionLoad || false
    },
  },
  
  created() {
    this.SET_COLLISION_TABLE_VISIBILITY(this.selectedTableParameters)
  },

  watch: {
    selectedTableParameters() {
      this.SET_COLLISION_TABLE_VISIBILITY(this.selectedTableParameters)
    }
  },

  methods: {
    ...mapActions('collision/table', ['selectColumns', 'reorderColumns']),
    ...mapMutations('collision/table', ['SET_COLLISION_TABLE_VISIBILITY']),

    show () {
      this.visible = true
    }
  }
}
</script>

<style lang="scss">
.table-settings-item .v-input--selection-controls {
  margin: 0;
}
.table-settings-item {
  display: flex;
  justify-content: space-between;
  color: var(--v-primary-base);
}
.table-settings-item_handle {
  cursor: pointer;
}
</style>