<template lang="pug">
div.caption(:class='{ "pointer": $listeners.click }' test-id="task-dates" @click='$emit("click")') {{ dates }}
</template>

<script>
export default {
  name: "TaskDates",

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  computed: { 
    dates() {
      const start = this.task?.startDate && new Date(this.task.startDate.replace(" ", "T")).toLocaleDateString(this.$i18n.locale)
      const end = this.task?.endDate && new Date(this.task.endDate.replace(" ", "T")).toLocaleDateString(this.$i18n.locale)

      return this.$t('module.task.time.interval', { start, end })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>