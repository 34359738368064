<template lang='pug'>
  div.task-tree-row-date
    div.pointer(@click='showInterval($event)') {{ start ? task.startDate : task.endDate }}
    
    task-date-interval(ref="task-date-interval" :task="task")
</template>

<script>
import { Task } from '@/assets/model/task/Task'
import TaskDateInterval from '@/components/task/TaskDateInterval'

export default {
  name: "TaskTreeRowDate",

  components: { TaskDateInterval },

  props: {
    task: Task,
    start: Boolean
  },

  methods: {
    showInterval() {
      this.$refs['task-date-interval'].showTaskDateInterval()
    }
  }
}
</script>

<style lang='scss' scoped>
  .task-tree-row-date {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
</style>