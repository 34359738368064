<template lang="pug">
div
  expansion-button( v-if="elements.length" :buttonHeader="$t('section.structure.model.suspicious.suspiciousElements')" )
    div( v-for="element in elements")
      suspect-elements-table-item( :element="element" :chosenElementUuid="chosenElementUuid" @change="handleChange")
</template>
  
<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { mapActions, mapState } from 'vuex';
import ExpansionButton from '../components/ExpansionButton.vue';
import SuspectElementsTableItem from './SuspectElementsTableItem.vue';
import { AlertService } from '@/assets/app/AlertService'

export default {
  data() {
    return {
      chosenElementUuid: null
    }
  },

  mounted() {
    if (this.isPatch) this.loadSuspectElements([this.selectedPatch.uuid])
    else this.loadSuspectElements([this.selectedModel.revision.uuid])
  },

  components: {
    ExpansionButton,
    SuspectElementsTableItem
  },

  props: {
    isPatch: Boolean
  },

  computed: {
    ...mapState('project', ['suspectElements', 'project', 'selectedModel']),
    ...mapState('patches', ['selectedPatch']),

    elements() {
      let values = null
      if (this.isPatch) {
        values = this.suspectElements?.map(revision => {
          if(revision.revUuid == this.selectedPatch.uuid) return revision.elements
          else return []
        })
      }
      else {
        values = this.suspectElements?.map(revision => {
          if (revision.revUuid == this.selectedModel.revision.uuid) return revision.elements
          else return []
        })
      }
      if (values.length == 0) return []
      return values?.reduce((a, v) => a.concat(v)) || []
    }
  },

  methods: {        
    ...mapActions('project', ['loadSuspectElements']),
    
    handleChange(globalId) {
      if (this.isThisRevisionInactive()) {
        AlertService.info({info: this.$t('section.structure.model.suspicious.turnOnModel')})
        return
      }
      if(this.chosenElementUuid != globalId) {
        this.chosenElementUuid = globalId
      }
      // Вывод сведений об элементе - пока не нужен.

      //   let projectUuid = this.project.uuid
      //   projectService.loadElementByGlobal(globalId, projectUuid).then(data => {  
      //     let elementInfo = this.createDetails(data)
      //     this.$emit('chosenSuspectElement', elementInfo)
      //   })
      // }
      else {
        this.chosenElementUuid = null
        // this.$emit('chosenSuspectElement', null)
      }
      let sceneObject = XeokitMediator.viewer.scene.objects[this.chosenElementUuid]
      XeokitMediator.ElementsSelection.pickElement(this.chosenElementUuid)
      // console.log(sceneObject)
      // console.log(sceneObject.aabb)
      // let aabb = sceneObject.aabb
      // if (aabb[3] === aabb[0] && aabb[4] === aabb[1] && aabb[5] === aabb[2]) {
      //   XeokitMediator.viewer.cameraFlight.flyTo({aabb: [
      //     sceneObject.aabb[0] - 0.5, 
      //     sceneObject.aabb[0] - 0.5, 
      //     sceneObject.aabb[0] - 0.5, 
      //     sceneObject.aabb[0] + 0.5, 
      //     sceneObject.aabb[0] + 0.5, 
      //     sceneObject.aabb[0] + 0.5
      //   ]})
      //   sceneObject.color = [1, 0, 0]
      // }
      // else {
      //  XeokitMediator.viewer.cameraFlight.flyTo({aabb: sceneObject.aabb})
      // }
      
      XeokitMediator.CameraFlight.flyTo(sceneObject)
    },

    // createDetails(elementInfo) { // TODO: Взято из axis-tree.module.js, SET_ELEMENT - имеет смысл вынести выше, на этап запроса элемента,
    //   const geometryAttr = {     // так как свойства элемента могут быть нужны и вне контекста дерева элементов. Нужно обсудить.
    //     TOTAL_SURFACE_AREA: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c201", notCreate: false },
    //     TOTAL_SHAPE_VOLUME: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c202", notCreate: false },
    //     SURFACE_AREA_ALONG_X: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c204", notCreate: false },
    //     SURFACE_AREA_ALONG_Y: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c205", notCreate: false },
    //     SURFACE_AREA_ALONG_Z: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c206", notCreate: false },
    //     BOUNDING_BOX_SIZE_ALONG_X: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c207", notCreate: false },
    //     BOUNDING_BOX_SIZE_ALONG_Y: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c208", notCreate: false },
    //     BOUNDING_BOX_SIZE_ALONG_Z: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c209", notCreate: false },
    //     LARGEST_FACE_DIRECTION: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c203", notCreate: true },
    //     LARGEST_FACE_AREA: { uuid: "c1a6881f-4698-40dc-bcce-54ad1c98c210", notCreate: true }
    //   }

    //   if (elementInfo) {
    //     if (elementInfo.geometryInfo && elementInfo.geometryInfo.additionalData) {
    //       let json_data = JSON.parse(elementInfo.geometryInfo.additionalData)
    //       elementInfo.geometry = Object.keys(json_data).map(key => ({
    //         "name": key,
    //         "operand": "GEOMETRY",
    //         "uuid" : geometryAttr[key]?.uuid,
    //         "topLevel": true,
    //         "notCreateGroup": geometryAttr[key]?.notCreate,
    //         "doubleValue": json_data[key], 
    //         "stringValue": "" + json_data[key], 
    //         "unit": key.indexOf("_AREA") > 0 ? "{unit.shortName.square_metre}" : key.indexOf("_SIZE") > 0 ? "{unit.shortName.metre}" : key.indexOf("_VOLUME") > 0 ?  "{unit.shortName.cubic_metre}" : ""
    //       }))
    //     }
    //     elementInfo.details = elementInfo.children.sort((a, b) => (a.children.length > 0 ? 1 : 0) - (b.children.length > 0 ? 1 : 0) || a.name.localeCompare(b.name))
    //     elementInfo.details.forEach(el => { if(el.children.length > 0) el.children.sort((a, b) => a.name.trim().localeCompare(b.name.trim())) })
    //   }
      
    //   return elementInfo
    // },
    isThisRevisionInactive() {
      let activeRevisions = XeokitMediator.getCurrentRevisions()

      let selectedRevisionUuid = null
      if (this.isPatch) selectedRevisionUuid = this.selectedPatch.uuid 
      else selectedRevisionUuid = this.selectedModel.revision.uuid

      for (let i = 0; i < activeRevisions.length; i++) {
        if (activeRevisions[i].uuid == selectedRevisionUuid) return false
      }

      return true
    }
  }
}
</script>
  
<style scoped>

</style>