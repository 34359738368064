<template lang="pug">
  app-dialog( v-model="$_show" width="900" @close='clear()' complex )

    v-row( no-gutters )
      v-col( :cols="2" )
        app-panel-section( :title="$t('section.elementTree.selectingParam')" )
          condition-parameter( :condAxisEnum='condAxisEnum' @selectOperand="selectOperand($event)" )
      
      v-col( :cols="10" style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.selectingClassAttr')" )
          condition-class-attribute(
            ref='conditon-class-attribute'
            :conditionTreeCache="conditionTreeCache"
            @selectAttr='selectAttribute($event)'
          )

    v-row.white( no-gutters style="margin-top: 1px;" )
        v-col.text-right
           app-button.mt-3.mb-2.mr-2( action @click="select" :disabled="!valid" ) OK

</template>

<script>
import ConditionParameter from '@/components/project/panel/left/components/logicGroup/conditionSections/ConditionParameter'
import ConditionClassAttribute from '@/components/project/panel/left/components/logicGroup/conditionSections/ConditionClassAttribute'
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'

import DialogToggable from '@/components/app/DialogToggable'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { projectService } from '@/_services'

export default {
  name: 'SelectAttribute',
  
  mixins: [DialogToggable],

  components: { 
    ConditionParameter, 
    ConditionClassAttribute 
  },

  data () {
    return {
      conditionTreeCache: new ConditionTreeCache(),
      attr: null,
      currentDelimeter: "_", 
    }
  },

  watch: {
    $_show (value) {
      if (value) {
        this.setForm({...this.defaultCondition}) // fix одна ссылка с logic-group-calc
        this.selectOperand(this.condAxisEnum[0])
      }
    },
  },

  computed: {
    ...mapGetters('project', ['axisEnum', 'isAxisEnumLoaded', 'projectUuid', 'hashProject']),
    ...mapState('condition', ['form', 'defaultCondition']),

    valid() {
      return this.attr!=null
    },

    condAxisEnum() {
      return this.axisEnum.filter(a => a.name=="ATTRIBUTE" || a.name=="USERPROP" || a.name=="MASK" )
    },

  },
  methods: {
    ...mapMutations('condition', ['setLoaded', 'setCurrentType', 'setSearch', 'setForm']),

    select() {
      this.$emit('selected', this.attr)
      this.clear()
    },

    selectOperand(param) {
      this.setLoaded(false)
      this.setSearch('')

      this.form.value = null
      this.form.operandUuid = null
      this.form.operand = param
      
      this.attr = null

      let currentDelimeter = this.form.operand.name=='MASK'? this.currentDelimeter : ""
      
      projectService.loadParamForCondition(this.projectUuid, param.name, currentDelimeter, this.hashProject).then(data => {
         this.conditionTreeCache.setItems(data)
         this.setLoaded(true)
      })
    },

    selectAttribute(item) {
      if(item.type && item.type.name != "SET" && item.type.name != "CLASS" && item.type.name != "CLASSIFICATOR"){
        this.setCurrentType( item.type.name )
        this.form.operandUuid = item.uuid
        this.attr = item
      }

      this.handleTreeOpen(item)
    },

    handleTreeOpen(item) {
      if (this.conditionTreeCache.isOpened(item)) {
        this.conditionTreeCache.close(item)
      }
      else {
        this.conditionTreeCache.open(item)
      }
    },

    clear() {
      this.setSearch('')
      this.setForm({ ...this.defaultCondition })
    },

  },
}

</script>
