<template lang="pug">
app-section.py-3( style='background-color: #EBEBEB;' v-if='task' )
  div.d-flex.align-center
    task-dates(v-if="hasTaskDates" :task='task' @click='showDates()')
    task-dates(v-else :task='task')

    task-progress-bar.ml-2( :task='task' color='#262626' fontWeight='300' fontSize='10px' @click="showProgress()" )
  
  div.mt-3
    markdown-text.clr(v-if='!showTitleTextarea && !hasTaskCreate' :text='task.title' v-line-clamp='4')
    markdown-text.clr(v-else-if='!showTitleTextarea' :text='task.title' @click='editTitle' v-line-clamp='4')
    markdown-textarea(v-else='showTitleTextarea' v-model='title' :label='$t("module.task.form.title")' hide-details light solo flat dense no-resize)
      v-btn.ml-auto( color="surface" icon x-small @click='showTitleTextarea = false') 
        v-icon close
      v-btn( color='accent' text x-small @click='setTaskTitle(title), showTitleTextarea = false') {{ $t('button.send') }}
  
  div.mt-3(v-if='!haveTaskType')
    markdown-text.clr(v-if='!showDescriptionTextarea && !hasTaskCreate' :text='task.description || "Описание"' style='font-size: 12px;')
    markdown-text.clr(v-else-if='!showDescriptionTextarea' :text='task.description || "Описание"' style='font-size: 12px;' @click='editDescription()')
    markdown-textarea(v-else='showDescriptionTextarea' v-model='description' :label='$t("module.task.form.description")' hide-details light solo flat dense no-resize)
      v-btn.ml-auto( color='surface' icon x-small @click='showDescriptionTextarea = false') 
        v-icon close
      v-btn( color='accent' text x-small @click='setTaskDescription(description), showDescriptionTextarea = false') {{ $t('button.send') }}

  //- TODO NEED REFACTOR
  task-forms-section.mt-3( v-if='haveTaskType' ref="taskFormsSec" :selectedTask="task" )
  //- TODO NEED REFACTOR
  task-report( v-if="showProgressWindow" @close="showProgressWindow = false" :selectedTask="task" )

  task-dates-interval-dialog(v-model='showDatesDialog' :task='task' @confirm='(start, end) => setTaskInterval(Date.parse(start), Date.parse(end))')

</template>

<script>
import TaskProgressBar from '@/components/task/TaskProgressBar.vue'
import TaskFormsSection from '@/components/task/taskDetailsSections/TaskFormsSection.vue'
import TaskFormDetails from '@/components/task/taskDetailsSections/TaskFormDetails.vue'
import TaskReport from '@/components/task/taskReport/TaskReport.vue'
import TaskDates from '../TaskDates.vue'
import TaskDatesIntervalDialog from '@/components/task/TaskDatesIntervalDialog.vue'

import { mapActions, mapGetters } from 'vuex'

import MarkdownText from '@/components/project/panel/right/MarkdownText.vue'
import MarkdownTextarea from '@/components/project/panel/right/MarkdownTextarea.vue'

export default {
  name: 'TaskInfoSection',
  
  components: { 
    TaskProgressBar,
    TaskFormsSection,
    TaskFormDetails,
    TaskReport,
    TaskDates,
    TaskDatesIntervalDialog,
    MarkdownText,
    MarkdownTextarea,
  },
  
  props: {
    task: Object,
  },

  data() {
    return {
      title: null,
      description: null,

      showProgressWindow: false,
      showDatesDialog: false,
      showTitleTextarea: false,
      showDescriptionTextarea: false,
    }
  },

  computed: {
    ...mapGetters('projectPermissions', ['hasTaskCreate','hasTaskDates']),

    haveTaskType() {
      return !!this.task.taskType?.uuid
    }
  },

  watch: {
    task() {
      this.title = this.task?.title || ""
      this.description = this.task?.description || ""
    }
  },

  mounted() {
    this.title = this.task?.title || ''
    this.description = this.task?.description || ''
  },

  methods: {
    ...mapActions('task', ['changeTaskDesc', 'changeTaskTitle', 'setDateInterval']),

    // TODO DELETE
    changeTaskType() {
      this.$refs.taskFormsSec.changeTaskType()
    },

    setTaskDescription(description) {
      description = description?.trim() || ''
      this.changeTaskDesc({ uuid: this.task.uuid, description })
    },

    setTaskTitle(title) {
      // TODO Надо ли заменять на дефолтное значение
      title = title?.trim() || this.$t('module.task.newTask')
      this.changeTaskTitle({ uuid: this.task.uuid, title })
    },
    
    setTaskInterval(start, end) {
      this.setDateInterval({ uuid: this.task.uuid, start, end })
    },

    showDates () {
      this.showDatesDialog = true
    },

    showProgress () {
      this.showProgressWindow = true
    },

    editTitle() {
      this.title = this.task?.title || ""
      this.showTitleTextarea = true
    },

    editDescription() {
      this.showDescriptionTextarea = true
    },
  }
}
</script>

// TODO Удалить ненужние стили
<style>
.t-desc textarea,
.t-title textarea {
  line-height: 16px;
}

</style>

<style lang="scss" scoped>
.t-title {
  font: 400 13px/16px $roboto;
  font-style: normal;
  color: #262626;
}
.dates-container{
  font: 300 10px/18px $roboto !important;
  font-style: normal;
  color: #262626;
}

.clr{
  color: #262626;
}

.cursorDefault {
  cursor: default;
}
</style>