<template lang="pug">
  div(test-id="floor-unit")
    v-hover(v-slot:default="{ hover }" elevation=150)
      .element-unit
        .d-flex.align-center
          .mark.ml-2
            span {{ relElevation }}
          .mark.ml-2
            span {{ element.absElevation > 0 ? '+' + element.absElevation : element.absElevation }}
          .mark.ml-2
            span {{ mark }}
          .floor-name.ml-2.my-2
            span(test-id="floor-name") {{ element.name }} 
          .models.my-1
            draggable( :list="element.elements" group="prettyModelName" @change='handleMoveElement($event)')
              template(v-for="item in prettyModelName")
                v-chip.shadow.my-1.mx-1( outlined small color="accent" text-color="var(--v-primary-base)" test-id="floor-elements") {{ item }}
          v-btn.ml-auto( min-height="25px" height="25px" icon  @click="settings" test-id="floor-settings")
            v-icon( v-if="hover" color="black" ) more_vert
    v-divider.mx-2.my-2(light)

    app-menu.pa-0( ref="settingFloor" :menu="tools" )

    app-dialog-confirm(v-model="dialog.deleteFloor" @confirm="deleteFloors")
      app-text {{ $t('floor.delete', {name: element.name}) }} 
</template>

<script>
import draggable from 'vuedraggable'
import { mapActions, mapGetters, mapState } from "vuex"
import { AlertService } from '@app/AlertService'

const transferringElementByFloor = {
  from: null,
  to: null
}

export default {

  components: {
    draggable,
  },

  data() {
    return {
      floorUud: null,
      dialog: {
        deleteFloor: false,
      }
    }
  },

  props: {
    element: null,
  },

  computed: {
     ...mapGetters ("project", ["projectUuid"]),
     ...mapState ("axis",["floors"] ),

    tools () {
      return [
        { title: this.$t('floor.floorUnit.edit'), action: this.openEditOfFloor},
        { title: this.$t('floor.floorUnit.delete'), action: this.deleteThisAccordance }
      ]
    },

    prettyModelName: {
      get() {
        return this.element.elements.map( (el) =>  el.title + '/' + el.name + '(' + (el.elevation > 0 ? '+' + el.elevation : el.elevation) + ')' )
      },

      set(element) {
        this.$emit('input', element)
      }
    },

    mark() {
      if(Number(this.element.elevation) > 0) {
        return '+' + this.element.elevation
      }
      return this.element.elevation
    },

    relElevation() {
      const relElevation = this.element.relElevation
      const absElevation = this.element.absElevation
      const elevation = this.element.elevation

      if (relElevation + absElevation !== elevation) {
        return this.mark
      } 
      else {
        return relElevation > 0 ? `+${relElevation}` : relElevation.toString()
      }
    }
    
    // prettyModelName() {
    //   return this.element.elements.map( (el) =>  el.title + '/' + el.name)
    // }
  },

  methods: {
    ...mapActions( "axis", ["removeFloor", "saveFloor", "moveFloorElement"] ),

     handleMoveElement(evt) {
      if(evt.added) transferringElementByFloor.to = this.element
      if(evt.removed) transferringElementByFloor.from = this.element

      if(transferringElementByFloor.from && transferringElementByFloor.to){
        this.moveFloorElement({ fromFloorId: transferringElementByFloor.from.uuid, toFloorId: transferringElementByFloor.to.uuid, elementId: evt.removed.element.uuid })
        transferringElementByFloor.to = transferringElementByFloor.from = null
      }
    },
    
    settings (event) {
      this.$refs.settingFloor.show (event)
    },

    openEditOfFloor () {
      this.$emit('editFloor')
    },

    deleteThisAccordance () {
      this.dialog.deleteFloor = true
    },

    deleteFloors() {
      this.dialog.deleteFloor = false
      let floorExist = this.floors.find(flr => flr.uuid == this.element.uuid)

      if (floorExist && floorExist.elements.length == 0) {
        this.removeFloor({ elUuid: this.element.uuid, projectUuid:this.projectUuid })
      }
      else {
        let obj =  {
          info: this.$t('floor.deleteError')
        }
        AlertService.warning( obj )
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.element-unit {
  color: black;
  min-height: 25px;
  width: 100%;
  font: normal 14px/16px "Roboto", sans-serif;
}
.floor-name {
  overflow: hidden;
  white-space: pre-line;
  word-break: break-word;
  width: 20%;
}

.models {
  white-space: pre-line;
  width: 50%;
}

.mark {
  width: 15%;
}
.tools {
  width: 50px;
}

.shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  white-space: pre-wrap;
  height: auto;
}
</style>

