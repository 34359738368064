<template lang="pug">
//- id for canvas resize
div(id='attributecheckingtable') 
  v-data-table.scroll-changer(
      dense
      :headers="visibleHeaders"
      :items="limitedItems"
      disable-pagination
      hide-default-footer
      show-select
      item-key="uuid"
      :height="tableHeight"
      disable-sort
      light
      )

      template( v-slot:top )
        attribute-table-top-slider(:height.sync="tableHeight" @onSlide="changeSliderHeight")
        attribute-table-top.top(@menu="openMenu" @onChangeActive="headerSelect = false")
        v-progress-linear(v-if="tableIsLoading" indeterminate color="accent")

      template(v-slot:header.data-table-select="{ on , props }")
        v-checkbox(v-model="headerSelect" @change="selectAll" test-id="table-header-check" dense hide-details @click.stop color="accent" :ripple="false")  

      template( v-slot:item="{item}")
        attribute-table-body(:item="item" @rowMenu="openMenu")

      template(v-if="start > 0" v-slot:body.prepend)
        tr
          td(:colspan="visibleHeaders.length" :style="'padding-top:' + startHeight + 'px'")

      template(v-if="start + perPage < attributeTableStore.itemsWithoutHidden.length" v-slot:body.append)
        tr
          td(:colspan="visibleHeaders.length" :style="'padding-bottom:' + endHeight + 'px'")

  attribute-checking-tools(ref="tools")
</template>

<script>
import AttributeTableBody from './AttributeTableBody.vue'
import AttributeTableTop from './AttributeTableTop.vue'
import AttributeTableTopSlider from '../collisionList/top/CollisionListTopSlider.vue'
import TableSliderService from '../collision/CollisionTableSliderService.js'
import AttributeHighlightService from './AttributeHighlightService.js'
import AttributeCheckingTools from './AttributeCheckingTools.vue'

import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'
import { PanelController } from '@/pages/panelController'

export default {
  components: {
    AttributeTableBody,
    AttributeTableTop,
    AttributeTableTopSlider,
    AttributeCheckingTools
  },

  data: () => ({
    timeout: null,
    rowHeight: 32,
    perPage: 30,
    tableHeight: 350,
    start: 0,

    headerSelect: false,
  }),

  beforeDestroy() {
    AttributeHighlightService.restoreSelectedObjects()
    this.attributeTableStore.resetAll()

    PanelController.setPanelPadding(true)
  },

  mounted() {
    TableSliderService.usePinia = true
    document.getElementsByClassName('v-data-table__wrapper')[0].onscroll = this.onScroll
    this.attributeTableStore.getCollisionAttrRules()
    this.attributeTableStore.getColumnWidth()
  },

  watch: {
    'attributeTableStore.itemsWithoutHidden'() {
      this.$nextTick(this.addSliders)
    },

    visibleHeaders() {
      this.$nextTick(this.addSliders)
    },
  },

  computed: {
    ...mapStores(useAttributeCheckingTableStore),

    items() {
      return this.attributeTableStore.items
    },

    visibleHeaders() {
      return this.attributeTableStore.visibleHeaders
    },

    tableIsLoading() {
      return this.attributeTableStore.tableIsLoading
    },

    limitedItems() {
      return this.attributeTableStore.itemsWithoutHidden.slice(this.start, this.perPage + this.start)
    },

    startHeight() {
      return this.start * this.rowHeight - 32
    },

    endHeight() {
      return this.rowHeight * (this.items.length - (this.start + this.limitedItems.length))
    },
  },

  methods: {
    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target
        const rows = Math.ceil(scrollTop / this.rowHeight)
        let start = rows + this.perPage > this.items.length ? this.items.length - this.perPage : rows

        this.start = start < 0 ? 0 : start

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop //firefox fix
        })
      }, 15)
    },

    selectAll() {
      if (this.headerSelect) {
        let globalIds = new Set()
        this.items.forEach((item) => {
          item.selected = this.headerSelect
          if (item.globalIds) item.globalIds.forEach(globalIds.add, globalIds)
          AttributeHighlightService.highlightSelectedAttributes([...globalIds])
        })
      } else {
        this.items.forEach((item) => (item.selected = this.headerSelect))
        AttributeHighlightService.resetHighlight()
      }
    },

    addSliders() {
      TableSliderService.addSliders(this.getTableHeight(), this.visibleHeaders)
    },

    changeSliderHeight() {
      TableSliderService.changeSliderHeight(this.getTableHeight())
    },

    getTableHeight() {
      if (this.limitedItems.length < this.perPage) {
        let height = this.limitedItems.length * this.rowHeight + 32
        return height > this.tableHeight ? this.tableHeight : height
      }
      return this.tableHeight
    },

    openMenu(event, item, isRowMenu = false) {
      this.$refs.tools.openMenu(event, item, isRowMenu)
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table {
  width: 100%;
  overflow-x: hidden;
}

::v-deep .v-data-table-header tr {
  background-color: var(--v-surface-lighten1);
}

::v-deep .v-data-table-header tr th span {
  color: var(--v-primary-darken2);
}

::v-deep .v-data-table-header tr th:first-child {
  background-color: #fff;
}

::v-deep th,
::v-deep td {
  border-right: 1px solid var(--v-surface-base);
}

::v-deep th {
  white-space: nowrap;
}

::v-deep tr td {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  height: 32px !important;
}

::v-deep .v-data-table-header {
  position: sticky;
  z-index: 10;
  background-color: var(--v-surface-lighten2);
  top: 0;
}

.top {
  background-color: var(--v-surface-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}

/* mozilla firefox */
.scroll-changer .v-data-table__wrapper {
  scrollbar-width: thin;
  scrollbar-color: var(--v-scroll-darken1) var(--v-scroll-base);
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
