<template lang="pug">
  app-panel(ident="elementtree")
    app-panel-header( :title="$t('section.elementTree.title')" :menu="activeGlobalTab != 'iot' ? headerMenu : iotMenu" )
    app-floating-panels-section( style="height: calc(100% - 26px) !important;" )
      app-floating-panel( :title="$t('section.elementTree.title')" bodyMinHeight="150"
        :settings="floatingPanels('ELEMENT_TREE_ELEMENT_TREE')")
        axis-section( @menu="onAxisMenu" @addingaxisgroup="showAddingAxisDialog()" @share="shareAxisData")
        axis-section-treeview(v-if="!isAxisGroupsSelected")
        NextcloudConverting.mt-5(v-if="$app.version.isCloud")
      
      app-floating-panel( v-if="element" :title="$t('section.element', {name: element.name})" showTooltip bodyMinHeight="150" overflowBody="hidden" 
        :settings="floatingPanels('ELEMENT_TREE_ELEMENT_DETAILS')")
        template( v-slot:headerButton )
          app-toolbar-button( header size="small" icon="mdi-eye" @click.stop="flyToElement(element)" :title="$t('button.flyToElement')")
        element-details( :element="element" data-aos="flip-down" style="flex: 1;" )

    app-menu( ref="axisMenu" :menu="axisMenu" )

    axis-grouping-dialog( v-model="dialog.editingAxisData" :group.sync="axisDataInWIP" @edited="onEditingAxisData" )
    app-dialog-confirm( v-if="axisDataInWIP" v-model="dialog.deletingAxisData" delete @confirm="onDeletingAxisData") 
      app-text {{ $t('section.elementTree.delGroup', {group:  axisDataInWIP.title}) }}
    v-snackbar(v-model="dialog.snack" color="success" timeout="1000" bottom rounded absolute ) OK

    AddAttribute( v-model="dialog.addAttribute" )
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

import { iotService } from '@/_services'
import { SourcePath } from '@app/SourcePath'

import { AxisDataType } from '@models/axis'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

import AxisBlock from './components/axisSection/AxisBlock'

import AxisSection from './components/axisSection/AxisSection'
import AxisSectionTreeview from './components/axisSection/AxisSectionTreeview'
import AxisGroupingDialog from './components/element/Grouping'
import ElementDetails from './components/element/Details'
import AddAttribute from './components/element/AddAttribute'
import NextcloudConverting from '@/components/project/panel/left/components/cloud/NextcloudConverting'
import { useAxisGroupTableStore } from '@/pinia'

export default {
  name: 'ProjectElementTree',

  components: {
    ElementDetails,
    AxisSection,
    AxisSectionTreeview,
    AxisGroupingDialog,
    AddAttribute,
    NextcloudConverting,
    AxisBlock,
  },

  data: () => ({
    axisDataInWIP: null,
    dialog: {
      editingAxisData: false,
      deletingAxisData: false,
      snack: false,
      addAttribute: false,
    },
  }),

  computed: {
    ...mapState('project', ['activeGlobalTab', 'activeIotAdmin']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('axis', ['selectedAxis']),
    ...mapGetters('axis/tree', ['element']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('projectPermissions', ['hasProjectAddAttribution']),

    groupingStore: () => useAxisGroupTableStore(),

    panelMenu() {
      return this.activeGlobalTab == 'iot' ? this.iotMenu : null
      //return (this.activeGlobalTab == 'iot') ? this.iotMenu : this.viewerMenu
    },

    showButtnAddElement() {
      return window.settings.showButtnAddElement ?? false
    },

    defaultMenu() {
      let items = [
        { title: this.$t('section.elementTree.menu.addGroup'), action: this.showAddingAxisDialog },
        //      { title: this.$t('section.elementTree.menu.makeFloor'), action: this.makeFloor}
      ]
      if (this.showButtnAddElement && this.hasProjectAddAttribution) {
        items = [...items, { title: this.$t('section.elementTree.menu.addAttribute'), action: this.showAddingAttrDialog }]
      }

      return items
    },

    headerMenu() {
      return this.menu ? this.defaultMenu.concat(this.menu) : this.defaultMenu
    },

    iotMenu() {
      return [
        //{ title: this.$t('section.elementTree.menu.addGroup'), action: this.showAddingAxisDialog },
        { title: this.$t('section.elementTree.menu.syncWithObj'), action: this.syncHome },
        { title: this.adminOptionTitle, action: this.toggleIotAdmin },
      ]
    },

    // viewerMenu () {
    //   return [
    //     { title: this.$t('section.elementTree.menu.addGroup'), action: this.showAddingAxisDialog }
    //   ]
    // },

    adminOptionTitle() {
      return this.activeIotAdmin ? this.$t('section.elementTree.offModeAdmin') : this.$t('section.elementTree.onModeAdmin')
    },

    axisMenu() {
      return [
        { title: this.$t('button.edit'), action: this.showEditingAxisDialog },
        { title: this.$t('button.delete'), action: this.showDeletingAxisDialog },
        { title: this.shareTitle, action: () => this.shareAxis(this.axisDataInWIP) },
        { title: this.$t('button.export'), action: this.exportGroup },
      ]
    },

    shareTitle() {
      return this.axisDataInWIP?.shared ? this.$t('button.cancel') : this.$t('button.share')
    },

    isAxisGroupsSelected() {
      return this.selectedAxis.value === 5
    },
  },

  mounted() {
    this.$store.dispatch('axis/init', AxisDataType.ELEMENTS)
  },

  methods: {
    ...mapActions('axis', ['loadAxisDataList', 'deleteAxis', 'shareAxis']),
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),
    ...mapMutations('project', ['toggleIotAdmin']),

    showAddingAxisDialog() {
      this.axisDataInWIP = null
      this.dialog.editingAxisData = true
    },

    showAddingAttrDialog() {
      this.dialog.addAttribute = true
    },

    shareAxisData(item) {
      this.shareAxis(item)
    },

    showEditingAxisDialog() {
      this.dialog.editingAxisData = true
    },

    showDeletingAxisDialog() {
      this.dialog.deletingAxisData = true
    },

    onAxisMenu(item, event) {
      this.axisDataInWIP = item.data
      this.$refs.axisMenu.show(event)
    },

    onEditingAxisData(uuid) {
      this.loadAxisDataList(uuid)
      if (this.groupingStore.groupsTableActive && this.groupingStore.selectedAxisGroupTable) {
        this.groupingStore.getAxisTreeFromTable(this.groupingStore.selectedAxisGroupTable.value)
      }
      this.dialog.editingAxisData = false
    },

    onDeletingAxisData() {
      this.deleteAxis(this.axisDataInWIP)
      this.dialog.deletingAxisData = false
    },

    exportGroup() {
      fetch(SourcePath.exportGroup({ uuid: this.axisDataInWIP.uuid, title: this.axisDataInWIP.title }))
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.download = this.axisDataInWIP.title + '.xlsx'

          link.click()
        })
      // window.open(SourcePath.exportGroup( { uuid: this.axisDataInWIP.uuid, title: this.axisDataInWIP.title }), '_blank')
    },

    syncHome() {
      iotService.refreshDevices(this.projectUuid)
      iotService.initHome(this.projectUuid)
    },

    flyToElement(element) {
      let selectedElement = XeokitMediator.viewer.scene.objects[element.globalId]

      if (selectedElement) {
        // XeokitMediator.ElementsSelection.pickElement(selectedElement.id)
        XeokitMediator.CameraFlight.cameraFlyTo(selectedElement)
      } else {
        XeokitMediator.ElementsSelection.pickElement(null)
      }
    },
  },
}
</script>
