<template lang='pug'>
  div
    v-btn(v-if='fab' color='accent' fab @click='openCreateSchemaDialog()')
      v-icon mdi-plus
    
    v-btn(v-else color='accent' elevation='0' small @click='openCreateSchemaDialog()')
      v-icon.mr-1(small) mdi-paperclip
      span {{ $t('module.mnemoschemas.createNewSchema') }}

    create-schema-dialog(v-model='showCreateSchemaDialog')
</template>

<script>
import CreateSchemaDialog from './CreateSchemaDialog'

export default {
  name: "NewSchemaFab",

  components: {
    CreateSchemaDialog
  },

  props: {
    fab: Boolean
  },

  data() {
    return {
      showCreateSchemaDialog: false
    }
  },

  methods: {
    openCreateSchemaDialog() {
      this.showCreateSchemaDialog = true
    }
  }
}
</script>

<style scoped>

</style>