<template lang="pug">
  app-dialog( v-model="visible" width="900" complex header="Справочник для плагина" @close="clearData" ) 
    v-row( no-gutters )
      v-col(cols=2)
        app-panel-section( title="Интерфейс" )
          div( style="height:400px" ) 
            .base-scroll-body
              app-text(v-for="(item, index) in firstCollList" :key="index" @click="selectFirst(item)" :class="{ 'selected': item === selectedClass }" ) {{ item }}
              v-divider.mt-2.mx-2(light)
              app-text.mt-2( @click="showModels" :class="{ 'selected': isModels }" ) {{ "Сущности" }}

      v-col.pl-1(cols=4)
        app-panel-section( :title="isModels ? 'Сущности' : 'Методы'" )
          div( style="height:400px" )
            .base-scroll-body( v-if="selectedClass" )
              app-text(v-for="(method, index) in methodsList" :key="index" :class="{ 'selected': method === selectedMethod }" @click="selectMethod(method)") {{ method }}

            .base-scroll-body( v-if="isModels" )
              app-text(v-for="(model, index) in modelsList" :key="index" :class="{ 'selected': model === selectedModel }" @click="selectModel(model)") {{ model }}

      v-col.pl-1
        app-panel-section( title="Описание" )
          div( style="height:400px" )
            div( v-if="selectedClass && selectedMethod" style="height:400px" )
              .base-scroll-body(:style="isCreateCondition ? 'height: 370px' : ''")
                app-text
                  div.pointer( v-for="(info, index) in methodInfo" :key="index" v-html="info.html" @click="insertText(info.method)")
              div( v-if="isCreateCondition" )
                app-button( @click="openDialogMethod('createCondition')" small color="accent" ) {{ "Автозаполнение функции" }}

            div( v-if="isModels && selectedModel" style="height:400px" )
              .base-scroll-body
                app-text
                  div.pointer( v-for="(field, index) in modelFields" :key="index" v-html="field.html" @click="insertText(field.field)" )


</template>

<script>
import { mapState } from 'vuex'

export default {

  data() {
    return {
      visible: false,

      selectedClass: null,
      isModels: false,

      selectedMethod: null,
      selectedModel: null,
    }
  },

  computed: {
    ...mapState('plugins', ['loadedPluginCode', 'methods', 'apiModels']),

    isCreateCondition() {
      return this.selectedMethod === 'createCondition'
    },

    firstCollList() {
      return Object.keys(this.methods).sort()
    },

    methodsList() {
      if (this.selectedClass) {
        return Object.keys(this.methods[this.selectedClass].methods).sort()
      }

      return []
    },

    modelsList() {
      return Object.keys(this.apiModels).sort()
    },

    modelFields() {
      if (this.selectedModel) {
        let list = []
        Object.values(this.apiModels[this.selectedModel].fields).forEach(field => {
          let info = {}
          info.html = "<p><b style='color: var(--v-accent-base);'>" + field.title + " : " + field.value + "</b><br>"
          info.html += "" + field.description + "</p>"
          info.field = field.title
          list.push(info)
        })

        return list
      }

      return []
    },

    methodInfo() {
      if (this.selectedMethod) {
        let list = []
        Object.values(this.methods[this.selectedClass].methods[this.selectedMethod]).forEach(method => {
          let info = {}
          info.html = "<p><b style='color: var(--v-accent-base);'>" + method.methodName + "( " + method.inputValue + " ) : " + method.returnValue + "</b><br>"
          info.html += method.description + "</p>" 
          info.method = `${this.selectedClass}.${method.methodName}(${method.inputValue})`
          list.push(info)
        })

        return list
      }

      return []
    }
  },

  methods: {
    show() {
      this.visible = true
    },

    insertText(method){
      this.$emit('onInsertText', method)
      this.clearData()
      this.visible = false
    },

    clearData() {
      this.selectedClass = null
      this.isModels = false
      this.selectedMethod = null
      this.selectedModel = null
    },

    selectFirst(item) {
      this.clearModel()
      if (this.selectedClass !== item)
        this.selectedMethod = null

      this.selectedClass = item
    },

    selectMethod(method) {
      this.clearModel()
      this.selectedMethod = method
    },

    showModels() {
      this.clearMethod()
      this.isModels = true
    },

    selectModel(model) {
      this.clearMethod()
      this.selectedModel = model
    },

    clearMethod() {
      this.selectedClass = null
      this.selectedMethod = null
    },

    clearModel() {
      this.selectedModel = null
      this.isModels = false
    },

    openDialogMethod(method){
      this.$emit('openDialog', method)
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>

.selected {
  font-weight: 500; 
  color: var(--v-accent-base) !important;
}

</style>