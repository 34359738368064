<template lang="pug">
  div
    div {{ name }}
    font(color="red") {{ error }}
    video.video_recorder_screen(:id="id" :width="width" :height="height" @loadeddata="load")
    .d-flex
      v-btn.my-1.mx-1( small @click="play" :disabled="disabledStart" light ) {{ $t('app.video.startRecording') }}
      v-btn.my-1( small @click="pause" :disabled="disabledStop" light ) {{ $t('app.video.pauseRecording') }}
      v-btn.my-1( v-if="loop_screen_ms === 0" small @click="screen" light) {{ $t('app.video.makeScreenshot') }}
    canvas.video_recorder_screen_canvas( :id="id_canvas" v-show="show_screen" :width="canvasWidth" :height="canvasHeight" )

    app-dialog( :header="$t('app.video.selectDevice') + name" v-model="dialog" :items="devices" )
</template>

<script>
export default {
  name: 'video-recorder-screen',
  props: {
    light:Boolean,
    color: String,
    name: {
      type: String,
      default: ''
    },
    width: {
      type: String,
    },
    height: {
      type: String,

    },
    qualityWidth: {
      type: Number,
      default: 1280
    },
    qualityHeight: {
      type: Number,
      default: 720
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop_screen_ms: {
      type: Number,
      default: 0
    },
    show_screen: {
      type: Boolean,
      default: false
    },
  },

  mounted() {
    this.video = document.getElementById(this.id);    
    this.canvas = document.getElementById(this.id_canvas);
    this.listDevices();
  },

  computed: {
    id() {
      return 'video_recorder_screen_' + this._uid
    },
    id_canvas() {
      return 'video_recorder_screen_canvas_' + this._uid
    },
  },

  methods: {
    media(device_id) {
      return {
        video: {
          deviceId: device_id,
          width: { ideal: this.qualityWidth },
          height: { ideal: this.qualityHeight }
        }
      }
    },
    listDevices() {
      let self = this
      let items = this.devices;
      let load = this.setDevices;
      let dialog = this.open;
      let constraints = {
        video: true,
        audio: false
      };
      navigator.mediaDevices?.getUserMedia(constraints).then(function() {
        navigator.mediaDevices.enumerateDevices().then(function(devices) {
          devices.forEach(function(device) {
            if (device.kind === 'videoinput') {
              items.push({
                'title': device.label,
                'action': () => { load(device.deviceId) }
              })
            }
            dialog();
          });
        });
      }).catch((err) => {
        if (err.code == 8)
          self.error = this.$t('app.video.noDevicesFound')
        else self.error = this.$t('app.video.errorGettingListDevices')
        console.error("Device access checks failed: ", err, constraints);
      });
    },
    setDevices(device_id) {
      let errBack = () => {};
      let loadDevice = this.loadDevice;
      let media = this.media(device_id);
      let video = this.video;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(media).then(function(stream) {
            video.srcObject = stream;//window.URL.createObjectURL(stream);
            loadDevice();console.log('1')
        });
      } else if (navigator.getUserMedia) { // Standard
        navigator.getUserMedia(media, function(stream) {
            video.src = stream;
            loadDevice();console.log('2')
        }, errBack);
      } else if (navigator.webkitGetUserMedia) { // WebKit-prefixed
        navigator.webkitGetUserMedia(media, function(stream){
            video.src = window.webkitURL.createObjectURL(stream);
            loadDevice();console.log('3')
        }, errBack);
      } else if (navigator.mozGetUserMedia) { // Mozilla-prefixed
        navigator.mozGetUserMedia(media, function(stream){
            video.src = window.URL.createObjectURL(stream);
            loadDevice();console.log('4')
        }, errBack);
      }
    },
    load() {
      // let rect = this.video.getBoundingClientRect()
      this.canvasWidth = this.qualityWidth;//rect.width;
      this.canvasHeight = this.qualityHeight;//rect.height;
    },
    loadDevice() {
      if (this.autoplay) {
        this.video.play();
        this.disabledStop = false;
      } else this.disabledStart = false;
      if (this.loop_screen_ms > 0)
        this.intervalId = setInterval(this.screen, this.loop_screen_ms)
    },
    play() {
      this.video.play()
      this.disabledStart = true;
      this.disabledStop = false;
      if (this.loop_screen_ms > 0)
        this.intervalId = setInterval(this.screen, this.loop_screen_ms)
    },
    pause() {
      this.video.pause();
      this.disabledStop = true;
      this.disabledStart = false;
      if (this.intervalId)
        clearInterval(this.intervalId)
    },
    screen() {
      // let rect = this.video.getBoundingClientRect()
			let context = this.canvas.getContext('2d');
      context.drawImage(this.video, 0, 0, this.qualityWidth, this.qualityHeight);
      this.$emit('screenshot', this.canvas.toDataURL('image/jpeg'))
      // this.screenshot(this.canvas.toDataURL('image/jpeg'))
    },
    open() {
      this.dialog = true
    }
  },

  data: () => ({
    error: '',
    canvasWidth: 0,
    canvasHeight: 0,
    video: null,
    canvas: null,
    intervalId: null,
    disabledStart: true,
    disabledStop: true,
    devices: [],
    dialog: false
  })
}
</script>