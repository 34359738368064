import { render, staticRenderFns } from "./BuildAccordance.vue?vue&type=template&id=7cd7998a&scoped=true&lang=pug&"
import script from "./BuildAccordance.vue?vue&type=script&lang=js&"
export * from "./BuildAccordance.vue?vue&type=script&lang=js&"
import style0 from "./BuildAccordance.vue?vue&type=style&index=0&id=7cd7998a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd7998a",
  null
  
)

export default component.exports