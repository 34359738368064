<template lang="pug">
v-menu( v-model="showMenu" transition="slide-x-transition" offset-y rounded :close-on-content-click="false" tile max-height="200" ) 
  v-btn.search-elements-filter-button--text( dense text x-small color="accent" @click="selectAllFilters" ) {{ filterButtonText }}

  template( v-slot:activator="{ on, attrs }" ) 
    v-btn.px-1( icon x-small v-bind="attrs" v-on="on"  )
      v-icon( color="grey" small ) mdi-filter
  
  .search-elements-filter--text( v-for="item in searchElementsStore.currentElementsTypes")
    v-checkbox( v-model="item.selected" hide-details :ripple="false" dense light @change="selectFilter()")
    span {{ item.name }}
</template>

<script>
import { mapStores } from 'pinia'
import { useSearchElementsStore } from '@/pinia/searchElements.module'

export default({
  data() {
    return {
      showMenu: false,
      typeList: [],  
    }
  }, 

  computed: {
    ...mapStores(useSearchElementsStore),

    filterButtonText() {
      if (this.searchElementsStore.isFiltersSelected) return 'Сбросить все'
      return 'Выделить все'
    }
  },

  methods: {
    selectAllFilters() {
      this.searchElementsStore.isFiltersSelected = !this.searchElementsStore.isFiltersSelected
      if (this.searchElementsStore.isFiltersSelected) {
        this.searchElementsStore.currentElementsTypes.forEach(el => {
          el.selected = true
        })
      } else {
          this.searchElementsStore.currentElementsTypes.forEach(el => {
            el.selected = false
          })
      }

      this.selectFilter()
    },

    selectFilter() {
      this.typeList = this.searchElementsStore.currentElementsTypes.filter(el => el.selected).map(el => el.uuid)
      
      if (this.typeList.length == this.searchElementsStore.currentElementsTypes.length) this.searchElementsStore.isFiltersSelected = true
      if (this.typeList.length == 0 || this.typeList.length < this.searchElementsStore.currentElementsTypes.length) this.searchElementsStore.isFiltersSelected = false

      this.searchElementsStore.getElementsByClassTypes(this.typeList)
    }
  }
})
</script>

<style scoped>
.search-elements-filter-button--text {
  text-transform: none;
}

.search-elements-filter--text {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.v-menu__content {
  padding: 8px;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: white;
  color: var(--v-primary-darken1)
}

.v-menu__content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}
.v-menu__content::-webkit-scrollbar-thumb  {
  background-color: var(--v-scroll-darken1);
}
</style>
 

