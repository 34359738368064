import store from '@/store'
import { useAttributeCheckingTableStore, useAxisGroupTableStore } from '@/pinia'

const pstore = useAttributeCheckingTableStore()
const groupingStore = useAxisGroupTableStore()

export default class TableSliderService {
  static usePinia = false //deprecated, need remove in feature
  static sliderClass
  static piniaStore

  static resizeHeaders(columnSize) {
    let table = document.getElementsByTagName('table')[0]

    if (table) {
      let row = table.getElementsByTagName('tr')[0]
      let cols = row ? row.children : null
      if (cols) {
        for (let i = 1; i < cols.length; i++) {
          if (columnSize[cols[i].textContent]) {
            cols[i].style.width = columnSize[cols[i].textContent]
          }
        }
      }
    }
  }

  static addSliders(height, headers, piniaStore, sliderClass = 'collision-table-slider', startIndex = 1) {
    this.sliderClass = sliderClass
    this.piniaStore = piniaStore

    const table = document.getElementsByTagName('table')[0]
    table.style.width = 'max-content'

    let row = table.getElementsByTagName('tr')[0]
    let cols = row ? row.children : null
    if (!cols) return

    // table.style.overflow = 'hidden'
    // let tableHeight = table.offsetHeight

    let list = document.querySelectorAll('.'.concat(this.sliderClass))
    list.forEach((el) => el.remove())

    for (let i = startIndex; i < cols.length; i++) {
      let div = TableSliderService.createColumnSlider(height)
      cols[i].appendChild(div)
      cols[i].style.position = 'relative'
      cols[i].id = headers[i - startIndex].value
      TableSliderService.setListeners(div)
    }
  }

  static changeSliderHeight(height) {
    let list = document.querySelectorAll('.'.concat(this.sliderClass))
    list.forEach((el) => (el.style.height = height + 'px'))
  }

  static createColumnSlider(height) {
    let div = document.createElement('div')
    div.style.top = 0
    div.style.right = 0
    div.style.width = '5px'
    div.style.position = 'absolute'
    div.style.cursor = 'col-resize'
    div.style.userSelect = 'none'
    div.style.height = height + 'px'
    div.classList.add(this.sliderClass)
    return div
  }

  static setListeners(div) {
    let pageX, curCol, curColWidth, colWidth

    div.addEventListener('mousedown', (e) => {
      curCol = e.target.parentElement
      pageX = e.pageX

      let padding = paddingDiff(curCol)

      curColWidth = curCol.offsetWidth - padding
    })

    div.addEventListener('mouseover', (e) => {
      e.target.style.borderRight = '2px solid #00ffff'
    })

    div.addEventListener('mouseout', (e) => {
      e.target.style.borderRight = ''
    })

    document.addEventListener('mousemove', (e) => {
      if (curCol) {
        let diffX = e.pageX - pageX
        colWidth = curColWidth + diffX

        if (this.piniaStore) {
          if (this.piniaStore == 'axisGroupTable') {
            groupingStore.headers.find((field) => field.value === curCol.id).width = colWidth
          }
        } else if (this.usePinia) {
          pstore.headers.find((field) => field.value === curCol.id).width = colWidth
        } else {
          store.state.collision.table.settings.fieldsSettings.find((field) => field.value === curCol.id).width = colWidth
        }
      }
    })

    document.addEventListener('mouseup', () => {
      if (curCol && colWidth) {
        if (this.piniaStore) {
          if (this.piniaStore == 'axisGroupTable') {
            groupingStore.headers.find((field) => field.value === curCol.id).width = colWidth
            groupingStore.saveHeaders()
          }
        } else if (this.usePinia) {
          pstore.saveHeader(curCol.id, colWidth)
        } else {
          store.dispatch(
            'collision/table/setColumnWidth',
            store.getters['collision/table/visibleHeaders'].find((header) => header.value === curCol.id)
          )
        }
      }
      curCol = undefined
      pageX = undefined
      curColWidth = undefined
      colWidth = undefined
    })

    function paddingDiff(col) {
      if (getStyleVal(col, 'box-sizing') == 'border-box') {
        return 0
      }

      let padLeft = getStyleVal(col, 'padding-left')
      let padRight = getStyleVal(col, 'padding-right')
      return parseInt(padLeft) + parseInt(padRight)
    }

    function getStyleVal(elm, css) {
      return window.getComputedStyle(elm, null).getPropertyValue(css)
    }
  }
}

// setDefaultRowHeight() {
//   let newRowHeight
//   if (this.start > 0) {
//     newRowHeight = document.querySelectorAll('tbody tr')?.[1]?.clientHeight
//   } else {
//     newRowHeight = document.querySelector('tbody tr')?.clientHeight
//   }
//   if (newRowHeight > 0) this.rowHeight = newRowHeight
// },
