<template lang="pug">
v-menu(bottom nudge-bottom='25' nudge-right='10' :close-on-content-click="true")
  template(v-slot:activator='{on, attrs}')
    v-btn(small icon v-bind='attrs' v-on='on')
      .drawing-scale-label {{ store.scaleDrawing }}
  app-panel
    app-section
      .d-flex.flex-column
        v-btn(text small v-for='measure in measures' :key='measure' color='#4D4D4D' @click='handleScaleChange(measure)')
          .drawing-scale-label(style='color: #4D4D4D') {{ measure }}
</template>

<script>
import { useDrawingsStore } from '@/pinia';
import { scaleSelectedPage } from '../../facades/drawingPageListeners.facade';

export default {
  name: 'DrawingScaleMenu',

  computed: {
    store: () => useDrawingsStore(),
    measures: () => [25, 50, 75, 100, 150, 200]
  },

  methods: {
    handleScaleChange(value) {
      this.store.setScaleDrawing(value)
      scaleSelectedPage(true)
    },
  }
}
</script>

<style scoped>
.drawing-scale-label {
  color: #4D4D4D;
  text-align: center;
}
</style>