<template lang='pug'>
app-section.primary.lighten-5
  app-select( v-model="projectClassificator" :items="classificators" :label="$t('section.classification.menuClassification.classifier')" item-text="title" outlined clearable dense )
  app-file-input.mb-2( v-model="file" :max-size="10000000" :formats="fileFormats" hideDetails )
  
  v-btn.text-none( :loading="loading" :disabled="disabled" color="accent" small depressed light @click="handleImport()" ) {{ $t('button.import') }}

</template>

<script>
export default {
  name: "TaskImportForm",

  props: {
    classificators: Array,
    loading: Boolean,
  },

  data() {
    return {
      projectClassificator: null,
      file: null,

      fileFormats: ['.xml', '.mpp'],
    }
  },

  computed: {
    disabled() {
      if (!this.file) return true
      
      const fileFormat = this.file.name.split('.').pop()
      if(fileFormat == "mpp") return false    

      const formatIsValid = this.fileFormats.includes('.' + fileFormat) 
      return !(this.projectClassificator && formatIsValid)
    }
  },

  methods: {
    handleImport() {
      this.$emit('import', this.projectClassificator, this.file)
    }
  }
}
</script>

<style>

</style>