<template lang='pug'>
div.align-center.d-flex.mnemoschema-row(:class="{'mnemoRow': (selectedMnemoschema && (selectedMnemoschema.uuid == schema.uuid)) }" @click="emitAction($event, schema)")
    div.s-title.mnemoschemaTitle {{ schema.title }}

    //- div.ml-auto.s-actions
    //-   div(v-for='([icon, action, title], index) in actions' :key='index')
    //-     v-tooltip(bottom)
    //-       template(v-slot:activator='{ on, attrs }')
    //-         v-btn(v-bind='attrs' v-on='on' icon small @click='emitAction($event, action)')
    //-           v-icon(small) {{ icon }}
    //-       span {{ $t(title) }}
    transition(name='contol-button')
          div.ml-auto
            v-btn(color="#C4C4C4" @click.stop='showMnemoschemaControlMenu' small icon)
              v-icon mdi-dots-vertical
            app-menu(ref="drawingControl" :menu='mnemoschemaSettings')
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MnemoschemaRow',

  props: {
    schema: Object,
  },

  computed: {

    ...mapState('mnemoschema', ['selectedMnemoschema']),

    actions() {
      return [
        ['mdi-pencil', 'edit', 'button.edit'],
        ['mdi-developer-board', 'open', 'module.mnemoschemas.openInEditor'],
        ['mdi-close', 'delete', 'button.delete'],
      ]
    },

    mnemoschemaSettings() {
      return [
        { title: this.$t("button.edit"), action: this.showEditMnemoschemasDialog },
        { title: this.$t("button.delete"), action: this.showDeleteMnemoschemasDialog },
        { title: this.$t("module.mnemoschemas.openInEditor"), action: this.showEditDrawingDialog },
        // { title: this.$t("module.drawing.delete"), icon: "mdi-delete-outline", action: this.showDeleteDrawingDialog },
      ]
    },

    mnemoschemaRowStyle(){
      return this.selectedMnemoschema && this.selectedMnemoschema == this.schema 
    }
  },

  methods: {
    emitAction(event,action) {
      event.stopPropagation()
      this.$emit('action', action)
    },

    showMnemoschemaControlMenu(event) {
      this.$refs.drawingControl.show(event)
    },

    showDeleteMnemoschemasDialog() {
      this.$emit('action', 'delete')
    },

    showEditMnemoschemasDialog() {
      this.$emit('action', 'edit')
    },

    showEditDrawingDialog() {
      this.$emit('action', 'open')
    }
  }
}
</script>

<style scoped>
  .mnemoschema-row {
    padding: 4px 4px 4px 8px;
    border-radius: 5px;
    /* background: #6B6B6B; */
    border: 2px solid grey;
    cursor: pointer;
  }
  .mnemoschema-row:hover{
    transform: scale(1.01);
    box-shadow: 0 0 10px grey;
  }
  .mnemoRow{
    background: rgb(163, 163, 163);
  }
  .s-actions {
    display: flex;
    align-items: center;
  }
  .mnemoschemaTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }
</style>