<template lang="pug">
  div(v-if="selectedTask.maintenanceTask")  
    task-section-expansion(:title="`${$t('module.task.maintenance')}`" headerColorDark  
      additionalIcon="/project/tools/show.svg" @additionalIconClick="showMaintenancePanel()"
      additionalIcon2="/project/tools/task-dashboard-grey.svg" @additionalIcon2Click="showMaintenanceDialog()")

      app-text.mx-2.d-flex.justify-space-between
        span {{selectedTask.maintenanceTask.title}}
        span {{selectedTask.maintenanceTask.maintenanceDate}} 
        span(:class="'task-status--' + selectedTask.maintenanceTask.status.name") {{selectedTask.maintenanceTask.status.title}}
      
    maintenance-check(v-model="dialog.saveCheck" :maintenanceTask="selectedTask.maintenanceTask" @updateMDate="loadMaintenanceTask()")
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import MaintenanceCheck from '@/components/project/panel/left/maintenance/dialogs/MaintenanceCheck.vue'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue'

export default {
  name: 'TaskMaintenanceTaskSection',

  components: {
    MaintenanceCheck,
    TaskSectionExpansion,
  },

  props: {
    selectedTask: Object,
  },

  watch: {
    selectedTask() {
      this.loadMaintenanceTask()
    },
  },

  data() {
    return {
      dialog: {
        saveCheck: false,
      },
    }
  },

  mounted() {
    if (!this.selectedTask?.maintenanceTask) this.loadMaintenanceTask()
    if (!this.loaded) this.INIT()
  },

  computed: {
    ...mapState('maintenance', ['loaded']),
  },

  methods: {
    ...mapActions('task', ['loadMaintenanceTask']),
    ...mapActions('maintenance', ['INIT', 'openNodeByUuid']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    showMaintenanceDialog() {
      this.dialog.saveCheck = true
    },

    showMaintenancePanel() {
      this.openNodeByUuid(this.selectedTask.maintenanceTask.equipmentId)
      this.setActiveGlobalTab('maintenance')
    },
  },
}
</script>

<style lang="scss" scoped>
.task-status--DONE {
  color: green;
}
.task-status--SKIP {
  color: red;
}
.task-status--PROGRESS {
  color: #cddd16;
}
</style>
