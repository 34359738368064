<template lang='pug'>
  div.task-tree-row-status
    task-priority.ml-1.mr-2(:task="task" theme='light' useMenu)
    task-status.ml-auto.mr-auto(:task="task" useMenu)
</template>

<script>
import TaskStatus from '../../TaskStatus'
import TaskPriority from '../../TaskPriority'
import { Task } from '@/assets/model/task/Task'

export default {
  name: "TaskTreeRowStatus",

  components: { TaskStatus, TaskPriority },

  props: {
    task: Task
  }
}
</script>

<style lang='scss' scoped>
  .task-tree-row-status {
    min-width: 105px;
    display: flex;
    padding: 8px 0px;
  }
</style>