import { useDrawingsStore } from "@/pinia"
import { startPageLoading } from "./drawingPage.facade"
import { scrollPageToComment } from "./drawingComment.facade"

export function selectPagePreview(page, comment = null, withSidePanel = false) {
  useDrawingsStore().selectedDrawingPage = page
  if (withSidePanel) useDrawingsStore().sidePanelVisible = true /* необходимо перенести/посмотреть, где лежит */

  setTimeout( () => {
    startPageLoading(useDrawingsStore().selectedDrawingPage).then(() => {
      if (comment) scrollPageToComment(comment)
    })
  })
}