<template lang="pug">
div.text-truncate
  div.task-status-fonts.text-truncate( v-if="!onlyMenu && !task.archived" :class="{'task-status-fonts-hover': hasEditStatus}" :style='{ color: checkTaskStatus }' @click="showStatusMenu" ) {{ taskStatusTitle }}
  div.task-status-fonts.text-truncate( v-if="!onlyMenu && task.archived" :class="{'task-status-fonts-hover': hasEditStatus}" style='color: red') {{ $t('headerToolbar.archive')  }}
  app-menu( ref="statusMenu" :menu="statusMenu" )
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: "TaskStatus",


  
  props: {
    task: Object,
    useMenu: Boolean,
    onlyMenu: Boolean,
  },

  computed: {
    ...mapState('task', ['completeStatuses', 'pluginStatus']),

    ...mapState("project", ["projectSettings"]),
    ...mapGetters("workspace", [ "workspaces"]),
    ...mapGetters("projectPermissions", [ "hasTaskStatusPriority", 'hasTaskClosed']),

    checkTaskStatus() {
      switch(this.task.completeStatus?.name){
        case "NEW":
          return "#EB5757"
        case "READY":
          return "#27AE60"
        case "DISCUSS":
          return "#f2994a"
        case "CLOSED":
          return "#c4c4c4"
        case "PROGRESS":
          return "#FF5733"
      }
      return 0;
    },

    taskStatusTitle() {
      if (this.checkPlugin && this.task.pluginStatus && this.pluginStatus?.length > 0) {
        let status = this.pluginStatus.find(el => el.name === this.task.pluginStatus)
        if(status && status.title) return status.title
      }
      return this.task.completeStatus?.message ? this.$t('module.' + this.task.completeStatus.message) : ''
    },

    statusMenu() {
      let stat = []

      if (this.checkPlugin && this.task.pluginStatus && this.pluginStatus && this.pluginStatus.length > 0) {
        for (let status of this.pluginStatus) {
          stat.push({ title: status.title, action: () => this.setPluginTaskStatus(status.name) })
        }
      }
      else {
        for(let status of this.completeStatuses ) {
          if (this.hasTaskStatusPriority && status.name !== 'CLOSED') {
            stat.push({ title: this.$t('module.' + status.message), action: () => this.setTaskStatus(status.name) })  
          }
          if (this.hasTaskClosed && status.name === 'CLOSED') {
            stat.push({ title: this.$t('module.' + status.message), action: () => this.setTaskStatus(status.name) })  
          }
        }
      }

      return stat
    },

    checkPlugin() {
      let ps = JSON.parse(JSON.stringify(this.projectSettings))
      let workspace = this.workspaces.find(el => el.uuid === ps.workSpace)
      return !!(workspace?.plugin && workspace?.plugin?.pluginUuid)
    },

    hasEditStatus () {
      return this.hasTaskStatusPriority || this.hasTaskClosed
    }
  },

  methods:{
    ...mapActions('task', ['changeTaskStatus', 'changePluginStatus']),

    showStatusMenu(event) {
      if (this.useMenu && this.hasEditStatus) this.$refs.statusMenu.show(event)
    },

    setTaskStatus(name) {
      this.changeTaskStatus({ uuid: this.task.uuid, status: name })
    },

    setPluginTaskStatus(status) {
      this.changePluginStatus({ uuid: this.task.uuid, status: status }).then( () => this.task.pluginStatus = status )
    }
  }
}
</script>

<style lang="scss" scoped>

.task-status-fonts {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.task-status-fonts-hover:hover {
  cursor: pointer;
  filter: brightness(70%);
  color: var(--v-primary-lighten3);
}

</style>