<template lang="pug">
  app-dialog(v-model='show.taskDateInterval' :header='$t("module.task.time.editDate")' :confirmText='$t("button.save")' @confirm="saveDate"
      :confirmDisabled='!(this.newDate.start && this.newDate.end)')
      app-section.py-4
        v-menu(
              ref="expired"  
              :close-on-content-click="false" 
              transition="scale-transition" offset-y min-width="auto"
              v-model="show.pickerStart"
              )
              template(v-slot:activator="{ on }")
                  v-text-field.py-0(
                      :value='dateFormat(newDate.start)' :label="$t('module.task.time.startDate')" 
                      prepend-icon="mdi-calendar" readonly
                      v-on="on" light clearable
                      test-id="task-date-start"
                  )
              v-date-picker( v-model='newDate.start' :min='startDateMin' first-day-of-week=1 no-title scrollable @input='checkDateInterval' @change='closePicker(true)')
        v-menu(
              ref="expired"  
              transition="scale-transition" offset-y min-width="auto"
              :close-on-content-click="false"
              v-model="show.pickerEnd"
              )
              template(v-slot:activator="{ on }")
                  v-text-field.py-0(
                      :value='dateFormat(newDate.end)' :label="$t('module.task.time.endDate')" 
                      prepend-icon="mdi-calendar" readonly
                      v-on="on" light clearable
                  )
              v-date-picker( v-model='newDate.end' :min='newDate.start' first-day-of-week=1 no-title scrollable @change='closePicker(false)' )
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "TaskDateInterval",
  
  props: {
    task: null,
  },

  data() {
    return {
      show: {
        taskDateInterval: false,
        pickerEnd: false,
        pickerStart: false,
        disabled: true
      },
      newDate: {
        start: "",
        end: ""
      },
    }
  },

  computed: {
    ...mapState('task', ['tasks']),

    startDateMin() {
      if(this. task && this.task.parent) {
        let parent = this.tasks.find(task => task.uuid === this.task.parent)
        if(this.task.execType.name === "PAR") {
          return parent?.startDate.split(' ')[0]
        }
        else if(this.task.execType.name === "SEQ") {
          let parentEndDate = parent?.endDate.split(' ')[0].split('-')
          parentEndDate[2] = parseFloat(parentEndDate[2]) + 1

          return parentEndDate.join('-')
        }
      }
      return ''
    }
  },

  methods: {
    ...mapActions('task', ['setDateInterval']),

    showTaskDateInterval() {
      if(this.task) {
        this.newDate.start = this.task.startDate.split(' ')[0]
        this.newDate.end = this.task.endDate.split(' ')[0]
      }
      this.show.taskDateInterval = true
    },

    saveDate() {
      if(this.task) {
        // let start = new Date(this.newDate.start)
        // let end = new Date(this.newDate.end)


        // start = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(), 0, 0, 0))
        // end = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate(), 0, 0, 0))

        // console.log(start)
        // console.log(end)

        // start.setHours(0, 0, 0, 0)
        // end.setHours(0, 0, 0, 0)

        this.setDateInterval( {uuid: this.task.uuid, start: Date.parse(this.newDate.start), end: Date.parse(this.newDate.end)} )

        // this.changeChildrenDates(this.task, start, end)
      }
      else this.$emit("date", this.newDate.start, this.newDate.end)
    },

    // Изменить даты всех связанных задач
    changeChildrenDates(task, newStart, newEnd) {
      let taskStartDate = new Date(task.startDate)
      let taskEndDate = new Date(task.endDate)

      taskStartDate.setHours(0, 0, 0, 0)
      taskEndDate.setHours(0, 0, 0, 0)

      let startOffset = new Date(newStart).getTime() - new Date(taskStartDate).getTime()
      let endOffset = new Date(newEnd).getTime() - new Date(taskEndDate).getTime()

      task.children.map(childTask => {
        if(childTask.execType.name === "PAR") {
          let newStartForParrallelChild = new Date(childTask.startDate).getTime() + startOffset
          let newEndForParrallelChild = new Date(childTask.endDate).getTime() + startOffset
          
          this.setDateInterval( {uuid: childTask.uuid, start: newStartForParrallelChild, end: newEndForParrallelChild} )

          this.changeChildrenDates(childTask, newStartForParrallelChild, newEndForParrallelChild)
        }
        else if(childTask.execType.name === "SEQ") {
          let newStartForSequenceChild = new Date(childTask.startDate).getTime() + endOffset
          let newEndForSequenceChild = new Date(childTask.endDate).getTime() + endOffset
          
          this.setDateInterval( {uuid: childTask.uuid, start: newStartForSequenceChild, end: newEndForSequenceChild} )

          this.changeChildrenDates(childTask, newStartForSequenceChild, newEndForSequenceChild)
        }
      })
    },

    checkDateInterval(start) {
      this.newDate.start = start
      if( new Date(this.newDate.end).getTime() < new Date(this.newDate.start).getTime() ){
        this.newDate.end = start
      }
    },

    dateFormat(date) {
      console.log(date)
      let format =  new Date(date).toISOString().split("T")[0].replace(/-/g, ".")
      format = format.split('.')
      let temp = format[0]
      format[0] = format[2]
      format[2] = temp
      format = format.join('.')
      return format
    },

    closePicker(start) {
      if(start){
        this.show.pickerStart = false
      } else{
        this.show.pickerEnd = false
      }
    }
  }
}
</script>

<style>

</style>