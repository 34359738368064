<template lang="pug">
.app-toolkit-column
  ToolButtonSelectionFrame
  ToolButtonSelectionShow
  ToolButtonSelectionHide
  ToolButtonSelectionClear
</template>

<script>
import ToolButtonSelectionFrame from './buttons/ToolButtonSelectionFrame.vue'
import ToolButtonSelectionHide from './buttons/ToolButtonSelectionHide.vue'
import ToolButtonSelectionShow from './buttons/ToolButtonSelectionShow.vue'
import ToolButtonSelectionClear from './buttons/ToolButtonSelectionClear.vue'

export default {
  components: {
    ToolButtonSelectionFrame,
    ToolButtonSelectionShow,
    ToolButtonSelectionHide,
    ToolButtonSelectionClear,
  },
}
</script>