<template lang="pug">
div 
  .section-header.mt-3 {{ $t('section.collision.rulesFounder.exceptionsElements') }}
  div( v-for="ex in exceptionsRules" test-id="exceptions-elements" )
    .d-flex.align-center.mt-2 
      v-tooltip(bottom)
        template(v-slot:activator="{ on, attrs }" )
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            .text-wrapper( v-bind="attrs" v-on="on" :class="{ 'elevation-1': hover, 'sample-edit': ex.new || ex.edit }" ripple @click="showExceptionADialog(ex)" ) {{ ex.exceptionA? ex.exceptionA.title: "" }}
        span  {{ ex.exceptionA? ex.exceptionA.title: "" }}
      app-icon-button.mx-1( v-if="ex.exceptionA && hasCollisionCreateRule" icon="mdi-eye" iconColor="var(--v-accent-base)" test-id="show-exception-elements" @click="showViewButton(ex, true)" )
      app-icon-button(v-if="hasCollisionCreateRule" icon="mdi-dots-vertical" buttonSize="24" iconColor="black" @contextmenu="onRuleMenu(ex, $event)" @click="onRuleMenu(ex, $event)" )
  app-icon-button.float-left.mt-1(v-if="hasCollisionCreateRule" icon="$add-box-outlined" iconColor="black" @click="showExceptionDialog()" test-id="add-exceptions-element" )

  app-menu( ref="exceptionElementMenu" :menu="exceptionElementMenu" )
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

function replacePairExceptions(exceptionRule, condition, aFlag){
  if(aFlag)
    exceptionRule['exceptionA'] = condition
  else
    exceptionRule['exceptionB'] = condition
  return exceptionRule;
}

function  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    }

  export default {
    name: "RuleSettingGeneralExceptions",

  data() {
    return {
    exceptionsRules: [],
    flagEditB: false,
    exceptionRule: {}, 
    menuItem: null, 
    }
  },

  computed: {
    ...mapGetters('rule/details', ['rule']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule']),
  
    exceptionElementMenu() {
      return [
        { title: this.$t('button.delete'), action: ()=>this.removeConditionExceptions(this.menuItem) },
      ]
    },
  },

  mounted() {
    if (this.rule.finder.className.includes('DataBaseClashRule') || this.rule.finder.className.includes('OccurrenceClashRule') || this.rule.finder.className.includes('PluginClashRule')) {
      this.exceptionsRules = [ ...this.rule.exceptions ]
    }
  },

  methods: {
    ...mapActions('rule/details', ['removeSampleExceptions']),

    sort(){
        this.exceptionsRules.sort((a, b) => 
            a.exceptionA.title > b.exceptionA.title ? a.exceptionA.title > b.exceptionA.title
            : a.exceptionB?.title > b.exceptionB?.title
      )
    },

    onRuleMenu (item,event) {
      this.menuItem = item
      this.$refs.exceptionElementMenu.show(event)
    },

    showViewButton(item, fl){
      if(item != null){
        if(fl && item.exceptionA != null)
          this.$emit('showViewButton', item.exceptionA)
        else if(item.exceptionB != null)
          this.$emit('showViewButton', item.exceptionB)
      }
    },

    showExceptionDialog() {
        this.exceptionRule = {}
        this.exceptionRule['uuid'] = uuidv4()
        this.flagEditB = false
        this.$emit('showConditionExceptionsDialog',this.exceptionRule, null, true, false)
    },

    showExceptionADialog(exception) {
        this.exceptionRule = exception
        this.flagEditB = false
        this.$emit('showConditionExceptionsDialog', this.exceptionRule, exception.exceptionA, true, false)
    },

    showExceptionBDialog(exception) {
        this.exceptionRule = exception
        this.flagEditB = true
        this.$emit('showConditionExceptionsDialog', this.exceptionRule, exception.exceptionB, false, true)
    },

    removeConditionExceptions(exception) {
      this.removeSampleExceptions(exception)
      this.exceptionsRules = this.exceptionsRules.filter(exceptionRule => exceptionRule !== exception)
      this.$emit('saveRule')
      this.sort()

      let item = exception.exceptionA || exception.exceptionB
      this.$emit('clearExceptionElements', item.uuid)
    },

    updateSampleExceptions(condition) {
      if (condition.edit) {
        if(this.flagEditB) 
          this.updateExistingSampleExceptions(condition, false) 
        else
          this.updateExistingSampleExceptions(condition, true)
      }
      else {
        if(!this.flagEditB){
         replacePairExceptions(this.exceptionRule, condition, true)
         this.exceptionsRules.push(this.exceptionRule)
        }
        else{
          replacePairExceptions(this.exceptionRule, condition, false)
        }
      }
      this.sort()
    },

    updateExistingSampleExceptions(condition, aflag) {
        replacePairExceptions(this.exceptionRule, condition, aflag)
        for (let index = 0; index < this.exceptionsRules.length; index ++) {
          if(this.exceptionRule.uuid && this.exceptionRule.uuid === this.exceptionsRules[index].uuid){
            this.exceptionsRules.splice(index, 1, this.exceptionRule)
            break
          }
        }
    },

    refreshExceptions() {
      this.exceptionsRules = this.rule !=undefined ?[ ...this.rule?.exceptions] : []
      this.sort()
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  font: normal 12px/16px $roboto;
  color: #303030;
}

.sample-edit {
  background: rgb(59, 147, 175, .3);
}

.text-wrapper {
  background-color: #EBEBEB;
  border-radius: 3px;
  font: normal 14px/16px $roboto;
  color: #4D4D4D;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>