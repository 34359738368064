<template lang="pug">
  app-section.base-scroll-body.mt-2( style="height: calc(100% - 32px); width: 100%; border-radius: 3px; ")
    //- div(style="color:black") {{open}} - {{active}}
    div( v-if="loading" )
      v-skeleton-loader( tile light type="list-item-avatar-two-line@3")
      v-skeleton-loader( tile light type="list-item-avatar-two-line@1" style="margin-left: 20px;")
      v-skeleton-loader( tile light type="list-item-avatar-two-line@2" style="margin-left: 40px;")
      v-skeleton-loader( tile light type="list-item-avatar-two-line@2")
      v-skeleton-loader( tile light type="list-item-avatar-two-line@3" style="margin-left: 20px;")
    //- div(style="color:black") i - {{num}}
    //- v-btn(@click="hehe") click
    v-treeview(v-if="!loading" v-model="tree" :items="cloudWebDavTree"  item-key="fileid" activatable light hoverable open-on-click
     :open.sync="open" :active.sync="active" transition :load-children="openCloudTree" :search="search")
      template(v-slot:prepend="{ item, open }")
        v-icon(v-if="item.type == 1") {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        v-icon(v-else) {{ getIcon(item) }}

      template(v-slot:label="{ item }")
        div(@contextmenu.stop="onMenu(item,$event)") 
          div {{ item.name }}
          .text-caption.grey--text.lighten-5--text(v-if="item.size > 0") 
            .d-inline-flex {{ item.sizeText }}
            .d-inline-flex.ml-5(v-if="item.lastModify") {{ item.lastModify }}

      template(v-slot:append="{ item, open }")
        v-btn.mr-2(@click.stop="onMenu(item, $event)" icon small)
          v-icon() mdi-dots-vertical


    toolbar-menu( ref="fileToolbar"  :menu="toolMenu" fontSize="10px" color="#757575" )

    app-dialog-confirm(v-model="dialog.confirm" delete @confirm="deleteItem")   {{dialog.text}} 

    app-dialog(v-model="dialog.create" :header="dialog.isFile ? $t('section.journal.addFile'):$t('section.journal.addFolder')" :confirmText="$t('button.add')"  @confirm="createItem" :confirmDisabled="disabled" )
      app-section.d-flex.flex-column  
        v-form(ref="form")
          div(v-if="dialog.isFile")
            app-file-input(v-model="dialog.file" :formats="[]" :maxSize="1.5e7")
          div(v-else)
            app-text-field(v-model="dialog.folderName" :label="$t('template.dialog.folderName')" :maxlength="50" required :rules="[rules.required, rules.text]") 

    file-sign(v-if="dialog.sign" :item="item" :isCheck="dialog.isCheck" :isParallelSign="dialog.isParallelSign"  @close="signDialogClose")  
</template>

<script>
  import ToolbarMenu from '@/components/project/panel/left/floors/ToolbarMenu'
  import FileSign from '@@/project/panel/left/fileManager/FileSign'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { $_validate_rules } from '@/common/ValidateRules'

  export default {
    name: 'FileManagerTree',
    mixins: [$_validate_rules],
    components: {
      ToolbarMenu,
      FileSign,
    },

    props: {
      search: String,
    },

    data() {
      return {
        tree: [],
        dialog: {
          confirm: false,
          create: false,
          template: false,
          sign: false,
          isFile: false,
          file: null,
          text: '',
          folderName: '',
          isCheck: false,
          isParallelSign: false,
        },
        num: 123,
        open: [],
        active: [],
        toolMenu: [],
        item: null,
        nextCloud: { cloudProvider: 'nextCloud' },
      }
    },

    mounted() {
      this.INIT()
    },

    computed: {
      ...mapGetters('cloud', ['cloudWebDavTree']),
      ...mapState('project', ['project']),

      disabled() {
        if (this.dialog.isFile) return this.dialog.file == null
        else return this.dialog.folderName.length == 0 || !/^[A-Za-zа-яА-ЯёЁ0-9.-]+$/.test(this.dialog.folderName)
      },

      folderMenu() {
        return [
          {
            title: this.$t('section.journal.addFile'),
            icon: '/img/project/tools/add_box_grey.svg',
            action: () => this.showCreateDialog('addFile'),
          },
          {
            title: this.$t('section.journal.addFolder'),
            icon: '/img/project/tools/add_box_grey.svg',
            action: () => this.showCreateDialog(),
          },
          {
            title: this.$t('section.journal.deleteFolder'),
            icon: '/img/project/tools/delete_fill.svg',
            action: () => this.showDeleteDialog('folder'),
          },
        ]
      },
      fileMenu() {
        return [
          {
            title: this.$t('section.journal.downloadFile'),
            icon: '/img/download.svg',
            action: () => this.downloadFile(),
          },
          {
            title: this.$t('section.journal.deleteFile'),
            icon: '/img/project/tools/delete_fill.svg',
            action: () => this.showDeleteDialog(),
          },
          {
            title: this.$t('section.journal.createDS'),
            icon: '/img/project/tools/sign.svg',
            action: () => this.showCreateSignDialog(),
          },
          {
            title: this.$t('section.journal.createParallelDS'),
            icon: '/img/project/tools/sign.svg',
            action: () => this.showCreateParallelSignDialog(),
          },
          {
            title: this.$t('section.journal.checkDS'),
            icon: '/img/project/tools/signcheck.svg',
            action: () => this.showCheckSignDialog(),
          },
        ]
      },

      loading() {
        return !this.cloudWebDavTree.length
      },
    },

    methods: {
      ...mapActions('cloud', [
        'cloudWebDavSearch',
        'cloudWebDavSearchByPath',
        'getCloudSettings',
        'cloudWebDavDownloadFile',
        'cloudWebDavCreateFolder',
        'cloudWebDavDeleteItem',
        'cloudWebDavUploadFile',
        'cloudWebDavUpdateTree',
      ]),

      async INIT() {
        let cloudAttr = await this.getCloudSettings(this.project)
        if (cloudAttr != null) {
          this.nextCloud = Object.assign({}, this.nextCloud, cloudAttr)
          this.cloudWebDavSearch(this.nextCloud)
        }
      },

      signDialogClose(signCreate) {
        this.dialog.sign = false
        this.dialog.isCheck = false
        this.dialog.isParallelSign = false

        if (!this.dialog.isCheck && signCreate) {
          this.cloudWebDavUpdateTree({ item: this.item, cloudWebDav: this.nextCloud })
        }
      },

      showDeleteDialog(type) {
        if (type)
          this.dialog.text =  this.$t('template.dialog.deleteFolder', {name: this.item.name})
        else this.dialog.text = this.$t('template.dialog.deleteFile', {name:this.item.name})
        this.dialog.confirm = true
      },

      showCreateDialog(type) {
        this.dialog.folderName = ''
        this.dialog.file = null
        this.dialog.isFile = false
        if (type) this.dialog.isFile = true
        this.dialog.create = true
      },

      showRootCreateDialog(text) {
        this.item = { path: '' }
        this.showCreateDialog(text)
      },

      showCreateSignDialog() {
        this.dialog.sign = true
      },

      showCreateParallelSignDialog() {
        this.dialog.isParallelSign = true
        this.dialog.sign = true
      },

      showCheckSignDialog() {
        this.dialog.isCheck = true
        this.dialog.sign = true
      },

      onMenu(item, event) {
        this.item = item
        if (item.type == 1) this.toolMenu = this.folderMenu
        else this.toolMenu = this.fileMenu
        this.$refs.fileToolbar.show(event)
      },

      getIcon(item) {
        let ext = item.ext.toLowerCase()
        let types = {
          html: 'mdi-language-html5',
          js: 'mdi-nodejs',
          json: 'mdi-code-json',
          md: 'mdi-language-markdown',
          pdf: 'mdi-file-pdf',
          txt: 'mdi-file-document-outline',
          xlsx: 'mdi-file-excel',
          xls: 'mdi-file-excel',
          doc: 'mdi-file-word',
          docx: 'mdi-file-word',
          ifc: 'mdi-file-cad',
          sig: 'mdi-file-key-outline',
          sgn: 'mdi-file-key-outline',
        }

        let mimeTypes = {
          image: 'mdi-file-image',
          video: 'mdi-file-video-outline',
          audio: 'mdi-file-music',
        }
        if (types[ext]) return types[ext]
        let mime = item.mimeType.match(/.+(?=\/)/g)[0]

        if (mimeTypes[mime]) return mimeTypes[mime]

        return 'mdi-file-document-outline'
      },

      openCloudTree(item) {
        this.nextCloud.path = item.path
        return this.cloudWebDavSearchByPath({ item: item, cloudWebDav: this.nextCloud })
      },

      createItem() {
        if (this.$refs.form.validate()) {
          //Добавить обработку ошибок
          if (this.dialog.isFile) {
            this.nextCloud.path = this.item.path + encodeURI(this.dialog.file.name)
            let formData = new FormData()
            formData.append('username', this.nextCloud.username)
            formData.append('passwd', this.nextCloud.passwd)
            formData.append('path', this.nextCloud.path)
            formData.append('cloudProvider', this.nextCloud.cloudProvider)
            formData.append('cloudUrl', this.nextCloud.cloudUrl)
            formData.append('file', this.dialog.file)
            this.cloudWebDavUploadFile({ item: this.item, cloudWebDav: this.nextCloud, formData: formData })
          } else {
            this.nextCloud.path = this.item.path + encodeURI(this.dialog.folderName)
            this.cloudWebDavCreateFolder({ item: this.item, cloudWebDav: this.nextCloud })
          }
        } else console.log('ERROR')
      },

      downloadFile() {
        //TODO:возможно переделать скачивание напрямую с NextCloud
        this.nextCloud.path = this.item.path
        this.nextCloud.name = this.item.name
        this.cloudWebDavDownloadFile(this.nextCloud)
      },

      deleteItem() {
        this.nextCloud.path = this.item.path
        this.cloudWebDavDeleteItem({ item: this.item, cloudWebDav: this.nextCloud })
      },
    },
  }
</script>

<style scoped>
  ::v-deep .v-treeview-node__toggle {
    width: fit-content;
  }

  ::v-deep .v-treeview-node__level {
    width: 24px;
  }
  .prev {
    border: solid 1px black;
    width: 60px;
    height: 60px;
  }
  /* ::v-deep .v-treeview-node__root{
  padding-left: 5px;
} */
</style>
