<template lang="pug">
.control-panel-root
  DrawingScaleMenu  
  DrawingScaleSlider
  DrawingOpenComment
  DrawingOpenCommentList
  DrawingFit
  DrawingCrop
</template>

<script>
import DrawingScaleMenu from './actions/DrawingScaleMenu.vue';
import DrawingScaleSlider from './actions/DrawingScaleSlider.vue';
import DrawingOpenComment from './actions/DrawingOpenComment.vue';
import DrawingOpenCommentList from './actions/DrawingOpenCommentList.vue';
import DrawingFit from './actions/DrawingFit.vue';
import DrawingCrop from './actions/DrawingCrop.vue';

export default {
  name: 'DrawingPageHeaderActions', 

  components: {
    DrawingScaleMenu,
    DrawingScaleSlider,
    DrawingOpenComment, 
    DrawingOpenCommentList, 
    DrawingFit, 
    DrawingCrop
  },
}
</script>

<style scoped>
.control-panel-root {
  margin-left: auto;
  display: flex;
  gap: 4px;
  align-items: center;
}
</style>