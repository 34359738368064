import date_utils from "./date_utils";
import { $, createSVG, animateSVG } from "./svg_utils";

export default class Bar {
  constructor(gantt, task) {
    this.set_defaults(gantt, task);
    this.prepare();
    this.draw();
    this.bind();
  }

  set_defaults(gantt, task) {
    this.action_completed = false;
    this.gantt = gantt;
    this.task = task;
  }

  prepare() {
    this.prepare_values();
    this.prepare_helpers();
  }

  prepare_values() {
    this.invalid = this.task.invalid;
    this.height = this.gantt.options.bar_height;
    this.x = this.compute_x();
    this.y = this.compute_y();
    this.corner_radius = this.gantt.options.bar_corner_radius;
    this.duration =
      date_utils.diff(this.task._end, this.task._start, "hour") /
      this.gantt.options.step - 1;
    this.width = this.gantt.options.column_width * this.duration;
    this.progress_width =
      this.gantt.options.column_width *
        this.duration *
        (this.task.progress / 100) || 0;
    this.group = createSVG("g", {
      class: this.task.visible ? "bar-wrapper pointer" : "bar-wrapper",
      "data-id": this.task.id,
    });
    this.bar_group = createSVG("g", {
      class: "bar-group",
      append_to: this.group,
    });
    this.handle_group = createSVG("g", {
      class: "handle-group",
      append_to: this.group,
    });

    this.parallel_bars = [];
    this.sequence_bars = [];
    this.expected_progress = this.compute_expected_progress();
    this.completed = this.compute_completed()
  }

  prepare_helpers() {
    SVGElement.prototype.getX = function() {
      return +this.getAttribute("x");
    };
    SVGElement.prototype.getY = function() {
      return +this.getAttribute("y");
    };
    SVGElement.prototype.getWidth = function() {
      return +this.getAttribute("width");
    };
    SVGElement.prototype.getHeight = function() {
      return +this.getAttribute("height");
    };
    SVGElement.prototype.getEndX = function() {
      return this.getX() + this.getWidth();
    };
  }

  draw() {
    this.draw_bar();
    this.draw_progress_bar();
    this.draw_expected_progress();
    this.draw_label();
    this.draw_resize_handles();

    this.draw_left_prompt()
    this.draw_right_prompt()

    if(this.task.isGroup) {
      this.draw_group_borders()
    }
    this.draw_hide_button();
    this.draw_forecast();
  }

  draw_bar() {
    this.$bar = createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "bar",
      append_to: this.bar_group,
    });

    if (this.invalid) {
      this.$bar.classList.add("bar-invalid");
    }
  }

  draw_group_borders() {
    this.top_border = createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: this.x,
      y: this.y,
      width: this.width,
      height: 2,
      class: 'group-border',
      append_to: this.bar_group
    })
    this.left_border = createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: this.x + this.width - 2,
      y: this.y,
      width: 2,
      height: this.height + this.height / 2,
      class: 'group-border',
      append_to: this.bar_group
    })
    this.right_border = createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: this.x,
      y: this.y,
      width: 2,
      height: this.height + this.height / 2,
      class: 'group-border',
      append_to: this.bar_group
    })
  }

  draw_progress_bar() {
    if (this.invalid) return;
    this.$bar_progress = createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: this.x,
      y: this.y,
      width: this.progress_width,
      height: this.height,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "bar-progress",
      append_to: this.bar_group,
    });

    animateSVG(this.$bar_progress, "width", 0, this.progress_width);
  }

  draw_expected_progress() {
    this.$bar_expected_progress = createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      width: this.expected_progress * (this.width / 100),
      height: this.height,
      x: this.x,
      y: this.y,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "expected-progress",
      append_to: this.bar_group,
      fill: this.expected_progress > this.task.progress ? "url(#diagonalHatchRed)" : "url(#diagonalHatchGreen)",
    });

    this.$bar_expected_progress.$bar = this;

    if (this.expected_progress <= this.task.progress) {
      let init_width = parseFloat(
        this.$bar_expected_progress.getAttribute("width")
      );
      let init_x = parseFloat(this.$bar_expected_progress.getAttribute("x"));

      this.$bar_expected_progress.setAttribute("x", init_x + init_width);
      this.$bar_expected_progress.setAttribute(
        "width",
        this.$bar_progress.getAttribute("width") -
          this.$bar_expected_progress.getAttribute("width")
      );
    }

    if(this.completed && this.task.progress < 100) {
      this.$bar_expected_progress.setAttribute('width', this.$bar.getWidth())
      this.$bar_expected_progress.setAttribute('fill', "url(#diagonalHatchRed)")
    }
    else if(this.completed && this.task.progress >= 100) {
      this.$bar_expected_progress.setAttribute('width', this.$bar.getWidth())
      this.$bar_expected_progress.setAttribute('x', this.$bar.getX())
      this.$bar_expected_progress.setAttribute('fill', "url(#diagonalHatchGreen)")
    }
  }

  draw_label() {
    createSVG("text", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: this.x + this.width / 2,
      y: this.y + this.height / 2,
      innerHTML: this.task.name,
      class: "bar-label",
      append_to: this.bar_group,
    });
    requestAnimationFrame(() => this.update_label_position());
  }

  draw_resize_handles() {
    if (this.invalid) return;

    const bar = this.$bar;
    const handle_width = 8;

    createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: bar.getX() + bar.getWidth() - 9,
      y: bar.getY() + 1,
      width: handle_width,
      height: this.height - 2,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: this.task.visible ? "handle right resize" : "handle right",
      append_to: this.handle_group,
    });

    createSVG("rect", {
      visibility: this.task.visible ? "visible" : "hidden",
      x: bar.getX() + 1,
      y: bar.getY() + 1,
      width: handle_width,
      height: this.height - 2,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: this.task.visible ? "handle left resize" : "handle left",
      append_to: this.handle_group,
    });
  }

  draw_hide_button() {
    if(this.task.children.length === 0 ) return
    const bar = this.$bar
    this.$bar_hide_button = createSVG("image", {
      visibility: this.task.visible ? "visible" : "hidden",
      id: this.task.id,
      x: bar.getX() + bar.getWidth() + 7,
      y: bar.getY(),
      rx: 3,
      ry: 3,
      width: 24,
      height: this.height,
      class: "hide-button",
      href: this.gantt.get_task(this.task.dependencies[0].task.uuid).visible ? '/img/project/tools/show-outline.svg' : '/img/project/tools/show-off-outline.svg',
      append_to: this.gantt.layers.grid
    })

    this.$bar_hide_button.addEventListener('click', () => {
      let init_scroll = this.gantt.$svg.parentElement.scrollLeft
      
      if(this.gantt.get_task(this.task.dependencies[0].task.uuid).visible) {
        this.hide_dependence_bars(this.task)
      }

      else {
        this.show_dependence_bars(this.task)
      }
      
      this.gantt.refresh(this.gantt.tasks)

      // TODO Переделать
      this.gantt.tasks.map(task => {
        let dates = this.gantt.get_bar(task.id).compute_start_end_date()
        task.start = dates.new_start_date
        task.end = dates.new_end_date
        task._start = dates.new_start_date
        task._end = dates.new_end_date
      })

      this.gantt.$svg.parentElement.scrollLeft = init_scroll
    })
  }

  draw_forecast() {
    this.forecast = createSVG('rect', {
      visibility: "visible",
      x: this.$bar.getEndX(),
      y: this.$bar.getY(),
      rx: 3,
      ry: 3,
      width: 0,
      height: this.height,
      class: 'forecast',
      append_to: this.gantt.layers.grid
    })

    this.forecast.onmousemove = () => {
      clearTimeout(this.forecastTimeout);
      this.forecastTimeout = setTimeout(() => {
        console.log(`Прогнозируемая дата начала: ${this.gantt.dates[((this.forecast.getEndX() - this.$bar.getWidth()) / this.gantt.options.column_width)]}\nПрогнозируемая дата окончания: ${this.gantt.dates[(this.forecast.getEndX() / this.gantt.options.column_width) - 1]}`)
      }, 400)
    };

    this.forecast.onmouseleave = () => {
      clearTimeout(this.forecastTimeout)
    };

    let todayIndex = this.gantt.dates.findIndex(date => date.getTime() === date_utils.today().getTime()) + 1;
    let todayX = (todayIndex > 0 ? todayIndex : this.gantt.dates.length) * this.gantt.options.column_width;
    let calculatedWidth = todayX - this.forecast.getAttribute('x')

    if(calculatedWidth < 0) {
      calculatedWidth = calculatedWidth * (-1)
    }

    this.forecast.setAttribute('width', calculatedWidth) 
    
    
    this.forecast.setAttribute('visibility', this.task.progress < this.expected_progress ? "visible" : "hidden")
  }

  draw_left_prompt() {
    const bar = this.$bar
    this.$left_prompt = createSVG("rect", {
      visibility: "hidden",
      x: bar.getX() - this.gantt.options.column_width / 2,
      y: bar.getY(),
      width: this.gantt.options.column_width / 2,
      height: this.height,
      class: "pulse-prompt",
      append_to: this.gantt.layers.grid
    })
  }

  draw_right_prompt() {
    const bar = this.$bar
    this.$right_prompt = createSVG("rect", {
      visibility: "hidden",
      x: bar.getX() + bar.getWidth(),
      y: bar.getY(),
      width: this.gantt.options.column_width / 2,
      height: this.height,
      class: "pulse-prompt",
      append_to: this.gantt.layers.grid
    })
  }

  hide_dependence_bars(task) {
    task.dependencies.map(dependence => {
      let hidden = this.gantt.set_task_visible(dependence.task.uuid, false)
      this.hide_dependence_bars(hidden)
    })
  }

  show_dependence_bars(task) {
    task.dependencies.map(dependence => {
      let hidden = this.gantt.set_task_visible(dependence.task.uuid, true)
      this.show_dependence_bars(hidden)
    })
  }

  get_progress_polygon_points() {
    const bar_progress = this.$bar_progress;
    return [
      bar_progress.getEndX() - 5,
      bar_progress.getY() + bar_progress.getHeight(),
      bar_progress.getEndX() + 5,
      bar_progress.getY() + bar_progress.getHeight(),
      bar_progress.getEndX(),
      bar_progress.getY() + bar_progress.getHeight() - 8.66,
    ];
  }

  bind() {
    if (this.invalid) return;
    this.setup_click_event();
  }

  setup_click_event() {
    $.on(this.group, "focus " + this.gantt.options.popup_trigger, () => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      this.show_popup();
      this.gantt.unselect_all();
      this.group.classList.add("active");
    });

    $.on(this.group, "dblclick", () => {
      // if (this.action_completed) {
      //   // just finished a move action, wait for a few seconds
      //   return;
      // }

      this.gantt.trigger_event("click", [this.task]);
    });
  }

  show_popup() {
    if (this.gantt.bar_being_dragged) return;

    const start_date = date_utils.format(
      this.task._start,
      "MMM D",
      this.gantt.options.language
    );
    const end_date = date_utils.format(
      date_utils.add(this.task._end, -1, "second"),
      "MMM D",
      this.gantt.options.language
    );
    const subtitle = start_date + " - " + end_date;

    this.gantt.show_popup({
      target_element: this.$bar,
      title: this.task.name,
      subtitle: subtitle,
      task: this.task,
    });
  }

  update_bar_position({ x = null, width = null }) {
    const bar = this.$bar;

    // TODO Подсчитывать один раз
    this.min_x = this.compute_min_x()
    this.min_width = this.compute_min_width()
    this.dependence_bars = this.dependence_bars || this.get_dependence_bars(this)
    // x >= this.min_x
    if(x) {
      this.update_attr(bar, "x", x)
    }
    
    if(width && width >= this.min_width ) {
      this.update_attr(bar, "width", width)
    }
    
    this.update_sequence_bars(this)
    // this.update_parallel_bars(this)
    this.update_children(this)

    if(this.parent_bar) {
      this.update_parent(this.parent_bar)
    }

    this.update_all()
  }

  compute_min_x() {
    let min_x = 0
    if(this.parent_bar) {
      min_x = this.previous_bar?.x + this.previous_bar?.width || this.parent_bar.x
    }
    return min_x
  }

  compute_min_width() {
    let min_width = this.gantt.options.column_width
    if(this.task.isGroup || this.dependence_bars.length > 0) {
      min_width = this.get_last_sub_bar().$bar.getEndX() - this.get_first_sub_bar().$bar.getX()
    }
    return min_width
  }

  get_dependence_bars(bar) {
    let dependence_bars = []

    bar.task.children.map(child_task => {
      let dependence_bar = this.gantt.get_bar(child_task.id || child_task.uuid)
      dependence_bars.push(dependence_bar)
    })

    return dependence_bars
  }

  update_parent(parent_bar) {
    let parent_$bar = parent_bar.$bar
    parent_bar.update_attr(parent_$bar, "x", parent_bar.get_first_sub_bar().$bar.getX())

    if(parent_$bar.getEndX() < this.$bar.getEndX()) {
      parent_bar.update_attr(parent_$bar, "width", 
        this.$bar.getEndX() - parent_bar.get_first_sub_bar().$bar.getX()
      )
    }
    
    parent_bar.update_all()

    if(parent_bar.parent_bar) {
      parent_bar.update_parent(parent_bar.parent_bar)
    }

    // parent_bar.update_children(parent_bar)
    parent_bar.update_sequence_bars(parent_bar)
  }

  update_children(bar) {
    bar.dependence_bars.map(dependence_bar => {
      let offset = dependence_bar.x - bar.x
      dependence_bar.update_attr(dependence_bar.$bar, "x", bar.$bar.getX() + offset)
      dependence_bar.update_all()
      dependence_bar.update_children(dependence_bar)
    })
  }

  update_sequence_bars(bar) {
    bar.sequence_bars.map(sequence => {
      let offset = sequence.x - (bar.x + bar.width)
      sequence.update_attr(sequence.$bar, "x", bar.$bar.getEndX() + offset)
      sequence.update_all()
      sequence.update_children(sequence)
      sequence.update_parent(sequence.parent_bar)
    })
  }

  // update_parallel_bars(bar) {
  //   bar.parallel_bars.map(parallel => {
  //     let offset = parallel.x - (bar.x + bar.width)
  //     parallel.update_attr(parallel.$bar, "x", bar.$bar.getEndX() + offset)
  //     parallel.update_all()
  //     parallel.update_children(parallel)
  //   })
  // }

  update_all() {
    this.update_label_position();
    this.update_handle_position();
    this.update_progressbar_position();
    this.update_arrow_position();
    this.update_expected_progress_position();
    this.update_hide_button()
    this.update_border_position()

    this.update_left_prompt()
    this.update_right_prompt()
  }

  get_last_sub_bar() {
    return this.dependence_bars.sort((a, b) => (b.$bar.getX() + b.$bar.getWidth()) - (a.$bar.getX() + a.$bar.getWidth()))[0]
  }

  get_first_sub_bar() {
    return this.dependence_bars.sort((a, b) => a.$bar.getX() - b.$bar.getX())[0]
  }

  date_changed() {
    let changed = false;
    const { new_start_date, new_end_date } = this.compute_start_end_date();

    if (Number(this.task._start) !== Number(new_start_date)) {
      changed = true;
      this.task._start = new_start_date;
    }

    if (Number(this.task._end) !== Number(new_end_date)) {
      changed = true;
      this.task._end = new_end_date;
    }

    this.task.start = new_start_date
    this.task.end = new_end_date

    if (!changed) {
      return;
    }

    this.gantt.trigger_event("date_change", [
      this.task,
      new_start_date,
      date_utils.add(new_end_date, -1, "second"),
    ]);
  }

  progress_changed() {
    const new_progress = this.compute_progress();
    this.task.progress = new_progress;
    this.gantt.trigger_event("progress_change", [this.task, new_progress]);
  }

  set_action_completed() {
    this.x = this.$bar.getX()
    this.width = this.$bar.getWidth()

    this.action_completed = true;
    setTimeout(() => (this.action_completed = false), 1000);
  }

  compute_start_end_date() {
    const bar = this.$bar;
    const rounding = 0.4

    let x_in_units = bar.getX() / this.gantt.options.column_width;
    
    let x_remainder = x_in_units % 1
    x_in_units = x_remainder >= rounding ? x_in_units + (1 - x_remainder) : x_in_units - x_remainder

    let new_start_date = date_utils.add(
      this.gantt.gantt_start,
      x_in_units * this.gantt.options.step,
      "hour"
    );

    let width_in_units = bar.getWidth() / this.gantt.options.column_width
    
    let width_remainder = width_in_units % 1
    width_in_units = width_remainder >= rounding ? width_in_units + (1 - width_remainder) : width_in_units - width_remainder

    if(x_remainder >= rounding && width_remainder >= rounding) {
      width_in_units -= 1
    }

    this.update_attr(bar, "x", x_in_units * this.gantt.options.column_width)
    this.update_attr(bar, "width", width_in_units * this.gantt.options.column_width)
    this.update_all()

    let new_end_date = date_utils.add(
      new_start_date,
      width_in_units * this.gantt.options.step,
      "hour"
    );

    return { new_start_date, new_end_date };
  }

  compute_progress() {
    const progress =
      (this.$bar_progress.getWidth() / this.$bar.getWidth()) * 100;
    return parseInt(progress, 10);
  }

  compute_expected_progress() {
    let expected_progress = 0;

    let start_day = new Date(this.task.start).getTime();
    let end_day = new Date(this.task.end).getTime();

    let working_days_count = (end_day - start_day) / 86400000;
    let progress_per_day = 100 / working_days_count

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (today.getTime() >= start_day && today.getTime() <= end_day) {
      let gone_days = Math.round((today.getTime() - start_day) / 86400000)
      expected_progress = progress_per_day * gone_days;
    }

    if(today.getTime() > end_day){
      expected_progress = 100
    }

    return expected_progress;
  }

  compute_completed() {
    let today = new Date()
    today.setHours(0, 0, 0, 0)

    if(today.getTime() > new Date(this.task.end).getTime()) {
      return true
    }
    return false
  }

  compute_x() {
    const { step, column_width } = this.gantt.options;
    const task_start = this.task._start;
    const gantt_start = this.gantt.gantt_start;

    const diff = date_utils.diff(task_start, gantt_start, "hour");
    let x = (diff / step) * column_width;

    if (this.gantt.view_is("Month")) {
      const diff = date_utils.diff(task_start, gantt_start, "day");
      x = (diff * column_width) / 30;
    }
    return x;
  }

  compute_y() {
    return (
      this.gantt.options.header_height +
      this.gantt.options.padding +
      this.task._index * (this.height + this.gantt.options.padding)
    );
  }

  get_snap_position(dx) {
    let odx = dx,
      rem,
      position;

    if (this.gantt.view_is("Week")) {
      rem = dx % (this.gantt.options.column_width / 7);
      position =
        odx -
        rem +
        (rem < this.gantt.options.column_width / 14
          ? 0
          : this.gantt.options.column_width / 7);
    } else if (this.gantt.view_is("Month")) {
      rem = dx % (this.gantt.options.column_width / 30);
      position =
        odx -
        rem +
        (rem < this.gantt.options.column_width / 60
          ? 0
          : this.gantt.options.column_width / 30);
    } else {
      rem = dx % this.gantt.options.column_width;
      position =
        odx -
        rem +
        (rem < this.gantt.options.column_width / 2
          ? 0
          : this.gantt.options.column_width);
    }
    return position;
  }

  update_attr(element, attr, value) {
    value = +value;
    if (!isNaN(value)) {
      element.setAttribute(attr, value);
    }
    return element;
  }

  update_progressbar_position() {
    this.$bar_progress.setAttribute("x", this.$bar.getX());
    this.$bar_progress.setAttribute(
      "width",
      this.$bar.getWidth() * (this.task.progress / 100)
    );
  }

  update_expected_progress_position() {
    const bar = this.$bar_expected_progress;
    this.update_attr(bar, "x", this.$bar.getX());
    this.update_attr(bar, "width", this.expected_progress / 100 * this.$bar.getWidth())
  }

  update_label_position() {
    const bar = this.$bar,
      label = this.group.querySelector(".bar-label");

    if (label.getBBox().width > bar.getWidth()) {
      label.classList.add("big");
      label.setAttribute("x", bar.getX() + bar.getWidth() + 5);
      if(this.task.children.length > 0) {
        label.setAttribute("x", label.getX() + 33)
      }
    } else {
      label.classList.remove("big");
      label.setAttribute("x", bar.getX() + bar.getWidth() / 2);
    }
  }

  // update_rounding_prompt_position() {
  //   const bar = this.$bar
  //   if(bar.getEndX() % this.gantt.options.column_width >= this.gantt.options.column_width / 2) {
  //     this.update_attr(this.$prompt, "width", this.gantt.options.column_width / 2)
  //     this.update_attr(this.$prompt, "x",  bar.getEndX() - (this.gantt.options.column_width - bar.getEndX() % this.gantt.options.column_width))
  //     this.$prompt.setAttribute('visibility', 'visible')
  //   }
  //   else if(bar.getX() % this.gantt.options.column_width <= this.gantt.options.column_width / 2) {
  //     this.update_attr(this.$prompt, "width", this.gantt.options.column_width / 2)
  //     this.update_attr(this.$prompt, "x", bar.getX() - bar.getX() % this.gantt.options.column_width)
  //     this.$prompt.setAttribute('visibility', 'visible')
  //   }
  //   else {
  //     this.$prompt.setAttribute('visibility', 'hidden')
  //   }
  // }

  update_right_prompt() {
    const bar = this.$bar
    const rounding = 0.4

    let width_in_units = bar.getEndX() / this.gantt.options.column_width
    let width_remainder = width_in_units % 1

    if(width_remainder >= rounding && this.task.visible) {
      this.$right_prompt.setAttribute('visibility', 'visible')
      
      let width_remainder = this.gantt.options.column_width - bar.getEndX() % this.gantt.options.column_width
      this.update_attr(this.$right_prompt, "x", bar.getEndX())
      this.update_attr(this.$right_prompt, "width", width_remainder)
    }
    else {
      this.$right_prompt.setAttribute('visibility', 'hidden')
    }
  }

  update_left_prompt() {
    const bar = this.$bar
    const rounding = 0.4

    let x_in_units = bar.getX() / this.gantt.options.column_width
    let x_remainder = x_in_units % 1

    if(x_remainder <= rounding && this.task.visible) {
      this.$left_prompt.setAttribute('visibility', 'visible')
      
      this.update_attr(this.$left_prompt, "width", bar.getX() % this.gantt.options.column_width)
      this.update_attr(this.$left_prompt, "x", bar.getX() - this.$left_prompt.getWidth())
    }
    else {
      this.$left_prompt.setAttribute('visibility', 'hidden')
    }
  }

  update_hide_button() {
    if(this.$bar_hide_button) { 
      this.update_attr(this.$bar_hide_button, "x", this.$bar.getX() + this.$bar.getWidth() + 7)
    }
  }

  update_handle_position() {
    const bar = this.$bar;
    this.handle_group
      .querySelector(".handle.left")
      .setAttribute("x", bar.getX() + 1);
    this.handle_group
      .querySelector(".handle.right")
      .setAttribute("x", bar.getEndX() - 9);
    const handle = this.group.querySelector(".handle.progress");
    handle && handle.setAttribute("points", this.get_progress_polygon_points());
  }

  update_arrow_position() {
    this.arrows = this.arrows || [];
    for (let arrow of this.arrows) {
      arrow.update();
    }
  }

  update_border_position() {
    const bar = this.$bar
    if(this.task.isGroup) {
      this.update_attr(this.top_border, "width", bar.getWidth())
      this.update_attr(this.top_border, "x", bar.getX())

      this.update_attr(this.left_border, "x", bar.getEndX() - 2)
      this.update_attr(this.right_border, "x", bar.getX())
    }
  }
}
