import { XeokitMediator } from '../XeokitMediator'
import { Mesh, ReadableGeometry, buildBoxGeometry, PhongMaterial, Node, VBOGeometry, buildSphereGeometry, buildCylinderGeometry } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'
import { useSelectedElementsStore } from '@/pinia'
//import { position } from "html2canvas/dist/types/css/property-descriptors/position";

const elementsStore = useSelectedElementsStore()

export class ObjectsDrawable {
  constructor() {
    this.meshMap = new Map()
  }

  get viewer() {
    return XeokitMediator.viewer
  }

  drawCube(id, isObject, position, scale, offset, rotation, opacity, diffuse, xSize, ySize, zSize) {
    const mesh = new Mesh(this.viewer.scene, {
      id: id,
      isObject: isObject,
      position: position,
      scale: scale,
      offset: offset,
      rotation: rotation,
      opacity: opacity,
      material: new PhongMaterial(this.viewer.scene, {
        diffuse: diffuse,
      }),
      geometry: new ReadableGeometry(
        this.viewer.scene,
        buildBoxGeometry({
          xSize: xSize,
          ySize: ySize,
          zSize: zSize,
        })
      ),
    })

    this.meshMap.set(id, mesh)
    elementsStore.setSelectedBoxElements(mesh.id)
    return mesh
  }

  drawKeepers(id, isObject, position, scale, rotation, diffuse) {
    const node = new Node(this.viewer.scene, {
      id: id,
      isObject: isObject,
      scale: scale,
      rotation: rotation,
      position: position,
      children: [
        this.drawWheel(diffuse),
        this.drawCone(diffuse)
      ]
    }) 

    return node
  }

  drawWheel(diffuse) {
    const mesh = new Mesh(this.viewer.scene, {
      isObject: true,
      scale: [1, 1, 1],
      rotation: [90, 0, 0],
      position: [0, 0.95, 0],
      material: new PhongMaterial(this.viewer.scene, {
        diffuse: diffuse,
      }),
      geometry: new VBOGeometry(XeokitMediator.viewer.scene, buildSphereGeometry({radius: 0.3})),
    })
    // this.meshMap.set(id, mesh)

    elementsStore.setPickedKeeperHead(mesh.id)
    return mesh
  }

  drawCone() {
    const cylinder = new Mesh(this.viewer.scene, {
      isObject: true,
      scale: [0.5, 1.5, 0.5],
      rotation: [0, 0, 0],
      material: new PhongMaterial(this.viewer.scene, {
        diffuse: [0.07, 0.74, 0.07],
      }),
      geometry: new VBOGeometry(XeokitMediator.viewer.scene, buildCylinderGeometry({
        radiusTop: 0.6,
        radiusBottom: 0.1,
        height: 0.85,
      })),
    })
    // this.meshMap.set(id, cylinder)
    
    elementsStore.setPickedKeeperBody(cylinder.id)
    return cylinder
  }

  clearMap() {
    this.meshMap.clear()
  }

  updateScaleMesh(id, scale) {
    this.meshMap.get(id).scale = scale
  }

  updateDiffuseMesh(id, diffuse) {
    this.meshMap.get(id).diffuse = diffuse
  }

  updatePositionMesh(id, position) {
    this.meshMap.get(id).position = position
  }

  deleteMesh(id) {
    this.meshMap.delete(id)
  }

  updateAll(scale, id, diffuse, position) {
    this.meshMap.get(id).position = position
    this.meshMap.get(id).diffuse = diffuse
    this.meshMap.get(id).scale = scale
  }

  updateMesh(id, mesh) {
    this.meshMap.set(id, mesh)
  }

  getDiffuse(id) {
    if (this.viewer?.scene?.objects[id]?.material?.diffuse) {
      return this.viewer.scene.objects[id].material.diffuse
    }
  }

  setDiffuse(id, diffuse) {
    if (this.viewer?.scene?.objects[id]?.material?.diffuse) {
      this.viewer.scene.objects[id].material.diffuse = diffuse
    }
  }

  getPosition(id) {
    if (this.viewer?.scene?.objects[id]?.position) {
      return this.viewer.scene.objects[id].position
    }
  }

  setPosition(id, position) {
    if (this.viewer?.scene?.objects[id]?.position) {
      this.viewer.scene.objects[id].position = position
    }
  }

  getOpacity(id) {
    if (this.viewer?.scene?.objects[id]?.opacity) {
      return this.viewer.scene.objects[id].opacity
    }
  }

  setOpacity(id, opacity) {
    if (this.viewer?.scene?.objects[id]?.opacity) {
      this.viewer.scene.objects[id].opacity = opacity
    }
  }

  callDestroy(id) {
    XeokitMediator.viewer?.scene?.objects[id]?.destroy()
  }
}

// ############## для создания кубика и шара во Vue компоненте  ##############

// mounted() {
//     this.initXeokit();
//   },

//   methods: {

//     initXeokit() {
//       const canvas = this.$refs.xeokitCanvas;

//       canvas.width = window.innerWidth;
//       canvas.height = window.innerHeight;

//       window.onresize = () => {
//         canvas.width = window.innerWidth;
//         canvas.height = window.innerHeight;
//       };

//       this.viewer = new Viewer({
//         canvasElement: canvas,
//       });

//       this.xktLoaderPlugin = new XKTLoaderPlugin(this.viewer);
//     },

//     addSphere() {
//       // Создаем геометрию сферы
//       buildSphereGeometry
//       const sphereGeometry = new ReadableGeometry(this.viewer.scene, buildSphereGeometry({
//         center: [0, 0, 0],
//         radius: 2,
//       }));

//       // Создаем материал сферы
//       const sphereMaterial = new PhongMaterial(this.viewer.scene, {
//         diffuse: [1, 0, 0] // Цвет сферы (красный)
//       });

//       // Создаем сферу
//       const sphereEntity = new Mesh(this.viewer.scene, {
//         geometry: sphereGeometry,
//         material: sphereMaterial
//       });

//       // Добавляем сферу к сцене
//       // this.viewer.scene.addEntity(sphereEntity);
//     },

//     createPlanka() {
//       // Создаем геометрию куба
//       const boxGeometry = new ReadableGeometry(this.viewer.scene, buildBoxGeometry(ReadableGeometry, this.viewer.scene, {
//         xSize: 0.1,
//         ySize: 0.3,  // Высота куба
//         zSize: 0.1
//       }));

//       // Создаем объект Mesh (куб)
//       const redTableLeg = new Mesh(this.viewer.scene, {
//         // id: "redLeg",  // Уникальный идентификатор для объекта Mesh
//         // isObject: true,
//         position: [1, 1, 1],
//         scale: [1, 1, 1],
//         rotation: [0, 0, 0],
//         geometry: boxGeometry,
//         material: new PhongMaterial(this.viewer.scene, {
//           diffuse: [1, 0.3, 0.3]
//         })
//       });

//       // Добавляем куб в сцену
//       // this.viewer.scene.add(redTableLeg);
//     },
