<template lang='pug'>
task-table( :tasks='supremeTasks' :headers='treeHeaders' )
</template>

<script>
import { mapState } from 'vuex'
import TaskTable from '@/components/task/taskTree/TaskTable.vue'

export default {
  name: 'TaskTree',

  components: {
    TaskTable,
  },

  computed: {
    ...mapState('task', ['tasks', 'treeHeaders']),

    supremeTasks() {
      // return this.tasks.filter(task => task.level === 0)
      return this.tasks
    },
  },
}
</script>

<style>

</style>