<template lang="pug">
  app-panel.pt-1.px-1.py-2( ident='workspaces' )
    task-header.mb-2( :title="$t('toolbarMenu.workspaces')" headerItemsCenter mainTitle )
      template( v-slot:headerAfter )
        app-icon-button( icon="mdi-close" @click="handleClose" test-id="close-app-panel" )
    app-section.pa-0.pb-4.floor-unit-container.overflow-auto.base-scroll-body
      .d-flex.align-center(style="background-color: #E5E5E5; color: grey; font: normal 13px/16px Roboto, sans-serif; height: 50px; border-radius: 5px")
        span.d-inline-flex.ml-3.header-name {{ $t('floor.name') }}
        app-icon-button.ml-auto.mr-5( icon="mdi-plus" buttonSize="small" buttonColor="var(--v-accent-base)" iconColor="white"  @click="addWorkspace" )
      div
        div.pt-1
          //- app-hover-item.mt-2(:item="$t('module.task.baseWorkspace')" @menu="showMenu") 
          app-hover-item.mt-2( 
            v-for="ws in workspaceList" 
            :key="ws.uuid"
            :item="ws" 
            @select="selectWorkSpace(ws)" 
            @menu="showMenu" 
            isWorkspaces
            )

    app-menu( ref="workspaceMenu" :menu="workspaceMenu" )
    
    v-form(@submit.prevent)
      app-dialog(v-model="dialog.saveWorkspace" ref="saveWorkspace" :header="$t('toolbarMenu.workspaces')" @confirm="save()" @close="refreshData()" :confirmDisabled="workspaceTitle" :confirmText="$t('button.save')" width="430")
        app-section
          app-text-field.my-2(v-if="workspace.title === '{module.task.baseWorkspace}'" disabled :label="$t('floor.name')" hide-details type="workspace.title" :value="getTitle(workspace.title)")
          app-text-field.my-2(v-else v-model="dialog.workspaceTitle" :label="$t('floor.name')" hide-details type="workspace.title")
          app-select.my-2(v-model="dialog.selectedPlugin" :label="$t('module.task.workspacePlugin')" :items="bpPluginsForWorkspace" itemText="pluginName" returnObject clearable)

    app-dialog-confirm(v-model="dialog.delWorkspace" ref="delWorkspace" @confirm="delWorkspace(workspace)")
      app-text {{ $t('toolbarMenu.deleteSpace', {title: workspace.title}) }}

    app-dialog-confirm(v-model="dialog.restoreWorkspace" ref="restoreWorkspace" @confirm="restoreWorkspace(workspace.uuid)")
      app-text.pt-5 {{ $t('toolbarMenu.restoreSpace', {title: workspace.title}) }}

</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex' 
import TaskHeader from '@/components/task/TaskHeader'
import AppHoverItem from '@/components/project/common/HoverItem.vue'

export default {
  data () {
    const defaultWorkspace = Object.freeze({
          uuid: null,
          title: null,
          project: null,
          plugin: null,
          archived: null,
        })

    return {
      dialog: {
        saveWorkspace: false,
        delWorkspace: false,
        restoreWorkspace: false,

        isBaseWorkspace: false,
        workspaceTitle: null,
        selectedPlugin: null,
      },
      workspace: Object.assign({}, defaultWorkspace),
      defaultWorkspace,
    }
  }, 

  components: {
    TaskHeader,
    AppHoverItem
  },

  mounted () {
    this.$store.dispatch('workspace/INIT')
    this.LOAD_PLUGINS(this.projectUuid)
  },
  
  computed: {
    ...mapGetters("project", [ "projectUuid" ]),
    ...mapState("project", ["projectSettings"]),
    ...mapGetters("workspace", [ "workspacesAll", "isWorkspacesLoaded" ]),

    ...mapGetters("plugins", ['bpPluginsForWorkspace']),

    workspaceList() {
      let base = this.workspacesAll.find(e => e.title === '{module.task.baseWorkspace}')
      let list = []
      if (!base) {
        let defaultWorkspace = Object.assign({}, this.defaultWorkspace)
        defaultWorkspace.title = '{module.task.baseWorkspace}'
        defaultWorkspace.project = this.projectUuid
        list.push(defaultWorkspace)
      }

      list = [...list, ...this.workspacesAll]
      return list
    },
    
    workspaceTitle() { 
      if(!this.dialog.workspaceTitle) {
        return !this.dialog.workspaceTitle
      } else {
        return !this.dialog.workspaceTitle.trim()
      }
    },

    workspaceMenu () {
      if(this.workspace && this.workspace.uuid != null && this.workspace.title != '{module.task.baseWorkspace}') {
        let items = []
        if (this.workspace.archived) items.push({ title: this.$t('button.restore'), action: () => this.$refs.restoreWorkspace.show() })
        else {
          items.push({ title: this.$t('button.edit'), action: () => this.showEditDialog() })
          items.push({ title: this.$t('button.toArch'), action: () => this.$refs.delWorkspace.show()})
        }
        return items
      }
      else
        return [
          { title: this.$t('button.edit'), action: () => this.showEditDialog() }
        ]
    },

  },

  methods: {
    ...mapActions('workspace', ['saveWorkspace', 'deleteWorkspace', 'restoreWorkspace']),
    ...mapMutations('project', ['setActiveGlobalTab', 'setTopUnit']),
    ...mapActions('project', ['updateSettings', 'postPanelsStatus']),
    ...mapMutations('task', ['setSelectedTask', 'setTasks', 'setTaskIsLoad']),
    ...mapActions('plugins', ['LOAD_PLUGINS']),

    delWorkspace(item) {
      this.deleteWorkspace(item.uuid).then(() => {
        if (this.projectSettings.workSpace == item.uuid) {
          let base = this.workspacesAll.find(e => e.title === '{module.task.baseWorkspace}')
          let ps = JSON.parse(JSON.stringify(this.projectSettings))
          ps.workSpace = base ? base.uuid : null
          this.setTaskIsLoad(false)
          this.setTasks([])
          this.setSelectedTask(null)
          this.setTopUnit('')
          this.updateSettings(ps)
        }
      })
    },

    showMenu (item, event) {
      if (item.title === '{module.task.baseWorkspace}')
        this.dialog.isBaseWorkspace = true
      this.workspace = item
      this.$refs.workspaceMenu.show(event)
    },

    selectWorkSpace (ws) {
      this.workspace = ws
    }, 

    addWorkspace () {
      this.workspace = JSON.parse(JSON.stringify(this.defaultWorkspace))
      this.workspace.project = this.projectUuid
      this.dialog.workspaceTitle = null
      this.$refs.saveWorkspace.show()
    },

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },

    showEditDialog() {
      this.dialog.workspaceTitle = this.workspace?.title
      this.dialog.selectedPlugin = this.workspace.plugin
      this.$refs.saveWorkspace.show()
    },

    save() {
      this.workspace.plugin = this.dialog.selectedPlugin
      this.workspace.title = this.dialog.workspaceTitle
      this.saveWorkspace(this.workspace).then(data => {
        this.refreshData()
        if (data.title === "{module.task.baseWorkspace}")
          this.updateProjectSettings(data)
      })
    },

    refreshData() {
      this.dialog.isBaseWorkspace = false
      this.dialog.selectedPlugin = null
      this.workspace = Object.assign({}, this.defaultWorkspace)
    },

    updateProjectSettings(workspace) {
      let ps = JSON.parse(JSON.stringify(this.projectSettings))
      if (!ps.workSpace) {
        ps.workSpace = workspace.uuid
        this.$store.dispatch('project/updateSettings', ps)
      }
    },

    getTitle(title) {
      return this.$t(title.replace("{", "").replace("}", ""))
    },
  },
}
</script>

<style scoped lang="scss">
.floor-unit-container {
  max-height: calc(100% - 32px);
}
.header-name {
  width: 88px;
}
</style>
      