<template lang='pug'>
app-panel.pl-2( padding="0px" )
  app-floating-panels-section( style="overflow: auto; overflow-x: hidden" )
    app-floating-panel( :title="schema ? schema.title : ''" bodyMinHeight="150" ref="wormRuleEditForm" overflowBody="hidden" :settings="floatingPanels((floatingPanelsKeys && floatingPanelsKeys.WORM_RULE_EDIT) || 'WORM_RULE_EDTI')")
      template( v-slot:headerButton )
        //- v-btn( small @click.stop="closeMnemoList")
        //- v-icon mdi-fullscreen
        v-btn(icon @click.stop="closeMnemoList")
          v-icon(color= "#757575") mdi-fullscreen
        v-btn(icon @click.stop="closeGraph")
          v-icon(color='#757575') mdi-close
        //- app-header-action( icon="fullscreen.svg" @click.stop="closeMnemoList" )
        //- app-header-action( icon="close.svg" @click.stop="closeGraph" )
          //- v-btn.exitGrapghViewer(color='accent' fab x-small @click.stop="closeGraph")
          //-   v-icon mdi-close
      div.graph-viewer-container( v-if="schema")
        //- GraphViewerHeader(@closeGraphViewer="closeGraph" @closeMnemoschemaList="closeMnemoList")
        div.g-viewer( v-if="schema")
          iframe#graphViewer( v-if="schema && schema.schema" :src='graphViewerSrc' frameBorder='0')

          iframe#graphViewer( v-else frameBorder='0')
          div.openMnemoschemaEditor( v-if="!schema.schema")
            v-btn( @click="openGraphEditor" color="accent")
              v-icon mdi-developer-board
            span {{ $t('module.mnemoschemas.openEditor') }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

const graphViewerSrc = '/graph/examples/grapheditor/www/noo_viewer.html'

import GraphViewerHeader from "./graphViewerApp/GrapgViewerHeader"
export default {
  name: "GraphViewer",

  props: {
    schema: Object,
  },

  components: {
    GraphViewerHeader
  },

  data() {
    return {
      selectedSchema: null,
      graphViewerSrc
    }
  },
  
  beforeMount() {
    if(this.schema){
      this.selectedSchema = this.schema
    }
    
  },

  watch:{
    schema(){
      if(this.schema && this.schema.schema){
        this.setSchema(this.schema)
      }
    }
  },
  mounted() {
    if(this.schema && this.schema.schema){
      window.onmessage = ({data}) => {
        if (data.type === 'viewer_ready') {
          this.setSchema(this.schema)
        }
      }
    }
  },
  computed:{
    ...mapGetters('project', ['projectUuid']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),


  },
  methods: {
    
    setSchema(schema) {
      const viewerIframe = document.querySelector('#graphViewer')
      if(viewerIframe){
        viewerIframe.contentWindow.postMessage({
        type: 'set_schema',
        schema: schema.schema
      })
      }
      
    },
    
    closeGraph(){
      this.$emit('coverGraphViewer')
    },

    closeMnemoList(){
      this.$emit('coverMnemoList')
    },
    
    openGraphEditor() {
      window.open(`/mnemo/project/${this.projectUuid}/schema/${this.schema.uuid}`)
    },
  }
}
</script>

<style lang='scss' scoped>
  .graph-viewer-container {
    position: relative;
    display: flex;
    flex: 1;
    // width: 100%;
  }
  .sidebar {
    width: 250px;
    height: 100%;
    padding: 4px;
    overflow: auto;
    display: flex;
  }
  .g-viewer {
    flex: 1;
    display: flex;

    & iframe {
      flex: 1;
    }
  }
  
  
  .icon {
    fill: red;
  }

  #graphViewer{
    background: 0%;
    }
  .openMnemoschemaEditor{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
  }
</style>