<template lang="pug">

  div.ma-2
    template(v-if="equipment && equipment.uuid != null")
      template(v-if="equipment.equipmentSpecification")
        app-text {{ $t('section.equipment.details.name') }}: {{ equipment.name }}
        app-text {{ $t('corp.typeEquipment') }}: {{ equipment.equipmentSpecification.name }}
        app-text {{ $t('section.equipment.details.model') }}: {{ equipment.model }}
        app-text {{ $t('section.equipment.details.manufacturer') }}: {{ equipment.manufacturer }}
        app-text {{ $t('section.equipment.details.resourceWorked', {name: equipment.equipmentSpecification.resourceMonths, worked: worked}) }}
        app-text {{ $t('section.equipment.details.warranty', {name: equipment.equipmentSpecification.warrantyMonths, lostWarranty: lostWarranty}) }}

      app-text {{ $t('section.equipment.details.yearProduction') }}: {{ equipment.productionYear }}
      app-text {{ $t('section.equipment.details.warrantyStartDate') }}: {{ equipment.warrantyStartDate }}
      app-text {{ $t('section.equipment.details.startDateOperation') }}: {{ equipment.operationStartDate }}
      app-text {{ $t('section.equipment.details.serialNumber') }}: {{ equipment.serialNumber }}
      app-text {{ $t('section.equipment.details.registrationNumber') }}: {{ equipment.reestrNumber }}
      app-text {{ $t('section.equipment.details.installationLocation') }}: {{ equipment.location }}

      v-btn.dict-button.mt-2(v-if="!showGoto" color="accent" dense text x-small @click="toggleLink = !toggleLink" ) {{ $t('section.equipment.details.linkedModelElements') }}
        v-icon(v-if="!toggleLink" small).pl-1 mdi-menu-down
        v-icon(v-if="toggleLink" small).pl-1 mdi-menu-up
      app-section.mb-4(v-if="toggleLink")          
        v-row(no-gutters)
          v-col.ml-2
            app-text {{ $t('section.equipment.details.linkedElements') }}: {{ countLink }}
            app-text {{ $t('section.equipment.details.selectedElements') }}: {{ countSelect }}
        v-row(v-if="hasMaintainceBindingElements" no-gutters)
          v-btn.mt-2.float-right( color="accent" dense x-small @click="linkEquipment" outlined ) {{ $t('section.equipment.details.saveBinding') }}

      div.mt-2(v-if="showGoto") 
            v-btn.mt-2( color="accent" dense x-small @click="selectElements" outlined ) {{ $t('section.equipment.details.showModel') }}
            v-btn.mt-2.ml-2( color="accent" dense x-small @click="goToEquipment" outlined ) {{ $t('section.equipment.details.goHardware') }}


</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { api } from "@/api"
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

export default {
  props: {
    equipment: null,
    showGoto: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
        toggleLink: false,
    }
  },

  components: {
  },

  watch:{
    equipment () {
     if(!this.showGoto) this.selectElements()
    }
  },

  mounted() {
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('projectPermissions', ['hasMaintainceBindingElements']),

    countLink(){
      return this.equipment?.elements ? this.equipment.elements.length : 0
    },

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    countSelect(){
      return this.selectedElements ? this.selectedElements.length : 0
    },

    lostWarranty (){
      let start = new Date(this.equipment.warrantyStartDate)
      return this.equipment.equipmentSpecification.warrantyMonths - this.monthDiff(start, new Date())
    },

    worked (){
      let start = new Date(this.equipment.operationStartDate)
      let diff = this.monthDiff(start, new Date())
      let diffPercent = Math.round((diff * 100) / this.equipment.equipmentSpecification.resourceMonths)
      return diffPercent + "% " + (diff + this.$t('section.equipment.details.mo'))
    }

  },

  methods: {
    ...mapActions('maintenance', ['linkEquipment']),

    monthDiff(start, end) {
      let months = ((end.getFullYear() - start.getFullYear()) * 12) - (start.getMonth() + 1) + end.getMonth()
      return months <= 0 ? 0 : months
    },

    goToEquipment() {
      this.$emit('goToEquipment', this.equipment)
    },

    selectElements() {
      if(this.equipment?.elements.length > 0) {
        api.projects.icfGlobalIdToGlobalId(this.equipment.elements, this.projectUuid).then(data => {
          XeokitMediator.ElementsSelection.selectElements(data)
        })
      } else
        XeokitMediator.ElementsSelection.selectElements([])
    }
  }
}
</script>

<style scoped>

.t-scroll-body {
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-primary-lighten4);
}

.h100p {height: 100%;}
</style>

