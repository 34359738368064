<template lang="pug">
  app-dialog(v-model="$_show" withScrollPanel complex width="80%" :header="$t('toolbarMenu.importStructure.importProject')" ref="dialog")
    v-row(no-gutters)
      v-col( cols=4)
        .header-column.d-flex.flex-column.pl-2.pr-2.header-column
          app-text.text-subtitle-2.mb-2.text-md-center.wrap-text {{ $t('toolbarMenu.importStructure.structureFolder') }}
          .divider
        app-section.col-section.base-scroll-body( :rounded="false" style="height: 392px;")
          .d-flex.justify-space-between(v-if="dirStruct.length")
            v-btn.dict-button.px-1(color="accent" dense text x-small @click="toggleMassFolding") 
              | {{ areAllTreeFolderOpens ? $t('importStructure.collapseTree') : $t('importStructure.expandTree') }}
              v-icon.ml-1.mr-0(color="accent" size="16" left ) {{ areAllTreeFolderOpens ? 'unfold_less' : 'unfold_more' }}
            v-btn.dict-button.px-1(v-if="dirStruct.length" color="accent" dense text x-small @click="toggleMassSelect") 
              | {{ areAllTreeNodesSelected ? $t('importStructure.clearAll') : $t('importStructure.selectedAll') }}
              v-icon.ml-1.mr-0(color="accent" size="16" left ) {{ areAllTreeNodesSelected ? 'remove_done' : 'done_all' }}
          v-treeview.ml-1.__project_view_tree(
            ref="tree"
            v-model="selection"
            :items="dirStruct"
            item-key="itemKey" item-text="nameModel" item-children="projectStructureDTO" color="accent" 
            light dense activatable selectable open-on-click
            expand-icon=""
            @update:open="handleOpen"
            :open.sync="openFolders"
          )
            template(v-slot:prepend="{ item, open }")
                v-icon(v-if="!item.isFile") {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                v-icon(v-if="item.extension == 'laz'") $laz-icon
                v-icon(v-if="item.extension == 'ifc'") $ifc-icon
                v-icon(v-if="item.extension == 'e57'") $e-fifty-seven-icon
                v-icon(v-if="item.extension == 'ifczip'") $ifczip-icon     
                v-icon(v-if="item.extension == 'pdf'") $pdf-icon

          app-button-add.mt-3.ml-1.mr-1(width="100%" :text="$t('button.add')" @click="openImportDialog")
          app-button-add.mt-3.ml-1.mr-1(width="100%" :text="$t('button.delete')" @click="clearDirStruct")
      v-col(cols=4)
        .header-column.d-flex.flex-column.pl-2.pr-2
          app-text.text-subtitle-2.mb-2.text-md-center.wrap-text {{ $t('toolbarMenu.importStructure.transformationRule') }}
          .divider
        app-section.col-section.base-scroll-body(:rounded="false" style="height: 392px;")
          v-row.d-flex(no-gutters v-for="(rule, index) in transformationRules")
            app-section.d-flex(style="height: 100%; width: 100%; padding: 0px; " :rounded="false")
              v-col(cols=5 style="padding: 3px;" )
                app-select(v-model="rule.title" hide-details :items="transformationRuleTypes" item-text='message' outlined @change="saveType(rule)")
              v-col(cols=5 style="padding: 3px;" )
                app-text-field(v-model="rule.condition" hide-details @change="saveCondition")
              v-col.d-flex.justify-center.pr-1(cols=1)
                v-icon(tag="button" :color="checkboxColor(rule)" light @click="toggleSaveRule(rule)") mdi-check-circle-outline
              v-col.d-flex.justify-center(cols=1)
                v-icon(tag="button" color='var(--v-surface-lighten1)' light @click="onRuleMenu(index, $event)") mdi-dots-vertical
          app-menu(ref="ruleMenu" :menu="ruleMenu" )
          v-row(no-gutters style="padding: 3px;")
            v-icon(tag="button" color="#3B93AF" light @click='addTransformationRule') mdi-plus-circle-outline
            v-switch.switch(v-model="ignoreDirs" color="accent" @click="toggleIgnoreDirs" hide-details  dense light)
              template( v-slot:label ) 
                app-text.text-md-center {{ $t("importStructure.ignoreDirs") }}
            v-checkbox.switch(v-model="useWebIfcParser" color="accent" dense hide-details light ) 
              template(v-slot:label)
                app-text.text-md-center(style = "margin-left: -6px;") {{ $t("importStructure.useAlternativeParser")}}
      v-col(cols=4)
        .header-column.d-flex.flex-column.pl-2.pr-2.header-column
          app-text.text-subtitle-2.mb-2.text-md-center.wrap-text {{ $t('toolbarMenu.importStructure.formedStructure') }}
          .divider
        app-section.col-section.base-scroll-body.pb-2( :rounded="false" style="height: 392px;" )
          tool-tree-draggable-batch-upload(@childToParentMap='handleChildToParentMap' :items="cloneProjectModel" :disabled="false")
          tool-tree-draggable-batch-upload(:items="previewStruct" :disabled="false")
    v-row(no-gutters)
      v-col.btn(cols=12 style='border-top: 1px solid #0000001F;') 
        app-button.mt-3.mb-2.mr-2(cancel @click="cancle") {{ $t('button.cancel')}}
        app-button.mt-3.mb-2.mr-2( action @click="save") {{ $t('button.save') }}
    upload-files-or-folders(ref="uploadFilesOrFolders" @selectedFilesOrFolders="handleFileOrFolderImport" @fileNodesMap="handleFileNodesMap" @flatListFolders="handleFlatListFolders")
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import ToolTreeDraggableBatchUpload from "@/components/project/common/ToolTreeDraggableBatchUpload.vue"
import UploadFilesOrFolders from "./UploadFilesOrFolders.vue";

import { mapGetters, mapState, mapActions } from 'vuex'
import { api } from "@/api"

export default {
  name: 'BatchUpload',
  mixins: [DialogToggable],

  components: {
    ToolTreeDraggableBatchUpload,
    UploadFilesOrFolders
  },
  data () {
    return {
      dirStruct: [],
      dialog: {
        createProjectStruct: false
      },
      transformationRules: [],
      newStruct: [],
      previewStruct: [],
      cloneProjectModel: [],
      currentRuleIndex: null,
      loadingShow: false,
      numberLoadedNodes: 0,
      countNodes: 0,
      selectedNodes: [],
      openFolders: [],
      fileNodesMap: new Map(),
      flatListFiles: [],
      flatListFolders: [],
      ignoreDirs: false,
      itemKeyToChildMap: new Map(),
      uuidToParentMap: new Map(),
      itemKey: 1,
      useWebIfcParser: false
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapGetters('project', ['projectModel']),

    ruleMenu () {
      return [
        { title: this.$t('button.delete'), action: this.deleteRule},
      ]
    },

    transformationRuleTypes () {
      return [
        this.$t('importStructure.transformationRule.type.separatorCharacter'),
        this.$t('importStructure.transformationRule.type.length')
      ]
    },

    selection: {
      get () { 
        return this.selectedNodes
      },
      set (nodes) {
        if (this.selection.length != nodes.length) {
          this.selectedNodes = nodes
          this.updateStruct()
        }
      }
    },

    areAllTreeNodesSelected() {
      return this.selection?.length === this.flatListFiles.length
    },

    areAllTreeFolderOpens() {
      return this.openFolders?.length === this.flatListFolders.length
    },  
  },

  watch: {
    $_show(val) {
      const deepCopy = (tree)=> {
        const array = []
        for (const node of tree) {
          let clone = {
            title: node.title,
            model: [],
            opened: false,
            uuid: node.uuid
          }
          if (node.model && node.model.length > 0) clone.model = deepCopy(node.model)
          array.push(clone) 
        }

        return array
      }

      if (val) this.cloneProjectModel= deepCopy(this.projectModel)
      if (!val) this.resetData()
    },
  },

  methods: {
    ...mapActions('project', ['loadProject']),

    handleChildToParentMap(child, parent) {
      child.itemKey ? this.setMap(child, parent) : this.traverseSetMap(child, parent)
    },

    setMap(child, parent) {
      this.itemKeyToChildMap.set(child.itemKey, child)
      const parentUuid = parent ? parent.uuid : parent
      this.uuidToParentMap.set(parentUuid, parent)
    },

    traverseSetMap (child, parent) { 
      for (const value of child.projectStructureDTO) {
        if (value.itemKey) {
          value.parentUuid = parent?.uuid
          this.setMap(value, parent)
          continue
        }

        this.traverseSetMap(value, parent)
        }
    },

    toggleIgnoreDirs() {
      this.itemKeyToChildMap.clear()
      this.updateStruct()
    },

    handleFileOrFolderImport(struct) {
      this.dirStruct.push(...struct)
    },

    handleFlatListFolders(flatListFolders) {
      this.flatListFolders = flatListFolders
    },

    handleFileNodesMap(fileNodesMap) {
      this.flatListFiles = [...fileNodesMap.keys()]
      this.fileNodesMap = fileNodesMap
    },

    toggleMassSelect() {
      this.selection = this.areAllTreeNodesSelected ? [] : this.flatListFiles
    },

    toggleMassFolding() {
      this.openFolders = this.areAllTreeFolderOpens ? [] : this.flatListFolders
      this.updateOpenedStatus(this.dirStruct)
    },

    handleOpen (value) {
      if (this.openFolders.length != value.length) {
        this.openFolders = value
        this.updateOpenedStatus(this.dirStruct)
      }
    },

    updateOpenedStatus(tree) {
      for (const node of tree) {
        node.opened = this.openFolders.includes(node.itemKey)
        if (node.projectStructureDTO.length) this.updateOpenedStatus(node.projectStructureDTO)
      }
    },

    updateStruct() {
      this.newStruct = this.createNewStruct(this.dirStruct, new Set(this.selectedNodes))
      this.updatePreviewStruct()
    },

    createNewStruct (tree, selectedNodesSet) {
      const array = []

      for (const node of tree) {
        if (node.projectStructureDTO.length) {
          const projectStructureDTO = this.createNewStruct(node.projectStructureDTO, selectedNodesSet)
          if (projectStructureDTO.length) {
            this.ignoreDirs? array.push(...projectStructureDTO): array.push({...node, projectStructureDTO,})
          } else this.itemKeyToChildMap.delete(node.itemKey)
        }

        if (selectedNodesSet.has(node.itemKey)) {
          array.push({...node })
        } else if (!node.projectStructureDTO.length) this.itemKeyToChildMap.delete(node.itemKey)
      }

      return array
    },

    updatePreviewStruct () {
      this.updateParentUuidNewStruct(this.newStruct)
      let newStrucAndRules = {
        previewProjectStructureDTO: this.newStruct,
        projectStructureRulesDTO: this.transformationRules
      }
      api.projects.fetchPreviewStructure(newStrucAndRules, this.project.uuid).then(data =>{
        this.previewStruct = data.previewProjectStructureDTO
        this.countNodes = this.countNodesInPreviewStruct(data.previewProjectStructureDTO, 0)
        this.deleteNodesInCloneProjectModel()
        this.updateStatePreviewStruct(this.previewStruct)
      })
    },

    updateParentUuidNewStruct(tree) {
      for (const node of tree) {
        node.parentUuid = this.itemKeyToChildMap.get(node.itemKey)?.parentUuid
        if (node.projectStructureDTO.length) this.updateParentUuidNewStruct(node.projectStructureDTO)
      }
    },

    countNodesInPreviewStruct(nodes, count) {
      for (const node of nodes) {
        if (node.projectStructureDTO.length) count = this.countNodesInPreviewStruct(node.projectStructureDTO, count)
        count++
      }

      return count
    },

    deleteNodesInCloneProjectModel() {
      for (const value of this.uuidToParentMap.values()) {
        const parentModel = value? value.model : this.cloneProjectModel
        for (let i = parentModel?.length - 1; i >= 0; i--) {
          if (parentModel[i].projectStructureDTO) parentModel.splice(i, 1)
        }
      }
    },

    updateStatePreviewStruct (tree) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].parentUuid) {
          const parent = this.uuidToParentMap.get(tree[i].parentUuid)
          parent?.model.push(tree[i])
          tree.splice(i, 1)
          i--
        }
        
        if (tree[i]?.projectStructureDTO.length) this.updateStatePreviewStruct(tree[i].projectStructureDTO)
      }
    },

    async save() {
      this.$_show = false
      this.numberLoadedNodes = 0
      this.$emit('numberLoadedNodes', this.numberLoadedNodes)
      this.$emit('countNodes', this.countNodes)
      this.$emit('loadingShow', true)
      const combinedArray = [...this.previewNodesInClone(), ...this.previewStruct]
      await this.uploadStructure(combinedArray) 
      await this.loadProject(this.project.uuid)
      this.$emit('loadingShow', false)
      api.projects.uploadStructureRule(this.transformationRules)
    },

    previewNodesInClone() {
      const arr = []
      for (const value of this.uuidToParentMap.values()) {
        const parentModel = value? value.model : this.cloneProjectModel
        for (let i = 0; i < parentModel?.length; i++) {
          if (parentModel[i].projectStructureDTO) arr.push(parentModel[i])
        }
      }

      return arr
    },

    uploadStructure(previewStruct, parentUuid = null) {
      previewStruct = this.movePdfFilesToEnd(previewStruct)
      return previewStruct.reduce((promiseChain, previewEntry) => {
        return promiseChain.then(() => {
          let formData = new FormData()
          let file = this.fileNodesMap.has(previewEntry.itemKey)? this.fileNodesMap.get(previewEntry.itemKey): null
          formData.append("nameModel", previewEntry.nameModel)
          formData.append("parentUuid", parentUuid ? parentUuid : previewEntry.parentUuid)
          formData.append("file", file)
          formData.append("webIfcParser", this.useWebIfcParser)
          return api.projects.uploadStructureSegment(formData, this.project.uuid)
            .then(data => {
              this.numberLoadedNodes++
              this.$emit('numberLoadedNodes', this.numberLoadedNodes)
              if (previewEntry.projectStructureDTO && previewEntry.projectStructureDTO.length > 0) {
                return this.uploadStructure(previewEntry.projectStructureDTO, data.ParentUuid)
              }
            });
        });
      }, Promise.resolve())
      .catch(error => {
        console.error("Ошибка при загрузке структуры проекта:", error)
      })
    },
      
    movePdfFilesToEnd (arr) {
      return arr.sort((a, b) => {
        if (a.extension === 'pdf') return 1
        if (b.extension === 'pdf') return -1

        return 0
      })
    },

    checkboxColor(rule) {
      return rule.isSave? '#81B635': '#B8B8B8'
    },

    toggleSaveRule (rule) {
      rule.isSave = !rule.isSave
      this.updatePreviewStruct()
    },

    onRuleMenu (index, event) {
      this.currentRuleIndex = index
      this.$refs.ruleMenu.show(event)
    },

    deleteRule () {
      this.transformationRules.splice(this.currentRuleIndex, 1)
      this.updatePreviewStruct()
    },

    saveCondition() {
      this.updatePreviewStruct()
    },

    saveType(rule) {
      rule.ruleType = rule.ruleType == 'SYMBOL'? 'LENGTH': 'SYMBOL'
      this.updatePreviewStruct()
    },

    addTransformationRule(){
      let rule = {
        title: this.$t('importStructure.transformationRule.type.separatorCharacter'),
        ruleType: 'SYMBOL',
        isSave: false,
        condition: '',
        projectUuid: this.project.uuid,
      }

      this.transformationRules.push(rule)
    },

    resetData() {
      this.dirStruct = []
      this.flatListFiles = []
      this.flatListFolders = []
      this.ignoreDirs = false
      this.itemKeyToChildMap.clear()
      this.uuidToParentMap.clear()
    },

    clearDirStruct() {
      this.dirStruct = []
      this.$refs.uploadFilesOrFolders.resetFileMapAndFlatListFolders()
    },

    cancle() {
      this.$_show = false
    },

    openImportDialog() {
      this.$refs.uploadFilesOrFolders.openDialog()
    },
  }
}
</script>

<style lang="scss" scoped>
  .switch {
    margin-left: 7px;
    margin-top: 0px;
    margin-right: 0px;
    padding-top: 0px;
  }

  .btn{
    display: flex;
    justify-content: flex-end;
    background-color: white;
  }

  .col-section{
    border-right: 1px solid #0000001F;
    height: 100%;
    width: 100%;
  }

  .divider {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background: #4d4d4d;
  }

  .wrap-text {
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
  }

  .header-column {
    background-color: white; 
    height: 36px; 
    padding-top: 11px;
  }

  ::v-deep .v-input--selection-controls__input {
    align-items: normal !important;
  }

  ::v-deep .v-input--selection-controls.v-input--dense:not(.v-input--switch) .v-input--selection-controls__ripple {
    top: 0;
    left: 0;
    margin: 0;
  }
</style>

<style lang="sass" scoped>
.__project_view_tree.v-treeview
  ::v-deep.v-treeview-node__toggle
    width: 12px !important
    margin-left: 0px
  ::v-deep.v-treeview-node__level
    width: 12px
  .v-icon
    font-size: 16px
    margin-bottom: 3px
  ::v-deep.v-treeview-node__content
    margin-left: -12px
  .v-treeview-node__checkbox 
    position: absolute
    right: 2px
</style>