<template lang="pug">
.base-scroll-body.py-2.px-1
  div( v-if="patchList.length > 0" )
    .model-panel-header
      span.column-title {{ $t('patch.table.reconstruction') }}
      span.column-title {{ $t('patch.table.date') }}

    div( v-for="item in patchList" :key="item.uuid" )
      app-tooltip( bottom :title="item.name" )
        v-hover.pointer( v-slot:default="{ hover }" )
          .model-panel( :class="{'selected-patch': selectedPatch && selectedPatch.uuid == item.uuid }" @click="openSelectedPatchPanel(item)" @contextmenu.stop="showPatchMenu($event, item)" )
            span.model-name( :class=" item.state.value == 1 ? 'active-patch ' : 'not-active-patch' ") {{item.name}}
            span.model-date {{ prettyDate(item.revisionDate) }}
            v-icon.model-menu-icon.mr-1( v-if="hover && hasProjectReconstruction" color="#434343"  @click.stop="showPatchMenu($event, item)" ) mdi-dots-vertical

  app-button-add.mt-2( v-else width="100%" :text="$t('section.structure.model.addChangeModel')" @click="showAddPatchModel" ) 

  app-dialog( v-model="dialog.addModelPatch"
              :header="$t('section.structure.model.addChangeModel')"
              :confirmText="$t('button.save')"
              @confirm="addModelPatch"
              :confirmDisabled="!checkData" )
    app-section
      div
        app-text-field( v-model="patchData.params.name" :label="$t('patch.dialog.recName')" light)
        app-file-input( v-model="patchData.ifcFile" :max-size="10737418240" :formats="['.ifc','.ifczip']" )
        .d-flex
          v-checkbox.ma-0.pa-0( v-model="patchData.params.replaceElement" :label="$t('patch.dialog.deletingSelected')" light )
          app-icon-action.ml-2( icon="$help-icon" size="20" @click="openWiki")

  app-dialog( v-if="editingModelPatch"
              v-model="dialog.editModelPatch"
              :header="$t('patch.dialog.editRec')"
              :confirmText="$t('button.save')"
              @confirm="editModelPatch" )
    app-section
      div 
        app-text-field( v-model="editingModelPatch.name" :label="$t('patch.dialog.recName')" )

  app-dialog-confirm( v-if="editingModelPatch" v-model="dialog.deleteModelPatch" @confirm="deleteModelPatch" )
    app-text {{$t('patch.dialog.deleteRec')}} "{{ editingModelPatch.name }}"?

  app-dialog-confirm( v-if="editingModelPatch" v-model="dialog.activateModelPatch" @confirm="activateModelPatch" )
   app-text {{$t('patch.dialog.activateRec')}} "{{ editingModelPatch.name }}"?

  app-dialog-confirm( v-if="editingModelPatch" v-model="dialog.deactivateModelPatch" @confirm="deactivateModelPatch" )
    app-text {{ $t('patch.dialog.deactivateRec') }} "{{ editingModelPatch.name }}"?

  app-menu( ref="patchModelMenu" :menu="patchModelMenu" )
  app-menu( ref="patchModelMenuDeact" :menu="patchModelMenuDeact")

  app-menu( ref="patchModelListMenu" :menu="patchModelListMenu")
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name:"ModelPatchList",

  data() {
    return {
      dialog: {
        addModelPatch: false,
        editModelPatch: false,
        deleteModelPatch: false,
        activateModelPatch: false, 
        deactivateModelPatch: false,
      },

      editingModelPatch: null,

      patchData: {
        ifcFile: null,
        params: {
          name: "",
          annotation: "",
          elements: [],
          replaceElement: false
        }
      },
    }
  },

  mounted() {
    this.loadPatchList()
  },

  computed: {
    ...mapState('patches', ['patchList', 'selectedPatch']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction", "hasProjectStruct", "hasProjectAddAttribution", "hasProjectDeleteModel", "hasProjectControlArchive", "hasModelDownloadRevision" ]),
    
    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    patchModelMenu(){
      let menu = []
      menu.push({ title: this.$t('button.edit'), action: this.showDialogEditModel })
      menu.push({ title: this.$t('button.delete'), action: this.showDialogDeleteModel })
      menu.push({ title: this.$t('button.activate'), action: this.showDialogActivateModel })
      return menu
    }, 

    patchModelMenuDeact() {
      let menu = []
      menu.push({ title: this.$t('button.edit'), action: this.showDialogEditModel })
      menu.push({ title: this.$t('button.delete'), action: this.showDialogDeleteModel })
      menu.push({ title: this.$t('patch.dialog.deactivate'), action: this.showDialogDeactivateModel})
      return menu
    },

    patchModelListMenu() {
      let menu = []
      menu.push({ title: this.$t('patch.dialog.addRec'), action: this.showAddPatchModel})
      return menu
    },

    checkData() {
      return this.patchData.params.name && this.patchData.params.name.length !== null && this.patchData.params.name?.trim()
    }
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab', 'setSelectedModel']),
    ...mapMutations('patches', ['setSelectedPatch']),
    ...mapActions('patches', ['saveEditedPatch', 'loadPatchList', 'createPatch', 'deletePatch', 'switchActivationPatch']),

    prettyDate: (fulldate) => {
      let [date] = fulldate.split(' ')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year[2] + year[3]}`
    },
    
    openSelectedPatchPanel(patch) {
      this.setSelectedModel(null)
      if (this.selectedPatch?.uuid == patch.uuid) {
        this.setSelectedPatch(null)
      } 
      else {
        this.setSelectedPatch(patch)
        this.$emit('openSelectedPatchPanel')
      }
    }, 
    showPatchMenu($event, patch) {
      this.editingModelPatch = patch
      if(this.hasProjectReconstruction) {
        if(this.editingModelPatch.state.value == 1) {
          this.$refs['patchModelMenuDeact'].show($event)
        }
        else 
          this.$refs['patchModelMenu'].show($event)
      }
    },

    showPatchListMenu($event) {
      this.$refs['patchModelListMenu'].show($event)
    },

    editModelPatch(){
      this.saveEditedPatch(this.editingModelPatch).then(() => {
        this.dialog.editModelPatch = false
      })
    },

    addModelPatch() {
      if (this.patchData.params.replaceElement) {
        this.patchData.params.elements = [...this.selectedElements]
      }
      this.createPatch(this.patchData).then(() => {
        this.dialog.addModelPatch = false
        this.loadPatchList()
      })
    },

    deleteModelPatch() {
      this.deletePatch(this.editingModelPatch.uuid)
      XeokitMediator.destroyUnnecessaryModels()
      this.dialog.deleteModelPatch = false
    },

    activateModelPatch() {
      this.switchActivationPatch(this.editingModelPatch.uuid)
      this.dialog.activateModelPatch = false
    },

    deactivateModelPatch() {
      this.switchActivationPatch(this.editingModelPatch.uuid)
      this.dialog.deactivateModelPatch = false
    },

    showDialogDeleteModel() {
      this.dialog.deleteModelPatch = true
    },

    showDialogEditModel() {
      this.dialog.editModelPatch = true
    },

    showDialogActivateModel() {
      this.dialog.activateModelPatch = true
    },

    showDialogDeactivateModel() {
      this.dialog.deactivateModelPatch = true
    },

    showAddPatchModel() {
      this.dialog.addModelPatch = true
      this.patchData.params.name = null
      this.patchData.ifcFile = null
      this.patchData.params.replaceElement = null
    },

    openWiki() {
      window?.open('https://wiki.bimit.ru', '_blank')
    }
  }

}
</script>

<style lang="scss" scoped>
.v-icon.v-icon::after {
  background-color: transparent;
}
.model-panel-header{
  width: 100%;
  font-size: 12px;
  color:#000;
  display: grid; 
  grid-template-columns: 1fr 56px 15px;
  column-gap: 15px;
  align-items: center;
  padding: 10px 0px 10px 10px
}
.model-panel{
  width: 100%;
  height: 32px;
  font-size: 12px;
  color:#000;
  display: grid; 
  grid-template-columns: 1fr 55px 15px;
  column-gap: 5px;
  // padding: 10px 0px 10px 10px;
}
.model-name{
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.model-menu-icon{
  width: 5px;
}
.model-date{
  display: flex;
  align-items: center;
  opacity: 0.7; 
  font-weight: 700;
  white-space: nowrap;
}
.column-title:nth-child(2n) {
  padding-left: 10px;
}
.selected-patch{
  background-color: var(--v-primary-lighten5);
}

.active-patch {
  color: var(--v-secondary-darken2); 
}

.not-active-patch {
  color: var(--v-secondary-lighten2);
}
.dialog-confirm-action {
  margin: 0px;
  font: normal 10px/12px $roboto;
  // color: #FFFFFF;
}

.wiki-link {
  a {
    text-align:center;
    font-weight: bold;
    font-size:16px;
    color: var(--v-primary-base);
    text-decoration: none;
    display:block;
    width: 24px;
    height: 24px;
    background: #f0f0f0;
    border-radius: 12px;
  }
}
</style>