import { render, staticRenderFns } from "./AddWormRuleDialog.vue?vue&type=template&id=3934bd34&scoped=true&lang=pug&"
import script from "./AddWormRuleDialog.vue?vue&type=script&lang=js&"
export * from "./AddWormRuleDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddWormRuleDialog.vue?vue&type=style&index=0&id=3934bd34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3934bd34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAlert,VBtn,VDivider,VForm,VIcon,VList,VListItem,VMenu,VProgressCircular,VSkeletonLoader,VSpacer,VSwitch})
