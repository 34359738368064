<template lang="pug">
div(ref='card' test-id="task-card")
  v-skeleton-loader(type='card' light v-if='!show' :height='80' :width='300')
  v-hover(#default="{ hover }" v-if='show')
    app-section.pointer( :style="isSelected")
      .d-flex.align-center
        app-avatar.mr-2( :text="avatarText" :url="avatarUrl" :colorObj="getBgColor(task.creator.uuid)" :disabled="task.creator && task.creator.archive" tooltipBottom)
        task-priority.mr-2(:task="task" theme='light')
        task-status.mr-2(:task="task")
        .caption.pl-4.data( :class="{'closed': isClosed }") {{ startDateNeededFormat }}
        .alias.ml-auto.font-weight-light(v-if="task.name" test-id="task-card-id" :class="{'closed': isClosed }") {{ task.name.charAt(0) + "-" + task.name.split("-").reverse()[0], }}
      .d-flex.mt-2
        .title( :class="{'closed': isClosed }" v-line-clamp='4') {{ task.title }}
      .d-flex.justify-end(v-if="!task.archived" style="height:18px" test-id="delete-btn-container")
        app-icon-action(v-if="hover && hasTaskDelete" size='16' icon="$delete-icon" test-id="task-card-delete"  @click.stop="confirmDelTask(task)")

  app-dialog-confirm( v-model="dialog.confirmDelTask" @confirm="deleteSelectedTask" ) 
    app-text {{ $t('module.task.confirmDeleteTask', {name: task.title}) }}
    
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { SourcePath } from '@app/SourcePath'
import { Task } from '@/assets/model/task/Task'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'

import TaskStatus from '@/components/task/TaskStatus.vue'
import TaskPriority from '@/components/task/TaskPriority.vue'
import MarkdownText from '../panel/right/MarkdownText.vue'
import uniqolor from 'uniqolor'

export default {
  name: 'ProjectTaskCard',

  components:{
    TaskStatus,
    TaskPriority,
    MarkdownText,
  },

  data(){
    return{
      dialog:{
        confirmDelTask: false,
        selectedDeleteTask: null,
      },
      show: false
    }
  },

  props: {
    task: {
      type: Task,
      required: true
    },
  },

  filters: {
    prettyDate: (fulldate) => {
      let [date, time] = fulldate.split(' ')
      let [hour, minutes] = time.split(':')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year} ${hour}:${minutes}`
    },
  },

  mounted() {
    const options = {
      root: null,
      threshold: "0"
    }
    const observer = new IntersectionObserver((entry, obs)=> {
      entry.map(entry => {
        if(entry.isIntersecting){
          this.show = true
          obs.unobserve(this.$refs.card)
        }
      })
    }, options)
    observer.observe(this.$refs.card)

  },
  computed: {
    ...mapState('task', ['selectedTask']),
    ...mapState('project', ['project']),
    ...mapGetters('projectPermissions', ['hasTaskDelete']),

    isClosed() {
      return this.task.completeStatus.name == 'CLOSED'
    },

    isSelected () {
      return this.selectedTask && this.selectedTask.uuid === this.task.uuid ? { backgroundColor: "#dff6fd"} : { backgroundColor: "#ffffff"}
    },
    
    avatarText () {
      let archive = this.task.creator?.archive ? this.$t('section.structure.model.memberDeleted') : ""
      return this.task.creator?.name + archive
    },

    avatarUrl () {
      return SourcePath.avatar(this.task.creator?.avatar)
    },

    checkTaskStatus(){
      switch(this.task.completeStatus.name){
        case "NEW":
          return "#eb5757"
        case "READY":
          return "#219653"
        case "DISCUSS":
          return "#f2994a"
        case "CLOSED":
          return "#c4c4c4"
      }
      return 0;
    },

    checkTaskPriority(){
      if (this.isClosed) {
        return "task_priority_ligh_0.svg"
      }
      switch(this.task.priority.name) {
        case "LOW":
          return "task_priority_light_1.svg"
        case "NORMAL":
          return "task_priority_light_2.svg"
        case "HIGH":
          return "task_priority_light_3.svg"
      }
      return false
    },

    startDateNeededFormat() {
      let startDate = new Date(this.task.startDate.replace(" ", "T"))

      let year =  startDate.getUTCFullYear().toString()

      let month = (startDate.getMonth() + 1).toString()
      month = month.length != 2 ? "0" + month : month 
      
      let day = startDate.getDate().toString()
      day = day.length != 2 ? "0" + day : day 

      startDate = day + "." + month + "." + year
      return startDate
    },
  },

  methods: {
    ...mapActions('task', ['addTask', 'deleteTask']),
    ...mapMutations('task', ['setSelectedTask']),

    createTask () {
      this.addTask({ title: "SUB" + this.task.title, project: this.project.uuid, parent: { uuid: this.task.uuid} })
    },

    confirmDelTask(task){
      this.selectedDeleteTask=task
      this.dialog.confirmDelTask=true
    },

    deleteSelectedTask(){
      this.deleteTask(this.selectedDeleteTask).then(() => {
        if(this.selectedTask?.uuid == this.selectedDeleteTask?.uuid) {
          this.setSelectedTask(null)
        }
        this.updateAnnotations()
      })
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    updateAnnotations() {
      TaskBimAnnotations.showTaskBimAnnotations()
    }
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.data {
  margin-left: auto !important;
}
.alias {
  margin-left: 10px !important;
  font: normal 12px/18px $roboto;
  color: #262626;
}
.status {
  font: 600 12px/18px $roboto;
}
.date{
  font: 300 10px/18px $roboto;
}
.title{
  word-break: normal;
}
.closed{
  word-break: normal;
  color:#a0a0a0;
}
</style>