<template lang="pug">
div
  v-data-table(
    :light="light" 
    :dark="dark"
    :headers="headers" 
    :items="items" 
    :items-per-page="itemsPerPage" 
    :hide-default-footer="hideDefaultFooter"
  )
    template(v-slot:item.actions="{ item }" v-if="tableName != 'changes'")
      v-icon(small @click="editItem(item, tableName)" style="margin-right: 10px") mdi-pencil
    template(v-slot:footer v-if="tableName != 'changes'")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          v-btn(style="margin: 5px" icon @click="newItem(tableName)" v-on="on" v-bind="attrs")
            v-icon mdi-plus-box-outline
        span {{ $t('section.journal.newItem') }}
</template>

<script>
export default {
  name: 'wj-data-table',

  props: {
    light: {
      default: true,
      type: Boolean
    },
    dark: {
      default: false,
      type: Boolean
    },
    headers: null,
    items: null,
    parentName: null,
    itemsPerPage: {
      default: 10,
      type: Number
    },
    hideDefaultFooter: {
      default: false,
      type: Boolean
    },

    tableName: null,
  },

  methods: {
    editItem(item, tableName) {
      this.$emit('editItem', item, tableName)
    },
    newItem(tableName) {
      this.$emit('newItem', tableName, this.parentName)
    },
  }
}
</script>

<style>

</style>