<template lang="pug">
  app-panel( ident="wormpannel" left v-bind="$attrs" @widthchange="handleWidthChange")
    app-panel-header(v-if="canWorkWithWorm" :title="$t('section.worm.default')" :menu="wormMenu" showClose )
    app-panel-header(v-else :title="$t('section.worm.default')" showClose )

    v-row.column-height(no-gutters)
      v-col.base-scroll-body.h100p(:style="{ flexBasis: leftPanelWidth + '%' }")
        app-panel( padding="0px" )
          app-floating-panels-section.wormTree( style="height: calc(100% - 14px) !important; overflow-x: hidden; " )
            app-floating-panel( :title="$t('section.worm.default')" bodyMinHeight="150" 
              :settings="floatingPanels('WORM_WORM')")
              .base-scroll-body
                v-progress-linear(v-if="isProjectWormFetching" indeterminate)
                v-row(v-if="!isProjectWormFetching && projectWormCount == 0 && canWorkWithWorm")
                  v-col.ma-1
                    app-button-add.mx-2(width="100%" :text="$t('button.create')" @click="showAddNew()")
                template(v-else-if="projectWormCount > 0")
                  app-select.dash-border-select.dash-size.mb-5.mr-2.mt-2.ml-2(v-model="projectWormSelected" :items="projectWorms" item-text="title" item-value="uuid" :label="$t('section.worm.select')" flat solo clearable hideDetails test-id="select-classifier")

                  worm-tree(@showExportDialog="showExportDealog")


            app-floating-panel( v-if="element" :title="$t('section.element', {name: element.name})" bodyMinHeight="150" overflowBody="hidden" :menu="elementMenu" 
              :settings="floatingPanels('WORM_ELEMENT_DETAILS')")
              element-details.py-0( v-if="element" :element="element" @showwormfromelement="showWormFromElement")
  
      .sizable-block-right(@mousedown="horizontalSizable($event)")
        v-img.icon-pos(:src="`/img/project/tools/sizable.svg`")

      v-col.h100p(:style="{ flexBasis: rightPanelWidth + '%', width: rightPanelWidth + '%' }")
        worm-rule-edit-form

    app-dialog(v-if="canWorkWithWorm" v-model="dialog.addnew" :header="$t('section.worm.add')" :confirmText="$t('button.save')" @confirm="saveNew")
      app-section
        v-form( ref="projectWormForm" )
          app-text-field(v-model="newProjectWorm.title" :label="$t('section.worm.add')" outlined :rules="[rules.required]")

    app-dialog(v-if="canWorkWithWorm" v-model="dialog.savingVersion" :header="$t('section.worm.saveVersion')" )
      app-section
        v-progress-linear(indeterminate)
        div(v-if="savingVersionError") {{ savingVersionError }}

    worm-version-compare(v-if="canWorkWithWorm" v-model="dialog.wormVersion")

    app-dialog(v-model="dialog.showInWorm" :header="$t('section.worm.elementIn')" )
      app-section
        v-progress-linear(v-if="nodesForElementLoading" indeterminate)
        div(v-else)
          div(v-if="forelement.length > 0")
            app-text(v-for="item in forelement" :key="item.uuid") 
              b {{ item.classificatorNode.pressmark }}
              | - {{ item.classificatorNode.title }}
          app-text(v-else) {{ $t('section.worm.rulesContainingElement') }}

    app-dialog(v-model="exportWorm.dialog" :header="$t('section.worm.export')" :confirmText="$t('button.send')" @confirm="getWormExport" :closeOnConfirm="false")
      app-section
        v-form( ref="exportWormForm" )
          div(v-if="!exportWorm.node")
            app-text {{$t('section.worm.unload')}}
            v-checkbox(v-for="pc in projectWorms" :key="pc.uuid" v-model="exportWorm.projectclassificator" :value="pc.uuid" :label="pc.title" dense hide-details light)

          app-text(v-if="exportWorm.node")
            template(v-if="exportWorm.node.nodeType.name == 'RULE' && exportWorm.node.classificatorNode") {{ exportWorm.node.classificatorNode.pressmark+' - '+exportWorm.node.classificatorNode.title+' ('+exportWorm.node.title+')' }}
            template(v-else) {{ exportWorm.node.title }}

          v-divider.my-2(light)
          v-checkbox(v-model="exportWorm.showelements" dense hide-details light :label="$t('section.worm.unloadItems')")

          v-checkbox(v-model="exportWorm.useplugin" dense hide-details light :label="$t('section.worm.usePlugin')")
          app-select.mt-2(v-if="exportWorm.useplugin" v-model="exportWorm.plugin" :items="wormPlugins" item-text="name" item-value="uuid" :disabled="!exportWorm.useplugin" :label="$t('section.classification.menuClassification.plugin')" clearable dense :rules="exportWorm.useplugin ? [rules.required] : []")
          
            
</template>

<script>
import { api } from "@/api"
import { AlertService } from '@app/AlertService'

import WormNodeType from '@/assets/model/worm/WormNodeType'
import ProjectWorm from '@/assets/model/worm/ProjectWorm'
import WormNode from '@/assets/model/worm/WormNode'
import WormVersion from '@/assets/model/worm/WormVersion'

import { mapState, mapGetters, mapActions } from 'vuex'

import WormTree from './components/tree/WormTree'
import WormRuleEditForm from './components/rule/WormRuleEditForm'

import WormVersionCompare from './components/wormVersion/WormVersionCompare'

import ElementDetails from '../components/element/Details'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name:'worm-pannel',
  
  components: {
    WormTree,
    WormRuleEditForm,
    WormVersionCompare,

    ElementDetails
  },

  props: {
    
  },

  data() {
    return {
      newProjectWorm: {
        title: "",
        projectUuid: null
      },

      leftPanelWidth: 50, 
      rightPanelWidth: 50, 
      resizableWidth: null,

      dialog:{
        addnew:false,
        savingVersion: false,
        wormVersion: false,
        showInWorm: false
      },

      nodesForElementLoading: false,
      nodesForElement:[],      

      savingVersionError: null,

      rules: {
        required: (value) => !!value || this.$t("error.require")
      },


      exportWorm: {
        dialog: false,
        projectclassificator: [],
        node: null,
        showelements: false,
        useplugin: false,
        plugin: null,
      }
    }
  },

  mounted() {
    this.init()
    this.LOAD_PLUGINS(this.project.uuid)
    this.resizableWidth = this.$el.offsetWidth || 100; 
  },

  computed: {
    ...mapState('project', ['project', 'projectSettings']),
    ...mapGetters('axis/tree', ['element']),

    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

    ...mapGetters('authUser', ['canWorkWithWorm']),

    ...mapGetters('plugins',['wormPlugins']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    saveWormName() {
      return this.projectWorms.find(el => el.uuid == this.projectWormSelected).title
    },

    wormMenu(){
      let act = []

      if (this.canWorkWithWorm) act.push({ title: this.$t('section.worm.addWorm'), action: this.showAddNew })

      if (this.canWorkWithWorm && this.projectWormSelected && this.wormNodeCount > 0) {
        act.push({ divider: true })
        act.push({ title: this.$t('section.worm.saveWormVersion'), action: this.saveVersion })
      }
      if (this.canWorkWithWorm && this.projectWormSelected && this.wormNodeCount > 0) {
        act.push({ divider: true })
        act.push({ title: this.$t('section.worm.compareWorm'), action: this.compareVersion })
      }
      
      if (this.pickedElement) {
        act.push({ divider: true })
        act.push({title: this.$t('section.worm.showInWorm') ,action: this.showWormFromElement })
      }

      act.push({ divider: true })
      act.push({ title: this.$t('section.worm.export'), action: () => this.showExportDealog() })

      if (this.canWorkWithWorm && this.projectWormSelected && this.wormNodeCount > 0) {
        act.push({ divider: true })
        act.push({ title: this.$t('section.worm.recalculate'), action: this.recalculateWorm })
      }
        
      return act
    },

    elementMenu(){
      let act = []
      act.push({
          title: this.$t("section.iot.elementMenu.action"),
          action: this.dev,
        });
      act.push({
        title: this.$t('section.worm.showInWorm'),
        action: this.showWormFromElement,
        });
        
      return act
    },

    typesCount(){
      return WormNodeType.query().count()
    },

    projectWorms(){
      return ProjectWorm.all()
    },

    isProjectWormFetching() {
      return this.$store.state.entities.ProjectWorm.fetching
    },

    projectWormCount() {
      return ProjectWorm.query().count()
    },

    wormNodeCount(){
      return WormNode.query().count()
    },

    forelement(){
      if (this.nodesForElement.length > 0) {
        let uids = this.nodesForElement.map(el => el.uuid)
        return WormNode.findIn(uids)
      }
      return []
    },

    permissionOnExport() {
      return this.hasWorm && this.hasWormView || this.hasWorm
    },

    projectWormSelected: {
      get () {
        return this.$store.state.project.projectSettings.projectWorm
      },
      set (value) {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        ps.projectWorm = value
        this.$store.dispatch('project/updateSettingsProjectWorm', ps)
      }
    }
  },

  watch: {
    'projectSettings.projectWorm'(val){
      if (val) {
        this.loadWormVersions()
      }
    },
    'dialog.savingVersion'(){
      this.savingVersionError = null
    }
  },

  methods: {
    ...mapActions('plugins', ['LOAD_PLUGINS']),

    handleWidthChange(newWidth) {      
      const totalWidth = 100; 
      const leftRatio = this.leftPanelWidth / (this.leftPanelWidth + this.rightPanelWidth); 
      const rightRatio = 1 - leftRatio; 

      this.leftPanelWidth = leftRatio * totalWidth * (newWidth / this.resizableWidth);
      this.rightPanelWidth = rightRatio * totalWidth * (newWidth / this.resizableWidth);
      const minWidthPercent = (300 / newWidth) * totalWidth;
      if (this.leftPanelWidth < minWidthPercent) {
        this.leftPanelWidth = minWidthPercent;
        this.rightPanelWidth = totalWidth - minWidthPercent;
      } else if (this.rightPanelWidth < minWidthPercent) {
        this.rightPanelWidth = minWidthPercent;
        this.leftPanelWidth = totalWidth - minWidthPercent;
      }
      this.resizableWidth = newWidth; 
    },

    horizontalSizable(event) {
      event.preventDefault();
      const startX = event.clientX;
      const initialLeftWidth = this.leftPanelWidth;

      const onMouseMove = (e) => {
        const deltaX = e.clientX - startX;
        const containerWidth = this.resizableWidth; 
        let newLeftPanelWidth = initialLeftWidth + (deltaX / containerWidth) * 100;
        const minWidthPercent = (300 / containerWidth) * 100;
        if (newLeftPanelWidth < minWidthPercent) {
          newLeftPanelWidth = minWidthPercent;
        } else if (newLeftPanelWidth > (100 - minWidthPercent)) {
          newLeftPanelWidth = 100 - minWidthPercent;
        }

        this.leftPanelWidth = newLeftPanelWidth;
        this.rightPanelWidth = 100 - newLeftPanelWidth;         
      };

      const onMouseUp = () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    },

    init() {
      this.$store.dispatch('axis/initShort')
      this.$store.dispatch('smeta/INIT')
      
      if (this.typesCount == 0) WormNodeType.api().fetch()
      ProjectWorm.api().fetchByProject(this.project.uuid)
      if (!this.projectSettings.projectWorm) WormNode.deleteAll()
      if (this.projectSettings.projectWorm && this.wormNodeCount == 0) {
        this.loadWormVersions()
      }
    },

    loadWormVersions(){      
      WormVersion.deleteAll()
      WormVersion.api().get('/worm/version/'+this.projectWormSelected)
    },

    showAddNew() {
      if (this.canWorkWithWorm) {
        this.newProjectWorm.title = ""
        this.newProjectWorm.projectUuid = this.project.uuid
        this.dialog.addnew = true
      }
    },

    saveNew(){
      if (this.canWorkWithWorm && this.$refs.projectWormForm.validate()) {
        ProjectWorm.api().post('/worm/create',this.newProjectWorm).then(data => {
          this.dialog.addnew = false
          this.projectWormSelected = data.uuid
        })
      }
    },

    saveVersion(){
      if (this.canWorkWithWorm) {
        this.savingVersion = true
        WormVersion.api().post('/worm/version/create?projectWormUuid='+this.projectWormSelected).then(() => {
          this.savingVersion = false
          WormVersion.api().get('/worm/version/'+this.projectWormSelected).then(response => {
            let wormVersion = response.response.data.length - 1
            AlertService.success({info: this.saveWormName + ' ver. ' + wormVersion + this.$t("section.worm.successSaveWorm")})
          })
        }).catch(e => this.savingVersionError = e)
      }
    },

    compareVersion() {
      if (this.canWorkWithWorm) {
        this.dialog.wormVersion = true
      }
    },

    showWormFromElement(){
      if(this.projectWormSelected){
        this.nodesForElement = []
        this.nodesForElementLoading = true
        this.dialog.showInWorm = true
        api.worm.nodesforelement(this.projectWormSelected, this.element.uuid).then(data => {
          this.nodesForElement = data
          this.nodesForElementLoading = false
        })
      } else {
        AlertService.error({
          data: { error_description: "ВОРМ не выбран" }
        })
      }
      
    },


    showExportDealog(item){

      this.exportWorm.dialog = false
      this.exportWorm.projectclassificator = []
      this.exportWorm.node = null
      this.exportWorm.showelements = false
      this.exportWorm.useplugin = false
      this.exportWorm.plugin = null

      if (item) {
        this.exportWorm.node = item
      }

      this.exportWorm.dialog = true
    },

    getWormExport() {
      let rq = {
        projectclassificator: (this.exportWorm.projectclassificator != null && this.exportWorm.projectclassificator.length > 0) ? this.exportWorm.projectclassificator : null,
        wormNodeUuid: this.exportWorm.node ? this.exportWorm.node.uuid : null,
        useplugin: this.exportWorm.useplugin,
        pluginUuid: this.exportWorm.plugin,
        showElements: this.exportWorm.showelements
      }

      
      if (this.$refs.exportWormForm.validate() && rq.projectclassificator != null || rq.wormNodeUuid != null) {
        AlertService.info({info: this.$t('section.worm.addToProcess')})
        api.worm.exportWorm(rq)
        this.exportWorm.dialog = false
      }
    },


    recalculateWorm() {
      WormNode.commit((state) => {state.fetching = true})
      api.worm.recalculateprojectworm(this.projectWormSelected).then(() => {
        WormNode.commit((state) => {state.fetching = false})
        WormNode.api().fetchByProjectWorm(this.projectWormSelected)
      })
    }

  }
}
</script>

<style scoped>
.h100p {height: 100%;}

.sizable-block-right {
  display: inline-block;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  z-index: 998;
  float: right;
}

.icon-pos {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.wormTree {
  /* min-width: 200px !important; */
  overflow-y: hidden;
}

.column-height{
  height: 100%;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.t-scroll-body {
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-primary-lighten4);
}
</style>
<style lang="scss">
  #wormpannel {
    max-height: 100%;
    height: 100%;
    min-width: 533px;
  }
</style>