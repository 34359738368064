<template lang="pug">
app-section.full-height
  v-progress-linear.mt-2(v-if="loading" indeterminate color="accent")
  div.full-height.overflow-hidden(v-else)
    v-tabs-items.full-height.overflow-hidden( v-model="tab" light )
      v-tab-item.full-height.overflow-hidden
        .d-flex.mb-2
          app-text {{ $t('section.elementTree.totaGroupsElements', {proxyGroups: proxyGroupLength, totalElements: totalElements}) }}
          v-spacer
          v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassGroupSelect") 
            | {{ allGroupsSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
            v-icon.ml-1(color="accent" size="16" ) {{ allGroupsSelected ? 'remove_done' : 'done_all' }}

        highlight-groups-list( ref="highlightGroupsListRef" 
                                :elements="items"
                                :selectedGroups="selectedGroups"
                                @toggleGroup="toggleGroup"
                                @selectGroup="selectGroup" )

      v-tab-item.full-height
        v-row.mb-2(no-gutters)
          v-col(cols=1)
            v-btn(@click="back" icon small color="accent")
              v-icon mdi-keyboard-backspace
          v-col(v-if="activeGroup" :title="`${activeGroup.title}; ${$t('section.elementTree.totalItemsGroup')} ${activeGroup.elements.length}`") 
            app-text.text-overflow.text-nowrap {{ activeGroup.title }}
            app-text.text-overflow.text-nowrap {{ $t('section.elementTree.totalItemsGroup') }} {{ activeGroup.elements.length }}
          v-col(v-if="activeGroup" cols=3) 
            v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassGroupElementsSelect") 
              | {{ allGroupElementsSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
              v-icon.ml-1( v-if="allGroupElementsSelected" color="accent" size="16" ) {{ allGroupElementsSelected ? 'remove_done' : 'done_all'}}
        highlight-active-group-list( v-if="activeGroup" 
                                      :elements="activeGroup.elements"
                                      :activeElement="activeElement"
                                      :selectedGroupElements="selectedGroupElements"
                                      @toggleElement="toggleElement"
                                      @selectElement="selectElement" )
</template>

<script>

import { mapActions } from 'vuex'
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { useHighlightGroupsStore } from '@/pinia/smeta/highlightGroups.store';
import HighlightActiveGroupList from './HighlightActiveGroupList.vue';
import HighlightGroupsList from './HighlightGroupsList.vue';

const highlightGroupsStore = useHighlightGroupsStore()

export default {
  name: 'HightLightGroups',
  
  components: {
    HighlightActiveGroupList,
    HighlightGroupsList
  },

  data () {
    return {
      tab:0,
      activeGroup: null,
      activeElement: null,
      selectedGroups: [],
      selectedGroupElements: [],
    }
  },

  mounted() {
    this.init()
    this.changeKeepElements()
  },

  beforeDestroy() {
    XeokitMediator.ElementsSelection.selectElements([])
  },

  watch: {
    pickedElement(){
      if(this.activeElement != this.pickedElement){
        this.activeElement = null
      }
    },
  },

  computed: {
    totalElements: () => highlightGroupsStore.totalElements, 
    proxyGroupLength: () => highlightGroupsStore.proxyGroupsLength,
    loading: () => highlightGroupsStore.loading,
    items: () => highlightGroupsStore.items,
    allGroupsSelected() {
      return this.selectedGroups.length == highlightGroupsStore.items.length
    },

    allGroupElementsSelected(){
      if (!this.activeGroup) return false
      else return this.activeGroup.elements.length == this.selectedGroupElements.length
    },

    pickedElement(){
      return XeokitMediator.ElementsSelection.pickedElement
    },
  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),

    init(){
      this.changeTab(0)
      this.clearActive()
      this.toggleMassGroupSelect()
    },

    changeKeepElements() {
      this.$refs.highlightGroupsListRef?.changeKeepElements()
    },

    changeTab(value) {
      this.tab = value
    },

    toggleMassGroupSelect(){
      if (this.allGroupsSelected) {
        this.clearActive()
      } else {
        this.selectedGroups = highlightGroupsStore.getProxyGroupsUuid()
        this.showSelectedGroups()
      }
    },

    selectGroup(groupUuid){
      let index = this.selectedGroups.findIndex(g => g == groupUuid)
      if (index>-1) {
        this.selectedGroups.splice(index,1)
      } else {
        this.selectedGroups.push(groupUuid)
      }
      this.showSelectedGroups()
    },

    selectElement(elementUuid){
      let index = this.selectedGroupElements.findIndex(g => g == elementUuid)
      if (index>-1) {
        this.selectedGroupElements.splice(index,1)
      } else {
        this.selectedGroupElements.push(elementUuid)
      }
      XeokitMediator.ElementsSelection.selectElements(this.selectedGroupElements)
    },

    showSelectedGroups(){
      let elem = []
      if (this.selectedGroups.length > 0) {
        this.selectedGroups.forEach(uuid => elem.push(highlightGroupsStore.getProxyGroupElementsUuids(uuid)))
      }

      XeokitMediator.ElementsSelection.selectElements(elem.flat())
    },

    clearActive(){
      this.activeElement = null
      this.selectedGroups = []
      XeokitMediator.ElementsSelection.selectElements([])
    },

    toggleMassGroupElementsSelect(){
      if (this.allGroupElementsSelected) {
        this.clearGroup()
      } else {
        this.selectedGroupElements = highlightGroupsStore.getProxyGroupElementsUuids(this.activeGroup.uuid)
        XeokitMediator.ElementsSelection.selectElements(this.selectedGroupElements)
      }
    },

    clearGroup(){
      this.selectedGroupElements = []
      XeokitMediator.ElementsSelection.selectElements([])
    },

    toggleElement(item){
      if (this.activeElement != null && item.uuid == this.activeElement) {
        this.activeElement = null
        XeokitMediator.ElementsSelection.pickElement(null)
        this.fetchElementByGlobalId(null)
      } else {
        this.activeElement = item.uuid
        XeokitMediator.ElementsSelection.pickElement(this.activeElement)
        this.fetchElementByGlobalId(item?.uuid)
      }
    },

    toggleGroup(item){
      this.activeGroup = item
      this.changeTab(1)
      this.selectedGroupElements = highlightGroupsStore.getProxyGroupElementsUuids(item.uuid)
      XeokitMediator.ElementsSelection.selectElements(this.selectedGroupElements)
    },

    back(){
      this.clearGroup()
      this.changeTab(0)
      this.activeGroup = null
      this.activeElement = null
      this.fetchElementByGlobalId(null)
      this.showSelectedGroups()
      this.changeKeepElements()
      XeokitMediator.ElementsSelection.pickElement(null)
    }
  },
}

</script>

<style scoped>
.overflow-hidden {overflow: hidden;}
.full-height {
  height: 100%;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-nowrap {
  white-space: nowrap;
}
</style>