<template lang="pug">
div 
  .section-header.mt-3 {{ $t('section.collision.rulesFounder.attributesForControl') }}
  div( v-for="condition in sortAttributeConditions" test-id="attr-checking-condition")
    div.d-flex.align-center.mt-2 
      v-tooltip(bottom)
        template(v-slot:activator="{ on, attrs }" )
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            .text-wrapper( v-bind="attrs" v-on="on" :class="{ 'elevation-1': hover }" ripple @click="openAttributeCondition(condition)" ) {{ getText(condition) }}
        span {{ getText(condition) }}
      app-icon-button( icon="mdi-dots-vertical" iconColor="black" @contextmenu="onRuleMenu(condition, $event)" @click="onRuleMenu(condition, $event)" )
  app-icon-button.float-left.mt-1(v-if="hasCollisionCreateRule" icon="$add-box-outlined" iconColor="black" test-id="add-attr-checking" @click="openAttributeCondition()")
  app-icon-button.float-left.mt-1(v-if="hasCollisionCreateRule" icon="$excel-outlined" iconColor="black" @click="show.importExcelDialog = true" )
  attribute-checking-condition(v-model='show.attributeCondition' :condition='edit.attributeCondition' @created="updateAttributeCondition")
  rule-import-excel-dialog( v-model="show.importExcelDialog" )

  //- app-menu(ref='attribute-to-control-menu' :menu='menu')
  app-menu( ref="attributeMenu" :menu="attributeMenu" )

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AttributeCheckingCondition from '@/components/project/panel/left/components/rulesFounder/ruleSetting/AttributeCheckingCondition.vue'
import RuleImportExcelDialog from '@/components/project/panel/left/components/rulesFounder/ruleSetting/RuleImportExcelDialog.vue'

// function uuidv4() {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//     var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
//     return v.toString(16);
//   });
// }


export default {
  name: "TestAttributeChecking",

  components: { AttributeCheckingCondition, RuleImportExcelDialog },
  
  data() {
    return {
      show: {
        attributeCondition: false,
        importExcelDialog: false
      },
      edit: {
        attributeCondition: null
      },

      attributeConditions: [],
      menuItem: null,
    }
  },

  computed: {
    ...mapGetters('rule/details', ['rule']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule']),
    ...mapState('condition', ['defaultCondition']),

    menu() {
      return [
        {title: this.$t("button.edit"), action: () => { this.openAttributeCondition(this.edit.attributeCondition) }},
        {title: this.$t("button.delete"), action:() => { this.deleteAttributeCondition(this.edit.attributeCondition) }},
      ]
    },

    isAttributeCondtionsEmpty() {
      return this.attributeConditions.length === 0
    },

    attributeMenu() {
      return [
        { title: this.$t('button.delete'), action: ()=>this.deleteAttributeCondition(this.menuItem)},
      ]
    },

    sortAttributeConditions(){
      return this.attributeConditions.slice().sort((a, b) => this.getText(a).localeCompare(this.getText(b)))
    }
  },

  mounted() {
    if (this.rule.finder.className.includes("AttributeClashRule")) {
      this.attributeConditions = this.rule.sampleB.map(sample => sample.logicGroup.condition[0])
    }
  },

  methods: {
    getText(condition) {
      let text = condition.title + "  |  " + this.$t(`section.elementTree.condition.${condition.operator.name}`)
      if (condition.operator.name != "EX" && condition.operator.name != "ISNUM" && condition.operator.name != "ISSRT") text += " " + (condition.value ?? '')
      return text
    },
    
    onRuleMenu (item,event) {
      this.menuItem = item
      this.$refs.attributeMenu.show(event)
    },

    openAttributeMenu(event, condition) {
      this.$refs['attribute-to-control-menu'].show(event)
      this.edit.attributeCondition = condition
    },
    

    openAttributeCondition(condition = null) {
      this.edit.attributeCondition = condition || { ...this.defaultCondition }
      
      const defaultOperand = {
        cond: 1, group: 1, name: "ATTRIBUTE", title: this.$t('form.formFields.attrField'), value: 5
      }
      if (!condition) {
        this.edit.attributeCondition.operand = defaultOperand
        this.edit.attributeCondition.operator = { name: "EQ", title: this.$t('section.elementTree.condition.EQ'), value: 0 }
      }
      this.show.attributeCondition = true
    },

    updateAttributeCondition(condition) {
      if (condition.temporaryUuid || condition.uuid) {
        this.updateExistingCondition(condition)
      }
      else {
        condition.temporaryUuid = this.uuidv4()
        this.attributeConditions.push(condition)
      }
      this.show.attributeCondition = false
      this.$emit('update')
    },

    updateExistingCondition(condition) {
      let uuid = condition.temporaryUuid || condition.uuid

      for (let index = 0; index < this.attributeConditions.length; index ++) {
        if (this.attributeConditions[index].temporaryUuid === uuid || this.attributeConditions[index].uuid == uuid) {
          this.attributeConditions.splice(index, 1, condition)
          break
        }
      }
    },

    // TODO Временно
    deleteAttributeCondition(condition) {
      this.attributeConditions = this.attributeConditions.filter(existingCondition => existingCondition !== condition)
      this.$emit('update')
    },

    buildLogicGroup() {
      let samples = []
      this.attributeConditions.forEach((cond) => {
        let sample = this.rule.sampleB?.find((sample) => cond.uuid == sample.logicGroup.condition[0].uuid)
        if (sample){
          sample = JSON.parse(JSON.stringify(sample))
          sample.logicGroup.condition = [cond]
          samples.push(sample)
        } 
        else samples.push(this.buildSample(cond))
      })
      return samples
    },

    refresh() {
      this.attributeConditions = this.rule.sampleB.map(sample => sample.logicGroup.condition[0])
    },

    buildSample(condition) {
      return {
        title: null,
        options: [],
        logicGroup: {
          condition: [condition],
          uuid: null
        }
      }
    },

    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-wrapper {
    background-color: #EBEBEB;
    border-radius: 3px;
    font: normal 14px/16px $roboto;
    color: #4D4D4D;
    padding: 8px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .text-wrapper:hover {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  }

  .section-header {
    font: normal 12px/16px $roboto;
    color: #303030;
  }
</style>