<template lang='pug'>
  .player
    v-tooltip(bottom)
      template( #activator="{ on, attrs }" )
        v-btn.mr-2(v-bind="attrs" v-on="on" x-small icon @click='startPlay(Date.parse(playerDate))')
          v-img(:src="playing ? '/img/project/tools/player_pause.svg' : '/img/project/tools/play_button.svg'")
      span {{ playing ? $t("module.task.gantt.stopPlaying") : $t("module.task.gantt.startPlaying") }}
    
    v-menu(offsetX offsetY)
      template( #activator="{ on, attrs }" )
        span(v-bind="attrs" v-on="on") {{ calendarDate }}
      v-date-picker(v-model='playerDate' first-day-of-week=1 :min='pickerMinDate' :max='pickerMaxDate' @input='changeDate')

    v-tooltip(bottom)
      template( #activator="{ on, attrs }" )
        v-btn.ml-1.reverse(v-bind="attrs" v-on="on" x-small icon @click="goToDate(-MILLISECONDS_IN_DAY * 7)")
          v-img(src='/img/project/tools/player_skip_double.svg')
      span {{ $t('module.task.gantt.weekAgo') }}

    v-tooltip(bottom)
      template( #activator="{ on, attrs }" )
        v-btn.reverse.mr-2(v-bind="attrs" v-on="on" x-small icon @click="goToDate(-MILLISECONDS_IN_DAY)")
          v-img(src='/img/project/tools/player_skip.svg')
      span {{ $t('module.task.gantt.dayAgo') }}
    
    v-slider( v-model="date" :max="lastTaskDate + MILLISECONDS_IN_DAY" :min="firstTaskDate - MILLISECONDS_IN_DAY" :step="MILLISECONDS_IN_DAY" thumb-size="100" height='20' color="#757575" track-color="#757575" ticks dense hide-details @input='changeDate')
      template( #thumb-label="{ value }" ) {{ today }}      
    
    v-tooltip(bottom)
      template( #activator="{ on, attrs }" )
        v-btn.ml-2(v-bind="attrs" v-on="on" x-small icon @click="goToDate(MILLISECONDS_IN_DAY)")
          v-img(src='/img/project/tools/player_skip.svg')
      span {{ $t('module.task.gantt.dayAhead') }}

    v-tooltip(bottom)
      template( #activator="{ on, attrs }" )
        v-btn.mr-2(v-bind="attrs" v-on="on" x-small icon @click="goToDate(MILLISECONDS_IN_DAY * 7)")
          v-img(src='/img/project/tools/player_skip_double.svg')
      span {{ $t('module.task.gantt.weekAhead') }}
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name: "NewGanttHeader",

  data() {
    return {
      playing: false,
      playerDate: this.today,
      date: 0,
      lastHighlightState: null,

      MILLISECONDS_IN_DAY: 86400000
    }
  },

  computed: {
    ...mapGetters('task', ['endDateAfter', 'lastTaskDate', 'firstTaskDate']),
    ...mapState('task', ['tasks']),

    today() {
      let date = new Date(this.date)
      let year =  date.getUTCFullYear().toString()

      let month = (date.getMonth() + 1).toString()
      month = month.length != 2 ? "0" + month : month 
      
      let day = date.getDate().toString()
      day = day.length != 2 ? "0" + day : day 

      return year + "-" + month + "-" + day
    },

    calendarDate() {
      if (this.playerDate) return this.playerDate
        
      let now = new Date()
      let year =  now.getUTCFullYear().toString()

      let month = (now.getUTCMonth() + 1).toString()
      month = month.length != 2 ? "0" + month : month 
      
      let day = now.getDate().toString()
      day = day.length != 2 ? "0" + day : day
      
      return year + "-" + month + "-" + day
    },

    pickerMaxDate() {
      let max = new Date(this.lastTaskDate + this.MILLISECONDS_IN_DAY)
      
      let year = max.getFullYear()
      let month = max.getMonth() + 1
      let day = max.getDate()

      day = day < 10 ? '0' + day : day
      month = month < 10 ? '0' + month : month

      return year + "-" + month + "-" + day
    },

    pickerMinDate() {
      let min = new Date(this.firstTaskDate - this.MILLISECONDS_IN_DAY)
      
      let year = min.getFullYear()
      let month = min.getMonth() + 1
      let day = min.getDate()

      day = day < 10 ? '0' + day : day
      month = month < 10 ? '0' + month : month
      return year + "-" + month + "-" + day
    }
  },

  watch: {
    date() {      
      this.playerDate = this.today
      this.showReadiness(this.date)

      this.$emit('update', this.date)
    }
  },

  async mounted() {
    if(this.tasks.length === 0) {
      await this.loadTasks()
    }

    this.date = Date.parse(new Date())
    this.showReadiness(Date.parse(this.playerDate))
    this.$store.dispatch('selected/projectSkeletonization')
  },

  beforeDestroy() {
    // this.$store.dispatch('selected/dropAllSmetaHighlights')
    // this.$store.dispatch('selected/dropColorized')
    
    // TODO Переделать под новое XKT API
    const scene = XeokitMediator.viewer.scene
    const visibleObjects = scene.objectIds.filter(objId => scene.objects[objId].opacity > 0)

    XeokitMediator.ElementsSettings.setElementsOpacity(visibleObjects, null)
    XeokitMediator.ElementsSettings.setElementsColorized(visibleObjects, null)
    // XeokitMediator.clearColorizeModel()
    // XeokitMediator.clearOpacityModel()
  },

  methods: {
    ...mapActions('task', ['loadTasks']),

    goToDate(date) {
      this.date += date
      this.changeDate(this.date)
    },

    showReadiness (date) {
      let sample = this.endDateAfter(date)
      if (!_.isEqual(this.lastHighlightState, sample)){
        this.$store.dispatch('selected/dropSmetaHighlights')

        this.$store.dispatch('selected/setElementsReadyState', sample.ready)
        // this.$store.dispatch('selected/setElementsNewState', sample.new)
        this.$store.dispatch('selected/setElementsInJobState', sample.inJob)
        this.$store.dispatch('selected/setElementsLatenessState', sample.lateness)
        this.lastHighlightState = sample
      } 
      else if (!this.lastHighlightState) {
        this.lastHighlightState = sample
      }
    },

    dropReadiness () {
      this.$store.dispatch('selected/dropSmetaHighlights')
    },

    async startPlay (date) {
      if (!this.playing) {
        this.playing = true
        for (this.date = date; this.date <= this.lastTaskDate; this.date += this.MILLISECONDS_IN_DAY * 5) {
          if (!this.playing) break;
          await this.waitSomeTime(300)
          if (this.playing) this.changeDate(this.date)
        }
        this.playing = false
      }
      else {
        this.playing = false
      }
    },

    changeDate(date) {
      let newDate = new Date(date)
      // if(typeof date === 'string') {
      //   this.date = newDate.getTime()
      // }
      // else 
      if(typeof date === 'number') {
        let year = newDate.getFullYear()
        let month = newDate.getMonth() + 1
        let day = newDate.getDate()

        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month

        this.playerDate = year + "-" + month + "-" + day
      }

      this.showReadiness(date)

      this.$emit('update', date)
    },

    waitSomeTime (time) {
      return new Promise((resolve) => {
        setTimeout(resolve, time)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .player {
    display: flex;
    align-items: center;
    color: #757575;
  }
  .reverse {
    transform: rotate(180deg);
  }
</style>