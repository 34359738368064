<template lang="pug">
component( :is="windowComponent" )
</template>

<script>
import { ToolWindow, useActiveToolWindowStore } from "@/stores/viewerTools/activeToolWindow.store"
const store = useActiveToolWindowStore()

export default {
  components: {
    [ToolWindow.SCENE_TYPES]: () => import('./windows/IFCTypesToolWindow.vue'),
    [ToolWindow.SCENE_GROUPS]: () => import('@/views/GroupsWindow/GroupsWindow.vue'),
    [ToolWindow.PLUGIN_CUBES]: () => import('./windows/PluginCubesToolWindow.vue'),
    [ToolWindow.PLUGIN_PLANES]: () => import('./windows/PluginPlanesToolWindow.vue'),
    [ToolWindow.PLUGIN_POINTS]: () => import('./windows/PluginPointsToolWindow.vue'),
    [ToolWindow.PLUGIN_RULER]: () => import('./windows/PluginRulerToolWindow.vue'),
  },

  computed: {
    windowComponent: () => store.activeWindow,
  },
}
</script>