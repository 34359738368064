<template lang='pug'>
app-section.primary.lighten-5
  v-menu( ref='datepickerMenu' :return-value.sync='dateStart' transition='scale-transition' min-width='auto' offset-y )
    template( v-slot:activator='{ on }' )
      v-text-field( v-model='dateStart' v-on='on' :label='$t("module.task.dateFrom")' prepend-icon='mdi-calendar' readonly light clearable )
    v-date-picker( v-model="dateStart" first-day-of-week=1 no-title scrollable @input="$refs.datepickerMenu.save(dateStart)" )
  
  v-btn.text-none( :disabled='!dateStart' color='accent' small depressed light @click='handleRecalculate()' ) {{ $t('module.task.recalculateTime') }}
</template>

<script>
export default {
  name: 'TaskDatesRecalculate',
  
  data() {
    return {
      dateStart: new Date().toISOString().slice(0, 10),
    }
  },

  methods: {
    handleRecalculate() {
      this.$emit('recalculate', this.dateStart)
    }
  }

}
</script>

<style>

</style>