<template lang="pug">
  app-dialog( v-model="visible" :header="$t('section.collision.settings')" )
    app-section
      div(v-for="header in headers")
        .d-flex.justify-space-between
          .primary--text.header-text {{ header.text }}
          v-btn-toggle( v-model="header.align" variant="plain" light divided mandatory @change="saveAlign(header)" )
            v-btn( icon value="left" x-small )
              v-icon mdi-format-align-left
            v-btn( icon value="center" x-small )
              v-icon mdi-format-align-center
            v-btn( icon value="right" x-small )
              v-icon mdi-format-align-right
</template>

<script>
import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'

export default {
  data () {
    return {
      visible: false,
    }
  },

  computed: {
    ...mapStores(useAttributeCheckingTableStore),

    headers() {
      return this.attributeTableStore.visibleHeaders
    }
  },

  methods: {
    show () {
      this.visible = true
    },

    saveAlign(header) {
      this.attributeTableStore.saveHeaderAlign(header)
    }
  }
}
</script>

<style lang="scss">
.header-text {
  font-size: 16px;
  align-self: center;
}
</style>