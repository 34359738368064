<template lang="pug">
  app-dialog( v-model="$_show" :width='900' @close='clear()' complex)
    v-row( no-gutters )
      v-col( :cols="2" )
        app-panel-section( :title="$t('section.elementTree.selectingParam')" )
          condition-parameter.condition-parameters( :condAxisEnum='attributeConditionAxisEnum' @selectOperand="selectOperand($event)")
          div(v-for="item in attributeConditionAxisEnum")
            .manual-input( v-if="item && item.name == 'MANUALINPUT'" :class='{"accent--text": manualInput}' @click='setManualInput(true)') {{ $t('section.collision.rulesFounder.manualInput') }}
      
      v-col( v-if='!atMaterialsOrSystems' :cols="5" style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.selectingClassAttr')" )
          template(v-if='!manualInput')
            app-text-field(v-if='["MATERIALS", "SYSTEMS"].includes(activeAttributeTab)' v-model='MATERIALS_SYSTEMS_BASEATTR_search' label='поиск')
            app-text-field(v-else-if='activeAttributeTab !== "MANUALINPUT"' v-model='searching' label='поиск')

            template(v-if='isAttributeTabs')
              v-tabs.mb-2( v-model='activeAttributeTab' light show-arrows)
                v-tab(href='#BASEATTR') Базовые
                v-tab(href='#USERPROP') Наборы
                v-tab(href='#USERMANUALPROP') Универсальные
                v-tab(href='#MANUALINPUT') Вручную
                v-tab(href='#MASK' style='display: none;') {{ $t("section.elementTree.condition.MASK") }}
                v-tab(href='#ATTRIBUTE' style='display: none;') {{ $t("section.elementTree.condition.ATTRIBUTE") }}

                
              template(v-if='activeAttributeTab === "MANUALINPUT"')
                app-text-field(v-model='MANUALINPUT_prefix' label='Имя набора' @input='form.manualOperand = MANUALINPUT_prefix + "/" + MANUALINPUT_postfix')
                app-text-field(v-model='MANUALINPUT_postfix' label='Имя атрибута' @input='form.manualOperand = MANUALINPUT_prefix + "/" + MANUALINPUT_postfix')

              v-virtual-scroll(:items='conditionTreeCache.tree.items || []' item-height='24' height='200' ref='attributes-tree')
                template(v-slot:default='{ item }')
                  div._tree-item.d-flex.pa-1(@click='selectAttribute(item)')
                    div.ml-2(v-for='level in (item.level || 0)')
                    v-icon.item__toggle(v-if='item.children && item.children.length' :class='{ "item__toggle--close" : !conditionTreeCache.isOpened(item) }' color='primary lighten-2') arrow_drop_down
                    div.px-2(v-else)
                    span.ml-1.text-truncate(:style="( form.manualOperand == item.name ) || ( form.operandUuid == item.uuid ) ? activeClass : ''" test-id="condition-tree-item" :title="itemName(item)") {{ itemName(item) }}
          
          //- condition-class-attribute(
            v-if='!manualInput'
            ref='conditon-class-attribute'
            :conditionTreeCache="conditionTreeCache"
            @selectAttr='selectAttribute($event)'
          //- )
          .manual-input-field(v-if='manualInput')
            app-text-field( v-model='form.manualOperand' :placeholder='$t("section.elementTree.enterValue")' hide-details)
            div.ma-3
              app-text.text--lighten-3 {{ $t('app.markdownTextarea.helperExample') }}: 
              app-text.text--lighten-3.ml-2 {{ $t('section.elementTree.IFCtypeGroupName') }} <br/> {{ $t('section.elementTree.groupName') }} <br/> {{ $t('section.elementTree.name') }}

      v-col( :cols='atMaterialsOrSystems ? 4 : 2' style='padding-left: 1px;' )
        app-panel-section( :title="$t('section.elementTree.choosingComparison')" )
          app-text( v-for="item in (atMaterialsOrSystems ? EQ_CONT_operators : condOperatorAttrCheck)" :key="item.value")
            div( :style="form.operator && form.operator.name == item.name ? activeClass : ''" @click="selectOperator(item)" ) {{ $t(`section.elementTree.condition.${item.name}`) }}

      v-col( :cols='atMaterialsOrSystems ? 6 : 3' style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.selectingValue')" )
          template(v-if='atMaterialsOrSystems')
            template(v-if='form.operator && form.operator.name === "EQ"')
              app-text-field(label='Поиск Мета' v-model='MATERIALS_SYSTEMS_search' clearable @clear='MATERIALS_SYSTEMS_search = ""')
              //- v-virtual-scroll(:items='(conditionTreeCache.tree.items || []).filter(s => s.name.toLowerCase().includes((MATERIALS_SYSTEMS_search || "").toLowerCase()))' item-height='24' height='200')
              v-virtual-scroll(:items='(conditionTreeCache.tree.items || []).filter(s => s.name.toLowerCase().includes((MATERIALS_SYSTEMS_search || "").toLowerCase()))' item-height='24' height='200')
                template(v-slot:default='{ item }')
                  div._tree-item.d-flex.pa-1(@click='selectAttribute(item)')
                    div.ml-2(v-for='level in (item.level || 0)')
                    v-icon.item__toggle(v-if='item.children && item.children.length' :class='{ "item__toggle--close" : !conditionTreeCache.isOpened(item) }' color='primary lighten-2') arrow_drop_down
                    div.px-2(v-else)
                    span.ml-1.text-truncate(:style="( form.manualOperand == item.name ) || ( form.operandUuid == item.uuid ) ? activeClass : ''" test-id="condition-tree-item" :title="itemName(item)") {{ itemName(item) }}
            template(v-else)
              app-text-field(:label='$t("section.elementTree.enterValue")' v-model='form.value' :rules='[rules.required]')
            
          template(v-else)
            div( v-if="form.operator && (form.operator.name!='EX' && form.operator.name!='NOTEX' && form.operator.name!='ISSTR' && form.operator.name!='ISNUM')" )
              app-select.select-maxw( v-if="form.operandUuid != null && (currentType == 'ENUM' || currentType == 'BOOL')" v-model="form.value" :items="attrTypeEnum" :label="$t('section.elementTree.enterValue')" item-value="value" )
              app-text-field( v-else v-model="form.value" :label="$t('section.elementTree.enterValue')" :rules="[this.rules.required]")
              app-text.ml-3.mt-n2.text--lighten-3(v-if="form.operator.name == 'RANGE'") Например: 1-10
              app-text.ml-3.mt-n2.text--lighten-3(v-if="form.operator.name == 'IN'") Например: 1,5,7

    v-row.align-center.white( slot='footer' no-gutters style="margin-top: 1px;" )
      app-button.ml-auto.my-2.mr-2( action :disabled='!valid' @click='saveCondition()' test-id="attr-checking-save") {{ $t('button.save') }}
</template>

<script> 
import ConditionParameter from '@/components/project/panel/left/components/logicGroup/conditionSections/ConditionParameter.vue'
import ConditionClassAttribute from '@/components/project/panel/left/components/logicGroup/conditionSections/ConditionClassAttribute.vue'

import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'
import { projectService } from '@/_services'
import values from "@/assets/model/collision/AttributeCheckingValues"

import { $_validate_rules } from '@/common/ValidateRules'
import DialogToggable from '@/components/app/DialogToggable'

export default {
  name: "AttributeCheckingCondition.vue",

  mixins: [$_validate_rules, DialogToggable],
  
  components: { ConditionParameter, ConditionClassAttribute },

  props: {
    condition: Object
  },

  data() {
    return {
      MANUALINPUT_prefix: '',
      MANUALINPUT_postfix: '',
      MATERIALS_SYSTEMS_search: '',

      attrTypeEnum: null,
      conditionTreeCache: new ConditionTreeCache(),
      manualInput: false,
      recursionFlag: null,
    }
  },

  watch: {
    $_show (value) {
      if (value) {
        let baseObj = this.condition || this.defaultCondition
        this.setForm(JSON.parse(JSON.stringify(baseObj)))
        this.show()
      }
    },
  }, 

  computed: {
    ...mapGetters('project', ['axisEnum', 'projectUuid']),
    ...mapState('condition', ['form', 'defaultCondition', 'currentType', 'search']),
    ...mapGetters('condition', ['activeClass', 'condOperatorAttrCheck']),
    ...mapState('copyutil', ['copiedElementAttribute']),

    activeAttributeTab: {
      get() {
        return this.form.operand && this.form.operand.name
      },
      set(val) {
        this.form.operand = this.axisEnum.find(axis => axis.name === val)
        this.selectOperand(this.form.operand)
      }
    },

    attributeConditionAxisEnum() {
      return this.axisEnum.filter(a => a.name == 'ATTRIBUTE' || a.name == 'MANUALINPUT')
    },
    
    attributeCheckingValues() {
      return values
    },

    valid() {
      let valueCanEmpty = this.form.operator && (this.form.operator.name=='EX' || this.form.operator.name=='NOTEX' || this.form.operator.name=='ISSTR' || this.form.operator.name=='ISNUM') ? true : false
      /*return this.manualInput ? 
        ( this.form.manualOperand && this.form.value && this.form.value.trim() ) : 
        ( this.form.value && this.form.value.trim() !== "" && this.form.operandUuid ) */
      if (this.activeAttributeTab === 'MATERIALS' || this.activeAttributeTab === 'SYSTEMS') {
        return !!this.form.value
      }
      return (this.manualInput || this.activeAttributeTab === 'MANUALINPUT') ? 
        ( (this.activeAttributeTab === 'MANUALINPUT' ? this.MANUALINPUT_postfix && this.MANUALINPUT_prefix : true) && this.form.manualOperand && ( valueCanEmpty || (this.form.value && this.form.value.trim()) ) ) :
        ( (this.form.operandUuid || ['MATERIALS', 'SYSTEMS', 'BASEATTR'].includes(this.activeAttributeTab)) && ( valueCanEmpty || (this.form.value && this.form.value.trim() !== "") ) )

    },

    searching: {
      get() {
        return this.search
      },
      set(val) {
        this.setSearch(val)
        this.conditionTreeCache.search(this.search)
      }
    },

    isAttributeTabs() {
      return ['BASEATTR', 'ATTRIBUTE', 'USERPROP', 'USERMANUALPROP', 'MANUALINPUT', 'MASK'].includes(this.activeAttributeTab)
    },

    atMaterialsOrSystems() {
      return this.activeAttributeTab === 'MATERIALS' || this.activeAttributeTab === 'SYSTEMS'
    },

    EQ_CONT_operators() {
      return this.condOperatorAttrCheck.filter(operator => operator.name === 'EQ' || operator.name === 'CONT')
    },
  },

  methods: {
    ...mapMutations('condition', ['setLoaded', 'setCurrentType', 'setSearch', 'setForm']),
    ...mapActions('condition', ['selectOperator']),
    ...mapMutations('copyutil', ['setCopiedElementAttribute']),

    selectOperand(operand, isCopy = false) {
      this.setLoaded(false)
      this.setManualInput(false)

      if (!isCopy) {
        this.form.value = null
        this.form.operandUuid = null
        this.form.operand = operand

        this.setSearch('')
      }

      return projectService.loadParamForCondition(this.projectUuid, operand.name).then(data => {
        if (['MATERIALS', 'BASEATTR'].includes(operand.name)) {
          data = data.map(item => ({ name: item, title: item, uuid: item }))
        }
        this.conditionTreeCache.setItems(data)

        let title = ""
        if( this.form.manualOperand ) {
          if( this.form.manualOperand.includes("/") ) title = this.form.manualOperand.split("/")
          if( title.length >= 2 ) {
            title = title[title.length - 2]
          } 
          if (operand.name === 'MANUALINPUT') {
            const [prefix, postfix] = this.form.manualOperand.split('/')

            this.MANUALINPUT_postfix = postfix || ""
            this.MANUALINPUT_prefix = prefix || ""
          }
        }

        // this.setSearch(title)
        this.searching = title
        this.setLoaded(true)
        this.recursionFlag = false
        if( this.form.manualOperand ) this.recursionPickedElement(this.conditionTreeCache.tree.items, isCopy) 
      })
    },

    recursionPickedElement( objArray, copy = false ) {
      let arr = this.form.manualOperand.split('/')
      let titleString = "'" + this.form.manualOperand + "' "
      let titleArr = this.reducedСonditions ? titleString.split('/') : this.form.manualOperand.split('/')
      for( let i = 0; i < objArray.length; i++ ) {
        if( ( objArray[i].name == arr[ arr.length - 1 ] || objArray[i].name == titleArr[ titleArr.length - 1 ] ) && !objArray[i]?.children?.length ) {
          this.selectAttribute( objArray[i], true )
          this.recursionFlag = true
        }
        if( objArray[i]?.children?.length && !this.recursionFlag && !copy && ( arr.indexOf(objArray[i].name) !== -1 ) ) {
          this.handleTreeOpen( objArray[i])
          this.recursionPickedElement( objArray[i].children )
        }

        if( copy ) {
          for( let j = 0; j < arr.length; j++ ) {
            if( objArray[i].children.length && !this.recursionFlag && objArray[i].name == arr[j] ) {
              this.handleTreeOpen( objArray[i] )
              this.recursionPickedElement( objArray[i].children, true )
            }
          }
        }
      }
    },

    setManualInput(state) {
      this.manualInput = state

      if (state) {
        this.form.operand = {
          name: ""
        }
      }
    },

    selectAttribute(item) {
      if(item.type && item.type.name != "SET" && item.type.name != "CLASS" && item.type.name != "CLASSIFICATOR"){
        this.setCurrentType( item.type.name )
        this.form.operandUuid = item.uuid 
        this.form.manualOperand = item.title || item.name

        if(this.currentType == "ENUM") projectService.loadBClassEnumElements(item.uuid).then(data => this.attrTypeEnum = data)
        if(this.currentType == "BOOL") this.attrTypeEnum = [{"value":"true", "text":this.$t('section.elementTree.yes')}, {"value":"false", "text":this.$t('section.elementTree.no')}]
      }
      else {
        this.form.manualOperand = item.name
      }

      this.handleTreeOpen(item)
    },

    selectValue(value) {
      this.form.value = value
    },

    handleTreeOpen(item) {
      if (this.conditionTreeCache.isOpened(item)) {
        this.conditionTreeCache.close(item)
      }
      else {
        this.conditionTreeCache.open(item)
      }
    },

    saveCondition() {
      this.form.title = this.buildTitle()

      this.condition.title = this.form.title
      this.condition.operand = this.manualInput ? null : this.form.operand
      this.condition.manualOperand = (this.manualInput || this.activeAttributeTab === 'MANUALINPUT' || ['MATERIALS', 'SYSTEMS', 'BASEATTR'].includes(this.activeAttributeTab)) ? this.form.manualOperand : null
      this.condition.operandUuid = ['MATERIALS', 'SYSTEMS', 'BASEATTR'].includes(this.activeAttributeTab) ? null : this.form.operandUuid
      if(this.form.operator?.name == 'ISSTR' || this.form.operator?.name == 'ISNUM'){
        this.condition.value = null
      } else {
        this.condition.value = this.form.value
      }
      this.condition.operator = this.form.operator

      if (this.copiedElementAttribute) this.setCopiedElementAttribute(null)

      this.$emit('created', this.condition)
      this.clear()
    },

    buildTitle() {
      if (this.manualInput || this.activeAttributeTab === 'MANUALINPUT' || ['MATERIALS', 'SYSTEMS', 'BASEATTR'].includes(this.activeAttributeTab)) {
        return this.form.manualOperand
      }
      let title = ''
      let item = this.conditionTreeCache.getById(this.form.operandUuid)
      let parents = this.conditionTreeCache.getAllParents(item).reverse()

      parents.map(parent => {
        title += parent.name + "/"
      })
      title += item.name
      return title
    },

    clear() {
      this.setSearch('')
      this.setForm({ ...this.defaultCondition })
    },

    show() {
      if (this.copiedElementAttribute) {

        let operand = this.copiedElementAttribute.operand ? this.copiedElementAttribute.operand : 'USERPROP'
        this.form.operand = this.attributeConditionAxisEnum.find(e => e.name == operand)

        if(this.form.operand) {
          this.form.operator = this.condOperatorAttrCheck.find(e => e.name == 'EQ')
          this.form.value = this.copiedElementAttribute.stringValue
          this.form.operandUuid = this.copiedElementAttribute.elementClass
          this.form.title = this.copiedElementAttribute.title
          if( this.copiedElementAttribute.title.includes("'") ) {
            this.form.manualOperand = this.copiedElementAttribute.title.split("'")[1]
          }

          this.selectOperand(this.form.operand, true)
          return
        }   
      }

      if (this.condition && this.condition.operand) {
        this.selectOperand(this.condition.operand).then(() => {
          if (this.condition.operandUuid) {
            this.$nextTick(() => {
              let title = this.condition.title.slice(this.condition.title.lastIndexOf("/") + 1)
              this.searching = title
              // this.setSearch(title)

              this.form.operandUuid = this.condition.operandUuid
              this.form.value = this.condition.value
            })
          } 
          else if (this.activeAttributeTab === 'MANUALINPUT' || ['MATERIALS', 'SYSTEMS', 'BASEATTR'].includes(this.activeAttributeTab)) {
            this.form.value = this.condition.value
          }
        }) 
      } 
      else if (this.condition && this.condition.manualOperand) {
        this.setManualInput(true)
        this.form.manualOperand = this.condition.manualOperand
        this.form.value = this.condition.value
      }
    },

    itemName(item) {
      return (item.name && item.name!='') ? item.name : (item.title && item.title!='') ? item.title : (item.classTitle && item.classTitle!='') ? item.classTitle : this.$t('section.elementTree.name')
    },
  }
}
</script>

<style scoped>
.h-100 {
  height:100%;
  max-height:300px;
  min-height:300px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.manual-input {
  font: normal 12px/16px "Roboto", sans-serif;
  color: var(--v-primary-base);
}
.condition-parameters {
  height: auto !important;
}
:first-child>>>.v-tabs-bar {
  height: 24px;
}
:first-child>>>.v-tab {
  text-transform: none; 
}
._tree-item { 
  font: normal 12px/16px "Roboto", sans-serif;
  color: black;
  transition: .1s;
  white-space: nowrap;
  text-overflow: revert-layer;
  overflow: hidden;
}
._tree-item:hover {
  background: #f0f0f0;
}
.item__toggle {
  font-size: 16px;
}
.item__toggle--open {
  transform: none;
}
.item__toggle--close {
  transform: rotate(-90deg);
}
</style>