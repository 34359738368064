<template lang="pug">
  app-panel.pt-1.px-1.py-2( ident='clouds' )
    task-header.mb-2( :title="$t('toolbarMenu.cloudSync')" headerItemsCenter mainTitle )
      template( v-slot:headerAfter )
        app-icon-button( icon="mdi-close" @click="handleClose" )
        
    app-section.m-2.floor-unit-container.overflow-auto
      app-text-field( v-model.trim="project.cloudRoot" :label="$t('toolbarMenu.pathToProjectInCloud')" )

      .d-flex.mt-4
        .align-content-start
          v-btn.text-none.edit-project__footer-button(color='error' @click='cancelEditting()') {{ $t('button.cancel') }}
        v-spacer
        .align-content-end
          v-btn.text-none.edit-project__footer-button( color="accent" :disabled="validation" @click="saveChanges()" ) {{ $t('button.save') }} 

</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex' 
import TaskHeader from '@/components/task/TaskHeader'

export default {
  data () {

    return {
      project: {},
    }
  }, 

  mounted () {
    
  },

  components: {
    TaskHeader
  },

  computed: {
    ...mapGetters("project", ["projectShortcut"]),
    
    validation() {
      return !this.project.cloudRoot
    }
  },

  beforeMount() {
    this.project = Object.assign({}, this.projectShortcut)
  },

  methods: {
    ...mapActions('project', ['setProjectCloudPath', 'postPanelsStatus']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },

    saveChanges() {
      this.setProjectCloudPath({uuid:this.project.uuid, path:this.project.cloudRoot}).then(() => {
        this.handleClose();
      })
    },

    cancelEditting() {
      this.handleClose();
    }
  },
}
</script>
