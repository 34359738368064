<template lang="pug">
div
  app-section.base-scroll-body( v-if="rule" style="height: 100%" )
    div( v-if='rule.sampleA' )
      .section-header.mt-1( :class="getRulePosition()" )
        span( v-if='finderClass === "DublicateClashRule"' ) {{ $t('section.collision.rulesFounder.dublicate') }}
        span( v-else ) {{ sampleATitle }}
        span( v-if='finderClass != "AttributeClashRule" && finderClass != "DublicateClashRule" && finderClass != "IFCValidateRule"' ) {{$t("section.collision.rulesFounder.secondElements")}}
      
      div( :class="getRulePosition()" )
        .d-flex.align-center( style="width: 100%" test-id="base-element" )
            v-tooltip( bottom )
              template( v-slot:activator="{ on, attrs }" )
                v-hover( v-slot:default="{ hover }" close-delay="150" )
                  .text-wrapper( v-bind="attrs" v-on="on" :class="{ 'elevation-1': hover, 'sample-edit': rule.sampleA.new || rule.sampleA.edit }" @click="showConditionDialog(rule.sampleA, false)" ) {{ rule.sampleA.title }}
              span {{ rule.sampleA.title }}
            app-icon-button.mx-1( icon="mdi-eye" iconColor="var(--v-accent-base)" :disabled="!isSampleAHasConditions(rule.sampleA)" @click="showButton(rule.sampleA)" test-id="sampleA-show-elements" )
            
            .menu-item( v-if='finderClass === "DublicateClashRule" || finderClass === "AttributeClashRule"  || finderClass === "IFCValidateRule"' )
              app-icon-button(v-if="hasCollisionCreateRule" icon="mdi-dots-vertical" iconColor="black" @contextmenu="onRuleMenu" @click="onRuleMenu" )
        div
          general-intersection( ref='general-intersection' v-if='finderClass === "OccurrenceClashRule" || finderClass === "DataBaseClashRule" || finderClass === "PluginClashRule"' 
                                @showConditionDialog="showConditionDialog" @showViewButton="showButton"
                                @showSettings="showSettings" @clearRules="showDialogClear" )
          attribute-checking( ref='attribute-checking' v-else-if='finderClass === "AttributeClashRule"'
                              @update="onSave" @clearElements="clearElements" )
          ifc-checking( v-else-if='finderClass === "IFCValidateRule"' ref='ifc-checking' ) 

        .menu-item( v-if='isMultipleIntersection')
          app-icon-button( icon="mdi-dots-vertical" buttonSize="24" iconColor="black" @contextmenu="onRuleMenu" @click="onRuleMenu" )

    .base-element.t.app-cursor--pointer( v-else @click="showConditionDialog(null, false)" test-id="add-base-element")
      app-icon-button( v-if="hasCollisionCreateRule" icon="$add-box-outlined" light )
      .section-header.ml-1 {{ $t("section.collision.rulesFounder.addBaseElement") }}

    div( v-if="isExceptionsAvailable" )
      general-exceptions( v-if='finderClass === "OccurrenceClashRule" || finderClass === "DataBaseClashRule" || finderClass === "PluginClashRule"' ref='general-exceptions' @showConditionExceptionsDialog="showConditionExceptionsDialog" @showViewButton = "showButton" @clearExceptionElements="clearExceptionElements")    

    app-dialog( v-if="dialog.condition" v-model="dialog.condition" width="1000" :header="$t('section.collision.rulesFounder.ruleSetting.header')" )
      rule-setting-conditions( v-model="wip.sample" :has-options="wip.isSampleB" :edit="edit" @close="onClose" :finderClass="finderClass")

    app-dialog( v-if="dialog.settings" v-model="dialog.settings" width="500" :header="rule.title")
      intersection-settings(v-model="settings.sample" @saveSettings="saveSettings")
    
    app-dialog-confirm( v-model="dialog.delete" delete @confirm="ruleClear" ) 
      app-text {{$t('section.elementTree.axis.clearRule', { rule: rule.title })}}
    
    app-menu( ref="ruleMenu" :menu="baseElementMenu" )
      
  .text-center( v-else )
    .py-5.primary--text {{ $t("section.collision.rulesFounder.notSelectedRule") }}
    .text-subtitle-2(style="color: #3B93AF;") {{ $t("section.collision.rulesFounder.selecteRuleOrCreate") }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import RuleSettingConditions from "./RuleSettingConditions.vue"
import AttributeChecking from "./RuleSettingAttributeChecking.vue"
import IfcChecking from "./RuleSettingIfcChecking.vue"
import GeneralIntersection from "./RuleSettingGeneralIntersection.vue"
import GeneralExceptions from "./RuleSettingGeneralExceptions.vue"
import IntersectionSettings from "./IntersectionSettings.vue"
import { api } from "@/api"

import { AlertService } from '@app/AlertService.js'
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";

export default {
  components: {
    RuleSettingConditions,
    AttributeChecking,
    GeneralIntersection,
    GeneralExceptions,
    IntersectionSettings,
    IfcChecking
  },

  data: () => ({ 
    wip: {
      sample: null,
      isSampleB: false,
      isSampleExceptionA: false,
      isSampleExceptionB: false,
      exceptionRule: null,
      viewModels: null,
      listUuid: [],
      activeElement: null,
    },
    dialog: { 
      condition: false,
      viewModel: false,
      settings: false,
      delete: false,
    },
    settings: {
      sample: null,
      options: null,
    },
    edit: false,

    finder: null,
  }),

  computed: {
    ...mapGetters('rule/details', ['finders', 'rule']),
    ...mapGetters('plugins', ['colisionPlugins']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule', 'hasCollisionView']),

    findersPlusPlugins() {
      let a = []
      a = a.concat(this.finders)
      .concat(this.colisionPlugins.map(e => {
        let o = {uuid:e.uuid, title:e.name, className:"com.pirsbim.collision.rule.PluginClashRule"}
        return o
      }))

      return a
    },

    type: {
      get () { 
        return this.finder || this.rule.finder
      },
      set (val) { 
        
        this.finder = { ...val } 
      }
    },

    finderClass() {
      return this.type.className.slice(this.type.className.lastIndexOf('.') + 1)
    },

    sampleATitle() {
      switch(this.finderClass) {
        case "DataBaseClashRule": return this.$t('section.collision.rulesFounder.baseElements')
        case "PluginClashRule": return this.$t('section.collision.rulesFounder.baseElements')
        case "AttributeClashRule": return this.$t('section.collision.rulesFounder.elementsForControl')
        case "IFCValidateRule": return this.$t('section.collision.rulesFounder.modelsForControl')
        case "OccurrenceClashRule": return this.$t('section.collision.rulesFounder.baseElements')
      }
      return ""
    },

    baseElementMenu() {
      let menu = []
      if (this.rule?.sampleA) {
        if (this.rule?.sampleB?.length > 0 && this.finderClass !== "AttributeClashRule" && this.finderClass !== "DublicateClashRule") {
          menu.push({ title: this.$t('section.collision.rulesFounder.ruleSetting.searchSettings'), action: () => this.showSettings() })
        }

        if(this.hasCollisionCreateRule) menu.push({ title: this.$t('button.clear'), action: () => this.showDialogClear() })
      }
      return menu
    },
    isExceptionsAvailable() {
      return this.rule?.sampleA && this.rule?.sampleB?.length > 0
    },

    isMultipleIntersection() {
      return (this.finderClass !== "AttributeClashRule" && this.finderClass !== "IFCValidateRule" && this.finderClass !== "DublicateClashRule") && this.rule?.sampleB?.length <= 1
    },

    createDefaultOptionsForSampleB() {
      return [
        { name: "collisionLimit", value: "true" },
        { name: "limitNumber", value: "10000" },
        { name: "calcDistance", value: "false" },
        { name: "excludeZone", value: "true" },
        { name: "excludeMaterial", value: "true" },
      ]
    },
  },

  methods: {
    ...mapActions('rule/details', ['saveRuleDetails', 'replaceSampleA', 'updateSampleBList', 'removeSampleB', 'selectRule', 'updateExceptionsList', 'removeSampleExceptions', 'clearRule']),
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),

    showConditionDialog (sample, isSampleB) {
      this.wip.sample = sample ? { ...sample, new: false, edit: true } : { title: this.rule.title, options: [], logicGroup: {}, new: true, edit: false }
      this.wip.isSampleB = isSampleB
      this.wip.isSampleExceptionA = false
      this.wip.isSampleExceptionB = false
      this.dialog.condition = true
      if (sample != null) {
        this.edit = true
      }
    },

    isSampleAHasConditions(rule){
      return rule?.logicGroup?.condition?.length > 0
    },

    showDialogClear() {
      this.dialog.delete = true
    },

    getRulePosition() {
      return {
        'rule-position': this.finderClass !== "AttributeClashRule" && (this.finderClass === "OccurrenceClashRule" || this.finderClass === "DataBaseClashRule" || this.finderClass === "PluginClashRule"),
        'check': this.finderClass === "AttributeClashRule"
      }

    },

    onRuleMenu (event) { 
      this.$refs.ruleMenu.show(event)
    },

    showSettings(sample = null) {
      if (sample)
        this.settings.sample = sample
      else
        this.settings.sample = this.rule.sampleB[0]
      this.dialog.settings = true
    },

    ruleClear(){
      this.clearRule(this.rule)
      this.clearElements()
    },

    clearElements() {
      this.$emit('clearElements')
    },

    clearExceptionElements(uuid) {
      this.$emit('clearExceptionElements', uuid)
    },

    saveSettings(options) {
      let rule = this.rule.sampleB.find(sample => sample.uuid === this.settings.sample.uuid)
      rule.options = options
      this.dialog.settings = false
      this.settings.sample = null
      this.onSave()
    },

    showButton(item){
      this.wip.viewModels = []
      this.wip.viewModels.cnt = 0
      this.wip.viewModels.title = item.title
      this.$emit('isLoading', true)
      api.collisionRules.viewSelectModel(item.uuid, this.projectUuid)  
        .then(data => {
          if (data === 'show_elements_invalid_uuid') {
            AlertService.error({data: { error_description: this.$t('section.collision.rulesFounder.elementsError') }})
          }
          else if(data != null && data.elements != null){
            this.wip.viewModels = data
            this.viewSelectModels(data)

            this.$emit('showElements', { list: this.wip.viewModels.elements, uuid: item.uuid })
          }

          this.$emit('isLoading', false)
        })
      // this.dialog.viewModel = true
    },

    viewSelectModels(data) {
      this.sort(data.elements)
      this.listUuid = []
      XeokitMediator.ElementsSelection.selectElements(data.elements.map(e => e.uuid))
    },

    sort(items){
        items.sort((a, b) => 
            a.name > b.name ? a.name > b.name
            : a?.name > b?.name
      )
    },

    clearViewElements(){
      XeokitMediator.ElementsSelection.selectElements([])
    },

    toggleElement(item){
      if (this.activeElement != null && item.uuid == this.activeElement.uuid) {
        this.activeElement = null
        this.fetchElementByGlobalId(null)
      } else {
        this.activeElement = item
        this.fetchElementByGlobalId(item?.uuid)
      }
    },
 
    showConditionExceptionsDialog(exception, sample, isSampleExceptionA, isSampleExceptionB){
      this.wip.exceptionRule= exception
      this.wip.sample = sample ? {...sample, new: false, edit: true } : { title: this.rule.title, options: [], logicGroup: {}, new: true, edit: false}
      this.wip.isSampleExceptionA = isSampleExceptionA
      this.wip.isSampleExceptionB = isSampleExceptionB
      this.wip.isSampleB = false
      this.dialog.condition = true
      if(sample != null){
        this.edit = true
      }
    },

    removeConditionExceptions (sample) {
      this.removeSampleExceptions(sample)
    },

    removeCondition (sample) {
      this.removeSampleB(sample)
    },

    onSave () {
      this.rule.finder = this.finder || this.rule.finder
      if (this.$refs['general-intersection']) {
        this.rule.sampleB = this.$refs['general-intersection'].samples
      }
      if (this.$refs['attribute-checking']) {
        this.rule.sampleB = this.$refs['attribute-checking'].buildLogicGroup()
      }
      if (this.$refs['general-exceptions'] ) {
        this.rule.exceptions = this.$refs['general-exceptions'].exceptionsRules
      }
      this.saveRule()
    },

    saveRule() {
      this.saveRuleDetails(this.rule).then(() => this.updateSettings())
    },

    onClose () {
      if(this.wip.isSampleB) {
        this.updateSampleBList({sample:this.wip.sample, edit:this.edit})
        if (this.wip.sample.options.length === 0) 
          this.wip.sample.options = this.createDefaultOptionsForSampleB
      }
      if(this.wip.isSampleExceptionA)this.updateExceptionsList({exceptionRule:this.wip.exceptionRule, sample:this.wip.sample, edit:this.edit, fl:true})
      if(this.wip.isSampleExceptionB)this.updateExceptionsList({exceptionRule:this.wip.exceptionRule, sample:this.wip.sample, edit:this.edit, fl:false})
      if (this.$refs['general-intersection'] && this.wip.isSampleB) {
        this.$refs['general-intersection'].updateSample(this.wip.sample)
      }
      else
          if (this.$refs['general-exceptions'] && (this.wip.isSampleExceptionA || this.wip.isSampleExceptionB)) {
            this.$refs['general-exceptions'].updateSampleExceptions(this.wip.sample)
          }
      else this.replaceSampleA(this.wip.sample)
      this.dialog.condition = false
      this.edit = false
      this.onSave()
    },

    updateSettings() {
      this.finder = this.rule ? { ...this.rule.finder } : null
      
      if (this.$refs['general-intersection']) {
        this.$refs['general-intersection'].refresh()
      }
      if (this.$refs['general-exceptions']) {
        this.$refs['general-exceptions'].refreshExceptions()
      }
      else if (this.$refs['attribute-checking']) {
        this.$refs['attribute-checking'].refresh()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.section-header {
  font: normal 12px/16px $roboto;
  color: #303030;
}

.rule-position {
  display: grid;
  grid-template-columns:minmax(40%, 47%) minmax(40%, 47%) auto;
  align-items: center;
}

.menu-item {
  justify-self: end;
}

.text-wrapper {
  background-color: #EBEBEB;
  border-radius: 3px;
  font: normal 14px/16px $roboto;
  color: #4D4D4D;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.base-element {
  display: flex;
  align-items: center;
}

.sample-edit {
  background: rgb(59, 147, 175, .3);
}
</style>