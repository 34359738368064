<template lang="pug">
div(id="grouptable" ref="grouptable")
  v-data-table(
    dense
    light
    :items="groupList"
    :height="tableHeight"
  
    disable-sort
    disable-pagination
    hide-default-footer
  )
    template(v-slot:top)
      collision-list-top-slider(:height.sync="tableHeight" @onSlide="changeSliderHeight")
      grouping-list-top( @updateAxisList="updateGroupAxisList" )
      v-progress-linear(v-if="store.isLoadingGrouping" indeterminate color="accent")

    template(v-slot:header)
      grouping-list-header(:sortBy="sortBy" :sortDesc="sortDesc" :columnMinWidth="columnMinWidth" )  

    template(v-slot:body)
      grouping-list-body(v-if="!store.isLoadingGrouping" :sliceItems="sliceItems" :start="start" 
      :groups="groups" :startHeight="startHeight" :endHeight="endHeight" @updateAxisList="updateGroupAxisList")
  
</template>

<script>
import TableSliderService from '../../collision/CollisionTableSliderService'
import CollisionListTopSlider from '../../collisionList/top/CollisionListTopSlider.vue'
import GroupingListTop from './GroupingListTop.vue'

import GroupingListBody from './GroupingListBody.vue'
import GroupingListHeader from './GroupingListHeader.vue'

import { useAxisGroupTableStore } from '@/pinia'
import { useGroupPaletteListState } from '@/states/groupPalette/groupPaletteList.state'

export default {
  components: {
    GroupingListTop,
    CollisionListTopSlider,
    GroupingListHeader,
    GroupingListBody,
  },

  data() {
    return {
      timeout: null,
      // timeout2: null,

      groupList: [],
      sliceItems: [],
      tableHeight: 350,
      itemPerPage: 30,
      itemHeight: 30,
      start: 0,
      columnMinWidth: 0,
      sortBy: null,
      sortDesc: false,
      prevScrollTop: 0
    }
  },

  mounted() {
    this.updateGroupAxisList()
    document.getElementsByClassName('v-data-table__wrapper')[0].onscroll = this.onScroll
  },

  computed: {
    store: () => useAxisGroupTableStore(),

    state: () => useGroupPaletteListState(),

    groups() {
      return this.state.fetchGroupPaletteList(this.store.selectedAxisGroupTable?.data?.uuid)
    },

    // limitedItems() {
    //   return this.store.treeAllVisibleGroupingNodes.slice(this.start, this.perPage + this.start)
    // },

    startHeight() {
      return this.start * this.itemHeight
    },

    endHeight() {
      return (this.groupList.length - (this.start + this.sliceItems.length)) * this.itemHeight
    },
  },

  watch: {
    'store.treeAllVisibleGroupingNodes'() {
      //remake this
      this.updateGroupAxisList()
    },

    groupList() {
      const end = Math.min(this.groupList.length, this.start + this.itemPerPage)
      this.sliceItems = this.groupList.slice(this.start, end) //remake this
      let paletteItems = []
      let correctArray = []

      this.groupList.forEach(group => {
        if (group.name) {
          this.groups.paletteItems.some(item => item.key.includes(group.name)) ? correctArray.push(group.name) : ''
        }
      })

      correctArray.forEach(name => {
        const matchingItems = this.groups.paletteItems.filter(item => item.key === name)
        paletteItems.push(...matchingItems)
      })

      const paletteLength = paletteItems.length
      const groupLength = this.groupList.length

      if (groupLength == paletteLength) {
        this.groupList.forEach((group, index) => {
          group.color = paletteItems[index].color
        })
      } else if (groupLength > paletteLength) {
        let counter = 0
        let parentColor = null

        this.groupList.forEach(group => {
          if (counter < paletteLength) {
            group.color = paletteItems[counter].color

            if (group.name == paletteItems[counter].key) {
              parentColor = paletteItems[counter].color
              counter++
            } else {
              group.color = parentColor
            }
          } else {
            group.color = parentColor
          }
        })
      }

      this.$nextTick(this.addSliders)
    },

    'store.visibleHeaders'() {
      this.$nextTick(this.addSliders)
    },
  },

  methods: {
    addSliders() {
      TableSliderService.addSliders(this.getTableHeight(), this.store.visibleHeaders, 'axisGroupTable', 'grouping-table-slider', 0)
    },

    changeSliderHeight() {
      TableSliderService.changeSliderHeight(this.getTableHeight())
    },

    getTableHeight() {
      if (this.sliceItems.length < this.perPage) {
        const height = this.sliceItems.length * this.rowHeight + 32
        return height > this.tableHeight ? this.tableHeight : height
      }
      return this.tableHeight
    },

    updateGroupAxisList(tree) {
      this.groupList = [...this.store.treeAllVisibleGroupingNodes]
      if (tree) this.loadInitialData()
    },

    loadInitialData() {
      const end = Math.min(this.groupList.length, this.start + this.itemPerPage)
      this.sliceItems = this.groupList.slice(this.start, end);
      this.getAttribute();
    },

    getAttribute() {
      let elWithoutAttributes = this.sliceItems.filter(el => el.attributeReceived === false).map(el => el.uuid)
      console.log(elWithoutAttributes)
      if (elWithoutAttributes.length) {
        this.store.loadElementAttrs(elWithoutAttributes).then(data => {
          if (data != {}) {
            this.sliceItems.forEach(item => {
              if (data[item.uuid]) {
                item.attributeList = data[item.uuid];
              }
              item.attributeReceived = true
            });
          }
        })
      }
    },

    calculatePosition(ev) {
      const scrollTop = ev.target.scrollTop
      if (this.prevScrollTop === scrollTop) return
      this.prevScrollTop = scrollTop;

      this.timeout && clearTimeout(this.timeout)
      this.timeout2 && clearTimeout(this.timeout2)

      this.timeout = setTimeout(() => {
        if (!this.groupList || !ev.target) return

        const maxStart = this.groupList.length - this.itemPerPage
        this.start = Math.max(0, Math.min(maxStart, Math.floor(scrollTop / this.itemHeight)))

        const end = Math.min(this.groupList.length, this.start + this.itemPerPage)
        this.sliceItems = this.groupList.slice(this.start, end)

        this.$nextTick(() => {
          ev.target.scrollTop = scrollTop //firefox fix
        })
      }, 15)

      this.timeout2 = setTimeout(() => {
        this.getAttribute()
      }, 50);
    },

    onScroll(ev) {
      this.calculatePosition(ev)
    },
  },
}
</script>

<style scoped lang="scss">
.virtual-scroll__parent {
  overflow: auto;
}

::v-deep .v-data-table {
  width: 100%;
  overflow-x: hidden;
}

::v-deep .v-data-table__wrapper {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}

::v-deep .v-data-table .v-data-table__wrapper table {
  width: max-content !important;
}

::v-deep .v-data-table-header tr th span {
  color: var(--v-primary-darken2);
}

::v-deep .v-data-table-header tr th i {
  color: var(--v-primary-darken2) !important;
}

::v-deep th,
::v-deep td {
  border-right: 1px solid var(--v-surface-base);
}

::v-deep th {
  white-space: nowrap;
}

::v-deep tr td {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  height: 32px !important;
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}

@-moz-document url-prefix() {
  .v-data-table__wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--v-accent-base) var(--v-scroll-base);
  }
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
