<template lang="pug">
v-tooltip( right open-delay=1000 )
  template( v-slot:activator="{ on, attrs }" )
    v-row.unit-row(v-on="on")
      v-hover( v-slot:default="{ hover }" close-delay="150" )
        .unit.app-cursor--context( :class="{'unit-active': isActive, 'elevation-5': hover, 'padding-for-icon': hover}" test-id="tree-unit-item" @click="toggle" ) 
          span {{ isAlias ? $t(model.title) : model.title }}
      //- app-button.eye-button( icon light small color='#666666')
      //-   v-icon mdi-eye-outline

  span {{ isAlias ? $t(model.title) : model.title }} 
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'OffsetGroupToolItem',

  props: {
    model: Object
  },

  computed: {
    ...mapState('project', ['selectedModel', 'compareRevisions']),

    isAlias () {
      return this.model.title.indexOf("notify.") != -1
    },

    isActive () {
      return this.selectedModel && this.selectedModel.uuid === this.model.uuid
    },
  },
  
  methods: {
    ...mapMutations('project', ['setSelectedModel', 'setCompareRevisions']),

    // Возможно нужно вернуть, если вдруг будут проблемы со скелетированием моделей и выходом из этого режима
    // clearCompareRevisions () {
    //   if ( this.compareRevisions ) {
    //     XeokitMediator.ModelsManager.setModelsVisible( XeokitMediator.viewer.scene.modelIds, true )
    //     XeokitMediator.ModelsManager.setModelsXrayed( XeokitMediator.viewer.scene.modelIds, false )
    //     this.setCompareRevisions(null)
    //   }
    // },

    async toggle () {
      // this.clearCompareRevisions()
      
      if (this.isActive) {
        await this.setSelectedModel(null)
      } 
      else {
        this.setSelectedModel(this.model)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.unit-row {
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 4px;
  flex-wrap: nowrap;
}

.unit {
  position: relative;
  padding: 8px;
  width: 100%;
  height: 32px;
  background-color: var(--v-surface-lighten1);
  border-radius: 5px;
  color: var(--v-primary-darken2);
  font: normal 12px/18px $roboto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unit-active {
  background-color:  var(--v-surface-base);
  border: 1px solid var(--v-surface-lighten2);
  color: var(--v-primary-darken2);
  font-weight: bold;
}

.padding-for-icon {
  padding-left: 16px;
}

.eye-button {
  margin-top: 2px;
  margin-right: 4px;
  margin-left: 4px;
}
</style>