import { render, staticRenderFns } from "./GroupList.vue?vue&type=template&id=aa644d76&scoped=true&lang=pug&"
import script from "./GroupList.vue?vue&type=script&lang=js&"
export * from "./GroupList.vue?vue&type=script&lang=js&"
import style0 from "./GroupList.vue?vue&type=style&index=0&id=aa644d76&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa644d76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VProgressCircular,VSkeletonLoader})
