<template lang="pug">
div.axis-treeview-container( ref="right-axis-treeview-container" )
    div( v-if="isTreeInPreparation && (!isAxisGroupsSelected || selectedAxisGroup)" )
      v-skeleton-loader( type="list-item-two-line" light )

    app-text.mt-3( v-else-if="treeAllVisibleNodes.length == 0 && (!isAxisGroupsSelected || selectedAxisGroup)" ) 
      v-icon.mx-2.mt-n1( color="primary lighten-3" ) info
      | {{ $t('section.elementTree.axis.elementNotFound') }}
      .ml-2 {{ $t('section.elementTree.axis.tryOtherRule') }}

    div( v-else-if='!isAxisGroupsSelected || selectedAxisGroup' :style="'height: 100%'")
      .d-flex.justify-space-between 
        v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassFolding" test-id="element-tree-collapse-expand") 
          v-icon.mr-1( color="accent" size="16" left ) unfold_more
          //- | {{ $t('section.elementTree.axis.collapseTree') }}
          | {{ areAllTreeNodesDisclosed ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
        v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassSelect" test-id="collision-treeview-select-all") 
          | {{ areAllEndpointsSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
          //- | Элементов: {{ totalEndpoints }}
          v-icon.ml-1( v-if="areAllEndpointsSelected" color="accent" size="16" ) remove_done
          v-icon.ml-1( v-else color="accent" size="16" ) done_all
          //- v-icon.ml-1( color="accent" size="16" ) library_add_check
      virtual-list.base-scroll-body.padd(
        ref="scTree"
        data-key="extraId"
        :data-sources="treeAllVisibleNodes"
        :data-component="itemComponent"
        :estimate-size="treeElementHeight"
        :keeps="treeElementKeeps"
        style="height: calc(100% - 20px); overflow-y: auto;"
        test-id="element-tree-list"
      )
      .total.mb-2( v-if="isTotalVisible" ) {{ $t('section.elementTree.axis.totalElements') }}: {{ totalEndpoints }}
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import VirtualList from 'vue-virtual-scroll-list'
import RightAxisSectionTreeviewItem from './RightAxisSectionTreeviewItem.vue'
import { AxisDataType } from '@/assets/model/axis'


export default {
  inheritAttrs: false,

  components: {
    VirtualList
  },

  props: {
    showCounter: Boolean,
  },

  data () {
    return {
      itemComponent: RightAxisSectionTreeviewItem,
      treeElementHeight: 24,
      treeElementKeeps: 20
    }
  },

  mounted(){
    const axisTreeContainer = this.$refs['right-axis-treeview-container']

    this.pageResizeObserver = new ResizeObserver(() => {
      this.treeElementKeeps = Math.ceil(axisTreeContainer.clientHeight / this.treeElementHeight) + 15 // было 10
    })

    this.pageResizeObserver.observe(axisTreeContainer)
  },

  computed: {
    ...mapGetters('right-axis', ['selectedAxis']),
    ...mapState('right-axis', ['selectedAxisGroup']),
    ...mapState('right-axis/tree', ['node', 'dataType', 'clickOnRow']),
    ...mapGetters('right-axis/tree', ['treeAllVisibleNodes', 'isTreeInPreparation', 'areAllTreeNodesDisclosed', 'areAllEndpointsSelected','totalEndpoints']),

    isTotalVisible () {
      return this.dataType == AxisDataType.COSTING
    },
    
    isAxisGroupsSelected() {
      return this.selectedAxis.value == 5
    },
  
    // pickedElement() {
    //   return XeokitMediator.ElementsSelection.pickedElement
    // },

  },

  watch:{
    node: "scrollToNode",

    // pickedElement(uuid) {
    //   this.fetchElementByGlobalId(uuid)
    // },
  },

  methods: {
    ...mapActions('right-axis/tree', ['expandAllTreeNodes', 'collapseAllTreeNodes', 'selectAllTreeEndpoints', 'deselectAllTreeEndpoints', 'fetchElementByGlobalId']),
    ...mapMutations('right-axis/tree', ['setClickOnRow']),

    scrollToNode(node) {
    
      if( node  && !this.clickOnRow ) {
        this.$nextTick(() => this.$refs.scTree.scrollToIndex(this.treeAllVisibleNodes.findIndex(n => n.extraId == node.extraId)))
      }
      this.setClickOnRow(false)
    },

    toggleMassFolding () {
      this.areAllTreeNodesDisclosed ? this.collapseAllTreeNodes() : this.expandAllTreeNodes()
    },

    toggleMassSelect () {
      if (this.areAllEndpointsSelected) {
        this.deselectAllTreeEndpoints()
      } else {
        this.expandAllTreeNodes()
        this.selectAllTreeEndpoints()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  text-align: center;
  margin-right: 12px;
  font-size: 12px;
  color: var(--v-accent-base);
  border-top: 1px dashed var(--v-accent-base);
}

.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;
}

.padd {
  height: calc(100% - 5px);
}
// .t-scroll-body {
//   overflow-y: auto;
//   height: 100% ;
//   overflow-x: hidden;
//   scrollbar-width: thin;
//   scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
// }

// .t-scroll-body::-webkit-scrollbar {
//   width: 5px;
//   background-color: var(--v-surface-lighten5);
// }
// .t-scroll-body::-webkit-scrollbar-thumb  {
//   background-color: var(--v-primary-lighten4);
// }
.axis-treeview-container {
  height: calc(100% - 42px);
}
</style>