<template lang="pug">
  app-dialog(v-if="maintenanceNotNull" v-model="$_show" width="400" :header="$t('section.equipment.maintenance.maintenanceDate')" :confirm-text="$t('button.save')" @confirm="saveDate()" :confirmDisabled='!maintenanceTask.maintenanceDate')
    app-section
      v-menu(ref='date' :close-on-content-click="false" :return-value.sync='maintenanceTask.maintenanceDate' transition='scale-transition' min-width='auto' offset-y )
        template( v-slot:activator='{ on }' )
          v-text-field.mr-2( v-model='maintenanceTask.maintenanceDate' v-on='on' :label='$t("section.equipment.maintenance.maintenanceDate")' prepend-icon='mdi-calendar' readonly light :rules="[rules.required]")
        v-date-picker( v-model="maintenanceTask.maintenanceDate" first-day-of-week=1 no-title scrollable @input="$refs.date.save(maintenanceTask.maintenanceDate)")    
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import { $_validate_rules } from '@/common/ValidateRules'
import { api } from '@/api'
import { mapGetters } from 'vuex'

export default {
  mixins: [$_validate_rules, DialogToggable],

  props: {
    maintenanceTask: null,
  },

  computed: {
  ...mapGetters('project', ['projectUuid']),

    maintenanceNotNull() {
      return this.maintenanceTask && this.maintenanceTask.maintenanceDate
    },
  },

  methods: {
    saveDate() {
      api.maintenance.editMaintenanceDate(this.maintenanceTask, this.projectUuid).then(() => {
        this.$emit('updateMDate')
      })
    },
  },
}
</script>
