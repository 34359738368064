<template lang="pug">
app-dialog( v-model="showDialog" header="Палитра групп" width="600" @close="onClose")
  app-section
    v-row.border-b
      v-col.pa-0(cols=6)
        GroupList( :selectedGroup="selectedGroup" @select="selectedGroup = $event" )
      v-col.pa-0.border-l(cols=6)
        GroupPalette( :groupId="selectedGroup" )
    v-row
      v-col.text-right
        app-button( @click="saveGroupPalette()" action :disabled="store && !store.isChanged") Сохранить
</template>

<script>
import GroupPalette from './subviews/GroupPalette.vue'
import GroupList from './subviews/GroupList.vue'
import { closeMenu } from '@/interactors/groupsWindow.interactor';
import { useGroupPaletteListState } from '@/states/groupPalette/groupPaletteList.state'

export default {
  components: {
    GroupPalette,
    GroupList,
  },

  props: {
    groupUuid: { type: String, default: null }
  },

  data: () => ({ 
    showDialog: true,
    selectedGroupUuid: null
  }),

  computed: {
    store: () => useGroupPaletteListState(),

    selectedGroup: {
      get() { return this.selectedGroupUuid ? this.selectedGroupUuid : this.groupUuid },
      set(value) { 
        this.store.saveGroupPaletteGroupHasChanged()
        this.selectedGroupUuid = value 
      }
    },
  },

  methods: {
    onClose: () => closeMenu(),

    saveGroupPalette () {
      this.store.saveGroupPalette()
    }
  }
}
</script>

<style scoped>
/* .border-b {
  border-bottom: 1px solid #c4c4c4;
} */
.border-l {
  border-left: 2px solid #919191;
  border-radius: 0px;
}
</style>