<template lang='pug'>
  div.r-list-item
    div.r-list-item-title(:class='{"r-selected": selected}')
      span {{ receiver.name }}
    div.r-list-item-actions.ml-auto.d-flex
      div(v-for='[icon, action, title] in actions')
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn(v-bind='attrs' v-on='on' icon small @click='emitAction($event, action)')
              v-icon(color='#4d4d4d' small) {{ icon }}
          span {{ $t(title) }}
</template>

<script>
export default {
  name: "ReceiverListItem",

  props: ['receiver', 'selected'],

  computed: {
    actions() {
      return [
        ['mdi-pencil', 'edit', 'button.edit'],
        ['mdi-delete', 'delete', 'button.delete'],
      ]
    }
  },

  methods: {
    emitAction(event, action) {
      event.stopPropagation()
      this.$emit('action', action)
    }
  }
}
</script>

<style>
  .r-list-item {
    color: var(--v-surface-base);
    display: flex;
    align-items: center;
  }

  .r-list-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .r-selected {
    color: var(--v-accent-base);
  }
</style>