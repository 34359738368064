<template lang="pug">
.search-elements-menu--body
  search-elements-table
</template>

<script>
import SearchElementsTable from './SearchElementsTable.vue'

export default ({
  components: {
    SearchElementsTable
  }
})
</script>

<style scoped>
.search-elements-menu--body {
  background-color: #fff;
  padding: 0;
}
</style>
