<template lang="pug">
div
  app-menu.menu(ref="menu" :menu="menu")

  change-status-dialog( ref="changeStatusDialog")
  reassign-dialog( ref="reassignDialog" )
  create-task-dialog( ref="createTaskDialog" )
  choose-task-dialog( ref="chooseTaskDialog" )
  make-group-dialog( ref="makeGroupDialog" )
  add-to-group-dialog( ref="addingGroupDialog" )
  edit-note-dialog( ref="editNoteDialog" )

  group-allow-dialog( ref="groupAllowDialog" )
  group-delete-dialog( ref="groupDeleteDialog" )
  group-edit-dialog( ref="groupEditDialog" )

  export-dialog( ref="exportDialog" )
</template>

<script>
  import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

  import ChangeStatusDialog from './dialogs/CollisionListChangeStatusDialog.vue'
  import ReassignDialog from './dialogs/CollisionListReassignDialog.vue'
  import CreateTaskDialog from './dialogs/CollisionListCreateTaskDialog.vue'
  import ChooseTaskDialog from './dialogs/CollisionListChooseTaskDialog.vue'
  import MakeGroupDialog from './dialogs/CollisionListMakeGroupDialog.vue'
  import AddToGroupDialog from './dialogs/CollisionListAddToGroupDialog.vue'
  import EditNoteDialog from './dialogs/CollisionListEditNoteDialog.vue'

  import GroupAllowDialog from '@/components/project/panel/left/components/collisionGroups/CollisionGroupsAllowDialog.vue'
  import GroupDeleteDialog from '@/components/project/panel/left/components/collisionGroups/CollisionGroupsDeleteDialog.vue'
  import GroupEditDialog from '@/components/project/panel/left/components/collisionGroups/CollisionGroupsEditDialog.vue'

  import ExportDialog from './dialogs/CollisionListExportDialog.vue'

  export default {
    components: {
      ChangeStatusDialog,
      ReassignDialog,
      CreateTaskDialog,
      ChooseTaskDialog,
      MakeGroupDialog,
      AddToGroupDialog,
      EditNoteDialog,

      GroupAllowDialog,
      GroupDeleteDialog,
      GroupEditDialog,

      ExportDialog,
    },

    data() {
      return {
        clickType: null,
        selectedRow: [],
        selected: false
      }
    },

    computed: {
      ...mapState('collision/table', ['selectedCollisions']),
      ...mapGetters('collision/table', ['selectedCollisionsUuid']),
      ...mapGetters('project', ['camera']),
      ...mapGetters('projectPermissions', ['hasTaskCreate', 'hasCollisionAddToTask', 'hasCollisionStatus', 'hasCollisionControlGroupView', 
                                           'hasCollisionResolution', 'hasCollisionCreateReport', 'hasCollisionNotes']),

      menu() {
        if (this.clickType === 'group') {
          let list = []

          if(this.hasCollisionResolution) list.splice(1, 1, { title: this.$t('section.collision.groupMenu.allow'), action: this.showAllowGroupDialog })
          if(this.hasCollisionControlGroupView) list.push( 
            { title: this.$t('section.collision.rowMenu.goToCamera'), action: this.goToCamera }, 
            { title: this.$t('section.collision.rowMenu.saveCamera'), action: this.saveCamera }, 
            { title: this.$t('button.edit'), action: this.showEditGroupDialog },
            { title: this.$t('button.delete'), action: this.showDeleteGroupDialog })

          return list

        } else if(this.clickType === 'header'){
          let list = [{ title: this.$t('section.collision.menu.setWidthToDefault'), action: this.resetColumnWidth }]

          if(this.hasCollisionCreateReport) list.splice(0, 0, { title: this.$t('section.collision.menu.export.file'), action: this.showExportDialog })

          return list
        }
        else {
          let count = this.selectedCollisions?.length || 0
          let menu = []
          if(this.hasCollisionStatus) {
            menu.push(
              { title: this.$tc('section.collision.menu.changeStatus', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.showChangeStatusDialog }
            )
          }
          if (this.hasTaskCreate && this.hasCollisionAddToTask) {
            menu.push(
              { title: this.$tc('section.collision.menu.createTask', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.showNewTaskDialog },
              { title: this.$tc('section.collision.menu.addToTask', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.showTasksDialog }
            )
          }

          let list = [
            { title: this.$t('section.collision.rowMenu.applyToGroup'), disabled: count < 2, action: this.changeApplyToGroup, checkbox: {isActive: true, data: this.selected}},
            { divider: true },
            { title: this.$t('section.collision.menu.resetSelected'), action: this.resetSelectedCollisions },
            // { title: this.$tc('section.collision.menu.reassign', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.reassignAllSelected },
            ...menu,
          ]

          if(this.hasCollisionNotes) list.push({ title: this.$tc('section.collision.menu.addNoteToGroup', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.showNoteEditDialog },)
            
          if(this.hasCollisionControlGroupView) list.splice(15, 0, 
          { title: this.$tc('section.collision.menu.addToGroup', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.showAddToGroupDialog },
          { title: this.$tc('section.collision.menu.selectToGroup', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.showCreateGroupDialogSelected }
          )

          if(this.hasCollisionControlGroupView) list.push({ title: this.$tc('section.collision.menu.saveCameraToGroup', this.selected ? [count] : 0), disabled: this.selected && count < 1, action: this.saveCamera })

          return list
        }
      },
    },

    methods: {
      ...mapActions('collision/table', ['selectCollisions', 'setGroupCamera', 'resetColumnWidth']),
      ...mapMutations('collision/table', ['SET_SELECTED_GROUP']),
      ...mapActions('collision', ['setCamera', 'setCameraToGroup']),

      showMenu(event, item, clickType) {
        this.clickType = clickType
        this.selectedRow = item
        if (this.selectedCollisions?.length >= 2) this.selected = true
        else this.selected = false
        this.$refs.menu.show(event)
      },

      changeApplyToGroup(selected, typeClick){
        if (typeClick)
          this.selected = !selected
        else
          this.selected = selected
      },

      showChangeStatusDialog() {
        this.$refs.changeStatusDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      resetSelectedCollisions() {
        this.SET_SELECTED_GROUP([])
        this.selectCollisions([])
      },

      reassignAllSelected() {
        this.$refs.reassignDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      showNewTaskDialog() {
        this.$refs.createTaskDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      showTasksDialog() {
        this.$refs.chooseTaskDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      showCreateGroupDialogSelected() {
        this.$refs.makeGroupDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      showAddToGroupDialog() {
        this.$refs.addingGroupDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      showNoteEditDialog() {
        this.$refs.editNoteDialog.show({ single: !this.selected, item: this.selectedRow })
      },

      saveCamera() {
        if (this.selectedRow.groupTitle || this.selectedRow.groupUuid) {
          this.setGroupCamera({ uuid: this.selectedRow.groupUuid, camera: JSON.stringify(this.camera()) })
        }
        else if (this.selected) {
          this.setCameraToGroup({ uuids: this.selectedCollisionsUuid, camera: JSON.stringify(this.camera()) })
        }
        else {
          if (this.selectedRow.uuid)
            this.setCamera({ uuid: this.selectedRow.uuid, camera: JSON.stringify(this.camera()) })
        }
      },

      showExportDialog() {
        this.$refs.exportDialog.show()
      },

      // groups
      showAllowGroupDialog() {
        this.$refs.groupAllowDialog.show(this.selectedRow)
      },
      showEditGroupDialog() {
        this.$refs.groupEditDialog.show(this.selectedRow)
      },
      showDeleteGroupDialog() {
        this.$refs.groupDeleteDialog.show(this.selectedRow)
      },
      goToCamera() {
        this.$emit('goToView', this.selectedRow.groupUuid)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
