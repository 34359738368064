<template lang="pug">
  v-expansion-panels( light flat hover ripple )
    v-expansion-panel( ref='axis-rules-panel' )
      v-expansion-panel-header {{ $t('section.elementTree.axis.ruleTree') }}
      v-expansion-panel-content
        axis-section-rules-treeview( @edit:rule="onEditRule" )
</template>

<script>
import AxisSectionRulesTreeview from "./AxisSectionRulesTreeview";

export default {
  components: {
    AxisSectionRulesTreeview
  },

  mounted () {
    this.$store.dispatch('rule/init')
    this.$refs['axis-rules-panel'].toggle()
  },

  methods: {
    onEditRule (rule) {
      this.$emit('edit:rule', rule)
    },
  }
}
</script>