<template lang="pug">
  app-dialog(v-if="dialog" v-model="dialog" width="400" :header='$t("section.equipment.maintenance.assignMaintenanceHeader")' :confirm-text="$t('button.save')" @confirm="addMaintenance()" :confirmDisabled='confirmDisabled')
    app-section
      app-text.mb-2 {{$t('section.equipment.form.equipment')}}: {{ sheduleStore.selectedEquipment.name }}
      app-select(label='Выбранное ТО' v-model="selectedRuleUuid" :items="maintenanceRules" item-text="title" item-value="uuid" :rules="[rules.requiredSimple]" )  
      app-text-field(v-model="num" :label="$t('section.equipment.maintenance.duty')" :rules="[rules.duty]")
      v-menu(ref='date' :close-on-content-click="false" :return-value.sync='date' transition='scale-transition' min-width='auto' offset-y )
        template( v-slot:activator='{ on }' )
          v-text-field.mr-2( v-model='date' v-on='on' :label='$t("section.equipment.maintenance.maintenanceDate")' prepend-icon='mdi-calendar' readonly light :rules="[rules.required, rules.date]")
        v-date-picker( v-model="date" first-day-of-week=1 no-title scrollable @input="$refs.date.save(date)") 
</template>

<script>
import { useMaintenanceDialogStore, useMaintenanceScheduleStore } from '@/pinia'

export default {
  data() {
    return {
      num: 1,
      date: null,
      maintenanceRules: [],
      selectedRuleUuid: null,
      dialog: false,

      rules: {
        duty: (value) =>
          (!!value && value > 0 && value <= Number(this.store.maintenanceTaskSettings.numDuty)) ||
          'Смена должна быть в диапазоне от 1 до ' + this.store.maintenanceTaskSettings.numDuty,
        required: (value) => (!!value && !!value?.trim()) || 'Обязательно к заполнению.',
        requiredSimple: (value) => !!value || 'Обязательно к заполнению.',
        date: (value) =>
          new Date(value).getTime() >= new Date(this.store.maintenanceTaskSettings.schedulerStartDate).getTime() ||
          'Дата ТО не должна быть меньше даты начала отчета',
      },
    }
  },

  computed: {
    store: () => useMaintenanceDialogStore(),
    sheduleStore: () => useMaintenanceScheduleStore(),

    confirmDisabled() {
      return (
        !this.num ||
        Number(this.num) <= 0 ||
        this.num > Number(this.store.maintenanceTaskSettings.numDuty) ||
        !this.date ||
        new Date(this.date).getTime() < new Date(this.store.maintenanceTaskSettings.schedulerStartDate).getTime() ||
        !this.selectedRuleUuid
      )
    },
  },

  methods: {
    addMaintenance() {
      const obj = {
        equipmentUuid: this.sheduleStore.selectedEquipment.uuid,
        maintenanceRuleUuid: this.selectedRuleUuid,
        maintenanceDate: this.date,
        dutyNum: this.num,
      }
      this.store.addMaintenanceTask(obj)
      this.dialog = false
    },

    showDialog() {
      this.maintenanceRules = []
      this.selectedRuleUuid = null
      this.num = 1
      this.maintenanceTask = null
      this.date = this.sheduleStore.selectedDate

      this.store.loadEquipmentRules(this.sheduleStore.specificationUuid).then((rules) => (this.maintenanceRules = rules))
      this.dialog = true
    },
  },
}
</script>
