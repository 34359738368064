<template lang="pug">
  .task-tree-status
    task-priority.ml-1.mr-2.mt-2(:task="task" theme='light' useMenu)
    task-status.ml-auto.mr-auto(:task="task" useMenu )
</template>

<script>
import TaskPriority from '../TaskPriority.vue'
import TaskStatus from '../TaskStatus.vue'
export default {
  name: "TaskTreeStatus",
  components: { TaskPriority, TaskStatus },
  props: {
    task: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .task-tree-status{
    display: flex;
    color: #4D4D4D;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 4px 0px 6px;
    min-width:100px; 
    flex:0;
  }
</style>