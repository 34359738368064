<template lang="pug">
    app-dialog( v-model="$_show" :header="header")
        app-section.t-scroll-body
            v-treeview(
                :items="cloudWebDavTree"
                :load-children="openCloudTree"
                :open.sync="open"
                open-on-click
                transition
                hoverable
                light
            )
                template(v-slot:prepend="{ item }")
                    v-icon(@click="downloadFromCloud(item)") {{ item.type==1 ? 'folder' : item.ext==='ifc' ? 'file_download' : 'attach_file' }}
                template(v-slot:label="{ item }")
                    div(@click="downloadFromCloud(item)") 
                        div(v-bind:class="item.ext!=='ifc' && item.type!=1 ? 'grey--text lighten-5--text' : ''") {{ item.name }}
                        .text-caption.grey--text.lighten-5--text(v-if="item.size > 0") 
                            .d-inline-flex {{ item.sizeText }}
                            .d-inline-flex.ml-5(v-if="item.lastModify") {{ item.lastModify }}
</template>

<script>

import DialogToggable from '@/components/app/DialogToggable'

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    name: "file-tree",
    mixins: [DialogToggable],

    data() {
        return{
            active: [],
            open: [],
        }
    },
    computed: {
        ...mapState('project', ['selectedModel','project']),
        ...mapGetters('cloud', ['cloudWebDavTree']),
        ...mapState('project', ['selectedModel','project']),
    },
    props: {
        cloudForm: null,
        header: null,
    },
    methods: {

        ...mapActions('cloud', ['cloudWebDavDownloadFile', 'cloudWebDavSearchByPath']),
        ...mapMutations('project', ['setTopUnit']),

        downloadFromCloud(item) {
            if (item.ext!=='ifc' && item.type!=1) return 
            if (item.type==0) {
                this.cloudForm.path = item.path
                this.cloudForm.fileHash = item.hash
                this.cloudForm.modelUuid = this.selectedModel.uuid
                let obj = {
                    filter: this.cloudForm,
                    fileSize: item.size
                }
                this.cloudWebDavDownloadFile(obj).then( () => {
                    if (!this.cloudWebDavError) {
                        this.setTopUnit('process')
                        this.$_show = false
                        this.$store.dispatch('project/loadProject', this.project.uuid)
                    }
                })
            }
        },
        openCloudTree (item) {
            this.cloudForm.path = item.path
            this.cloudWebDavSearchByPath( {item: item, cloudWebDav: this.cloudForm})
        },
    }
}

</script>

<style scoped>
.t-scroll-body {
  overflow-y: auto;
  height: 50vh;
  scrollbar-width: thin;
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-primary-lighten3);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-surface-lighten3);
}
</style>
