<template lang="pug">
div
    .header
      .title
        span {{selectedMnemoschema.title}}

      .buttonGroup
        .closeMnemoschemaList
          v-btn(color='accent' fab x-small @click="closeMnemoschemaList")
            v-icon mdi-format-list-bulleted
        .closeButton
          v-btn.exitGrapghViewer(color='accent' fab x-small @click="closeGraphViewer")
            v-icon mdi-close
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:"GraphViewerHeader",
  computed:{
    ...mapState('mnemoschema', ['selectedMnemoschema']),
  },
  methods:{
    
    closeGraphViewer(){
      this.$emit('closeGraphViewer')
    },

    closeMnemoschemaList(){
      this.$emit('closeMnemoschemaList')
    }
  }

}
</script>

<style scoped>
.header{
  padding: 5px;
  position: absolute;
  width: 100%;
  background: #6B6B6B;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items:center
}
.exitGrapghViewer{
  position: relative;
  margin-left: auto;
}
.title{
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}
.buttonGroup{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  gap:15px
}
</style>