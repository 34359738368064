<template lang="pug">
  app-dialog(v-model="$_show" :header="header" width="700" complex )
    app-panel
      app-section

        app-text-field(v-model="searchText" :label="$t('section.classification.menuClassification.codeSearch')" outlined dense clearable hide-details)
        //- v-skeleton-loader(v-if="treeLoading" type="list-item-two-line" light)

        div(v-if="mode == 'RULE'")
          div.mh300.my-2.base-scroll-body
            v-treeview.__project_view_tree(
                  ref="simpleSmetaItemsTree"
                  dense
                  light
                  hoverable
                  item-disabled="disabled"

                  :active.sync="activeItemForRule"
                  activatable

                  open-on-click
                  :open.sync="openedNodesForRule"
                  item-key="uuid"
                  :items="treeListForRule"
                  :search="searchText"
                  item-text="pressmark"
                  
                  return-object)
                  template(v-slot:label="{ item }")
                    div(:title="item.pressmark ? item.pressmark+' - '+item.title : item.title")
                      span(:class="{strong: item.type.name == 'RULE'}") {{ item.pressmark ? item.pressmark+' - ' : '' }} {{ item.title }}

        div(v-else)
          v-form(ref="compoundForm" @submit.prevent="handleSave")
            div.base-scroll-body.mh300.my-2
              div.my-3(v-if="!searchMode")
                v-skeleton-loader(v-if="treeLoading" type="list-item-two-line" light)
                v-treeview.__project_view_tree(
                  dense
                  light
                  hoverable
                  item-disabled="disabled"
                  :active.sync="activeItem"
                  open-on-click
                  :open.sync="openedNodes"
                  item-key="uuid"
                  :items="treeList"
                  :load-children="loadChildrens"
                  return-object)
                  template(v-slot:label="{ item }")
                    div(@click="selectItem(item)") {{ item.pressmark ? item.pressmark+' - ' : '' }}{{ item.title }}
                    
              div.my-3(v-if="searchMode")
                v-list(v-if="!searchLoading" light dense)
                  v-list-item-group(v-model="searchSelected")
                    v-list-item(v-for="item in searchList" :key="item.uuid" :disabled="item.nodeType.value != nodeType.value") {{ item.pressmark ? item.pressmark+' - ' : '' }}{{ item.title }}

            v-alert(v-if="nodeError" dense text type="error") {{ nodeErrorText }}

            .d-flex
              app-text-field(v-model="compound.rate" :label="$t('section.classification.menuClassification.outgo')" required :rules="[rules.required, rules.double]")

            //- v-checkbox.mx-n1(v-model="compound.showInSmeta" dense hide-details light)
              template(v-slot:label)
                app-text.mx-n1 {{ $t('section.classification.menuClassification.displaySmeta') }}
            
            .d-flex
              v-spacer
              app-button( action type="submit") {{ $t('button.save' )}}
</template>

<script>
import DialogToggable from "@/components/app/DialogToggable"
import { mapState } from "vuex";

import { api } from "@/api";

export default {
  mixins: [DialogToggable],
  components: {

  },

  props: {
    oldCompound: Object,
    nodeType: Object,
    wormCompounds: Array,
    mode:{
      type: String,
      default: 'RULE'
    }
  },

  data() {
    return {
      compound: {
        targetCompoundUuid:null,
        compoundedNodeUuid: null,
        rate: null,
        showInSmeta: false,
        classificatorFindRuleUuid: null,
        mode: null
      },

      treeLoading: false,

      treeListForRule: [],
      openedNodesForRule: [],
      activeItemForRule: [],

      treeList: [],
      openedNodes: [],
      activeItem: [],

      searchText: null,
      searchLoading: false,
      searchMode: false,
      searchList: [],
      searchSelected: null,

      nodeError: false,

      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      },
    }
  },

  mounted() {

  },

  computed: {
    ...mapState("project", ["project"]),

    nodeErrorText(){
      return this.$t('section.classification.menuClassification.classNotSelected')
    },

    header(){
      if (this.mode == 'RULE') return this.$t('section.classification.menuClassification.replaceCompoundToRule' )
      else return this.$t('section.classification.menuClassification.replaceCompoundToCompound', {name:this.nodeType.title} )
    }
  },

  watch: {
    $_show(val){
      this.nodeError = false
      this.treeList = []
      this.openedNodes = []
      this.activeItem = []
      this.searchSelected = null
      this.compound = {
        targetCompoundUuid: this.oldCompound?.uuid,
        compoundedNodeUuid: null,
        rate: null,
        showInSmeta: false,
        classificatorFindRuleUuid: null,
        mode: this.mode
      }
      if (val) {
        if (this.mode == 'RULE') this.listForRule()
        else this.listProjectClassofocators()
      }
    },

    searchText(val){
      if (this.mode != 'RULE'){
        if (val && val.trim() != '') {
          this.searchSelected = null
          this.activeItem = []
          this.search()
        } else {
          this.searchMode = false
          this.searchList = []
          this.searchSelected = null
        }
      }
    },

    searchSelected(val){
      if (val != null) this.compound.compoundedNodeUuid = this.searchList[val].uuid
      else this.compound.compoundedNodeUuid = null
    },

    activeItem(val){
      if (val && val[0]) this.compound.compoundedNodeUuid = val[0].uuid
      else this.compound.compoundedNodeUuid = null
    },

    activeItemForRule(val){
      if (val && val[0]) {
        this.compound.classificatorFindRuleUuid = val[0].uuid
        this.selectItemForRule(val[0])
      }
      else this.compound.classificatorFindRuleUuid = null
    },

    'compound.compoundedNode'(val){
      if (val) this.nodeError = false
      else this.nodeError = true
    }
  },

  methods: {
    search() {
      this.searchMode = true
      this.searchLoading = true
      api.smeta.searchForCompound(this.project.uuid, this.searchText).then(data=>{
        this.searchList = data
        this.searchLoading = false
      })
    },

    listForRule(){
      this.treeLoading = true
      api.smeta.onlywithrulestree(this.project.uuid).then(data => {
        this.treeListForRule = data
        this.treeLoading = false
      })
    },

    listProjectClassofocators() {
      this.treeLoading = true
      api.smeta.projclasslist(this.project.uuid).then((data) => {
        data.forEach(e => {
          e.children = []
          e.ispc = true
        })
        this.treeList = data
        this.treeLoading = false
      })
    },

    loadChildrens(item) {
      if (item.ispc) {
        api.smeta.topnodes(item.uuid).then((data) => {
          data = data.filter(d => d.nodeType.name != 'NODE')
          data.forEach(e => {
            delete e.children
            if (e.nodeType.name == 'TOPIC') e.children = []
            if (e.nodeType.name != 'TOPIC' && e.nodeType.name != this.nodeType.name) e.disabled = true
            if (this.wormCompounds.some(el => el.compoundedNode && el.compoundedNode.uuid == e.uuid)) e.disabled = true
          })
          item.children = data
          if (!this.openedNodes.includes(item.uuid)) this.openedNodes.push(item)
        })
      } else {
        api.smeta.childrennodesfast(item.uuid, item.projectClassificatorUuid).then((data) => {
          data.forEach(e => {
            delete e.children
            if (e.nodeType.name == 'TOPIC') e.children = []
            if (e.nodeType.name != 'TOPIC' && e.nodeType.name != this.nodeType.name) e.disabled = true
            if (this.wormCompounds.some(el => el.compoundedNode && el.compoundedNode.uuid == e.uuid)) e.disabled = true
          })
          item.children = data
          if (!this.openedNodes.includes(item.uuid)) this.openedNodes.push(item)
        })
      }
    },

    selectItem(item){
      if (item.nodeType && item.nodeType.name == this.nodeType.name) {
        this.activeItem = []
        this.activeItem.push(item)
        this.compound.targetCompoundUuid = this.oldCompound.uuid
      }
    },

    selectItemForRule(item){
      if (item) {
        this.compound = {
          targetCompoundUuid: this.oldCompound.uuid,
          classificatorFindRuleUuid: item.uuid,
          rate: null,
          showInSmeta: false,
          mode: this.mode
        }
        this.handleSave()
      }
    },

    handleSave(){
      if (this.mode == 'RULE') {
        this.$emit('replaceCompoundAction', this.compound)
      } else {
        if (this.$refs.compoundForm.validate() && this.compound.compoundedNodeUuid) {
          this.$emit('replaceCompoundAction', this.compound)
        } else {
          if (!this.compound.compoundedNodeUuid) this.nodeError = true
        }
      }
    },
  }
}
</script>

<style scoped>
.mh300 {
  height: 264px;
}
</style>