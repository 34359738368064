<template lang="pug">
app-dialog( v-model="$_show" header="Import BCF" width="500" :confirmText="$t('button.import')"
            :closeOnConfirm="false"
            :confirmDisabled="bcfFile == null ? true : false || importLoading"
            @confirm="saveBCF")
  app-section
    app-file-input( v-model="bcfFile" :formats="['.bcfzip', '.bcf']" label="BCF file" :maxSize="1.5e7" hideDetails )
    v-checkbox.mt-2( v-model="isNeedToFlip" dense label="Инвертировать секущие плоскости" light ) 
    v-progress-linear.mt-3(v-if="importLoading" indeterminate)
    div(v-if="importError")
      app-text {{ importError }}
</template>

<script>
import { mapState } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"
import { api } from "@/api"
export default {
  name: "TaskImportBCFDialog",
  mixins: [DialogToggable],

  props: {
    
  },

  data () {
    return {
      bcfFile: null,
      importLoading: false,
      importError: null, 
      isNeedToFlip: false
    }
  },

  watch: {
    $_show(){
      this.bcfFile = null
      this.importLoading = false
      this.importError = null
    }
  },

  computed: {
    ...mapState('project', ['project']),
  },

  methods:{
    saveBCF(){
      this.importLoading = true
      let formData = new FormData()
      formData.append('project', this.project.uuid)
      formData.append('file', this.bcfFile)
      formData.append('reverseClippingPlanes', this.isNeedToFlip)
      api.tasks.importBcf(formData).then(() => {
        this.importLoading = false
        this.$_show = false
        this.$emit('reloadtasks')
        
      }).catch(e => this.importError = e)
    }
  }
}
</script>