<template lang='pug'>
  app-dialog(v-model='show' header='Новое значение канала')
    div.d-flex.flex-column
      app-section
        app-text-field(v-model='dataValue' label='Введите значение')
      app-button.mt-2.ml-auto(color='accent' @click='save()') {{ $t('button.save') }}
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "CreateChannelDataDialog",

  props: {
    value: Boolean,
    channel: String,
  },

  data: () => ({
    dataValue: null,
  }),

  computed: {
    show: {
      get() {
        this.value && this.init()
        return this.value
      },

      set() {
        this.close()
      }
    }
  },

  methods: {
    ...mapActions('receiver', ['createChannelData']),

    close() {
      this.$emit('input', false)
    },

    save() {
      const tdata = {
        channel: this.channel,
        value: this.dataValue
      }

      this.createChannelData(tdata).then(() => {
        this.close()
      })
    },

    init() {
      this.dataValue = null
    }
  }
}
</script>

<style>

</style>