<template lang='pug'>
task-models-select-dialog( v-model='$_show' :models='translatedFlatList' :selected-models='task.models' :header='$t("module.task.addModel")' :confirmText='$t("button.save")' @confirm='(models) => setModels({ uuid: task.uuid, models })' )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import TaskModelsSelectDialog from '@/components/task/TaskModelsSelectDialog.vue'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SetTaskModelDialog',

  components: { TaskModelsSelectDialog },

  mixins: [DialogToggable],

  props: {
    task: Object,
  },

  computed: {
    ...mapGetters('project', ['flatlist']),

    translatedFlatList() {
      return this.flatlist.map(model => {
        model.name = this.$t(NotificationService.NotificationReplace(model.name))
        return model
      })
    },
  },

  methods: {
    ...mapActions('task', ['setModels']),
  }
}
</script>

<style>

</style>