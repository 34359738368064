import { render, staticRenderFns } from "./FileTree.vue?vue&type=template&id=5a94c66e&scoped=true&lang=pug&"
import script from "./FileTree.vue?vue&type=script&lang=js&"
export * from "./FileTree.vue?vue&type=script&lang=js&"
import style0 from "./FileTree.vue?vue&type=style&index=0&id=5a94c66e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a94c66e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VTreeview})
