<template lang="pug">
  v-row(dense).profile
    v-col
      .avatar 
        .avatar-letters {{ avatar }}
        //- Добавить цвет аватара при использовании
      .u-info
        div(v-bind:class="'fio-default'") {{ teammate }}
    v-col.pr-2(cols="auto")
      slot

</template>

<script>
export default {
  name: 'ProjectTeammate',
  props: {
    teammate: String
  },

  computed: {
    avatar () {
      return this.teammate[0].toUpperCase()
    },
  },

}
</script>

<style lang="scss" scoped>
.profile {
  margin-bottom: 16px;
}
.avatar {
  display: inline-block;
  font: normal 14px/24px $roboto;
  color: #505254;
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
}
.u-info {
  display: inline-block;
  margin-left: 8px;
  font: normal 14px/12px $roboto;
  max-width: calc(100% - 132px);
}
.fio {
  color: #505254;
}
.fio-default {
  color: #3B93AF;
}
</style>