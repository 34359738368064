<template lang="pug">
.node_label.pr-6(:title="source.title")
  v-row.pointer(no-gutters @click="toggleGroup(source)")
    v-col(cols=8) 
      div.text-overflow {{ source.title }}
    v-col.text-center(cols=2) {{ source.cnt }}
    v-col.text-center(cols=2 :title="source.value ? source.value : '-'") 
      div.text-overflow {{ (source.value == null || source.value == 0) ? '-' : source.value}}
  v-icon.node__checkbox( tag="button" size="17" light @click.stop="selectGroup(source.uuid)" ) {{ isGroupSelected ? 'check_box' : 'check_box_outline_blank' }}
</template>

<script>

export default {
  props: {
    source: { type: Object, default: null },
    selectedGroups: [],
    toggleGroup: Function,
    selectGroup: Function
  },

  data() {
    return {
      numberFormatter: new Intl.NumberFormat("ru-RU",{ maximumFractionDigits: 3, style: 'decimal', useGrouping: false })
    }
  },

  computed: {
    isGroupSelected() {
      return this.selectedGroups.includes(this.source.uuid)
    },
  }
}
</script>

<style scoped>
.node_label {
  white-space: nowrap;
  position: relative;
}
.node__checkbox {
  position: absolute;
  right: 10px;
  top: 0;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>