<template lang="pug">
div
  v-checkbox.settings-options-header(
    v-model="enableAnnotations", :label="$t('viewer.sectionPlane.showAnnotations')",
    dense, hide-details, light,
    @click="showAnnotations()"
  )
</template>

<script>
import { mapState, mapActions } from "vuex";
import { IFCAnnotationsDrawer } from "@/plugins/IFCAnnotations/IFCAnnotatationsDrawer.js"

export default {
  name: "Annotations",

  data() {
    return {
      enableAnnotations: false,
    };
  },

  computed: {
    ...mapState("project", ["project"]),
  },

  methods: {
    ...mapActions('IFCAnnotations', ['getIFCAnnotations']),

    showAnnotations() {
      if(this.enableAnnotations){
        let projectUuid = this.project.uuid
        this.getIFCAnnotations(projectUuid).then(data => {
          IFCAnnotationsDrawer.drawIFCAnnotations(data)
        })
      }
      else {
        IFCAnnotationsDrawer.clearDrawedEdges()
        IFCAnnotationsDrawer.clearDrawedPoints()
        IFCAnnotationsDrawer.clearDrawedTexts()
      }
    },
  },
};
</script>

<style>
</style>