<template lang="pug">
  .task-tree-progress
    .d-flex.align-center
      task-progress-bar(:task='task' percent color="#4D4D4D" fontSize='12px' @click="$emit('openReport', task)" ref='progressBar').ml-auto
</template>

<script>
import TaskProgressBar from '@/components/task/TaskProgressBar.vue'
export default {
  name: "TaskTreeProgress",
  components: {TaskProgressBar},
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  methods: {
    showProgressDialog() {
      this.$refs.progressBar.showEditProgress()
    }
  }
}
</script>

<style lang="scss" scoped>
  .task-tree-progress{
    display: flex;
    color: #4D4D4D;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 4px 0px 6px;
    min-width:100px; 
    flex:0;
  }
</style>