<template lang="pug">
  .relative.gizmo
    div#myAxisCanvas
</template>

<script>
import { AxisGizmoPlugin } from '@/plugins/xeokit/AxisGizmoPlugin'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  mounted () {
    XeokitMediator.onReady(this.init)
  },

  methods: {
    init (viewer) {
      new AxisGizmoPlugin(viewer, {
        containerId: "myAxisCanvas",
        size: [120, 120] 
      })
    },
  }
  
}
</script>

<style scoped lang="scss">
#myAxisCanvas {
  width: 100px;
  height: 100px;
}
.relative {
  position: relative;
}

</style>