<template lang="pug">
    app-dialog(v-model="$_show" :header="title" @confirm="saveEquipment(equipment)" :confirmText="$t('button.save')" width="430" :confirmDisabled='!valid')
      v-form(ref="saveEquipmentForm" v-model="valid" @submit.prevent="submit")
        app-text-field.my-2(v-model="equipment.name" :label="$t('section.equipment.details.name')" hide-details :rules="[rules.required]")
        //- app-text-field.my-2(v-model="equipment.title" :label="$t('section.equipment.details.mark')" hide-details :rules="[rules.required]")
        //- app-text-field.my-2(v-model="equipment.manufacturer" :label="$t('section.equipment.details.manufacturer')" hide-details :rules="[rules.required]")
        v-row(no-gutters)
          v-col
            app-text-field.my-2(v-model="equipment.resourceMonths" :label="$t('section.equipment.details.resourceMonth')" hide-details :rules="[rules.number]")
          v-col.ml-4
            app-text-field.my-2(v-model="equipment.warrantyMonths" :label="$t('section.equipment.details.warrantyMonths')" hide-details :rules="[rules.number]")
        app-textarea.my-2( v-model="equipment.workParams" :label="$t('section.equipment.details.operatingParameters')" outlined hide-details)

</template>

<script>

import { $_validate_rules } from '@/common/ValidateRules'
import DialogToggable from '@/components/app/DialogToggable'

export default {
  mixins: [$_validate_rules, DialogToggable],

  props: {
    equipment: Object,
  },

  data () {
    return {
      valid: false,
    }
  },

  computed: {
    title (){
      return this.equipment?.name!=null ? this.equipment.name : this.$t('section.equipment.form.equipment')
    }
  },

  methods: {
    submit(){
      this.$refs.saveEquipmentForm.validate()
    },

    saveEquipment (equipment) {
      this.$emit('save', equipment)
    },

  }

}
</script>
