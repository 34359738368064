<template lang="pug">
div.fix-panels-section( ref="fixPanelsSection" )
  slot
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "FixPanelsSection",

  components: { 
    draggable
  },

  data(){
    return {
    }
  },

  methods: {
  }
  
}
</script>

<style scoped>
.fix-panels-section{
  width: 100%;
  height: 100%;
  max-height: auto;
}
</style>