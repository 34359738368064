<template lang="pug">
div
  v-tooltip( bottom )
    template( v-slot:activator="{ on, attrs }" )
      app-icon-action( v-on="on" icon="$download-icon" color="var(--v-primary-darken2)" @click="downloadPatch")
    span {{ $t('patch.dialog.download') }}
  
</template>

<script>
import { SourcePath } from '@app/SourcePath'
import { mapState } from 'vuex'

export default {
  name: 'PatchExportFile', 

  computed: {
    ...mapState('patches', ['selectedPatch']),
  },

  methods: {
    downloadPatch() {
      window.open(SourcePath.file(this.selectedPatch.file), '_blank')
    }

  }
}
</script>