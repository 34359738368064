<template lang="pug">
  div.section-expansion
    div.py-1.pl-2.section-expansion-center(@click="openPanel" test-id="section-expasion-header")
      task-header(:title="title" :colorDark="headerColorDark" headerItemsCenter :style="changeHeaderHeight ? 'height: auto !important; min-height: 24px': ''" :headerWrap="headerWrap")
        template(v-slot:headerBefore)
          slot(name="headerBefore")
        template(v-slot:titleAfter)
          slot(name="titleAfter") 
        template(v-slot:headerAfter)
          task-button-action(v-if="additionalIcon" :icon="additionalIcon" @click.stop="additionalIconClick" test-id="subtasks-create-task")
          task-button-action(v-if="additionalIcon2" :icon="additionalIcon2" @click.stop="additionalIcon2Click" test-id="subtasks-create-task2")
          v-btn.pl-0.pr-0( color="#6B6B6B" dense text x-small)
            v-icon(v-if="!show" :small="smallExpandIcon" color="#C4C4C4" ).pl-1 expand_more
            v-icon(v-if="show" :small="smallExpandIcon" color="#757575").pl-1 expand_less
          slot(name="headerAfter") 
    div(:class="{'pb-2':show}" :style="`background-color: ${sectionColor};`")
      slot(v-if="show")
</template>

<script>
import TaskHeader from "../TaskHeader.vue"
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  components: {
    TaskHeader,
    TaskButtonAction
  },
  
  props: {
    title: String,
    showToggle: Boolean,
    smallExpandIcon: Boolean,
    headerColorDark: Boolean,
    headerWrap: Boolean,
    changeHeaderHeight: Boolean,
    additionalIcon: String,
    additionalIcon2: String,
    sectionColor: String,
    openOnAdditionalIcon: Boolean
  },
  
  mounted () {
    if (this.showToggle)
      this.show = true
  },

  data () {
    return {
      show: false
    }
  },

  methods:{
    additionalIconClick() {
      if(!this.openOnAdditionalIcon) this.show = true 
      this.$emit('additionalIconClick')  
    },

    additionalIcon2Click() {
      this.$emit('additionalIcon2Click')  
    },

    openPanel(){
      this.show = !this.show
      this.$emit('openPanel', this.show)
    }
  },
}
</script>

<style>
.section-expansion {
  background-color: white;
  border-radius: 5px;
}
</style>