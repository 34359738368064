<template lang="pug">
  app-section.base-scroll-body.height-100( :class='{"grabbing": diagramIsGrabbing}' )
    //- Панель управления
    new-gantt-header.mb-2( v-if='!isTaskManager' ref="gantt-header" @update='refreshGanttPlayerTick' )

    svg#gantt

    //- Загрузочный экран
    .loading-screen(v-if='ganttIsUpdating')
      v-progress-circular(color='accent' indeterminate)

    task-smeta-details( v-if='smetaDetails' :task='showSmetaTask' @close="closeSmetaDetails" @showTaskReport='showTaskReport' style="z-index:1" )
    task-report( v-if='show.taskReport' :selectedTask='showSmetaTask' @close='closeTaskReport' style="z-index:2" )

    .time-before-sync {{ syncMessage }}
</template>

<script>
// import Gantt from 'frappe-gantt'
import Gantt from '@/plugins/gantt/gantt'
import store from '@/store'
import TaskSmetaDetails from '@/components/task/TaskSmetaDetails.vue'
import TaskReport from '@/components/task/taskReport/TaskReport.vue'
import NewGanttHeader from '@/components/task/gantt/NewGanttHeader.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

const milliseconds_in_seconds = 1000

export default {
  name: "TaskNewGantt",
  
  components: {
    TaskSmetaDetails, 
    TaskReport,
    NewGanttHeader
  },

  data() {
    return {
      gantt: null,

      show: {
        taskReport: false
      },

      diagramIsGrabbing: false,
      
      refreshUnsubcribe: null, // Событие обновления задач

      dateUpdateInterval: null,
      tasksDatesToUpdate: [],

      locale: null,

      ganttScroll: {
        x: null,
        y: null
      },

      timeBeforeSync: 5 * milliseconds_in_seconds,
      syncInterval: null,
      ganttIsUpdating: false,
      tasksToUpdate: []
    }
  },

  computed: {
    ...mapState('task', ['tasks', 'showSmetaTask', 'smetaDetails', 'tree', 'ganttInitScroll']),

    // getTimeBeforeSync() {
    //   let millisecondsRemainder = (this.timeBeforeSync % 1000 / 100).toString()
    //   millisecondsRemainder = millisecondsRemainder.length === 1 ?  millisecondsRemainder : millisecondsRemainder
    //   return Math.floor(this.timeBeforeSync / 1000) + '.' + millisecondsRemainder
    // }

    syncMessage() {
      if(this.syncInterval) {
        return this.$t('module.task.gantt.sync')
      }
      return this.$t('module.task.gantt.syncDate') + this.currentTime()
    },

    isTaskManager() {
      return window.location.href.includes('task/manager')
    }
  },

  async mounted() {
    this.locale = localStorage.getItem("locale")

    if(this.tree.length == 0) {
      await this.loadTasks()
    }

    let gantt_settings = this.getGanttSettings()
    this.gantt = new Gantt('#gantt', this.diclousoredTree(), gantt_settings)

    // Добавление перетаскивания страницы
    let ganttSvg = document.getElementById('gantt')
    ganttSvg.parentElement.setAttribute('style', 'height: calc(100% - 35px);')
    this.setSectionDragListeners(ganttSvg.parentElement)

    // Подписка на обновление диаграммы 
    this.refreshUnsubcribe = store.subscribe((mutation) => {
      if(mutation.type === 'task/setTasks') {
        // this.refreshSyncInterval()
        this.refreshGantt(this.gantt)
        
        if(this.ganttScroll.x || this.ganttScroll.y) {
          this.gantt.$svg.parentElement.scrollLeft = this.ganttScroll.x || 0
          this.gantt.$svg.parentElement.scrollTop = this.ganttScroll.y || 0
        }

        this.ganttIsUpdating = false

        // Обновление прогресса на модели
        let player = this.$refs['gantt-header']
        player?.showReadiness(player.playerDate)
      }
    })

    if(this.ganttInitScroll) {
      ganttSvg.parentElement.scrollLeft = this.ganttInitScroll.x
      ganttSvg.parentElement.scrollTop = this.ganttInitScroll.y

      this.ganttScroll.x = this.ganttInitScroll.x
      this.ganttScroll.y = this.ganttInitScroll.y
    }

    this.$emit('init', this.gantt)
  },

  beforeDestroy() {
    // Отписка от обновления диаграммы
    if(typeof this.refreshUnsubcribe === 'function') {
      this.refreshUnsubcribe()
    }

    clearInterval(this.syncInterval)
    this.updateTasks()
    
    this.setGanttInitScroll(this.ganttScroll.x ? this.ganttScroll : null)
  },

  methods: {
    ...mapActions('task', ['setDateIntervalOfTasks', 'loadTasks']),
    ...mapMutations('task', ['setShowSmetaTask', 'hideSmetaDetails', 'showSmetaDetails', 'setGanttInitScroll', 'setSelectedTask']),

    // Раскрытие дерева
    diclousoredTree() {
      let dicloused_tree = []
      const sorted_tree = this.tree.sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime())

      sorted_tree.map(parent_task => {
        dicloused_tree.push(parent_task)
        
        this.computeInnerTree(parent_task).map(task => {
          dicloused_tree.push(task)
        })
      })

      return dicloused_tree.map(task => {
        let preparedTask = {
          id: task.uuid,
          name: task.title,
          progress: task.progress,
          start: task.startDate.split(' ')[0],
          end: task.endDate.split(' ')[0],

          isGroup: task.group,
          parent: task.parent,
          exec_type: task.execType.name,
          
          previous_task: task.previous_task,
          sequence_task: task.sequence_task,
          
          visible: true,
          children: task.children,
          dependencies: task.children.map(child => {
            return {
              task: child,
              type: child.execType
            }
          })
        }
        return preparedTask
      })
    },

    // Раскрытие вложенных задач
    computeInnerTree(task) {
      let computed_tree = []

      task.children.map((child, index) => {
        child.previous_task = task.children[index - 1]
        child.sequence_task = task.children[index + 1]

        computed_tree.push(child)
        computed_tree = computed_tree.concat(this.computeInnerTree(child))
      })

      return computed_tree
    },

    closeSmetaDetails() {
      this.show.taskReport = false
      this.setShowSmetaTask(null)
      this.hideSmetaDetails()
    },

    showTaskReport() {
      this.show.taskReport = true
    },

    closeTaskReport() {
      this.show.taskReport = false
    },

    setSectionDragListeners(section) {
      section.onmousedown = (click) => {
        // Не перетаскивать диаграмму, если клик был по задаче
        let className = click.target.className.baseVal
        if(!(className?.includes('grid-row') || className?.includes('today-highlight') || className?.includes('row-line') || className?.includes('player-tick') || className?.includes('forecast')) && className?.length > 0) {
          return
        }
        
        section.onmousemove = (event) => {
          this.diagramIsGrabbing = true
          
          section.scrollTop -= event.movementY
          section.scrollRight += event.movementX
          section.scrollLeft -= event.movementX

          this.ganttScroll.x = section.scrollLeft
          this.ganttScroll.y = section.scrollTop
        }
      }
      section.onmouseleave = () => {
        this.diagramIsGrabbing = false
        section.onmousemove = null
      }
      section.onmouseup = () =>{ 
        this.diagramIsGrabbing = false
        section.onmousemove = null
      }
    },

    refreshGantt(gantt) {
      let tasks = this.diclousoredTree()

      gantt.tasks.map(task => {
        let currentTask = tasks.find(t => t.id === task.id)
        if (currentTask) {
          currentTask.visible = task.visible
        }
      })

      gantt.refresh(tasks)

      if (!this.isTaskManager) {
        let player_date = this.$refs['gantt-header'].playerDate
        this.refreshGanttPlayerTick(player_date)
      }
    },

    refreshGanttPlayerTick(date) {
      this.gantt?.update_player_date_tick(date)
    },

    refreshSyncInterval() {
      this.timeBeforeSync = 5 * milliseconds_in_seconds
      clearInterval(this.syncInterval)

      this.syncInterval = setInterval(() => {
        this.timeBeforeSync -= 100
        
        if(this.timeBeforeSync <= 0) {
          this.ganttIsUpdating = true
          
          this.updateTasks()

          clearInterval(this.syncInterval)
          this.syncInterval = null
        }
      }, 100)
    },

    updateTasks() {
      let time_offset = new Date().getTimezoneOffset() / 60
      let dates = this.tasksToUpdate.map(task => {
        let start = new Date(task._start)
        let end = new Date(task._end)
        start.setHours(0, 0, 0, 0)
        end.setHours(0, 0, 0, 0)
        return {
          uuid: task.id,
          startDate: time_offset < -3 ? start.getTime() + 86400000 : start.getTime(),
          stopDate: time_offset < -3 ? end.getTime() + 86400000 : end.getTime()
        }
      })
      return this.setDateIntervalOfTasks(dates)
    },

    // Первоначальные настройки диаграммы
    getGanttSettings() {
      return {
        language: this.locale,

        on_click: (task) => {
          let taskWithSmeta = this.tasks.find(t => t.uuid === task.id)
          if (this.isTaskManager) {
            this.setSelectedTask(taskWithSmeta)
          }
          else {
            this.setShowSmetaTask(taskWithSmeta)
            this.showSmetaDetails()
          }
        },

        on_dates_update: (tasks) => {
          this.refreshSyncInterval()
          this.tasksToUpdate = tasks
          
          let player = this.$refs['gantt-header']
          player?.showReadiness(player.playerDate)
        },

        on_start_change: () => {
          clearInterval(this.syncInterval)
        },

        custom_popup_html: () => {
          return ""
        }
      }
    },

    currentTime() {
      let now = new Date()
      let {year, month, day, hour, minutes} = {
        year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate(), hour: now.getHours(), minutes: now.getMinutes()
      }
      
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      hour = hour < 10 ? '0' + hour : hour
      minutes = minutes < 10 ? '0' + minutes : minutes

      return year + "/" + month + "/" + day + " " + hour + ":" + minutes
    }
  }
}
</script>

<style lang="scss" scoped>
  .height-100 {
    max-height: 100%;
    height: 100%;
    position: relative;
  }
  .grabbing {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  .loading-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.8;
    top: 0px;
    left: 0px;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .time-before-sync {
    position: absolute;
    color: #797979;
    z-index: 1;
    font-size: 13px;
    bottom: 27px;
    right: 20px;
  }
</style>