<template lang="pug">
  div
    app-section.mb-4      
      v-row( no-gutters )
        v-col
          div( @click="lockedOrNo")
            span.text-left.mt-2.span-style {{$t("section.structure.model.revisionMoving.offset")}}
              v-form( ref="offsetRevisionForm" )
                v-row( no-gutters ).mt-2.mb-3             
                  .col-4.pr-1
                    span.text-left.pl-2.real X({{ realOffsetCoord.x }})
                    app-text-field( v-model.number="modelPosition.offsetX" hide-details :disabled="locked" :rules="[rules.required, rules.double]" @input="changeCoords()" @keyup.enter='view') 
                  .col-4.pr-1
                    span.text-left.pl-2.real Y({{ realOffsetCoord.y }})
                    app-text-field( v-model.number="modelPosition.offsetY" hide-details :disabled="locked" :rules="[rules.required, rules.double]" @input="changeCoords()" @keyup.enter='view') 
                  .col-4
                    span.text-left.pl-2.real Z({{ realOffsetCoord.z }}) 
                    app-text-field( v-model.number="modelPosition.offsetZ" hide-details :disabled="locked" :rules="[rules.required, rules.double]" @input="changeCoords()" @keyup.enter='view')

                        
                span.text-left.mt-4.span-style {{$t("section.structure.model.revisionMoving.rotate")}}
                v-row.mb-3( no-gutters ).mt-2
                  .col-4.pr-1
                    span.text-left.pl-2.real X({{ realEulerCoord.x }})
                    app-text-field( v-model.number="modelPosition.eulerX" hide-details :disabled="locked" :rules="[rules.required, rules.double]" @input="changeCoords()" @keyup.enter='view')
                  .col-4.pr-1
                    span.text-left.pl-2.real Y({{ realEulerCoord.y }})
                    app-text-field( v-model.number="modelPosition.eulerY" hide-details :disabled="locked" :rules="[rules.required, rules.double]" @input="changeCoords()" @keyup.enter='view')
                  .col-4
                    span.text-left.pl-2.real Z({{ realEulerCoord.z }}) 
                    app-text-field( v-model.number="modelPosition.eulerZ" hide-details :disabled="locked" :rules="[rules.required, rules.double]" @input="changeCoords()" @keyup.enter='view')
                .row
                  .col
                    v-btn.mt-1.float-right( v-if="!locked" color="accent" dense x-small @click="send" outlined) {{$t("section.structure.model.revisionMoving.save")}}  
                    v-btn.mt-1.mr-1.float-right( v-if="!hideViewChanges" color="accent" dense x-small @click="view" outlined) {{ $t("button.look") }}  
                    span.mt-1(v-if="locked") {{ $t('section.structure.model.revisionMoving.offsetBlocked') }}
                .row( v-if="withLocker && hasModelBlocking" )
                  .col  
                    v-icon(v-if="!locked" color="#a39d9d" @click="lock") mdi-lock
                    v-icon(v-if="locked" color="black"  @click="unlock") mdi-lock

</template>
<script>

import { mapState, mapActions, mapGetters } from "vuex"

import DialogToggable from '@/components/app/DialogToggable'

import { AlertService } from '@app/AlertService'

// import { PositionControlCubePlugin } from "@/plugins/xeokit/PositionControlCubePlugin/PositionControlCubePlugin.js"
// import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name:"CoordinatesMenu",

  mixins: [DialogToggable],

  props: {
    withLocker: Boolean,

    realOffsetCoord: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 }
      }
    },

    realEulerCoord: {
      type: Object, 
      default() {
        return { x: 0, y: 0, z: 0 }
      }
    }, 

    offset: {
      type: Object, 
      default() {
        return { x: 0, y: 0, z: 0 } 
      }
    }, 

    euler: {
      type: Object, 
      default() {
        return { x: 0, y: 0, z: 0 }
      }
    },

    hideViewChanges: Boolean,
  },

  data () {
    const defaultForm = Object.freeze({
      offsetX: 0,
      offsetY: 0,
      offsetZ: 0,
      eulerX: 0, 
      eulerY: 0,
      eulerZ: 0,
      realoffsetX: 0,
      realoffsetY: 0,
      realoffsetZ: 0,
      realeulerX: 0, 
      realeulerY: 0,
      realeulerZ: 0
    })
    return {
      defaultForm: Object.assign({}, defaultForm),
      modelPosition: Object.assign({}, defaultForm),
      lastSentForm: null,
      locked: false,
      isProjectOwner: false,
      rules: {
        required: (value) => value != null || this.$t("error.require"),
        double: (value) => (value!=null && /^(-)?\d+(.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      },
      // posControlCube: new PositionControlCubePlugin(XeokitMediator.viewer, { container: document.getElementById("measurementScrim") }),
    }
  },

  watch: {
    selectedModel: function() {
      this.locked = (this.selectedModel ?? false) ? (this.selectedModel?.lockOffset ?? false) : (!this.selectedPatch?.xktFile ?? true)
      // this.posControlCube.deactivate()
    },
    selectedPatch: function() {
      this.locked = (this.selectedModel ?? false) ? (this.selectedModel?.lockOffset ?? false) : (!this.selectedPatch?.xktFile ?? true)
      // this.posControlCube.deactivate()
    },
    offset: function() {
      this.setDefaultData()
    }, 

    euler: function() {
      this.setDefaultData()
    }

    // selectedRevision: function(val) {
    //   if (val) 
    //     this.posControlCube.activate(val, this.modelPosition)
    // }
  },

  computed: {
    ...mapState('project', ['selectedModel', 'project', 'selectedRevision', 'topUnit']),
    ...mapState('patches', ['selectedPatch']),
    ...mapState('authUser', ['user']),
    ...mapGetters('projectPermissions', ['hasModelBlocking']),

    lighten3() {
        if (localStorage.getItem("themeName") === "grey") return this.$vuetify.theme.themes.dark.surface.base
        return this.$vuetify.theme.themes.dark.surface.lighten3
    },

    checkValues() {
      if(this.modelPosition.offsetX != this.offset.x)
        return true
      if(this.modelPosition.offsetY != this.offset.y)
        return true
      if(this.modelPosition.offsetZ != this.offset.z)
        return true

      if(this.modelPosition.eulerX != this.euler.x)
        return true
      if(this.modelPosition.eulerY != this.euler.y)
        return true
      if(this.modelPosition.eulerZ != this.euler.z)
        return true

      return false
    }
  },

  mounted() {
    this.project.membership.forEach(member => {
      if(member.owner && this.user.uuid == member.profile.uuid)
        this.isProjectOwner = true
    })
    this.locked = (this.selectedModel ?? false) ? (this.selectedModel?.lockOffset ?? false) : (!this.selectedPatch?.xktFile ?? true)
    this.setDefaultData()

    // if(this.selectedRevision)
    //   this.posControlCube.activate(this.selectedRevision, this.modelPosition)
    
    // this.posControlCube.on("update", (updateData)=> {
    //   let angle = updateData.angle
    //   let offset = updateData.offset

    //   this.modelPosition.eulerX = angle.x
    //   this.modelPosition.eulerY = angle.y
    //   this.modelPosition.eulerZ = angle.z

    //   // this.modelPosition.offsetX = this.offsetCoord.x + offset.x
    //   // this.modelPosition.offsetY = this.offsetCoord.y + offset.y
    //   // this.modelPosition.offsetZ = this.offsetCoord.z + offset.z

    //   this.modelPosition.offsetX = this.offsetCoord.x + offset.x
    //   this.modelPosition.offsetY = this.offsetCoord.y + offset.z
    //   this.modelPosition.offsetZ = this.offsetCoord.z - offset.y
    // })

    // this.$root.$on('projectUpdated', () => {
    //   if (this.selectedRevision) {
    //     this.posControlCube.activate(this.selectedRevision, this.modelPosition)
    //   }
    // })
  },

  beforeDestroy() {
    // this.posControlCube.deactivate()
  },

  methods: {
    ...mapActions("project", ["lockOffsetModel", "unlockOffsetModel"]),

    send() {
      if (this.checkValues) {    
        if (this.$refs.offsetRevisionForm.validate()) {
          // this.posControlCube.deactivate()
          this.$emit('saveCoord', this.modelPosition, this.getFormValue())
          // this.modelPosition =  Object.assign({}, this.modelPosition, this.defaultForm)
        }
      }
    },
    view() {
      // if (this.checkValues) {    
        if (this.$refs.offsetRevisionForm.validate() && !this.hideViewChanges) {
          // this.posControlCube.deactivate()
          this.$emit('viewCoord', this.modelPosition, this.getFormValue())
          // this.modelPosition =  Object.assign({}, this.modelPosition, this.defaultForm)
        }
      // }
    },

    getFormValue() {
      let lastSentForm = new FormData()
      lastSentForm.set('offsetx', this.modelPosition.offsetX)
      lastSentForm.set('offsety', this.modelPosition.offsetY)
      lastSentForm.set('offsetz', this.modelPosition.offsetZ)
      lastSentForm.set('eulerx', this.modelPosition.eulerX)
      lastSentForm.set('eulery', this.modelPosition.eulerY)
      lastSentForm.set('eulerz', this.modelPosition.eulerZ)
      return lastSentForm
    },
      
    lock() {
      this.locked = true
      let here = this
      this.lockOffsetModel().then(() => {
        AlertService.warning({info: here.$t('section.structure.model.revisionMoving.locked')})
      }).catch(e => {
        AlertService.error(e.response)
        this.locked = !this.locked
      })
    },

    unlock() {
      this.locked = false
      let here = this
      this.unlockOffsetModel().then(() => {
        AlertService.success({info: here.$t('section.structure.model.revisionMoving.unlocked')})
      }).catch(e => {
        AlertService.error(e.response)
        this.locked = !this.locked
      })
    },

    lockedOrNo() {
      if(this.locked && !this.isProjectOwner){
        AlertService.warning({info: this.$t('section.structure.model.revisionMoving.offsetBlocked')})
      }
    },
    
    closeExpansionCoords() {
      this.posControlCube.deactivate()
    },
    
    changeCoords() {
      // console.log("changecoords")
      // this.posControlCube.setNewOffsetAndEuler(this.modelPosition)
    }, 

    setDefaultData() {
      this.modelPosition.offsetX = this.offset.x
      this.modelPosition.offsetY = this.offset.y 
      this.modelPosition.offsetZ = this.offset.z 

      this.modelPosition.eulerX = this.euler.x 
      this.modelPosition.eulerY = this.euler.y 
      this.modelPosition.eulerZ = this.euler.z  
    }
  }
}
</script>

<style lang="scss" scoped>
  .dict-button {
    display: block;
    font: normal 12px/24px $roboto;
    text-transform: none;
  }
  .real {
    color: #c5c0c0;    
  }

  .span-style {
  color: var(--v-primary-base);
  margin-top: 8px;
  text-align: left;
  font: normal 12px/16px "Roboto", sans-serif;
}
</style>