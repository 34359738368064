<template lang="pug">
  v-tab-item(
    :active-class="activeClass"
    :disabled="disabled"
    :eager="eager"
    :id="id"
    :reverse-transition="reverseTransition"
    :transition="transition"
   )
    slot
</template>

<script>
  export default {
    name: 'tab-item',

    props: {
      activeClass: String,
      disabled: Boolean,
      eager: Boolean,
      id: String,
      reverseTransition: [Boolean, String],
      transition: [Boolean, String],
    },
  }
</script>
