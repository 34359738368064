<template lang="pug">
  v-menu( v-model="showMenu" :position-x="x" :position-y="y" tile :absolute="isAbsolute" transition="slide-x-transition" offset-y rounded )
    template( v-slot:activator="{ on }" )
      //- v-img.header-icon( v-if="basic" src="@/assets/img/playlist_play.png" :width="iconSize" :height="iconSize" v-on="on" :class="['app-theme--' + themeName]")
      app-icon-action.mr-2( v-if="basic" icon="$task-menu-icon" v-on="on" size="16" test-id="task-details-toolbar-menu" )
      //- v-img.header-icon( v-else-if="icon" :src="`/img/${icon}.png`" :width="iconSize" :height="iconSize" v-on="on" :class="['app-theme--' + themeName]")
      //- v-img.header-icon( v-else-if="svgIcon" :min-width="iconSize" :src="`/img/project/tools/${svgIcon}.svg`" :width="iconSize" :height="iconSize" v-on="on" :class="['app-theme--' + themeName]" test-id="task-details-toolbar-menu")
    .m-content
      .m-content-header.filling(v-if='header') {{ $t(header) }}
      .m-content-body
        template(v-for="(option, idx) in menu")
          v-divider(v-if="option.divider" light)

          .m-content-text( v-else ripple :style="`font-size:${fontSize}; color:${color};`" @click="(event) => handleSelect(event, option, idx)" :test-id="option.testID")

            .m-content-text--disabled( v-if="option.disabled" )
              .m-content-priority( v-if="option.color" :style="{ backgroundColor: option.color }")
              template(v-if="option.icon")
                v-icon(color="#505254").mr-2 {{ option.icon }}
              | {{ option.title }}

            .m-content-text--active.pl-3.pr-4( v-else test-id="toolbar-menu-option")
              .m-content-priority( v-if="option.color" )
                img( :src="`/img/project/tools/${checkPriority(option)}`" )

              template( v-if="option.icon" )
                v-btn( small icon dense width="15" height="15" color="accent" ) 
                  v-img.mr-1( :src="`${option.icon}`" :max-width="15" :height="15" )
              | {{ option.title }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    menu: Array,
    basic: Boolean,
    iconSize: {
      type: Number,
      default: 24
    },
    icon: String,
    svgIcon: String,
    fontSize:{
      type: String,
      default: "16px"
    },
    color:{
      type: String,
      default: "#3B93AF"
    },
    item: null,

    header: String,
  },

  data: () => ({ showMenu: false, moving: false, x: 0, y: 0 }),

  computed: {
    ...mapGetters('theme', ['themeName']),

    isAbsolute () {
      return !this.basic && !this.icon
    },

    
  },

  watch: {
    showMenu (newValue) {
      if (!newValue && !this.moving) this.$emit('close')
    },
  },

  methods: {
    handleSelect (event, option, idx) {
      if (!option.disabled) {
        if (option.action) option.action(event, this.item)
        else this.$emit('menu:select', option, idx, this.item)
      }
    },

    show (e) {
      e.preventDefault()
      this.moving = true
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.moving = false
        this.showMenu = true
      })
    },

    checkPriority (option) {
      switch(option.name) {
        case "LOW":
          return "task_priority_light_1.svg"
        case "NORMAL":
          return "task_priority_light_2.svg"
        case "HIGH":
          return "task_priority_light_3.svg"
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
// .header {
//   height: 32px;
//   display: flex;

//   &-icon {
//     display: inline-block;
//     // margin-right: 8px;
//     cursor: pointer;
//     // flex: 0 1 auto;
//   }

//   &-text {
//     font: bold 16px/24px $roboto;
//     vertical-align: top;
//     flex: 1 1 auto;
//   }
// }
.m-content {
  // border-radius: 0px;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
  background-color: var(--v-themeColor-base);
  // padding: 8px;
}
.m-content-body {
  // border-radius: 5px;
  background-color: #fff;
  // padding: 0;
}
.m-content-priority {
  // display: inline-block;
  // width: 10px;
  // height: 16px;
  // vertical-align: middle;
}
.m-content-text {
  font: normal 12px/32px $roboto;
}
.m-content-header {
  background-color: var(--v-surface-lighten1);
}

.m-content-text--disabled {
  color: var(--v-primary-lighten4);
  cursor: not-allowed;
}
.m-content-text--active {
  width:100%;
  cursor: pointer;
  // border-radius: 5px;
}
.m-content-text--active:hover{
  background: #E5E5E5;
}
.filling {
  padding: 0px 12px;
  line-height: 32px;
}
</style>

<style>
.v-menu__content {
  border-radius: 0px;
  background-color: #4D4D4D;
}
</style>