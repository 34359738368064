import { fetchAllGroups, prepareAxisForEdit } from '@/facades/groups.facade'

export const AxisDataAdapter = {
  getDefaultAxisData: async () => await prepareAxisForEdit(),

  findGroupByUuid: (uuid) => {
    let list = fetchAllGroups()
    for (let item of list) {
      if (item.uuid == uuid) return item
    }
    return null
  },
}
