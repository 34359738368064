<template lang="pug">
div.group-list
  div( v-for="group in groups" )
    .d-flex.justify-start.align-center
      v-btn.group--tree-button( small icon @click='toggleList(group)' test-id="tool-tree-hide")
        v-icon(color='#262626' small) {{ group.isOpen ? "mdi-menu-down" : "mdi-menu-right" }}
      .group__header.d-flex( :style="{ 'background-color': 'rgb(from' + group.color + ' r g b / 0.3)'}" )
        .group__title  
          div {{ group.title }}
          .pl-3( v-if="!group.noRevision" )
            div {{ "x: " + group.offset[0] }}
            div {{ "y: " + group.offset[1] }}
            div {{ "z: " + group.offset[2] }}
        app-button( icon light small color='#666666' @click="flyToGroup(group.offsetAabb)" )
          v-icon mdi-eye-outline  
    .group__container( v-if="group.isOpen" )
      offset-group-tool-item( v-for="model in group.models" :model="model")
</template>

<script>
import OffsetGroupToolItem from './OffsetGroupToolItem.vue'
import { useModelOffsetGroupsStore } from '@/pinia/modelOffsetGroups/modelOffsetGroups.store'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {

  components: {
    OffsetGroupToolItem
  },

  computed: {
    modelOffsetGroupsStore: () => useModelOffsetGroupsStore(),

    groups() {
      return this.offsetGroupsStore.getOffsetGroups()
    },
  },

  methods: {
    toggleList(group) {
      group.isOpen = !group.isOpen
    },

    flyToGroup(aabb) {
      console.log(aabb)
      XeokitMediator.CameraFlight.flyTo({ aabb: aabb })
    }
  }
}

</script>

<style lang="scss" scoped>
.group-list {
  padding-bottom: 4px;
}

.group__container{
  border-left: 1px dotted #303030;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
}

.group__header {
  color: #303030;
  padding: 4px;
  padding-left: 5px;
  border-radius: 5px;
  margin-top: 8px;
  position: relative;
  left: -8px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.group__title {
  padding-top: 4px;
  padding-left: 4px;
}

.group--tree-button {
  position: relative;
  left: 1px;
  top: 44px;
  height: 16px;
  width: 16px;
  background: var(--v-surface-lighten2);
  border: 1px solid #303030;
  z-index: 1;
}
</style>