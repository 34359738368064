<template lang="pug">
ToolButton( 
  :active="isActive" 
  :tooltip="tooltip" 
  @click='toggle()'
) $viewer-gps-icon
</template>

<script>
import ToolButton from './ToolButton.vue'
import { ToolWindow, useActiveToolWindowStore } from "@/stores/viewerTools/activeToolWindow.store"
const store = useActiveToolWindowStore()
 
export default {
  components: {
    ToolButton,
  },

  computed: {
    isActive: () => store.activeWindow == ToolWindow.PLUGIN_POINTS,
    tooltip: () => 'Поиск координат',
  },

  methods: {
    toggle () {
      store.toggleActiveWindow(ToolWindow.PLUGIN_POINTS)
    }
  }
}
</script>