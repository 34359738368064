<template lang="pug">
div.element( :class="{ 'element--hightlighted': hightlighted }" @click="chooseElement" )
  v-tooltip( top )
    template( v-slot:activator="{ on, attrs }" )
      div.element__label.ml-1( v-on="on" ) {{ element.title }}
    span {{ suspectReason }}
</template>

<script>

export default {
  props: {
    element: Object,
    chosenElementUuid: String
  },

  computed: {
    hightlighted() {
      return this.element.globalId == this.chosenElementUuid
    },

    suspectReason() {
      if(this.element.suspectReason == "OUT")
        return this.$t('section.structure.model.suspicious.out')
      if(this.element.suspectReason == "BIG")
        return this.$t('section.structure.model.suspicious.big')
      if(this.element.suspectReason == "LONG")
        return this.$t('section.structure.model.suspicious.long')
      return "Reason not find"
    }
  },

  methods: {
    chooseElement() {
      this.$emit('change', this.element.globalId)
    }
  }
}
</script>

<style scoped>
.element {
  color: rgba(0,0,0,.87);
}
.element:hover {
  background-color: var(--v-primary-lighten5);
  cursor: pointer;
}
.element--hightlighted {
  background-color: var(--v-primary-lighten5);
  box-shadow:inset 0px 0px 0px 1px var(--v-primary-lighten4);
}
.element__label {
  flex: 1;
  font-size: 12px;
  color: rgba(0,0,0,.87);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>