<template lang="pug">

  app-panel( padding="0px")
    fix-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
      fix-panel(:menu="topMenu" :title="$t('section.journal.eventHistory')" style="height: calc(100% - 10px) !important;")
        template(v-slot:headerButton)
          app-toolbar-item.mt2px( :items="warningLevels" :mainItem="$t('section.journal.allLevels')"
            key-name="message" min-width="85" :all="$t('app.select.all')" @select="selectLevel" style="text-align: center") {{ $t(currentLevel) }}

          app-toolbar-item.mt2px.ml-2( :items="warningFeatureType" :mainItem="$t('section.journal.allTypes')"
            key-name="message" min-width="80" :all="$t('app.select.all')" @select="selectFeatureType" style="text-align: center") {{ $t(currentFeatureType) }}

          v-menu(
            ref="menu1"
            :close-on-content-click="false" :return-value.sync="filter.from"
            transition="scale-transition" offset-y min-width="auto"
          )
            template(v-slot:activator="{ on }")
              v-icon( color="var(--v-primary-darken2)" ) mdi-calendar
              input.mt2px.ml-2.dateInput( v-model='filter.from' :placeholder="$t('section.journal.from')" readonly v-on="on")
            v-date-picker( v-model="filter.from" first-day-of-week=1 no-title scrollable @input="$refs.menu1.save(filter.from)")
          
          v-menu(
            ref="menu2"
            :close-on-content-click="false" :return-value.sync="filter.to"
            transition="scale-transition" offset-y min-width="auto"
          )
            template(v-slot:activator="{ on }")
              v-icon( color="var(--v-primary-darken2)" ) mdi-calendar
              input.mt2px.ml-2.dateInput( v-model='filter.to' :placeholder="$t('section.journal.before')" readonly v-on="on")
            v-date-picker( v-model="filter.to" first-day-of-week=1 no-title scrollable @input="$refs.menu2.save(filter.to)")

          input.mt2px.ml-2.searchInput( v-model='filter.search' :placeholder="$t('section.journal.combination')")

          v-btn.mx-2(small icon)
            v-icon( color="var(--v-primary-darken2)" @click.stop='getEventsByFilter()') mdi-arrow-right-circle-outline

        v-row.h100p( no-gutters )
          v-col.base-scroll-body
            template(v-if="isEventsLoaded")
              warning-card(v-for="event in events.list" :key="event.uuid" :event="event" @click.native="setSelectedEvent(event)" :short="true")
              app-text.ma-2(v-if="events.list.length == 0") {{ $t('section.journal.eventsNotFound') }}
              //- v-pagination( v-if="events.totalPages > 1" v-model="filter.page" :total-visible="filter.perPage" :length="events.totalPages" @input="getEventsByFilter()")
              paginator( v-if="events.totalPages > 1" v-model="filter.page" :totalPages="events.totalPages"  @input="getEventsByFilter()")

          v-col.base-scroll-body
            warning-card(v-if='selectedEvent' :event="selectedEvent" @goToEquipment="goToEquipment")

    warning-profiles-dialog(ref='warningProfiles' v-model="dialog.openProfiles" @edited="afterEditedProfiles")        
</template>

<script>

import WarningProfilesDialog from './components/WarningProfilesDialog'
import WarningCard from './components/WarningCard'
import { mapState, mapGetters, mapMutations } from 'vuex'
import Paginator from '@/components/project/panel/left/maintenance/components/Paginator.vue'
import FixPanel from '@/components/project/panel/left/maintenance/components/FixPanel.vue'
import FixPanelsSection from '@/components/project/panel/left/maintenance/components/FixPanelsSection.vue'

export default {
  data () {
    return {
      dialog: {
        openProfiles: false
      },
      filter: {
        level: null,
        type: null,
        from: null,
        to: null,
        page: 1,
        perPage: 5,
        search: ''
      }
    }
  },

  components: {
    WarningProfilesDialog,
    WarningCard,
    Paginator,
    FixPanel,
    FixPanelsSection
  },

  mounted() {
  },

  computed: {
    ...mapState('equipmentWarning', ['events', 'profiles', 'selectedEvent', 'warningLevels']),
    ...mapGetters('equipmentWarning', ['isEventsLoaded', 'warningFeatureType']),
    ...mapGetters('projectPermissions', ['hasMaintainceEquipment']),

    topMenu () {
      if (this.hasMaintainceEquipment) {
        return [
          { title: this.$t('section.journal.eventProfiles'), action: () => this.dialog.openProfiles = true },
        ]
      }

      return null
    },

    currentLevel () {
      return this.filter.level !=null ? this.filter.level.message : this.$t('section.journal.allLevels')
    },

    currentFeatureType () {
      return this.filter.type !=null ? this.filter.type.message : this.$t('section.journal.allTypes')
    },

  },

  methods: {
    ...mapMutations('equipmentWarning', ['setSelectedEvent']),

    getEventsByFilter() {
      this.setSelectedEvent(null)
      this.$store.dispatch('equipmentWarning/LOAD_EVENTS_BY_FILTER', this.filter)
    },

    loadData() {
      this.$store.dispatch('equipmentWarning/INIT')
      this.$refs.warningProfiles.loadData()
      this.filter.page = 1
    },

    afterEditedProfiles() {
      this.dialog.openProfiles = false
    },

    selectLevel(item) {
      this.filter.level = item
    },

    selectFeatureType(item) {
      this.filter.type = item
    },

    goToEquipment(item) {
      this.$emit('goToEquipment', item)
    },

  }
}
</script>

<style scoped>

.t-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
  background-color: var(--v-primary-lighten4);
}

.h100p {height: 100%;}

.mt2px {
  margin-top: 2px;
}

.searchInput  {
  max-width: 80px;
  background: var(--v-secondary-lighten5);
  color: var(--v-secondary-darken1);
  border-radius: 5px;
  height: 24px;
  font-size: 13px;
  padding: 0 8px;
}

.searchInput:focus {
  outline: 0px; 
}

.dateInput  {
  max-width: 80px;
  background: var(--v-secondary-lighten5);
  color: var(--v-secondary-darken1);
  border-radius: 5px;
  height: 24px;
  font-size: 13px;
  padding: 0 8px;
}

.dateInput:focus {
  outline: 0px; 
}
</style>
