<template lang="pug">
app-dialog( v-model="$_show" :header="$t('plugin.pluginSettings')" :confirmText="$t('button.save')" @confirm="savePluginSettings" :confirmDisabled="!valid || !(formPlugin.name.trim())")
  v-form( v-model="valid" ref="pluginSettingsForm" )
    app-section
      app-text-field( v-model="formPlugin.name" :label="$t('plugin.settings.name')" :rules="[rules.required]" )
      app-select( v-model="formPlugin.type" :items="types" item-text='title' :label="$t('plugin.settings.type')" :rules="[rules.required]" )
      app-select( v-if="isFormsLoaded" v-model="formPlugin.formUuid" item-value="uuid" clearable :items="formsForPlugin" item-text="name" :label="$t('plugin.settings.form')" )
      app-select( v-model="formPlugin.templateUuid" clearable :label="$t('plugin.settings.templates')" :items="templates" item-text="name" item-value="uuid" hideDetails )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable.vue'


export default {
  mixins: [DialogToggable],

  props: {
    formPlugin: Object
  },

  data() {
    return {
      valid: false, 
      rules: {
        required: (value) => !!value || this.$t("error.require")
      }
    }
  },

  computed: {
    ...mapState('plugins', ['types']),
    ...mapGetters('forms', ['forms', 'isFormsLoaded']),
    ...mapState('template', ['templates']),


    formsForPlugin() {
      return this.forms.filter(f => f.formType.value==1)
    },
  },

  methods: {
    savePluginSettings() {
      if (this.$refs.pluginSettingsForm.validate()) {
        this.$emit('savePluginSettings')
      }
    }
  }
}
</script>
