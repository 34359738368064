<template lang="pug">
  .d-flex.flex-column.align-center.item
    v-hover( v-slot:default="{ hover }" :close-delay="closeDelayHover" )
      .item__content( 
        :class="{ 'item__content--dark': dark, 'elevation-4': (hover && shadow) }" 
        :style="styleBody"
        @click.stop="onClick" 
        @contextmenu.stop="onMenu" 
        ) 
        slot( name="preTitle" )
        span( :class="{ 'item__content--selected': selected }") {{ getTitle(title) + archived }}
        slot( name="postTitle" v-if="hover")
        v-icon.item__more-action( v-if="hover" :color="color" @click.stop="onMenu" :style="styleIcon" ) more_vert
    slot( v-if="$slots.default" )
</template>

<script>
export default {
  name: 'app-hover-item',

  props: {
    item: {
      type: [String, Object],
      required: true
    },
    itemKey: String,
    dark: Boolean,
    selected: Boolean,
    prevent: Boolean,
    closeDelayHover: {
      type: [String, Number],
      default: 150
    },

    shadow: {
      type: Boolean,
      default: true
    },

    backgroundColor: {
      type: String,
      default: "var(--v-primary-lighten5)"
    },

    hoverHeight: {
      type: [String, Number],
      default: 32
    },

    iconTop:{
      type: [String, Number],
      default: 4
    },

    isWorkspaces: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    titleKey () {
      return this.itemKey || 'title'
    },

    title () {
      if (this.item instanceof Object) return this.item[this.titleKey] || JSON.stringify(this.item)
      return this.item
    },

    archived () {
      return this.isWorkspaces && this.item?.archived ? this.$t('toolbarMenu.archivedSpace') : ''
    },

    color () {
      if (this.dark) return this.selected ? '#000' : '#fff'
      return 'primary'
    },

     styleBody() {
      return {
        'background-color': this.backgroundColor,
      }
    },

    styleHeight(){
      return {
        'height': this.hoverHeight + "px"
      }
    },

    styleIcon(){
      return {        
        'top': this.iconTop + "px"  
      }
    }
  },

  methods: {
    getTitle(title) {
      if (!title) return ""

      if (title.includes("{")) 
        return this.$t(title.replace("{", "").replace("}", ""))

      return title
    },

    onMenu (event) {
      if (this.prevent) {
        event.stopPropagation()
        event.preventDefault()
      }
      this.$emit('menu', this.item, event)
    },

    onClick (event) {
      this.$emit('select', this.item, event)
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  // height: 32px;
}
.item__content {
  position: relative;
  width: 100%;
  padding: 8px 8px 8px 8px;
  border-radius: 4px;
  font: normal 12px/18px $roboto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: context-menu;

  // background-color: var(--v-primary-lighten5);
  color: var(--v-primary-base);

  &--selected {
    font-weight: bold;
    border-bottom: 2px solid var(--v-surface-lighten3);
  }

  &--dark {
    background-color: var(--v-surface-lighten1);
    color: var(--v-primary-lighten5);

    .item__content--selected {
      border-bottom: 2px solid var(--v-surface-lighten3);
    }
  }
}
.item__content:hover {
  background-color: var(--v-primary-lighten5)!important;
}
.item__more-action {
  position: absolute;
  right: 0;
  //top: 5px;
  cursor: pointer;
}

</style>