<template lang="pug">
  .d-flex.task-table-header
    .column( v-for='header in headers' v-if='header.show' :style='`flex: ${header.flex? 1: 0}; min-width: ${header.minWidth}`') 
      .column-label( :class='{"text-start": header.start, "pointer": header.start}' @click='sort(header.name)' test-id="task-table-header-title") 
        span {{ $t(header.title) }}
        v-btn(x-small icon color='#4d4d4d' v-if='header.icon && taskTreeCache' :class='{"white": header.name == taskTreeCache.sort_option.name}')
          img.sort-icon(src='/img/project/task/task-tree-sort.svg' :class='{"sort-icon-reverse": taskTreeCache.sort_option.name === header.name && !taskTreeCache.sort_option.reverse}')
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "TaskTableHeader",
  props: {
    headers: Array,
    icon: Boolean
  },

  data() {
    return {
      sortReverse: false
    }
  },

  computed: {
    ...mapState('task', ['taskTreeCache'])
  },

  methods: {
    sort(option) {
      if(option != null){
        if(option === "THEME") {
          this.taskTreeCache.sort("DEFAULT")
          return
        }

        if(this.taskTreeCache.sort_option.name === option) {
          this.sortReverse = !this.sortReverse
        }
        this.taskTreeCache.sort(option, this.sortReverse)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .column{
    color: #4d4d4d;
    display: flex;
    position: relative;
    border:1px #E5E5E5 solid;
    z-index: 1;
  }
  .task-table-header{
    // position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    font-size:12px;
    z-index: 1000;
  }
  .column-label{
    height: 36px;
    background: #E5E5E5;
    width: 100%;
    padding: 0px 13px 0px 0px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr auto;
    text-align: center;
  }
  .column-label::before{
    margin-left: -15px;
    content: "";
    position: absolute;
    width: 2px;
    height: calc(2 * 100vh);
    background: #E5E5E5;
  }
  .column-label.text-start.pointer{
    padding: 0 0 0 10px;
  }
  .column-label.text-start.pointer::before{
    content: "";
    position: absolute;
    width: 2px;
    height: 100vh;
    background: transparent;
  }
  .sort-icon{
    transform: rotate(180deg);
  }
  .sort-icon-reverse{
    transform: rotate(0deg);
  }
  .pointer{
    cursor: pointer;
  }
</style>