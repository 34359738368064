import uniqolor from 'uniqolor'

function generateColor (str) {
    let itemColor = uniqolor(str, { format: 'hex' })
    let color = itemColor.color
    return color
}

export const colorAdapter = {
    generateColor,

    generateColorByUUIDAndDate: (uuid) => {
        let dateString = new Date().getTime().toString()
        let str = uuid + "_" + dateString
        return generateColor(str)
    },
  }