<template lang="pug">
app-dialog(v-model="$_show" :header="$t('section.classification.menuClassification.addCompound', {name:$t(nodeType.title)} )" @close="handleClose" width="700" complex)
  app-section( :rounded="false" )
    .d-flex
      app-text-field(v-model="searchText" @keyup.enter="search" :label="$t('section.classification.menuClassification.codeSearch')" outlined dense clearable hide-details)
      app-button.ml-2(@click="search" color="accent" height="40") {{ $t('section.elementTree.search') }}

    v-form(ref="compoundForm" @submit.prevent="handleSave")

      div.base-scroll-body.mh350.my-2
        div.my-3(v-if="!searchMode")
          v-treeview.__project_view_tree(
            dense
            light
            hoverable
            item-disabled="disabled"
            :active.sync="activeItem"
            open-on-click
            :open.sync="openedNodes"
            item-key="uuid"
            :items="treeList"
            :load-children="loadChildrens"
            return-object)
            template(v-slot:label="{ item }")
              div(@click="selectItem(item)") {{ item.pressmark ? item.pressmark+' - ' : '' }}{{ item.title }}
              

        div.my-3(v-if="searchMode")
          v-skeleton-loader(v-if="searchLoading" type='list-item-three-line' light)
          v-list(v-if="!searchLoading" light dense)
            v-list-item-group(v-model="searchSelected")
              v-list-item(v-for="item in searchList" :key="item.uuid" :disabled="item.nodeType.value != nodeType.value" )
                span(v-html="itemHighlightedText(item)")
                
      v-alert(v-if="nodeError" dense text type="error") {{ nodeErrorText }}

      template(v-if="showDefault")
        app-text-field(v-model="compound.rate" :label="$t('section.classification.menuClassification.outgo')" outlined dense :rules="[rules.required, rules.double]")

        v-checkbox.mx-n1(v-model="compound.showInSmeta" dense hide-details light)
          template(v-slot:label)
            app-text.mx-n1 {{ $t('section.classification.menuClassification.displaySmeta') }}
        
        .d-flex
          v-spacer
          v-btn(color="accent" type="submit") {{ $t('button.save' )}}
      template(v-else)
        .d-flex
          .d-flex
            v-checkbox.checkbox( v-model="isChecked" color="accent" @change="resetValidationForm()" hide-details light)
          app-text-field(v-model="compound.rate" :label="$t('section.classification.menuClassification.outgo')" :disabled="!isChecked" dense :rules="[rules.required, rules.double]")

        .d-flex
          v-spacer
          app-button(action type="submit") {{ $t('button.add' )}}

</template>

<script>
import DialogToggable from "@/components/app/DialogToggable"
import { mapState } from "vuex";
import { api } from "@/api";

export default {
  mixins: [DialogToggable],
  components: {

  },

  props: {
    wormNode: Object,
    nodeType: Object,
    wormCompounds: Array,
    showDefault: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      compound: {
        wormNode:null,
        compoundedNode: null,
        rate: null,
        showInSmeta: false
      },

      treeList: [],
      openedNodes: [],
      activeItem: [],

      searchText: null,
      searchLoading: false,
      searchMode: false,
      searchList: [],
      searchSelected: null,

      nodeError: false,

      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      },
      isChecked: false,
      selectedNode: false,
    }
  },

  mounted() {
    this.compound.wormNode = this.wormNode
    this.listProjectClassofocators()
  },

  computed: {
    ...mapState("project", ["project"]),

    nodeErrorText(){
      return this.$t('section.classification.menuClassification.classNotSelected')
    }
  },

  watch: {
    $_show(val){
      this.nodeError = false
      this.treeList = []
      this.openedNodes = []
      this.activeItem = []
      this.searchSelected = null
      this.compound = {
        wormNode:null,
        compoundedNode: null,
        rate: null,
        showInSmeta: false
      }
      if (val) this.listProjectClassofocators()
    },

    searchText(val){
      if (val && val.trim() != '') {
        this.searchSelected = null
        this.activeItem = []
      } else {
        this.searchMode = false
        this.searchList = []
        this.searchSelected = null
      }
    },

    searchSelected(val){
      if (val != null) this.compound.compoundedNode = this.searchList[val]
      else this.compound.compoundedNode = null
    },

    activeItem(val){
      if (val && val[0]) this.compound.compoundedNode = val[0]
      else this.compound.compoundedNode = null
    },

    'compound.compoundedNode'(val){
      if (val) this.nodeError = false
      else this.nodeError = true
    }
  },

  methods: {
    search() {
      this.searchMode = true
      this.searchLoading = true
      api.smeta.searchForCompound(this.project.uuid, this.searchText).then(data=>{
        this.searchList = data
        this.searchLoading = false
      })
    },

    itemHighlightedText(item) {
      const highlightWords = this.searchText.trim().split(' ')
      const highlightRegexp = new RegExp(highlightWords.join('|'), 'gi')
      return ((item.pressmark ? item.pressmark + ' - ' : '') + item.title).replaceAll(highlightRegexp, (word) => '<span style="color:#3B93AF;">' + word + '</span>')
    },

    listProjectClassofocators() {
      api.smeta.projclasslist(this.project.uuid).then((data) => {
        data.forEach(e => {
          e.children = []
          e.ispc = true
        })
        this.treeList = data
      })
    },

    loadChildrens(item) {
      if (item.ispc) {
        api.smeta.topnodes(item.uuid).then((data) => {
          data = data.filter(d => d.nodeType.name != 'NODE')
          data.forEach(e => {
            delete e.children
            if (e.nodeType.name == 'TOPIC') e.children = []
            if (e.nodeType.name != 'TOPIC' && e.nodeType.name != this.nodeType.name) e.disabled = true
            if (this.wormCompounds.some(el => el.compoundedNode && el.compoundedNode.uuid == e.uuid)) e.disabled = true
          })
          item.children = data
          if (!this.openedNodes.includes(item.uuid)) this.openedNodes.push(item)
        })
      } else {
        api.smeta.childrennodesfast(item.uuid, item.projectClassificatorUuid).then((data) => {
          data.forEach(e => {
            delete e.children
            if (e.nodeType.name == 'TOPIC') e.children = []
            if (e.nodeType.name != 'TOPIC' && e.nodeType.name != this.nodeType.name) e.disabled = true
            if (this.wormCompounds.some(el => el.compoundedNode && el.compoundedNode.uuid == e.uuid)) e.disabled = true
          })
          item.children = data
          if (!this.openedNodes.includes(item.uuid)) this.openedNodes.push(item)
        })
      }
    },

    selectItem(item){
      if (item.nodeType && item.nodeType.name == this.nodeType.name) {
        this.activeItem = []
        this.activeItem.push(item)
        this.selectedNode = true
      }
    },

    handleSave(){
      if(this.compound.compoundedNode) {
        if(this.isChecked === false){
          this.compound.rate = null
        }
        this.$emit('addCompound', this.compound)
        this.resetValidationForm()
        this.isChecked = false
      } else {
        this.nodeError = true
        this.resetValidationForm()
      }
    }, 

    handleClose() {
      this.isChecked = false
      this.resetValidationForm()
    },

    resetValidationForm () {
      this.$refs.compoundForm.resetValidation()
    },
  }
}
</script>

<style lang="scss" scoped>

.mh350 {
  height: calc(75vh - 380px)
}

.v-input--selection-controls {
  margin-top: 5px !important;
}

::v-deep .v-treeview-node__label {
  white-space: wrap !important;
}
</style>