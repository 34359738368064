<template lang="pug">
  .header(:style="`height: ${height}px`" :class="{'header-center': headerItemsCenter}")
    .d-flex(:style=" urseatHeader ?  'overflow: hidden; ' : ''")
      .d-flex(:class="{'header-center': headerItemsCenter}" :style=" headerWrap ?  'flex-wrap: wrap; width: 100%; ' : 'width: 100%;' ")
        div.mr-2( v-if="$slots.menu || menu" )
          slot( name="menu" )
            app-menu( :menu="menu" basic color="var(--v-surface-lighten3)") 
        slot(name="headerBefore")
        .header-title( :style="titleStyle" :class="{'header-title-dark': colorDark, 'header-main-title':mainTitle, 'header-big-title':bigTitle}") {{ title }}
        slot(name="titleAfter")
    .d-flex.justify-end(:class="{'header-center': headerItemsCenter}")
      slot(name="headerAfter")
</template>

<script>
export default {
  props: {
    title: String,
    colorDark: Boolean,
    headerItemsCenter: Boolean,
    mainTitle: Boolean,
    bigTitle: Boolean,
    taskDetailsTitle: Boolean,
    height: {
      type: String,
      default: "24"
    },
    headerWrap: {
      type: Boolean,
      default: false
    },
    urseatHeader: {
      type: Boolean,
      default: false
    },
    titleStyle: {
      type: String,
      default: ""
    },
    menu: Array,
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.header-center{
  align-items: center;
}

.header-title {
  // margin-left: 8px;
  font: bold 12px/18px $roboto;
  text-transform: capitalize;
  color: var(--v-primary-darken2) !important;
  // color: #C4C4C4;
  // color: var(--v-surface-lighten3);
}
.header-main-title{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform:uppercase;
  // color:#E5E5E5;
  // color: var(--v-surface-lighten3);
}
.header-title-dark{
  margin-left: 0px;
  //text-transform: capitalize;
  font: normal 12px/18px $roboto;
  color: #262626 !important;
}
.header-big-title{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  // color: #E5E5E5;
  color: var(--v-surface-lighten3);
}
</style>