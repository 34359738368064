<template lang='pug'>
div.d-flex.flex-column
  div.flex.overflow-auto.bg.taskInfo.base-scroll-body
    app-section.primary.lighten-5.scroll
      task-info-section(:task='task' test='test' test-id="task-info-title-input")

      div.d-flex.flex-column(style='gap: 4px;')
        //- NEED REFACTOR
        task-smeta-section(:task="task")
        task-sub-tasks-section(:selectedTask='task' @click:subtask='$emit("click:subtask", $event)')
        //- NEED REFACTOR
        task-appointed-section(:selectedTask="task")
        task-maintenance-task-section(:selectedTask="task")
        task-models-section(:selectedTask="task")
        task-collisions-section( :selectedTask="task")
        //- NEED REFACTOR
        task-attachments-section(:selectedTask="task")
        //- NEED REFACTOR
        task-comments-section(:selectedTask='task' @quotedComment='(comment) => quotedComment = comment')
        task-params-section(:selectedTask='task')
    
  task-create-comment-section(:selectedTask='task' :quotedComment="quotedComment" @cancelQuote='quotedComment = null')

</template>

<script>

import TaskCommentsSection from '@/components/task/taskDetailsSections/taskComments/TaskCommentsSection.vue'
import TaskInfoSection from '@/components/task/taskDetailsSections/TaskInfoSection.vue'
import TaskStatus from '@/components/task/TaskStatus.vue'
import TaskPriority from '@/components/task/TaskPriority.vue'
import TaskSmetaSection from '@/components/task/taskDetailsSections/TaskSmetaSection.vue'
import TaskSubTasksSection from '@/components/task/taskDetailsSections/TaskSubTasksSection.vue'
import TaskAppointedSection from '@/components/task/taskDetailsSections/TaskAppointedSection.vue'
import TaskModelsSection from '@/components/task/taskDetailsSections/TaskModelsSection.vue'
import TaskCollisionsSection from '@/components/task/taskDetailsSections/TaskCollisionsSection.vue'
import TaskAttachmentsSection from '@/components/task/taskDetailsSections/TaskAttachmentsSection.vue'
import TaskCreateCommentSection from '@/components/task/taskDetailsSections/taskComments/TaskCreateCommentSection.vue'
import TaskParamsSection from '@/components/task/taskDetailsSections/TaskParamsSection.vue'
import WorkspaceSelectDialog from '@/components/task/WorkspaceSelectDialog.vue'
import TaskMaintenanceTaskSection from '@/components/task/taskDetailsSections/TaskMaintenanceTaskSection.vue'

export default {
  name: "TaskInfo",

  components: {
    TaskCommentsSection,
    TaskInfoSection,
    TaskStatus,
    TaskPriority,
    TaskSmetaSection,
    TaskSubTasksSection,
    TaskAppointedSection,
    TaskModelsSection,
    TaskCollisionsSection,
    TaskAttachmentsSection,
    TaskCreateCommentSection,
    TaskParamsSection,
    WorkspaceSelectDialog,
    TaskMaintenanceTaskSection
  },

  props: {
    task: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      quotedComment: null
    }
  }
}
</script>

<style scoped>
  ::v-deep .section-expansion {
    border-radius: 0px;
  }

  .scroll {
  overflow: hidden !important;
  }

  .bg {
    background-color: #f0f0f0;
    border-radius: 5px 5px 0 0;
  }
</style>