<template lang="pug">
app-panel( ident='grids' )
  app-panel-header.ml-2.mt-1( :title="$t('toolbarMenu.gridsSettings.settings')" showClose )
  app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden;" )
    app-floating-panel(:title="$t('toolbarMenu.gridsSettings.structure')" bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_PROJECT_TREE')")
      ifc-tree.ml-2.mt-2( v-model="state.gridsIfcTree" )    
    app-floating-panel(:title="$t('toolbarMenu.gridsSettings.displaySettings')" bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#ffffff'" :settings="floatingPanels('STRUCTURE_PROJECT_TREE')")
      v-checkbox.settings-options-header.ml-2.mt-2( v-model="state.gridsGlowingThrough", :label="$t('toolbarMenu.gridsSettings.transparency')", dense, hide-details, light, @change="state.toggleGridsGlowingThrough" )
      v-checkbox.settings-options-header.ml-2.mt-2( v-model="state.needDebounce", :label="$t('toolbarMenu.gridsSettings.debounce')", dense, hide-details, light, @change="state.toggleGridsDebounce" )
</template>

<script>
import IfcTree from "@/components/project/common/IFCTree.vue"
import { useGridsSettingsState } from '@/states/grids/gridsSettings.state'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'grids',

  components: {
    IfcTree,
  },

  computed: {
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

    state: () => useGridsSettingsState()
  },

  mounted() {
    this.state.requestGridsIfcTree()
    this.state.loadState()
  },
}
</script>

<style>

.tree-background {
  border-radius: 5px;
  background-color: #f0f0f0;
  max-height: 400px;
}

.btn {
  outline: none !important;
}
</style>