<template lang="pug">
  .date-row-content(@click="showTaskDateInterval") {{ dateFormat }}
    task-date-interval(ref="taskDateInterval" :task="task")
</template>

<script>
import { Task } from '@/assets/model/task/Task'
import TaskDateInterval from '@/components/task/TaskDateInterval'
export default {
  name: "TreeRowDate",
  components:{
    TaskDateInterval
  },
  props: {
    task: {
      type: Task,
      required: true
    },
    end: Boolean
  },
  computed: {
    dateFormat() {
      let newFormatDate = new Date(this.end? this.task.endDate: this.task.startDate)
      newFormatDate = (newFormatDate.getDate() + '.' 
        + ((newFormatDate.getMonth()+1) < 10 ? '0' : '') 
        + (newFormatDate.getMonth()+1) + '.' + newFormatDate.getFullYear())
      return newFormatDate
    }
  },
  methods:{
    showTaskDateInterval(){
      this.$refs.taskDateInterval.showTaskDateInterval()
    },
  }
}
</script>

<style lang="scss" scoped>
  .date-row-content{
    display: flex;
    color: #4D4D4D;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 4px 0px 6px;
    min-width:100px; 
    flex:0;
  }
</style>