<template lang="pug">
app-dialog(v-model="$_show" :header="$t('section.worm.compareVersions')" width="80%" complex)
  v-row(no-gutters style="height:100%")
    v-col(cols=6)
      app-section.h500.overflow-x-hidden.base-scroll-body( :rounded="false" )
        app-select.mb-5(v-model="version1" :items="wormVersions" :item-text="item => item.title +' ver. '+ item.version" :label="$t('section.worm.comparisonVersion', { number: 1} )" clearable hideDetails )
        div(v-if="version1")
          app-text
            b {{$t('section.worm.versionDate', { date: toLocalTimeZone(version1.dateTime.replace(" ", "T")) }) }}
          version-tree(:items="ver1Nodes")

    v-col(cols=6 style="padding-left: 1px;")
      app-section.h500.overflow-x-hidden.base-scroll-body( :rounded="false" )
        app-select.mb-5(v-model="version2" :items="wormVersions" :item-text="item => item.title +' ver. '+ item.version" :label="$t('section.worm.comparisonVersion', {number: 2})" clearable hideDetails )
        div(v-if="version2")
          app-text
            b {{$t('section.worm.versionDate', { date: toLocalTimeZone(version2.dateTime.replace(" ", "T")) }) }}
          version-tree(:items="ver2Nodes" :compare-to="version1")
</template>

<script>
import { mapGetters } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

import WormVersion from '@/assets/model/worm/WormVersion'
import WormVersionNode from '@/assets/model/worm/WormVersionNode'

import VersionTree from './VersionTree'

export default {
  name:'worm-version-compare',

  mixins: [DialogToggable],
  
  components: {
    VersionTree
  },

  props: {
    
  },

  data() {
    return {
      version1: null,
      version2: null,
    }
  },

  mounted() {
    
  },

  computed: {
    ...mapGetters('authUser', ['toLocalTimeZone']),
    wormVersions(){
      return WormVersion.all()
    },

    ver1Nodes(){
      if (this.version1) return WormVersionNode.query().where('wormVersionUuid', this.version1.uuid).where('parentUuid', null).get()
      return []
    },

    ver2Nodes(){
      if (this.version2) return WormVersionNode.query().where('wormVersionUuid', this.version2.uuid).where('parentUuid', null).get()
      return []
    }
  },

  watch: {
    version1(v){
      this.loadNodes(v)
    },

    version2(v){
      this.loadNodes(v)
    }
  },

  methods: {
    loadNodes(version){
      if (version) {
        WormVersionNode.api().get('/worm/version/'+version.uuid+'/nodes')
      }
    }
  }
}
</script>

<style scoped>
.h500 {
  height: 500px;
}
</style>