<template lang="pug">
.drawing__info__block
  .drawing__info_text
    //- .heading-6.drawing__text_color Зафиксируйте требуемое положение
    .body-2.drawing__subtext_color Укажите настройки, которые необходимо применить
  .drawing__info_text
    .drawing__info_input
      .body-2.drawing__subtext_color Наименование
      app-text-field.drawing__info_input_width( v-model="state.title" label="Наименование" hideDetails @input="state.applyTitle" )
    .drawing__info_input 
      .body-2.drawing__subtext_color Масшабирование (м)
      app-text-field.drawing__info_input_width( v-model="state.heightSize" label="Масштаб" hideDetails @input="state.applyHeightSize" )
    .drawing__info_input 
      .body-2.drawing__subtext_color Поворот (градусы)
      .d-flex.align-center
        app-text-field.drawing__info_coord_width( v-model="state.rotation[0]" label="X" hideDetails @input="state.applyRotation" )
        app-text-field.drawing__info_coord_width.mx-2( v-model="state.rotation[1]" label="Y" hideDetails @input="state.applyRotation" )
        app-text-field.drawing__info_coord_width( v-model="state.rotation[2]" label="Z" hideDetails @input="state.applyRotation" )
    .drawing__info_input 
      .body-2.drawing__subtext_color Смещение (м)
      .d-flex.align-center
        app-text-field.drawing__info_coord_width( v-model="state.position[0]" label="X" hideDetails @input="state.applyPosition" )
        app-text-field.drawing__info_coord_width.mx-2( v-model="state.position[1]" label="Y" hideDetails @input="state.applyPosition" )
        app-text-field.drawing__info_coord_width( v-model="state.position[2]" label="Z" hideDetails @input="state.applyPosition" )
  .d-flex.align-center
    .body-2.drawing__subtext_color Прозрачность
    v-slider.ml-2( v-model="state.opacity" max="100" min="0" dense hide-details color="var(--v-accent-base)" light @change="state.applyOpacity" )
  .d-flex.align-center.justify-end
    app-button( @click="state.cancelEdit" small cancel ) Отмена
    app-button.ml-2( @click="state.saveSettings" small action ) Сохранить
    //- app-button( @click="setDefaultPosition" small outlined ) Установить по умолчанию
</template>

<script>
import { useDrawingTransformSettingState } from '@/pinia';

export default {
  name: 'DrawingTransformSettingPanel', 

  computed: {
    state: () => useDrawingTransformSettingState()
  },

  beforeMount() {
    if (!this.state.isDrawingSettingMode) this.state.setEditedImageSectionPlaneState()
  }
}
</script>

<style scoped>
.drawing__info__block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.drawing__info_text {
  display: flex;
  flex-direction: column; 
  gap: 8px;
}

.drawing__info_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.drawing__text_color {
  color: #282828;
}

.drawing__subtext_color {
  color: #535353;
}

.drawing__info_input_width {
  max-width: 200px !important;
}

.drawing__info_coord_width {
  max-width: 70px !important;
}
</style>