import { defineStore } from 'pinia'

export const useSceneModelVisibilityStore = defineStore('scene-model-visibility', {
  state: () => ({
    isModelHidden: false,
  }),

  actions: {
    showModel () {
      this.isModelHidden = false
    },
    
    hideModel () {
      this.isModelHidden = true
    },
  }
})
