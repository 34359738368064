<template lang='pug'>
  div
    task-header(:title='$t("module.mnemoschemas.title")' headerItemsCenter mainTitle)
      
      template(v-slot:headerAfter)
        v-btn.mr-2(icon color='#E5E5E5' @click='openReceiversDialog()')
          v-icon mdi-devices
        div.mnemo-search
          //- input.mr-1(:value='search' @input='handleUpdate($event.target.value)')
          input.mr-1(:value='searchValue' @input='handleUpdate($event.target.value)')
          v-icon(color='#C4C4C4') mdi-magnify
    
    receivers-dialog(v-model='showReceiversDialog')
</template>

<script>
import TaskHeader from '@/components/task/TaskHeader'
import ReceiversDialog from './ReceiversDialog'

export default {
  name: "MnemoschemaPanelHeader",

  components: {
    TaskHeader,
    ReceiversDialog
  },

  props: ['search'],
  
  data: () => ({
    searchValue:null,
    showReceiversDialog: false,
  }),

  methods: {
    // handleUpdate(value) {
    //   this.$emit('update:search', value)
    // },
    handleUpdate(value) {
      this.$emit('searchMnemoschema', value)
    },

    openReceiversDialog() {
      this.showReceiversDialog = true
    },
  },
}
</script>

<style lang='scss' scoped>
  .mnemo-search {
    display: flex;
    background: #e5e5e5;
    border-radius: 3px;
    padding: 0px 4px;

    & input {
      outline: none;
      border: none;
    }
  }
</style>