<template lang="pug">
task-section-expansion( 
    :title="`${$t('module.task.subtasks')} (${selectedTask.children && selectedTask.children.length})`" 
    :showToggle="getSubTaskPanel"
    :additionalIcon="addIcon" 
    @additionalIconClick="iconClick" 
    openOnAdditionalIcon
    headerColorDark  
    test-id="task-subtask-section" 
    @openPanel="setSubTaskPanel($event)" 
    ref='expansion' 
  )
  div.mx-1
    div(v-if='show.createTask')
      markdown-textarea( v-model='subTaskTitle' :label='$t("module.task.form.title")' :autofocus="true" hide-details light solo flat dense no-resize test-id="subtask-title-input" )
        v-btn.ml-auto.mr-1(color='surface' x-small icon @click='show.createTask = false, subTaskTitle = null')
          v-icon clear
        v-icon.mr-1(small color='surface') mdi-calendar
        v-btn(color='accent' :disabled='validation' x-small text light @click='createSubTask()' test-id="create-subtask-button" ) {{ $t('button.create') }}
        
    draggable( v-model="selectedTask.children" @change="updateChildren" )
      div.subtask-selector.pa-1.mb-2( v-for="(task, index) in selectedTask.children || []" :key="task.uuid" @click="toggleTask(task)" test-id="subtask-list" )
        
        div.d-flex.align-center
          task-dates.mr-2(:task='task')
          task-progress-bar.flex(:task='task')
        
        markdown-text.short-task-title.t-title(:text='task.title')
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaskProgressBar from '@/components/task/TaskProgressBar.vue'
import draggable from 'vuedraggable'
import TaskDates from '../TaskDates.vue'
import MarkdownText from '@/components/project/panel/right/MarkdownText.vue'
import MarkdownTextarea from '@/components/project/panel/right/MarkdownTextarea.vue'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';

export default {
  name: 'TaskSubTasksSection',

  components: {
    draggable,
    TaskProgressBar,
    TaskDates,
    MarkdownText,
    MarkdownTextarea,
    TaskSectionExpansion
  },
  
  props: {
    selectedTask: Object,
  },

  data() {
    return {
      show: {
        createTask: false,
      },

      subTaskTitle: null,
    }
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('taskDetails', ['getSubTaskPanel']),

    ...mapGetters('projectPermissions', ['hasTaskCreate']),

    addIcon() {
      if (this.hasTaskCreate) return 'add_box.png'
      return null
    },

    validation() {
      if (this.subTaskTitle) {
        return !this.subTaskTitle.trim()
      } else {
        return !this.subTaskTitle
      }
    }
  },

  methods: {
    ...mapActions('task', ['reorderTaskChildren', 'addSubTask']),
    ...mapActions('taskDetails', ['setSubTaskPanel']),

    updateChildren(){
      let childrensUuids = this.selectedTask.children.map(child => child.uuid)
      this.reorderTaskChildren(childrensUuids);
    },

    iconClick() {
      if (!this.getSubTaskPanel) {
        const elem = this.$refs.expansion
        elem.openPanel()
      }
      this.show.createTask = !this.show.createTask
    },

    createSubTask () {
      if (!this.selectedTask.children) {
        this.selectedTask.children = []
      }
      this.addSubTask({ title: this.subTaskTitle, project: this.projectUuid, parent: { uuid: this.selectedTask.uuid }, sortOrder: this.selectedTask.children.length })
      this.subTaskTitle = ''

      this.show.createTask = false
    },

    toggleTask(task) {
      this.$emit('click:subtask', task)
    },
  }
}
</script>

<style lang="scss" scoped>
.short-task-title{
  word-wrap: break-word;
  -webkit-line-clamp: 3; 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subtask-selector:hover{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
}

.t-title {
  font: 400 13px/16px $roboto;
  font-style: normal;
  color: #262626;
}
</style>