import { longActionResultService, reportService } from '@/_services'

import store from '@/store'

export default {
  getExportResult,
  getGenerateReport,
  updateWorkSpaces
}



function getExportResult(processUuid){
  return longActionResultService.getExportResultByProcess(processUuid).then(data => {
    if (data.status.name == 'SUCCESS' && data.type.name == 'FILE' && data.result != '') {
      return longActionResultService.downloadExportFile(data).then(file=>{
        let a = document.createElement("a")
        a.href = URL.createObjectURL(new Blob([file]))
        a.download = data.result
        a.click()
      })
    }
  })
}

function getGenerateReport(source) {
  return reportService.downloadCollisions(source.reportName).then(file => {
    let a = document.createElement("a")
    a.href = URL.createObjectURL(new Blob([file]))
    a.download = source.reportName
    a.click()
  })
}

function updateWorkSpaces(source) {
  store.dispatch('workspace/LOAD_WORKSPACES').then((workspaces) => {
    if (store.state.project.projectSettings.workSpace == source.uuid) {
      let base = workspaces.find(e => e.title === '{module.task.baseWorkspace}')
      let ps = JSON.parse(JSON.stringify(store.state.project.projectSettings))
      ps.workSpace = base ? base.uuid : null
      store.commit('task/setTaskIsLoad', false)
      store.commit('task/setTasks', [])
      store.commit('task/setSelectedTask', null)
      store.commit('project/setTopUnit', '')
      store.dispatch('project/updateSettings', ps)
    }
  })  
}