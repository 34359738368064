<template lang="pug">
div
  .ma-2
    v-checkbox.settings-options-header( v-model="mySettingQualitySaoEnabled" :label="$t('module.statistic.projects.shadows')" dense hide-details light @change='setWasChanged(true)' )
    v-checkbox.settings-options-header( v-model="mySettingQualityAntialias" :label="$t('module.statistic.projects.smoothing')" dense hide-details light @change='setWasChanged(true)' )
    v-checkbox.settings-options-header( v-model="mySettingQualityEdges" :label="$t('module.statistic.projects.showFacets')" dense hide-details light @change='setWasChanged(true)' )
    v-checkbox.settings-options-header( v-model="mySettingQualityArmature" :label="$t('module.statistic.projects.showArmature')" title='"IfcReinforcingBar", "IfcReinforcingElement", "IfcReinforcingMesh", "IfcTendon", "IfcTendonAnchor"' dense hide-details light @change='setWasChanged(true)' )
    v-checkbox.settings-options-header( v-model="mySettingQualitySpeedRender" :label="$t('module.statistic.projects.speedRender')"  dense hide-details light @change='setWasChanged(true)' )
    //- Временно расположил аннотации здесь
    v-checkbox.settings-options-header( v-model="enableAnnotations", :label="$t('module.statistic.projects.showAnnotations')", dense, hide-details, light, @change='setWasChanged(true)' )
    //- annotations

    v-btn.mt-3( :disabled='!wasChanged' color='accent' elevation='0' light @click='setModelQuality()' ) {{ $t('button.save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { AlertService } from '@/assets/app/AlertService'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { IFCAnnotationsDrawer } from "@/plugins/IFCAnnotations/IFCAnnotatationsDrawer.js"
import Annotations from '../Annotations.vue'

export default {
  name: "BimQualitySettings",
  components: {
    Annotations
  },

  data() {
    return {
      mySettingQualitySaoEnabled: false, 
      mySettingQualityAntialias: false, 
      mySettingQualityEdges: true,
      mySettingQualityArmature: false,
      mySettingQualitySpeedRender: true,

      enableAnnotations: false,
      wasChanged: false,
    }
  },

  computed: {
    ...mapGetters('authUser', ['modelQuality']),
    ...mapState("project", ["project"]),
  },

  mounted() {
    if (this.modelQuality) {
      let modelQuality = JSON.parse(this.modelQuality.value)
      this.mySettingQualityEdges = modelQuality.edges
      this.mySettingQualitySaoEnabled = modelQuality.sao
      this.mySettingQualityAntialias = modelQuality.antialias
      this.mySettingQualityArmature = modelQuality.armature
      this.mySettingQualitySpeedRender = modelQuality.speedRender ?? true
    }
  },

  methods: {
    ...mapActions('authUser', ['setProfileSettingModelQuality']),
    ...mapActions('IFCAnnotations', ['getIFCAnnotations']),

    setModelQuality() {
      this.setWasChanged(false)

      const modelQuality = {
        edges: this.mySettingQualityEdges,
        sao: this.mySettingQualitySaoEnabled,
        armature: this.mySettingQualityArmature,
        antialias: this.mySettingQualityAntialias,
        speedRender: this.mySettingQualitySpeedRender
      }

      const modelQuality$1 = XeokitMediator.ModelQuality.setModelQuality({
        sao: modelQuality.sao,
        edges: modelQuality.edges,
        fastNav: modelQuality.speedRender,
        antialias: modelQuality.antialias
      })

      if (modelQuality$1.antialias !== this.mySettingQualityAntialias) {
        AlertService.warning({
          info: 'Для применения настроек сглаживания обновите страницу'
        })
      }

      if(this.enableAnnotations){
        let projectUuid = this.project.uuid
        this.getIFCAnnotations(projectUuid).then(data => {
          if(data == 0 || data[0].annotations.length == 0) {
            AlertService.info({info: this.$t('module.statistic.projects.noIfcAnnotations')})
            this.isGridsActive = false
          }
          else {
            IFCAnnotationsDrawer.drawIFCAnnotations(data)
          }
        })
      }
      else {
        IFCAnnotationsDrawer.clearDrawedEdges()
        IFCAnnotationsDrawer.clearDrawedPoints()
        IFCAnnotationsDrawer.clearDrawedTexts()
      }

      this.setProfileSettingModelQuality(JSON.stringify(modelQuality))
    },

    setWasChanged(wasChanged) {
      this.wasChanged = wasChanged
    }
  }
}
</script>

<style>

</style>