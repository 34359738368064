<template lang="pug">
div
  condition( v-model="isCondition" @created="createCondition" :returnTitleName="true" )
  groovy-guide-dialog( ref="guide" @openDialog="openDialogMethod" @onInsertText="$emit('insertText', $event)")
</template>

<script>
import Condition from '@/components/project/panel/left/components/logicGroup/Condition.vue'
import GroovyGuideDialog from './GroovyGuideDialog.vue'

export default {
  components: {
    Condition,
    GroovyGuideDialog
  }, 

  data() {
    return {
      isCondition: false,
    }
  },

  methods: {
    showGuide() {
      this.$refs.guide.show()
    },

    openDialogMethod(method){
      switch(method) {
        case 'createCondition':
          this.$store.dispatch('project/loadAxisEnum')
          this.$store.dispatch('project/loadConditionOperator')
          this.isCondition = true
          break
      }
    },

    createCondition(form){
      let operand = form.operand.name
      let operator = form.operator.name
      let value = form.value
      let title = form.title
      let array = false

      if (operand === "CLASS") title = title[title.length - 1]
      else if (typeof title != 'string' && title.length > 1) array = true
      else if (typeof title != 'string') title = title[0]

      if (!array) title = this.renameTitle(title)

      if(operand === "MODEL" || operand === "FLOOR" || operand === "CLASS" || operand === "ZONE" || operand === "LAYER") {
        if(operator === "CONT" || operator === "NOTCONT") title = ""
        else value = ""
      }

      if(operand === "ATTRIBUTE" || operand === "USERPROP" || operand === "MASK" || operand === "GEOMETRY") {
        if(operator === "EX" || operator === "NOTEX") value = ""
      }
      
      let command = "bim.createCondition(\"" + operand + "\", "

      if (array) {
        command += "\"" + title[title.length - 2] + "\", \"" + title[title.length - 1] + "\", "
        command += "\"" + operator + "\", "
        if (value) command += "\"" + value + "\""
        else command += "\"\""
      } else if (value) {
        if (title) command += "\"" + title + "\", "
        command += "\"" + operator + "\", "
        command += "\"" + value + "\""
      } else {
        command += "\"" + operator + "\", "
        command += "\"" + title + "\""
      }
      command += ")"

      this.$emit('insertText', command)
      this.isCondition = false
    },

    renameTitle(str) {
      switch(str) {
        case this.$t("section.elementTree.idprop.ifcGlobalId"):
          return "ifcGlobalId"
        case this.$t("section.elementTree.idprop.uuid"):
          return "uuid"
        case this.$t("section.elementTree.idprop.name"):
          return "name"
        case this.$t("section.elementTree.geometry.area"):
          return "area"
        case this.$t("section.elementTree.geometry.volume"):
          return "volume"
        case this.$t("section.elementTree.geometry.areax"):
          return "areax"
        case this.$t("section.elementTree.geometry.areay"):
          return "areay"
        case this.$t("section.elementTree.geometry.areaz"):
          return "areaz"
        case this.$t("section.elementTree.geometry.boundx"):
          return "boundx"
        case this.$t("section.elementTree.geometry.boundy"):
          return "boundy"
        case this.$t("section.elementTree.geometry.boundz"):
          return "boundz"
        case null:
          return ""
        default:
          return str
      }
    },
  }
}
</script>
