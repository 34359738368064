<template lang="pug">
app-panel( padding="0px")
  fix-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
    fix-panel(:title="$t('section.journal.chart')" style="height: calc(100% - 10px) !important;")
      template(v-slot:headerButton)
        app-toolbar-button.mr-2(v-if="store.hasMaintainceSettingChartTO" header size="small" icon="$setting-icon" :title="$t('module.task.settings')" @click.stop="openSettings" )
        
      div.wrapper
        v-simple-table.scroll-changer(dense fixed-header height="100%" style="height: 100%")
          template(v-slot:default)
            thead
              tr
                th.sticky-col 
                  v-tooltip(v-if="store.daysInMonth" top) {{ $t('module.task.back') }}
                    template(v-slot:activator="{on, attrs}" )
                      v-btn(@click="store.returnFromMonthView()" small icon v-bind="attrs" v-on="on")
                        v-icon mdi-arrow-left 
                  span(v-if="store.daysInMonth").ml-2 {{store.prettyDate}}    
                th.cell-selection.px-1.app-cursor--pointer(v-if="!store.daysInMonth" v-for="(month, index) in store.months" @click="store.goToMonthView(month, index)") {{ store.getMonthTitle(month) }}
                th.cell-selection.px-1.app-cursor--pointer(v-if="store.daysInMonth" v-for="day in store.daysInMonth") {{ day }}

            tbody
              tr(v-for="item in store.treeFlatList" :key="item.uuid")
                td.sticky-col(:style="item.isGroup? '' : 'overflow: auto;'") 
                  .node__level( v-for="index in item.level" :key="index" )
                  span(v-if="item.isGroup") {{ sourceTitle(item) }}
                  span.app-cursor--pointer.font-weight-bold(v-if="!item.isGroup" @click="goToEquipment(item)") {{ sourceTitle(item) }}

                td.td-item.px-1(v-if="!store.daysInMonth" v-for="(taskList, index) in store.mTasks" :class="{ 'cell-selection':!item.isGroup }" @contextmenu.self="onCellContext($event, index, 'month', item)")
                  span(v-if="!item.isGroup") 
                    template(v-for="task in taskList")
                      span.app-cursor--pointer.px-1(v-if="task.equipmentId == item.uuid" :class="'task-status--' + task.status.name" @click="openCheck(task)" @contextmenu="onCellContext($event, index, 'month', item, task)") {{task.title}}

                td.td-item.px-1(v-if="store.daysInMonth" v-for="(taskList, index) in store.monthTask" :class="{ 'cell-selection':!item.isGroup }" @contextmenu.self="onCellContext($event, index, 'day', item)")
                  span(v-if="!item.isGroup") 
                    template(v-for="task in taskList")
                      //- v-tooltip(top) Смена: {{getDayWorkShift(task.maintenanceDate)}}
                      //-   template(v-slot:activator="{on, attrs}")
                      .app-cursor--pointer.px-1(v-if="task.equipmentId == item.uuid" style="padding:0" :class="'task-status--' + task.status.name" @contextmenu="onCellContext($event, index, 'day', item, task)" @click="openCheck(task)")
                        div(style="font-size:9px") {{ getDutyNum(task) }}
                        div(style="font-size:11px") {{ task.title }}


  maintenance-check-dialog(v-model="dialog.saveCheck" :maintenanceTask="store.currentTask" @updateMDate="store.updateData")
  maintenance-settings-dialog(v-if="dialog.settings" v-model="dialog.settings" @close="closeSettings()" @confirm="closeSettings()")
  maintenance-date-dialog(v-model="dialog.dateEdit" :maintenanceTask="store.currentTask" @updateMDate="store.updateData")
  maintenance-duty-dialog(ref="dutyDialog" @updateDuty="store.updateData")
  maintenance-add-dialog(ref="addDialog")
  app-dialog-confirm(v-model="dialog.delete" @confirm="deleteMaintenanceTask")
    app-text {{ $t('section.equipment.maintenance.deleteConfirm', { val:currentTaskName }) }}
  
  app-menu( ref="menu" :menu="taskMenu" )
</template>

<script>
import FixPanel from '@/components/project/panel/left/maintenance/components/FixPanel.vue'
import FixPanelsSection from '@/components/project/panel/left/maintenance/components/FixPanelsSection.vue'
import MaintenanceCheckDialog from '@/components/project/panel/left/maintenance/dialogs/MaintenanceCheck.vue'
import MaintenanceDateDialog from '@/components/project/panel/left/maintenance/dialogs/MaintenanceDateDialog.vue'
import MaintenanceDutyDialog from '@/components/project/panel/left/maintenance/dialogs/MaintenanceDutyDialog.vue'
import MaintenanceSettingsDialog from '@/components/project/panel/left/maintenance/dialogs/MaintenanceSettings.vue'
import MaintenanceAddDialog from './dialogs/MaintenanceAddDialog.vue'
import { useMaintenanceScheduleStore } from '@/pinia'

export default {
  components: {
    MaintenanceAddDialog,
    MaintenanceCheckDialog,
    MaintenanceSettingsDialog,
    MaintenanceDateDialog,
    MaintenanceDutyDialog,
    FixPanel,
    FixPanelsSection,
  },

  data() {
    return {
      dialog: {
        saveCheck: false,
        settings: false,
        dateEdit: false,
        delete: false,
      },
    }
  },

  // watch: {
  //   'store.months': function () {
  //     this.store.goToMonthView(this.store.viewSettings.month, this.store.viewSettings.index)
  //   },
  // },

  computed: {
    store: () => useMaintenanceScheduleStore(),

    currentTaskName() {
      return this.store?.currentTask?.title
    },

    taskMenu() {
      const menu = [{ title: this.$t('section.equipment.maintenance.assignMaintenance'), action: () => this.$refs.addDialog.showDialog() }]

      if (this.store.currentTask) {
        menu.push({ title: this.$t('module.task.openTask'), action: this.store.goToTask })
        if (this.store.hasMaintainceDateTO) {
          menu.push({ title: this.$t('section.equipment.maintenance.maintenanceDateChange'), action: () => (this.dialog.dateEdit = true) })
        }

        if (this.store.daysInMonth) {
          menu.push({ title: this.$t('section.equipment.maintenance.changeDutyNum'), action: () => this.changeDutyDialog() })
        }

        menu.push({ title: this.$t('section.equipment.maintenance.removeMaintenance'), action: () => (this.dialog.delete = true) })
      }

      return menu
    },
  },

  methods: {
    deleteMaintenanceTask() {
      this.store.deleteMaintenanceTask(this.store.currentTask.uuid)
    },

    openEditDateDialog(task) {
      this.store.currentTask = task
      this.dialog.dateEdit = true
    },

    sourceTitle(source) {
      return source.isGroup ? source.title : source.name
    },

    openCheck(task) {
      this.store.currentTask = task
      this.dialog.saveCheck = true
    },

    goToEquipment(item) {
      this.$emit('goToEquipment', item)
    },

    openSettings() {
      this.dialog.settings = true
    },

    closeSettings() {
      this.dialog.settings = false
    },

    changeDutyDialog() {
      this.$refs.dutyDialog.showDialog(this.store.currentTask, this.store.getDayWorkShift(this.store.currentTask.maintenanceDate))
    },

    getCurrentDuty(task) {
      if (task && !task.dutyNum && task.dutyNum <= 0) return this.store.getDayWorkShift(task.maintenanceDate)
      return null
    },

    getDutyNum(task) {
      if (task.dutyNum === -1) return 'error'
      else if (task.dutyNum) return 'c.' + task.dutyNum
      else return 'c.' + this.store.getDayWorkShift(task.maintenanceDate)
    },

    onCellContext(event, index, type, equipment, task) {
      if (equipment.isGroup) return
      if (type === 'month') {
        const [m, y] = this.store.months[index].split(' ')
        this.store.selectedDate = new Date(y, Number(m), 1).toLocaleDateString('ru').split('.').reverse().join('-')
      } else {
        const [m, y] = this.store.viewSettings.month.split(' ')
        this.store.selectedDate = new Date(y, Number(m), ++index).toLocaleDateString('ru').split('.').reverse().join('-')
      }

      this.store.selectedEquipment = equipment
      this.store.currentTask = task
      this.$refs.menu.show(event)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

th {
  min-width: 40px;
}

th,
td {
  border-left: 1px solid #dddddd;
  border-collapse: collapse;
}

tr:hover {
  background-color: transparent !important;
}

.cell-selection:hover {
  background-color: #eee !important;
}

th.app-cursor--pointer,
td.td-item {
  z-index: 0 !important;
  text-align: center !important;
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}

/* mozilla firefox */
.scroll-changer .v-data-table__wrapper {
  scrollbar-width: thin;
  scrollbar-color: var(--v-scroll-darken1) var(--v-scroll-base);
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  left: 0px;
  padding-left: 5px !important;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.sticky-col span {
  font-size: 12px;
}

.t-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.sticky-col::-webkit-scrollbar {
  height: 5px;
  background-color: var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar-thumb,
.sticky-col::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-lighten4);
}

.node__level {
  display: inline-block;
  width: 8px;
}

.task-status--DONE {
  padding: 5px;
  background-color: green;
}

.task-status--SKIP {
  padding: 5px;
  background-color: red;
}

.task-status--PROGRESS {
  padding: 5px;
  background-color: #cddd16;
}
</style>
