<template lang="pug">
  app-dialog( v-model="visible" :header="$t('module.task.choose')" :confirm-text="$t('button.choose')" :confirm-disabled="!tasksSetting.selected" @confirm="attachCollisionsToTask" withScrollPanel)
    app-section.base-scroll-body(style="height: calc(100% - 56px)")
      div( v-if="tasksSetting.loading" )
        v-skeleton-loader( type="list-item-two-line" light )
      .select-task( v-else )
        app-text-field(v-model='searchText' :label='$t("section.elementTree.search")')
        project-task-card.mb-2( :key="t.uuid" v-for="t in filteredTasks" :class="tasksSetting.selected && t.uuid == tasksSetting.selected.uuid ? 'selected' : 'simple'" :task="t" @click.native="toggleTask(t)" )
</template>

<script>
  import { api } from '@/api'
  import { mapState, mapGetters } from 'vuex'

  import ProjectTaskCard from '@/components/project/common/TaskCard'
  import { TaskScreenshot } from '@/components/task/taskScreenshot'

  export default {
    components: {
      ProjectTaskCard,
    },

    data() {
      return {
        visible: false,
        tasksSetting: {
          selected: null,
          loading: false,
          single: false,
        },
        selectedRow: null,

        searchText: '',
      }
    },

    computed: {
      ...mapState('task', ['tasks']),

      ...mapGetters('collision/table', ['selectedCollisionsUuid']),

      filteredTasks() {
        return this.tasks.filter(task => task.title.toLowerCase().includes(this.searchText.toLowerCase()))
      },
    },

    methods: {

      show({ single, item = null }) {
        this.tasksSetting.loading = true
        this.tasksSetting.single = single
        this.selectedRow = item
        this.visible = true
        this.searchText = ''
        this.$store.dispatch('task/loadTasks').then(() => {
          this.tasksSetting.loading = false
        })
      },

      toggleTask(task) {
        let { selected } = this.tasksSetting
        this.tasksSetting.selected = selected && task.uuid == selected.uuid ? null : task
      },

      attachCollisionsToTask() {
        let list = this.tasksSetting.single ? [this.selectedRow.uuid] : this.selectedCollisionsUuid
        api.tasks.attachCollisionsToTask(this.tasksSetting.selected.uuid, list, TaskScreenshot.createTaskScreenshotSourceData())
      },
    },
  }
</script>

<style lang="scss" scoped>
  .selected {
    border: 4px solid #3b93af;
  }
  .simple {
    border: 1px solid #c4c4c4;
  }

  .select-task {
    height: 400px;
  }

</style>
