<template lang="pug">
div 
  v-tooltip( bottom )
    template( v-slot:activator="{ on, attrs }" )
      app-icon-action( v-on="on" icon="$delete-icon" color="var(--v-primary-darken2)" @click="deleteIFCFile = !deleteIFCFile" )
    span {{ $t('patch.dialog.deleteFile') }}

  app-dialog-confirm( v-model="deleteIFCFile" @confirm="deletePatch" )
    .primary--text {{$t('patch.dialog.deleteRec')}} "{{ selectedPatch.name }}"?
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PatchDeleteFile', 

  data() {
    return {
      deleteIFCFile: false,
    }
  },

  computed: {
    ...mapState('patches', ['selectedPatch']),
  },
  methods: {
    ...mapActions('patches', ['deletePatchFile']),

    deletePatch() {
      this.deletePatchFile(this.selectedPatch.uuid).then( () => {
        this.deleteIFCFile = false
      })
    }
  }
}
</script>