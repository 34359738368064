<template lang='pug'>
  app-dialog-confirm(v-model='show' delete @confirm='handleConfirm()')
    app-text {{ $t('module.mnemoschemas.confirmDeleteText', { schemaTitle: schema && schema.title ? schema.title : '-' }) }}
</template>

<script>
export default {
  name: "DeleteSchemaDialog",

  props: ['value', 'schema'],

  computed: {
    show: {
      get() {
        return this.value
      },

      set() {
        this.close()
      }
    }
  },

  methods: {
    close() {
      this.$emit('input', false)
    },

    handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>

</style>