<template lang='pug'>
  iframe#graph-viewer(:src='graphViewerSrc' frameBorder='0')
</template>

<script>
import { Telemetry } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import i18n from '@/plugins/i18n'
import { telemetryService } from '@/_services/telemetry.service'

const graphViewerSrc = '/graph/examples/grapheditor/www/viewer.html?openInSameWin=1'

export default {
  name: "GraphViewer",
  
  data: () => ({
    graphViewerSrc: graphViewerSrc + `&lang=${i18n.locale}`,
    telemetrySubscribe: null,
  }),

  async beforeMount() {
    window.addEventListener('message', this.windowMessageHandler = ({data}) => this.iframeMessageHandler(data))
    
    this.waitForViewer = new Promise((resolve) => {
      this.viewerReady = resolve
    })

    Telemetry.on('update', this.telemetryUpdateHandler = (data) => {
      const profile = this.profiles.find(profile => profile.warningFeatures.find(feature => feature.uuid === data.warningData?.warningFeature))
      const feature = profile?.warningFeatures.find(feature => feature.uuid === data.warningData?.warningFeature)

      const channelData = {
        [data.uuid]: {
          value: data.tData.value,
          warningLevel: profile?.level.value ?? null,
          alarmText: data.tData.value + ` (мин.: ${feature?.min}, макс.: ${feature?.max})`
        }
      }

      this.viewerWindow.postMessage({
        type: 'update',
        data: channelData
      })
    })
    // this.telemetrySubscribe = new TelemetrySubscribe(this.projectUuid)
    // this.telemetrySubscribe.on('update', (data) => {
    //   this.viewerWindow.postMessage({
    //     type: 'update',
    //     data: [data]
    //   })
    // })
    
    // TODO Если добавить новое устройство, в схеме оно не добавится
    this.receivers = await telemetryService.getReceivers(this.projectUuid).then(data => data.data)
    await Promise.all(this.receivers.map(receiver => {
      return telemetryService.getChannels(receiver.uuid).then(data => (receiver.channels = data.data))
    }))
  },

  async mounted() {
    this.viewerWindow = document.querySelector('#graph-viewer').contentWindow
  },

  beforeDestroy() {
    Telemetry.off('update', this.telemetryUpdateHandler)
    window.removeEventListener('message', this.windowMessageHandler)
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapState('channel/cache', ['channelsCache']),
    ...mapState('equipmentWarning', ['profiles']),
  },

  methods: {
    iframeMessageHandler(eventData) {
      switch (eventData.type) {
        case 'viewerReady': {
          this.viewerReady()
          break;
        }
      }
    },

    async setMnemoschema(mnemoschema) {
      await this.waitForViewer

      this.viewerWindow.postMessage({
        type: 'setMnemoschema',
        mnemoschema,
        receivers: this.receivers,
      })

      const tData = {}
      this.channelsCache.forEach((channel, channelUuid) => {
        tData[channelUuid] = channel.value
      })
      
      this.viewerWindow.postMessage({
        type: 'update',
        data: tData
      })
    }
  }
}
</script>

<style scoped>
  #graph-viewer {
    height: 100%;
    width: 100%;
  }
</style>