<template lang="pug">
  v-dialog(v-model="$_show" scrollable)
    v-hover(#default="{ hover }")
      .dialog-body.surface.base( :style=" withScrollPanel ? 'height: 100%;' : ''" )
        .close-button()
          v-btn.close-icon.mt-1.mr-1( v-if="hover" icon @click="handleClose" test-id="close-app-panel")
            v-icon( size="24" color="" ) clear
        slot     
</template>

<script>
import { SourcePath } from '@app/SourcePath'
import DialogToggable from '@/components/app/DialogToggable'
export default {
  name: "ImageDialog",
  mixins: [DialogToggable],

  props: {
    file: String,
    confirmText: String,
    withScrollPanel: Boolean
  },

  computed:{
    imagePath() {
      return SourcePath.attachment(this.file)
    },
  },

  methods:{
    handleClose () {
      this.$_show = false
      this.$emit('close')
    }
  }

}
</script>
  
<style>
.v-dialog {
  width: auto;
}
</style>

<style lang="scss" scoped>
.dialog-body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.close-button { 
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.close-icon {
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>