<template lang="pug">
div
  app-dialog( v-model="$_show" header="Export BCF" @confirm="handleExportBCF" confirmText="Экспортировать")
    app-section
      v-checkbox( v-model="isNeedToFlip" dense label="Инвертировать секущие плоскости" light ) 
  app-dialog-loading( v-model='bcfExportIsLoading' )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import { authUserService } from '@/_services'
import { config, $_app_server } from '@/_helpers'
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ExportBCFDialog', 

  mixins: [DialogToggable],

  data() {
    return {
      isNeedToFlip: false, 
      bcfExportIsLoading: false,
    }
  }, 

  computed: {
    ...mapState('task', ['filter']),
    ...mapGetters('project', ['projectUuid']),
  },

  methods: {
    handleExportBCF() {
      let send = () => {
        this.bcfExportIsLoading = true
        this.filter.reverseClippingPlanes = this.isNeedToFlip
        $_app_server({
          url: `${config.apiUrl}tasks/exportBCF`,    //?version=3.0
          data: this.filter,
          method: 'POST',
          responseType: 'blob'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.download = `${this.projectUuid}.bcfzip`
          link.click()
          link.remove()
          this.bcfExportIsLoading = false
        }).catch( (error) => {
          if (error.response !== undefined) {
            // console.log(error.response.status)
            if (error.response.status !== 401){
              // this.grandSmeta.error = error
            }

            if (error.response.status === 401){
              authUserService.refreshToken().then(() => {
                send()
              }).catch( (error) => {
                console.log(error)
                console.log(error.response)
              })
            }
          }
        })
      }

      send()
    }
  }
}
</script>