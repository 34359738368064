<template lang='pug'>
app-dialog(v-model='$_show' v-bind='$attrs' :confirm-text='$t("button.confirm")' @confirm='handleConfirm()')
  app-section
    app-select(v-model='selectedWorkspace' :label="$t('module.task.selectWorkspace')" :items="listWorkspaces" item-value='uuid' :item-text='getTitle' dense hide-details)
</template>

<script>
import { mapGetters } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable.vue'

export default {
  name: "SelectDialog",

  mixins: [DialogToggable],

  props: {
    workspace: String,
  },

  data() {
    return {
      selectedWorkspace: null
    }
  },

  computed: {
    ...mapGetters('workspace', ['workspaces']),
    
    listWorkspaces() {
      let list = JSON.parse(JSON.stringify(this.workspaces))

      let base = list.find(e => e.title === '{module.task.baseWorkspace}')
      if (!base) {
        list.unshift({uuid:null, title:this.$t("module.task.baseWorkspace")})
      }

      return list
    },
  },

  watch: {
    $_show() {
      this.selectedWorkspace = this.workspace
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm', this.selectedWorkspace)
    },

    getTitle(item) {
      let title = item.title

      if (!title) return ""

      if (title.includes("{")) 
        return this.$t(title.replace("{", "").replace("}", ""))

      return title
    },
  }
}
</script>

<style>

</style>