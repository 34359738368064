<template lang="pug">
div.base-scroll-body
  app-dialog(v-model="$_show" :header="$t('module.task.taskTypeSelection')" width="500" style="overflow-x:hidden;")
    task-form-details(v-if="$_show" @changeTaskType="changeTaskType")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { api } from '@/api'
import Field from '@/pages/admin/forms/Field'
import DialogToggable from '@/components/app/DialogToggable'
import FillForm from '@/pages/admin/forms/Form'
import TaskFormDetails from '@/components/task/taskDetailsSections/TaskFormDetails'

export default {
  name: "TaskFormTypeDialog",

  props: {
    selectedTask: null,
    returnData: {
      default:false,
      type:Boolean
    }
  },
  mixins: [DialogToggable],
  components: {
    Field,
    FillForm,
    TaskFormDetails
  },

  data () {
    const defaultForm = Object.freeze({
          uuid: null,
          bind: null,
          fields: [],
          form: { "uuid" : this.formId, "name": null }
        })

    return {
      selectedForm: null,
      formId: null,
      dialog: {
        showForm: false,
        saveForm: false,
      },
      formData: Object.assign({}, defaultForm),
      defaultForm,
      type: null,
      fieldValues: [],
      selectedTaskTypeId: null,
      edit: false,
    }
  },

  mounted () {
    this.$store.dispatch('project/loadAxisEnum')
    // this.$store.dispatch('forms/INIT', { bind: this.projectUuid })
  }, 

  watch: {
    $_show (value) {
      if(!value){
        this.loadTasks()
      } else {
        if(this.selectedTask.taskType){
          console.log(this.selectedTask)
          this.selectedTaskTypeId = this.selectedTask.taskType.uuid
        } 
      }
    }
  },

  computed: {
    ...mapGetters('forms', ['form', 'forms', 'isFormsLoaded', 'formTypes', 'isFormTypesLoaded', 'fieldTypes', 'isFieldTypesLoaded']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('task', ['getTaskType']),
  },

  methods: {
    ...mapActions('forms', ['saveForm', 'deleteForm', 'gotoForm', 'saveField']),
    ...mapActions('task', ['setTaskType', 'setTaskTypeFields', 'loadTasks']),
    ...mapMutations('forms', ['setForm']),
    ...mapMutations('task', ['setSelectedTaskType']),

    changeTaskType(data) {
      if(this.selectedTask.taskType?.uuid) {
        api.forms.deleteData(this.selectedTask.taskType.uuid).then(() => {
          api.forms.fill(data.taskType).then((dt) => {
            api.forms.dataList(dt.bind).then(d => {
              if(d.length > 0){
                this.$store.commit('task/setSelectedTaskType', {taskType: d[d.length-1]})
                this.$_show = false
              }
            })
          })
        })
      } else {
        api.forms.fill(data.taskType).then((dt) => {
          api.forms.dataList(dt.bind).then(d => {
            if(d.length > 0){
              this.$store.commit('task/setSelectedTaskType', {taskType: d[d.length-1]})
              this.$_show = false
            }
          })
        })
      }
    },
  }
}
</script>

<style scoped>
  .hovered-sec {
    border-radius: 1px;
    background-color: #F4F4F4;
  }

  .task-form-font {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .hovered-mark {
    color:#757575 !important;
  }

  .check-mark {
    display: inline-flex;
    position: absolute;
    right: 0;
    max-width: 20px;
    max-height: 20px;
    justify-content: center;
    align-items: center;
    color: #E5E5E5; 
  }
</style>