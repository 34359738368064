<template lang="pug">
ToolButton( 
  :tooltip="$t('viewer.tools.highlightFrame')" 
  :active="store.isSelectionFrameActive" 
  :disabled="store.isToolDisabled" 
  @click="store.toggleSelectionFrameActivation" 
) $viewer-element-selector-icon
</template>

<script>
import { useSelectionStore } from '@/stores/viewerTools/selection.store'
import ToolButton from './ToolButton.vue'

export default {
  components: {
    ToolButton,
  },

  computed: {
    store: () => useSelectionStore(),
  },
}
</script>