<template lang="pug">
  app-section.ma-1.pa-0
    v-btn.right(icon text depressed x-small @click="swapFilter")
      v-icon(color="var(--v-primary-darken2)") mdi-filter
      span.attachment {{$t("section.equipment.filter.equipmentAttachments")}}
    
    input(id="fileUploadEquipment" @change="onFileSelected" type="file" hidden)
    v-btn.right( small icon color="accent" @click="chooseFiles(equipment)" )
      v-icon mdi-plus
      span.attachment {{$t("module.task.addAttachment")}}

    expansion-button.short-text.mb-1(:buttonHeader='$t("section.equipment.sharedAttachments")')
      v-row.mt-1(v-show="equipment.attachments.length" v-for="(attachment, index) in equipment.attachments" :key="index" cols="auto" no-gutters)
        v-col.pa-0(cols=10)
          app-text.attachment.pt-1 {{attachment.title}}
        v-col.pa-0(cols=1)
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn.mb-2( v-bind='attrs' v-on='on' x-small icon @click="downloadEquipmentFile(attachment.uuid)")
                v-icon file_download
            span {{$t("section.journal.downloadFile")}}
        v-col.pa-0(cols=1)
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn.mb-2( v-bind='attrs' v-on='on' x-small icon @click="confirmDeleteAttach(attachment)")
                v-icon delete
            span {{$t("module.task.delAttachment")}}

    v-divider.mb-2

    expansion-button.short-text(:buttonHeader='$t("patches.elements")')
      .d-block(v-for="element in filterElements" :key="element.globalId" @click="(selectElement(element))" no-gutters)
        v-row.my-1
          v-col.pa-0(cols=1)
            expansion-button.toggle-expansion( :class="{ 'not-attach' : !element.file.length }" @click.native="openElements(element)")
          v-col.pa-0(cols=10)
            app-text.attachment.short-text.pt-1(:class="{ 'not-attach' : !element.file.length }" @click.native="openElements(element)") {{ element.name }}
          v-col.pa-0(cols=1)
            input(id="fileUploadElement" @change="onFileSelected" type="file" hidden)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn.mr-1.mb-1( v-bind='attrs' v-on='on' x-small icon color="accent" @click="chooseFiles(element)" )
                  v-icon mdi-plus
              span {{$t("section.journal.addFile")}}

        v-row.my-1(v-show="element.file.length && opened.includes(element)" v-for="(file, index) in element.file" :key="file.uuid" :class="{'list_item' : index % 2 == 0}" no-gutters)
          v-col.pa-0(cols=10)
            app-text.attachment.mt-1.pl-4 {{file.name}}
          v-col.pa-0(cols=1)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn.pb-1( v-bind='attrs' v-on='on' x-small icon @click="downloadElementFile(file.uuid)")
                  v-icon file_download
              span {{$t("section.journal.downloadFile")}}
          v-col.pa-0(cols=1)
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn.pb-2( v-bind='attrs' v-on='on' x-small icon @click="confirmDeleteFile(file)")
                  v-icon delete
              span {{$t("module.task.delAttachment")}}
      
        v-divider
        
    app-dialog-confirm(v-model="dialog.deleteAttach" ref="delElementFile" @confirm="deleteEquipAttach")
      app-text {{ $t('section.structure.model.deleteFileConfirm') }} "{{ deleteFile.title }}"?

    app-dialog-confirm(v-model="dialog.delElementFile" ref="delElementFile" @confirm="deleteElemAttach")
      app-text {{ $t('section.structure.model.deleteFileConfirm') }} "{{ deleteFile.name }}"?
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { api } from "@/api"
import { SourcePath } from '@app/SourcePath'

import ExpansionButton from '@/components/project/panel/left/components/ExpansionButton'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  props: {
    equipment: null,
  },
  components: {
    ExpansionButton,
  },
  data () {
    return {
      elements: [],
      selectedItem: null,
      deleteFile: '',
      dialog: {
        deleteAttach: false,
        delElementFile: false,
      },
      filteredElements: false,
      opened: [],
    }
  },

  watch: {
    equipment: {
      handler(newVal) {
        if(newVal?.elements) {
          this.loadElements();
        }
      },
      immediate: true,
      deep: true,
    }
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),

    filterElements() {
      return this.filteredElements ? this.elements.filter(elem => elem.file.length) : this.elements
    },

  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalIdWithoutConditions']),

    openElements(element) {
      this.opened.includes(element)? this.opened = this.opened.filter(el => el != element): this.opened.push(element)
    },
    
    swapFilter() {
      this.filteredElements = !this.filteredElements
    },

    loadElements() {
      api.projects.elementFlatWithFiles(this.equipment.elements, this.projectUuid).then(data => {
        this.elements = data
      })
    },

    selectElement(element) {
      this.fetchElementByGlobalIdWithoutConditions(element.globalId)
      let aabb = XeokitMediator.viewer.scene.getAABB(element.globalId)
      XeokitMediator.CameraFlight.flyTo(aabb)
      XeokitMediator.viewer.scene.setObjectsPickable(element.globalId, true)
      XeokitMediator.viewer.scene.setObjectsVisible(element.globalId, true)
      XeokitMediator.viewer.scene.setObjectsHighlighted(element.globalId, true)
    },

    chooseFiles(item) {
      this.selectedItem = item
      if(this.selectedItem.attachments) {
        document.getElementById("fileUploadEquipment").click();
      } else {
        document.getElementById("fileUploadElement").click();
      }
    },

    async onFileSelected(event) {
      let files = event.target.files || event.dataTransfer.files
      if(this.selectedItem.attachments) {
        await api.maintenance.saveEquipmentAttachment(this.selectedItem.uuid, files[0]).then(() => {
          api.maintenance.getEquipmentAttachment(this.selectedItem.uuid).then(data => this.equipment.attachments = data)
        })
      } else {
        api.projects
          .uploadElementFile(this.projectUuid, this.selectedItem.globalId, files[0])
          .then(() => this.fetchElementByGlobalIdWithoutConditions(this.selectedItem.globalId))
          .catch(() => {
            this.stateFileDownload = false
          })
        await api.projects.elementFlatWithFiles(this.equipment.elements, this.projectUuid).then(data => {
          this.elements = data
        })
      }
    },

    downloadElementFile (uuid) {
      window.open(SourcePath.elementFile(uuid), '_blank')
    },

    downloadEquipmentFile (uuid) {
      window.open(SourcePath.equipmentAttachment(uuid))
    },

    confirmDeleteFile(item) {
      this.deleteFile = item
      this.dialog.delElementFile = true
    },

    confirmDeleteAttach(item) {
      this.deleteFile = item
      this.dialog.deleteAttach = true
    },

    async deleteElemAttach() {
      await api.projects.deleteElementFile(this.deleteFile.uuid).then(() => this.fetchElementByGlobalIdWithoutConditions(this.deleteFile.elementGlobalId))
      this.dialog.delElementFile = false
      await api.projects.elementFlatWithFiles(this.equipment.elements, this.projectUuid).then(data => {
        this.elements = data
      })
    },

    async deleteEquipAttach() {
      this.equipment.attachments = await this.equipment.attachments.filter(el => el.uuid != this.deleteFile.uuid)
      await api.maintenance.deleteEquipmentAttachment(this.deleteFile.uuid).then(() => {
        this.dialog.deleteAttach = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .right {
    display: flex;
    justify-content: flex-start;
  }
  .reverse {
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse !important;
  }
  ::v-deep .short-text .v-btn__content { 
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
   }
   
  .short-text {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .not-attach {
    opacity: 0.5;
  }
  .attachment {
    margin-left: 6px;
    font: normal 12px/14px $roboto;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ::v-deep .toggle-expansion {
    margin-left: 4px !important;
    padding-left: 0px !important;
    width: 24px !important;
    padding-right: 0px !important;
  }
  .list-attach {
    padding: 0;
  }
  ::v-deep button.expansion-button.mt-2.v-btn {
    padding-left: 2px !important;
    padding-right: 0px !important;
    min-width: 24px !important;
    margin-top: 2px !important;
    color: var(--v-primary-base) !important;
    caret-color: var(--v-primary-base) !important;
  }
  ::v-deep .container-position {
    padding: 0px !important;
  }
  ::v-deep .my-1.col {
    padding: 0px !important;
  }
  .list_item {
    background: #e5e5e5 !important;
  }
</style>