export default {
  title: {
    panelList: titlePanelsList(),
    objectTypes: titleObjectTypes(),
    dialog: titleLabelForDialog(),
  },
  section1: {
    headers: section1Headers(),
    label: section1Label(),
  },
  section2: {
    headers: section2Headers(),
    label: section2Label(),
  },
  section3: {
    headers: section3Headers(),
    label: section3Label(),
  },
  section4: {
    headers: section4Headers(),
    label: section4Label(),
  },
  section5: {
    headers: section5Headers(),
    label: section5Label(),
  },
  section6: {
    headers: section6Headers(),
    label: section6Label(),
  },

  archiveList: headersForArchiveList(),
  workPerson: workPersonDialog(),
}

function section1Headers() {
  return [
    ...s1Headers(),
    { text: 'Действия', value: 'actions', sortable: false, width: 70 }
  ]
}
function section1Label() {
  return {
    personName: { label: 'Наименование лица, осуществляющего строительство', hint: '' },
    fullName: { label: 'Лицо, входящее в инженерно-технический персонал', hint: 'Фамилия, инициалы, должность лица, входящего в список инженерно-технического персонала' },
    startDate: { label: 'Дата начала работ на объекте капитального строительства', hint: '' },
    worksType: { label: 'Тип выполняемых работ', hint: '' },
    endDate: { label: 'Дата окончания работ на объекте капитального строительства', hint: '' },
    sign: { label: 'Подпись', hint: 'Должность, фамилия, инициалы, подпись уполномоченного представителя лица, осуществляющего строительство' },
  }
}

function section2Headers() {
  return [
    ...s2Headers(),
    { text: 'Действия', value: 'actions', sortable: false, width: 70 }
  ]
}
function section2Label() {
  return {
    specialJournalName: { label: 'Наименование специального журнала (журнала авторского надзора)', hint: '' },
    specialJournalDate: { label: 'Дата выдачи специального журнала', hint: '' },
    personName: { label: 'Лицо, ведущее журнал', hint: 'Наименование лица, осуществляющего строительство (лица, осуществляющего подготовку проектной документации), ведущих журнал, их уполномоченных представителей с указанием должности, фамилии, инициалов' },
    date: { label: 'Дата передачи застройщику или техническому заказчику журнала', hint: '' },
    sign: { label: 'Подпись', hint: 'Подпись уполномоченного представителя застройщика или технического заказчика, лица, ответственного за эксплуатацию, и (или) регионального оператора' },
  }
}

function section3Headers() {
  return [
    ...s3Headers(),
    { text: 'Действия', value: 'actions', sortable: false, width: 70 }
  ]
}
function section3Label() {
  return {
    date: { label: 'Дата выполнения работ', hint: '' },
    workName: { label: 'Наименование работ', hint: '' },
    workConditions: { label: 'Условия производства работ', hint: '' },
    workScope: { label: 'Объем', hint: '' },
    workPlace: { label: 'Место', hint: '' },
    workMethod: { label: 'Методика', hint: '' },
    sign: { label: 'Подпись', hint: 'Должность, фамилия, инициалы, подпись уполномоченного представителя лица, осуществляющего строительство' },
  }
}

function section4Headers() {
  return [
    ...s4Headers(),
    { text: 'Действия', value: 'actions', sortable: false, width: 70 }
  ]
}
function section4Label() {
  return {
    infoConstructionControl: { label: 'Сведения о строительном контроле', hint: 'Сведения о проведении строительного контроля при строительстве, реконструкции, капитальному ремонту объекта капитального строительства' },
    disadvantages: { label: 'Выявленные недостатки', hint: '' },
    termEliminationViolations: { label: 'Срок устранения выявленных недостатков', hint: '' },
    signTerm: { label: 'Подпись', hint: 'Должность, фамилия, инициалы, подпись уполномоченного представителя застройщика или технического заказчика, лица, ответственного за эксплуатацию, и (или) регионального оператора по вопросам строительного контроля' },
    dateEliminationViolations: { label: 'Дата устранения недостатков', hint: '' },
    signDate: { label: 'Подпись', hint: 'Должность, фамилия, инициалы, подпись уполномоченного представителя застройщика или технического заказчика, лица, ответственного за эксплуатацию, и (или) регионального оператора по вопросам строительного контроля' },
  }
}

function section5Headers() {
  return [
    ...s5Headers(),
    { text: 'Действия', value: 'actions', sortable: false, width: 70 }
  ]
}
function section5Label() {
  return {
    executiveDocName: { label: 'Наименование исполнительной документации', hint: 'Наименование исполнительной документации (с указанием наименования и реквизитов документа, вида работ, места расположения конструкций, участков сетей инженерно-технического обеспечения)' },
    infoAboutSign: { label: 'Дата подписания документа, должности, фамилии, инициалы лиц, подписавших документы', hint: '' },
  }
}

function section6Headers() {
  return [
    ...s6Headers(),
    { text: 'Действия', value: 'actions', sortable: false, width: 70 }
  ]
}
function section6Label() {
  return {
    dateControlEvent: { label: 'Дата контрольного (надзорного) мероприятия', hint: '' },
    controlEventsInfo: { label: 'Данные о проведенных контрольных (надзорных) мероприятиях', hint: 'Данные о проведенных органом государственного строительного надзора контрольных (надзорных) мероприятиях' },
    termEliminationViolations: { label: 'Срок устранения выявленных нарушений', hint: '' },
    signTerm: { label: 'Должность, фамилия, инициалы, подпись должностного лица', hint: '' },
    dateEliminationViolations: { label: 'Фактическая дата устранения выявленных нарушений', hint: '' },
    signDate: { label: 'Должность, фамилия, инициалы, подпись должностного лица', hint: '' },
  }
}

/** Текст и параметры для Work Journal Title
 * @param {Boolean} icon Выводить ли иконку в expansion panel
 * @param {String} type Тип панели - один или несколько инпутов, или таблица
 * @param {String} itemName Название поля в объекте items
 * @param {Array} headers Массив с названиями заголовков для таблиц
 * @param {String} title Заголовок для панели
 * @param {String} input Label и Hint для input и dataPicker 
 * @param {String} parent ItemName лица/компании для workPerson
 */
function titlePanelsList() {
  return [
    {
      icon: true, type: 'input', itemName: 'journalNumber', headers: null, title: "Номер журнала",
      input: {
        label: "Номер журнала",
        hint: ""
      }
    },

    {
      icon: true, type: 'inputWithAutocomplete', headers: null, title: "Объект капитального строительства",
      itemName: {
        first: 'constructionObjectType',
        second: 'constructionObjectName'
      },
      input: {
        first: {
          label: "Укажите тип объекта",
          hint: "Указать строительство, реконструкция, капитальный ремонт"
        },
        second: {
          label: "Укажите наименование объекта",
          hint: "Наименование объекта капитального строительства, его почтовый или строительный адрес",
        }
      }
    },

    {
      icon: true, type: 'input', itemName: 'developer', headers: null, title: "Застройщик",
      input: {
        label: "Укажите информацию о лице",
        hint: "Фамилия, имя, отчество (при наличии), адрес места жительства, ОГРНИП, ИНН индивидуального предпринимателя, наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс, адрес электронной почты, наименование, ОГРН, ИНН саморегулируемой организации, членом которой является, для индивидуальных предпринимателей и юридических лиц; фамилия, имя, отчество (при наличии), паспортные данные, адрес места жительства, телефон/факс, адрес электронной почты – для физических лиц, не являющихся индивидуальными предпринимателями"
      }
    },
    { icon: true, parent:'developer', type: 'table', itemName: 'developerTeam', headers: developerTeam(), input: null, title: "Уполномоченный представитель застройщика" },

    {
      icon: true, type: 'input', itemName: 'techCustomer', headers: null, title: "Технический заказчик",
      input: {
        label: "Укажите информацию о лице",
        hint: "Наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс, адрес электронной почты, наименование, ОГРН, ИНН саморегулируемой организации, членом которой является"
      }
    },
    { icon: true, parent:'techCustomer', type: 'table', itemName: 'techCustomerTeam', headers: techCustomerTeam(), input: null, title: "Уполномоченный представитель технического заказчика" },

    {
      icon: true, type: 'input', itemName: 'operator', headers: null, title: "Лицо, ответственное за эксплуатацию, и (или) региональный оператор",
      input: {
        label: "Укажите информацию о лице",
        hint: "Фамилия, имя, отчество (при наличии), адрес места жительства, ОГРНИП, ИНН индивидуального предпринимателя, наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс, адрес электронной почты, фамилия, имя, отчество (при наличии), паспортные данные, адрес места жительства, телефон/факс, адрес электронной почты - для физических лиц, не являющихся индивидуальными предпринимателями"
      }
    },
    { icon: true, parent:'operator', type: 'table', itemName: 'operatorTeam', headers: operatorTeam(), input: null, title: "Уполномоченный представитель лица, ответственного за эксплуатацию, и (или) регионального оператора" },

    {
      icon: true, type: 'input', itemName: 'constructionPermit', headers: null, title: "Сведения о выданном разрешении на строительство",
      input: {
        label: "Укажите информацию о выданном разрешении",
        hint: "Номер, дата выдачи разрешения, наименование органа исполнительной власти, государственной корпорации или органа местного самоуправления, выдавших разрешение"
      }
    },

    {
      icon: true, type: 'input', itemName: 'documentPerson', headers: null, title: "Лицо, осуществляющее подготовку проектной документации",
      input: {
        label: "Укажите информацию о лице",
        hint: "Фамилия, имя, отчество (при наличии), адрес места жительства, адрес электронной почты, ОГРНИП, ИНН индивидуального предпринимателя, наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс, адрес электронной почты, наименование, ОГРН, ИНН саморегулируемой организации, членом которой является"
      }
    },
    { icon: true, type: 'table', itemName: 'documentPersonTeam', headers: documentPersonTeam(), input: null, title: "Уполномоченный представитель лица, осуществляющего подготовку проектной документации, по вопросам проверки соответствия выполняемых работ проектной документации" },

    {
      icon: true, type: 'input', itemName: 'infoPositiveConclusion', headers: null, title: "Сведения о положительном заключении экспертизы проектной документации",
      input: {
        label: "Укажите сведения о заключении",
        hint: "Номер и дата выдачи, орган или организация, его утвердившие"
      }
    },

    {
      icon: true, type: 'input', itemName: 'builder', headers: null, title: "Лицо, осуществляющее строительство",
      input: {
        label: "Укажите информацию о лице",
        hint: "Фамилия, имя, отчество (при наличии), адрес места жительства, ОГРНИП, ИНН индивидуального предпринимателя, наименование, ОГРН, ИНН, место нахождения юридического лица, телефон/факс, адрес электронной почты, наименование, ОГРН, ИНН саморегулируемой организации, членом которой является"
      }
    },
    { icon: true, parent:'builder', type: 'table', itemName: 'builderTeam', headers: builderTeam(), input: null, title: "Уполномоченный представитель лица, осуществляющего строительство" },
    { icon: true, parent:'developer', type: 'table', itemName: 'developerControlTeam', headers: developerControlTeam(), input: null, title: "Уполномоченный представитель застройщика или технического заказчика по вопросам строительного контроля" },
    { icon: true, parent:'operator', type: 'table', itemName: 'operatorControlTeam', headers: operatorControlTeam(), input: null, title: "Уполномоченный представитель лица, ответственного за эксплуатацию, и (или) регионального оператора по вопросам строительного контроля" },
    { icon: true, parent:'builder', type: 'table', itemName: 'builderControlTeam', headers: builderControlTeam(), input: null, title: "Уполномоченный представитель лица, осуществляющего строительство, по вопросам строительного контроля" },
    { icon: true, type: 'table', itemName: 'otherPerson', headers: otherPerson(), input: null, title: "Другие лица, осуществляющие строительство, их уполномоченные представители" },

    {
      icon: true, type: 'input', itemName: 'stateControlInfo', headers: null, title: "Сведения о государственном строительном надзоре",
      input: {
        label: "Укажите сведения о государственном строительном надзоре",
        hint: "Наименование органа государственного строительного надзора, почтовые реквизиты, телефон/факс, адрес электронной почты,фамилия, имя, отчество (при наличии), должность должностного лица (должностных лиц), номер, дата приказа (распоряжения)"
      }
    },
    {
      icon: true, type: 'input', itemName: 'constructionInfo', headers: null, title: "Общие сведения об объекте капитального строительства",
      input: {
        label: "Укажите общие сведения",
        hint: "Наименование объекта капитального строительства, краткие проектные характеристики объекта капитального строительства"
      }
    },

    {
      icon: true, type: 'datePicker', headers: null, title: "Даты начала и окочания работ на объекте капитального строительства",
      itemName: {
        first: "startConstructionDate",
        second: "endConstructionDate",
      },
      input: {
        first: {
          label: "Начало строительства, реконструкции, капитального ремонта объекта капитального строительства",
          hint: ""
        },
        second: {
          label: "Окончание строительства, реконструкции, капитального ремонта объекта капитального строительства",
          hint: ""
        },
      }
    },
    {
      icon: true, type: 'datePicker', headers: null, title: "Период учета работ (указывается в случае ведения нескольких журналов)",
      itemName: {
        first: "startPeriod",
        second: "endPeriod",
      },
      input: {
        first: {
          label: "Начало работ",
          hint: ""
        },
        second: {
          label: "Окончание работ",
          hint: ""
        },
      }
    },

    { icon: true, type: 'table', itemName: 'changes', headers: changes(), input: null, title: "Сведения об изменениях в записях Титульного листа общего журнала, в котором ведется учет выполнения работ по строительству, реконструкции, капитальному ремонту объекта капитального строительства" },
  ]
}
function titleObjectTypes() {
  return [
    { title: "Строительство", value: 0 },
    { title: "Реконструкция", value: 1 },
    { title: "Капитальный ремонт", value: 2 }
  ]
}
function titleLabelForDialog() {
  return {
    personName: { other: 'Фамилия, имя, отчество (при наличии), адрес места жительства, ОГРНИП, ИНН – для индивидуального предпринимателя; наименование, ОГРН, ИНН, место нахождения – для юридических лиц; фамилия, имя, отчество (при наличии) паспортные данные, адрес места жительства - для физических лиц, не являющихся индивидуальными предпринимателями', document: 'Наименование лица, осуществляющего подготовку проектной документации, сведения о разделах проектной документации, подготовленных этим лицом', label: 'Наименование лица' },
    fullName: { hint: 'Фамилия, имя, отчество (при наличии)', label: 'ФИО представителя' },
    position: { hint: 'Должность', label: 'Должность' },
    worksType: { hint: 'Выполняемые работы по строительству, реконструкции, капитальному ремонту объекта капитального строительства', label: 'Выполняемые работы' },
    document: { hint: 'Наименование, дата, номер документа, подтверждающего полномочие', label: 'Документ, подтверждающий полномочия' },
    identiNumber: { hint: 'Идентификационный номер в национальном реестре специалистов в области строительства', document: 'Идентификационный номер в национальном реестре специалистов в области архитектурно-строительного проектирования', label: 'Идентификационный номер' },
    sign: { hint: 'Подпись', other: 'Подпись уполномоченного представителя лица, осуществляющего строительство', label: 'Подпись' },
    changes: { change: "Изменения в записях с указанием основания", sign: "Подпись" }
  }
}
function headersForArchiveList() {
  let s1 = s1Headers();
  let s2 = s2Headers();
  let s3 = s3Headers();
  let s4 = s4Headers();
  let s5 = s5Headers();
  let s6 = s6Headers();
  return [
    { icon: false, type: 'table', itemName: 's1', headers: [{ text: 'Статус', value: 'status.title', sortable: false, width: 70 }, ...s1], title: "Раздел 1", input: null },
    { icon: false, type: 'table', itemName: 's2', headers: [{ text: 'Статус', value: 'status.title', sortable: false, width: 70 }, ...s2], title: "Раздел 2", input: null },
    { icon: false, type: 'table', itemName: 's3', headers: [{ text: 'Статус', value: 'status.title', sortable: false, width: 70 }, ...s3], title: "Раздел 3", input: null },
    { icon: false, type: 'table', itemName: 's4', headers: [{ text: 'Статус', value: 'status.title', sortable: false, width: 70 }, ...s4], title: "Раздел 4", input: null },
    { icon: false, type: 'table', itemName: 's5', headers: [{ text: 'Статус', value: 'status.title', sortable: false, width: 70 }, ...s5], title: "Раздел 5", input: null },
    { icon: false, type: 'table', itemName: 's6', headers: [{ text: 'Статус', value: 'status.title', sortable: false, width: 70 }, ...s6], title: "Раздел 6", input: null },
  ]
}
function s1Headers() {
  return [
    { text: '№ п/п', value: 'number', sortable: false, width: 70 },
    { text: 'Наименование лица, осуществляющего строительство', value: 'personName', sortable: false },
    { text: 'Фамилия, инициалы, должность лица, входящего в список инженерно-технического персонала', value: 'fullName', sortable: false },
    { text: 'Дата начала работ на объекте капитального строительства', value: 'startDate', sortable: false },
    { text: 'Тип выполняемых работ', value: 'worksType', sortable: false },
    { text: 'Дата окончания работ на объекте капитального строительства', value: 'endDate', sortable: false },
    { text: 'Должность, фамилия, инициалы, подпись уполномоченного представителя лица, осуществляющего строительство', value: 'sign', sortable: false }
  ]
}
function s2Headers() {
  return [
    { text: '№ п/п', value: 'number', sortable: false, width: 70 },
    { text: 'Наименование специального журнала (журнала авторского надзора)', value: 'specialJournalName', sortable: false },
    { text: 'Дата выдачи специального журнала', value: 'specialJournalDate', sortable: false },
    { text: 'Наименование лица, осуществляющего строительство (лица, осуществляющего подготовку проектной документации), ведущих журнал, их уполномоченных представителей с указанием должности, фамилии, инициалов', value: 'personName', sortable: false },
    { text: 'Дата передачи застройщику или техническому заказчику журнала', value: 'date', sortable: false },
    { text: 'Подпись', value: 'sign', sortable: false },
  ]
}
function s3Headers() {
  return [
    { text: '№ п/п', value: 'number', sortable: false, width: 70 },
    { text: 'Дата выполнения работ', value: 'date', sortable: false },
    { text: 'Наименование работ', value: 'workName', sortable: false },
    { text: 'Условия производства работ', value: 'workConditions', sortable: false },
    { text: 'Объем', value: 'workScope', sortable: false },
    { text: 'Место', value: 'workPlace', sortable: false },
    { text: 'Методика', value: 'workMethod', sortable: false, },
    { text: 'Должность, фамилия, инициалы, подпись уполномоченного представителя лица, осуществляющего строительство', value: 'sign', sortable: false },
  ]
}
function s4Headers() {
  return [
    { text: '№ п/п', value: 'number', sortable: false, width: 70 },
    { text: 'Сведения о проведении строительного контроля при строительстве, реконструкции, капитальному ремонту объекта капитального строительства', value: 'infoConstructionControl', sortable: false },
    { text: 'Выявленные недостатки', value: 'disadvantages', sortable: false },
    { text: 'Срок устранения выявленных недостатков', value: 'termEliminationViolations', sortable: false },
    { text: 'Должность, фамилия, инициалы, подпись', value: 'signTerm', sortable: false },
    { text: 'Дата устранения недостатков', value: 'dateEliminationViolations', sortable: false },
    { text: 'Должность, фамилия, инициалы, подпись', value: 'signDate', sortable: false },
  ]
}
function s5Headers() {
  return [
    { text: '№ п/п', value: 'number', sortable: false, width: 70 },
    { text: 'Наименование исполнительной документации', value: 'executiveDocName', sortable: false },
    { text: 'Дата подписания документа, должности, фамилии, инициалы лиц, подписавших документы', value: 'infoAboutSign', sortable: false },
  ]
}
function s6Headers() {
  return [
    { text: '№ п/п', value: 'number', sortable: false, width: 70 },
    { text: 'Дата контрольного (надзорного) мероприятия', value: 'dateControlEvent', sortable: false },
    { text: 'Данные о проведенных контрольных (надзорных) мероприятиях', value: 'controlEventsInfo', sortable: false },
    { text: 'Срок устранения выявленных нарушений', value: 'termEliminationViolations', sortable: false },
    { text: 'Должность, фамилия, инициалы, подпись должностного лица', value: 'signTerm', sortable: false },
    { text: 'Фактическая дата устранения выявленных нарушений', value: 'dateEliminationViolations', sortable: false },
    { text: 'Должность, фамилия, инициалы, подпись должностного лица', value: 'signDate', sortable: false },
  ]
}

function developerTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'identiNumber', sortable: false, text: 'Идентификационный номер в национальном реестре специалистов в области строительства'},
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function techCustomerTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'identiNumber', sortable: false, text: 'Идентификационный номер в национальном реестре специалистов в области строительства' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function operatorTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function documentPersonTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'personName', sortable: false, text: 'Наименование лица, осуществляющего подготовку проектной документации, сведения о разделах проектной документации, подготовленных этим лицом' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность'},
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'identiNumber', sortable: false, text: 'Идентификационный номер в национальном реестре специалистов в области архитектурно-строительного проектирования' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function builderTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'identiNumber', sortable: false, text: 'Идентификационный номер в национальном реестре специалистов в области строительства' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function developerControlTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function operatorControlTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function builderControlTeam() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии)' },
    { value: 'position', sortable: false, text: 'Должность' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие' },
    { value: 'sign', sortable: false, text: 'Подпись' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function otherPerson() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'personName', sortable: false, text: 'Фамилия, имя, отчество (при наличии), адрес места жительства, ОГРНИП, ИНН – для индивидуального предпринимателя; наименование, ОГРН, ИНН, место нахождения – для юридических лиц; фамилия, имя, отчество (при наличии) паспортные данные, адрес места жительства - для физических лиц, не являющихся индивидуальными предпринимателями' },
    { value: 'fullName', sortable: false, text: 'Фамилия, имя, отчество (при наличии) уполномоченного представителя лица, осуществляющего строительство' },
    { value: 'position', sortable: false, text: 'Должность уполномоченного представителя лица' },
    { value: 'document', sortable: false, text: 'Наименование, дата, номер документа, подтверждающего полномочие уполномоченного представителя лица' },
    { value: 'worksType', sortable: false, text: 'Выполняемые работы по строительству, реконструкции, капитальному ремонту объекта капитального строительства' },
    { value: 'sign', sortable: false, text: 'Подпись уполномоченного представителя лица, осуществляющего строительство' },
    { value: 'actions', text: "Действия", sortable: false, width: 70 }
  ]
}
function changes() {
  return [
    { value: 'number', width: 70, text: '№ п/п' },
    { value: 'date', sortable: false, text: 'Дата'},
    { value: 'changes', sortable: false, text: 'Изменения в записях c указанием основания' },
    { value: 'person', sortable: false, text: 'Фамилия, инициалы, должность лица, внесшего изменения, наименование, дата, номер документа, подтверждающего полномочие лица' },
    { value: 'sign', sortable: false, text: 'Подпись' },
  ]
}

function workPersonDialog() {
  return {
    personName: { individual: "Наименование компании или лица", juridical: "Наименование лица", hint: "Фамилия, имя, отчество (при наличии), адрес места жительства, ОГРНИП, ИНН – для индивидуального предпринимателя; наименование, ОГРН, ИНН, место нахождения – для юридических лиц" },
    fullName: { individual: "ФИО", juridical: "ФИО представителя", hint: "Фамилия, имя, отчество (при наличии)" },
    position: { individual: "Должность", juridical: "Должность представителя" },
    document: { label: "Документ, подтверждающий полномочия", hint: "Наименование, дата, номер документа, подтверждающего полномочие" },
    worksType: { label: "Выполняемые работы", hint: "Выполняемые работы по строительству, реконструкции, капитальному ремонту объекта капитального строительства" },
    identiNumber: { label: "Идентификационный номер", hint: "Идентификационный номер в национальном реестре специалистов в области строительства или в области архитектурно-строительного проектирования" },
  }
}