<template lang="pug">
.layout( v-if="!state.isCroppingModeActive" id="drawingCommentsLayout"
          :style='state.panelCommentModeStyle' 
          @click='state.commentMode ? state.showContextMenu($event) : null'
          @contextmenu='state.showContextMenu($event)' )

  div( v-if='state.showComments' 
        v-for="comment in state.allComments" 
        :style="state.commentPosition(comment)" )

    drawing-comment( :comment='comment' 
                      :context='state.imageSizes' 
                      :key='comment.uuid' )

  div( v-if='state.showContextMenuStatus' :style='state.contextMenuPosition' @contextmenu.stop='state.hideContextMenu($event)' )
    button.context-menu-button( @click.stop='state.showCommentCreateDialog()' ) {{ $t("module.drawing.addComment.text") }}
</template>

<script>
import { useDrawingsStore } from '@/pinia'
import { useDrawingCommentState } from '@/pinia'
import DrawingComment from './DrawingComment.vue'

export default {
  components: {
    DrawingComment
  },

  computed: {
    state: () => useDrawingCommentState(),
    store: () => useDrawingsStore()
  }
}
</script>

<style scoped>
.layout {
  position: absolute;
  top: 0%; 
  left: 0%;
  width: 100%; 
  height: 100%;
}

.context-menu-button{
  background: white;
  color: black;
  border: 1px solid #EB5757;
  border-radius: 20px 20px 20px 0px;

  line-height: 34px;
  font-size: 12px;

  padding: 0px 4px;  
  z-index: 1;
  width: 160px;
  
  display: flex;
  justify-content: center;
  position: relative;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
}

.context-menu-button::before {
  content: "";
  
  width: 8px;
  height: 8px;
  
  background-image: linear-gradient(to top right, #EB5757 50%, transparent 0);
  
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.context-menu-button:hover {
  background: black;
  color: white;
}
</style>