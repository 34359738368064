import i18n from '@/plugins/i18n';

export const $_parse = {
  methods: {
    $parse_to_back(s) { return parse_to_back(s) },
  }
}

let pos = 0;
let tokens = [];
let result = {};

function status_fail(msg) { 
    result.status="FAIL"
    result.err=msg
}

function check(ex) { 
    let open = 0
    for (let i = 0; i < ex.length; ++i) {
      if(ex[i]=="(") open++
      else if(ex[i]==")") open--
      if(open < 0){
         ex.unshift("(")
         return check(ex)
      }
    }

    return ex
}

function to_token(conditionRows) { 
    let ex = []
    conditionRows.forEach((a, index) => {
      if(a.start!=null && a.start!='') a.start.split("").forEach(b => ex.push(b)) 
      ex.push(a.attr)
      if(a.end!=null && a.end!='') a.end.split("").forEach(b => ex.push(b)) 
      if(index<conditionRows.length-1 && a.logic!=null && a.logic.value>-1) ex.push(a.logic)
    })

    ex = check(ex)
    return ex
}

function parse_to_back(s) { 
    pos = 0
    tokens = to_token(s)
    result.status = "OK"
    result.expr = expression()
    if(result.expr.logicOperatorType === undefined)
       result.expr = {condition:[result.expr]}
    if(pos < tokens.length) status_fail(i18n.t('section.elementTree.captionParse'))
    return result
}


function expression() {
    return f("exp")
}

function f(n) {
    let oType = (n=="exp")? "OR" : "AND"
    let logicGroup = {"logicOperatorType":{ "value": oType}, condition:[], children:[], sortOrder: pos}
    let first = (n=="exp")? f("term") : factor()
    first.sortOrder = pos

    if(first.logicOperatorType !== undefined) 
        logicGroup.children.push(first)
    else
        logicGroup.condition.push(first) 

    while (pos < tokens.length) {
        let operator = tokens[pos]
        if (operator.name!=oType) {
            break
        } else {
            pos++
        }

        let second = (n=="exp")? f("term") : factor()

        if(second !== undefined){
            second.sortOrder = pos
            if(second.logicOperatorType !== undefined) 
                logicGroup.children.push(second)
            else
                logicGroup.condition.push(second) 
        }

    }

    if((logicGroup.condition.length + logicGroup.children.length) > 1){
       return logicGroup
    } else {
       return first
    }
}


function factor() {
    let next = tokens[pos]
    let result
    if (next=="(") {
        pos++
        result = expression()  
        let closingBracket
        if (pos < tokens.length) {
            closingBracket = tokens[pos]
        } else {
            tokens.push(")")                             // Add auto )
            closingBracket = ")"
            //status_fail("Неожиданный конец выражения")
        }
        if (pos < tokens.length && closingBracket==")") {
            pos++
            return result
        }
        status_fail(i18n.t('section.elementTree.captionParse'))
    }
    pos++
    return next
}
