<template lang="pug">
div
  app-dialog(v-model="columnsDialog" :header="$t('section.groupingTable.addColumn')")
    app-section
      v-list.column__list.base-scroll-body(light)
        v-list-item(v-for="column in settingColums" :key="column.value" @click="selectColumn(column)")
          .column__list__item-text {{ $t(column.name) }}

  app-dialog(v-model="selectAttributeDialog" :header="dialogHeader" @close="show" width="400")
    div(style='min-height:400px')
      v-tabs.mb-2(v-if="selectedAttr.value.includes('attribute')" v-model='getActiveAttributeTab' light)
        v-tab(href='#BASEATTR') {{ $t('section.elementTable.column.BASEATTR') }}
        v-tab(href='#USERPROP') {{ $t('section.elementTable.column.USERPROP') }}
        v-tab(href='#USERMANUALPROP') {{ $t('section.elementTable.column.USERMANUALPROP') }}
        //- v-tab(href='#MANUALINPUT') Вручную
        v-tab(href='#ATTRIBUTE' style='display: none;') {{ $t("section.elementTree.condition.ATTRIBUTE") }}
      
      //- template(v-if='getActiveAttributeTab === "MANUALINPUT"')
        app-text-field(v-model='MANUALINPUT_prefix' label='Имя набора' @input='form.manualOperand = MANUALINPUT_prefix + "/" + MANUALINPUT_postfix, currentOperandTitle = form.manualOperand')
        app-text-field(v-model='MANUALINPUT_postfix' label='Имя атрибута' @input='form.manualOperand = MANUALINPUT_prefix + "/" + MANUALINPUT_postfix, currentOperandTitle = form.manualOperand')
      v-virtual-scroll.base-scroll-body(v-if="!attrIsLoading" :items='conditionTreeCache.tree.items || []' item-height='24' height='400')
        template(v-slot:default='{ item }')
          //- div.tree-item.d-flex.pa-1(@click='')
          .tree-item.d-flex.pa-1(@click='selectAttribute(item)')
            .ml-2(v-for='level in (item.level || 0)')
            v-icon.item__toggle(v-if='item.children && item.children.length' :class='{ "item__toggle--close" : !conditionTreeCache.isOpened(item) }' color='primary lighten-2') arrow_drop_down
            .px-2(v-else)
            //- span.ml-1.text-truncate(:style="( form.manualOperand == item.name ) || ( form.operandUuid == item.uuid ) ? activeClass : ''" test-id="condition-tree-item" :title="itemName(item)") {{ itemName(item) }}
            span.ml-1.text-truncate {{ item.name }}
      .loading__block(v-else)
        v-progress-circular( size="50" color="primary" indeterminate)
</template>

<script>
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'
import { useAxisGroupTableStore } from '@/pinia'

const dialogTitle = {
  model: 'section.elementTable.column.dialog.model',
  floor: 'section.elementTable.column.dialog.floor',
  geometry: 'section.elementTable.column.dialog.geometry',
  attribute: 'section.elementTable.column.dialog.attribute',
  identification: 'section.elementTable.column.dialog.identification',
}

export default {
  data() {
    return {
      columnsDialog: false,
      selectAttributeDialog: false,
      conditionTreeCache: new ConditionTreeCache(),
      selectedAttr: {
        name: '',
        value: '',
      },
      attrIsLoading: false,
      activeAttributeTab: 'ATTRIBUTE',
      valueList: null,
    }
  },

  computed: {
    store: () => useAxisGroupTableStore(),

    dialogHeader() {
      return this.$t(dialogTitle[this.selectedAttr.value])
    },

    settingColums() {
      return [
        { name: 'section.groupingTable.column.quantity', value: 'elementsCount' },
        { name: 'section.groupingTable.column.marker', value: 'color' },
        { name: 'section.groupingTable.column.model', value: 'modelName' },
        { name: 'section.groupingTable.column.floor', value: 'floorName' },
        { name: 'section.groupingTable.column.ifcType', value: 'elementClassName' },
        { name: 'section.groupingTable.column.attribute', value: 'attribute' },
        { name: 'section.groupingTable.column.zone', value: 'zoneName' },
        { name: 'section.groupingTable.column.layer', value: 'layerName' },
        { name: 'section.groupingTable.column.geometry', value: 'geometry' },
        { name: 'section.groupingTable.column.identification', value: 'identification' },
      ]
    },

    getActiveAttributeTab: {
      get() {
        return this.activeAttributeTab
      },
      async set(val) {
        this.activeAttributeTab = val
        this.selectColumn(this.selectedAttr, val)
        // this.conditionTreeCache.search(this.search)
      },
    },
  },

  methods: {
    show() {
      this.activeAttributeTab = 'ATTRIBUTE'
      this.columnsDialog = true
    },

    handleTreeOpen(item) {
      if (this.conditionTreeCache.isOpened(item)) {
        this.conditionTreeCache.close(item)
      } else {
        this.conditionTreeCache.open(item)
      }
    },

    selectAttribute(item) {
      const addColumn = (attrItem) => {
        let column = {
          value: this.selectedAttr,
          attr: attrItem,
        }
        this.store.addColumnToTable(column)
        this.store.saveHeaders() //check this leter
        this.selectAttributeDialog = false
      }

      if (this.selectedAttr.value !== 'attribute') {
        addColumn(item)
        return
      }

      if ('children' in item && item.children.length) {
        this.handleTreeOpen(item)
      } else {
        addColumn(item)
      }
    },

    selectColumn(column, attr) {
      this.selectedAttr = column
      let attributColumns = ['geometry', 'attribute', 'identification']
      if (attributColumns.includes(column.value) || attr) {
        let needDisclosure = false
        let paramName = column.value === 'identification' ? 'IDPROP' : attr ? attr : column.value.toLocaleUpperCase()

        this.attrIsLoading = true
        this.selectAttributeDialog = true

        this.store.loadParamForCondition(paramName).then((data) => {
          this.attrIsLoading = false
          if (column.value === 'geometry') {
            this.valueList = data.map((item) => {
              let it = Object.assign({}, item)
              it.name = this.$t('section.elementTree.geometry.' + item.attr)
              return it
            })
          }

          if (column.value === 'identification') {
            this.valueList = data.map((item) => {
              let it = Object.assign({}, item)
              it.name = this.$t('section.elementTree.idprop.' + item.attr)
              return it
            })
          }

          if (column.value === 'attribute') {
            needDisclosure = true
            this.valueList = data
          }

          switch (attr) {
            case 'BASEATTR':
              needDisclosure = true
              this.valueList = data.map((item) => {
                return { name: item }
              })
              break

            case 'USERPROP':
            case 'USERMANUALPROP':
            case 'MANUALINPUT':
              needDisclosure = true
              this.valueList = data
              break
            default:
              break
          }

          this.conditionTreeCache.setItems(this.valueList)
          if (needDisclosure && this.valueList.length === 1 && this.valueList?.[0].children.length) {
            this.valueList.forEach(el => {
              this.handleTreeOpen(el)
            });
          }
        })
      } else {
        let newColumn = {
          value: column,
          attr: '',
        }
        this.store.addColumnToTable(newColumn)
        this.store.saveHeaders() //check this leter
      }
      this.columnsDialog = false
    },
  },
}
</script>
<style lang="scss" scoped>
.column__list {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.column__list__item-text {
  color: #333333;
  padding: 10px 5px;
}

.tree-item {
  font: normal 12px/16px 'Roboto', sans-serif;
  color: black;
  white-space: nowrap;
}

.item__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}

.item__toggle--close {
  transform: rotate(-90deg);
}

.loading__block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
