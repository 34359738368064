<template lang="pug">
  app-panel(ident="reports" v-bind="$attrs")
    app-panel-header(:title="$t('reports.mainHeader')" showClose )

    fill-form( v-model="dialog.fillForm" :formId="formUuid" :returnData="true" @filled="afterFormFilled" )
    app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
      
      app-floating-panel(:title="$t('reports.header')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" :settings="floatingPanels('REPORTS')" )
        v-progress-linear(v-if="running" indeterminate color="accent")
        div.format.base-scroll-body.pr-1
          div.receiver-list-item(v-for="pl in reportPlugins" :key="pl.uuid" )
            v-row
              v-col.pointer(@click="fillPluginForm(pl)")
                .text {{pl.name}}
        

      app-floating-panel(:title="$t('reports.companyHeader')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" :settings="floatingPanels('REPORTS_ORG')" )
        v-progress-linear(v-if="running" indeterminate color="accent")
        div.format.base-scroll-body.pr-1
          div.receiver-list-item(v-for="pl in reportOrgPlugins" :key="pl.uuid" )
            v-row
              v-col.pointer(@click="fillPluginForm(pl)")
                .text {{pl.name}}          
        

    //- app-dialog(v-model="dialog.runPlugin" :header="$t('reports.reportFile')")
    //-   app-section(v-if="exportProccess.state")
    //-     app-text {{ $t('reports.preparingFile') }}
    //-     v-progress-linear(:value="exportProccess.state.progress")
    //-   app-section(v-if="exportError")
    //-     app-text {{ exportError }}


    v-snackbar(v-if="error" v-model="errorSnackBar" :multi-line="true" timeout="-1" color="alert") 
      div {{ $t('error.default') }}: {{ error }}
      template(v-slot:action="{ attrs }")
        v-btn(color="red" icon v-bind="attrs" @click="clearError" :title="$t('button.close')")
          v-icon mdi-cancel

    v-snackbar( v-model="processSnack" color="success" timeout="1000" width="180" left bottom rounded ) {{ processSnackText }}

</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import { pluginService } from '@/_services'
import FillForm from "@/pages/admin/forms/Form"
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@app/AlertService'

export default {
  name: 'Reports',

  data() {
    return {
      formUuid: null,
      error: null,
      errorSnackBar: false,
      processSnack: false,
      processSnackText: "",
      running: false,
      dialog: {
        pluginsList: false,
        fillForm: false,
        runPlugin: false
      },
      filter: {
        plugin: null,
      },
      formParam: {},

      exportProccess:{
        state: null,
        interval: null
      },
      exportError:null
    }
  },

  components: {
    FillForm
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    
  },

  computed: {
    ...mapState('authUser', ['user']),
    ...mapState('project', ['project']),
    ...mapState('plugins', ['types','loadedPlugin']),
    ...mapGetters('plugins', ['reportPlugins','reportOrgPlugins']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

  },

  methods: {
    ...mapActions('plugins',['INIT','LOAD_PLUGINS','LOAD_PLUGINS_BY_PROFILE','LOAD_TABLE']),
    ...mapMutations('plugins',['setLoadedPlugin','setPluginTable']),
    ...mapActions('selected', ['dropColorized']),

    init(){
      this.INIT()
      this.loadPluginsList()
    },

    loadPlugin(item){
      pluginService.load(item.uuid).then(data => {
        this.setLoadedPlugin(data)
        this.dialog.pluginsList = false
      })
    },

    loadPluginsList(){
      this.LOAD_PLUGINS(this.project.uuid)
      this.LOAD_PLUGINS_BY_PROFILE()
    },

    showPluginsList(){
      this.dialog.pluginsList = true
    },

    clearError(){
      this.error = null
    },

    afterFormFilled(data) {
      data.forEach( field => {
        if(field.alias)
          this.formParam[field.alias] = field.value
      })
      this.runPlugin()
    },

    fillPluginForm(pl){
      this.clearError()
      this.exportError = null
      this.filter.plugin = pl
      this.formParam = {}
      if(pl.formUuid) {
        this.formUuid = pl.formUuid
        this.dialog.fillForm = true 
      } else this.runPlugin()
    },

    runPlugin() {
      this.clearError()
      this.exportError = null
      if (this.filter.plugin.type.name != 'ANALYTICAL_REPORT') {
        // this.dialog.runPlugin = true
        AlertService.info({info: this.$t('reports.addToProcess')})
        pluginService
          .executeWithParamToFile(this.project.uuid, this.filter.plugin.uuid, this.formParam)
      } else {
        XeokitMediator.clearColorizeModel()
        XeokitMediator.ElementsSelection.selectElements([])
        this.running = true
        this.setPluginTable(null)
        pluginService
          .executeWithParam(this.project.uuid, this.filter.plugin.uuid, this.formParam)
          .then((data)=>{
            data.pluginUuid = this.filter.plugin.uuid
            this.setPluginTable(data)
            this.LOAD_TABLE(true)
          }) 
          .catch((error) => {
            this.errorSnackBar = true
            this.error = error
          }).finally(() => this.running = false)  
        
        }
    },
  }
}
</script>
<style lang="scss" scoped>
.format{
  height: 100%;
  overflow-y: auto;
}
.receiver-list-item {
  display: flex;
  align-items: center;
  color: black;
  font-weight: bold;
  padding: 4px 8px;
  transition: .1s;
  cursor: pointer;
  
  & span { 
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.pressed {
    background: #F8F8F8;
  }

  &:hover {
    background: #e5e5e5;
  }

  &.selected {
    background: #e5e5e5;
  }
    
}
.text{
  font-family: 16px "Roboto", sans-serif !important;
}
</style>

