<template lang="pug">
  .d-flex.flex-column(:style="isSelected" ref='row' :draggable="true")
    .task-row
      tree-task-theme.column-0(:task='source' @click.native='toggleTask(source)' @show='showChild' test-id="task-tree-theme")
      task-tree-status(:task='source' v-if='headers.find(header => header.name == "STATUS").show' :class='`column-${headers.findIndex(header => header.name == "STATUS")}`')
      task-tree-progress(:task='source' v-if='headers.find(header => header.name == "PROGRESS").show' :class='`column-${headers.findIndex(header => header.name == "PROGRESS")}`'
        @openReport="openReport")
      tree-executor(:task='source' v-if='headers.find(header => header.name == "EXECUTOR").show' :class='`column-${headers.findIndex(header => header.name == "EXECUTOR")}`')
      tree-row-date(:task='source' v-if='headers.find(header => header.name == "START").show' :class='`column-${headers.findIndex(header => header.name == "START")}`' test-id="task-tree-date-start")
      tree-row-date(:task='source' end v-if='headers.find(header => header.name == "END").show' :class='`column-${headers.findIndex(header => header.name == "END")}`')
    
</template>

<script>
import { api } from '@/api'
import { Task } from '@/assets/model/task/Task'
import { mapState, mapActions, mapMutations } from 'vuex'
import TreeRowDate from './TreeRowDate.vue'
import TreeExecutor from './TreeExecutorRow.vue'
import TreeTaskTheme from './TreeTaskTheme.vue'
import TaskTreeStatus from './TaskTreeStatus.vue'
import TaskTreeProgress from './TaskTreeProgress.vue'
export default {
  name: "TaskTreeRow",
  components: { 
    TreeRowDate, 
    TreeExecutor,
    TreeTaskTheme,
    TaskTreeProgress,
    TaskTreeStatus
  },
  props: {
    source: {
      type: Task,
      required: true
    },
    headers: Array
  },
  data(){
    return {
      show: {
        child: true,
        row: true
      }
    }
  },
  computed: {
    ...mapState('task', ['selectedTask']),
    isSelected () {
      return this.selectedTask && this.selectedTask.uuid === this.source.uuid ? { backgroundColor: "#e5e5e5"} : { backgroundColor: "#ffffff"}
    }
  },
  methods: {
    ...mapActions('task', ['addTask', 'search']),
    ...mapMutations('task', ['setSelectedTask', 'delAllSubTaskOpen', 'addSubTaskOpen', 'setSelectedTaskType']),
    ...mapMutations('project', ['setTopUnit']),

    loadTaskType(task) {
      if(task.uuid && task.uuid != null && (task.taskType.uuid == null || task.taskType.uuid == undefined)) {
        api.forms.dataList(task.uuid).then(data => {
          if(data.length > 0){
            this.$store.commit('task/setSelectedTaskType', {taskType: data[data.length-1]})
          }
        })
      }
    },

    toggleTask (t) {
      if (this.selectedTask && this.selectedTask.uuid === t.uuid) {
        this.setSelectedTask(null)
        this.delAllSubTaskOpen()
      } else {
        this.setSelectedTask(t)
        this.delAllSubTaskOpen()
        this.addSubTaskOpen(t.uuid)
        this.loadTaskType(t)
      }
    },
    showChild(show) {
      this.show.child = show
    },
    
    showProgressBar(){
      this.$refs.progressBar.showEditProgress()
    },

    openReport(task) {
      this.$emit('openReport', task)
    }
  },

  mounted() {
    console.log("SOURCE", this.source)
  }
}
</script>

<style lang="scss" scoped>
  .task-row{
    display: grid;
    grid-template-areas: "A B C D E F";
    grid-template-columns: 1fr repeat(5, auto);
  }
  .column-0{
    grid-area: A;
    cursor: pointer;
    z-index: 10;
  }
  .column-1{
    grid-area: B;
    z-index: 10;
  }
  .column-2{
    grid-area: C;
    z-index: 10;
  }
  .column-3{
    grid-area: D;
    z-index: 10;
  }
  .column-4{
    grid-area: E;
    z-index: 10;
  }
  .column-5{
    grid-area: F;
    z-index: 10;
  }
</style>