<template lang="pug">
  .task-tree-theme-row( @click='$emit("click")' )
    .hundred-width(:style='`padding-left: ${taskTreeCache.sort_option.name ? 0 : task.level * 18}px; width: 100%`')
      .d-flex.align-center.hundred-width
        v-btn.mr-1(x-small @click.stop='showChild' color='#E5E5E5' elevation='0' height='36px' style='width:18px; min-width:18px; border-radius:3px; place-self:start' :disabled='!!(taskTreeCache.sort_option.name || task.children.length === 0)' :text='!!(taskTreeCache.sort_option.name || task.children.length === 0)')
          v-icon(v-if='!taskTreeCache.sort_option.name && task.children.length > 0' color='black' small) {{ arrowIcon }}
        //- v-tooltip.mr-1(bottom open-delay='1000' close-delay='0')
          template( v-slot:activator="{ on, attrs }" )
        //- .task-title( v-bind="attrs" v-on="on" )
        .task-title
          span(:title='task.title') {{ task.title }}
          //- span {{ task.title }}
</template>

<script>
import { Task } from '@/assets/model/task/Task'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: "TreeTaskTheme",

  props: {
    task: {
      type: Task,
      required: true
    }
  },

  data() {
    return {
      show: {
        child: true
      }
    }
  },

  computed: {
    ...mapGetters('task', ['taskTreeIsOpened']),
    ...mapState('task', ['taskTreeCache']),

    arrowIcon(){
      return this.show.child ? "mdi-menu-up" : "mdi-menu-down"
    }
  },

  beforeMount() {
    this.show.child = this.taskTreeIsOpened(this.task)
  },

  methods: {
    ...mapMutations('task', ['handleTreeClose', 'handleTreeOpen']),

    showChild(){
      if(this.taskTreeIsOpened(this.task)) {
        this.handleTreeClose(this.task)
        this.show.child = false
      }
      else {
        this.handleTreeOpen(this.task)
        this.show.child = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .task-tree-theme-row{
    display: flex;
    color: #4D4D4D;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-width :250px; 
    flex:1; 
    font-size: 14px;
    padding: 0px 4px 0px 4px
  }
  .hundred-width{
    width: 100%;
  }
  .task-title{
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis
  }
</style>