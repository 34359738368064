<template lang="pug">
ToolButton( 
  :active="isActive" 
  :tooltip="tooltip" 
  @click="toggleTool" 
) $viewer-toolbar-groups-icon
</template>

<script>
import ToolButton from './ToolButton.vue'
import { ToolWindow, useActiveToolWindowStore } from "@/stores/viewerTools/activeToolWindow.store"
const store = useActiveToolWindowStore()

export default {
  components: {
    ToolButton,
  },

  computed: {
    isActive: () => store.activeWindow == ToolWindow.SCENE_GROUPS,
    tooltip: () => 'Группы',
  },

  methods: {
    toggleTool () {
      store.toggleActiveWindow(ToolWindow.SCENE_GROUPS)
    },
  }
}
</script>