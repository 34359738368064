<template lang="pug">
  app-dialog( v-model="$_show" v-bind='$attrs' :confirm-disabled="!ready" @confirm="onSaving" :confirmText="$t('section.collision.rulesFounder.importButton')" )
    app-section
      app-file-input( v-model="file" :max-size="1000000000" :formats="['.json']" )
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

export default {
  mixins: [DialogToggable],

  data: () => ({ file: null }),

  computed: {
    ready () {
      return !! this.file
    },
  },

  methods: {
    ...mapActions('rule/global', ['importRules']),
    ...mapMutations('rule', ['setIsLoadingTree']),

    onSaving () {
      
      this.setIsLoadingTree(true)
      this.importRules(this.file).then(() => {
        this.$store.dispatch('rule/reloadTree').then(() => {
          this.setIsLoadingTree(false)

          this.file = null
        })
      })
    },
  },

}
</script>

