<template lang="pug">
div
  task-section-expansion( :title="`${$t('module.task.collisiions')} (${collisionsLength})`" :show-toggle="getCollisionPanel" headerColorDark @openPanel="setCollisionPanel" )
    div.mx-2.mt-2
      div.t-collision-card.mb-2.pa-2.flex(v-for='(collision, index) in collisions' :key='collision.uuid' :class='{ "active": collision.uuid === selectedCollisionUuid }' test-id="task-collision-card" @click='onCollisionClick(collision)' )
        v-hover(#default='{ hover }')
          div( @contextmenu.prevent='toggleStatusMenu($event, index)' )
            div.caption.bold.pointer( :style='{ "color": statusColor[collision.status.name] }' test-id="task-collision-status" @click.stop='toggleStatusMenu($event, index)' ) {{ collision.status.title }}
            app-text.mt-1
              div.text-truncate # {{ collision.info ? collision.info.type : '' }}
              div.text-truncate # {{ collision.info ? collision.info.shortDescription : ''}}
              div.text-truncate # {{ collision.info ? `${$t('section.collision.table.rule')}: ${collision.info.ruleTitle}` : ''}}
              div.text-truncate # {{ collision.info ? `${$t('section.collision.table.selection')}: ${collision.info.ruleATitle} -> ${collision.info.ruleBTitle}` : ''}} 
              div.text-truncate {{ collision.info && collision.info.note && collision.info.note !== undefined ? `# ${collision.info.note}` : ''}}
            
            div.d-flex(v-if="hasTaskCreate")
              v-spacer
              v-btn( color='surface' icon x-small @click.stop='deleteCollisionDialogShow = true, deleteCollisionIdx = index' )
                v-fade-transition
                  v-icon( v-if='hover' small ) mdi-delete

      div.t-collision-card.mb-2.pa-2.flex(v-for='(rule, index) in rules' :key='rule.uuid' @click='onRuleAndAttributeClick(rule)' :class='{ "active": rule.uuid === attributeTableStore.selectedAtrrOrRuleUuid }')
        v-hover(#default='{ hover }')
          div.pointer
            //- div.caption.bold( style='color: #27ae60' ) {{ $t('section.collision.table.rule') }}
            app-text.mt-1
              div.text-truncate # {{ rule.finder ? rule.finder.title : '' }}
              div.text-truncate # {{ rule.title ? rule.title : '' }}
              div.text-truncate # {{ rule.countCollisions ? 'Количество коллизий: ' + rule.countCollisions : ''}}
            
            div.d-flex(v-if="hasTaskCreate")
              v-spacer
              v-btn( color='surface' icon x-small @click.stop='deleteRuleDialogShow = true, deleteRuleIdx = index' )
                v-fade-transition
                  v-icon( v-if='hover' small ) mdi-delete

      div.t-collision-card.mb-2.pa-2.flex(v-for='(attribute, index) in attributes' :key='attribute.uuid' @click='onRuleAndAttributeClick(attribute)'  :class='{ "active": attribute.uuid === attributeTableStore.selectedAtrrOrRuleUuid }')
        v-hover(#default='{ hover }')
          div.pointer
            div.caption.bold( v-if="attribute.recalcStatus.name === 'DELETE'" style='color: #EB5757' ) {{ $t('module.task.recalcStatus.delete') }}
            div.caption.bold( v-else :style='{ "color": statusColor[attribute.status.name] }' ) {{ attribute.status.title }}
            app-text.mt-1
              div.text-truncate # {{ attribute.attribute ? attribute.attribute : '' }}
              div.text-truncate # {{ 'Элементов: ' + attribute.countElement }}
            
            div.d-flex(v-if="hasTaskCreate")
              v-spacer
              v-btn( color='surface' icon x-small @click.stop='deleteAttributeDialogShow = true, deleteAttributeIdx = index' )
                v-fade-transition
                  v-icon( v-if='hover' small ) mdi-delete

  app-menu( ref="status-menu" :menu="statusMenu" )
  app-dialog-confirm( v-model='deleteCollisionDialogShow' @confirm='deleteCollisionFromTask(deleteCollisionIdx)' ) 
    app-text {{ $t('module.task.confirmCollisionDelete') }}
  app-dialog-confirm( v-model='deleteRuleDialogShow' @confirm='deleteRuleOrAttributeFromTask("rule")') 
    app-text {{ $t('module.task.confirmRuleDelete') }}
  app-dialog-confirm( v-model='deleteAttributeDialogShow' @confirm='deleteRuleOrAttributeFromTask("attribute")') 
    app-text {{ $t('module.task.confirmAttributeDelete') }}
</template>

<script>
import { AlertService } from '@/assets/app/AlertService'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { api } from '@/api'
import { useAttributeCheckingTableStore } from '@/pinia'
import CollisionHighlightService from '@@/project/panel/left/components/collision/CollisionHighlightService.js'
import { mapStores } from 'pinia'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';

export default {
  name: 'TaskCollisionsSection',

  components: {
    TaskSectionExpansion
  },

  data() {
    return {
      statusMenu: [],

      deleteCollisionIdx: null,
      deleteCollisionDialogShow: false,

      deleteRuleDialogShow: false,
      deleteRuleIdx: null,

      deleteAttributeDialogShow: false,
      deleteAttributeIdx: null,

      statusColor: {
        NEW: '#EB5757',
        WORK: '#f2994a',
        SOLVED: '#27ae60',
        ALLOWED: '#c4c4c4',
      },
    }
  },

  props: {
    selectedTask: Object,
  },

  computed: {
    ...mapStores(useAttributeCheckingTableStore),

    ...mapState('collision/search', ['collisionSelectedElements', 'collisionSelectedGlobalIds']),
    ...mapState('collision/table', ['taskCollision', 'selectedCollisions']),
    ...mapGetters('collision/search', ['preparedCollisionsTask']),
    ...mapGetters('taskDetails', ['getCollisionPanel']),
    ...mapGetters('projectPermissions', ['hasCollisionStatus', 'hasCollisionResolution']),

    ...mapGetters('projectPermissions', ['hasTaskCreate']),

    collisions() {
      return this.selectedTask.collision && this.selectedTask.collision.length > 0 ? [...new Set(this.selectedTask.collision)] : []
    },

    rules() {
      return this.selectedTask.rule && this.selectedTask.rule.length > 0 ? [...new Set(this.selectedTask.rule)] : []
    },

    attributes() {
      return this.selectedTask.attribute && this.selectedTask.attribute.length > 0 ? [...new Set(this.selectedTask.attribute)] : []
    },

    isTaskManager() {
      return window.location.href.includes('task/manager')
    },

    selectedCollisionUuid() {
      let { globalIdA, globalIdB } = this.collisionSelectedGlobalIds || {}
      let collision = this.selectedTask.collision?.find((item) => item.globalIdA === globalIdA && item.globalIdB === globalIdB)

      return collision?.uuid
    },

    collisionsLength() {
      return this.collisions.length + this.rules.length + this.attributes.length
    },
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab', 'setAttributeChecking']),
    ...mapMutations('collision/search', ['setTabCollision', 'setCollisionSelectedElements']),
    ...mapActions('collision/search', ['setCollisionFromTask']),
    ...mapActions('taskDetails', ['setCollisionPanel']),
    ...mapActions('collision', ['setCollisionStatus']),
    ...mapMutations('collision', ['setTab']),
    ...mapMutations('collision/table', ['CHANGE_COLLISION_SOURCE']),
    ...mapActions('collision/table', ['selectCollisions', 'updateCollisionsList', 'updateCollisionTaskList']),

    onCollisionClick(collision) {
      if (this.isTaskManager) {
        return AlertService.warning({ info: this.$t('module.task.taskManager.projectWarn') })
      }

      this.setActiveGlobalTab('collision')
      this.setTabCollision(false)
      this.setTab(1)
      this.setAttributeChecking(false)

      this.setCollisionFromTask(this.selectedTask.collision)

      if (!this.taskCollision.isActive || this.taskCollision.taskUuid != this.selectedTask.uuid) {
        this.CHANGE_COLLISION_SOURCE({
          isActive: true,
          id: this.selectedTask.uuid,
          list: this.preparedCollisionsTask,
        })
      }

      this.$nextTick(() => CollisionHighlightService.setCollisionSelected(collision))
    },

    onRuleAndAttributeClick(item) {
      if (item?.recalcStatus?.name !== 'DELETE') {
        this.attributeTableStore.selectedElement = null
        this.attributeTableStore.selectedAtrrOrRuleUuid = item.uuid
        if (this.attributeTableStore.task.taskId == this.selectedTask.uuid && this.attributeTableStore.task.active){
          if (item?.collisionRule) this.attributeTableStore.openRuleAttribute(item?.collisionRule)
          return
        }
        if (this.isTaskManager) {
          return AlertService.warning({ info: this.$t('module.task.taskManager.projectWarn') })
        }

        this.setActiveGlobalTab('collision')
        this.setTabCollision(false)
        this.setTab(2)
        this.setAttributeChecking(true)

        this.attributeTableStore.changeItemsSource(true, this.selectedTask.uuid, item?.collisionRule)
      }
    },

    toggleStatusMenu(event, collisionIdx) {
      const menu = this.$refs['status-menu']

      if (menu.showMenu) {
        return menu.close()
      }

      const collision = this.selectedTask.collision[collisionIdx]

      if(this.hasCollisionStatus) {
        this.statusMenu = [
          {
            title: this.$t('section.collision.status.new'),
            action: () => this.setCollisionStatus({ uuid: collision.uuid, status: 'NEW', prevStatus: collision.status.name }),
            disabled: collision.status.name === 'NEW',
          },
          {
            title: this.$t('section.collision.status.work'),
            action: () => this.setCollisionStatus({ uuid: collision.uuid, status: 'WORK', prevStatus: collision.status.name }),
            disabled: collision.status.name === 'WORK',
          },
        ]
        
        if(this.hasCollisionResolution) {
          this.statusMenu.push({
            title: this.$t('section.collision.status.allowed'),
            action: () => this.setCollisionStatus({ uuid: collision.uuid, status: 'ALLOWED', prevStatus: collision.status.name }),
            disabled: collision.status.name === 'ALLOWED',
          })
        }
      }

      menu.show(event)
    },

    deleteCollisionFromTask(collisionIdx) {
      if (collisionIdx < 0 || collisionIdx > this.selectedTask.collision.length) return

      let { globalIdA, globalIdB } = this.collisionSelectedGlobalIds || {}
      let collision = this.selectedTask.collision[collisionIdx]
      if (collision.globalIdA === globalIdA && collision.globalIdB === globalIdB) this.setCollisionSelectedElements([])

      this.selectedTask.collision.splice(collisionIdx, 1)
      this.setCollisionFromTask(this.selectedTask.collision)

      let newSelected = []
      if (this.preparedCollisionsTask.length > 0) {
        this.CHANGE_COLLISION_SOURCE({ isActive: true, id: this.selectedTask.uuid, list: this.preparedCollisionsTask })
        newSelected = this.selectedCollisions
          .filter((el) => collision.globalIdA !== el.globalIdA || collision.globalIdB !== el.globalIdB)
          .map((el, index) => {
            el.index = index
            return el
          })
      } else if (this.taskCollision.isActive) {
        this.CHANGE_COLLISION_SOURCE({ isActive: false, id: this.selectedTask.uuid })
        this.updateCollisionsList()
      }
      this.selectCollisions(newSelected)

      api.tasks.patchTask(
        this.selectedTask.uuid,
        this.selectedTask.collision.map((c) => c.uuid)
      )

      this.updateCollisionTaskList({ collisionId: collision.uuid, taskId: this.selectedTask.uuid })
    },

    deleteRuleOrAttributeFromTask(type = 'rule') {
      let uuids
      if (type == 'rule') {
        this.selectedTask.rule.splice(this.deleteRuleIdx, 1)
        uuids = this.selectedTask.rule.map((c) => c.uuid)
      } else {
        this.selectedTask.attribute.splice(this.deleteAttributeIdx, 1)
        uuids = this.selectedTask.attribute.map((c) => c.uuid)
      }
      api.tasks.patchTask(this.selectedTask.uuid, uuids, type).then(() => {
        this.attributeTableStore.changeItemsSource(true, this.selectedTask.uuid)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.t-attach {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: calc(100% - 15px);
}
.t-attach-active {
  background: #e5e5e5;
}
.t-attach-icon {
  width: 24px;
  height: 24px;
}
.t-attach-status {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  text-align: left;
  color: #eb5757;
  user-select: none;
}
.collision-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.col-solved {
  color: #27ae60;
}
.col-in-work {
  color: #f2994a;
}
.col-allowed {
  color: #c4c4c4;
}
.t-collision-card {
  border: 1px solid var(--v-primary-lighten4);
  border-radius: 3px;

  &.active {
    background: var(--v-primary-lighten5);
  }
}
</style>
