<template lang='pug'>
div.fill-panel-height.d-flex
  task-details-panel(v-if='selectedTask' @close='refreshTaskPanel()')

  app-panel.mr-2.px-1(v-if='isTaskCreating' width='350')
    task-header.mt-2.mb-2( :title="$t('module.task.creature')" mainTitle headerItemsCenter)
      template(v-slot:headerBefore)
        v-btn.mr-1( icon small @click='setIsTaskCreating(false)')
          app-icon-action(icon="mdi-arrow-left") 
    app-section
      create-task-form.flex(@create='setIsTaskCreating(false)')

  app-panel( v-if='!( (isTree || isDiagram) && selectedTask )' ref='task-panel' ident='tasks' right )
    div.d-flex.flex-column.fill-height
      task-header.mb-2( :title="$t('module.task.title')" :menu='tasksPanelMenu' headerItemsCenter mainTitle )
        template(v-slot:titleAfter)
          app-toolbar.ml-2( horizontal )
            app-toolbar-button( size="small" icon="$filter-icon" :active="showFilterForm" :title="$t('module.task.taskFilter')" @click='showFilterForm = !showFilterForm' test-id="panel-task-filter" )
            app-toolbar-button(v-if="hasTaskCreate" size="small" icon="$add-box-icon" :active="isTaskCreating" :title="$t('module.task.create')" @click='showTaskCreate(!isTaskCreating)' test-id="panel-task-create" )          
        
        template(v-slot:headerAfter)
          app-toolbar( horizontal gap="4px" )
            app-toolbar-button( v-if="isTree" iconSize="18px" size="small" icon="$setting-icon" :title="$t('module.task.settings')" @click='showTreeColumnsSettings = true' test-id="panel-task-settings" )
            app-toolbar-button( size="small" iconSize="18px" icon="$task-dashboard-icon" :active="isDashboard" :title="$t('module.task.dashboard')" @click='setTaskDisplayMode("dashboard")' test-id="panel-task-dashboard" )
            app-toolbar-button( size="small" iconSize="18px" icon="$task-tree-icon" :active="isTree" :title="$t('module.task.tree')" @click='setTaskDisplayMode("tree")' test-id="panel-task-treeView" )
            app-toolbar-button( size="small" iconSize="18px" icon="$task-diagram-gant-icon" :active="isDiagram" :title="$t('module.task.diagram')" @click='setTaskDisplayMode("diagram")' test-id="panel-task-diagram" )

      task-import-form.mb-2( v-if='showImportTaskForm' :classificators='projectClassificators' :loading='taskImportIsLoading' @import='(classificator, file) => importXMLTasks(classificator, file)' )
      task-dates-recalculate.mb-2( v-if='showTaskDatesRecalculate' @recalculate='tasksDatesRecalculate($event)' )
      task-filter-menu.mb-2( v-if='showFilterForm' )

      div.flex.overflow-hidden
        task-dashboard( v-if='isDashboard' )
        task-tree( v-if='isTree' )
        task-gantt( v-if='isDiagram' )
    
  task-tree-columns-settings-dialog( v-model='showTreeColumnsSettings' )
  task-import-BCF-dialog( v-model="showImportBCFDialog" @reloadtasks="loadTasks")
  ExportBCFDialog( v-model="showExportBCFDialog" )

</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { AlertService } from '@/assets/app/AlertService'
import { panelNames } from '@/store/sections/LayoutRepository'

// TODO TaskHeader используется везде, переделать на app-panel-header и отрефакторить
import TaskHeader from '@/components/task/TaskHeader.vue'

import TaskDashboard from '@/components/task/TaskDashboard.vue'
import TaskGantt from '@/components/task/TaskGantt.vue'
import TaskTree from '@/components/task/TaskTree.vue'
import TaskTreeColumnsSettingsDialog from '@/components/task/TaskTreeColumnsSettingsDialog.vue'
import ExportBCFDialog from '@/components/task/ExportBCFDialog.vue'
import TaskImportForm from '@/components/task/TaskImportForm.vue'
import TaskImportBCFDialog from '@/components/task/TaskImportBCFDialog.vue'
import TaskDatesRecalculate from '@/components/task/TaskDatesRecalculate.vue'
import TaskFilterMenu from '@/components/task/TaskFilterMenu.vue'
import TaskDetailsPanel from '@/components/project/panel/right/TaskDetailsPanel.vue'
import CreateTaskForm from '@/components/task/CreateTaskForm.vue'
import TasksFlatList from '@/components/task/TasksFlatList.vue'
import { api } from '@/api'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

const resizableModes = ['tree', 'diagram']
const isResizable = (displayMode) => resizableModes.includes(displayMode)

export default {
  name: 'Tasks',

  components: {
    TaskDashboard,
    TaskTree,
    TaskGantt,
    TaskHeader,
    TaskDetailsPanel,
    TaskTreeColumnsSettingsDialog,
    TaskImportForm,
    TaskImportBCFDialog,
    TaskDatesRecalculate,
    TaskFilterMenu,
    CreateTaskForm,
    TasksFlatList,
    ExportBCFDialog
  },

  data() {
    return {
      showTreeColumnsSettings: false,
      showTaskCreatePanel: true,
      taskImportIsLoading: false,
      showImportBCFDialog: false,
      showImportTaskForm: false,
      showTaskDatesRecalculate: false,
      showFilterForm: false,
      showExportBCFDialog: false,
      onLoadProject: null,
    }
  },

  computed: {
    ...mapState('task', ['taskDisplay', 'selectedTask', 'filter', 'isTaskCreating', 'treeWidth', 'diagramWidth', 'dashboardWidth', 'prevTaskPanel']),
    ...mapState('smeta', ['projectClassificators']),
    ...mapGetters('project', ['projectUuid']),
    ...mapState('collision', ['tab']),
    ...mapState('project', ['topUnit']),
    ...mapState('layoutControler', ['fromCollision']),
    ...mapGetters('projectPermissions', ['hasTaskCreate', 'hasTaskExportImportBCF']),

    isDashboard() {
      return this.taskDisplay === 'dashboard'
    },

    isTree() {
      return this.taskDisplay === 'tree'
    },

    isDiagram() {
      return this.taskDisplay === 'diagram'
    },

    tasksPanelMenu() {
      // TODO Переводы
      let menu = [
      { title: 'Import XML | MPP', action: () => this.showImportTaskForm = !this.showImportTaskForm }
      ]

      if (this.hasTaskExportImportBCF) {
        menu.push(
          { title: 'Export BCF', action: this.exportBCFTasks },
          { title: 'Import BCF', action: () => this.showImportBCFDialog = true }
        )
      }

      menu.push(
        { title: this.$t('module.task.recalculateTime'), action: () => this.showTaskDatesRecalculate = !this.showTaskDatesRecalculate }
      )

      return menu
    },

    // isTaskCreating() {
    //   return this.selectedTask && this.selectedTask.uuid === null
    // }
  },
  
  mounted() {
    this.refreshTaskPanel()

    this.loadTasks().then(() => {
      TaskBimAnnotations.showTaskBimAnnotations()
    })
    this.$store.dispatch('forms/INIT', { bind: this.projectUuid })
    
    this.$store.dispatch('smeta/LOAD_PROJECT_CLASSIFICATORS', this.projectUuid)
    this.$store.dispatch('task/loadDictionaries', this.projectUuid)

    this.onLoadProject = XeokitMediator.xktLoader.on('renderDone', () => {
      TaskBimAnnotations.showTaskBimAnnotations()
    })
  },

  beforeDestroy() {
    // this.setTopUnit(null)
    if (this.fromCollision) {
      this.setTaskDisplay(this.prevTaskPanel.panelName)
      this.setWidthData({ id: panelNames.TASKS, width: this.prevTaskPanel.width })
    }

    if (this.onLoadProject) {
      XeokitMediator.xktLoader.off(this.onLoadProject);
    }
  },

  destroyed() {
    TaskBimAnnotations.clearTaskBimAnnotations()
    this.setSelectedTask(null)
  },

  methods: {
    ...mapActions('task', ['loadTasks', 'importTask', 'recalcTask', 'setCreatingTask']),
    ...mapMutations('task', ['setTaskDisplay', 'setSelectedTask', 'setIsTaskCreating', 'delAllSubTaskOpen', 'addSubTaskOpen', 'setTreeWidth', 'setDiagramWidth', 'setDashboardWidth']),
    ...mapMutations('project', ['setTopUnit']),
    ...mapMutations('layoutControler', ['setWidthData']),

    setTaskDisplayMode(displayMode) {
      if (this.taskDisplay === displayMode) return

      if(this.taskDisplay === 'tree'){
        this.setTreeWidth(this.$refs['task-panel'].$el.clientWidth)
      }

      if(this.taskDisplay === 'diagram'){
        this.setDiagramWidth(this.$refs['task-panel'].$el.clientWidth)
      }

      this.setTaskDisplay(displayMode)
      this.setSelectedTask(null)
      
      this.refreshTaskPanel()
    },

    _setSelectedTask(task) {
      const isSelected = task?.uuid === this.selectedTask?.uuid

      this.setSelectedTask(isSelected ? null : task)
      this.delAllSubTaskOpen()

      if (this.selectedTask) {
        this.addSubTaskOpen(this.selectedTask.uuid)
        this.loadTaskType(this.selectedTask)
        TaskBimAnnotations.showTaskBimAnnotations()
      }
    },
    
    loadTaskType(task) {
      if(task?.uuid && task.uuid != null && task.taskType && (task.taskType.uuid == null || task.taskType.uuid == undefined)) {
        api.forms.dataList(task.uuid).then(data => {
          if(data.length > 0){
            this.$store.commit('task/setSelectedTaskType', {taskType: data[data.length-1]})
          }
        })
      }
    },
    
    
    // TODO ПЕРЕДЕЛАТЬ ПОСЛЕ ПЕРЕДЕЛКИ ПАНЕЛЕЙ
    refreshTaskPanel() {
      const resizable = isResizable(this.taskDisplay)
      this.setPanelResizable(resizable)

      const panelSize = {
        'tree': this.treeWidth,
        'diagram': this.diagramWidth,
        'dashboard': this.dashboardWidth,
      }

      this.setPanelWidth(panelSize[this.taskDisplay])
    },

    setPanelResizable(resizable) {
      const taskPanel = this.$refs['task-panel']
      resizable ? taskPanel?.showControls() : taskPanel?.hideControls()
    },

    setPanelWidth(width) {
      const taskPanel = this.$refs['task-panel']
      taskPanel?.setSize(width)
    },

    async importXMLTasks(classificator, file) {
      this.taskImportIsLoading = true
      await this.importTask({ project: this.projectUuid, file: file, classificator: classificator?.uuid }).then(data => {
        const error = data.error
        if (error) {
          AlertService.warning({ info: error })
        }
      })
      .catch(() => AlertService.warning({ info: "Server Error" }))
      
      this.showImportTaskForm = false
      this.taskImportIsLoading = false
    },

    async tasksDatesRecalculate(dateStart) {      
      await this.recalcTask({ project: this.projectUuid, start: dateStart }).then(data => {
        const error = data.error
        if (error) {
          AlertService.warning({ info: error })
        }
      })
      .catch(() => AlertService.warning({ info: "Server Error" }))

      this.showTaskDatesRecalculate = false
    },

    showTaskCreate(show) {
      this.updateAnnotations()
      this.setSelectedTask(null)
      this.$nextTick(() => {
        this.setIsTaskCreating(show)
      })
    },

    // TODO NEED REFACTOR AND PERENESTI IN EXPORT BCF DIALOG
    exportBCFTasks() {
      this.showExportBCFDialog = true
    },

    updateAnnotations() {
      TaskBimAnnotations.showTaskBimAnnotations()
    }
  },
}
</script>

<style scoped>
.text-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .fill-panel-height {
    height: calc(100%);
  }
</style>