import { useDrawingsStore } from "@/pinia"
import "@/components/drawings/page/pageFit.scss"

export class DrawingPageScalingService {

	static get #store() { return useDrawingsStore() }
	static #pageResizeObserver = null

	static fitPage() {
		const drawingPageImage = document.getElementById("drawingPageImage")
		const pseudoPagePanelFitting = document.getElementById("drawingPseudoPagePanelFitting")

		this.#store.selectedDrawingPage.offsets = null

		if (this.#store.scaleDrawing !== 100) {
			this.#store.setScaleDrawing(100)
			this.scaleSelectedPage()
		}
		
		drawingPageImage.style.transform = ''
		drawingPageImage.style.transformOrigin = ''

		if (!this.#store.pageIsFittingSize) {
			this.#store.pageIsFittingSize = true

			this.#pageResizeObserver = new ResizeObserver(() => {
				if (pseudoPagePanelFitting) {
					pseudoPagePanelFitting.style.height = drawingPageImage.height + "px"
					setTimeout(() => {
						this.#store.imageSize.height = drawingPageImage.height
						this.#store.imageSize.width = drawingPageImage.width
						this.#store.currentPseudoPanelSizes.height = parseInt(pseudoPagePanelFitting.style.height)
						this.#store.currentPseudoPanelSizes.width = pseudoPagePanelFitting.getBoundingClientRect().width
					})
				}
			})

			this.#pageResizeObserver.observe(drawingPageImage)

			drawingPageImage.classList.add('page-fit')
			drawingPageImage.style.maxWidth = this.#store.originalPageSize.width + 'px'

			if (pseudoPagePanelFitting) {
				pseudoPagePanelFitting.classList.add('page-fit')
				pseudoPagePanelFitting.style.maxWidth = this.#store.originalPageSize.width + 'px'
				setTimeout(() => {
					this.#store.imageSize.width = this.#store.originalPageSize.width
					this.#store.imageSize.height = this.#store.originalPageSize.height

					this.#store.currentPseudoPanelSizes.width = pseudoPagePanelFitting.getBoundingClientRect().width
					this.#store.currentPseudoPanelSizes.height = pseudoPagePanelFitting.getBoundingClientRect().width
				}) 
			} 
		}
		else this.stopFittingPage()
	}

	static scaleSelectedPage(returnIfFitting) {
		const drawingPageImage = document.getElementById("drawingPageImage")
		const pseudoPagePanel = document.getElementById("drawingPseudoPagePanel")
		const croppingLayout = document.getElementById("drawingCroppingLayout")

		if (this.#store.pageIsFittingSize) {
			this.stopFittingPage()
			if (returnIfFitting) return
		}

		drawingPageImage.style.transformOrigin = 'top left' 
		drawingPageImage.style.transform = `scale(${this.#store.scaleDrawing / 100})`

		setTimeout(() => {
			if (pseudoPagePanel) {
				pseudoPagePanel.style.width = drawingPageImage.getBoundingClientRect().width + 'px' 
				pseudoPagePanel.style.height = drawingPageImage.getBoundingClientRect().height + 'px'
				this.#store.currentPseudoPanelSizes.width = parseInt(pseudoPagePanel.style.width)
				this.#store.currentPseudoPanelSizes.height = parseInt(pseudoPagePanel.style.height)
			}

			if (croppingLayout) {
				croppingLayout.style.width = drawingPageImage.getBoundingClientRect().width + 'px' 
				croppingLayout.style.height = drawingPageImage.getBoundingClientRect().height + 'px'
			}
		})

		this.#store.selectedDrawingPage.offsets = {
			scale: this.#store.scaleDrawing,
			scroll: {
				x: this.#store.selectedDrawingPage.offsets?.x ? this.#store.selectedDrawingPage.offsets?.x : 0,
				y: this.#store.selectedDrawingPage.offsets?.y ? this.#store.selectedDrawingPage.offsets?.y : 0, 
			}
		}
	}

	static stopFittingPage() {
		if (this.#pageResizeObserver) this.#pageResizeObserver.disconnect()

		const drawingPageImage = document.getElementById("drawingPageImage")
		const pseudoPagePanelFitting = document.getElementById("drawingPseudoPagePanelFitting")
		const pseudoPagePanel = document.getElementById("drawingPseudoPagePanel")
		const croppingLayout = document.getElementById("drawingCroppingLayout")
		
		this.#store.pageIsFittingSize = false 

		drawingPageImage.classList.remove('page-fit')
		drawingPageImage.style.maxWidth = ''
		
		if (pseudoPagePanel) {
			pseudoPagePanelFitting.classList.remove('page-fit')
			pseudoPagePanelFitting.style.maxWidth = ''
		} 

		setTimeout(() => {
			if (pseudoPagePanel) {
				pseudoPagePanel.style.width = drawingPageImage.getBoundingClientRect().width + "px"
				pseudoPagePanel.style.height = drawingPageImage.getBoundingClientRect().height + "px"

				this.#store.imageSize.width = drawingPageImage.getBoundingClientRect().width
				this.#store.imageSize.height = drawingPageImage.getBoundingClientRect().height
				
				this.#store.currentPseudoPanelSizes.width = parseInt(pseudoPagePanel.getBoundingClientRect().width)
				this.#store.currentPseudoPanelSizes.height = parseInt(pseudoPagePanel.getBoundingClientRect().height)
			}

			if (croppingLayout) {
				croppingLayout.style.width = drawingPageImage.getBoundingClientRect().width + 'px' 
				croppingLayout.style.height = drawingPageImage.getBoundingClientRect().height + 'px'
			}

		})
	}

	static scrollToInitialOffsets() {
		const selectedPageBody = document.getElementById("drawingSelectedPageBody")
		const { x, y } = this.#store.selectedDrawingPage.offsets.scroll

		this.#store.setScaleDrawing(this.#store.selectedDrawingPage.offsets.scale)
		this.scaleSelectedPage(false)

		selectedPageBody.scrollTo(x, y)

		this.#store.selectedDrawingPage.offsets = {
			scale: this.#store.scaleDrawing,
			scroll: { x, y }
		}
	}
}