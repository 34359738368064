<template lang='pug'>
  div.receivers-list
    receivers-list-item(v-for='receiver in list' :receiver='receiver' :key='receiver.uuid' :selected='selected && selected.uuid === receiver.uuid' @click.native='handleAction("select", receiver)' @action='handleAction($event, receiver)')
</template>

<script>
import ReceiversListItem from './ReceiversListItem'

export default {
  name: "ReceiversList",

  components: {
    ReceiversListItem
  },

  props: {
    list: Array,
    selected: Object,
  },

  methods: {
    handleAction($event, receiver) {
      this.$emit('action', { type: $event, receiver })
    }
  }
}
</script>

<style>
  .receivers-list {
    display: flex;
    flex-direction: column;
  }
</style>