<template lang="pug">
  div
    app-dialog( v-model="$_show" :header="$t('cloud.nextCloud.signIn')" @confirm="showTreeNextCloud" :confirmText="$t('button.signIn')")
      app-section
        app-text-field( v-model="nextCloud.username" :label="$t('signIn.login')" )
        app-text-field( 
          v-model="nextCloud.passwd" 
          :type="showPSWD ? 'text' : 'password'" 
          :label="$t('signIn.password')" 
          :append-icon="showPSWD ? 'visibility' : 'visibility_off'"  
          @click:append="showPSWD = !showPSWD"
        )
        app-text-field( v-model="nextCloud.cloudUrl" :label="$t('cloud.nextCloud.server')" )

    app-dialog-loading(v-model="loading")

    file-tree(
      v-model="dialog.nextCloudTree"
      :header="$t('cloud.nextCloud.tree')"
      :cloudForm="nextCloud" 
    )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapActions, mapState } from 'vuex'
import FileTree from './cloudComponents/FileTree'


export default {
  name: "next-cloud-tree",

  mixins: [DialogToggable],

  components: {
    FileTree
  },

  data () {
    let defaultForm = Object.freeze({
      username: null,
      passwd: null,
      path: null,
      cloudUrl: null,
      cloudProvider: 'nextCloud',
      fileHash: null,
      modelUuid: null
    })
    return {
      dialog: {
        nextCloudTree: false,
      },
      showPSWD: null,
      nextCloud: Object.assign({}, defaultForm),
      defaultForm,
      active: [],
      open: [],
      loading: false
    }
  },

  computed: {
    ...mapState('cloud', ['cloudWebDavError']),
    ...mapState('authUser', ['user']),
  },

  watch: {
    $_show (value) {
      if (value) {
        this.showNextCloudAuth()
      }
    },
  },

  methods: {
    ...mapActions('cloud', ['cloudWebDavSearch', 'cloudWebDavSearchByPath']),

    showNextCloudAuth () {
      
      this.nextCloud = JSON.parse(JSON.stringify(this.defaultForm))
      if (this.user.cloudOrg && this.user.cloudOrg.cloudUrl && this.user.cloudOrg.cloudUrl!="") {
        this.nextCloud.cloudUrl = this.user.cloudOrg.cloudUrl;
      }
      if (localStorage.getItem('nextCloud') !== undefined && localStorage.getItem('nextCloud')!=null) { 
        let decodeBase64 = atob(localStorage.getItem('nextCloud'))
        this.nextCloud = JSON.parse(decodeBase64)
      }
    },

    showTreeNextCloud () {
      let encodeBase64 = btoa(JSON.stringify(this.nextCloud))
      localStorage.setItem("nextCloud", encodeBase64)
      
      this.loading = true
      this.cloudWebDavSearch(this.nextCloud).then(() => {
        if (this.cloudWebDavError) {
          this.dialog.nextCloudTree = false
          this.$_show = true
        } else {
          this.$_show = false
          this.dialog.nextCloudTree = true
        }
        this.loading = false
      })
    },

  }
  
}
</script>

<style scoped>
.t-scroll-body {
  overflow-y: auto;
  height: 50vh;
  scrollbar-width: thin;
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-primary-lighten3);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-surface-lighten3);
}
</style>