<template lang="pug">
.d-flex.align-center
  app-toolbar-button.mx-4( icon="$search-icon" :title="$t('headerToolbar.search')" @click="toggleSearch" )
  search-elements-field( v-if="isSearchActive" )

  ProjectControlTopBarToolbar.ml-5.mr-2( v-if="!$app.version.isCloud" )
</template>

<script>
import SearchElementsField from "../common/searchElements/SearchElementsField.vue"
import ProjectControlTopBarToolbar from '@/components/project/control/topBar/ProjectControlTopBarToolbar.vue'

export default {
  components: {
    ProjectControlTopBarToolbar,
    SearchElementsField
  },

  data() {
    return {
      isSearchActive: false,
    }
  },

  methods: {
    toggleSearch() {
      this.isSearchActive = !this.isSearchActive
    }
  }
}
</script>