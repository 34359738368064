<template lang="pug">
div
  app-menu(ref="menu" :menu="menuItems")

  export-dialog( ref="exportDialog" isAttr )
  add-to-task( ref="addToTaskDialog" )
  create-task( ref="createTaskDialog" )
</template>

<script>
import { useAttributeCheckingTableStore } from '@/pinia'

import ExportDialog from '../collisionList/dialogs/CollisionListExportDialog.vue'
import AddToTask from './dialogs/AttributeCheckingAddToTask.vue'
import CreateTask from './dialogs/AttributeCheckingCreateTask.vue'
import { mapStores } from 'pinia'
import { mapGetters} from 'vuex'

export default {
  components: {
    ExportDialog,
    AddToTask,
    CreateTask,
  },

  data: () => ({
    selectedItem: null,
    rowMenu: false,
  }),

  computed: {
    ...mapStores(useAttributeCheckingTableStore),
    ...mapGetters('projectPermissions', ['hasCollisionCreateReport']),

    menuItems() {
      let list = []
      if (this.rowMenu) {
        list.push({ title: this.$tc('section.collision.menu.createTask', 0), action: this.showNewTaskDialog })
        list.push({ title: this.$tc('section.collision.menu.addToTask', 0), action: this.showTasksDialog })
        return list
      } else {
        if (this.hasCollisionCreateReport) list.push({ title: this.$t('section.collision.menu.export.file'), action: this.showExportDialog })
        list.push({ title: this.$t('section.collision.menu.setWidthToDefault'), action: this.resetColumnWidth })
        return list
      }
    },
  },

  methods: {
    openMenu(event, item, isRowMenu) {
      this.rowMenu = isRowMenu
      if (item) this.selectedItem = item
      this.$refs.menu.show(event)
    },

    showExportDialog() {
      this.$refs.exportDialog.show()
    },

    showNewTaskDialog() {
      this.$refs.createTaskDialog.show({ single: true, item: this.selectedItem })
    },

    showTasksDialog() {
      this.$refs.addToTaskDialog.show({ single: true, item: this.selectedItem })
    },

    resetColumnWidth() {
      this.attributeTableStore.resetHeaders()
    },
  },
}
</script>
