<template lang='pug'>
tasks-flat-list( :tasks='filteredTasks' :selectedTaskUuid='selectedTask && selectedTask.uuid' test-id="task-list" @select='toggleTask($event)' )
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import TasksFlatList from '@/components/task/TasksFlatList.vue'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'

export default {
  name: "TaskDashboard",

  components: {
    TasksFlatList,
  },

  computed: {
    ...mapState('task', ['tasks', 'selectedTask']),
    ...mapGetters('task', ['filteredTasks'])
  },

  methods: {
    ...mapMutations('task', ['setSelectedTask', 'setIsTaskCreating', 'delAllSubTaskOpen', 'addSubTaskOpen']),
    ...mapActions('task', ['loadTaskType']),

    toggleTask(task) {
      const isSelected = task.uuid === this.selectedTask?.uuid
      this.setSelectedTask(isSelected ? null : task)

      this.delAllSubTaskOpen()

      if (this.selectedTask) {
        this.addSubTaskOpen(this.selectedTask.uuid)
        this.loadTaskType(this.selectedTask)
        TaskBimAnnotations.showTaskBimAnnotations()
      }
    },

    // loadTaskType(task) {
    //   if(task?.uuid && task.uuid != null && task.taskType && (task.taskType.uuid == null || task.taskType.uuid == undefined)) {
    //     api.forms.dataList(task.uuid).then(data => {
    //       if(data.length > 0){
    //         this.$store.commit('task/setSelectedTaskType', {taskType: data[data.length-1]})
    //       }
    //     })
    //   }
    // },
  },
}
</script>

<style>

</style>