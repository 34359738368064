<template lang="pug">
  div  
    v-menu(ref="functionMenuBefore" v-model="showMenu" :position-x="x" :position-y="y" :absolute="isAbsolute" transition="slide-x-transition" offset-y rounded  :close-on-content-click="false" )
      input.search( placeholder="Search" v-model="searchText"  )
      template( v-slot:activator="{ on }" )
      .m-content 
        .m-content-body.py-2
          template(v-for="(option, idx) in menuItems")
            v-divider(v-if="option.divider" light)
            .m-content-text(v-else ripple :style="`font-size:${fontSize}; color:${color};`" @mouseover="hoverElement(option)"  @click="(event) => handleSelect(event, option, idx)"  )
              .m-content-text--disabled.pr-4( v-if="option.disabled" ) 
                .m-content-priority.mr-2( v-if="option.color" :style="{ backgroundColor: option.color }")
              .m-content-text--active.pl-2.pr-4( v-else )
                template( v-if="option.icon")
                .text.text-truncate {{ option.title }} | 
                  span.text1 {{ option.value }} 
    .description(ref="description" v-if="showDescription " :style="`left:${this.x + 360}px; top:${this.y - 50}px`") {{description}}
</template>
<script>
export default {
  name: 'FunctionMenu',
  props: {
    menu: Array,
    basic: Boolean,
    iconSize: {
      type: Number,
      default: 24,
    },
    icon: String,
    fontSize: {
      type: String,
      default: '12px',
    },
    color: {
      type: String,
      default: '#000',
    },
    item: null,
  },

  data: () => ({
    showMenu: false,
    moving: false,
    a: true,
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    description: '',
    searchText: '',
  }),

  mounted() {},

  computed: {
    isAbsolute() {
      return !this.basic && !this.icon
    },

    showDescription() {
      return (
        this.description != '' &&
        this.description != null &&
        this.showMenu == true
      )
    },

    menuItems() {
      let arr = this.menu.filter((item) =>
        item.title.toLowerCase().includes(this.searchText.toLowerCase()) || item.value.toLowerCase().includes(this.searchText.toLowerCase())
      )
      if(arr[0])
        this.updateDescription(arr[0].description)
      else
        this.updateDescription("")
      return arr.length > 0 ? arr : [{ title: this.$t('template.noMatches') }]
    },
  },

  watch: {
    showMenu(newValue) {
      if (!newValue && !this.moving) this.$emit('close')
    },
  },

  methods: {
    hoverElement(el) {
      this.description = el.description
    },
    updateDescription(el) {
      this.description = el
    },
    handleSelect(event, option, idx) {
      if (!option.disabled) {
        this.showMenu = false
        if (option.action) option.action(event, this.item)
        else this.$emit('menu:select', option, idx, this.item)
      }
    },
    show(e) {
      e.preventDefault()
      this.moving = true
      this.showMenu = false
      this.x = e.clientX
      if(window.innerHeight - 250 < e.clientY)
        this.y = window.innerHeight - 250
      else
        this.y = e.clientY
      this.$nextTick(() => {
        this.moving = false
        this.showMenu = true
      })
    },
    hide() {
      this.showMenu = false
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 32px;
  display: flex;

  &-icon {
    display: inline-block;
    cursor: pointer;
    flex: 0 1 auto;
  }

  &-text {
    font: bold 14px/24px $roboto;
    vertical-align: top;
    flex: 1 1 auto;
  }
}
.m-content {
  border-radius: 0px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
  max-height: 200px;
  min-width: 300px;
}
.m-content-body {
  border-radius: 0px;
  background-color: #f8f8f8;
}
.m-content-priority {
  display: inline-block;
  width: 10px;
  height: 16px;
}
.m-content-text {
  font: normal 12px/24px $roboto;
  text-align: left;
}
.m-content-text--disabled {
  color: var(--v-primary-lighten4);
  cursor: not-allowed;
}
.m-content-text--active {
  width: 100%;
  cursor: pointer;
}
.m-content-text--active:hover {
  background: #e5e5e5;
  color: black;
}
.description {
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #000;
  min-width: 370px;
  max-width: 370px;
  word-break: break-all;
  position: absolute;
  overflow-y: auto; 
  z-index: 10;
  white-space:pre-line;
  border-radius: 3px;
  font-size: 13px;
}
.text {
  word-spacing: 5px;
  font-weight: 600;
}
.text1 {
  font-weight: 600;
}
.search {
  position: sticky;
  top: 0;
  padding: 4px;
  width: 100%;
  background: #f8f8f8;
  outline: solid #e3e3e3 !important;
}
</style>

<style scoped>
.v-menu__content {
  background-color: #f5f5f500 !important;
  min-width: 420px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
