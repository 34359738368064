<template lang="pug">
tr.header
  th(v-for="(header, index) in store.visibleHeaders" 
    :key="index"
    @click="sortItems(header)"
    :style="{ minWidth: columnMinWidth, width: header.width + 'px', position: 'relative'}" 
    :class="{'sorted-desc': sortBy === header.value && sortDesc, 'sorted-asc': sortBy === header.value && !sortDesc}"
  )
    div.header_text {{ $t(header.name) }}
</template>

<script>
import { useAxisGroupTableStore } from '@/pinia'

export default {
  props: {
    columnMinWidth: {
      type: Number,
      default: 0,
    },
    columnWidthsList: [],
    sortBy: String,
    sortDesc: Boolean,
  },

  computed: {
    store: () => useAxisGroupTableStore(),
  },

  methods: {
    sortItems(column) {
      this.$emit('sortItem', column)
    },

    initResize(event, index) {
      this.$emit('initResize', event, index)
    },
  },
}
</script>

<style scoped>
.table-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.sortable {
  cursor: pointer;
}

.header_text {
  position: relative;
  align-items: center;
  background: #666666;
  color: #dddddd;
}

.sorted-asc .header_text::after {
  font-family: 'Material Design Icons';
  content: '\F005D';
}

.sorted-desc .header_text::after {
  font-family: 'Material Design Icons';
  content: '\F0045';
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--v-surface-lighten1);
}
</style>
