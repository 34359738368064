<!-- Deprecated -->
<template lang="pug">
  app-panel
    app-section.m-2
      v-img.image.ma-0.pa-0( :src="image" height="150" )
        template( #placeholder )
          v-img.image.ma-0.pa-0( :src="placeholder" height="150" )
      app-file-input( v-model="preview" :max-size="10000000" :formats="[]" @input="preparePreview" )
      app-text-field( v-model="project.title" :label="$t('project.form.name')" )
      div( style="color: black;" ) TeST: {{ testText }}
      app-text-field( v-model="project.alias" :label="$t('project.form.key')" )
      app-textarea( v-model="project.description" :label="$t('project.form.description')" outlined )

    app-section.mt-2
      v-row(no-gutters)
        v-col
          v-icon(tag="button" size="32" color="#959595" v-show="!toggleOffset" @click.native="toggleOffset = !toggleOffset" :title="$t('project.setProjectCenter')") center_focus_strong
          v-icon(tag="button" size="32" color="#959595" v-show="toggleOffset" @click.native="toggleOffset = !toggleOffset" :title="$t('button.hide')") center_focus_weak
          span.header-title.text-center.ml-2(v-show='toggleOffset' :style="`color:${lighten3()}`") {{ $t('project.setCoordsProjectCenter') }}
      v-row(no-gutters)
        v-col
          <div v-show='toggleOffset'>
            app-text-field(v-model="project.offsetX" :label="$t('project.form.coordX')" :suffix="$t('project.form.suffix')")
            app-text-field(v-model="project.offsetY" :label="$t('project.form.coordY')" :suffix="$t('project.form.suffix')")
            app-text-field(v-model="project.offsetZ" :label="$t('project.form.coordZ')" :suffix="$t('project.form.suffix')")
          </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SourcePath } from '@app/SourcePath'

export default {
  name: 'ProjectForm',
  
  props: {
    project: Object
  },

  data () {
    return {
      toggleOffset: false,
      preview: null,
      counter: 0,
    }
  },

  computed: {
    image () {
      return SourcePath.preview(this.project.preview)
    },

    placeholder () {
      return '/img/common/bim_default_preview_2.jpeg'
    },

    testText () {
      return this.project.title
    }
  },

  methods: {
    ...mapActions('project', ['setProjectPreview']),

    preparePreview() {
      if (this.preview == null) return
      this.setProjectPreview({ uuid: this.project.uuid, file: this.preview }).then(() => {
        this.preview = null
        this.counter++
      })
    },

    lighten3() {
      if (localStorage.getItem("themeName") === "grey") return this.$vuetify.theme.themes.dark.surface.base
      return this.$vuetify.theme.themes.dark.surface.lighten3
    },
  }
}
</script>