<template lang="pug">
  .div
    //v-data-table( light dense :headers="headers" :items="data" )

    v-simple-table( light height="524px" fixed-header)
      template( v-slot:default )
        thead
          tr
            th( v-for="item, index in headers" :class="index>0 ? 'text-center' : 'text-left'") {{item.text}}
        tbody
          tr( v-for="item in dataChild" )
            td( v-for="i, index in item" :style="i.err ? errStyle : ''" :class="index>0 ? 'text-center' : ''")
              template(v-if="index>0")
                span.text-nowrap(v-if="i.data.calc" :style="i.data.deltaCollisions>0 ? plusStyle : i.data.deltaCollisions<0 ? minusStyle : ''")  
                  | {{i.data.countCollisions}} / 
                  span(v-if="i.data.deltaCollisions>0") +
                  | {{i.data.deltaCollisions}}
                span(v-else) -
              template(v-else) {{i.text}}

    div.clr.my-2 {{ $t('section.collision.quantityIncrement') }}

</template>

<script>

export default {

  props: {
    stat: Array
  },

  computed: {
    plusStyle () {
      let theme = this.$vuetify.theme.themes.dark
      return {
        fontWeight: "bold",
        color: theme.alert.base,
      }
    },

    minusStyle () {
      let theme = this.$vuetify.theme.themes.dark
      return {
        fontWeight: "bold",
        color: theme.success,
      }
    },

    errStyle () {
      let theme = this.$vuetify.theme.themes.dark
      return {
        borderBottom: "3px solid " + theme.alert.base,
      }
    },

    headers () {
      let res = []
      res.push({ text: this.$t('section.structure.model.titlematerials'), value: 'name' })
      for(let i = 0; i < this.stat.length; i++) {
        if(!this.stat[i].breakFlag && this.stat[i].endFlag)
          res.push({ text: this.stat[i].calcDate, value: 'c' + i })
      }
      return res
    },

    data () {
      let res = []
      for(let r = 0; r < this.stat[0].rules.length; r++) {
        let row = {}
        row.name = this.stat[0].rules[r].title
        for(let i = 0; i < this.stat.length; i++) {
          if(!this.stat[i].breakFlag && this.stat[i].endFlag) {
            let err =  this.stat[i].errorFlag ?  this.$t('plugin.error') : '' 
            row["c"+i] = this.stat[i].rules[r].countCollisions + "/" + this.stat[i].rules[r].deltaCollisions + err
          }
        }
        res.push(row)
      }

      return res
    },

    dataChild () {
      let res = []
      for(let r = 0; r < this.stat[0].rules.length; r++) {
        let row = []
        row.push({ text : this.stat[0].rules[r].title })
        for(let i = 0; i < this.stat.length; i++) {
          if(!this.stat[i].breakFlag && this.stat[i].endFlag) {
            row.push({ data : this.stat[i].rules[r], err : this.stat[i].errorFlag })
          }
        }
        res.push(row)
      }
      return res
    }

  }
}
</script>

<style lang="scss" scoped>
.text-nowrap {
  white-space: nowrap;
}
.clr{
  color: var(--v-surface-lighten1);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}

/* mozilla firefox */
@-moz-document url-prefix() {
  .v-data-table__wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--v-accent-base) var(--v-scroll-base)
  }
}
</style>