<template lang="pug">
div
  app-dialog( v-model="visible" v-if="visible" width="40%" :header="$t('plugin.uploadPluginTemplate')" @close="clearPluginTemplate" :closeOnConfirm="false" :confirmText="$t('button.export')" @confirm="exportPlugin" )
    app-text-field( v-model="pluginTemplate.name" :label="$t('plugin.pluginTemplate.label.name')" )
    app-textarea.mb-4( v-model="pluginTemplate.description" hideDetails outlined :label="$t('plugin.pluginTemplate.label.description')" noResize height="100")
    app-text-field( v-model="pluginTemplate.fileName" :label="$t('plugin.pluginTemplate.label.fileName')" )

  app-dialog-confirm(v-model="confirm" v-if="confirm" @confirm="confirmExport()")
    app-text {{ $t('plugin.pluginTemplate.existTemplate') }}

</template>

<script>
import { pluginService } from '@/_services'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      visible: false,
      confirm: false,
      plugin: null,

      existPluginTemplate: null,

      pluginTemplate: {
        name: null,
        description: null,
        scriptContent: null,
        fileName: null,
        scriptUuid: null,
      },
    }
  }, 

  computed: {
    ...mapState('plugins', ['loadedPlugin', 'pluginsTemplate']),
  },

  methods: {
    ...mapActions('plugins', ['getPluginsTemplate']),
  
    show() {
      this.visible = true
      this.getPluginsTemplate()
      this.pluginTemplate.name = this.loadedPlugin.name
      this.pluginTemplate.description = this.loadedPlugin.description
    }, 

    clearPluginTemplate() {
      this.pluginTemplate.name = null
      this.pluginTemplate.description = null
      this.pluginTemplate.scriptContent = null
      this.pluginTemplate.fileName = null
      this.pluginTemplate.scriptUuid = null
    },

    exportPlugin() {
      this.pluginTemplate.scriptUuid = this.loadedPlugin.uuid
      this.existPluginTemplate = this.pluginsTemplate.find(plugin => plugin.fileName === this.pluginTemplate.fileName)
      
      if (this.existPluginTemplate) this.confirm = true
      else this.confirmExport(false)
    },

    confirmExport(isNeedUpdate = true) {
      if (this.existPluginTemplate && !this.pluginTemplate.description)
        this.pluginTemplate.description = this.existPluginTemplate.description

      pluginService.exportPlugin(this.pluginTemplate, isNeedUpdate).then(() => {
        this.clearPluginTemplate()
        this.confirm = false
        this.visible = false
        this.existPluginTemplate = null
      })
    }
  }
}
</script>