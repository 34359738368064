<template lang="pug">
  profile-form(v-model="$_show" :user="formUser" :title="$t('registration.title')"  isRegistration @click="registration()" @close="handleClose" :noDialog="noDialog" )
</template>

<script>
import { mapGetters } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable'
import { api } from '@/api'
import ProfileForm from '@/components/admin/users/ProfileForm'
import { AlertService } from '@app/AlertService'
import { authUserService } from '@/_services/'

export default {
  name: 'AccessRegistation',

  components: {
    ProfileForm,
  },

  props: {
    email: {
      type: String,
      default: ''
    },
    noDialog: {
      default: false,
      type: Boolean
    },
  },

  mixins: [DialogToggable],

  data () {
    const defaultForm = Object.freeze({
      uuid:	null,
      email: null,
      name: null,
      phone: null,
      archive: null,
      password: null,
    })
    
    return {
      infoText: "",
      formUser: Object.assign( {}, defaultForm),
      defaultForm,
    }
  },

  watch: {
    $_show (value) {
      if (value) {
        this.formUser = Object.assign( {}, this.defaultForm)
        let registerUser = localStorage.getItem("registerUser")
        if (registerUser) this.formUser = JSON.parse(registerUser)
      }
    },
  },

  mounted () {
    if (this.email) this.formUser.email = this.email
  },
  
  computed: {
    ...mapGetters('roles', ['roles']),
  },
  
  methods: {
    registration () {
        let obj = {
          profile: this.formUser,
          password: this.formUser.password
        }

        api.users.registration(obj).then( () => {
          if (window.settings.server.registrationEmail) {
            this.infoText = this.$t('section.сonfiguration.linkSent', { emil: this.formUser.email })
            AlertService.success({ info: this.infoText })
          } 
          else {
            localStorage.setItem("profile", "")//data
          }

          this.$metrika.reachGoal('REGISTRATION', {user_email: this.formUser.email })
          this.$_show = false
          this.login()
        })
        .catch ( () => {
          localStorage.setItem("registerUser", JSON.stringify(this.formUser) )
          this.$_show = true
          AlertService.error({ data: { error_description: "Пользователь с таким e-mail уже зарегистрирован" } })
        })
    },

    login () {
      localStorage.removeItem("registerUser")
      authUserService.redirectLoginAccount("register")
    },

    handleClose() {
      this.$emit('close')
    }
  },
  
}
</script>