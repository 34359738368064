import { api } from '@/api'
import { fetchProjectUuid } from './project.dataProvider'

export async function fetchGroupPaletes () {
	let groupPalettes = await api.groupPalette.fetchGroupPaletes()
	return groupPalettes
}

export async function fetchGroupPaletesByGroupUUID (groupId) {
  let groupPalettes = await api.groupPalette.fetchGroupPaletesByProjectAxisId(groupId)
  return groupPalettes
}

export async function saveGroupPalette (palette) {
  palette.project = fetchProjectUuid()
  let groupPalette = await api.groupPalette.saveGroupPalette(palette)
  return groupPalette
}

export async function editGroupPaletteItem (item) {
  let groupPaletteItem = await api.groupPalette.editGroupPaletteItem(item)
  return groupPaletteItem
}

export async function deleteGroupPaleteWithUUID (paletteId) {
  return await api.groupPalette.deleteGroupPalete(paletteId)
}