import { defineStore } from 'pinia'
import { groupsPalettes, topLevelElements, saveGroupPalette } from '@/facades/groupPalette.facade'

import { colorAdapter } from '@/adapters/color.adapters.js'

import _ from 'lodash'

export const useGroupPaletteListState = defineStore('group-palette-list-state', {
  state: () => ({
    palette: null,
    isChanged: false
  }),

  actions: {
    resetPalette() {
      this.palette = null
    },

    fetchGroupPaletteList (groupUUID) {
      if (this.palette && this.palette.projectAxis === groupUUID) return this.palette

      let palette = groupsPalettes(groupUUID)
      if (!palette) palette = this.fetchTopLevelElements(groupUUID)
      palette.key = 'Цвет по умолчанию'
      this.palette = _.cloneDeep(palette)

      return this.palette
    },

    fetchTopLevelElements (groupUUID) {
      let paletteItems = topLevelElements(groupUUID).map(item => {
        let uuid = item.uuid
        let key = item.name || item.classTitle || 'Без названия'
        let color = null
        return { elementUuid: uuid, key, color }
      }) 

      let palette = {
        color: null,
        projectAxis: groupUUID,
        paletteItems
      }
      
      return palette
    },

    resetColor (el) {
      this.palette.paletteItems = this.palette?.paletteItems?.map(item => {
        if (el.key === item.key) item.color = null
        return item
      })
      this.findDifferencePalette()
    },

    // Обновить все цвета палитры
    generateColorsForPalette () {
      let palette = this.palette

      if (!palette) return

      let groupUUID = palette.projectAxis
      palette.color = colorAdapter.generateColorByUUIDAndDate(groupUUID)
      palette.paletteItems?.map(item => {
        let uuid = item.uuid || item.elementUuid
        item.color = colorAdapter.generateColorByUUIDAndDate(uuid)
        return item
      })
      this.findDifferencePalette()

      this.palette = palette
    },

    // Обновить все цвета палитры
    generateEmptyColorsPalette () {
      let palette = this.palette
      if (palette.color === null) {
        let groupUUID = palette.projectAxis
        this.palette.color = colorAdapter.generateColorByUUIDAndDate(groupUUID)
      }

      palette?.paletteItems?.map(item => {
        if (item.color === null) {
          let uuid = item.uuid || item.elementUuid
          item.color = colorAdapter.generateColorByUUIDAndDate(uuid)
        }
        return item
      })
      this.findDifferencePalette()

      this.palette = palette
    },

    // Сбросить цвета рутовых элементов палитры
    resetColorsPalette () {
      this.palette.paletteItems = this.palette?.paletteItems?.map(item => {
        item.color = null
        return item
      })
      this.findDifferencePalette()
    },

    async saveGroupPaletteGroupHasChanged () {
      if (this.palette && this.findDifferencePalette()) {
        this.saveGroupPalette(this.palette)
      }
    },

    async saveGroupPalette () {
      this.isChanged = false
      await saveGroupPalette(this.palette)
      await this.fetchGroupPaletteList(this.palette.projectAxis)
    },

    findDifferencePalette () {
      let isChanged = false
      let palette = _.cloneDeep(this.palette)
      let groupUUID = palette.projectAxis || null
      if (groupUUID) {
        let paletteOrig = groupsPalettes(groupUUID)

        if (!paletteOrig && palette && palette.color) {
          isChanged = true
        }
        else if (paletteOrig && palette.color !== paletteOrig.color) {
          isChanged = true
        }
        else if (palette.paletteItems.length > 0 && palette.paletteItems.find(item => item.color) && !paletteOrig.paletteItems){
          isChanged = true
        } 
        else if (palette.paletteItems && paletteOrig.paletteItems && palette.paletteItems.length === paletteOrig.paletteItems.length) {
          let findItem = palette.paletteItems?.find(item => {
            return paletteOrig.paletteItems.find(itemOrig => item.key === itemOrig.key && item.color !== itemOrig.color)
          })
          isChanged =  findItem || false
        }
        
      }

      this.isChanged = isChanged ? true : false

      return isChanged
    },

    setChanged () {
      this.isChanged = true
    },

    // fetchGroupPaletteList () {
      // return {
      //   uuid: null,
      //   profile: null,
      //   project: null,
      //   projectAxis: null,
      //   color: 'black',
      //   paletteItems: [
      //     {
      //       uuid: null,
      //       key: "title 1", 
      //       color: "green"
      //     },
      //     {
      //       uuid: null,
      //       key: "title 2", 
      //       color: "blue"
      //     },
      //     {
      //       uuid: null,
      //       key: "title 3", 
      //       color: "grey"
      //     },
      //     {
      //       uuid: null,
      //       key: "title 4", 
      //       color: "red"
      //     },
      //     {
      //       uuid: null,
      //       key: "АбдурахманАлиАбыХатабОглыБульбаТарасМалодойУсатыйЛысый", 
      //       color: "yellow"
      //     },
      //   ]
      // }
    // },
  }
})