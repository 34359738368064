import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=3bccb293&scoped=true&lang=pug&"
import script from "./ColorPicker.vue?vue&type=script&lang=js&"
export * from "./ColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=3bccb293&prod&scoped=true&lang=css&"
import style1 from "./ColorPicker.vue?vue&type=style&index=1&id=3bccb293&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bccb293",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VColorPicker,VMenu,VTextField})
