<template lang="pug">
  v-tabs-items(
    :value="value"
    :active-class="activeClass"
    :continuous="continuous"
    :dark="dark"
    :light="light"
    :mandatory="mandatory"
    :max="max"
    :multiple="multiple"
    :next-icon="nextIcon"
    :prev-icon="prevIcon"
    :reverse="reverse"
    :show-arrows="showArrows"
    :show-arrows-on-hover="showArrowsOnHover"
    :tag="tag"
    :touch="touch"
    :touchless="touchless"
    :value-comparator="valueComparator"
    :vertical="vertical"

    @change="handleChange" 
    )
    slot
</template>

<script>
  export default {
    name: 'app-tabs-items',

    props: {
      value: null,
      activeClass: {
        default: 'v-window-item--active',
        type: String,
      },
      continuous: Boolean,
      dark: {
        default: true,
        type: Boolean,
      },
      light: Boolean,
      mandatory: Boolean,
      max: [Number, String],
      multiple: Boolean,
      nextIcon: {
        default: '$next',
        type: [Boolean, String],
      },
      prevIcon: {
        default: '$prev',
        type: [Boolean, String],
      },
      reverse: Boolean,
      showArrows: Boolean,
      showArrowsOnHover: Boolean,
      tag: {
        default: 'div',
        type: String,
      },
      touch: Object,
      touchless: Boolean,
      valueComparator: Function,
      vertical: Boolean,
    },

    methods: {
      handleChange(event) {
        this.$emit('change', event)
      },
    },
  }
</script>
