<template lang="pug">
div.ml-4.base-scroll-body(style="overflow-x: hidden")
  app-button-add.mt-3.ml-1.mr-4( width="100%" :text="$t('viewer.tools.addDirLight')" @click="addDirLight()" )

  .mt-2( v-for="light in lights" )
    v-row.mt-1
      v-col.py-2.align-self-center( cols = 10 )
        .caption
          .colorLight.mr-2( v-if="light.deleted" :id="`color-${light.id}`" :style="`background-color: rgb(${ light.defaultColor });`" @click='showColorDirLight(light)' )
          span( v-if="light.type === 'AmbientLight'" ) {{$t('module.statistic.projects.externalLighting')}} ({{ light.intensity }} )
          span( v-else-if="light.type === 'DirLight'" ) {{$t('module.statistic.projects.lightSource') }} ({{ light.intensity }} )
          span( v-else ) {{$t('module.statistic.projects.lightSource')}}

    v-row.mt-0( v-if="light.deleted" )
      v-col.py-1( cols = 10)
        input.mt-1( type="range" :id="light.id" :value="light.intensity" min="0" max="1" step="0.1" @change="setIntensityLight(light)" style="width:100%" )
      v-col.py-1.align-self-start( cols = 2 )
        v-tooltip( right open-delay=300 )
          template( v-slot:activator="{ on, attrs }" )
            v-btn.mr-2( x-small icon test-id="dir-light-clear-btn" @click='destroyDirLight(light)' v-on="on" )
              v-icon( small color="red" ) clear
          span {{ $t('button.delete') }}
    div.mr-4( v-else )
      input.mt-1( :id="light.id" :value="light.intensity" type="range" min="0" max="1" step="0.1" @change="setIntensityLight(light)" style="width:100%" )

  app-dialog( v-model="dialog.settingColor" width="300" :header="$t('profile.action.items.colorSetting')" :confirmText="$t('button.save')"
              @confirm="setColorLight(currentLight)" @close="dialog.settingColor = false" )
    v-color-picker( v-model="lightColor" mode="rgba" )

</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator.js'

export default {
  name: "BimLightsSettings",
  
  data () {
    return {
      dialog: {
        settingColor: false
      },
      currentLight: null,
      lightColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1
      }
    }
  },

  computed: {
    viewer () {
      return XeokitMediator.viewer
    },

    lightSettings () {
      return XeokitMediator.BimLights.lightSettings
    },

    sceneLights () {
      return XeokitMediator.viewer.scene.lights
    },

    lights () {
      let filteredLightSettings = this.lightSettings.filter(l => {
        l.deleted = !l.id.startsWith("__")
        l.defaultColor = l.color.map(c => Math.round(c * 255)).toString()
        return l
      })
      if (filteredLightSettings.length > 0) return filteredLightSettings

      return Object.values(this.sceneLights)
    }
  },

  methods: {
    showColorDirLight (light) {
      this.currentLight = light
      let [r, g, b] = light.color.map(val => Math.round(val * 255))
      this.lightColor = {r, g, b}
      this.dialog.settingColor = true
    },

    setColorLight (light) {
      XeokitMediator.BimLights.setColorLight(light, this.lightColor)
    },

    setIntensityLight (light) {
      light.intensity = document.getElementById(light.id).value
      XeokitMediator.BimLights.updateSettings(light)
    },

    addDirLight() {
      XeokitMediator.BimLights.addDirLight()
    },

    destroyDirLight (light) {
      XeokitMediator.BimLights.destroyDirLight(light)
    }
  }
}
</script>

<style scoped>
.colorLight {
  width: 12px; 
  height: 12px; 
  display: inline-block;
  border: 1px solid black;
  cursor: pointer;
}

.panel-control-light {
  position: absolute; 
  bottom: 0; 
  left: 60px;
}

.section-control-light {
  max-height: 250px; 
  width: 250px; 
  overflow-y: auto; 
  overflow-x: hidden;
}
</style>
