<template lang="pug">
div
  expansion-button( :buttonHeader="$t('patch.list.replaced')" )  
    app-text
      v-tooltip( bottom )
        template( v-slot:activator="{ on, attrs }" )
          div( v-on="on" style="font-weight: 700; cursor: pointer;" @click="selectEditedElements()" ) {{ $t('patch.list.saved') + ' : ' + savedEditedElementsCount}}
        span {{ isEditedElementsChanged ? $t('patch.list.highlightSaved') : $t('patch.list.resetSelected') }}
      div.mt-1 {{ $t('patch.list.elementsToAdd') + ": " + addedElementCount }}
      div.mt-1 {{ $t('patch.list.elementsToDelete') + ": " + removedElementCount}}

      .d-flex.justify-end( v-if="isEditedElementsChanged && hasProjectReconstruction" style="width: 100%" )
        v-btn.mt-1( color="accent" dense x-small outlined @click="saveEditedElements()" ) {{$t("section.structure.model.revisionMoving.save")}}
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import ExpansionButton from '../../components/ExpansionButton.vue';
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name: "PatchElementList",
  
  components: {
    ExpansionButton
  },

  computed: {
    ...mapState('patches', ['selectedPatch']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction"]),

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    isEditedElementsChanged() {
      return JSON.stringify(this.selectedElements || []) !== JSON.stringify(this.selectedPatch.elements)    
    },

    savedEditedElementsCount() {
      return this.selectedPatch?.elements?.length || 0
    },

    addedElementCount() {
      let addedCount = 0
      if(this.hasProjectReconstruction) {
        this.selectedElements?.forEach((uuid) => {
          if (!this.selectedPatch?.elements?.includes(uuid)) {
            addedCount++
          }
        })
      }
      return addedCount
    },
    
    removedElementCount() {
      let removedCount = 0
      if(this.hasProjectReconstruction) {
        this.selectedPatch.elements?.forEach((uuid) => {
          if (!this.selectedElements?.includes(uuid)) {
            removedCount++
          }
        })
      }
      return removedCount
    }
  },

  methods: {
    ...mapMutations('patches', ['setEditedElements']),
    ...mapActions('patches', ['saveEditedPatch', 'editPatchElements']),
    
    saveEditedElements() {
      this.setEditedElements(this.selectedElements)
      this.saveEditedPatch(this.selectedPatch)
      this.editPatchElements({patchUuid: this.selectedPatch.uuid, elements: this.selectedElements})
    },

    selectEditedElements() {
      if (this.isEditedElementsChanged && this.hasProjectReconstruction) {
        XeokitMediator.ElementsSelection.selectElements(this.selectedPatch.elements)
      }
      else {
        XeokitMediator.ElementsSelection.selectElements([])
      }
    }
  }
};
</script>