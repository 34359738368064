<template lang="pug">
div(v-if="hasTaskCreateCommentAttach")
  task-quote-create-section( v-if="quotedComment" :comment="quotedComment" style='border: 1px solid red;' @cancelQuote="$emit('cancelQuote')")
  .t-comment.text-right.pa-1
    .t-comment-input(test-id="task-create-comment-section")
      task-comment-markdown-textarea(
        ref="comment"
        v-model="comment" :label="$t('module.task.writeMessage')" background-color="#FFFFFF" color="#6B6B6B" 
        rows="2"
        hide-details light solo flat dense onlyEditor
        @click="createComment" notBlur focusIcoButtons
        noResize
        scrollHide
        :height="63"
      )
        template(v-slot:focusIcoButtons)
          task-button-action.ml-1(v-if="!$route.path.includes('task/manager')" icon="project/tools/add_a_photo.svg" size=16 @click="takeScreenshotToTask" test-id="task-create-comment-section-screenshot")
          task-button-action.ml-1(v-if="hasTaskDownloadFile" icon="project/tools/attach_file.svg" size=16 @click="showAttachmentDialog" test-id="task-create-comment-attach")
    
    //- Добавить вложение
    task-comment-attachment-dialog( v-model='dialog.attachment' :task='selectedTask' )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaskQuoteCreateSection from '@/components/task/taskDetailsSections/taskComments/TaskQuoteCreateSection.vue'
import TaskCommentAttachmentDialog from './TaskCommentAttachmentDialog.vue'
import { TaskScreenshot } from '../../taskScreenshot'
import { useTaskScreenshotComment } from '@/pinia/taskScreenshotComment.js'
import { useTaskScreenshotStore } from "@/pinia"
import TaskCommentMarkdownTextarea from '@/components/task/taskDetailsSections/taskComments/TaskCommentMarkdownTextarea.vue';
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  name: "TaskCreateCommentSection",

  components: {
    TaskQuoteCreateSection,
    TaskCommentAttachmentDialog,
    useTaskScreenshotComment,
    TaskCommentMarkdownTextarea,
    TaskButtonAction
  },

  props: {
    selectedTask: Object,
    quotedComment: Object,
  },

  data() {
    return{
      taskScreenshotComment: useTaskScreenshotComment,
      comment: "",
      dialog: {
        attachment: null,
        comment: "",
      },
    }
  },

  mounted () {
    if (this.$refs.comment!==undefined)
    this.$refs.comment.$el.addEventListener('paste', this.pasteFile)
  },

  updated() {
    if(this.taskScreenshotComment.status) {
      this.comment = this.taskScreenshotComment.comment
      this.taskScreenshotComment.status = false
    }
  },

  computed: {
    ...mapGetters('projectPermissions', ['hasTaskCreateCommentAttach', 'hasTaskDownloadFile']),

    taskScreenshotStore: () => useTaskScreenshotStore(),
  },

  methods:{
    ...mapActions('task', ['addComment', 'addAttachment', 'addAttachmentWithCam']),

    pasteFile (event) {
      let data = event.clipboardData || event.originalEvent.clipboardData

      if (data.getData('text') != false) return
      if (data.items.length == 0 || data.files.length == 0) return

      let first = data.items[0]

      if (first.kind === 'file' && first.type.startsWith('image/')) {
        var file = first.getAsFile()
        if (file) {

          let sourceData = {
            comment: this.comment
          }
          // this.addAttachment({ uuid: this.selectedTask.uuid, name: file.name || 'image.png', file })
          this.addAttachmentWithCam({ uuid: this.selectedTask.uuid, name: file.name || 'image.png', file, sourceData: JSON.stringify(sourceData) })
          event.preventDefault()
        }
      }
    },

    createComment() {
      if(this.comment != '') {
        this.addComment({ uuid: this.selectedTask.uuid, text: this.comment, quotedCommentUuid: this.quotedComment ? this.quotedComment.uuid : null })
      }
      this.comment = ""
      this.$emit('cancelQuote')
    },

    async takeScreenshotToTask() {
      this.taskScreenshotStore.setCreateScreenshotStatus(true)
      await TaskScreenshot.showCreateTaskScreenshotDialog({
        taskUuid: this.selectedTask.uuid,
        screenshotComment: this.comment
      })
      this.taskScreenshotComment.comment = this.comment
      this.taskScreenshotStore.setCreateScreenshotStatus(false)
    },

    showAttachmentDialog() {
      this.dialog.attachment = true
    },
  }
}
</script>

<style>
.t-comment {
  background: #E5E5E5;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 3px 3px;
  display: flex; 
  flex-direction: column;
}

.t-comment-input {
  background: #FFFFFF;
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 3px;
}
</style>