import { useSearchElementsStore } from "@/pinia/searchElements.module"

export class SearchElementsScroller {
  constructor() {
    this.startIndex = 0
    this.step = 1
    this.elementHeight = 28
    this.firstRowHeight = 0
    this.lastRowHeight = 0
    this.timeoutScroll = null
    this.timeoutGetElements = null
    this.store = useSearchElementsStore()
  }

  initScroll(topHeight, elementsLength) {
    const tableTop = topHeight;
    const viewPortY = document.documentElement.clientHeight;

    if (tableTop > 0) {
      this.step = Math.floor((viewPortY - tableTop) / this.elementHeight);
    } else {
      this.step = Math.floor(viewPortY / this.elementHeight);
      this.startIndex = Math.floor(-tableTop / this.elementHeight);
    }

    this.firstRowHeight = this.startIndex * this.elementHeight;
    this.lastRowHeight = elementsLength * this.elementHeight - this.step * this.elementHeight;
  }

  calculateScroll(topHeight, elementsLength) {
    const top = topHeight
    const viewportY = document.documentElement.clientHeight;
    if (top < 0) {
      this.step = Math.floor(viewportY / this.elementHeight);
      this.startIndex = Math.floor(-top / this.elementHeight);
    } else {
      this.startIndex = 0;
      this.step = Math.floor((viewportY - top) / this.elementHeight);
    }

    this.firstRowHeight = this.startIndex * this.elementHeight;
    this.lastRowHeight = elementsLength * this.elementHeight - this.step * this.elementHeight - this.firstRowHeight;
    this.lastRowHeight = this.lastRowHeight > 0 ? this.lastRowHeight : 0
  }

  handleScroll(topHeight, elementsLength, scrollPos) {
    this.timeoutScroll && clearTimeout(this.timeoutScroll)
    this.timeoutGetElements && clearTimeout(this.timeoutGetElements)

    this.timeoutScroll = setTimeout(() => {
      this.calculateScroll(topHeight, elementsLength)
    }, 15)

    this.timeoutGetElements = setTimeout(() => {
      this.#_getElementsByPage(topHeight, scrollPos)
    }, 25)
  }

  #_getElementsByPage(topHeight, scrollPos) {
    if (topHeight > scrollPos) {
      this.store.searchElementsFilter.page = Math.floor(this.startIndex / this.store.searchElementsFilter.pageSize)
    } else {
      this.store.searchElementsFilter.page = Math.round(this.startIndex / this.store.searchElementsFilter.pageSize)
    }
    let totalPages = Math.round(this.store.currentElements.length / this.store.searchElementsFilter.pageSize)
    if (this.store.searchElementsFilter.page <= totalPages) {
      this.store.fetchElements()
    }
  }
}