<template lang='pug'>
div
  div.mnemoschemas-list
    mnemoschema-row( v-for='schema in list' :schema='schema' :key='schema.uuid' @click.native='select(schema)' @action='handleMnemoschemaAction($event, schema)')   
    create-schema-dialog(v-model='showMnemoschemaEditDialog' :schema='selectedSchema')
    delete-schema-dialog(v-model='showMnemoschemaDeleteDialog' :schema='selectedSchema' @deleteMnemo='deleteMnemo')

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CreateSchemaDialog from './CreateSchemaDialog'
import DeleteSchemaDialog from './DeleteSchemaDialog'
import MnemoschemaRow from './MnemoschemaRow'
import GraphViewer from './GraphViewer'

const action = {
  EDIT: 'edit',
  DELETE: 'delete',
  OPEN: 'open'
}

export default {
  name: 'TempMnemoschemasList',

  components: {
    MnemoschemaRow,
    CreateSchemaDialog,
    DeleteSchemaDialog,
    GraphViewer,
  },

  data: () => ({
    showMnemoschemaEditDialog: false,
    showMnemoschemaDeleteDialog: false,

    newSchemaTitle: '',
    selectedSchema: null,
    selectedSchemaStyle: false,
  }),
  
  computed: {
    ...mapState('mnemoschema', ['list', 'selectedMnemoschema']),
    ...mapGetters('project', ['projectUuid']),
  },

  methods: {
    ...mapActions('mnemoschema', ['updateList']),
    ...mapMutations('mnemoschema', ['setSelectedMnemoschema']),


    handleMnemoschemaAction(actionType, schema) {
      this.selectedSchema = schema

      switch (actionType) {
        case action.EDIT: {
          this.openSchemaEditDialog()
          break
        }
        case action.DELETE: {
          this.confirmSchemaDelete()
          break
        }
        case action.OPEN: {
          this.openGraphEditor()
        }
      }
    },

    openSchemaEditDialog() {
      this.showMnemoschemaEditDialog = true
    },
   
    confirmSchemaDelete() {
      this.showMnemoschemaDeleteDialog = true
    },

    openGraphEditor() {
      window.open(`/mnemo/project/${this.projectUuid}/schema/${this.selectedSchema.uuid}`)
    },

    select(schema) {  
      this.setSelectedMnemoschema(schema)
      this.$emit('selectMnemoschema', schema)
    },

    deleteMnemo(deletedSchema) {
      this.$emit('deleteMnemo',deletedSchema)
    }
  }
}
</script>

<style scoped>
  .mnemoschemas-list {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
</style>