<template lang="pug">
  app-dialog(v-model="$_show" width="630" :header="$t('section.equipment.form.equipment')" :confirm-text="$t('button.save')" @confirm="saveEquipment(equipment)" :confirmDisabled='!valid' @close='close') 
    v-form(ref="saveEquipmentForm" v-model="valid" @submit.prevent="submit")
      app-select( v-if="equipment.uuid != null" v-model="equipment.equipmentGroup" :items="preparedGroups" item-value="uuid" :label="$t('section.equipment.form.group')" return-object)
      app-text-field.mt-2( v-model="equipment.name" :rules="[rules.required]" :label="$t('corp.title')" hide-details)
      app-autocomplete.my-2(v-if="isEquipmentsLoaded" v-model="equipment.equipmentSpecification" :items="equipments" item-text="name" :label="$t('corp.typeEquipment')" item-value="uuid" return-object :rules="[rules.requiredSimple]")
      app-text-field.mt-2( v-else :rules="[rules.required]" :label="$t('corp.typeEquipment')" hide-details )
      app-text-field.mt-2( v-model="equipment.model" :label="$t('section.equipment.details.model')" hide-details )
      app-text-field.mt-2( v-model="equipment.manufacturer" :label="$t('section.equipment.details.manufacturer')" hide-details )

      v-row.mt-2(no-gutters)
        v-col
          app-section
            v-menu(
              ref="menu1"
              :close-on-content-click="false" :return-value.sync="equipment.warrantyStartDate"
              transition="scale-transition" offset-y min-width="auto"
            )
              template(v-slot:activator="{ on }")
                v-text-field(
                  v-model="equipment.warrantyStartDate" :label="$t('section.equipment.details.warrantyStartDate')" 
                  prepend-icon="mdi-calendar" readonly
                  v-on="on" light clearable hide-details
                )
              v-date-picker( v-model="equipment.warrantyStartDate" first-day-of-week=1 no-title scrollable @input="$refs.menu1.save(equipment.warrantyStartDate)")
          
        v-col.ml-2
          app-section
            v-menu(
              ref="menu2"
              :close-on-content-click="false" :return-value.sync="equipment.operationStartDate"
              transition="scale-transition" offset-y min-width="auto"
            )
              template(v-slot:activator="{ on }")
                v-text-field(
                  v-model="equipment.operationStartDate" :label="$t('section.equipment.details.startDateOperation')" 
                  prepend-icon="mdi-calendar" readonly :rules="[rules.required]"
                  v-on="on" light clearable hide-details
                )
              v-date-picker( v-model="equipment.operationStartDate" first-day-of-week=1 no-title scrollable @input="$refs.menu2.save(equipment.operationStartDate)")

      v-row.mt-2(no-gutters)
        v-col
          app-text-field( v-model="equipment.productionYear" :rules="[rules.number]" :label="$t('section.equipment.details.yearProduction')" hide-details)
        v-col.ml-2
          app-text-field( v-model="equipment.serialNumber" :rules="[rules.required]" :label="$t('section.equipment.details.serialNumber')" hide-details)
        v-col.ml-2
          app-text-field( v-model="equipment.reestrNumber" :rules="[rules.required]" :label="$t('section.equipment.details.registrationNumber')" hide-details)

      app-textarea.mt-2( v-model="equipment.location" :label="$t('section.equipment.details.installationLocation')" height=100 outlined hide-details)

</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { $_validate_rules } from '@/common/ValidateRules'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'EquipmentForm',
  mixins: [$_validate_rules, DialogToggable],
  props: {
    equipment: null,
  },
  data () {
    return {
      valid: false,
      loaded: false,
    }
  },
  mounted() {
    if(this.projectOrgId != null)
      this.$store.dispatch('corp/LOAD_ORG_EQUIPMENTS', this.projectOrgId)
  },
  computed: {
    ...mapGetters('project', ['projectOrgId']),
    ...mapGetters('corp', [ 'equipments', 'isEquipmentsLoaded']),
    ...mapState('maintenance', ['tree']),

    preparedGroups () {
      return this.tree.flatlist.filter(i => i.isGroup).map(original => {
        let m = { ...original }
        let fulltext = []
        for (let i = 0; i < m.level; i++) fulltext.push('| - ')
        fulltext.push(m.title)
        m.text = fulltext.join('')
        return m;
      })
    }

  },
  methods: {
    ...mapActions('maintenance', ['saveEquipment']),

    submit(){
      this.$refs.saveEquipmentForm.validate()
    },

    close(){
      this.$emit('close')
    },
  },
}

</script>
