<template lang='pug'>
app-dialog( v-model='$_show' :header='$t("module.task.time.editDate")' :confirmText='$t("button.save")' :confirm-disabled='disabled' @confirm='handleConfirm()')
  app-section
    v-menu( ref='start' :return-value.sync='start' transition='scale-transition' min-width='auto' offset-y )
      template( v-slot:activator='{ on }' )
        v-text-field( v-model='start' v-on='on' :label='$t("module.task.time.startDate")' prepend-icon='mdi-calendar' readonly light clearable test-id="task-date-start")
      v-date-picker( v-model="start" :max="end" first-day-of-week=1 no-title scrollable @input="$refs.start.save(start), onStartDateInput()")
    
    v-menu( ref='end' :return-value.sync='end' transition='scale-transition' min-width='auto' offset-y )
      template( v-slot:activator='{ on }' )
        v-text-field( v-model='end' v-on='on' :label='$t("module.task.time.endDate")' prepend-icon='mdi-calendar' readonly light clearable )
      v-date-picker( v-model="end" :min="start" first-day-of-week=1 no-title scrollable @input="$refs.end.save(end), onEndDateInput()" )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
export default {
  name: "TaskDatesIntervalDialog",

  mixins: [DialogToggable],

  props: {
    task: Object
  },

  data() {
    return {
      start: null,
      end: null,
    }
  },

  computed: {
    disabled() {
      if (!this.start || !this.end) return true

      const start = new Date(this.start).getTime()
      const end = new Date(this.end).getTime()

      return start > end
    },

    startMs() {
      return this.start ? new Date(this.start).getTime() : null
    },

    endMs() {
      return this.end ? new Date(this.end).getTime() : null
    }
  },

  watch: {
    $_show() {
      // this.start = this.task?.startDate && new Date(this.task.startDate).toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(".").reverse().join("-")
      this.start = this.task?.startDate.split(' ')[0]
      // this.end = this.task?.endDate && new Date(this.task.endDate).toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(".").reverse().join("-")
      this.end = this.task?.endDate.split(' ')[0]
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm', this.start, this.end)
    },

    onStartDateInput() {
      this.startMs > this.endMs ? this.end = new Date(this.start).toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(".").reverse().join("-") : null
    },

    onEndDateInput() {
      this.endMs < this.startMs ? this.start = new Date(this.end).toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' }).split(".").reverse().join("-") : null
    },
  },
}
</script>

<style>

</style>