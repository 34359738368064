<template lang='pug'>
  app-dialog(v-model='show' :header='$t(headerTitle)')
    div.d-flex.flex-column
      app-section(v-if='localReceiver')
        div.d-flex.flex-column
          v-progress-linear(v-if='isLoading' indeterminate color='accent')
          div.mb-1(v-else)

          app-text-field.mt-2(v-model='localReceiver.name' :label="$t('module.mnemoschemas.receivers.dataSource.name')" outlined hide-details)
          app-select.mt-2(v-model='localReceiver.receiverClass' :label="$t('module.mnemoschemas.receivers.receiverProtocol')" outlined :items='receiverClassList' item-text="title")
          
          v-hover(v-slot:default='{ hover }')
            div.d-inline-block.relative.mt-2
              app-textarea.custom-scrollbar(v-model='localReceiver.properties' :label="$t('module.mnemoschemas.receivers.receiverOptions')" outlined no-resize light hide-details)
              div
                v-tooltip( bottom )
                  template( v-slot:activator="{ on }" )
                    v-fade-transition
                      v-btn.surface.lighten-3(v-if='hover && !localReceiver.properties' v-on="on" icon small absolute bottom right @click='localReceiver.properties = receiverPropertiesExample')
                        v-icon(color='var(--v-primary-darken2)' small) mdi-text-box-outline
                  span Вставить шаблон
                
          app-select.mt-2(v-model='localReceiver.converterClass' :items='converterList' :label="$t('module.mnemoschemas.receivers.dataConverter')" item-text="title" outlined light)
      
      app-button.mt-2.ml-auto(:disabled='!isReceiverValid || !isReceiverClass || !isConverterClass || isSaving' color='accent' @click='save()') {{ $t('button.save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

const defaultReceiver = {
  name: null,
  project: null,
  properties: null,
  receiverClass: null,
  converterClass: null,
}

const receiverPropertiesExample = `{
  'url': 'tcp://url:port', 
  'username': '', 
  'password': '', 
  'topics': 'your topic name'
}`

export default {
  name: "CreateReceiverDialog",

  props: {
    receiver: Object,
    value: Boolean
  },

  data: () => ({
    localReceiver: null,
    isLoading: false,
    isSaving: false,

    receiverPropertiesExample,
  }),

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapState('receiver', ['receiverClassList', 'converterList']),

    show: {
      get() {
        this.value && this.init()
        return this.value
      },

      set() {
        this.close()
      }
    },

    headerTitle() {
      return this.receiver?.uuid ? 'module.mnemoschemas.receivers.receiverEditing' : 'module.mnemoschemas.receivers.receiverCreating'
    },

    isReceiverValid() {
      return !!(this.localReceiver?.name)
    },

    isReceiverClass() {
      return !!(this.localReceiver?.receiverClass)
    },

    isConverterClass() {
      return !!(this.localReceiver?.converterClass)
    },
  },

  methods: {
    ...mapActions('receiver', ['saveReceiver']),

    init() {
      this.localReceiver = this.receiver ? { ...this.receiver } : { ...defaultReceiver }
    },

    async save() {
      if (this.isSaving) {
        return;
      }
      
      this.isSaving = true;
      this.isLoading = true;
      await this.saveReceiver({...this.localReceiver, project: this.projectUuid});
      this.isLoading = false;

      this.isSaving = false; 
      this.close();
    },

    close() {
      this.$emit('input', false)
    },

    receiverClassSelectComparator(a, b) {
      return +a === +b
    }
  }
}
</script>

<style scoped>
.custom-scrollbar ::-webkit-scrollbar {
  background-color: var(--v-scroll-base);
  border-radius: 4px;
  width: 8px;
}
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
  border-radius: 4px;
}
</style>