<template lang="pug">
div
  .selected-page-header
    .selected-page-title( :title='store.selectedDrawingPage.name' ) {{ store.selectedDrawingPage.name }}
    DrawingPageHeaderActions
  DrawingCroppingControl(v-if="store.isCroppingModeActive")
</template>

<script>
import DrawingPageHeaderActions from '@/components/drawings/page/DrawingPageHeaderActions.vue';
import { useDrawingsStore } from '@/pinia'

export default {
  name: 'DrawingPageHeader', 
  components: { 
    DrawingPageHeaderActions, 
    DrawingCroppingControl: () => import('@/components/drawings/cropping/DrawingCroppingControl.vue'),
  },

  computed: {
    store: () => useDrawingsStore(),
  },
}
</script>

<style lang="scss" scoped>
.selected-page-header {
  background: #E5E5E5;
  min-height: 32px;
  width: 100%;

  line-height: 32px;
  font-weight: bold;
  
  display: flex;
  padding: 0px 12px;
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  & .control-panel {
    margin-left: auto;
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
.selected-page-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>