<template lang="pug">
  app-dialog( v-model="visible" :header="$t('section.collision.rowMenu.note')" :confirm-text="$t('button.save')" @confirm="saveNote" )
    app-section
      app-text-field( v-model="note" placeholder="Введите заметку" )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      visible: false,
      note: '',
      item: null,
      single: false,
    }
  },

  computed: {
    ...mapGetters('collision/table', ['selectedCollisionsUuid']),

  },

  methods: {
    ...mapActions('collision', ['setNote', 'setNoteToGroup']),

    show ({single, item}) {
      this.item = item
      this.single = single
      this.note = item.displayedNote
      this.visible = true
    },

    saveNote () {
      if (this.single) {
        if (this.item.uuid) {
          this.item.displayedNote = this.note
          this.setNote({ uuid: this.item.uuid, text: this.note })
        }
      }
      else {
        this.setNoteToGroup({ uuids: this.selectedCollisionsUuid, text: this.note })
      }
    },
  }
}
</script>