<template lang="pug">
  .header.surface.primary--text
    v-row.header-row(no-gutters)
      v-col.mx-2.pointer( v-for="(item, idx) in menu" :key="idx" ripple @click="handleSelect(item, idx)" style="flex-grow: unset !important") 
        div.d-flex.align-center.my-2.header-panel-text(:style="item.active ? activeClass : ''" test-id="details-header-item") {{ item.title }}
      v-col(v-if="pluginConsole")
        div.d-flex.justify-end.align-center.ma-2
          app-toolbar( horizontal )
            app-toolbar-button( header size="small" icon="mdi-update" :title="$t('plugin.updateConsole')" @click="$emit('update')" )
            app-toolbar-button( header size="small" icon="mdi-trash-can-outline" :title="$t('plugin.clearConsole')" @click="$emit('clear')" )
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PluginConsoleHeader', 

  data () { 
    return { item: null}
  },
  
  props: {
    menu: Array
  },

  computed: {
    ...mapState('plugins', ['pluginConsole']),
    activeClass() {
      return "width: fit-content; border-bottom: 2px solid var(--v-primary-darken2)"
    },
  },
  
  methods: {
    handleSelect (item, idx) {
      this.item = item 
      if (item.action) item.action()
      else this.$emit('menu:select', item, idx)
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  background: var(--v-surface-lighten1) !important;
  font: normal 12px/14px $roboto;
  font-weight: 700;
}
.header-row {
  border-bottom: 1px solid #C4C4C4;
}
.pointer:hover {
  font-weight: 500;
}
.pointer {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.header-panel-text {
  color: var(--v-primary-darken2) !important;
}
</style>
