<template lang="pug">
  .font
    slot
</template>
<script>

export default {
  name: 'header-text',
}

</script>
<style lang="scss" scoped>
.font {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color:black;
  line-height: 24px;
}
</style>