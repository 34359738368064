<template lang="pug">
  app-dialog(v-model="visible" :header="$t('section.groupingTable.columnSetting')")
    app-section
      v-btn.dict-button.px-1.mb-2( v-if="store.headers.length" color="accent" dense text x-small @click="changeAllColumnsVisible") 
        | {{ allColumnsSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
        v-icon.ml-1( v-if="allColumnsSelected" color="accent" size="16" ) remove_done
        v-icon.ml-1( v-else color="accent" size="16" ) done_all

      draggable.table-settings.base-scroll-body(v-model="store.headers" handle=".table-settings-item_handle" @change="onDragChange")
        .table-settings-item(v-for="header in store.headers" :key="header.value")
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            .column.d-flex.justify-space-between
              v-checkbox(v-model="header.view" :disabled="header.value == 'oname'" :label="$t(header.name)" color="#3B93AF" light dense hide-details @change="onVisibleChange")
              v-icon.table-settings-item_handle(v-if="hover && header.value != 'oname'" @click.stop="removeColumn(header)" light dense) mdi-close
          v-icon.table-settings-item_handle(light dense) mdi-menu
          
      v-btn.mt-2(dense text x-small color="#333333" @click="$emit('addColumn')") 
        v-icon(left size="22") mdi-plus-box-outline
        | {{$t('section.groupingTable.addColumn')}}
</template>

<script>
import draggable from 'vuedraggable'
import { useAxisGroupTableStore } from '@/pinia'
export default {
  components: {
    draggable,
  },

  data() {
    return {
      visible: false,
      allColumnsSelected: false,
    }
  },

  computed: {
    store: () => useAxisGroupTableStore(),
  },

  methods: {
    show() {
      this.visible = true
      this.checkAllSelected()
    },

    onDragChange() {
      this.store.headers.forEach((col, index) => (col.order = index))
      this.store.saveHeaders()
    },

    onVisibleChange() {
      this.store.saveHeaders()
    },

    checkAllSelected() {
      this.allColumnsSelected = this.store.headers.every((column) => column.view)
    },

    changeAllColumnsVisible() {
      this.allColumnsSelected = !this.allColumnsSelected
      this.store.headers.forEach((column) => {
        if (column.value !== 'oname') column.view = this.allColumnsSelected
      })
      this.store.saveHeaders()
    },

    removeColumn(column) {
      this.store.headers = this.store.headers.filter((el) => el.value !== column.value)
      this.store.saveHeaders()
      this.checkAllSelected()
    },
  },
}
</script>
<style lang="scss" scoped>
.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;
}

.table-settings {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.table-settings-item .v-input--selection-controls {
  margin: 0;
}

.table-settings-item {
  display: flex;
  justify-content: space-between;
  color: var(--v-primary-base);
}

.table-settings-item_handle {
  cursor: pointer;
}

.column {
  width: 90%;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

.column .v-input .v-label,
.v-input--is-disabled .v-label--is-disabled {
  color: #4d4d4d !important;
}
</style>
