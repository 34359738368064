<template lang="pug">
  .unit(v-if="!importProjStruct")
    v-tooltip(right open-delay=400 )
      template( v-slot:activator="{ on, attrs }" )
        .unit__offset-marker( v-on="on" :style="{ borderColor: modelGroup.color }" @click="cameraFlyToModelGroup" )
      div( v-if="modelGroup.offset[0] == 'noRevision'" ) {{ $t('section.structure.model.offsetGroup.noRevision') }}
      div( v-else )
        p.mb-1 {{ $t('section.structure.model.offsetGroup.title') }}
        p.mb-0 {{ "x: " + modelGroup.offset[0] + ", " }}
        p.mb-0 {{ "y: " + modelGroup.offset[1] + ", " }}
        p.mb-0 {{ "z: " + modelGroup.offset[2] }}
    v-tooltip( right open-delay=800 )
      template( v-slot:activator="{ on, attrs }" v-if="model.archived == archived || archived == true")
        .unit__row( v-on="on" :style="{ borderColor: modelGroup.color }" )
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            .unit__bar.app-cursor--context( test-id="tree-unit-item" :class="unitBarHoveringStyle(hover)" @click="toggle" @contextmenu.stop="showMenu" ) 
              v-icon.unit__drag( v-if="hover && hasProjectStruct" :color="unitButtonsColor" size="18" ) drag_indicator
              span.unit__title {{ isAlias ? $t(model.title) : model.title }}
              v-icon.unit__action( v-if="hover && checkRole" :color="unitButtonsColor" @click.stop="showMenu" ) more_vert
          v-switch.unit__switch( v-show="showSwitch" v-model="models" :value="model.uuid" color="accent" dense light)
      span {{ isAlias ? $t(model.title) : model.title }} 
  div.unit(v-else)
    v-tooltip( right open-delay=800 )
        template( v-slot:activator="{ on, attrs }")
          .unit__row( v-on="on" :style="{ borderColor: '#ff7070' }" )
            v-hover( v-slot:default="{ hover }" close-delay="150" )
              .unit__bar.app-cursor--context(:style="unitBarColor" :class=" {'elevation-5': hover, 'title-padding': hover}") 
                v-icon.unit__drag( v-if="hover && hasProjectStruct && !disabled && !model.model" :color="unitButtonsColor" size="18" ) drag_indicator
                span.unit__title {{$t(model.nameModel? model.nameModel: model.title )}}
        span {{ $t(model.nameModel? model.nameModel: model.title) }} 
  
</template>

<script>
import { useModelOffsetGroupsStore } from '@/pinia/modelOffsetGroups/modelOffsetGroups.store';
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProjectToolTreeUnit',

  props: {
    archived: {
      default: false,
      type: Boolean
    },
    model: Object,
    importProjStruct: {
      default: false,
      type: Boolean
    },
    disabled: Boolean
  },

  computed: {
    ...mapState('project', ['selectedModel', 'projectSettings', 'compareRevisions']),
    ...mapGetters('project', ['topUnit']),
    ...mapGetters('project', ['flatlist']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction", "hasProjectStruct", "hasProjectStruct", "hasProjectAddAttribution", "hasProjectDeleteModel", "hasProjectControlArchive", "hasModelDownloadRevision", "hasProjectSendToArchive" ]),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('axis/tree', ['element']),

    unitBarColor() {
      return {
        'backgroundColor': this.model.projectStructureDTO? 'var(--v-surface-lighten1)' : 'var(--v-primary-darken2)',
        'color': this.model.projectStructureDTO? 'var(--v-primary-darken2)'  : 'var(--v-surface-lighten1)',
      }
    },
    modelOffsetGroupsStore: () => useModelOffsetGroupsStore(),

    modelGroup() {
      return this.modelOffsetGroupsStore.groups[this.model.uuid]
    },

    checkRole() { 
      return this.hasProjectControlArchive || this.hasProjectSendToArchive || this.hasProjectStruct || this.hasProjectDeleteModel || (this.model.model.length > 0)
    },

    unitButtonsColor() {
      return this.isActive ? 'var(--v-primary-darken2)' : 'var(--v-surface-lighten2)'
    },

    models: {
      get() {
        let l = []
        Object.keys(this.projectSettings.workModel).forEach(modelUUID => {
          if (this.projectSettings.workModel[modelUUID].switchon) l.push(modelUUID)
        });
        return l;
      },
      set(value) {
        if (value.includes(this.model.uuid) && this.model.model.length > 0) {
          value = this.showChild(value, this.model.model.filter(el => !el.archived))
        } else {
          value = this.hideChild(value, this.model.model.filter(el => !el.archived))
        }
        this.SET_ELEMENT()

        this.updateWorkModels(value).then( () => {
          if(this.topUnit === 'elementTree') {
            this.fetchTree()
          }
        })
      }
    },
 

    isAlias() {
      return this.model.title.indexOf("notify.") != -1
    },

    isActive() {
      return this.selectedModel && this.selectedModel.uuid === this.model.uuid
    },

    isArchived() {
      return this.model.archived
    }, 

    showSwitch() {
      return this.archived && !this.model.archived ? false : true
    },

    parentTitle() {
      if (this.archived && this.model.stack.length > 1) { 
        let parent =  this.flatlist.find(m => m.uuid == this.model.stack[this.model.stack.length-2])
        return parent !== undefined && !parent.archived ? parent.title + " / " : ""
      } 
      else return ""
    },
  },
  
  methods: {
    ...mapMutations('project', ['setSelectedModel', 'setCompareRevisions']),
    ...mapActions('project', ['updateWorkModels', 'makeModelVisible']),
    ...mapMutations('project', ['setTopUnit']),
    ...mapMutations('axis/tree', ['SET_ELEMENT']),
    ...mapActions('project', ['updateWorkModels', 'makeModelVisible', 'postPanelsStatus']),
    ...mapActions('axis/tree', ['fetchTree']),


    unitBarHoveringStyle(hover) {
      return {
        'unit-active': this.isActive,
        'unit-archived': this.isArchived,
        'archive-unit-notarchived': !this.showSwitch,
        'elevation-5': hover, 
        'title-padding': hover 
      }
    },

    clearCompareRevisions() {
      if (this.compareRevisions) {
          XeokitMediator.ModelsManager.setModelsVisible(XeokitMediator.viewer.scene.modelIds, true)
          XeokitMediator.ModelsManager.setModelsXrayed(XeokitMediator.viewer.scene.modelIds, false)
          this.setCompareRevisions(null)
        }
    },

    async toggle() {
        this.clearCompareRevisions()
        
        if (this.isActive) {
          await this.setSelectedModel(null)
        } 
        else {
          this.setSelectedModel(this.model)
        }
    },

    showMenu(event) {
      this.$emit('menu', event, this.model)
    },

    showChild(list, models) {
      if (models.length > 0) {
        models.map(item => {
          if (!this.archived && !item.archived) {
            list.push(item.uuid)
            list = this.showChild(list, item.model)
          }
        })
      }
      return list
    },

    hideChild(list, models) {
      if (models.length > 0) {
        models.map(item => {
          if (!this.archived && !item.archived) {
            var index = list.indexOf(item.uuid)
            if (index !== -1) list.splice(index, 1)
            list = this.hideChild(list, item.model)
          }
        })
      }
      return list
    },

    cameraFlyToModelGroup() {
      XeokitMediator.CameraFlight.flyToProjectModels(this.modelGroup.groupUuids)
    }
  }
}
</script>

<style lang="scss" scoped>
.unit {
  display: flex;
  align-items: center;
  max-width: 100%
}

.unit__offset-marker {
  border-inline-start-width: 10px;
  border-inline-start-style: solid;
  margin-top: 8px;
  border-radius: 4px;
  height: 32px;
  width: 4px;
  min-width: 0;
  cursor: pointer;
}

.unit__row {
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 8px;
  flex-wrap: nowrap;
  display: flex;
  width: 100%
}

.unit__switch {
  margin-left: 8px;
  margin-top: 0px;
  margin-right: 4px;
}

.unit__bar {
  display: grid;
  position: relative;
  margin-left: -6px;
  padding: 8px;
  width: 100%;
  height: 32px;
  background-color: var(--v-surface-lighten1);
  border-radius: 5px;
  color: var(--v-primary-darken2);
  font: normal 12px/18px $roboto;
  min-width: 0;
}

.unit__title {
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unit-active {
  background-color:  var(--v-surface-base);
  border: 1px solid var(--v-surface-lighten2);
  color: var(--v-primary-darken2);
  font-weight: bold;
}

.unit__action {
  position: absolute;
  right: -2px;
  top: 5px;
  cursor: pointer;
}

.unit__drag {
  position: absolute;
  left: 0;
  top: 7px;
  cursor: move;
}

.unit-archived {
  color: var(--v-primary-darken2);
}

.archive-unit-notarchived {
  background-color: var(--v-surface-lighten1);
  color: var(--v-primary-darken2);
  margin-right: 44px;
  &.unit-active {
    background-color:  var(--v-surface-base);
    border: 1px solid var(--v-surface-lighten2);
    color: var(--v-primary-darken2);
    font-weight: bold;
  }
}

.title-padding {
  padding-left: 16px;
}
</style>