<template lang="pug">
div
  v-tooltip( bottom )
    template( v-slot:activator="{ on, attrs }" )
      app-icon-action( v-on="on" icon="$add-box-icon" color="var(--v-primary-darken2)" @click="dialog.addIFCFile = !dialog.addIFCFile" )
    span {{ $t('patch.dialog.import') }}
  app-dialog( v-model="dialog.addIFCFile" :header="setHeader" :confirmText="$t('button.save')" :confirmDisabled="!valid" @confirm="addModelPatch()" @close="closeModelPatch" )
    app-section
      v-form(ref='form' v-model='valid')
        app-file-input(v-model="IFCFile" :max-size="10737418240" :formats="['.ifc','.ifczip']" :rules='[rules.requiredSimple]')
    //- v-row(no-gutters)
      v-col.text-left
        app-button.dialog-confirm-action.mt-3(@click="closeModelPatch" color="var(--v-surface-lighten1)") {{ $t('button.cancel') }}
      v-col.text-right
        app-button.dialog-confirm-action.mt-3(:disabled='!valid' @click="addModelPatch()") {{ $t('button.save') }}
</template>

<script>
import nextCloudTree from '@/components/project/panel/left/components/cloud/nextCloudTree'
import { mapState, mapActions, mapMutations } from 'vuex'
import { $_validate_rules } from '@/common/ValidateRules'

export default {
  name: 'PatchImportFile',

  mixins: [$_validate_rules],

  components: {
    nextCloudTree
  },

  data() {
    return {
      IFCFile: null,
      dialog: {
        addIFCFile: false, 
      },

      valid: false,
    }
  },

  computed: {
    ...mapState('patches', ['selectedPatch']),

    setHeader() {
      if(this.selectedPatch.file === null) return this.$t('section.structure.model.addRevision')
      if(this.selectedPatch.file) return this.$t('patch.dialog.changeFile')
      return this.$t('section.structure.model.addRevision')
    },
  },

  beforeDestroy() {
    this.$emit("close")
  },

  methods: {
    ...mapActions('patches', ['editPatchFile', 'getPatch']), 
    ...mapMutations('patches', ['setSelectedPatch']), 
    
    openAddingIFCFileDialog() {
      this.dialog.addIFCFile = true
    },
    
    addModelPatch() {
      this.editPatchFile({patchUuid: this.selectedPatch.uuid, file: this.IFCFile})
      this.IFCFile = null
      this.dialog.addIFCFile = false
      this.resetForm()
      this.$emit('uploadPatch', true)
      let token = setInterval( () => {
        this.getPatch(this.selectedPatch.uuid).then(data => {
          if(data.state.value === 1)  {
            this.$emit('uploadPatch', false)
            clearInterval(token);
          }
        })
      }, 4000)
    },

    closeModelPatch() {
      this.resetForm()
      this.IFCFile = null 
      this.dialog.addIFCFile = false
    }, 

    resetForm() {
      this.$refs.form.reset()
    },
  }
}
</script>