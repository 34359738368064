<template lang="pug">
v-menu(ref="date" :return-value.sync="date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto")
  template(v-slot:activator="{ on }")
    v-text-field(:label="label" v-model="date" prepend-icon="mdi-calendar" readonly dense v-on="on" light :hideDetails="true" clearable style="margin:10px 0 10px 0;")
  v-date-picker(v-model="date" @input="$refs.date.save(date)" @change="saveValue()" first-day-of-week=1 no-titleItems scrollable)
</template>

<script>
export default {
  name: 'wj-date-picker',

  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    item: null,
  },

  data() {
    return {
      date: this.item,
    }
  },

  mounted() {
    this.date = this.item
  },

  watch: {
    item: 'checkValue'
  },

  methods: {
    saveValue() {
      this.$emit('saveValue', this.date, this.name)
    },

    checkValue() {
      if (this.item && this.item != this.date)
        this.date = this.item
    }
  }
}
</script>

<style>

</style>