<template lang="pug">
  v-row.h100p(no-gutters)
    v-col.h100p(cols=6)
      app-panel( padding="0px")
        app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
          app-floating-panel(ref="floatingPanel" :menu="treeMenu" :title="$t('module.mnemoschemas.receivers.channel.equipment')" bodyMinHeight="150" overflowBody="hidden" 
            :settings="floatingPanels('MAINTENANCE_TREE')")
            .base-scroll-body
              equipment-tree(ref="equipmentTree" @calcTO="calcTO")

          app-floating-panel( v-if="element" :title="$t('section.element', {name: element.name})" bodyMinHeight="150" overflowBody="hidden" 
            :settings="floatingPanels('MAINTENANCE_ELEMENT_DETAILS')")
            element-details.py-0( :element="element" @loadElements="loadElements()")

    v-col.h100p(cols=6)
      app-panel( padding="0px")
        app-floating-panels-section.base-scroll-body.pl-2( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
          app-floating-panel( :title="equipmentTitle" bodyMinHeight="150" overflowBody="hidden" 
            :settings="floatingPanels('MAINTENANCE_EQUIPMENT_DETAILS')")
            .base-scroll-body
              equipment-details(ref="equipmentDetails" @edit="editEquipment()" :equipment="selectedEquipment")

          app-floating-panel( :title="$t('section.documents')" bodyMinHeight="150" overflowBody="hidden" 
            :settings="floatingPanels('MAINTENANCE_LINKED_ELEMENTS')")
            .base-scroll-body
              linked-elements.py-0(ref="linkedElements" v-if="selectedEquipment" :equipment="selectedEquipment")

          app-floating-panel( :title="$t('section.journal.serviceHistory')" bodyMinHeight="150" overflowBody="hidden" 
            :settings="floatingPanels('MAINTENANCE_HISTORY')")
            .base-scroll-body
              maintenance-history(ref="maintenanceHistory")

          app-floating-panel( :title="$t('section.journal.incomingData')" bodyMinHeight="75" overflowBody="hidden"
            :settings="floatingPanels('MAINTENANCE_DATA')")
            .base-scroll-body
              equipment-channels-data

          app-floating-panel( :title="$t('section.journal.warningLog')" bodyMinHeight="75" overflowBody="hidden" 
            :settings="floatingPanels('MAINTENANCE_ALERTS')")
            .base-scroll-body
              equipment-warnings(ref="equipmentWarnings" :equipment="selectedEquipment")
  
    app-dialog-confirm(v-model="dialog.confirmCalcTO" @confirm="confirmCalcTO")
      app-section
        app-text {{ $t('section.equipment.maintenance.confirmCalcTO') }}
    
            
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { api } from "@/api"

import ElementDetails from '../components/element/Details.vue'
import EquipmentTree from './components/EquipmentTree.vue'
import EquipmentDetails from './components/EquipmentDetails.vue'
import LinkedElements from './components/LinkedElements.vue'
import EquipmentWarnings from './components/EquipmentWarnings.vue'
import MaintenanceHistory from './components/MaintenanceHistory.vue'
import EquipmentChannelsData from './components/EquipmentChannelsData.vue'

export default {
  components: {
    ElementDetails,
    EquipmentTree,
    EquipmentDetails, 
    MaintenanceHistory,
    EquipmentWarnings,
    EquipmentChannelsData,
    LinkedElements,
  },

  data: () => ({
    dialog: {
      confirmCalcTO: false
    },
    allTO: false
  }),

  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('axis/tree', ['element']),
    ...mapState('maintenance', ['selectedEquipment']),

    ...mapGetters('project', ['projectUuid']),

    ...mapGetters('projectPermissions', ['hasMaintainceEquipment', 'hasMaintainceCalculateTO']),

    treeMenu () {
      let menu = []
      if (this.hasMaintainceEquipment) {
        menu.push(
          { title: this.$t('button.add'), action: () =>  this.openEquipmentTree() }
        )
      }
      if (this.hasMaintainceCalculateTO) {
        menu.push(
          { title: this.$t('section.journal.calculategraph'), action: () => this.calcTO() }
        )
      }

      if (menu.length > 0) return menu

      return null
    },

    equipmentTitle() {
      return this.selectedEquipment ? this.selectedEquipment.name : this.$t('module.task.info')
    }
  },

  methods: {
    loadData() {
      this.$refs.equipmentWarnings?.getEventsByFilter()
      this.$refs.maintenanceHistory?.loadTasks()
    },

    editEquipment(){
      this.$refs.equipmentTree.editEquipment()
    },

    loadElements() {
      this.$refs.linkedElements.loadElements()
    },

    calcTO(all = true) {
      this.allTO = all
      this.dialog.confirmCalcTO = true
    },

    confirmCalcTO() {
      if( !this.allTO && this.selectedEquipment ) {
        api.maintenance.calcTO(this.selectedEquipment.uuid).then(() => {
          this.$refs.maintenanceHistory.loadTasks()
        })
      } else {
        api.maintenance.calcAllTO(this.projectUuid).then(() => {
          this.$refs.maintenanceHistory.loadTasks()
        })
      }
    },

    openEquipment(item) {
      this.$refs.equipmentTree.openEquipment(item)
    },

    openEquipmentTree() {
      // if (!this.$refs.floatingPanel.openedPanel) this.$refs.floatingPanel.openBody()
      this.$nextTick(() => {
        this.$refs?.equipmentTree?.addRootGroup()
      })
    }
  }
}
</script>

<style scoped>

.t-scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-primary-lighten4);
}

.h100p {height: 100%;}
</style>

