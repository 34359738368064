<template lang="pug">
div
  v-menu(v-model="showMenu" :position-x="x - 205" :position-y="y" tile transition="slide-x-transition" offset-y :close-on-click="closeOnClick" :close-on-content-click="false" rounded="lg" style="border-radius: none !important;")
    .m-content 
      .m-content-body
        template(v-for="(item, index) in filterMainList")
          .m-items-content(v-if="!checkTopUnit(item)" :class="{'borders': item.name=='FORTHISPROJECT', 'divider': !topUnit && index == 5 }")
            .m-content-text(@click="handleSelect(item)")
              v-list-item-title {{ item.title }} 
            .m-content-checkbox(v-if="item.checked != null || item.checked != undefined" @click="handleSelect(item, 'checkbox')")
              v-checkbox(id="checkbox" v-model="item.checked" hide-details light)
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "NotificationFilterMenu",
  
  props: {
    menu: null,
  },

  data: () => ({ 
    showMenu: false, 
    moving: false, 
    x: 0, 
    y: 0, 
    closeOnClick: true,
    filterMainList: null,
  }),

  mounted() {
    this.filterMainList = this.menu
  },

  computed: {
    ...mapState('project', ['topUnit']),
  },

  methods: {
    checkTopUnit(item) {
      return item.name == "FORTHISPROJECT" && !this.topUnit
    },

    handleSelect (option, string) {

      if(!string && (option.checked != null || option.checked != undefined)) {
        option.checked = !option.checked
      }

      this.$emit('selectFilter', option.name)
    },

    show (e) {

      if(e.type == "click") this.closeOnClick = false
      e.preventDefault()
      this.moving = true
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.moving = false
        this.showMenu = true

        if(e.type == "click") {
          setTimeout(()=>{
            this.closeOnClick = true
          }, 100)
        }
      })

    },
  }
};
</script>

<style scoped>
.borders {
  margin: 10px 0;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
}
.m-content {
  padding: 2px;
  border-radius: 0px;
  background-color: #D0D0D0;
}
.m-content-body {
  border-radius: 3px;
  background-color: #F0F0F0;
}
.m-items-content {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
}
.m-items-content:hover {
  border-radius: 3px;
  background: #e5e5e5;
}
.m-content-text {
  width: 80%;
  font-size: 14px;
  color: #757575;
}
.m-content-checkbox {
  margin-left: 5px;
  color: #434343;
  outline:#434343
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-input__slot {
  margin-bottom: 0 !important
}
.divider {
  border-top: 1px solid #C4C4C4;
}
</style>