<template lang="pug">
  app-dialog(v-model="$_show" width="600" complex) 
    v-row(no-gutters)
        v-col(cols="8")
           app-section-external-header {{ $t('section.iot.device') }}
        v-col.pl-2(cols="4")
           app-section-external-header {{ $t('section.iot.view') }}
    v-row(no-gutters)
        v-col(cols="8")
           app-section.h-100
             draggable(v-model="form.items")
               v-row(no-gutters v-for="(row, index) in form.items" :key="index")
                 v-col
                   app-text-field( v-model="row.device.title+' / '+row.deviceParam.name" label="" readonly)
                 v-col(cols="auto")
                   v-icon.mt-2( color="grey" @click.stop="removeItem(index)" ) clear
                 v-col.pt-2(cols="auto")
                   v-icon.unit-drag( color="grey" ) list

             .d-inline-flex.align-center.add-container.mt-4( @click="showWidgetItem" )
               v-icon.icon-wrapper( size="18" ) add
               .section-header.ml-3 {{ $t('section.iot.addDevice') }}

        v-col.pl-2(cols="4")
           app-section.h-100
             app-text-field.select-maxw(v-model="form.title" :label="$t('section.iot.name')")
             app-select.select-maxw.mb-4(v-model="form.size" :items="widgetSize" :label="$t('section.iot.size')" item-text="title" )
             app-select.select-maxw(v-model="form.sort" :items="sort" :label="$t('section.iot.order')" )


    v-row(no-gutters)
        v-col.text-right
           app-button.mt-3(@click="saveWidget" :disabled="!valid") OK

    app-dialog(v-model="dialogItem" width="600" complex) 
      v-row(no-gutters)
          v-col
             app-section-external-header {{ $t('section.iot.device') }}
          v-col.pl-2
             app-section-external-header {{ $t('section.iot.parameter') }}
      v-row(no-gutters)
          v-col
             app-section.h-100
               app-text(v-for="item in devices" :key="item.uuid") 
                  div(:style="widgetItem.device && widgetItem.device.uuid == item.uuid ? activeClass : ''" @click="selectDevice(item)") {{ item.title }}
    
          v-col.pl-2
             app-section.h-100
               app-text(v-for="item in deviceParam" :key="item.uuid")
                   div(:style="widgetItem.deviceParam && widgetItem.deviceParam.uuid  == item.uuid ? activeClass : ''" @click="selectParam(item)") {{ item.name }}
    
      v-row(no-gutters)
          v-col.text-right
             app-button.mt-3(@click="addWidgetItem" :disabled="!itemValid") OK

</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { iotService } from '@/_services'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'CreateDeviceWidget',
  mixins: [DialogToggable],
  components: { draggable },
  props: {
    widget: null,
  },
  data () {
    const defaultWidget = Object.freeze({
      uuid: null,
      project: null,
      title: "",
      sort: 1,
      size: null,
      items: []
    })

    const defaultWidgetItem = Object.freeze({
      uuid: null,
      device: null,
      deviceParam: null,
      sort: 1,
    })

    return {
      form: Object.assign({}, defaultWidget),
      defaultWidget,
      widgetItem: Object.assign({}, defaultWidgetItem),
      dialogItem: false
    }
  },
  watch: {
    $_show (value) {
      if (value) {
        let baseObj = this.widget || this.defaultWidget
        this.form = JSON.parse(JSON.stringify(baseObj))
      }
    },
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('iot', ['widgetSize', 'devices']),
    activeClass() {
      return "font-weight: 500; color: "+this.$vuetify.theme.themes.dark.accent.base
    },
    deviceParam() {
      if(this.widgetItem.device){
        let dev = this.devices.find(d => d.uuid == this.widgetItem.device.uuid)
        return dev === undefined ? [] : dev.deviceReestr.deviceParam
      } else  return []
    },
    sort() {
      return Array(10).fill().map((e, i) => i + 1)
    },
    itemValid() {
      if( this.widgetItem.device!=null && this.widgetItem.deviceParam!=null) return true
      else return false
    },
    valid() {
      if( this.form.title!=null && this.form.items!=null && this.form.items.length>0 && this.form.size!=null && this.form.sort!=null) return true
      else return false
    },
  },
  methods: {
    saveWidget() {
      if(this.form.project == null) this.form.project = this.projectUuid
      this.$emit('created', this.form)
    },
    selectDevice(item){
      this.widgetItem.device = item
      this.widgetItem.deviceParam = null
    },
    selectParam(item){
      this.widgetItem.deviceParam = item
    },
    showDeviceData(d, p) {
      this.showData=false
      this.device = d
      this.param = p
      iotService.getDeviceData(d.uuid, p.uuid).then(data => {
        this.dt = data
        this.showData=true
      })
    },
    showWidgetItem() {
      this.widgetItem.device = null
      this.dialogItem=true
    }, 
    addWidgetItem() {
      this.dialogItem=false
      this.form.items.push(Object.assign({}, this.widgetItem))
    },
    removeItem(index) {
       this.form.items.splice(index, 1)
    },
  },
  mounted() {
    this.$store.dispatch('iot/init', this.projectUuid)
  }
}
</script>

<style lang="scss" scoped>
.add-container {
  cursor: pointer;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
}
.section-header {
  font: normal 12px/16px $roboto;
  color: #3B93AF;
}
.icon-wrapper {
  background-color: #C4C4C4;
  border-radius: 2px;
}

.h-100 {
  height:100%;
  max-height:300px;
  min-height:300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.select-maxw {
  max-width:173px;
}

</style>
