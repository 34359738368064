<template lang="pug">
.mnemoschemas-list-container
    app-section.mt-2.schemas-section
      .mnemoschemas( v-for='(mnemoschemas, index) in currentMnemoschemasList' )
        .groups( :style='mnemoschemasTitleStyle(index)')
            .d-flex.flex-column(v-for='group in mnemoschemas.groups')
              //- drawing-row( :drawing="group.drawing" )
              mnemoschemas-row( :drawing="group.drawing" )
          
      .empty-page(v-if="currentMnemoschemasList.length == 0")
          .no-one-mnemoschemas
            v-btn.empty-drawing-button(color='accent' small text  @click='openEditor(projectUuid)')
              span {{ $t('module.mnemoschemas.addMnemonic') }}
              v-icon(small) mdi-paperclip
    .add-new-mnemoschema(v-if='currentMnemoschemasList.length > 0')
      v-btn.ml-auto.mr-5(color='#4d4d4d' @click='openEditor(projectUuid)' small fab) 
        v-icon mdi-plus
</template>

<script>
import DrawingRow from '@/components/drawings/DrawingRow'
import MnemoschemasRow from './MnemoschemasRow'
import { mapState, mapGetters } from 'vuex'
import { useDrawingsStore } from '@/pinia';
export default {
  name: "MnemoschemasList",

  components: {
    DrawingRow,
    MnemoschemasRow
  },

  mounted() {
    console.log(this.currentMnemoschemasList);
    // this.$root.$emit('drawingsByModelsInit', this)
  },

  computed: {
    ...mapState('process', ['processes', 'uploads']),
    ...mapGetters('project', ['projectUuid']),

    store: () => useDrawingsStore(),

    currentMnemoschemasList() {
    if(this.store.searchFilter) {
      if(typeof this.store.searchFilter == 'string' && this.store.searchFilter.length > 0)
        return this.store.getSearchValidPages.filter(model => model.groups.length > 0)
    }

    return this.store.modelsWithGroups.filter(model =>
      model.groups.length > 0 || this.uploads.find(upload => model.uuid == upload.modelUuid) != null
    )
  },

  },

  methods: {
    mnemoschemasTitleStyle(index) {
      if(index % 2 == 0) {
        return 'background: #F8F8F8'
      } 
      return 'background: #FFFFFF'
    },

    modelValidPages(model) {
      return model.pages.filter(page => page.included && page.version.uuid == model.drawing.currentVersion.uuid)
    },

    mnemoschemasVisible(mnemoschemas){
      if(!mnemoschemas.visible) {
        mnemoschemas.groups.map(group => group.getPages(group.drawing.currentVersion).then(() => {
          mnemoschemas.visible = true
        }))
      }
      else {
        mnemoschemas.visible = false
      }
    },

    openEditor(projectUuid, schemaUuid) {
      let route = this.$router.resolve({ path: `/mnemo/project/${projectUuid}/schema/${schemaUuid}` })
      window.open(route.href)
    }
  }

}
</script>

<style>

</style>
<style scoped>
.mnemoschemas-list-container{
  height: 100%;
  position: relative;
  color: #1B252C;
}
.schemas-section{
  height: 100%;
}
.empty-page {
  color: #757575;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  height: calc(100% - 0px);
  justify-content: center;
  line-height: 40px;
}
.no-one-mnemoschemas {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
}
.empty-drawing-button {
  border: 1px dashed;
}
.magnify{
  position: absolute;
  right: 4px;
}
.add-new-mnemoschema {
    position: sticky;
    bottom: 20px;
    display: flex;
  }

</style>