<template lang="pug">
  .relative
    .absolute.l--100
      canvas#myNavCubeCanvas
</template>

<script>
import { NavCubePlugin } from '@/plugins/xeokit/NavCubePlugin'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import i18n from '@/plugins/i18n';

export default {
  mounted () {
    XeokitMediator.onReady(this.init)
  },

  methods: {
    init (viewer) {
      new NavCubePlugin(viewer, {
        canvasId: "myNavCubeCanvas",
        visible: true,
        cameraFly: true,
        cameraFitFOV: 45,
        cameraFlyDuration: 0.5,
        fitVisible: true,
        synchProjection: false,
        shadowVisible: false,

        language: i18n.locale,
        frontColor: "#fff",
        backColor: "#fff",
        leftColor: "#fff",
        rightColor: "#fff",
        topColor: "#fff",
        bottomColor: "#fff"
      })
    }
  }
}
</script>

<style scoped>
#myNavCubeCanvas {
  width: 150px;
  height: 150px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.l--100 {
  left: -150px;
}
</style>