<template lang="pug">
.app-toolkit-column
  ToolButtonPluginRuler
  ToolButtonPluginPlane
  ToolButtonPluginCube
  ToolButtonPluginPoint
</template>

<script>
import ToolButtonPluginCube from './buttons/ToolButtonPluginCube.vue'
import ToolButtonPluginPlane from './buttons/ToolButtonPluginPlane.vue'
import ToolButtonPluginPoint from './buttons/ToolButtonPluginPoint.vue'
import ToolButtonPluginRuler from './buttons/ToolButtonPluginRuler.vue'

export default {
  components: {
    ToolButtonPluginCube,
    ToolButtonPluginPlane,
    ToolButtonPluginPoint,
    ToolButtonPluginRuler,
  },
}
</script>