<template lang="pug">
v-slider.ma-0.pa-0( :value='store.scaleDrawing'
                    :min='25' :max='200' :step='25'
                    height='28' track-color="#4D4D4D" color='#4D4D4D'
                    style='width: 84px;'
                    dense hide-details
                    @input='handleScaleChange($event)' )
</template>

<script>
import { useDrawingsStore } from '@/pinia';
import { scaleSelectedPage } from '../../facades/drawingPageListeners.facade';

export default {
  name: 'DrawingScaleSlider', 

  computed: {
    store: () => useDrawingsStore(),
  },

  methods: {
    handleScaleChange(value) {
      this.store.setScaleDrawing(value)
      scaleSelectedPage(true)
    },
  }
}
</script>