<template lang='pug'>
  div.ma-2
    app-text
      v-row(v-for='channel in equipmentChannels' :key='channel.uuid' no-gutters)
        v-col.text-truncate() {{ channel.title }}
        v-col.text-truncate(cols='auto') {{ channel.data || '-' }}
</template>

<script>
import { telemetryService } from '@/_services/telemetry.service'
import { mapState } from 'vuex'
import { Telemetry } from '@/utils'

export default {
  name: "EquipmentChannelsData",

  props: {
    equipment: null
  },

  watch: {
    selectedEquipment: 'updateEquipmentChannels'
  },

  data: () => ({
    equipmentChannels: [],
    equipmentChannelsCache: new Map(),
  }),

  computed: {
    ...mapState('maintenance', ['selectedEquipment']),
  },

  beforeMount() {
    this.updateEquipmentChannels()
    
    Telemetry.on('update', this.telemetryUpdateHandler = (data) => {
      const cachedChannel = this.equipmentChannelsCache.get(data.uuid)

      if (!cachedChannel && data.equipment === this.selectedEquipment?.uuid) {
        const channel = {title: data.title, data: data.tData.value, uuid: data.uuid}
        this.equipmentChannels.push(channel)
        this.equipmentChannelsCache.set(channel.uuid, channel)
      }

      if (!cachedChannel) return
      
      cachedChannel.data = data.tData.value
    })

    this.$root.$on('updateEquipmentChannels', (equipmentUuid) => {
      if (this.selectedEquipment?.uuid === equipmentUuid) {
        this.updateEquipmentChannels()
      }
    })
  },

  beforeDestroy() {
    Telemetry.off('update', this.telemetryUpdateHandler)
  },

  methods: {
    async updateEquipmentChannels() {
      if (!(this.selectedEquipment && this.selectedEquipment.uuid)) return

      const channels = await telemetryService.getEquipmentChannels(this.selectedEquipment.uuid)
      channels.forEach(channel => {
        channel.data = this.equipmentChannelsCache.get(channel.uuid)?.data || null
        this.equipmentChannelsCache.set(channel.uuid, channel)
      })
      this.equipmentChannels = channels
    }
  }
}
</script>

<style>

</style>