<template lang="pug">
div
  div.mx-2( :class="checkTasksExist()" )
    div.left-container
      div.align-self-center
        app-icon-action.menu( icon="$task-menu-icon" color="var(--v-primary-darken2)" size="16" @click="openMenu" test-id="menu-btn")
      span.header-text.ml-2 {{ $t('section.collision.attrTable.header') }}

    div.middle-container( v-if="taskId" )
      v-btn( x-small :color="getColorCollision" @click="changeActiveItems(false)" ) {{ $t('section.collision.title') }}
      v-btn.ml-1( x-small :color="getColorTask" @click="changeActiveItems(true)" ) {{ $t('module.task.inTask') }}

    div.right-container
      v-tooltip( bottom )
        template(v-slot:activator="{ on, attrs }")
          v-btn( icon v-bind='attrs' v-on='on' @click="toggleAllGroup(true)")
            v-icon( size="20" color="var(--v-primary-darken2)" ) mdi-arrow-expand-vertical
        span {{ $t("section.collision.table.expandRules") }}

      v-tooltip( bottom )
        template(v-slot:activator="{ on, attrs }")
          v-btn( icon v-bind='attrs' v-on='on' @click="toggleAllGroup(false)")
            v-icon( size="20" color="var(--v-primary-darken2)" ) mdi-arrow-collapse-vertical
        span {{ $t("section.collision.table.collapseGroups") }}  
      
      v-tooltip( bottom )
        template( v-slot:activator="{ on, attrs }" )
          v-btn( icon v-bind='attrs' v-on='on' @click="showSettings" )
            v-icon( size="20" color="var(--v-primary-darken2)" ) settings
        span {{ $t('section.collision.settings') }}

  column-settings-dialog( ref="settings" )

</template>

<script>
import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'
import ColumnSettingsDialog from './dialogs/AttributeCheckingColumnSettings.vue'

export default {
  components: {
    ColumnSettingsDialog,
  },

  computed: {
    ...mapStores(useAttributeCheckingTableStore),

    taskActive() {
      return this.attributeTableStore.task.active
    },
    taskId() {
      return this.attributeTableStore.task.taskId
    },

    getColorCollision() {
      return this.taskActive ? '' : 'accent'
    },
    getColorTask() {
      return this.taskActive ? 'accent' : ''
    },
  },

  watch:{
    taskActive(){
      this.$emit('onChangeActive')
    }
  },

  methods: {
    checkTasksExist() {
      return this.taskId ? 'main-container' : 'main-container-diff'
    },

    openMenu(event) {
      this.$emit('menu', event)
    },

    toggleAllGroup(isOpen) {
      this.attributeTableStore.toggleAllGroup(isOpen)
    },

    changeActiveItems(isTask) {
      if (isTask === this.taskActive) return
      this.attributeTableStore.changeItemsSource(isTask)
    },

    showSettings() {
      this.$refs.settings.show()
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.main-container-diff {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.left-container {
  display: flex;
  justify-content: flex-start;
}

.middle-container {
  display: flex;
  justify-content: center;
}

.right-container {
  display: flex;
  justify-content: flex-end;
}

.menu {
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.header-text {
  font: normal 14px/32px $roboto;
  color: var(--v-primary-darken2);
}
</style>
