<template lang="pug">
  app-dialog(v-if="dialog" v-model="dialog" width="400" :header="$t('section.equipment.maintenance.duty')" :confirm-text="$t('button.save')" @confirm="saveDuty()" :confirmDisabled='confirmDisabled')
    app-section
      app-text-field(v-model="num" :label="$t('section.equipment.maintenance.duty')" dense type="number" :rules="[rules.duty]")
</template>

<script>
import { api } from '@/api'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      num: 0,
      maintenanceTask: null,
      dialog: false,

      rules: {
        duty: (value) => (!!value && value > 0 && value <= Number(this.maxDuty)) || "Смена должна быть в диапазоне от 1 до " + this.maxDuty,
      }
    }
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapState('project', ['projectSettings', 'maintenanceTaskSettings']),

    confirmDisabled() {
      return !this.num || this.num <= 0 || this.num > Number(this.maxDuty)
    },

    maxDuty() {
      return this.maintenanceTaskSettings.numDuty
    },
  },

  methods: {
    saveDuty() {
      if (this.maintenanceTask?.dutyNum != this.num) {
        this.maintenanceTask.dutyNum = this.num
        api.maintenance.editMaintenanceDutyNum(this.maintenanceTask, this.projectUuid).then(() => {
          this.$emit('updateDuty')
        })
      }

      this.dialog = false
    },

    showDialog(mTask, currentDuty) {
      this.maintenanceTask = mTask
      if (mTask.dutyNum) this.num = mTask.dutyNum
      else if (currentDuty) this.num = currentDuty
      else this.num = 0

      this.dialog = true
    },
  },
}
</script>
