<template lang="pug">
div( ref="highlight-active-treeview-container" style="height: 100%" )
  virtual-list.base-scroll-body.v-list(
    :keeps="treeElementKeeps",
    :estimate-size="treeElementHeight"
    data-key="uuid"
    :data-component="highlightActiveGroupItemComponent"
    :data-sources="elements"
    :extra-props="{ toggleElement: toggleElement, selectElement: selectElement, selectedGroupElements: selectedGroupElements, activeElement: activeElement }"
  )
</template>

<script>
import HighlightActiveGroupItem from './HighlightActiveGroupItem.vue'
import VirtualList from 'vue-virtual-scroll-list'

export default {
  components: {
    HighlightActiveGroupItem,
    VirtualList
  },

  props: {
    elements: [],
    activeElement: null,
    selectedGroupElements: []
  },

  data() {
    return {
      treeElementKeeps: 20,
      treeElementHeight: 18,
      highlightActiveGroupItemComponent: HighlightActiveGroupItem
    }
  },

  methods: {
    toggleElement(item) {
      this.$emit('toggleElement', item)
    },

    selectElement(uuid) {
      this.$emit('selectElement', uuid)
    },

    changeKeepElements() {
      const smetaTreeContainer = this.$refs['highlight-active-treeview-container']
      if(smetaTreeContainer != undefined){
        this.pageResizeObserver = new ResizeObserver(() => {
          this.treeElementKeeps = Math.ceil(smetaTreeContainer.clientHeight / this.treeElementHeight) + 10
        })
        this.pageResizeObserver.observe(smetaTreeContainer)
      }
    },
  },

  mounted() {
    this.changeKeepElements()
  }
}
</script>

<style scoped>
.v-list {
  color: rgba(0,0,0,.87);
  font-size:12px;
  height: calc(100% - 32px);
}
</style>