<template lang="pug">
  app-dialog( v-model="show" :header="$t('section.journal.participantsWorks')" @close="handleClose" width="750px")
      div.d-flex
        
        div(style="width:300px;")
          app-section.pa-1.t-scroll-body
            v-btn.dict-button.pa-1(dense text color="black" @click="showPersonInfo(item)" v-for="item in workPersonList" :key="item.uuid" width="100%") {{ getNameWorkPerson(item) }}
          app-button.mt-2(@click="newWorkPerson()" width="100%" color="accent") {{$t('button.add')}}
        app-section.pa-4.ml-5(style="width:500px") 
          div(v-if="selectedPerson" style="height:100%")
            v-radio-group( v-model="personType" light :readonly="existing")
              v-radio( :label="$t('section.journal.physicalPerson')" value="1" )
              v-radio( :label="$t('section.journal.juridicalPerson')" value="2" )
            app-text-field(light v-model="workPersonItem.personName" :label="personType === '1' ? inputText.personName.individual : inputText.personName.juridical" :hint="inputText.personName.hint")
            app-text-field(light v-model="workPersonItem.fullName" :label="personType === '1' ? inputText.fullName.individual : inputText.fullName.juridical" :hint="inputText.fullName.hint")
            app-text-field(light v-model="workPersonItem.position" :label="personType === '1' ? inputText.position.individual : inputText.position.juridical")
            app-text-field(light v-model="workPersonItem.document" :label="inputText.document.label" :hint="inputText.document.hint")
            app-text-field(light v-model="workPersonItem.worksType" :label="inputText.worksType.label" :hint="inputText.worksType.hint")
            app-text-field(light v-model="workPersonItem.identiNumber" :label="inputText.identiNumber.label" :hint="inputText.identiNumber.hint")
            v-btn.mt-2(@click="savePerson()" width="100%" color="green") {{$t('button.save')}}
</template>

<script>
import textJournal from "./textJournal"
import { mapState, mapActions } from 'vuex'
import { $_validate_rules } from '@/common/ValidateRules'

  export default {
    name: 'WorkPersonDialog',

    mixins: [$_validate_rules],

    components: {},

    data() {
      return {
        workPersonList: [],

        existing: false,

        personType: "1",
        show: true,
        workPersonItem: Object.assign({}, this.wjWorkPersonNewItem),
        inputText: textJournal.workPerson,
        selectedPerson: false
      }
    },

    mounted() {
      this.getWorkPersonList(this.project.uuid).then(() => {
        if (this.listWorkers)
          this.workPersonList = this.listWorkers
      })
    },

    computed: {
      ...mapState("document", ["wjWorkPersonNewItem", "listWorkers"]),
      ...mapState('project', ['project']),
    },

    watch: {
      listWorkers: 'getNewList'
    },

    methods: {
      ...mapActions('document', ['saveNewWorkPerson', 'getWorkPersonList']),

      handleClose(event) {
        this.$emit('close', event)
      },

      getNameWorkPerson(item) {
        if(item.fullName)
          return item.fullName
        else if (item.personName)
          return item.personName
        else ""
      },

      getNewList() {
        if (this.listWorkers)
          this.workPersonList = this.listWorkers
        else 
          this.workPersonList = []
      },

      showPersonInfo(item) {
        this.workPersonItem = item
        this.selectedPerson = true
        this.existing = true
        this.personType = this.workPersonItem.juridical ? "2" : "1"
      },

      newWorkPerson() {
        this.workPersonItem = Object.assign({}, this.wjWorkPersonNewItem)
        this.selectedPerson = true
        this.existing = false
        this.personType = "1"
      },

      savePerson() {
        this.workPersonItem.project = this.project.uuid
        this.workPersonItem.juridical = this.personType == "2" ? true : false
        this.saveNewWorkPerson(this.workPersonItem).then(() => {
          this.workPersonItem = Object.assign({}, this.wjWorkPersonNewItem)
          this.selectedPerson = false
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
.hover{
  color: black;
  background-color: var(--v-primary-lighten5);
} 
.nonhover{
  color:black;
}
.dict-button {
  display: block;
  text-transform: none;
  padding: 0;
}
</style>
