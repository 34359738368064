<template lang="pug">
app-toolbar
  app-toolbar-button( 
    :title="$t('viewer.tools.defaultView')" 
    title-orientation='right' 
    icon="$viewer-fit-icon" 
    icon-size="32px" 
    test-id="default-view-btn"
    @click="setModelsFitToVisibleAndResize" 
  )
</template>

<script>
import { PanelController } from "@/pages/panelController";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";

export default {
  name: "ProjectInstruments",

  data() {
    return {
      isCtrlKeyPressed: false,
    }
  },

  async mounted() {
    await XeokitMediator.waitForViewer()
    this.setCtrlKeyPressedListeners()
  },

  beforeDestroy() {
    this.removeCtrlKeyPressedListeners()
  },

  computed: {
    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },
  },

  methods: {
    setModelsFitToVisibleAndResize() {
      if (!this.isCtrlKeyPressed) {
        PanelController.setPanelPadding(true)
        
        if (XeokitMediator.SectionCube.active) {
          let aabb = XeokitMediator.SectionCube.aabb
          XeokitMediator.CameraFlight.flyTo({aabb})
        } else {
          XeokitMediator.CameraFlight.flyToDefaultView()
          XeokitMediator.GlMemoryInfo.showGlInfo()
        }
      }
      // Перелет только к выделенным элементам (не тестилось с плоскостями сечений и кубом сечений)
      else {
        XeokitMediator.CameraFlight.flyToElements(this.selectedElements)
      }
    },

    setCtrlKeyPressedListeners(){
      document.addEventListener("keydown", this._ctrlKeyDown = (event) => {
        if (event.ctrlKey) this.isCtrlKeyPressed = true
      })

      document.addEventListener("keyup", this._ctrlKeyUp = (event) => {
        if (!event.ctrlKey) this.isCtrlKeyPressed = false
      })
    },

    removeCtrlKeyPressedListeners(){
      document.removeEventListener("keydown", this._ctrlKeyDown)
      document.removeEventListener("keyup", this._ctrlKeyUp)
    }
  },
}
</script>
