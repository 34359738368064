<template lang='pug'>
app-dialog( v-model='$_show' :header="$t('module.task.reassignTask')" )
  app-section.base-scroll-body(style='max-height: 30vh;')
    div.surface--text
      div.d-flex.px-1.pointer.text-color( v-for='task in tasks' :class='{ "surface white--text": selectedTask === task, "task-disabled": disabledTasks.includes(task.uuid) }' @click='selectedTask = disabledTasks.includes(task.uuid) ? selectedTask : task' )
        div.level-spacer( v-for='level in task.level' )
        span {{ task.title }}
  
  div.d-flex.mt-4
    app-button(:disabled='!task.parent' action small @click='extractFromParent(task.uuid)')  {{ $t('module.task.taskOutControl') }}
    app-button.ml-auto(:disabled='!selectedTask' action small @click='setParent(task.uuid, selectedTask.uuid)')  {{ $t('button.save') }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import { mapActions, mapState } from 'vuex'

export default {
  
  mixins: [DialogToggable],
  
  props: {
    task: Object,
  },

  data() {
    return {
      selectedTask: null,
      disabledTasks: [],
    }
  },

  computed: {
    ...mapState('task', ['tasks']),
  },

  watch: {
    $_show() {
      this.selectedTask = this.task.parent ? this.tasks.find(task => task.uuid === this.task.parent) || null : null
      
      const childrenUuids = (task) => {
        const uuids = task.children.map(ch => ch.uuid)
        task.children.forEach(child => {
          uuids.concat(childrenUuids(child))
        })
        return uuids
      }

      this.disabledTasks = childrenUuids(this.task).concat([this.task.uuid])
    }
  },

  methods: {
    ...mapActions('task', ['taskOutOfParent', 'changeParent']),

    extractFromParent(taskUuid) {
      this.taskOutOfParent({ uuid: taskUuid })
      this.close()
    },

    setParent(taskUuid, parentUuid) {
      this.changeParent({ uuid: taskUuid, value: parentUuid })
      this.close()
    },

    close() {
      this.$_show = false
    },
  },

}
</script>

<style scoped>
  .level-spacer {
    width: 16px;
  }

  .task-disabled {
    cursor: default;
    opacity: .4;
  }
  .text-color {
    color: var(--v-secondary-base);
  }
</style>