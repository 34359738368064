<template lang="pug">
.drawing-page
  DrawingPageHeader
  .drawing-page__body( ref='selected-page-body' id="drawingSelectedPageBody" :style='state.grabbingCursor')
    DrawingImage
    .drawing-page__image_layout( :style="'width: ' + state.imageLayoutWidth + 'px; height: ' + state.imageLayoutHeight + 'px;'" id="drawingImageLayout" )
      DrawingComments( v-if="!state.isCroppingModeActive" )
      DrawingCroppingLayout( v-if="state.isCroppingModeActive" )
</template>

<script>
import { useDrawingPageState } from '@/pinia'
import DrawingPageHeader from '@/components/drawings/page/DrawingPageHeader.vue'
import DrawingImage from '@/components/drawings/page/DrawingImage.vue'
import DrawingComments from '@/components/drawings/comments/DrawingComments.vue'
import DrawingCroppingLayout from '@/components/drawings/cropping/DrawingCroppingLayout.vue'

export default {
  name: "NewDrawingPage",

  components: {  
    DrawingPageHeader, 
    DrawingImage, 
    DrawingComments,
    DrawingCroppingLayout
  },

  computed: {
    state: () => useDrawingPageState()
  },
}
</script>

<style scoped>
.drawing-page {
  background: #FFFFFF;

  min-height: 100%;
  height: 1px;
  width: 100%;
  min-width: 300px;

  border-radius: 3px;
}

.drawing-page__body {
  background: inherit;
  
  width: 100%;
  height: calc(100% - 32px);
  position: relative;
  overflow: hidden;

  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  user-select: none;
}

.drawing-page__image_layout {
  position: absolute;
}
</style>