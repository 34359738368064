<template lang="pug">
  app-dialog( v-model="visible" :confirm-disabled="!model" header="Переназначить коллизии" :confirmText="$tc('section.collision.reassign', 0)" @confirm="done" )
    app-section
      app-select( v-model="model" :items="availableModels" item-text="title" :label="$tc('section.collision.reassign', 1)" )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {

  data () {
    return {
      visible: false,
      model: null,
      uuids: null
    }
  },

  computed: {
    ...mapGetters('collision/table', ['availableModels', 'selectedCollisionsUuid']),
  },

  methods: {
    ...mapActions('collision/table', ['getAvailableModels', 'reassignTo']),

    show ({ single, item }) {
      this.uuids = single ? [item.uuid] : this.selectedCollisionsUuid
      this.model = null
      this.getAvailableModels(this.uuids)
      this.visible = true
    },

    done () {
      this.reassignTo({ model: this.model.uuid, uuids: this.uuids })
    },
  },
}
</script>

