<template lang="pug">
    div
      expansion-button( :buttonHeader="buttonHeader" )
        app-text
          div( v-if='generatedPassport' )
            div(v-if='generatedPassport.lazName' v-html='$t("section.structure.model.details.lazName", { name: generatedPassport.lazName })')
            div(v-if='generatedPassport.laz' v-html='$t("section.structure.model.details.laz", { laz: generatedPassport.laz })')
    </template>
    
    <script>
    import ExpansionButton from './components/ExpansionButton'
    export default {
      name: 'PointCloudPassport', 
      components: {
        ExpansionButton
      },
      props: {
        passportData: null, 
        buttonHeader: {
          type: String,
          default: "",
        }
      },
    
      data() {
        return {
          toggleDetailsModel: false
        }
      },
    
      computed: {
        generatedPassport() {
          let curPassport = this.passportData
          
          let passport = {
            lazName: curPassport?.filename,
            laz: Math.round((curPassport?.filesize + Number.EPSILON) * 100) / 100,
          }
          if (passport.laz || passport.lazName) {
            return passport
          }

          return null
        },
      }
    }
    </script>
    