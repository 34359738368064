<template lang="pug">
  div
      v-sheet.viewer.mx-0(v-show="!focused && !onlyEditor" v-html="viewerHtml" :elevation="'elevation'" 
        :color="backgroundColorSheet?backgroundColorSheet:'white'" 
        :style="`color: ${textColorSheet}; min-height: 0px;`"
        :class="{'shorten-text':shortenText}"
        light
        @click="handleFocus")

      .editor(v-show="focused || onlyEditor" @blur="handleSave")
        v-textarea.field.darkTextareaStyle(
          :class="{'hide-scrollbar':scrollHide}"
          :value="value" 
          :label="labelText" 
          :light="light"
          :background-color="backgroundColor ? backgroundColor : focused ? '#F0F0F0' : '#fff'"
          :color="color"
          :autofocus="focus"
          :rows="rows"
          :solo="solo"
          :suffix="suffix"
          filled
          :flat="flat"
          :height="height"
          single-line
          :auto-grow="autoGrow"
          :dense="dense"
          :no-resize="noResize"
          :hide-details="hideDetails"
          @blur="handleBlur"
          @input="handleInput"
          @focus="handleFocus"
          test-id="markdown-textarea-edit-comment"
          
          v-on:keyup.enter.ctrl="handleSave"
        )
        v-row.pt-1(v-if="focusIcoButtons")
          v-col.d-flex.justify-start.align-end.mb-1.pr-0.pb-0.mb-4.text-left
            slot(name="focusIcoButtons")
          v-col.d-flex.justify-end.align-end.mb-1.mr-1.pl-0.pb-0.mb-4.text-right
            app-icon-action(icon="$send-icon" size=16 @mousedown.prevent="handleSave" test-id="markdown-textarea-send-button")

        v-row.pt-1(v-else-if="!focusIcoButtons")
          v-col.text-left.pr-0
            v-btn.mt-1(color="#6b6b6b" @mousedown.prevent="showInfo = true" icon x-small)
              v-icon help
            slot
          v-col.text-right.pl-0
            v-btn.mt-1(color="#6b6b6b" @mousedown.prevent="handleCancel" icon x-small)
              v-icon clear
            v-btn.mt-1(color="#3b93af" @mousedown.prevent="handleSave" text x-small test-id="markdown-textarea-send-comment") {{ $t('button.send') }}

        app-dialog(v-model="showInfo" :header="$t('app.markdownTextarea.help')" width="600" )
          app-section.base-scroll-body( style='height: 75vh' )
            .surface--text
              | {{ $t('app.markdownTextarea.helperLink') }}  
              a(href="https://markdown-it.github.io/" target="_blank") markdown-it
              | - {{ $t('app.markdownTextarea.helperLink1') }}
              .render-help(v-html="helperHtml")

      .mt-1(v-if="buttons && !focused")
        v-btn.text-capitalize(color="#3b93af" @click="handleFocusChange" x-small text) {{ $t('button.change') }}
        slot(name="buttons")
          
      div
        slot(name="addons")          
</template>

<script>
var MarkdownIt = require('markdown-it'),
    md = new MarkdownIt({breaks: true,});

export default {
  name: 'TaskCommentMarkdownTextarea',
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: ''
    },
    suffix: String,
    buttons: Boolean,
    onlyViewer: Boolean,
    onlyEditor: Boolean,
    rows: [Number,String],
    autoGrow: Boolean, 
    noResize: Boolean, 
    scrollHide: Boolean,
    hideDetails: Boolean, 
    light: Boolean, 
    solo: Boolean, 
    flat: Boolean, 
    dense: Boolean, 
    color: String,
    backgroundColor: String,
    backgroundColorSheet: String,
    textColorSheet: String,
    elevation: [String, Number],
    notBlur: Boolean,
    icoButtons: Boolean,
    focusIcoButtons: Boolean,
    shortenText: Boolean,
    height: [String, Number],
  },
  computed: {
    labelText () {
      return this.label ? this.label : this.$t('app.markdownTextarea.label')
    },

    viewerHtml () {
      let val = this.value ? this.value : this.label
      return md.render(val)
    },

    helperHtml () {
      let markdodwEample = `# ${this.$t('app.markdownTextarea.helperH1')}`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `# ${this.$t('app.markdownTextarea.helperH1')}`+
      `\n\`\`\`\n\n---\n\n\n`+
      `## ${this.$t('app.markdownTextarea.helperH2')}`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `## ${this.$t('app.markdownTextarea.helperH2')}`+
      `\n\`\`\`\n\n---\n\n`+
      `**${this.$t('app.markdownTextarea.helperBold')}**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `**${this.$t('app.markdownTextarea.helperBold')}**`+
      `\n\`\`\`\n\n---\n\n`+
      `*${this.$t('app.markdownTextarea.helperItalic')}*`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `*${this.$t('app.markdownTextarea.helperItalic')}*`+
      `\n\`\`\`\n\n---\n\n`+
      `${this.$t('app.markdownTextarea.helperList')}\n`+
      `${this.$t('app.markdownTextarea.helperItem1')}\n${this.$t('app.markdownTextarea.helperItem2')}\n${this.$t('app.markdownTextarea.helperItem3')}`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**\n`+
      `\n\`\`\`\n`+
      `${this.$t('app.markdownTextarea.helperItem1')}`+
      `\n\`\`\`\n`+
      `\n\`\`\`\n`+
      `${this.$t('app.markdownTextarea.helperItem2')}`+
      `\n\`\`\`\n`+
      `\n\`\`\`\n`+
      `${this.$t('app.markdownTextarea.helperItem3')}`+
      `\n\`\`\`\n`+
      `\n---\n`+
      `###[${this.$t('app.markdownTextarea.helperNameLink')}](https://www.google.com)\n\n`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `###[${this.$t('app.markdownTextarea.helperNameLink')}](https://www.google.com)`+
      `\n\`\`\`\n---\n\n\n`+
      `${this.$t('app.markdownTextarea.helperImage')}\n![Minion](https://octodex.github.com/images/minion.png)\n\n`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `![Minion](https://octodex.github.com/images/minion.png)`+
      `\n\`\`\``
      return md.render(markdodwEample)
    },
  },
  methods: {
    handleInput (event) {
      this.$emit('input', event)
    },

    handleBlur () {
      if (!this.notBlur) {
        // this.handleSave(event)
        this.handleFocusClose()
      }
    },

    handleSave (event) {
      this.focused = false
      this.focus = false
      // if (this.value !== "" && this.value !== this.origValue) {
      this.origValue = this.value
      this.$emit('click', event)
      // }
    },

    handleCancel() {
      // this.origValue = null
      this.focused = false
      this.focus = false
      this.handleInput(this.origValue)
    },

    handleFocus ($event) {
      if ($event.target.href) {
        $event.preventDefault()
        $event.stopPropagation()
        window.open($event.target.href)
        return
      }
      if (!this.buttons && !this.icoButtons && !this.onlyViewer) {
        this.focused = true
        this.focus = true
      } else if (this.onlyViewer) {
        this.focused = false
        this.focus = false
      }
    },

    handleFocusClose() {
      this.focused = false
      this.focus = false
    },

    handleFocusChange () {
      this.focused = true
      this.focus = true
    },
  },
  data () {
    return {
      showInfo: false,
      focused: false,
      focus: false,
      origValue: this.value
    }
  }
}
</script>

<style>
.editor {
  min-height: 90px;
  
}

.viewer, .onlyViewer {
  color: black;
  width: 100%;
  min-height: 20px;
  margin: 0 10px;
}

.t-scroll-body {
  overflow-y: auto;
  height: 75vh;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-primary-lighten3);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-surface-lighten3);
}

.viewer img, .onlyViewer img {
  width: 100px;
}

.viewer {
  word-wrap: break-word;
}

.viewer P, .onlyViewer p {
  margin-bottom: 0px;
}

.viewer:hover {
  cursor: pointer;
}

.field {
  border-radius: 3px;
}

.hide-scrollbar textarea {
  overflow-y:hidden
} 
</style>

<style lang="scss" scoped>
.render-help {
  width: 100%;
  overflow: hidden;
}

::v-deep .render-help img {
  width: 100px;
}

.darkTextareaStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #4D4D4D;
  line-height: 1100%;
}
.shorten-text {
  max-height:306px;
  overflow: hidden;
}
</style>
