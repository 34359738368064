<template lang="pug">
  .header.surface.lighten-1
    v-row.header-row(no-gutters)
      v-col.mx-2.pointer( v-for="(item, idx) in menu" :key="idx" ripple @click="handleSelect(item, idx)" ) 
        div.d-flex.justify-center.align-center.my-2.header-panel-text(:style="item.active ? activeClass : ''" test-id="details-header-item") {{ item.title }}
</template>

<script>
export default {
  data () { 
    return { item: null}
  },
  props: {
    menu: Array
  },

  computed: {
    activeClass() {
      return "border-bottom: 2px solid var(--v-primary-darken2)"
    },
  },
  methods: {
    handleSelect (item, idx) {
      this.item = item 
      if (item.action) item.action()
      else this.$emit('menu:select', item, idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  background: #E5E5E5;
  font: normal 12px/14px $roboto;
  font-weight: 700;
}

.header-row {
  border-bottom: 1px solid #C4C4C4;
}

.pointer:hover {
  font-weight: 500;
}

.pointer {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.header-panel-text {
  color: var(--v-primary-darken2) !important;
}
</style>
