<template lang="pug">
  .layout-deck(v-if="editActive" @mousemove="move($event)" @mouseup="stopEditing" id="layoutdeck")
</template>

<script>

import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  
  computed: {
    ...mapState('layoutControler', ['editActive', 'mouseOnDeckPosition', 'change', 'axis'])
  },
  
  methods: {
    ...mapMutations('layoutControler', ['setStartedMousePosition', 'setMouseOnDeckPosition']),
    ...mapActions('layoutControler', ['changeSize', 'changeVerticalSize', 'stopEdit']),

    move(event){
      if (this.axis == 'horizontal') {
        this.horizontalMove(event)
        return
      }

      this.verticalMove(event)
    },

    horizontalMove(event) {
      this.setStartedMousePosition({x: this.mouseOnDeckPosition.x, y: this.mouseOnDeckPosition.y})
      this.setMouseOnDeckPosition({x: event.x, y: event.y})
      this.changeSize()
    },

    verticalMove(event) {
      this.setStartedMousePosition({x: this.mouseOnDeckPosition.x, y: this.mouseOnDeckPosition.y})
      this.setMouseOnDeckPosition({x: event.x, y: event.y})
      this.changeVerticalSize()
    },

    stopEditing() {
      this.stopEdit()
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout-deck {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    pointer-events: all;
  }
</style>