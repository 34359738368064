<template lang="pug">
.app-toolkit-column
  ToolButtonSceneGroups
  ToolButtonSceneTypes
</template>

<script>
import ToolButtonSceneGroups from './buttons/ToolButtonSceneGroups.vue'
import ToolButtonSceneTypes from './buttons/ToolButtonSceneTypes.vue'

export default {
  components: {
    ToolButtonSceneGroups,
    ToolButtonSceneTypes,
  },
}
</script>