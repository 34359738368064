<template lang="pug">
.drawing-by-models-container
  DrawingPageSelecting.mr-2( v-if="store.selectedDrawingPage" style="flex:1" ref="drawing" )
  .drawing-models.base-scroll-body( v-else )

    .drawing-models-body
      //- Модели с группами чертежей
      .models( v-for='(model, index) in currentModelsWithPages' )
        .d-flex.align-center.px-2.pointer( :style='modelTitleStyle(index)' @click='modelVisible(model)' )
          span.font-weight-bold {{ translateName(model.name) }}
          .loading.px-2
            div( v-for="u in uploads" :key="u.idx" )
              div( v-if="u.modelUuid == model.uuid && u.progress < 100" )
                .caption.mt-1 {{ u.name }}
                v-progress-linear.mt-1( v-model="u.progress" color="light-blue" height="10" value="10" striped )
              div( v-if="u.modelUuid == model.uuid && u.progress == 100" )
                .caption.mt-1.primary--text {{ $t('module.process.wait') }}
                div( v-for="p in processes" :key="p.uuid" )
                  div( v-if="p.processType.name == 'DRAWINGPROCESSING' && p.objectUuid == u.uuid" )
                    .caption.mt-1 {{ p.processType.title }}
                    v-progress-linear.mt-1( v-model="p.progress" color="light-blue" height="10" value="10" striped )
                    .caption.mt-1 {{ p.startDate ? p.startDate : $t('module.process.wait') }}

          v-btn.ml-auto(icon small)
            v-icon.close-model( color='#C4C4C4' :class='{"close-button": !model.visible}' ) expand_less
        .groups( v-if='model.visible' :style='modelTitleStyle(index)' )
          .d-flex.flex-column( v-for='group in model.groups' )
            DrawingRow( :drawing="group.drawing" @editPageDisplaySettings='editPageDisplaySettings(group)' )
            .d-flex.flex-wrap.mt-3.pl-2( v-if='model.visible' )
              .drawings.flex-wrap
                v-tooltip( v-for='page in modelValidPages(group)' :disabled='!page.name' :key='page.uuid' bottom )
                  template( v-slot:activator="{ on, attrs }" )
                    .font-weight-regular.drawing-box( v-bind="attrs" v-on="on" )
                      .white.pointer( @click='selectPage(page)' )
                        DrawingPreview.drawing-border( :pageUuid='page.uuid' )
                        .page-number {{ page.number }}
                      span.pl-1( v-html='page.name' )
                  span {{ page.name }}
                  
      //- Пустая страница
      .empty-page( v-if='!hasDrawings' )
        v-progress-circular( v-if='store.drawingsIsLoading' indeterminate color="accent" )
        .no-one-drawing( v-else )
          v-btn.empty-drawing-button( color='accent' small text @click="showNewDrawingDialog" )
            span {{ $t("module.drawing.addDrawing") }}
            v-icon( small ) mdi-paperclip

  //- Фаб нового чертежа
  .add-new-drawing( v-if='!store.selectedDrawingPage && hasDrawings' )
    v-btn.ml-auto.mr-5( color='accent' @click='showNewDrawingDialog' small fab ) 
      v-icon( color='#fff' ) mdi-plus
  
  //- Добавление нового чертежа
  app-dialog( v-model='addNewDrawingDialog.show' :header='$t("module.drawing.addNewPdf")' )
    app-section.py-4.d-flex.flex-column
      .new-drawing-header.mb-2 {{ $t("module.drawing.elementSelectionCondition") }}
      v-menu( nudge-bottom='28' bottom )
        template( v-slot:activator='{on, attrs}' )
          div.relative(v-bind='attrs' v-on='on')
            input.new-drawing-input.pointer( :placeholder='$t("module.drawing.indicateSource")' :value='translateName(addNewDrawingDialog.drawing.model.name)' readonly )
            v-btn.select-button( x-small icon )
              v-icon( color='#757575' ) mdi-menu-down
        .d-flex.flex-column.model-select-container.base-scroll-body
          .model-select-row( v-for='model in preparedModels' @click='addNewDrawingDialog.drawing.model = model' ) {{ translateName(model.name) }}
      input.new-drawing-input( v-model='addNewDrawingDialog.drawing.name' :placeholder='$t("module.drawing.name")' )
      input( v-show='false' type='file' ref='newDrawingFileInput' accept='.pdf, .PDF' @change='fileForNewDrawingChoosed' )
      .file-input.pointer( @click='$refs.newDrawingFileInput.click()' )
        v-icon( color='#757575' ) mdi-paperclip
        .file-name( v-if='addNewDrawingDialog.drawing.file && addNewDrawingDialog.drawing.file.name.length > 0' ) 
          span {{ addNewDrawingDialog.drawing.file.name }}
        .placeholder( v-else ) {{ $t("section.structure.model.chooseMaterial") }}
      v-btn.ml-auto.mt-3( color='accent' @click='runDrawingUpload' :disabled='!newDrawingFieldsAreValid' light small ) {{ $t("download") }}

        
</template>

<script>
import DrawingPreview from '@/components/drawings/DrawingPreview.vue'
import DrawingPageSelecting from '@/components/drawings/DrawingPageSelecting.vue'
import DrawingRow from './DrawingRow.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import { AlertService } from '@/assets/app/AlertService'
import { useDrawingsStore } from '@/pinia'

export default {
  name: 'DrawingsByModels',

  components: { DrawingPreview, DrawingPageSelecting, DrawingRow },

  data() {
    return {
      addNewDrawingDialog: {
        show: false,
        drawing: {},
        firstLoadDrawing: false,
      },
    }
  },

  watch: {
    flatlist() {
      if (this.flatlist.length && !this.firstLoadDrawing) {
        this.firstLoadDrawing = true
        this.store.loadDrawings(this.projectUuid)
      }
    },
  },

  computed: {
    ...mapGetters('project', ['flatlist', 'projectUuid']),
    ...mapState('process', ['processes', 'uploads']),

    store: () => useDrawingsStore(),

    preparedModels() {
      let filteringUUIDs = []
      return this.flatlist.map((original) => {
        let m = { ...original }
        if (filteringUUIDs.includes(m.uuid)) {
          m.disabled = true
          filteringUUIDs.push(...m.model.map((i) => i.uuid))
        }

        m.title = this.$t(NotificationService.NotificationReplace(m.name))
        let fulltext = []
        for (let i = 0; i < m.level; i++) fulltext.push('| - ')
        fulltext.push(m.title)
        m.text = fulltext.join('')
        return m
      })
    },

    currentModelsWithPages() {
      if (this.store.searchFilter) {
        if (typeof this.store.searchFilter == 'string' && this.store.searchFilter.length > 0) {
          return this.store.getSearchValidPages.filter((model) => model.groups.length > 0)
        }
      }

      return this.store.modelsWithGroups.filter(
        (model) => model.groups.length > 0 || this.uploads.find((upload) => model.uuid == upload.modelUuid) != null
      )
    },

    hasDrawings() {
      const modelWithGroup = this.store.modelsWithGroups.find((model) => model.groups.length > 0)
      if (modelWithGroup) {
        return true
      }
      return false
    },

    newDrawingFieldsAreValid() {
      return (
        this.addNewDrawingDialog.drawing.name &&
        this.addNewDrawingDialog.drawing.name.length > 0 &&
        this.addNewDrawingDialog.drawing.file.name &&
        this.addNewDrawingDialog.drawing.model.name &&
        !!this.addNewDrawingDialog.drawing.name.trim()
      )
    },
  },

  mounted() {
    this.$root.$emit('drawingsByModelsInit', this)
  },

  methods: {
    ...mapActions('process', ['runDrawingVersionUpload']),

    selectPage(page, comment = null, withSidePanel = false) {
      this.store.selectedDrawingPage = page

      setTimeout(() => {
        const drawingPageSelecting = this.$refs.drawing
        drawingPageSelecting?.selectPagePreview(page, comment, withSidePanel)
      })
    },

    modelVisible(model) {
      if (!model.visible) {
        model.groups.map((group) =>
          group.getPages(group.drawing.currentVersion).then(() => {
            model.visible = true
          })
        )
      } else {
        model.visible = false
      }
    },

    modelValidPages(model) {
      return model.pages.filter((page) => page.included && page.version.uuid == model.drawing.currentVersion.uuid)
    },

    translateName(name) {
      return this.$t(NotificationService.NotificationReplace(name)).toUpperCase()
    },

    showNewDrawingDialog() {
      this.addNewDrawingDialog.drawing = {
        model: {
          name: '',
        },
        name: '',
        file: {
          name: '',
        },
      }
      this.addNewDrawingDialog.show = true
    },

    runDrawingUpload() {
      const file = this.addNewDrawingDialog.drawing.file
      const extension = file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1)

      this.addNewDrawingDialog.show = false

      if (extension.toLowerCase() !== 'pdf') {
        AlertService.error({
          data: { error_description: this.$t('module.drawing.invalidExtension') },
        })
        return
      }
      let drawingForm = {
        name: this.addNewDrawingDialog.drawing.name,
        model: this.addNewDrawingDialog.drawing.model,
        projectUuid: this.projectUuid,
      }

      this.store.addNewDrawing(drawingForm).then((drawing) => {
        const versionForm = new FormData()
        versionForm.append('name', drawing.name)
        versionForm.append('drawingUuid', drawing.uuid)
        versionForm.append('file', file)
        versionForm.append('modelUuid', drawing.model.uuid)

        this.runDrawingVersionUpload(versionForm)
      })
    },

    hideSidePanel() {
      return this.$refs.drawing.hideSidePanel()
    },

    modelTitleStyle(index) {
      if (index % 2 == 0) {
        return 'background: #F8F8F8'
      }
      return 'background: #FFFFFF'
    },

    getCurrentVersionOfDrawing(drawing) {
      return drawing.versions.find((version) => version.uuid == drawing.currentVersion)
    },

    scaleSelectedDrawingPage() {
      this.$refs.drawing.scaleSelectedDrawingPage()
    },

    selectedPageFitSize() {
      this.$refs.drawing.selectedPageFitSize()
    },

    editPageDisplaySettings(group) {
      this.selectPage(group.pages.filter((page) => page.version.uuid == group.drawing.currentVersion.uuid)[0], null, true)
    },

    scrollPageToComment(comment) {
      const selectedPage = this.store.drawingsPages.find((page) => page.uuid == comment.page)
      if (selectedPage) {
        this.store.selectedDrawingPage = selectedPage
        this.selectPage(this.store.selectedDrawingPage, comment)
      } else {
        this.store.getAllVersionsPagesByPageUuid(comment.page).then(() => {
          const selectedPage = this.store.drawingsPages.find((page) => page.uuid == comment.page)
          this.store.selectedDrawingPage = selectedPage
          this.selectPage(this.store.selectedDrawingPage, comment)
        })
      }
    },

    fileForNewDrawingChoosed(event) {
      let file = event.target.files[0]
      if (!this.addNewDrawingDialog.drawing.name) {
        let clearFileName = []
        file.name.split('.').map((string, index) => {
          if (index != file.name.split('.').length - 1) clearFileName.push(string)
        })
        this.addNewDrawingDialog.drawing.name = clearFileName.join('.')
      }
      this.addNewDrawingDialog.drawing.file = file
    },
  },
}
</script>

<style lang="scss" scoped>
.drawing-models {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 3px;
}
.drawing-models-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 0px);
}
.models {
  width: 100%;
}
.drawing-by-models-container {
  height: 100%;
  position: relative;
  color: #1b252c;
}

.white {
  min-width: 200px;
  max-width: 200px;
  height: 120px;
  position: relative;
  border: 1px solid black;
}
.font-weight-bold {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
}
.drawings {
  gap: 20px;
  display: flex;
}
.font-weight-regular {
  color: #e5e5e5;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-drawing-window {
  background: #757575;
  width: 200px;
  height: 120px;
  display: flex;
  place-content: center;
}
.default {
  width: 200px;
  height: 120px;
}
.selected-preview-page {
  border: 4px solid #3b93af;
}
.drawing-box {
  color: #1b252c;
}
.close-model {
  transform: rotate(0deg);
}
.close-button {
  transform: rotate(180deg);
}
.page-number {
  position: absolute;
  bottom: 0px;
  color: #000000;
  background: #c4c4c4;
  left: 0px;
  width: 32px;
  height: 32px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groups {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.group-name {
  font-size: 18px;
}
.group-version {
  font-size: 12px;
}
.drawing-border {
  border: 1px solid #757575;
}
.drawing-model-header {
  align-items: center;
  display: flex;
  font-weight: bold;
  color: #262626;
  background: #e5e5e5;
  line-height: 40px;
  padding: 0px 4px 0px 8px;
  position: fixed;
  z-index: 1;
}
.empty-page {
  color: #757575;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  height: calc(100% - 0px);
  justify-content: center;
  line-height: 40px;
}
.no-one-drawing {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
}
.add-new-drawing {
  position: sticky;
  bottom: 40px;
  display: flex;
}
.new-drawing-header {
  color: #757575;
  font-size: 12px;
}
.relative {
  position: relative;
}
.new-drawing-input {
  background: #e5e5e5;
  border: none;
  outline: none;
  height: 32px;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  color: #4d4d4d;
  font-size: 14px;
  width: 100%;
}
.new-drawing-input::placeholder {
  color: #4d4d4d;
}
.select-button {
  position: absolute;
  top: 6px;
  right: 6px;
}
.model-select-row {
  background: white;
  color: #4d4d4d;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
}
.model-select-row:hover {
  background: #f8f8f8;
}
.file-input {
  background: #e5e5e5;
  border-radius: 2px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4d4d4d;
}
.file-input .placeholder {
  color: #929292;
}
.file-name {
  color: #4d4d4d;
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
}
.empty-drawing-button {
  border: 1px dashed;
}

.loading {
  flex: auto;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;

  & span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.model-select-container{
  max-height: 190px;
  overflow-y: auto;
}
</style>
