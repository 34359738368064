<template lang="pug">
AppTooltip( top title="Связать чертеж" )
  AppIconButton( icon="mdi-link-lock" :iconColor="state.isCroppingModeActive ? '#8DAFFF' : '#4d4d4d'" @click="state.toggleCroppingMode" :disabled="state.isDrawingSettingMode" )
</template>

<script>
import { useDrawingPageState } from '@/pinia';

export default {
  name: 'DrawingCrop', 

  computed: {
    state: () => useDrawingPageState(),
  },
}
</script>