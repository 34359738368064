<template lang="pug">
  div( v-if="pluginTable && pluginTableFlag"  id="plugintable" ref="plugintable" )
    
    v-data-table(
      :headers="headers"
      v-resize="onResize"
      :items="tableElements"
      :items-per-page="itemsPerPage"
      dense class="elevation-1"
      hide-default-footer
      :height="tableHeight"
      light
    )

      template( v-slot:top )
        .head-sizable-block( @mousedown="verticalSizable" )
          .horizontal-icon-pos
            v-icon( size="60" color="accent" style="cursor: row-resize !important;") {{ "$horizontal-sizable-icon" }}

        div.main-container
          div.left-container.mx-2
            app-toolbar( horizontal )
              app-toolbar-button( :icon="hideFlag ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" 
                                  :title="hideFlag ? $t('plugin.table.showAllElements') : $t('plugin.table.hideAllElements')"
                                  @click="showOrHideElements" )

              app-toolbar-button( :icon="xrayFlag ? 'mdi-radiology-box-outline' : 'mdi-radiology-box'"
                                  :title="xrayFlag ? $t('plugin.table.hideXray') : $t('plugin.table.showXray')"
                                  @click="showModelXray" )

              app-toolbar-button( icon="mdi-table-arrow-right" :title="$t('plugin.table.export')" @click="exportPluginTable" )

          div.right-container.mx-2
            app-toolbar-button( icon="mdi-close" :title="$t('plugin.table.close')" @click="closePluginTable" )

      template(v-slot:item="{ item, index }")
        tr( :class="{ 'highlight-row' : index % 2 == 0 } ")
          td(v-for="header, index in headers") 
            v-btn(v-if="item[index].actionType" light x-small @click="makeActionOnElements(item[index])") {{item[index].buttonName}}
            div(v-else ) {{  rounding(item[index]) }}

      template(v-slot:footer)
        div.d-flex.justify-end.align-center.style
          div.mr-3.app-font-12 {{ totalPagesInfo }}
          v-btn(:disabled="isPrevPageAvailable" icon  @click="openPrevPage" :title="$t('plugin.table.prevPage')")
            v-icon( color="var(--v-primary-darken2)" ) keyboard_arrow_left
          div.mx-3.app-font-12 {{ pluginTable.pagination.page }}
          v-btn(:disabled="!isLastPageAvailable" icon @click="openNextPage" :title="$t('plugin.table.nextPage')")
            v-icon( color="var(--v-primary-darken2)" ) keyboard_arrow_right
</template>

<script>
  import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    name: 'PluginResultTable',

    data() {
      return {
        hideFlag: true,
        xrayFlag: false,
        pageLoading: false,
        tableHeight: window.innerHeight * 0.33,
        xrayElems: {
          elems: [],
          color:""
        },
        windowSize: {
          x: 0,
          y: 0,
        },
      }
    },

    mounted() {
      document.onmouseup = this.verticalSizableUp
    },

    beforeDestroy() {
      document.onmouseup = null
      document.onmousemove = null
      
      let canvas = document.getElementById('myCanvas')
      let measure = document.getElementById('measurementScrim')
      let axis = document.getElementById('myAxisCanvas')
      if (canvas) canvas.height = window.innerHeight
      if (measure) measure.style.paddingBottom = 0
      if (axis) axis.style.paddingBottom = 0
    },

    watch: {
      pluginTableFlag(){
        this.hideFlag = true
        this.xrayFlag = false
        this.pageLoading = false
        this.xrayElems.elems = []
        this.xrayElems.color = ""
        this.resizeCanvas()
      }
    },

    computed: {
      ...mapState('plugins', ['pluginTable', 'pluginTableFlag']),

      tableElements() {
        return this.pluginTable.elements
      },

      headers() {
        let el = this.pluginTable.elements[0]
        if (el) {
          let arr = []
          let headers = this.pluginTable.headers
          for (let i = 0; i < headers.length; i++) {
            arr.push({ text: headers[i], value: i.toString(), sortable:false })
          }
          return arr
        }
        return []
      },

      viewer() {
        return XeokitMediator.viewer
      },

      itemsPerPage() {
        return this.pluginTable.pagination.pageSize
      },

      totalPagesInfo() {
        let pagInfo = this.pluginTable.pagination

        if (pagInfo.page == 1 && pagInfo.total > 0 && pagInfo.pageSize < pagInfo.total)
          return `1-${pagInfo.pageSize} из ${pagInfo.total}`
        if (pagInfo.page == 1 && pagInfo.total > 0 && pagInfo.pageSize >= pagInfo.total)
          return `1-${pagInfo.total} из ${pagInfo.total}`
        if (pagInfo.page > 1 && pagInfo.total > 0) {
          if (pagInfo.pageSize * pagInfo.page > pagInfo.total)
            return ` ${pagInfo.pageSize * (pagInfo.page - 1) + 1} - ${pagInfo.total} из ${pagInfo.total} `
          return ` ${pagInfo.pageSize * (pagInfo.page - 1) + 1} - ${pagInfo.pageSize * pagInfo.page} из ${
            pagInfo.total
          } `
        }

        return this.$t('section.collision.table.footer.pageInfo.empty')
      },

      isPrevPageAvailable() {
        return (this.pluginTable.pagination.page > 0 && this.pluginTable.pagination.page == 1) || this.pageLoading
      },

      isLastPageAvailable() {
        let pages = Math.ceil(this.pluginTable.pagination.total / this.pluginTable.pagination.pageSize)
        return this.pluginTable.pagination.page > 0 && this.pluginTable.pagination.page < pages && !this.pageLoading
      },
    },

    methods: {
      ...mapMutations('plugins', ['setPluginTable']),
      ...mapActions('plugins', ['LOAD_TABLE', 'downloadXLSX', 'getTablePage']),

      rounding(elem) {
        if (typeof elem == 'number') {
          if (!Number.isInteger(elem) && elem != 0) {
            return +elem.toFixed(4)
          }
          return elem
        }

        return elem
      },

      exportPluginTable() {
        this.downloadXLSX(this.pluginTable.pluginUuid)
      },

      returnDefaultView() {
        XeokitMediator.Models.restoreModels()
      },

      hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
          ? [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255]
          : [1, 1, 0]
      },

      highlightElements(color, highlightedObjectIds) {
        XeokitMediator.ElementsSettings.setElementsHighlighted(highlightedObjectIds, true, color)
      },

      highlightElementsArray(color, list, opacity) {
        XeokitMediator.ElementsSettings.setElementsColorized(list, color)

        if(!!opacity && opacity >= 0)
          XeokitMediator.ElementsSettings.setElementsOpacity(list, opacity / 100)
        else
          XeokitMediator.ElementsSettings.setElementsOpacity(list, 1.0)
      },

      makeXrayElems() {
        let elemsUuids = this.mapObjectElements(this.xrayElems.elems)
        XeokitMediator.ElementsSettings.setElementsXRayed(elemsUuids, false)
      },

      restoreGroupElementsColor(){
        for (let element of this.xrayElems.elems) {
            if (typeof element === 'string') this.highlightElementsArray(this.hexToRgb(this.xrayElems.color), element)
              else
                if (typeof element === 'object' && element.elementUuid)
                  this.highlightElementsArray(this.hexToRgb(element.color), element.elementUuid, element.opacity)
                else
                  if (typeof element === 'object' && element.uuid)
                    this.highlightElementsArray(this.hexToRgb(this.xrayElems.color), element.uuid, element.opacity)
            }
      },

      mapObjectElements(arrayElements) {
        let elements = []
        for (let element of arrayElements) {
              if (typeof element === 'string') elements.push(element)
              else
                if (typeof element === 'object' && element.elementUuid)
                  elements.push(element.elementUuid)
                else
                  if (typeof element === 'object' && element.uuid)
                    elements.push(element.uuid)
            }
        return elements
      },

      objectToArray(data){
        if(data instanceof Array) return data 
        else return [data]
      },

      makeActionOnElements(data) {
        this.clearAllSelected()
        let elements = []
        let defaultColor = '#FFFF00'
        this.xrayElems.elems = []
        this.xrayElems.color = defaultColor

        switch (data.actionType) {
          case 'SHOW_ELEMENTS':
            elements = this.mapObjectElements(this.objectToArray(data.arguments[0]))
            XeokitMediator.ElementsSelection.selectElements(elements)
            XeokitMediator.CameraFlight.flyToElements(elements)
            break

          case 'SHOW_GROUP_ELEMENTS':
            if (typeof data.arguments[0] === 'string' && data.arguments[0].length == 7) {
              defaultColor = data.arguments[0]
              elements = data.arguments[1]
            } else elements = data.arguments[0]

            elements = this.objectToArray(elements)

            for (let element of elements) {
              if (typeof element === 'string') this.highlightElementsArray(this.hexToRgb(defaultColor), element)
              else
                if (typeof element === 'object' && element.elementUuid)
                  this.highlightElementsArray(this.hexToRgb(element.color), element.elementUuid, element.opacity)
                else
                  if (typeof element === 'object' && element.uuid)
                    this.highlightElementsArray(this.hexToRgb(defaultColor), element.uuid, element.opacity)
              }

            this.xrayElems.elems = elements
            this.xrayElems.color = defaultColor
            
            if (this.xrayFlag) {
              this.makeXrayElems()
            }
            
            XeokitMediator.CameraFlight.flyToElements(this.mapObjectElements(elements))

            break
        }
      },

      onResize() {
        this.windowSize = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
      },

      resizeCanvas(height = 0) {
        if (this.pluginTableFlag && height === 0)
          height = this.tableHeight || window.innerHeight * 0.33
        document.getElementById("myCanvas").height = window.innerHeight - height
        document.getElementById("measurementScrim").style.paddingBottom = height ? height + 'px' : 0
        document.getElementById("myAxisCanvas").style.paddingBottom = height ? (height + 220) + 'px' : 0
      },

      closePluginTable() {
        this.LOAD_TABLE(false)
        this.returnDefaultView()
        this.clearAllSelected()
        this.resizeCanvas()
        this.xrayFlag = false
      },

      openPrevPage() {
        this.pageLoading = true
        let obj = {
          pluginUuid: this.pluginTable.pluginUuid,
          page: this.pluginTable.pagination.page - 1,
          pageSize: this.pluginTable.pagination.pageSize,
          actions: this.pluginTable.actions,
          rowsPositions: this.pluginTable.rowsPositions
        }
        this.getTablePage(obj).then(() => {
          this.pageLoading = false
        })
      },

      openNextPage() {
        this.pageLoading = true
        let obj = {
          pluginUuid: this.pluginTable.pluginUuid,
          page: this.pluginTable.pagination.page + 1,
          pageSize: this.pluginTable.pagination.pageSize,
          actions: this.pluginTable.actions,
          rowsPositions: this.pluginTable.rowsPositions
        }
        this.getTablePage(obj).then(() => {
          this.pageLoading = false
        })
      },

      showOrHideElements() {
        if (this.hideFlag) {
          let array = []
          this.pluginTable.actions.map((el) => {
            let arr = []
            let uuid = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g

            el.args.forEach((val) => {
              if (val instanceof Array) {
                for (let elem of val) {
                  if (elem.elementUuid) arr.push(elem.elementUuid)
                  else if (elem.uuid) arr.push(elem.uuid)
                  else arr.push(elem)
                }

              } else if (uuid.test(val)) {
                arr.push(val)
              }
            })
            array.push(...arr)
          })
          this.hideFlag = false
          XeokitMediator.ElementsSelection.selectElements(array)
        } else {
          this.hideFlag = true
          this.clearAllSelected()
        }
      },

      verticalSizable() {
        document.onmousemove = this.verticalSizableMove
      },

      verticalSizableMove(ev) {
        let height = this.tableHeight - ev.movementY
        if (height < window.innerHeight - 150) this.tableHeight = height

        this.resizeCanvas(height)
      },

      verticalSizableUp() {
        document.onmousemove = null
      },

      clearAllSelected() {
        XeokitMediator.ElementsSelection.selectElements([])
        XeokitMediator.clearColorizeModel()
      },

      showModelXray() {
        this.xrayFlag = !this.xrayFlag
        const allModels = this.viewer.scene.models
        const allModelsKeys = Object.keys(allModels)
        if (this.xrayFlag) {
          
          allModelsKeys.forEach((key) => {
            XeokitMediator.setRevisionVisualizationSettings({
              uuid: key,
              xrayed: true,
              visible: true,
              edges: false,
              clearColorize: false,
              pickable: false,
            })
            let model = allModels[key]
            model.xrayMaterial.edgeAlpha = 0.3
            model.xrayMaterial.fillAlpha = 0.1
            model.xrayMaterial.edgeWidth = 5
          })
        } else {
          this.returnDefaultView()
        }
        this.makeXrayElems()
        this.restoreGroupElementsColor()
      },
    },
  }
</script>

<style scoped>
  .main-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: var(--v-surface-base);
  }

  .left-container {
    display: flex;
    justify-content: flex-start;
  }

  .right-container {
    display: flex;
    justify-content: flex-end;
  }

  .head-sizable-block {
    width: 100%;
    height: 7px;
    cursor: row-resize;
    z-index: 998;
    background-color: var(--v-surface-base);
  }

  .horizontal-icon-pos {
    display: inline-block;
    position: relative;
    left: 50%;
    top: -15px;
  }

  ::v-deep .text-start.sortable {
    width: max-content !important;
    min-width: max-content !important;
  }
  ::v-deep th,
  ::v-deep td {
    border-right: 1px solid var(--v-surface-base);
  }

  ::v-deep th {
    color: var(--v-primary-darken2) !important;
  }
  ::v-deep .v-data-table-header tr{
    background-color: var(--v-surface-base);
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .style{
    background-color: var(--v-surface-base);
    color: var(--v-primary-darken2);
  }

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}

@-moz-document url-prefix() {
  .v-data-table__wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--v-accent-base) var(--v-scroll-base)
  }
}

.highlight-row {
  background-color: #f0f0f0;
}
</style>
