<template lang="pug">
div
  div( v-if="projectWormSelected != null" )
    div( v-if="isWormNodeFetching" )
      v-skeleton-loader( type="list-item-two-line" light )
      v-progress-linear( indeterminate )

    div( v-if="!isWormNodeFetching && wormNodeCount == 0 && canWorkWithWorm" )
      app-button-add.mx-2( width="100%" :text="$t('section.classification.menuClassification.create')" test-id="worm-create-new-node" @click="createNewRootNode()" )

    div( v-if="!isWormNodeFetching && wormNodeCount > 0" style="position: relative;" )
      .d-flex.mb-2.align-center
        v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleAll" )
          v-icon.mr-1( color="accent" size="16" left ) unfold_more
          | {{ areAllTreeNodesOpened ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
        v-spacer

        v-menu( offset-y :close-on-content-click="false" light left )
          template( v-slot:activator="{ on, attrs }" )
            app-icon-button( v-bind="attrs" v-on="on" icon="mdi-filter" iconSize="18" iconColor="var(--v-accent-base)" light )
          v-list.pa-0
            v-list-item
              v-switch.mt-0( v-model="filterWithRules" dense hide-details color="var(--v-accent-base)" )
                template( v-slot:label ) 
                  .worm-tree__filter_text {{$t("section.classification.menuClassification.withValidRules" )}}

        app-tooltip( bottom :title="isAllDefaultType ? $t('section.worm.everythingComplete') : $t('section.worm.everythingBrief')" )
          app-icon-button.mr-1( :icon="isAllDefaultType ? '$full-unloading-icon' : '$short-unloading-icon'"
                                iconColor="var(--v-accent-base)" light iconSize="16"
                                @click="toggleAllDataExportType" )                      
      v-treeview.__project_view_tree(
          v-if="renderComponent"
          ref="wormNodesTree"
          dense
          light
          hoverable
          :active="tree.active"
          item-key="uuid"
          :items="nodesTree"
          :open.sync="treeOpened"
          item-disabled="locked"
          @update:open="handleTreeUpdate"
        )
      
        template( v-slot:label="{ item, active }" )
          template( v-if="item.nodeType.name == 'TOPIC'")
            template( v-if="item.isEdit" )
              v-form.py-1.my-9( ref="nodeForm" @submit.prevent="saveNode()" )
                v-row.pt-2( style="position: absolute; top: 0px; left: 4px;" )
                  v-col.pr-1( cols=4 )
                    app-text-field.text-nopadding.white( v-model="editNode.pressmark" :label="$t('section.classification.menuClassification.code')" :rules="[rules.required]" focus hideDetails test-id="worm-node-code" )
                  v-col.px-1( cols=5 )
                    app-text-field.text-nopadding.white( v-model="editNode.title" :label="$t('section.classification.menuClassification.title')" outlined :rules="[rules.required]" hideDetails test-id="worm-node-title" )
                  v-col.px-0.pt-4( cols=1 )
                    v-btn( icon small type="submit" test-id="worm-confirm-edit" )
                      v-icon mdi-check
                    v-btn( icon small @click="cancelEditNode(item)" )
                      v-icon mdi-cancel
            template( v-else )
              draggable( :list="nodesTree" group="node" :id="item.uuid" :data-parent="item.parentUuid" @start="checkStart" :move="checkMove" @end="checkEnd" )
                v-hover.pointer( v-slot:default="{ hover }" close-delay="150" )
                  .d-flex.py-1.p-relative( @contextmenu.prevent="showItemMenu($event,item)" @click="selectNode($event,item)" :title="item.title" )
                    .nodeLabel.mr-4 {{ item.pressmark }} - {{ item.title }}
                    v-icon.unit-action( v-if="hover && canWorkWithWorm" color="#000" @click.stop="showItemMenu($event,item)" ) more_vert

          template( v-if="item.nodeType.name == 'RULE'" )
            draggable( :list="nodesTree" group="node" :id="item.uuid" :data-parent="item.parentUuid" @start="checkStart($event, item)" :move="checkMove" @end="checkEnd" )
              v-hover.p-relative.pointer( v-slot:default="{ hover }" close-delay="150" )
                div
                  div.mb-2( v-if="item.classificatorNode" @contextmenu.prevent="showItemMenu($event,item)" @click="selectNode($event,item)" :title="item.classificatorNode.pressmark+' - '+item.classificatorNode.title+ ' ('+item.title+')'" )
                    v-row( no-gutters )
                      v-col.flex-grow.min-width-30( cols=6 style="flex: 0 0 30% !important;" )
                        div.mt-1.flex-grow
                          div.text-overflow
                            span( v-if="item.resource" )
                              v-icon mdi-link-box-variant
                            span( v-if="item.logicValidy")
                              v-icon.center( v-if="item.logicValidy.valid" color="#0f0" ) mdi-circle-medium
                            span.bold( :class="{'condition-substitute': (item.conditionLinks && item.conditionLinks.length > 0)}" ) {{ item.classificatorNode.pressmark }}
                      v-col.flex-grow.max-width-30
                        div.mt-1.d-flex.justify-center( test-id="worm-node-volume" ) {{ item.value == 0 ? '-' : numberFormatter.format(item.value) }}
                      v-col.flex-grow.max-width-30.mr-1
                        div.mt-1.d-flex.justify-center {{ (item.classificatorNode.unitValue > 1 ? item.classificatorNode.unitValue : '') + (item.classificatorNode.unitType ? item.classificatorNode.unitType.title : '') }}
                      v-col.flex-grow.max-width-30
                        div.mt-1.d-flex.justify-center {{ item.cnt == 0 ? '-' : item.cnt }}
                    div.small-gray {{ item.classificatorNode.title }} ({{ item.title }})
                    v-progress-linear.p-absolute( v-if="item.isCalculating" indeterminate )
                  
                  div.p-relative.mb-1.pt-1( v-else @contextmenu.prevent="showItemMenu($event,item)" @click="selectNode($event,item)" )
                    .d-flex
                      v-icon.mr-1(color="#FFCF40" size="12") mdi-alert
                      div {{ item.title }}
                    div.small-gray {{ $t('section.collision.rulesFounder.foundRule') }} 

                  app-tooltip( :title="item.dataExportType.name == 'DEFAULT' ? 'Полная выгрузка' : 'Короткая выгрузка'" bottom )
                    v-img.p-absolute.top-0.unit-action.mt-6px.right-15( v-if="!item.resource" :src="item.dataExportType.name == 'DEFAULT' ? '/img/project/tools/full_unloading.svg' : '/img/project/tools/short_unloading.svg'" @click.stop="toggleExportType(item)" )
      
                  v-icon.p-absolute.top-0.right-0.unit-action.mt-6px( v-if="hover && canWorkWithWorm" color="#000" @click.stop="showItemMenu($event,item)" ) more_vert
                
  app-menu( ref="itemMenu" :menu="nodeMenu" @close="closeItemMenu" )

  add-worm-rule-dialog( v-model="addRuleDialog" :title="$t('section.worm.addRule', {name: addRuleClass ? addRuleClass.title : $t('section.classification.menuClassification.classNotSelected')} )" :savingErrorText="addRuleDialogError"  @saverule="createNewRule" )

  app-dialog-confirm( v-if="deleteItem.deleteNode"
                      v-model="deleteDialog"
                      :header="$t('section.worm.delete', {name: deleteItem.deleteNode.nodeType.title} )"
                      @confirm="deleteNode" :disabled="deletingItem")
    app-section
      app-text( v-if="deleteItem.deleteNode.nodeType.name == 'TOPIC'" ) {{ $t('section.worm.delete') }} "{{ deleteItem.deleteNode.title }}"?
      div( v-if="deleteItem.deleteNode.nodeType.name == 'RULE'" )
        app-text {{ $t('section.worm.deleteRule') }} "{{ deleteItem.deleteNode.title }}"?
        app-text( v-if="deleteItem.deleteNode.classificatorNode" ) {{ deleteItem.deleteNode.classificatorNode.pressmark }} - {{ deleteItem.deleteNode.classificatorNode.title }}
      v-checkbox( v-if="deleteItem.deleteNode.nodeType.name=='TOPIC'" v-model="deleteItem.withSubs" dense hide-details light test-id="worm-delete-incl-subs" )
        template( v-slot:label )
                app-text.mx-n1 {{$t('section.worm.includingSubsections')}}
      v-progress-linear( v-if="deletingItem" indeterminate )
      app-text( v-if="deletingItem" ) {{ $t('section.classification.menuClassification.deleting') }}
</template>

<script>

import { api } from "@/api"
import { mapState, mapGetters, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import WormNodeType from '@/assets/model/worm/WormNodeType'
import ProjectWorm from '@/assets/model/worm/ProjectWorm'
import WormNode from '@/assets/model/worm/WormNode'
import AddWormRuleDialog from '../rule/AddWormRuleDialog.vue'

export default {
  name:'worm-tree',
  
  components: {
    AddWormRuleDialog,
    draggable
  },

  props: {
    
  },

  data() {
    return {
      tree: {
        active: [],
        expand: false
      },

      editNode:null,
      addRuleClass:null,

      menuItem: null,

      addRuleDialog: false,
      addRuleDialogError: null,

      deleteDialog: false,
      deleteItem:{
        deleteNode: null,
        withSubs: false
      },
      deletingItem: false,

      grandSmeta: {
        loading: false,
        dialog: false,
        item: null,
        showelements: false,
        error: null
      },

      dragUtils:{
        openNodeTimer: null
      },

      exportWorm:{
        dialog: false,
        loading: true,
        model: false,
        wmm: false,
        plugin: null,
        node: null,
        showelements: false
      },

      rules: {
        required: (value) => (!!value && !!(value.trim())) || this.$t("error.require")
      },

      createNewNode: false,
      creatingNode: false,

      numberFormatter: new Intl.NumberFormat("ru-RU",{ maximumFractionDigits: 3, style: 'decimal', useGrouping: false }),
      listSelectedNodesDOM: [],
      selectedNodes: [],
      renderComponent: true,

      savedNodeList: []
    }
  },

  mounted() {
    if (this.editingRuleUuid) this.tree.active.push(this.editingRuleUuid)
    // this.flatAll()
    if (this.projectWormSelected && (this.wormNodeCount == 0 || !this.projectWorm.isActualCalc))  this.loadNodes()
  },

  computed: {
    ...mapState('project', ['project', 'projectSettings']),
    ...mapState('entities/WormNode', ['editingRuleUuid']),
    ...mapGetters('plugins',['wormPlugins']),
    ...mapGetters('authUser', ['canWorkWithWorm']),
    ...mapState('worm', ['showWithValidRules', 'selectedWormNode']),

    projectWorm(){
      return ProjectWorm.find(this.projectWormSelected)
    },

    nodeMenu(){
      let act = []
      if (this.menuItem) {
        if (this.menuItem.nodeType.name == 'TOPIC') {
            act.push({ title: this.$t('section.worm.export'), action: this.showExportDialog })
            act.push({ divider: true })
          if ( this.menuItem.parentUuid == null ) {
            act.push({ title: this.$t('section.classification.menuClassification.addRootPartition'), action: this.createNewRootNode })
            act.push({ divider: true })
          }
            act.push({ title: this.$t('section.classification.menuClassification.addSubsection'), action: this.addNewNode })
            act.push({ title: this.$t('section.classification.menuClassification.editSection'), action: this.showEditNode })
            act.push({ title: this.$t('section.classification.menuClassification.deleteSection'), action: this.showDelNode })
            act.push({ divider: true })
            act.push({ title: this.$t('section.classification.menuClassification.addRule'), action: this.showAddRule })
        } else if (this.menuItem.nodeType.name == 'RULE') {

          if (!this.menuItem.resource) {
            act.push({ title: this.$t('section.worm.export'), action: this.showExportDialog })
          }
            act.push({ divider: true })
            act.push({ title: this.$t('section.classification.menuClassification.deleteRule'), action: this.showDelNode })
        }
      }
      
      return act
    },

    projectWormSelected: {
      get () {
        return this.$store.state.project.projectSettings.projectWorm
      },
      set (value) {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        ps.projectWorm = value
        this.$store.dispatch('project/updateSettingsProjectWorm', ps)
      }
    },

    filterWithRules: {
      get () {
        return this.showWithValidRules
      },
      set (value) {
        this.setShowWithValidRules(value)
        if (!value) {
          this.renderComponent = false;
          this.$nextTick(() => {
            this.renderComponent = true;
          });
        }
      }
    },
    treeOpened: {
      get() {
        return this.$store.state.entities.WormNode.openedNodes
      },
      set(v) {
        
        WormNode.commit((state) => {state.openedNodes = v})
      }
    },

    types(){
      return WormNodeType.all()
    },

    isAllDefaultType() {
      return WormNode.getters('isAllDefaultType')
    },

    allNodes () {
      return WormNode.getters('allNodes')(this.projectWormSelected)
    },

    nodesTree(){
      const tree = WormNode.getters('nodesTree')(this.projectWormSelected)
      
      let sortRecursive = (tree) => {
        tree.sort((a, b) => {
          const partsA = this.splitString(a.classificatorNode?.pressmark ?? '');
          const partsB = this.splitString(b.classificatorNode?.pressmark ?? '');
          
          for (let i = 0; i < partsA.length; i++) {
            const comparison = (partsA[i] || '').localeCompare(partsB[i] || '', undefined, { numeric: true, sensitivity: 'base' });
            
            if (comparison !== 0) {
              return comparison;
            }
          }
          return 0;
        });

        tree.forEach(node => sortRecursive(node.children))
      }

      sortRecursive(tree)

      return (tree)
    },

    allTopicWithChildrenCount() {
      return WormNode.getters('allTopic')(this.projectWormSelected)
    },

    isWormNodeFetching() {
      return this.$store.state.entities.WormNode.fetching
    },

    wormNodeCount(){
      return WormNode.query().count()
    },

    filteredNodeList() {
      let currentOpened = [] 
      if (this.showWithValidRules) {
        let prefilteredList = this.$store.state.entities.WormNode.filteredNodeList.filter(el => el.hasChildren).map(el => el.uuid)
        currentOpened = this.treeOpened.filter(node => prefilteredList.includes(node))
      }
      return currentOpened
    },

    areAllTreeNodesOpened() {
      let currentNodes = this.showWithValidRules ? this.filteredNodeList : this.treeOpened
      if (this.allTopicWithChildrenCount == currentNodes.length && this.allTopicWithChildrenCount == 0) return false
      else if (this.allTopicWithChildrenCount !== currentNodes.length && this.allTopicWithChildrenCount > 0) return false
      return this.allTopicWithChildrenCount == currentNodes.length
    },
  },

  watch: {
    projectWormSelected(v){
      if (v) {
        WormNode.commit((state) => {
          state.editingRuleUuid = null
          state.openedNodes = []
        })
        
        this.loadNodes(v)
      }else {
        WormNode.commit((state) => {
          state.editingRuleUuid = null
          state.openedNodes = []
        })
        WormNode.deleteAll()
      }
    }
  },

  methods: {
    ...mapMutations('worm', ['setShowWithValidRules', 'setShowPanelHightLightGroups']),

    handleTreeUpdate(event) {
      if (event ?? null) this.savedNodeList = event
      if (!this.showWithValidRules) this.treeOpened = this.savedNodeList
    },

    splitString(str){
      return str.split(/[-.]/)
    },

    toggleAll () {
      const poolSize = 20
      if (this.wormNodeCount <= poolSize) {
        this.tree.expand = !this.areAllTreeNodesOpened
        this.$refs.wormNodesTree.updateAll(this.tree.expand)
      } else {
        if (this.areAllTreeNodesOpened) {
          this.tree.expand = false
          let l = this.treeOpened.length
          while (l > 0) {
            let count = l > poolSize ? poolSize : l
            l = l - count

            setTimeout(() => {
              this.$nextTick(() => {
                this.treeOpened.splice(l, count)
              })
            }, 10)
          }

          return
        }
        this.tree.expand = true

        let opened = [...this.treeOpened]
        let left = this.allNodes.filter(n => !opened.includes(n.uuid))
        let temp = []
        for (let idx = 0; idx < left.length; idx++) {
          const node = left[idx];
          if (idx % poolSize == 0 && temp.length) {
            let temp2 = [...temp]
            temp = []

            setTimeout(() => {
              this.$nextTick(() => {
                this.treeOpened.push(...temp2)
              })
            }, 10);
          } else {
            temp.push(node.uuid)
          }
        }

      }
    },

    loadNodes(){
      WormNode.api().getProjectClassificators(this.project.uuid).then(() => {
        WormNode.deleteAll()
        WormNode.api().fetchByProjectWorm(this.projectWormSelected).then(() => {
          const rule = WormNode.find(this.selectedWormNode)
          let parentUuid = rule.parentUuid
          while (parentUuid) { 
            if (!this.treeOpened.includes(parentUuid)) {
              this.treeOpened.push(parentUuid)
            }
            parentUuid = WormNode.find(parentUuid)?.parentUuid ?? null
          }
          this.selectNode(null, rule)
        })
      })
    },

    recalculateWorm() {
      WormNode.commit((state) => {state.fetching = true})
      api.worm.recalculateprojectworm(this.projectWormSelected).then(data => {
        WormNode.commit((state) => {state.fetching = false})
        ProjectWorm.update({where: this.projectWormSelected, data: data})
        WormNode.api().fetchByProjectWorm(this.projectWormSelected)
      })
    },

    toggleExportType(item){
      let type = {value: 0, name: 'DEFAULT', title: this.$t('button.default')}
      if (item.dataExportType.value == 0) type = {value: 1, name: 'SHORT', title: this.$t('section.worm.brief')}
      
      WormNode.api().patch(`/worm/node/${item.uuid}/setexporttype`, type, {save: false})
        .then(() => WormNode.update({where: item.uuid, data: {dataExportType: type}}))
        .catch(e => console.error(e))
    },

    toggleAllDataExportType(){
      let type = {value: 0, name: 'DEFAULT', title: this.$t('button.default')}
      if (this.isAllDefaultType) type = {value: 1, name: 'SHORT', title: this.$t('section.worm.brief')}

      WormNode.api().patch(`/worm/${this.projectWormSelected}/node/setallexporttype`, type, {save: false})
        .then(() => WormNode.update({where: record => record.projectWormUuid == this.projectWormSelected && record.nodeType.name === 'RULE', data: {dataExportType: type}}))
        .catch(e => console.error(e))
    },

    showItemMenu(event, item) {
      if(this.canWorkWithWorm) {
        this.menuItem = item
        this.$refs.itemMenu.show(event)
      }
    },

    closeItemMenu() {
      this.menuItem = null
    },

    checkStart: function(e, item) {
      this.cancelEditNode()
      let itemSelected = null
      clearTimeout(this.dragUtils.openNodeTimer)
      if(item){
        if(this.selectedNodes.length == 0){
          this.selectedNodes.push(this.item)
          this.listSelectedNodesDOM.push(e.item)
        } else {
          itemSelected = this.selectedNodes?.find(el => el.uuid == item.uuid);
        }
        if(!itemSelected){
          this.listSelectedNodesDOM.map(el => {
            el.offsetParent.style.background = "transparent"
          })
          this.selectedNodes = []
          this.selectedNodes.push(item)
          this.listSelectedNodesDOM = []
          this.listSelectedNodesDOM.push(e.target)
        }else {
          this.listSelectedNodesDOM.map(el => {
            el.offsetParent.style.background = "transparent"
            el.style.display = "none"
          })
        }
      }
      if (this.treeOpened.includes(e.to.id)) {
        let ind = this.treeOpened.findIndex(el => el == e.to.id)
        if (ind > -1) this.treeOpened.splice(ind,1)
      }
    },

    checkMove: function(e) {
      clearTimeout(this.dragUtils.openNodeTimer)
      let nodeTo = WormNode.find(e.to.id)
      if (e.to.id != e.from.id && nodeTo.nodeType.name == 'TOPIC' && !this.treeOpened.includes(e.to.id)) {
        this.dragUtils.openNodeTimer = setTimeout(() => {
          this.treeOpened.push(e.to.id)
        }, 500)
      }
    },

    checkEnd(e) {
      clearTimeout(this.dragUtils.openNodeTimer)
      if(this.selectedNodes.length > 0 && WormNode.find(e.from.id)){
        this.selectedNodes.map(node => {
          this.endDrag(e,node)
          this.selectedNodes = []
          this.listSelectedNodesDOM.map(el => {
            if(el.offsetParent) el.offsetParent.style.background = "transparent"
            el.style.display = "block"
          })
          this.listSelectedNodesDOM = []

        })
      }else {
        this.endDrag(e,null)
      }
    },

    endDrag(e,node){
      if (e.from.id != e.to.id) {

        let source = node ? WormNode.find(node.uuid) : WormNode.find(e.from.id)
        let target = WormNode.find(e.to.id)

        let targetParentNode = null
        if (target.parentUuid != null || source.nodeType.name == 'TOPIC'){
          
          targetParentNode = WormNode.find(target.parentUuid)
          let targetParentChildrens = WormNode.query().where('parentUuid', targetParentNode ? targetParentNode.uuid : null).orderBy('srt').orderBy('pressmark').orderBy('title').orderBy('uuid').get()

          if (source.parentUuid == null || !source.parentUuid != target.parentUuid) {
            WormNode.update({where: source.uuid, data: {parentUuid: source.parentUuid}})
          }

          let sourceIndex = targetParentChildrens.findIndex(e => e.uuid == source.uuid);
          let targetIndex = targetParentChildrens.findIndex(e => e.uuid == target.uuid);

          if (sourceIndex > -1) {
            targetParentChildrens.splice(sourceIndex,1)
          }
          targetParentChildrens.splice(targetIndex, 0, source);

          let outSort = 0
          for (let n of targetParentChildrens) {
            WormNode.update({where: n.uuid, data: {srt: outSort}})
            outSort++;
          }
          WormNode.api().patch(`/worm/projectworm/movenode?source=${source.uuid}&target=${target.uuid}`).then(async () => {
            this.renderComponent = false;
            await this.$nextTick(() => {
              this.renderComponent = true;
            });
          })
        }
      }
    },

    createNewRootNode(){
      let newNode = new WormNode()
      newNode.nodeType = WormNodeType.find('TOPIC')
      newNode.projectWormUuid = this.projectWormSelected
      newNode.title = this.$t('section.classification.menuClassification.newSection')
      WormNode.api().post(`/worm/projectworm/createnode`,newNode).then(data => {
        this.cancelEditNode()
        this.editNode = Object.assign({},WormNode.find(data.entities.WormNode[0].uuid))
        WormNode.update({where: this.editNode.uuid, data: {isEdit: true}})
        this.createNewNode = true
        this.creatingNode = data
      })
    },

    addNewNode(){
      let newNode = new WormNode()
      newNode.parentUuid = this.menuItem.uuid
      newNode.nodeType = WormNodeType.find('TOPIC')
      newNode.projectWormUuid = this.projectWormSelected
      newNode.title = this.$t('section.classification.menuClassification.newSection')
      newNode.srt = 0
      WormNode.api().post(`/worm/projectworm/createnode`,newNode).then(data => {
        if (!this.treeOpened.includes(newNode.parentUuid)) this.treeOpened.push(newNode.parentUuid)

        this.cancelEditNode()
        this.editNode = Object.assign({},WormNode.find(data.entities.WormNode[0].uuid))
        WormNode.update({where: this.editNode.uuid, data: {isEdit: true}})
        let parentNode = WormNode.find(this.editNode.parentUuid)
        WormNode.update({where: parentNode.uuid, data: {hasChildren: true}})
        this.createNewNode = true
      })
    },

    showAddRule(){
      this.addRuleDialogError = null
      this.addRuleClass = this.menuItem
      this.addRuleDialog = true
    },

    createNewRule(rules){
      let toSave = []
      for (let rule of rules) {
        if (rule.type.name == 'RULE') {
          let newNode = new WormNode()
          newNode.parentUuid = this.addRuleClass.uuid
          newNode.nodeType = WormNodeType.find('RULE')
          newNode.projectWormUuid = this.projectWormSelected
          newNode.classificatorFindRuleUuid = rule.uuid
          toSave.push(newNode)
        }
      }
      if (toSave.length > 0) {
        WormNode.api().post(`/worm/projectworm/createrules`,toSave).then(data => {
          if (!this.treeOpened.includes(data.entities.WormNode[0].parentUuid)) this.treeOpened.push(data.entities.WormNode[0].parentUuid)
          this.selectNode(null,WormNode.find(data.entities.WormNode[0].uuid))
            
          WormNode.update({where:  this.addRuleClass.uuid, data: {hasChildren: true}}).then(async () => {
              data.entities.WormNode.forEach(el => {
                WormNode.update({ where: el.uuid, data: {hasValidRules: el.logicValidy?.valid} }).then()
              })
            })
            
            this.addRuleClass = null
            this.addRuleDialog = false
        }).catch(e => this.addRuleDialogError = e)
      }
    },

    showEditNode(){
      this.cancelEditNode()
      this.editNode = Object.assign({},this.menuItem)
      WormNode.update({where: this.menuItem.uuid, data: {isEdit: true}})
    },

    cancelNewEditNode(){
      // WormNode.query().where('isEdit', true).all().forEach(el => WormNode.update({where: el.uuid, data: {isEdit: false}}))
      this.editNode = null
    },

    cancelEditNode(item){
      WormNode.query().where('isEdit', true).all().forEach(el => WormNode.update({where: el.uuid, data: {isEdit: false}}))
      if(this.createNewNode){
        this.deletingItem = false
        this.deleteItem.withSubs = false
        this.deleteItem.deleteNode = item
        this.deleteNode()
        this.createNewNode = false
      }
      this.editNode = null
    },

    saveNode(){
      this.createNewNode = false
      WormNode.api().put(`/worm/projectworm/editnode`,this.editNode).then(() => this.editNode = null)
      this.cancelEditNode()
    },

    selectNode(event, item) {
      this.cancelEditNode()
      if(!item.classificatorNode){
        this.setShowPanelHightLightGroups(false)
      }
      if(event && event.shiftKey && item.nodeType.name == 'RULE'){
        if(this.selectedNodes.length > 0 && this.selectedNodes.includes(item)){
          let index = this.selectedNodes.findIndex(el => el.uuid == item.uuid)
          if (index !== -1) {
            this.selectedNodes.splice(index, 1)
          }
          event.target.offsetParent.offsetParent.style.background = "transparent"
          index = this.listSelectedNodesDOM.findIndex(el => el == event.target.offsetParent)
          if (index !== -1) {
            this.listSelectedNodesDOM.splice(index, 1)
          }
        } else {
          event.target.offsetParent.offsetParent.style.background = "var(--v-primary-lighten4)"
          this.listSelectedNodesDOM.push(event.target.offsetParent)
          this.selectedNodes.push(item)
        }

      } else {
        this.listSelectedNodesDOM.map(node => {
          node.offsetParent.style.background = "transparent"
        })
        this.listSelectedNodesDOM = []
        this.selectedNodes = []
      }

      if (item.nodeType.name == 'TOPIC'){
        let i = this.treeOpened.findIndex(e => e == item.uuid)
        if (i>=0) this.treeOpened.splice(i,1)
          else this.treeOpened.push(item.uuid)
                } else if (item.nodeType.name == 'RULE'){
        if (this.editingRuleUuid == item.uuid) {
          WormNode.commit((state) => {state.editingRuleUuid = null})
          this.tree.active = []
        } else {
          WormNode.commit((state) => {state.editingRuleUuid = item.uuid})
          this.tree.active = []
          this.tree.active.push(item.uuid)
        }
      }
    },

    showDelNode(){
      this.deletingItem = false
      this.deleteItem.withSubs = false
      this.deleteItem.deleteNode = this.menuItem
      this.deleteDialog = true
    },

    deleteNode(){
      this.deletingItem = true
      WormNode.api().delete(`/worm/projectworm/deletenode/${this.deleteItem.deleteNode.uuid}?deep=${this.deleteItem.withSubs}`).then(() => {

        if (this.editingRuleUuid == this.deleteItem.deleteNode.uuid) {
          this.cancelEditNode()
          WormNode.commit((state) => {state.editingRuleUuid = null})
        }

        WormNode.deleteAll()
        WormNode.api().fetchByProjectWorm(this.projectWormSelected).then(() => {
          if(!this.allNodes.some(node => node.uuid == this.editingRuleUuid)){
            this.cancelEditNode()
            WormNode.commit((state) => {state.editingRuleUuid = null})
          }
        })
        this.deleteDialog = false
        this.deleteItem.deleteNode
        this.deletingItem = false
        this.deleteItem.withSubs = false
      })
    },

    // EXPORTINGS
    showExportDialog(){
      let node = this.menuItem
      this.$emit('showExportDialog',node)
    }
  }
}
</script>

<style lang="scss" scoped>
.center{
  padding-bottom: 1px;
}
.min-width-30 {
  min-width: 30px !important;
}
.flex-grow {
  flex-grow: 0.25 !important;
}
.text-overflow {
  text-overflow: ellipsis !important;
  white-space:nowrap;
  overflow: hidden !important;
}
.right-15 {
  right: 15px;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}

.nodeLabel {
  text-overflow: ellipsis;
  overflow: hidden;
}
.bold {
  font-weight: bold;
}
.mt-6px {
  margin-top: 6px;
}
.small-gray {
  font-size:10px;
  color:gray;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
}

::v-deep.text-nopadding {
  .v-text-field__details {
    padding: 0px;
    white-space: normal;
  }
}

.worm-tree__filter_text {
  color: var(--v-accent-base); 
  font-size: 10px; 
  text-transform: uppercase;
}
</style>