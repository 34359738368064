<template lang="pug">
  app-dialog( v-model="$_show" v-bind='$attrs' :confirm-disabled="!folderReady" @confirm="onSavingFolder" :confirmText="$t('button.save')" )
    app-section
      app-text-field( v-model="title" :label="$t('section.collision.rulesFounder.groupName')"  test-id="group-name")
      app-select( v-if="hasFolders" v-model="parentUuid" :items="preparedFolders" item-text='title' item-value="uuid" :label="$t('section.collision.rulesFounder.parentElement')" clearable hideDetails test-id="parent-folder")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

export default {
  mixins: [DialogToggable],

  props: {
    folder: Object,
    parent: Object,
  },

  data: () => ({ title: null, parentUuid: null }),

  computed: {
    ...mapGetters('rule', ['folderFlatList']),

    hasFolders () {
      return this.folderFlatList.length > 0
    },

    folderReady () {
      return !!this.title && !!this.title.trim()
    },

    folderUuid () {
      return this.folder && this.folder.uuid
    },

    preparedFolders () {
      let exclusions = this.folderUuid ? [this.folderUuid] : []
      return this.folderFlatList.map(one => {
        let disabled = exclusions.includes(one.uuid)
        if (disabled) {
          exclusions.push(...one.children.map(i => i.uuid))
        }
        let text = Array(+one.level).join('| - ').concat(one.title)
        return { ...one, text, disabled }
      })
    },
  },

  watch: {
    folder: {
      handler (val) { this.title = val && val.title },
      immediate: true
    },
    parent: {
      handler (val) { this.parentUuid = val && val.uuid }, 
      immediate: true
    }
  },

  methods: {
    ...mapActions('rule', ['saveFolder']),

    onSavingFolder () {
      let data = { parent: this.parentUuid, title: this.title, uuid: this.folderUuid }
      this.saveFolder(data)
    },
  }
}
</script>

