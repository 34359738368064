<template lang="pug">
  .d-flex.justify-center.mb-7( style="width: 300px; margin: 0 0 0 -140px;" )
    v-slider(
      v-model="sliderValue" 
      min="0" max="100" step="1" 
      append-icon="add" prepend-icon="remove"
      @click:append="zoomIn"
      @click:prepend="zoomOut"
      @change="zoom"
    )
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

const SLIDER_STEP = 1
let zoomLastValue = 0

export default {
  data () {
    return {
      value: 50,
    }
  },

  computed: {
    sliderValue: {
      get () {
        return +this.value
      },

      set (val) {
        this.value = +val
      }
    },
  },

  mounted () {
    XeokitMediator.onReady(this.init)
  },
  
  methods: {
    init (viewer) {
      viewer.camera.on("eye", this.correctZoom)
      viewer.camera.on("look", this.correctZoom)
    },

    zoomOut () {
      this.sliderValue = Math.max(this.sliderValue - SLIDER_STEP, 0)
      this.zoom()
    },

    zoomIn () {
      this.sliderValue = Math.min(this.sliderValue + SLIDER_STEP, 100)
      this.zoom()
    },

    zoom () {
      XeokitMediator.viewer.camera.zoom(zoomLastValue - this.sliderValue)
      zoomLastValue = this.sliderValue
    },

    correctZoom () {
      zoomLastValue = Math.round(100 - XeokitMediator.viewer.camera.eyeLookDist)
      this.sliderValue = zoomLastValue
    },
  }
}
</script>