<template lang="pug">
.app-toolkit-column
  ToolButtonGrid
</template>

<script>
import ToolButtonGrid from './buttons/ToolButtonGrid.vue'

export default {
  components: {
    ToolButtonGrid,
  },
}
</script>