<template lang="pug">
  div(id="collisiontable" ref="collisiontable")
    v-data-table(
      dense
      :headers="headersWithTranslate"
      :items="collisionsLimited"
      disable-pagination
      hide-default-footer
      :show-select="getVisibleCollisionTable"
      item-key="uuid"
      :height="tableHeight"
      :custom-sort="tableSort"
      :disable-sort="taskCollision.isActive"
      light
      )

      template( v-slot:top )
        collision-list-top-slider(v-if="!tableIsHiden" :height.sync="tableHeight" @onSlide="changeSliderHeight")
        collision-list-top.top(@showMenu="openHeaderMenu" @hide="tableIsHiden=$event" @changeItems="changeItems")
        v-progress-linear(v-if="isLoadingCollision || (loadElement.status && loadElement.isNeedWait)" indeterminate color="accent")

      template(v-slot:header.data-table-select="{ on , props }")
        v-checkbox(v-model="headerSelect" test-id="table-header-check" @change="selectAll" dense hide-details @click.stop :indeterminate="isPartItems" color="accent" :ripple="false" @click="deletePickedElement")

      template( v-slot:item="{item, index}")
        collision-list-body(:item="item" :class='{_highlight: item._highlight }' @rowMenu="openRowMenu" test-id="collision-table-row" @statusMenu="openStatusMenu" @group="toggleGroup" @groupMenu="openGroupMenu" @click="deletePickedElement") 

      template(v-if="start > 0" v-slot:body.prepend)
        tr
          td(:colspan="visibleHeaders.length" :style="'padding-top:' + startHeight + 'px'" )

      template(v-if="start + perPage < itemsWithoutHidden.length" v-slot:body.append)
        tr(v-if="getVisibleCollisionTable")
          td(:colspan="visibleHeaders.length" :style="'padding-bottom:' + endHeight + 'px'")    

    collision-list-menu(ref="menu" @goToView="goToGroupCamera")
    app-menu(ref="status-menu" :menu="statusMenuItems")
</template>

<script>
import CollisionListTop from './components/collisionList/top/CollisionListTop.vue'
import CollisionListTopSlider from './components/collisionList/top/CollisionListTopSlider.vue'
import CollisionListBody from './components/collisionList/CollisionListBody.vue'
import CollisionListMenu from './components/collisionList/CollisionListMenu.vue'
import CollisionHighlightService from './components/collision/CollisionHighlightService.js'
import TableSliderService from './components/collision/CollisionTableSliderService.js'
import { useSelectedElementsStore } from '@/pinia'
import { mapStores } from 'pinia'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import store from '@/store'

export default {
  components: {
    CollisionListTop,
    CollisionListTopSlider,
    CollisionListMenu,
    CollisionListBody,
  },

  data() {
    return {
      timeout: null,
      timeout2: null,
      rowHeight: 32,
      perPage: 30,
      columnSize: {},
      tableHeight: 350,
      headerSelect: false,
      selected: [],
      sort: null,
      collision: null,
      tableIsHiden: false,
      tableIsOpen: false,
      statusFloatingPanel: true,
    }
  },

    computed: {
    ...mapStores(useSelectedElementsStore),
    ...mapState('collision/search', ['collisionSelectedElements', 'intersectionModel', 'collisionSelectedGlobalIds', 'loadElement']),
    ...mapGetters('collision/search', ['isLoadingCollision', 'preparedCollisionsTask']),

    ...mapState('collision/table', ['tableItems', 'selectedGroup', 'taskCollision', 'rowStart']),
    ...mapGetters('collision/table', [
      'itemsWithoutHidden',
      'visibleHeaders',
      'selectedCollisions',
      'collisionGroupsIndex',
      'selectedCollisionsElementAB',
      'getVisibleCollisionTable'
    ]),
    ...mapGetters('projectPermissions', ['hasCollisionStatus', 'hasCollisionResolution']),

    ...mapState('axis/tree', ['items']),

    start: {
      get() {
        return this.rowStart
      },
      set(value) {
        this.SET_ROW_START(value)
      },
    },

    statusMenuItems() {
      let list = []
      if(this.hasCollisionStatus) {
        list = [
          {
            title: this.$t('module.task.collisionNew'),
            action: () => this.setStatus('NEW'),
            disabled: this.collision?.status.name === 'NEW',
          },
          {
            title: this.$t('module.task.collisionInWork'),
            action: () => this.setStatus('WORK'),
            disabled: this.collision?.status.name === 'WORK',
          },
        ]
        
        if(this.hasCollisionResolution) {
          list.push(
            {
            title: this.$t('module.task.collisionAllowed'),
            action: () => this.setStatus('ALLOWED'),
            disabled: this.collision?.status.name === 'ALLOWED',
            }
          )
        }
      }

      return list
    },

    isPartItems() {
      let selectedSize = this.selectedCollisions.length + this.selectedGroup.length
      return selectedSize < this.tableItems.length && selectedSize > 0
    },

    headersWithTranslate() {
      this.visibleHeaders.forEach((header) => {
        header.text = this.$t('section.collision.table.headers.' + header.value)
      })
      return this.visibleHeaders
    },

    collisionsLimited() {
      if(this.getVisibleCollisionTable) {
        let lastGroupIdx = 0
        return this.itemsWithoutHidden.slice(this.start, this.perPage + this.start).map((item, idx) => {
          if (item?.groupTitle) {
            lastGroupIdx = idx
            return item
          }

          const itemInGroupIdx = idx - lastGroupIdx - 1
          item._highlight = this.taskCollision.isActive ? !(idx % 2) : !(itemInGroupIdx % 2)
          return item
        })
      } else {
        return [
          {
            null: null,
          }
        ]
      }
    },

    startHeight() {
      return this.start * this.rowHeight - 32
    },

    endHeight() {
      return this.rowHeight * (this.itemsWithoutHidden.length - (this.start + this.collisionsLimited.length))
    },
  },

  watch: {
    selectedCollisions: 'updateCollisionSelect',
    collisionSelectedElements: 'highlightCollisions',
    intersectionModel: 'settingSelectedObjects',

    itemsWithoutHidden() {
      this.$nextTick(this.addSliders)
    },

    visibleHeaders() {
      this.$nextTick(this.addSliders)
    },
  },

  mounted() {
    this.refreshSettings()
    TableSliderService.usePinia = false
    document.getElementsByClassName('v-data-table__wrapper')[0].onscroll = this.onScroll
    this.getCollisionTypes()
    CollisionHighlightService.onMouseclickedCollision()

    this.refreshTable()
  },

  destroyed() {
    let canvas = document.getElementById('myCanvas')
    let measure = document.getElementById('measurementScrim')
    let axis = document.getElementById('myAxisCanvas')
    if (canvas) canvas.height = window.innerHeight
    if (measure) measure.style.paddingBottom = 0
    if (axis) axis.style.paddingBottom = 0
    CollisionHighlightService.collisionPanelDestroy()
    CollisionHighlightService.offMouseclickedCollision()
    this.$store.commit('collision/table/SET_TABLE_ITEMS_FOR_REPLACE', [])
    this.SET_ROW_START(0)
    this.tableIsOpen = false
  },

  methods: {
    ...mapActions('collision', ['setCollisionStatus']),
    ...mapActions('collision/search', ['getCollisionTypes', 'setChoosenGlobalId', 'deselectCollision']),
    ...mapActions('axis/tree', ['fetchElementByGlobalId', 'fetchTree']),

    ...mapActions('collision/table', ['selectCollisions', 'refreshSettings', 'updateCollisionsList', 'setTableSort']),
    ...mapMutations('collision/table', [
      'SET_HIDDEN_ITEMS',
      'SET_SELECTED_GROUP',
      'CHANGE_COLLISION_SOURCE',
      'SET_CLOSED_GROUP',
      'SET_ROW_START',
    ]),

    refreshTable() {
      if ((this.items && this.items.length > 0) && (!this.itemsWithoutHidden || this.itemsWithoutHidden.length === 0) && !this.isLoadingCollision) {
        this.fetchTree()
      }
    },

    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout)
      this.timeout2 && clearTimeout(this.timeout2)

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target
        const rows = Math.ceil(scrollTop / this.rowHeight)
        let start = rows + this.perPage > this.itemsWithoutHidden.length ? this.itemsWithoutHidden.length - this.perPage : rows

        this.start = start < 0 ? 0 : start

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop //firefox fix
          this.resizeHeaders()
        })
      }, 15)

      this.timeout2 = setTimeout(() => {
        this.updateCollisionsList()
      }, 25)
    },

    changeItems(isTaskCollision) {
      this.selectCollisions([])
      this.SET_SELECTED_GROUP([])
      if (isTaskCollision) {
        this.CHANGE_COLLISION_SOURCE({ isActive: isTaskCollision, list: this.preparedCollisionsTask })
      } else {
        this.CHANGE_COLLISION_SOURCE({ isActive: isTaskCollision })
      }
      this.updateCollisionsList()
    },

    selectAll() {
      if (this.headerSelect) {
        let list = this.taskCollision.isActive ? [...this.tableItems] : []

        if (!this.taskCollision.isActive) {
          for (let i in this.tableItems) {
            if (this.tableItems[i].globalIdA || this.tableItems[i].globalIdB || !this.tableItems[i].groupTitle) {
              list.push(this.tableItems[i])
            }
          }
          let groups = []
          this.collisionGroupsIndex.forEach((index) => groups.push(this.tableItems[index]))
          this.SET_SELECTED_GROUP(groups)
        }

        this.selectCollisions(list)
      } else {
        this.SET_SELECTED_GROUP([])
        this.selectCollisions([])
      }
    },

    updateCollisionSelect() { 
      this.deletePickedElement()
      this.tableItems.forEach((item) => (item.selected = false))

      if (this.selectedCollisions.length) {
        this.selectedCollisions.forEach((collision) => (this.tableItems[collision.index].selected = true))
        if (!this.taskCollision.isActive) {
          this.selectedGroup.forEach((group) => (this.tableItems[group.index].selected = true))
        }
      } else {
        if(!this.selectedElementsStore?.pickedElement) {
          this.deselectCollision()
        }
        this.SET_SELECTED_GROUP([])
      }
      
      this.headerSelect = this.selectedCollisions.length !== 0
      if(!this.selectedElementsStore?.pickedElement) {
        this.highlightCollisions(false)
      }
    },

    deletePickedElement() {
      if(this.tableIsOpen) {
        store.dispatch('axis/tree/fetchElementByGlobalId', null)
        this.selectedElementsStore.pickedElement = null
        this.deselectCollision()
        this.highlightCollisions(false)
        this.selectedElementsStore.setStatusFloatingPanel(this.statusFloatingPanel)
      }
      this.tableIsOpen = true
    },

    highlightCollisions(needIntersection = true) {
      CollisionHighlightService.highlightSelectedCollision(this.$root)
      if (needIntersection) CollisionHighlightService.collisionBalloonAndIntersection()
    },

    settingSelectedObjects() {
      CollisionHighlightService.settingSelectedObjects()
    },

    toggleGroup(group) {
      this.SET_CLOSED_GROUP(group)
      this.SET_HIDDEN_ITEMS(group)
      this.updateCollisionsList()
      this.$nextTick(this.addSliders)
      if (group.isOpen) this.goToGroupCamera(group.groupUuid)
    },

    tableSort(items, sortBy, sortDesc) {
      let options
      if (sortBy[0] != undefined) {
        options = {
          value: sortBy[0],
          sort: sortDesc[0] ? 'DESC' : 'ASC',
        }
      } else {
        options = {
          value: 'displayedDate',
          sort: 'DESC',
        }
      }

      this.setTableSort(options)

      return this.collisionsLimited
    },

    openStatusMenu($event, item) {
      this.collision = item
      const statusMenu = this.$refs['status-menu']
      if (statusMenu.showMenu) {
        this.$refs['status-menu'].close()
      } else {
        this.$refs['status-menu'].show($event)
      }
    },

    openRowMenu(event, item) {
      this.$refs.menu.showMenu(event, item || item.item, 'row')
    },

    openHeaderMenu(event) {
      this.$refs.menu.showMenu(event, null, 'header')
    },

    openGroupMenu(event, item) {
      this.$refs.menu.showMenu(event, item, 'group')
    },

    setStatus(statusName) {
      this.setCollisionStatus({ uuid: this.collision.uuid, status: statusName, prevStatus: this.collision.status.name }).then(
        (collision) => {
          this.collision.displayedStatus = collision.status.title
        }
      )
    },

    resizeHeaders() {
      TableSliderService.resizeHeaders(this.columnSize)
    },

    addSliders() {
      TableSliderService.addSliders(this.getTableHeight(), this.visibleHeaders)
    },

    changeSliderHeight() {
      TableSliderService.changeSliderHeight(this.getTableHeight())
    },

    getTableHeight() {
      if (this.collisionsLimited.length < this.perPage) {
        let height = this.collisionsLimited.length * this.rowHeight + 32
        return height > this.tableHeight ? this.tableHeight : height
      }
      return this.tableHeight
    },

    goToGroupCamera(groupUuid) {
      if (groupUuid) CollisionHighlightService.goToGroupCamera(groupUuid)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table {
  width: 100%;
  overflow-x: hidden;
}

::v-deep .v-data-table-header tr{
  background-color: var(--v-surface-lighten1);
}

::v-deep .v-data-table-header tr th span{
  color: var(--v-primary-darken2)
}

::v-deep .v-data-table-header tr th i {
  color: var(--v-primary-darken2) !important;
}

::v-deep .v-data-table-header tr th:first-child{
  background-color: #fff;
}

::v-deep .v-data-table-header tr th:first-child i {
  color: var(--v-accent-base) !important;
}

._highlight {
  background-color: #f0f0f0;
}

::v-deep th,
::v-deep td {
  border-right: 1px solid var(--v-surface-base);
}

::v-deep th {
  white-space: nowrap;
}

::v-deep tr td {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  height: 32px !important;
}
::v-deep .v-data-table-header {
  position: sticky;
  z-index: 10;
  background-color: var(--v-surface-lighten2);
  top: 0;
}
.top {
  background-color: var(--v-surface-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}

@-moz-document url-prefix() {
  .v-data-table__wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--v-accent-base) var(--v-scroll-base)
  }
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
