<template lang='pug'>
app-dialog(v-model='$_show' v-bind='$attrs' @confirm='confirm()')
  app-section
    app-select(v-model='selected' :items='models' :label="$t('module.task.models')" item-text='title' item-value='uuid' multiple clearable )

</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'

export default {
  name: "TaskModelsSelectDialog",

  mixins: [DialogToggable],

  props: {
    models: Array,
    selectedModels: Array,
  },

  data() {
    return {
      selected: []
    }
  },

  watch: {
    $_show() {
      this.selected = this.selectedModels
    },
  },

  methods: {
    confirm() {
      this.$emit('confirm', this.selected)
    }
  }
}
</script>

<style>

</style>