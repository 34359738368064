<template lang='pug'>
div.md-render(v-html='renderHtml' :class='{ "pointer": $listeners.click }' v-on='$listeners')
</template> 

<script>
import MarkdownIt from 'markdown-it/lib'
const md = new MarkdownIt({ breaks: true })

export default {
  name: "MarkdownText",
  
  props: {
    text: String
  },

  computed: {
    renderHtml() {
      const primary = this.text || ''
      return md.render(primary)
    }
  }

}
</script>

<style>
  .md-render p {
    margin-bottom: 0px;
  }

  .md-render img {
    width: 100%;
  }
</style>