<template lang="pug">
div
  div( v-if="task.priority && !onlyMenu" :class="{'priority':hasTaskStatusPriority}" @click="showPriorityMenu" )
    v-img.priority-icon( width='5' height='16' :src="`/img/project/tools/${checkTaskPriority}`" )
  
  app-menu( ref="priorityMenu" :menu="priorityMenu" )
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: "TaskPriority",
  
  props: {
    task: Object,
    useMenu: Boolean,
    onlyMenu: Boolean,
    theme: String
  },
  
  computed: {
    ...mapState('task', ['priorities']),
    ...mapGetters('theme', ['themeName']),
    ...mapGetters("projectPermissions", [ "hasTaskStatusPriority"]),

    priorityMenu() {
      return this.priorities.map(p => ({ ...p, action: () => { this.setPriority(p.name) } }))
    },

    checkTaskPriority() {
      switch(this.task.priority.name){
        case "LOW":
          return `task_priority_${this.theme || this.themeName}_1.svg`
        case "NORMAL":
          return `task_priority_${this.theme || this.themeName}_2.svg`
        case "HIGH":
          return `task_priority_${this.theme || this.themeName}_3.svg`
      }
      return false
    },
  },

  methods: {
    ...mapActions('task', ['changeTaskPriority']),

    showPriorityMenu(event) {
      if(this.useMenu && this.hasTaskStatusPriority) this.$refs.priorityMenu.show(event)
    },

    setPriority(priority) {
      this.changeTaskPriority({ uuid: this.task.uuid, priority })
    },
  }
}
</script>

<style lang="scss" scoped>
.priority:hover{
  cursor: pointer;
  filter: brightness(150%);
  color: var(--v-primary-lighten3);
}
</style>