var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"table-container base-scroll-body"},[_c('v-data-table',{staticClass:"flex-table",attrs:{"light":"","fixed-header":"","headers":_vm.translationHeaders,"items":_vm.visibleTask,"hide-default-footer":true,"items-per-page":_vm.visibleTask.length,"sort-by":_vm.sortBy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('TaskTreeRowTheme',{staticClass:"table__td pointer",attrs:{"task":item,"flat":_vm.isFilter || !!_vm.sortBy.length,"open":_vm.taskTreeCache.isOpened(item),"test-id":"theme-table-tree"},on:{"expand":function($event){return _vm.toggleOpen(item)}},nativeOn:{"click":function($event){return _vm.setSelected(item)}}})]}},{key:"item.completeStatus",fn:function(ref){
var item = ref.item;
return [_c('TaskTreeRowStatus',{staticClass:"table__td",attrs:{"task":item}})]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('TaskTreeRowProgress',{staticClass:"table__td sizeW",attrs:{"task":item},nativeOn:{"click":function($event){$event.stopPropagation();_vm.reportTask = item}}})]}},{key:"item.workers",fn:function(ref){
var item = ref.item;
return [_c('TaskTreeRowExecutor',{staticClass:"table__td",attrs:{"task":item}})]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('TaskTreeRowDate',{staticClass:"table__td",attrs:{"task":item,"test-id":"start-date-table-tree","start":""}})]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('TaskTreeRowDate',{staticClass:"table__td",attrs:{"task":item}})]}}])}),(_vm.reportTask)?_c('task-report',{attrs:{"selectedTask":_vm.reportTask},on:{"close":function($event){_vm.reportTask = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }