import { defineStore } from 'pinia'

export const ModalComponent = Object.freeze({
  GroupMenu: 1,
  EditPalette: 2,
  EditGroup: 3,
})

export const useGroupsWindowAssembly = defineStore('groups-window-assembly', {
  state: () => ({
    activeModalComponent: null,
    groupUuid: null,
    disabledAllGroups: null,
    selectedElement: null,
    menuPosition: {
      x: 0,
      y: 0,
    }
  }),

  getters: {
    isGroupingDialogVisible () {
      return this.activeModalComponent === ModalComponent.EditGroup
    },
    isPaletteDialogVisible () {
      return this.activeModalComponent === ModalComponent.EditPalette
    },
    isGroupMenuVisible () {
      return this.activeModalComponent === ModalComponent.GroupMenu
    },
  },

  actions: {
    closeGroupingDialog () {
      this.activeModalComponent = null
    }
  }
})
