<template lang="pug">
div.flex
  app-section(style='font-size: 12px; color: #000')
    div(v-if='hasTaskTypeForm')
      div.d-flex.align-center.mb-3
        div {{ $t('module.task.taskType') }} <span class='black--text' test-id="task-form-section-title">{{ taskType.form.name }}</span>
        task-button-action.ml-auto(icon="project/tools/edit-nofill.svg" size='16' @click="fillFormFields()")
      
      div.mt-1(v-for='field in filterFields')
        div.field-value {{ field.field.name }}: <span class='black--text' test-id="task-forms-section-text">{{ field.value }}</span>

  fill-form(v-model="dialog.fillFormFields" :title="$t('module.task.editTaskType')" :selectedTask="selectedTask" :formId="taskType.form.uuid" :edit="true" @updateFormData="onUpdateFormData")
  task-form-type-dialog(v-model="dialog.fillForm" :selectedTask="selectedTask" @updateForm="onUpdateForm")
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import TaskFormTypeDialog from '@/components/task/taskDetailsSections/TaskFormTypeDialog'
import FillForm from '@/pages/admin/forms/Form.vue'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  name: "TaskFormsSection",

  data() {
    return {
      dialog: {
       fillForm: false,
       fillFormFields: false,
      },
    }
  },

  props: {
    selectedTask: {
      type: Object,
      required: true,
    },
  },

  components: {
    FillForm,
    TaskFormTypeDialog,
    TaskButtonAction
  },

  computed: {
    ...mapGetters('forms', ['form']),
    ...mapGetters('project', ['projectUuid']),
    ...mapState('task', ['creatingTask']),

    hasTaskTypeForm() {
      return !!this.selectedTask.taskType?.form
    },

    taskType() {
      return this.selectedTask.taskType
    },

    fields() {
      //TODO - Переделать
      if (!this.hasTaskTypeForm) {
        this.$store.dispatch('forms/LOAD_FORM', { formId: this.taskType.form.uuid })
      }

      if (this.taskType.uuid && this.taskType.fields.length) {
        let field = []
        field = this.taskType.fields
        return field.sort((a, b) => a.field.sortOrder > b.field.sortOrder ? 1 : -1)
      }

      if (this.creatingTask && this.selectedTask.uuid == null) {
        return this.creatingTask.taskType.fields
      }
      
      if (this.isFormLoaded) {
        return this.form.fields
      }
      
      return []
    },

    filterFields() {
      return this.fields.filter(f => f.field.name)
    }
  },

  mounted() {
    this.$store.dispatch('forms/INIT', { bind: this.projectUuid })
  },

  methods: {
    ...mapActions('task', ['setTaskTypeFields']),

    changeTaskType() {
      this.fillForm()
    },

    fillForm() {
      this.dialog.fillForm = true
    },

    fillFormFields() {
      this.dialog.fillFormFields = true
    },

    onUpdateForm(data) {
      this.dialog.fillForm = data
      this.dialog.fillForm = false
    },

    onUpdateFormData(data) {
      this.dialog.fillForm = false
      let newTaskType = data
      newTaskType.bind = this.selectedTask.uuid
      this.setTaskTypeFields(newTaskType)
    },
  }
}
</script>

<style scoped>
.field-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>