<template lang="pug">
app-panel.px-1.py-2( v-bind="$attrs" ident="faceControl" )
  app-panel-header( :title="$t('section.presenceControl.title')" showClose )

  app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
    
    app-floating-panel( :title="$t('section.presenceControl.incoming')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" :settings="floatingPanels('INCOMING')" )
      div.pr-1.t-scroll-body
        video-recorder-screen.video-recorder( :autoplay="true" :loop_screen_ms="5000" @screenshot="(img) => { screen(img, 1) }" )
        div.ml-1.video-info
          v-chip.mb-1( color="green" v-for="(item, idx) in input" :key="idx" ) {{ item }}

    app-floating-panel( :title="$t('section.presenceControl.exiting')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" :settings="floatingPanels('EXITING')" )
      div.pr-1.t-scroll-body
        video-recorder-screen.video-recorder( :autoplay="true" :loop_screen_ms="5000" @screenshot="(img) => { screen(img, 0) }" )
        div.ml-1.video-info
          v-chip.mb-1( color="red" v-for="(item, idx) in output" :key="idx" ) {{ item }}
</template>

<script>
import { mapGetters } from 'vuex'
import VideoRecorderScreen from "@/components/project/common/VideoRecorderScreen.vue"
import { api } from "@/api"

export default {
  name: 'FaceCatcher',
  components: {
    VideoRecorderScreen
  },

  data () {
    return {
      input: [],
      output: []
    }
  },

  methods: {
    screen(imageUrl, type) {
      api.face.checkScreenshot(imageUrl, 0).then((data) => {
        if (data != '') {
          if (type == 1)
            this.input.push('Пользователь ' + data.remoteId)
          else this.output.push('Пользователь ' + data.remoteId)
        }
      });
    }
  },

  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
  }
}
</script>

<style scoped>
  .video-recorder {
    display: inline-block;
    color: var(--v-surface-lighten3);
  }

  .video-info {
    display: inline-block;
    vertical-align: top;
  }
</style>