<template lang="pug">
div
  div(style="margin: 8px")
    .measurement( v-if="!isOccurrenceRule" )
      .number-icon 1
      .first-font.ml-2 {{ $t("section.collision.rulesFounder.ruleSetting.fisrtOption") }}
      
    .measurement.ml-7( v-if="!isOccurrenceRule" )
      .measurement-first
        v-btn.radio-button(v-if="hasCollisionCreateRule" icon @click="typeOption = 1" width="24px")
          v-icon(v-if="typeOption === 1" color="#4d4d4d" small) mdi-radiobox-marked
          v-icon(v-else color="#4d4d4d" small) mdi-radiobox-blank
        v-tooltip( bottom open-delay=300 )
          template( v-slot:activator="{ on, attrs }" )
            .second-font( v-on="on" ) {{$t("section.collision.rulesFounder.ruleSetting.allowance")}}
          span {{$t("section.collision.rulesFounder.ruleSetting.allowanceSpan")}}
        v-text-field.input-half.ml-2( v-model="form.margin" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" ) 
      .measurement-first.ml-4
        v-btn.radio-button(v-if="hasCollisionCreateRule" icon @click="typeOption = 2" width="24px")
          v-icon(v-if="typeOption === 2" color="#4d4d4d" small) mdi-radiobox-marked
          v-icon(v-else color="#4d4d4d" small) mdi-radiobox-blank
        v-tooltip( bottom open-delay=300 )
          template( v-slot:activator="{ on, attrs }" )
            .second-font( v-on="on" ) {{$t("section.collision.rulesFounder.ruleSetting.gap")}}
          span {{$t("section.collision.rulesFounder.ruleSetting.gapSpan")}}
        v-text-field.input-half.ml-2( v-model="form.gap" hide-details light :disabled="typeOption === 1 || !hasCollisionCreateRule" type="number" )
      
    //- До лучших времен ᓚᘏᗢ
    //- .measurement.ml-7(v-if="typeOption === 1 && form.margin")
    //-   .measurement-first
    //-     v-btn(icon width="24px" @click="bIntoA = !bIntoA")
    //-       v-icon(v-if="!bIntoA" small color="#4d4d4d") mdi-checkbox-blank-outline
    //-       v-icon(v-else small color="#4d4d4d") mdi-checkbox-marked
    //-     v-tooltip( bottom open-delay=300 )
    //-       template(v-slot:activator="{ on, attrs }")
    //-         .second-font.pointer( v-on="on" @click="bIntoA = !bIntoA") {{ $t('section.collision.rulesFounder.ruleSetting.bIntoA.title') }}
    //-       span {{ $t('section.collision.rulesFounder.ruleSetting.bIntoA.hint') }}

    .measurement.ml-7.mt-4( v-if="!isOccurrenceRule" )
      .number-icon 2
      .first-font.ml-2 {{ $t("section.collision.rulesFounder.ruleSetting.secondOption") }}

    .measurement.ml-15.mt-2( v-if="!isOccurrenceRule" )
      .measurement-title {{$t("section.collision.rulesFounder.ruleSetting.angle")}}
      v-text-field.input-half( v-model="form.angleFrom" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" )
        template( #prepend ) 
          .mt-2.mx-1 {{$t("section.collision.rulesFounder.ruleSetting.from")}}
      v-text-field.input-half.ml-1( v-model="form.angleTo" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" )
        template( #prepend ) 
          .mt-2.mx-1 {{$t("section.collision.rulesFounder.ruleSetting.to")}}
      .measurement-or {{ $t('section.structure.model.form.or')}}

    .measurement.ml-15.mt-3( v-if="!isOccurrenceRule" )
      .measurement-title {{$t("section.collision.rulesFounder.ruleSetting.area")}}
      v-text-field.input-half( v-model="form.squareFrom" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" )
        template( #prepend )
          .mt-2.mx-1 {{$t("section.collision.rulesFounder.ruleSetting.from")}}
      v-text-field.input-half.ml-1( v-model="form.squareTo" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" )
        template( #prepend )
          .mt-2.mx-1 {{$t("section.collision.rulesFounder.ruleSetting.to")}}
      .measurement-or {{ $t('section.structure.model.form.or')}}

    .measurement.ml-15.mt-3.mb-2( v-if="!isOccurrenceRule" )
      .measurement-title {{$t("section.collision.rulesFounder.ruleSetting.volume")}}
      v-text-field.input-half( v-model="form.volumeFrom" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" )
        template( #prepend )
          .mt-2.mx-1 {{$t("section.collision.rulesFounder.ruleSetting.from")}}
      v-text-field.input-half.ml-1( v-model="form.volumeTo" hide-details light :disabled="typeOption === 2 || !hasCollisionCreateRule" type="number" )
        template( #prepend )
          .mt-2.mx-1 {{$t("section.collision.rulesFounder.ruleSetting.to")}}
      .measurement-or(style="visibility: hidden;") {{ $t('section.structure.model.form.or')}}

    v-btn.expansion-button.mt-6( v-if="!isOccurrenceRule" color="accent" dense text x-small @click="toggleExpansion = !toggleExpansion" ) {{ $t('section.collision.rulesFounder.ruleSetting.moreOption') }}
      v-icon(v-if="!toggleExpansion" small).pl-1 mdi-menu-down
      v-icon(v-if="toggleExpansion" small).pl-1 mdi-menu-up
    div.container-position( v-if="toggleExpansion" )
      v-checkbox.my-1( v-if="!isOccurrenceRule" color="accent" dense hide-details light v-model="form.calcDistance" :disabled="distanceDisabled || !hasCollisionCreateRule" )
        template( v-slot:label )
          v-tooltip( bottom open-delay=300 )
            template( v-slot:activator="{ on, attrs }" )
              .first-font( v-on="on" ) {{ $t('section.collision.rulesFounder.ruleSetting.calcDistance.title') }}
            span {{ $t('section.collision.rulesFounder.ruleSetting.calcDistance.hint') }}
      v-checkbox.my-1( color="accent" dense hide-details light v-model="form.excludeZone" :disabled='!hasCollisionCreateRule' )
        template( v-slot:label )
          v-tooltip( bottom open-delay=300 )
            template( v-slot:activator="{ on, attrs }" )
              .first-font( v-on="on" ) {{ $t('section.collision.rulesFounder.ruleSetting.excludeZone.title') }}
            span {{ $t('section.collision.rulesFounder.ruleSetting.excludeZone.hint') }}
      v-checkbox.my-1( color="accent" dense hide-details light v-model="form.excludeMaterial" :disabled='!hasCollisionCreateRule' )
        template( v-slot:label )
          v-tooltip( bottom open-delay=300 )
            template( v-slot:activator="{ on, attrs }" )
              .first-font( v-on="on" ) {{ $t('section.collision.rulesFounder.ruleSetting.excludeMaterial.title') }}
            span {{ $t('section.collision.rulesFounder.ruleSetting.excludeMaterial.hint') }}
      .d-flex.align-center.ma-0
        v-checkbox.my-1.mr-2( color="accent" dense hide-details light v-model="form.collisionLimit" :disabled='!hasCollisionCreateRule' ) 
          template( v-slot:label )
            v-tooltip( bottom open-delay=300 )
              template( v-slot:activator="{ on, attrs }" )
                .first-font( v-on="on" ) {{ $t('section.collision.rulesFounder.ruleSetting.collisionLimit.title') }}
              span {{ $t('section.collision.rulesFounder.ruleSetting.collisionLimit.hint') }}
        v-text-field.collision-max-count( v-model="form.limitNumber" hide-details light :disabled="!form.collisionLimit || !hasCollisionCreateRule" type="number" )

  .text-right.mt-2
    app-button( action @click="onSave" test-id="collision-rule-save" :disabled="gapRequired || !hasCollisionCreateRule")
      | {{ $t('button.save') }}
</template>

<script>
// import { api } from "@/api"
import { mapGetters } from 'vuex'

const emptyForm = Object.freeze({
  margin: null,
  gap: null,
  angleTo: null,
  angleFrom: null,
  squareTo: null,
  squareFrom: null,
  volumeFrom: null,
  volumeTo: null,

  calcDistance: false,
  excludeZone: true,
  excludeMaterial: true,
  collisionLimit: true,
  limitNumber: 10000,
})

export default {

  props: {
    value: Object,
    hasOptions: Boolean,
    edit: Boolean,
  },

  data: () => ({
    form: { ...emptyForm },
    uuids: { ...emptyForm },

    typeOption: 1,
    bIntoA: false,

    moreOption: false,

    toggleExpansion: false,
  }),
  
  computed: {
    ...mapGetters('rule/details', ['rule']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule']),

    logicGroup () {
      return this.value && this.value.logicGroup || {}
    },

    gapRequired() {
      let val = Number(this.form.gap)
      return this.typeOption === 2 && (!val || !this.form.gap)
    },

    distanceDisabled() {
      return !!(((this.typeOption === 1 && this.form.margin) || (this.typeOption === 2 && this.form.gap)) || this.form.angleTo 
        || this.form.angleFrom || this.form.squareTo || this.form.squareFrom || this.form.volumeFrom || this.form.volumeTo)
    },

    isOccurrenceRule() {
      return this.rule?.finder?.className.includes("OccurrenceClashRule")
    },
  },

  watch: {
    value: {
      handler ({ options, title }) {
        this.form = { ...emptyForm }
        this.uuids = { ...emptyForm }
        this.title = title

        if (!options || options.length == 0) {
          this.isIntersectionsAllowed = false
        }
        else {
          options.forEach(o => {
            this.form[o.name] = JSON.parse(o.value.toLowerCase())
            this.uuids[o.name] = o.uuid
          })
          this.isIntersectionsAllowed = true
        }

        if (this.form.gap && (this.form.gap === '0' || this.form.gap > 0))
          this.typeOption = 2
        else 
          this.typeOption = 1

        if (this.isOccurrenceRule) {
          this.toggleExpansion = true
        }

        this.bIntoA = this.rule.bIntoA
      },
      immediate: true
    },

    typeOption(){
      if(this.typeOption == 2){
        this.form.calcDistance = !!this.form.gap
      }
      else {
        this.form.calcDistance = !!this.form.margin
      }
    },

    "form.margin"(){
      this.form.calcDistance = !!this.form.margin
    },

    "form.gap"(){
      this.form.calcDistance = !!this.form.gap
    }
  },

  methods: {
    onSave () {
      if (this.isOccurrenceRule) {
        this.clearOptions()
      }
      else {
        if (this.typeOption === 1) 
          this.form.gap = null

        if (this.distanceDisabled) 
          this.form.calcDistance = true
      }

      let options = Object.keys(this.form).map(
        name => ({ 
          name, 
          value: this.form[name],
          uuid: this.uuids[name]?.length === 36 ? this.uuids[name] : null
        })
      ).filter(i => !!i.value || typeof i.value === 'boolean')

      // До лучших времен ᓚᘏᗢ
      // if (this.typeOption === 2 || !this.form.margin) this.bIntoA = false
      // api.collisionRules.setSamplePriority(this.rule.uuid, this.bIntoA)
      // this.rule.bIntoA = this.bIntoA

      this.$emit('saveSettings', options)
    },

    clearOptions() {
      this.form.gap = null
      this.form.margin = null
      this.form.angleTo = null
      this.form.angleFrom = null
      this.form.squareTo = null
      this.form.squareFrom = null
      this.form.volumeTo = null
      this.form.volumeFrom = null
      this.form.calcDistance = false
    }
  }
}
</script>

<style lang="scss" scoped>
.save-button {
  font: normal 10px/12px $roboto;
  color: #FFFFFF;
}
.radio-button {
  margin: 0;
  padding: 0;
}
.number-icon {
  background-color: #3b93af;
  padding: 2px 8px;
  border-radius: 100%;
  font: normal 14px/20px $roboto;
}
.first-font {
  font: normal 14px/20px "Roboto", sans-serif;
  color: #505254;
  margin-left: 0;
}
.second-font {
  font: normal 13px $roboto;
  color: #000000;
}
.dimens-section {
  height: 100%;
}
.input {
  width: 90px;
  margin-left: 4px;
}
.input-half {
  width: 90px;
  margin-left: 4px;
}
.measurement {
  display: flex;
  align-items: center;
}
.measurement-first {
  display: flex;
  align-items: center;
}
.measurement-title {
  font: normal 13px $roboto;
  align-self: end;
  color: #000000;
  width: 200px;
}
.measurement-or {
  font: normal 11px $roboto;
  color: #3c3c3c;
  margin: 25px 15px -25px 5px;
}
.collision-max-count {
  margin: 0;
  padding: 0;
}
.expansion-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none !important;
  padding: 0 !important;
}
.container-position {
  padding: 4px;
}
</style>

<style lang="scss">
.measurement > .v-input {
  font: normal 13px/32px $roboto;
  color: #505254;
}
.measurement .v-input__prepend-outer {
  margin: 0;
}
.measurement .v-input {
  padding: 0;
  margin: 0;
}
.measurement input {
  text-align: center;
  padding: 0;
}
.settings-options-header {
  font: bold 14px/18px $roboto;
  margin: 0;
  padding: 0;
}
.settings-options-header .v-label {
  font: bold 14px/18px $roboto;
}
</style>
