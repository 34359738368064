<template lang='pug'>
app-dialog(v-model='show' :header='$t(dialogHeader)' @close='touched = false')
  app-section
    app-text-field(:value='localReceiver.name' :placeholder='$t("module.mnemoschemas.receivers.receiverName")' @input='handleUpdate("name", $event)')
    
  div.d-flex.mt-2
    app-button.ml-auto(color='accent' :disabled='!(touched && formIsValid)' @click='save()') {{ $t('button.save') }}
</template>

<script>
const defaultReceiver = {
  name: ''
}

export default {
  name: 'ReceiverFormDialog',

  props: {
    value: Boolean,
    receiver: Object
  },

  data: () => ({
    touched: false
  }),

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    localReceiver() {
      return this.receiver || defaultReceiver
    },

    formIsValid() {
      return !!this.localReceiver.name
    },

    dialogHeader() {
      return this.localReceiver.uuid ? 'module.mnemoschemas.receivers.receiverEditing' : 'module.mnemoschemas.receivers.receiverCreating'
    }
  },

  methods: {
    handleUpdate(prop, value) {
      this.touched = true
      this.$emit('update:receiver', {
        ...this.localReceiver, 
        [prop]: value}
      )
    },

    save() {
      this.show = false
      this.touched = false
      this.$emit('save')
    }
  }
}
</script>

<style>

</style>