<template lang="pug">
  div
    wjPanelList(:headers="headersForList" :items="titleItems" @checkValue="checkValue" 
      @showEditItemDialog="showEditItemDialog" @showNewItemDialog="showNewItemDialog")

    app-dialog( v-model="dialog.newOrEdit" :header="dialog.newItem ? $t('section.journal.newItem') : $t('button.edit')" @confirm="dialog.newItem ? saveNewItem() : saveEditItem()" @close="clearItem()" 
        :confirmText="dialog.newItem ? $t('button.add') : $t('button.save')" width="450")
      app-section
        v-radio-group(v-if="dialog.newItem" v-model="dialog.newPerson" light)
          v-radio( :label="$t('section.journal.newParticipantWork')" value="1" @click="resetItem()")
          v-radio( :label="$t('section.journal.chooseExisting')" value="2" )
        AppAutocomplete.mb-5.mt-2(dense outlined light v-if="dialog.newPerson === '2'" v-model="dialog.item" :items="listWorkers" :label="$t('section.journal.selectParticipant')" item-text="fullName")
        div(:readonly="dialog.newPerson === '2' && dialog.item")
          app-text-field(v-if="checkInputDialog('personName')" light v-model="dialog.item.personName" :readonly="dialog.newPerson === '2' ? true : false"
            :label="dialog.text.personName.label" :hint="dialog.changesName == 'documentPersonTeam' ? dialog.text.personName.document : dialog.text.personName.other")
          app-text-field(light v-model="dialog.item.fullName" :label="dialog.text.fullName.label" :hint="dialog.text.fullName.hint" :readonly="dialog.newPerson === '2' ? true : false")
          app-text-field(light v-model="dialog.item.position" :label="dialog.text.position.label" :readonly="dialog.newPerson === '2' ? true : false")
          app-text-field(light v-model="dialog.item.document" :label="dialog.text.document.label" :hint="dialog.text.document.hint" :readonly="dialog.newPerson === '2' ? true : false")
          app-text-field(v-if="checkInputDialog('worksType')" light v-model="dialog.item.worksType" :label="dialog.text.worksType.label" :hint="dialog.text.worksType.hint" :readonly="dialog.newPerson === '2' ? true : false")
          app-text-field(v-if="checkInputDialog('identiNumber')" light v-model="dialog.item.identiNumber" :label="dialog.text.identiNumber.label" 
            :hint="dialog.changesName == 'documentPersonTeam' ? dialog.text.identiNumber.document : dialog.text.identiNumber.hint" :readonly="dialog.newPerson === '2' ? true : false")
        app-text-field(light v-model="dialog.item.sign" :label="dialog.text.sign.label" :hint="dialog.changesName == 'otherPerson' ? dialog.text.sign.other : null") 

    app-dialog( v-model="dialog.changes" :header="$t('section.journal.titleChanges')"  @confirm="saveInfoChanges()" @close="closeChangesDialog()" :confirmText="$t('button.save')" width="450")
      app-section
        app-text-field(v-model="dialog.changesItem.changes" :label="dialog.text.changes.change" light)
        app-text-field(v-model="dialog.changesItem.sign" :label="dialog.text.changes.sign" light)
        AppAutocomplete.mb-5(dense light v-model="dialog.changesItem.person" :items="listWorkers" :label="$t('section.journal.authorChanges')" item-text="fullName" item-value="fullName")
        
</template>

<script>
import textJournal from "./textJournal"
import _ from "lodash"
import { AlertService } from "@app/AlertService"

import wjPanelList from "@/components/project/panel/left/documents/components/ExpansionPanel"

import { mapState, mapMutations, mapActions, mapGetters } from "vuex"

export default {
  name: "sectionTitle",
  components: {
    wjPanelList
  },

  data() {
    return {
      dialog: {
        item: Object.assign({}, this.wjWorkPersonNewItem),
        oldItem: Object.assign({}, this.wjWorkPersonNewItem),
        newOrEdit: false,
        newItem: false,

        changesName: "",
        personParent: "",

        newPerson: "1",

        text: textJournal.title.dialog,

        changes: false,
        changesItem: Object.assign({}, this.wjTitleChangesNewItem)
      },
      titleItems: Object.assign({}, this.wjTitleNullItems),
      oldTitleItems: Object.assign({}, this.wjTitleNullItems),
    }
  },

  mounted() {
    if (this.wjTitle) {
      this.titleItems = this.wjTitle
      this.oldTitleItems = Object.assign({}, this.titleItems)
    }
  },

  beforeDestroy() {},

  computed: {
    ...mapState("document", ["wjTitleNullItems", "wjTitleChangesNewItem", "wjWorkPersonNewItem", "listWorkers"]),
    ...mapGetters("document", ["wjTitle"]),
    ...mapState('project', ['project']),

    headersForList() { return textJournal.title.panelList }
  },

  watch: {
    wjTitle: "getNewItems",
  },

  methods: {
    ...mapMutations("document", ["setActiveWorkJournal"]),
    ...mapActions("document", ["changeActiveJournal", "getJournalList", "createNewJournal", "saveWorkJournal", "saveNewWorkPerson", "getWorkPersonList"]),

    getNewItems() {
      if (this.wjTitle) {
        this.titleItems = this.wjTitle
        this.oldTitleItems = Object.assign({}, this.titleItems)
      } else {
        this.titleItems = Object.assign({}, this.wjTitleNullItems)
        this.oldTitleItems = Object.assign({}, this.wjTitleNullItems)
      }
    },

    checkInputDialog(inputName) {
      if (inputName == "personName" && (this.dialog.changesName == "documentPersonTeam" || this.dialog.changesName == "otherPerson"))
        return true
      else if (inputName == "identiNumber" && (this.dialog.changesName == "developerTeam" || this.dialog.changesName == "techCustomerTeam" || this.dialog.changesName == "documentPersonTeam" || this.dialog.changesName == "builderTeam"))
        return true
      else if (inputName == "worksType" && this.dialog.changesName == "otherPerson")
        return true

      return false
    },

    checkValue(item) {
      if (this.titleItems[item] != this.oldTitleItems[item] && this.oldTitleItems[item] != null) {
        this.dialog.changes = true
        this.dialog.oldItem = this.oldTitleItems[item]
        this.dialog.item = this.titleItems[item]
        this.dialog.changesName = item
      }
    },

    resetItem() {
      this.dialog.item = Object.assign({}, this.wjWorkPersonNewItem)
    },

    showEditItemDialog(item, tableName) {
      this.dialog.newOrEdit = true
      this.dialog.newItem = false
      this.dialog.item = item
      this.dialog.oldItem = Object.assign({}, item)
      this.dialog.changesName = tableName
      this.dialog.newPerson = '0'
    },

    showNewItemDialog(tableName, parentName) {
      this.getWorkPersonList(this.project.uuid).then(() => {
        this.dialog.newOrEdit = true
        this.dialog.newItem = true
        this.dialog.changesName = tableName
        this.dialog.item = Object.assign({}, this.wjWorkPersonNewItem)
        this.dialog.personParent = parentName
        this.dialog.newPerson = '1'
      })
    },

    saveNewItem() {
      let num = this.titleItems[this.dialog.changesName].length + 1
      let newWorkPerson = Object.assign({}, this.wjWorkPersonNewItem)
      newWorkPerson.number = num
      for (let item in newWorkPerson)
        if (item != "number" && item != "uuid") newWorkPerson[item] = this.dialog.item[item]

      newWorkPerson.project = this.project.uuid
      
      if (this.dialog.item.uuid != null)
        newWorkPerson.workPersonUuid = this.dialog.item.uuid

      if (newWorkPerson.personName === null && this.dialog.personParent)
        newWorkPerson.personName = this.titleItems[this.dialog.personParent]

      this.titleItems[this.dialog.changesName].push(newWorkPerson)

      this.clearItem()
    },

    saveEditItem() {
      if (!_.isEqual(this.dialog.item, this.dialog.oldItem))
        this.dialog.changes = true
      else this.clearItem()
    },

    saveInfoChanges() {
      this.dialog.changesItem.number = this.wjTitle.changes.length + 1

      let oldItem = "", newItem = ""
      if (typeof this.dialog.oldItem === "object" && typeof this.dialog.item === "object") {
        for (let item in this.dialog.oldItem) {
          if (item != "uuid" && item != "profile" && item != "workPersonUuid" && item != "project" && item != "juridical" && this.dialog.oldItem[item] != null)
            oldItem += this.dialog.oldItem[item] + ", "
        }
        for (let item in this.dialog.item) {
          if (item != "uuid" && item != "profile" && item != "workPersonUuid" && item != "project" && item != "juridical" && this.dialog.item[item] != null)
            newItem += this.dialog.item[item] + ", "
        }
      } else {
        oldItem = this.dialog.oldItem
        newItem = this.dialog.item
      }

      this.dialog.changesItem.changes =
        "(" + oldItem + ") заменен на (" + newItem + ") - " + this.dialog.changesItem.changes

      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) month = "0" + month
      let day = date.getDate()
      this.dialog.changesItem.date = year + "-" + month + "-" + day

      this.dialog.oldItem = Object.assign({}, this.wjWorkPersonNewItem)

      this.wjTitle.changes.push(this.dialog.changesItem)

      this.oldTitleItems = Object.assign({}, this.titleItems)

      this.clearItem()
    },

    clearItem() {
      if (this.dialog.oldItem.number != null)
        for (let item in this.dialog.item)
          this.dialog.item[item] = this.dialog.oldItem[item]

      this.dialog.changesName = ""
      this.dialog.item = Object.assign({}, this.wjWorkPersonNewItem)
      this.dialog.oldItem = Object.assign({}, this.wjWorkPersonNewItem)
      this.dialog.changesItem = Object.assign({}, this.wjTitleChangesNewItem) 
      this.dialog.newOrEdit = false
      this.dialog.delete = false
      this.dialog.changes = false
      this.dialog.personParent = ""
    },

    closeChangesDialog() {
      if (typeof this.dialog.oldItem === "object") 
        for(let item in this.dialog.oldItem) {
          this.titleItems[this.dialog.changesName][item] = this.dialog.oldItem[item]
        }
      else
        this.titleItems[this.dialog.changesName] = this.dialog.oldItem

      AlertService.info({info: this.$t('section.journal.changeCancele')})
      this.dialog.changes = false

      this.clearItem()
    },
  }
}
</script>

<style lang="scss" scoped>
.panels {
  width: calc(100% - 2px);
  margin: 1px;
}
.panel-field {
  width: calc(100% - 40px);
  margin: 10px 0 0 20px;
}
</style>
