<template lang="pug">
.comment-import-mode-panel
  .comment-import-mode-hint
    span {{ $t("module.drawing.importMessage") }}
    .d-flex.ml-auto.mt-3
      v-btn.mr-3(x-small text color='#757575' @click='store.setCommentImportMode(false)') {{ $t('button.cancel') }}
      v-btn(x-small color='accent' min-width='84' @click='importCommentNewPage') {{ $t("module.drawing.import") }}
</template>

<script>
import { DrawingPageCommentsService } from '@/components/drawings/services/drawingPageCommentsService'
import { useDrawingsStore } from '@/pinia'

export default {
  name: 'DrawingImportInfo', 

  computed: {
    store: () => useDrawingsStore(),
  },

  methods: {
    importCommentNewPage() { DrawingPageCommentsService.importCommentNewPage() },
  }
}
</script>

<style lang="scss" scoped>
.comment-import-mode-hint {
  width: 250px;
  height: 80px;

  margin-top: 8px;
  padding: 8px 4px 0 8px;
  border-radius: 3px;

  box-shadow: 0 0 4px rgba(0,0,0,.65);
  background: rgb(255, 255, 255, .9);

  font-size: 11px;

  display: flex;
  flex-direction: column;
}

.comment-import-mode-panel {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
}
</style>