<template lang="pug">
app-dialog(v-model="visible" :header="$t('plugin.information')")
  app-section.mb-4 
    app-text {{ pluginResInfoData }}
</template>

<script>

export default {
  props: {
    pluginResInfoData: {}
  },

  data() {
    return {
      visible: false
    }
  },

  methods: {
    show() {
      this.visible = !this.visible
    }
  }
}
</script>
