<template lang="pug">
  div
    v-data-table(
      light
      :items="items"
      :headers="headers")
      template(v-slot:item.actions="{ item }")
        v-icon(small @click="editItem(item)" style="margin-right: 10px") mdi-pencil
        v-icon(small @click="deleteItem(item)") mdi-delete

    app-dialog( v-model="dialog.delete" header="Подтверждение")
      app-section
        app-text Вы действительно хотите удалить данную запись?
        div(style="height:23px")
          v-btn.mt-2.float-right(color="red" dense text x-small outlined @click="realDeleteItem()") {{$t('button.delete')}}

    app-dialog( v-if="dialog.newOrEdit" v-model="dialog.newOrEdit" :header="dialog.newItem ? $t('section.journal.newItem') : $t('button.edit')" @confirm="dialog.newItem ? saveNewItem() : saveEditItem()" 
        @close="clearItem()" :confirmText="dialog.newItem ? $t('button.add') : $t('button.save')" width="450")
      app-section
        wjDatePicker(:label="text.date.label" :item="tableItem.date" name="date" @saveValue="saveValue")
        app-text-field(light v-model="tableItem.workName" :label="text.workName.label" :hint="text.workName.hint")
        app-text-field(light v-model="tableItem.workConditions" :label="text.workConditions.label" :hint="text.workConditions.hint")
        app-text-field(light v-model="tableItem.workScope" :label="text.workScope.label" :hint="text.workScope.hint")
        app-text-field(light v-model="tableItem.workPlace" :label="text.workPlace.label" :hint="text.workPlace.hint")
        app-text-field(light v-model="tableItem.workMethod" :label="text.workMethod.label" :hint="text.workMethod.hint")
        app-text-field(light v-model="tableItem.sign" :label="text.sign.label" :hint="text.sign.hint")
</template>

<script>
import textJournal from './textJournal'
import _ from 'lodash'

import wjDatePicker from '@/components/project/panel/left/documents/components/DatePicker.vue'

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

  export default {
    name: 'section3',
    components: {wjDatePicker},

    data() {
      return {
        items: [],
        archivedItems: [],
        dialog: {
          newItem: false,
          newOrEdit: false,
          delete: false,
          deleteItem: null,
          oldItem: null,
        },
        tableItem: Object.assign({}, this.wjWorksNewItem),
        text: textJournal.section3.label,
      }
    },

    mounted() {
      if (this.wjWorks) {
        this.items = this.wjWorks.active
        this.archivedItems = this.wjWorks.archived
      }
    },

    beforeDestroy() {},

    computed: {
      ...mapState('document', ['workJournal', 'listJournals', 'wjWorksNewItem']),
      ...mapState('project', ['project']),
      ...mapGetters('document', ['wjWorks']),

      headers() { return textJournal.section3.headers },
    },

    watch: {
      wjWorks: 'getNewItems',
    },

    methods: {
      ...mapMutations('document', ['setActiveWorkJournal', 'setListJournals']),
      ...mapActions('document', ['changeActiveJournal', 'getJournalList', 'createNewJournal', 'saveWorkJournal']),

      getNewItems() {
        if (this.wjWorks) {
          this.items = this.wjWorks.active
          this.archivedItems = this.wjWorks.archived
        } else {
          this.items = []
          this.archivedItems = []
        }
      },

      saveValue(date, name) {
        this.tableItem[name] = date
      },

      showNewItemDialog() {
        this.dialog.newItem = true
        this.dialog.newOrEdit = true
        this.tableItem = Object.assign({}, this.wjWorksNewItem)
      },

      editItem(item) {
        this.dialog.oldItem = Object.assign({}, item)
        this.tableItem = item
        this.dialog.newOrEdit = true
      },

      deleteItem(item) {
        this.dialog.deleteItem = item
        this.dialog.delete = true 
      },

      clearItem() {
        this.$emit('clearItem', this.dialog, this.tableItem, this.wjWorksNewItem)
      },

      saveEditItem() {
        if (!_.isEqual(this.tableItem, this.dialog.oldItem)) {
          this.$emit('editItem', this.archivedItems, this.dialog.oldItem)
        }
        this.clearItem()
      },
 
      saveNewItem() {
        this.$emit('saveItem', this.items, this.tableItem, this.wjWorksNewItem)
        this.clearItem()
      },

      realDeleteItem() {
        this.$emit('deleteItem', this.items, this.archivedItems, this.dialog.deleteItem)

        this.clearItem()
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
