import { decorateToRebuildScene } from '@/decorators/scene.decorator'
import { useGroupsStore } from '@/stores/groups/groups.store'
import { useGroupsElementStore } from '@/stores/groups/groupsElement.store'
import { useGroupsVisibilityStore } from '@/stores/groups/groupsVisibility.store'
import { useElementsColorStore } from '@/stores/groups/elementsColor.store'
// import { useSceneModelVisibilityStore } from '@/stores/scene/sceneModelVisibility.store'
import { flyToElements, selectElements, unselectElements } from './scene.facade'

import { fetchIfcGuidsByElementUuids, getIfcGlobalAttrId } from '@/providers/data/axes.dataProvider'
import { ProjectAxisForm } from '@models/axis'

import { useSelectedElementsStore } from '@/pinia'
import { getAxisEnum, getConditionOperators } from '@/providers/data/axes.dataProvider'
import { makeConditionTitleByIFCGUIDs } from '@/services/axes.service'
import { useGroupsPaletteStore } from '@/stores/groups/groupPalettes.store'

import { recolorizeScene } from './scene.facade'

const groupsStore = useGroupsStore()
const groupsElementStore = useGroupsElementStore()
const groupsVisibilityStore = useGroupsVisibilityStore()
const groupPalettesStore = useGroupsPaletteStore()
const elementsColorStore = useElementsColorStore()

//#region Управление кэшем

export async function prepareGroupsCache () {
  if (groupsStore.prepared) return
  await groupsStore.prepareGroups()

  groupsStore.rawList.forEach(async (group) => {
    await groupPalettesStore.prepareGroupPalleteByGroupUUID(group.uuid)
    await groupsElementStore.prepareGroupElements(group.uuid)
    recolorizeScene()
  })
}

export async function saveGroup (group) {
  groupsStore.saveGroup(group)

  await groupPalettesStore.prepareGroupPalleteByGroupUUID(group.uuid)
  await groupsElementStore.prepareGroupElements(group.uuid)
  recolorizeScene()
}

export function resetGroupsCache () {
  groupsStore.$reset()
  groupsElementStore.$reset()
  groupsVisibilityStore.$reset()
  groupPalettesStore.$reset()
  elementsColorStore.$reset()
}
//#endregion

//#region Список групп

export const fetchAllGroups = () => groupsStore.rawList
export const fetchElementsForGroupWithUuid = (uuid) => groupsElementStore.groupElements[uuid]
export const isGroupsPrepared = () => groupsStore.prepared

export const deleteGroupWithUuid = decorateToRebuildScene((uuid) => {
  groupsStore.deleteGroupWithUuid(uuid)
  groupsElementStore.deleteGroupElements(uuid)
  groupPalettesStore.deleteGroupPalette(uuid)
})

//#endregion

//#region Видимость групп

export const fetchSetOfHiddenGroupsUuids = () => groupsVisibilityStore.hidden
export const setNewSetOfHiddenGroupsUuids = decorateToRebuildScene((hidden) => {
  groupsVisibilityStore.hidden = hidden
})

export const showAllGroups = decorateToRebuildScene(() => {
  groupsVisibilityStore.$reset()
})

export const hideAllGroups = decorateToRebuildScene(() => {
  let set = new Set()
  groupsStore.rawList.forEach(item => {
    set.add(item.uuid)
  })
  groupsVisibilityStore.hidden = set
})

//#endregion

export function fitGroupToScreenByGroupUuid (uuid) {
  const uuids = fetchElementsForGroupWithUuid(uuid)
  flyToElements(uuids)
}

export function selectElementsByGroupUuid (uuid) {
  const uuids = fetchElementsForGroupWithUuid(uuid)
  uuids && selectElements(uuids)
}

export function deselectElementsByGroupUuid (uuid) {
  const uuids = fetchElementsForGroupWithUuid(uuid)
  uuids && unselectElements(uuids)
}

//#region WIP управление осями
export async function prepareAxisForEdit (group = null) {
  let axisData = new ProjectAxisForm(group)
  
  let uuids = Array.from(useSelectedElementsStore().selectedElements)
  if (uuids?.length) {
    // Data provider
    let data = await fetchIfcGuidsByElementUuids(uuids)
    // Helper / service
    await fillCopyData(axisData, data)
  } else {
    // TODO: добавил костыль, чтоб при открытии диалогового окна группировки
    // не отображалось пустое условие отбора
    axisData.logicGroup = {}
  }

  return axisData
}

export function changeGroupsSortIndex(groups) {
  groups.forEach((group, index) => group.sortIndex = index)
  groupsStore.rawList = groups
}

export async function changeGroupPosition(obj) {
  const uuid = obj?.moved?.element?.uuid ?? null
  const pos = obj?.moved?.newIndex
  await groupsStore.changeGroupPosition(uuid, pos)
  recolorizeScene()
}

// Service
export async function fillCopyData (data, guids) {
  let cond = data.logicGroup.condition[0]

  cond.title = makeConditionTitleByIFCGUIDs(guids)
  cond.value = guids.join(',')

  cond.operator = getConditionOperators().find(e => e.name == 'IN')
  cond.operand = getAxisEnum().find(e => e.name == 'IDPROP')
  // Store
  // cond.operandUuid = this.ifcGlobalAttrId
  cond.operandUuid = await getIfcGlobalAttrId()
}
//#endregion
