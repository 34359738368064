<template lang="pug">
    app-dialog(v-model="show" width="400" :header="$t('module.task.settings')" @confirm="saveSettings" @close="$emit('close')" :confirmDisabled="!checkSettings" :confirmText="$t('button.save')" )
      app-section
        v-menu( ref='date' :return-value.sync='date' :close-on-content-click="false" transition='scale-transition' min-width='auto' offset-y )
          template( v-slot:activator='{ on }' )
            v-text-field( v-model='date' v-on='on' :label='$t("section.equipment.maintenance.startDate")' prepend-icon='mdi-calendar' readonly light clearable :rules="[rules.required]")
          v-date-picker( v-model="date" first-day-of-week=1 no-title scrollable @input="$refs.date.save(date)")
        
        app-text-field(v-model="numDuty" :label="$t('section.equipment.maintenance.numDuty')" dense :rules="[rules.required]" type="number")
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    date: null,
    numDuty: 4,
    show: true,
  }),

  mounted() {
    this.openSettings()
  },

  computed: {
    ...mapState('project', ['projectSettings', 'project', 'maintenanceTaskSettings']),

    checkSettings() {
      return !!this.date && !!this.numDuty
    },

    rules() {
      return { required: (value) => !!value || this.$t('error.require') }
    },
  },

  methods: {
    ...mapActions('project', ['updateMaintenanceTaskSettings']),
    

    openSettings() {
      this.numDuty = this.maintenanceTaskSettings.numDuty
      this.date = this.maintenanceTaskSettings.schedulerStartDate
      if (!this.date) {
        this.date = this.project.createDate.slice(0, 10)
      }
    },

    saveSettings() {
      let date = new Date(this.date)
        .toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' })
        .split('.')
        .reverse()
        .join('-')
      this.updateMaintenanceTaskSettings({ schedulerStartDate: date, numDuty: this.numDuty })
      this.$emit('confirm')
    },
  },
}
</script>