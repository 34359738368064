<template lang="pug">
div.pa-2
  div( v-if="!searchElementsStore.firstElementLoaded" style="width: 410px;" )
    v-progress-linear.mb-4( indeterminate color="var(--v-surface-lighten2)" )
    div.d-flex.justify-center( style="color: #000;" ) Загрузка списка

  div( v-else )
    search-elements-table-header
    search-elements-table-body( v-if="searchElementsStore.currentElements.length" )
    div.d-flex.justify-center( v-else style="color: #000;" ) Список пуст
</template>

<script>
import { mapStores } from 'pinia';
import SearchElementsTableBody from './SearchElementsTableBody.vue';
import SearchElementsTableHeader from './SearchElementsTableHeader.vue';
import { useSearchElementsStore } from '@/pinia/searchElements.module';

export default {
  components: {
    SearchElementsTableHeader,
    SearchElementsTableBody
  },

  computed: {
    ...mapStores(useSearchElementsStore),

    isEmpty() {
      return !!this.searchElementsStore.currentElements[0]?.uuid ?? false
    }
  }
}
</script>
