<template lang="pug">
  div
    //- Назначенные
    task-section-expansion(v-if="!onlyDialogs" :title="`${$t('module.task.assigned')} (${allWorkersOnTask.length + getGroupWorkers.length})`" show-toggle style="border-radius: 0px;" 
      :additionalIcon="addIcon" @additionalIconClick="showWorkersDialog()" :headerColorDark="true" :showToggle="getAppointedPanel" @openPanel="openPanel")
      .d-flex.flex-column.flex-wrap.mx-1(v-if="allWorkersOnTask.length || getGroupWorkers.length")
          div.d-flex.align-center.mt-2.mr-7( v-for="worker in allWorkersOnTask" :key="worker.uuid" @click="moveResponder(worker)" style="width: 95%;" )
            div( v-if="worker.isResponder" style="display: flex; width: 95%;" )
              v-tooltip.ml-auto(bottom)
                template( v-slot:activator="{ on }" )
                  div.d-flex.align-center.flex( v-on="on" )
                    app-avatar(                 
                      :text="avatarText(worker)" :url="avatarUrl(worker)" :disabled="worker.archive"                
                      :size="24" :bordered="worker.isResponder" :colorObj="getBgColor(worker.uuid)"                               
                    )                
                    .text-14.text-over.ml-1(style='font-weight: 400; line-height: 24px; color: #262626;') {{worker.name}}
                span {{worker.name}}
              v-tooltip(bottom) 
                template( v-slot:activator="{ on }" )
                  div.ml-auto( v-on="on" )
                    v-icon(small color="primary") outlined_flag
                span {{ $t('section.collision.table.headers.displayedResponder') }}

            v-tooltip( v-else-if="!worker.isResponder" bottom )
              template( v-slot:activator="{ on }" )
                div.flex( v-on="on" )
                  div.text-over( style="display: flex;" )
                    app-avatar( 
                      :text="avatarText(worker)" :url="avatarUrl(worker)" :disabled="worker.archive" 
                      :size="24" :bordered="worker.isResponder" :colorObj="getBgColor(worker.uuid)"                      
                    )
                    .text-14.text-over.ml-1(style='font-weight: 400; line-height: 24px; color: #262626;') {{worker.name}}
              span {{worker.name}}

          div.d-flex.align-center.mt-2.mr-7(v-for="wGroup in getGroupWorkers" :key="wGroup.uuid" style="display: flex; width: 95%;" )
            v-tooltip.ml-auto( bottom )
              template( v-slot:activator="{ on }" )
                .text-14.text-over(:style="wGroup.uuid === groupResponder" 
                                    style="line-height: 24px; color: #262626;" @click="showGroupResponderDialog(wGroup)" v-on="on" ) (Группа) {{wGroup.title}}
              span {{wGroup.title}}
            v-tooltip.ml-auto(v-if="wGroup.uuid === groupResponder" bottom )
              template( v-slot:activator="{ on }" )
                div( v-on="on" )
                  v-icon( small color="primary") outlined_flag
              span {{ $t('section.collision.table.headers.displayedResponder') }}

      div(v-else-if="hasTaskAppointment")
        app-button-add.mx-auto( :text="$t('module.task.assign')" width="100%" @click="showWorkersDialog()" )

    //- Назначенные исполнители
    app-dialog( v-model="dialog.workers" :header="$t('module.task.designatedPerformers')" @confirm="onConfirm" :confirm-text="$t('button.appoint')" @close="onClose" )
      app-section.base-scroll-body(style="height:50vh; overflow-y: auto")
        app-section-header-tabs.mb-1( :items="teamTabItems" :value="teamTab" @input="(tab) => teamTab = tab" light )
        template(v-if="teamTab == 0")
          app-text-field( v-model="search" prepend-icon="search" :label="$tc('section.structure.model.searchTeam', 0)" clearable)
          .t-row.app-cursor--pointer.pa-1.my-2( v-for="t in filterAppointWorkers" :key="t.uuid" )  
              v-checkbox.pa-0.my-1( v-model="workers" :value="t.profile.uuid" :disabled="t.profile.uuid === responderUUID" light hide-details )
                template( v-if="t.profile.uuid === responderUUID" slot="append")
                  v-icon.mt-3( color="primary" ) outlined_flag
                template( slot="label" )
                  project-teammate.app-cursor--pointer.ma-0.mt-2( style="display:flex; align-items:center; " :teammate="t")
        
        template(v-else)
          v-row( v-for="group in availableOrgGroups" :key="group.uuid" dense)
            v-checkbox.pa-0.my-1(v-model="groupWorkers" :value="group.uuid" :disabled="group.uuid === groupResponder" light hide-details)
              template( v-if="group.uuid === groupResponder" slot="append")
                v-icon( color="primary" ) outlined_flag
              template( slot="label" )
                app-text {{group.title}}
          div(v-if="!project.organization")
            app-text.text-center.mt-2 {{ $t('section.structure.model.team.noOrganization') }}
          div(v-if="availableOrgGroups.length == 0 && project.organization")
            app-text.text-center.mt-2 {{ $t('section.structure.model.noGroup') }}
      
    //- Назначить ответственного
    app-dialog( v-model="dialog.responder" :header="$t('module.task.assignResponder')" )
      app-section.mt-2.base-scroll-body(style="height:50vh; overflow-y: auto")
        .t-row.app-cursor--pointer.pa-1.my-2( v-for="w in allActiveTeammates" :key="w.uuid" )
          project-teammate.app-cursor--pointer.ma-0( :teammate="w" @click.native="saveResponder(w)" )

    //- Подтверждение назначения ответственного
    app-dialog-confirm(v-model="dialog.moveResponder" @confirm="setMoveResponder") 
      app-text {{ $t('module.task.assignResponsible', {name: selWorker ? selWorker.name : ''}) }}

    app-dialog-confirm(v-model="dialog.groupResponder" @confirm="confirmSetGroupResponder") 
      app-text {{ $t('module.task.assignResponsible', {name: wGroup ? wGroup.title : ''}) }}
</template>

<script>
import { SourcePath } from '@app/SourcePath'
import { mapState, mapGetters, mapActions } from 'vuex'
import uniqolor from 'uniqolor';
import ProjectTeammate from '@/components/project/common/Teammate'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';
import _ from 'lodash'

export default {
  name: 'TaskAppointedSection',
  components: { 
    ProjectTeammate,
    TaskSectionExpansion
  },
  props: {
    selectedTask: null,
    onlyDialogs: null,
  },
  
  data() {
    return {
      dialog: {
        workers: false,
        moveResponder: false,
        responder: false,
        groupResponder: false,
      },
      selWorker: null,
      workers: [],
      groupWorkers: [],
      wGroup: null,
      responderUUID: null,
      search: '',

      teamTab: 0
    }
  },

  watch: {
    selectedTask: {
      handler (val) {
        if (this && val) {
          this.resetTeammates()
        }
      },
      immediate: true,
      
    },
  },

  computed:{
    ...mapGetters('taskDetails', ['getAppointedPanel']),
    ...mapGetters('task', ['allWorkersOnTask', 'getGroupWorkers']),
    ...mapGetters('project', ['membership','allActiveTeammates']),
    ...mapState('project', ['project']),
    ...mapGetters('corp', {orgGroups:'groups'}),

    ...mapGetters('projectPermissions', ['hasTaskAppointment','hasTaskResponder']),

    addIcon() {
      if (this.hasTaskAppointment) return '/project/tools/edit.svg'
      return null
    },

    allAppointWorkers(){
      let listWithoutLeaveTeammates = this.membership.filter(member => member.profile!= null && (member.memberReference.length > 0 || member.owner == true))
      this.allWorkersOnTask.forEach(worker => {
        if(!listWithoutLeaveTeammates.find(teammate => teammate.profile.uuid === worker.uuid)) {
          let newMember = this.membership.find(member => member.profile?.uuid == worker.uuid)
          if( newMember ) listWithoutLeaveTeammates.push(newMember)
        }
      })
      
      return listWithoutLeaveTeammates
    },

    filterAppointWorkers(){
      return this.allAppointWorkers.filter(worker => worker.profile.name.toLowerCase().includes(this.search?.toLowerCase().trim() || ''))
    },

    teamTabItems () {
      return [
        { title: this.$t('drawer.users'), value: 0 },
        { title: this.$t('corp.group'), value: 1 },
      ]
    },

    availableOrgGroups() {
      let groups = this.getGroupWorkers.concat(this.orgGroups?.filter((p) => this.project.membership.some(el => el.orgGroup?.uuid == p.uuid)) || [])
      groups = _.uniqBy(groups, (item) => item.uuid)
      // let groups = new Set([...this.getGroupWorkers, ...this.orgGroups?.filter((p) => this.project.membership.some(el => el.orgGroup?.uuid == p.uuid))])
      return groups.length > 0 ? groups : []
    },

    groupResponder() {
      return this.selectedTask?.groupResponder?.uuid
    },
  },

  methods:{
    ...mapActions('task', ['setResponder', 'setWorkers', 'setGroupWorkers', 'setGroupResponder']),
    ...mapActions('taskDetails', ['setAppointedPanel']),

    openPanel(show){
      this.setAppointedPanel(show)
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },
    
    avatarText (profile) {
      return profile.archive ? profile.name + " (Удален)" : profile.name
    },

    avatarUrl (profile) {
      return profile.avatar ? SourcePath.avatar(profile.avatar) : ''
    },

    moveResponder (profile) {
      if (!this.hasTaskResponder) return
      if (profile.archive || profile.isResponder) return
      this.selWorker = profile
      this.dialog.moveResponder = true
    },

    resetTeammates () {
      const { workers, responder, groupWorkers } = this.selectedTask
      this.groupWorkers = groupWorkers ? groupWorkers.map(gw => gw.uuid) : []
      this.workers = workers ? workers.map(w => w.uuid) : []
      this.responderUUID = responder && responder.uuid
      if (this.responderUUID) this.workers.push(this.responderUUID)
    },

    showWorkersDialog () {
      this.resetTeammates()
      this.dialog.workers = true
    },

    showResponderDialog(){
      this.dialog.responder = true
    },

    saveResponder ({ profile }) {
      this.dialog.responder = false
      this.setResponder({ uuid: this.selectedTask.uuid, responderUuid: profile.uuid })
    },

    setMoveResponder () {
      this.dialog.moveResponder = false
        this.setResponder({ uuid: this.selectedTask.uuid, responderUuid: this.selWorker.uuid })
    },

    showGroupResponderDialog(wGroup) {
      this.dialog.groupResponder = true
      this.wGroup = wGroup
    },

    confirmSetGroupResponder() {
      this.dialog.groupResponder = false
      this.setGroupResponder({ uuid: this.selectedTask.uuid, wGroupUuid: this.wGroup.uuid })
      this.wGroup = null
    },

    onClose() {
      this.search = ''
      this.teamTab = 0
    },

    onConfirm(){
      if(this.teamTab == 0){
        this.setWorkers({ uuid: this.selectedTask.uuid, workerIds: this.workers.map(uuid => uuid ) })
      } else {
        this.setGroupWorkers({ uuid: this.selectedTask.uuid, groupWorkerIds: this.groupWorkers.map(uuid => uuid) })
      }
      this.onClose()
    }
  }
}
</script>

<style>
.v-input--selection-controls__input {
  display: flex !important;
  align-items: flex-start !important;
}

.text-over {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}

</style>
