<template lang="pug">
  app-dialog( v-model="visible" :header="$t('section.collision.createGroup')" :confirm-text="$t('button.save')" :confirmDisabled='validation' @confirm="makeGroup" )
    app-section
      app-text-field( v-model="title" :label="$t('section.collision.nameGroup')" test-id="collision-create-group-input" )
</template>

<script>
import { mapActions } from 'vuex'

export default {  
  data () {
    return {
      visible: false,
      title: '',
      uuids: null
    }
  },

  computed: {
    validation() {
      return !this.title.trim()
    }
  },

  methods: {
    ...mapActions('collision/table', ['createGroup']),

    show ({ single, item } = {}) {
      this.uuids = null
      if(single) this.uuids = [item.uuid]
      this.title = ''
      this.visible = true
    },

    makeGroup () {
      this.createGroup({title:this.title, uuids:this.uuids})
    },
  }
}
</script>