<template lang="pug">
  div.t-quote-comment
    div.d-flex.t-quote-body
      v-img.reply-icon( :src="`/img/project/tools/reply-dark.svg`" width="20" height="20" light )
      
      div.pt-1.pl-2.pb-2.quote-comment-size
        task-comment( :comment="comment" :class="widthComment ? 'comment-size-end' : 'comment-size-simple'" onlyView shortenComment )
        task-comment-creator( :comment="comment" short )
      
      v-btn.align-items-center.ml-auto.mr-2( small icon @click="$emit('cancelQuote')" )
        v-icon( color="black" ) close
</template>

<script>
import TaskComment from '@/components/task/taskDetailsSections/taskComments/TaskComment.vue'
import TaskCommentCreator from '@/components/task/taskDetailsSections/taskComments/TaskCommentCreator.vue'
import { mapState } from 'vuex'

export default {
  name: "TaskQuoteCreateSection",

  components: {
    TaskComment,
    TaskCommentCreator,
  },

  props: {
    comment: Object,
  },

  computed: {
    ...mapState('layoutControler', ['detailsWidth']),

     widthComment() {
      return this.detailsWidth > 500
    },
  }
}
</script>

<style>
  .t-quote-comment {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 3px 3px;
  }
  .t-quote-body{
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
  }
  .reply-icon{
    flex: 0 1 auto;
    min-width: 20px;
  }

  .comment-size-end {
    padding-left: 27px;
  }

  .comment-size-simple {
    padding-left: 0%;
  }

  .quote-comment-size {
    width: 75%;
  }
</style>