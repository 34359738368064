<template lang="pug">
.cursor-navigation
  #cursor
    v-icon#flare(v-show="cursorFlyNavigation == 'flare'" color="#eb8885") flare
    v-icon#north(v-show="cursorFlyNavigation == 'north'" color="#eb8885") north
    v-icon#south(v-show="cursorFlyNavigation == 'south'" color="#eb8885") south

    v-icon#west(v-show="cursorFlyNavigation == 'west'" color="#eb8885") west
    v-icon#north_west(v-show="cursorFlyNavigation == 'north_west'" color="#eb8885") north_west
    v-icon#south_west(v-show="cursorFlyNavigation == 'south_west'" color="#eb8885") south_west

    v-icon#east(v-show="cursorFlyNavigation == 'east'" color="#eb8885") east
    v-icon#north_east(v-show="cursorFlyNavigation == 'north_east'" color="#eb8885") north_east
    v-icon#south_east(v-show="cursorFlyNavigation == 'south_east'" color="#eb8885") south_east

  div#camera-center-marker
    div
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  data () {
    return {
      cursorFlyNavigation: "flare",
    }
  },

  mounted() {
    XeokitMediator.on("flyNav", (flyNav) => {
      this.cursorFlyNavigation = flyNav
    });
  }
}
</script>

<style>
.cursor-navigation {
  position: absolute;
  margin-bottom: 110px;
}

#camera-center-marker div {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 25px;
}

#camera-center-marker {
  color: #ffffff;
  padding: 25px;
  opacity: 0.8;
  border-radius: 30px;
  background: #4f4a40;
  visibility: hidden;
  pointer-events: none;
}

#cursor { 
    pointer-events: none; 
    visibility: hidden;
    z-index: 1;
    border-radius: 25px;
    background: #4f4a40;
    padding: 5px;
}
</style>