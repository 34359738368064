<template lang='pug'>
div.d-flex.align.center
  task-progress-bar.flex(:task='task' percent color="#4D4D4D" fontSize='12px' @click='dialogProgress')

</template>

<script>
import { Task } from '@/assets/model/task/Task'
import TaskProgressBar from '@/components/task/TaskProgressBar.vue'

export default {
  name: "TaskTreeRowProgress",

  data() {
    return {
      dialog:{
        progress: false,
      },
    }
  },

  components: { 
    TaskProgressBar,
  },

  props: {
    task: Task,
  },

  methods: {
    dialogProgress() {
      this.dialog.progress = true
    }
  },
}
</script>

<style scoped>

</style>