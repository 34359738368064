<template lang='pug'>
app-dialog(v-model='$_show' v-bind='$attrs' :header='$t("module.task.addAttachment")' :confirm-text='$t("button.add")' :confirm-disabled='!file || (!comment && routePath) || file.size > 10000000' @confirm='addAttachment(file, comment)')
  app-section
    app-file-input( v-model="file" :max-size="10490000" :formats="[]" test-id="task-create-file-input" )
    app-textarea.text-area-comment( v-model="comment" :label="$t('module.task.comment')" outlined )
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import { mapActions } from 'vuex'

export default {
  name: 'TaskCommentAttachmentDialog',

  mixins: [DialogToggable],

  props: {
    task: Object,
  },

  data() {
    return {
      comment: null,
      file: null,
    }
  },

  watch: {
    $_show() {
      this.file = this.comment = null
    }
  },

  computed: {
    routePath() {
      return this.$route.path.includes('/project')
    }
  },

  methods: {
    ...mapActions('task', ['addAttachmentWithCam']),

    addAttachment(file, comment) {
      if (!file) return

      const sourceData = JSON.stringify({ comment })
      this.addAttachmentWithCam({ uuid: this.task.uuid, name: file.name, file, sourceData })
    }
  }
}
</script>

<style>

</style>