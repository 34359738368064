<template lang='pug'>
div.channels-list
    div(v-for='channel in list')
      channel-row.ml-1.mr-1(:channel='channel' @action='handleСhannelAction($event, channel)')
      //- div
      //-   span.c-list-item-title {{ channel.title }}
      //-   transition(name='contol-button')
      //-     div.ml-auto
      //-       v-btn(color="#C4C4C4" @click.stop='showChannelsControlMenu' small icon)
      //-         v-icon mdi-dots-vertical
      //-       app-menu(ref="channelsControl" :menu='channelsSettings')
        //- div(v-for='[icon, action, title] in actions')
        //-   v-tooltip(bottom)
        //-     template(v-slot:activator='{ on, attrs }')
        //-       v-btn(v-bind='attrs' v-on='on' icon x-small @click='action(channel)')
        //-         v-icon(color='var(--v-surface-base)') {{ icon }}
        //-     span {{ $t(title) }}

    app-dialog-confirm(v-model='showDeleteChannelConfirmDialog' delete @confirm='$deleteChannel(nextChannel)') {{ $t('module.mnemoschemas.receivers.channel.confirmChannelDelete', {channelTitle: nextChannel && nextChannel.title}) }}
</template>

<script>
import { mapActions } from 'vuex'
import ChannelRow from './ChannelRow'

const action = {
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add'
}

export default {
  name: "ChannelsList",

  props: ['list'],

  components: {
    ChannelRow
  },

  data: () => ({
    nextChannel: null,
    showDeleteChannelConfirmDialog: false
  }),

  computed: {
    actions() {
      return [
        ['mdi-delete', this.openDeleteChannelConfirmDialog, 'button.delete']
      ]
    },

    channelsSettings() {
      return [
        { title: this.$t("button.edit"), icon: "mdi-pencil", action: this.dev },
        { title: this.$t("button.delete"), icon: "mdi-close", action: this.dev },
        // { title: this.$t("module.mnemoschemas.openInEditor"), icon: "mdi-pencil-outline", action: this.showEditDrawingDialog },
        // { title: this.$t("module.drawing.delete"), icon: "mdi-delete-outline", action: this.showDeleteDrawingDialog },
      ]
    }
  },

  methods: {
    ...mapActions('receiver', ['deleteChannel']),

     handleСhannelAction(actionType, channel) {
      switch (actionType) {
        case action.EDIT: {
          this.dev
          break
        }
        case action.DELETE: {
          this.openDeleteChannelConfirmDialog(channel)
          break
        }
        case action.ADD: {
          this.dev
        }
      }
    },
    
    openDeleteChannelConfirmDialog(channel) {
      this.nextChannel = channel
      this.showDeleteChannelConfirmDialog = true
    },

    $deleteChannel(channel) {
      this.deleteChannel(channel)
    },

    showChannelsControlMenu(event){
      console.log(this.$refs.channelsControl);
      this.$refs.channelsControl.show(event)
    },

    showEditChannelsDialog(){
      console.log('123');
    }
  }
}
</script>

<style lang='scss' scoped>
  .channels-list {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .c-list-item-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>