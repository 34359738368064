<template lang="pug">
  //- app-panel(width = "450")
  app-panel.pt-1.px-1.py-2( ident='floors' padding="4px" )
    task-header.mb-2( :title="$t('floor.floorLabel')" headerItemsCenter mainTitle :menu="menu" )
      template( v-slot:headerAfter )
        app-icon-button( icon="mdi-close" @click="handleClose" test-id="close-app-panel" )
    //- span.header-floor.d-inline-flex.mb-2 {{ $t('floor.floorLabel') }}
    app-section.base-scroll-body.pa-0.pb-4.floor-unit-container
      .d-flex.align-center(style="background-color: #E5E5E5; color: grey; font: normal 13px/16px Roboto, sans-serif; height: 50px; border-radius: 5px")
        div.mark.ml-2 Отн. отметка
        div.mark.ml-2 Абс. отметка
        div.mark.ml-2 {{ $t('floor.mark') }}
        div.name.ml-2 {{ $t('floor.name') }}
        div.accordance.ml-2 {{ $t('floor.accordance') }}
        app-icon-button.ml-auto.mr-5( icon="mdi-plus" buttonSize="small" buttonColor="var(--v-accent-base)" iconColor="white"  @click="openComparison" test-id="new-floor" )
      div
        div.pt-1
          floor-unit(v-for="(fl, index) in readyFloor" :key="index" :element="fl" @editFloor='openEditComparison(fl)')
    build-accordance(v-if="dialog.saveComparison" v-model="dialog.saveComparison" :floorUuid="null" :isEdit="false" @savefloors="sendFloor")
    build-accordance(v-model="dialog.editComparison" :floorUuid="this.floorUuid" :isEdit="true" @editfloors="editingFloor")
</template>

<script>

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex' 
import FloorUnit from './FloorUnit'
import BuildAccordance from './BuildAccordance'
import { AlertService } from '@app/AlertService'
import { projectService } from '@/_services'
import TaskHeader from '@/components/task/TaskHeader'

export default {
  data () {
    return {
      floorForm : {
        elements: [],
        name: '',
        project: ''
      },
      revisions: [],
      floorUuid: null,
      dialog: {
        editComparison: false,
        saveComparison: false,
      },
    }
  }, 

  components: {
    FloorUnit,
    BuildAccordance,
    TaskHeader
  },

  mounted () {
    this.loadLayers(this.projectUuid)
    // this.currentRevisions()
    this.loadFloors(this.projectUuid)
  },

  
  computed: {
    ...mapGetters("project", [ "projectUuid", "floorModel","flatlist" ]),
    ...mapState("axis",[ "floors" ]),

    menu () {
      return [
        { title: this.$t('section.elementTree.menu.makeFloor'), action: this.makeFloor}
      ]
    },

    readyFloor () {
      let floorsData = []
      this.floors.forEach( floor => {
        let floorData = {
          name: floor.name,
          uuid: floor.uuid,
          elements: [],
          project: floor.project,
          elevation: floor.elevation,
          relElevation: floor.relElevation,
          absElevation: floor.absElevation,
        }
        floor.elements.forEach( el => {
          if (this.currentRevisions.find(rev => rev.uuid == el.modelRevision)) {
            floorData.elements.push({ uuid:el.uuid, title:this.floorModel(el.model)?.title, name: el.name, elevation: el.elevation })
          }
        })
        floorsData.push(floorData)
      })
      return floorsData
    },

    currentRevisions () {
      const revisions = []
      this.flatlist.forEach( el => {
        if (el.revision) {
          revisions.push({ uuid:el.revision.uuid })
        }
      })
      return revisions
    },
  },

  methods: {
    ...mapActions("axis", [ "loadLayers", "saveFloor", "loadFloors" ]),
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('project', ['postPanelsStatus']),
    ...mapMutations('axis', ['SET_FLOORS']),
    openComparison () {
      this.dialog.saveComparison = true
    },

    openEditComparison (floor) {
      this.dialog.editComparison = true
      this.floorUuid = floor.uuid
    },

    sendFloor (floorData) {
      this.saveFloor({ savedFloor:floorData, projectUuid:this.projectUuid }).then(() => {
      }).catch( () => {
        let obj = {
          info: this.$t('floor.error')
        }
        AlertService.warning(obj)
      })
    },

    editingFloor (floorData) {
      floorData.uuid = this.floorUuid
      this.saveFloor({ savedFloor:floorData, projectUuid:this.projectUuid }).then(() => {
        this.loadFloors(this.projectUuid)
      }).catch( () => {
        let obj =  {
          info: this.$t('floor.error')
        }
        AlertService.warning(obj)
      })
    },

    getUuid (floorId) {
      this.floorUuid = floorId
    },

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },

    makeFloor () {
      projectService.makeFloor(this.projectUuid).then(() => {
         this.loadLayers(this.projectUuid)
         this.loadFloors(this.projectUuid)
         AlertService.info({info: this.$t('section.elementTree.menu.formedFloor')})
      })
    },

  },
}
</script>

<style scoped lang="scss">
.floor-unit-container {
  max-height: calc(100% - 32px);
}

.mark {
  width: 15%;
}
.name {
  width: 20%;
}
.accordance{
  width: 50%;
}
</style>
      