import { render, staticRenderFns } from "./RulesFounderTreeview.vue?vue&type=template&id=6a908430&scoped=true&lang=pug&"
import script from "./RulesFounderTreeview.vue?vue&type=script&lang=js&"
export * from "./RulesFounderTreeview.vue?vue&type=script&lang=js&"
import style0 from "./RulesFounderTreeview.vue?vue&type=style&index=0&id=6a908430&prod&lang=scss&scoped=true&"
import style1 from "./RulesFounderTreeview.vue?vue&type=style&index=1&id=6a908430&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a908430",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VFadeTransition,VHover,VIcon,VTreeview})
