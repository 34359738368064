<template lang="pug">
  div
    .mx-2.my-2
      app-text-field(
        v-model="settings.pointInterval", :label="$t('viewer.pointClouds.pointInterval')",
        @input="changeVisibility()"
      )

      v-btn.mt-3( color='accent' elevation='0' light :disabled="!wasChange"
        @click="setVisibilitySettings()") {{ $t('button.save') }}
</template>

<script>

import { mapActions } from "vuex";
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  data() {
    return {
      wasChange: false,
      settings: {
        pointInterval: 1,
      }
    }
  },

  methods: {
    ...mapActions("authUser", ["postCloudVisibilitySettings", 'getCloudVisibilitySettings']),

    changeVisibility() {
      this.wasChange = true
    },

    setVisibilitySettings() {
      this.wasChange = false
      XeokitMediator.reloadBimLASLoaderPlugin(this.settings)
      this.settings.interval = this.settings.pointInterval
      this.postCloudVisibilitySettings(this.settings)
    },

    changeVisibilityFromServer() {
      this.getCloudVisibilitySettings().then((data) => {
        if(data.interval) {
          this.settings.pointInterval = data.interval
        } else {
          this.settings.pointInterval = 1
        }
      })
    },
  },

  mounted() {
    this.changeVisibilityFromServer()
  },

}
</script>

<style lang="scss" scoped>

</style>