<template lang="pug">
div.element-rules
  v-progress-linear(v-if="isLoading" color='accent' indeterminate)

  div.element-rules-header
    div.d-flex.align-center.justify-space-between
      v-btn.text-none.pl-0.pr-1( :disabled="isLoading" color="accent" text small light @click="findElementRules(0)") {{ $t("section.elementTree.mode.smetaRules.rulesSearch") }}
      v-btn.text-none.pl-1.pr-0( :disabled="isLoading" color="accent" text small light @click="findElementRules(1)") {{ $t("section.elementTree.mode.smetaRules.rulesSearchFast") }}

    div(v-if="wasLoading && elementRules.length && !isLoading")
      v-btn.text-none.pl-0.pr-1( color="accent" text x-small @click="isExpandAll ? collapseTree() : expandTree()") 
        v-icon( small ) mdi-unfold-more-horizontal
        span.ml-1 {{ isExpandAll ? $t("section.elementTree.axis.collapseTree") : $t("section.elementTree.axis.expandTree") }}
  
  template(v-if="!isLoading")
    template(v-if="wasLoading && elementRules.length")
      v-treeview(ref='element-rules-treeview' :items='elementRules' :open.sync='opened' item-key='uuid' hoverable light dense)
        template(v-slot:label="{ item }")
          //- v-hover(v-slot="{ hover }")
          div.d-flex.align-center( @click="goTo(item)" )
            span.flex.text-over {{ item.pressmark ? item.pressmark + ' - ' : '' }}{{ item.title }}
              //- v-fade-transition
              //-   v-icon.ml-auto.mr-1( v-if="hover && item.type.name == 'RULE'" color='surface' small @click="openElementRulesMenu($event, item)") more_vert
    div.text-center.surface--text(v-else) {{ wasLoading ? $t("section.elementTree.mode.smetaRules.rulesNotFound") : $t("section.elementTree.mode.smetaRules.rulesNotLoaded") }} 

  app-menu(ref='elementRulesMenu' :menu='elementRulesMenu')

</template>

<script>
import { api } from '@/api'
import { mapMutations, mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import store from '@/store'

export default {
  name: 'ElementRules',

  props: {
    element: Object,
  },
  
  data() {
    return {
      opened: [],
      elementRules: [],
      parentCount: 0,
      wasLoading: false,
      isLoading: false,
      menuItem: null,

    }
  },

  computed: {
    ...mapGetters('smeta', ['getNode']),

    isExpandAll() {
      return this.opened.length == this.parentCount
      
    },

    elementRulesMenu() {
      return [
        { title: this.$t("section.elementTree.mode.smetaRules.goToRule"), action: () => this.goTo(this.menuItem) },
      ]
    },
  },

  watch: {
    element() {
      this.elementRules = []
      this.wasLoading = false
      this.isLoading = false
      this.parentCount = 0
      this.opened = []
    },
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab']),

    ...mapActions('smeta', ['expandTo']),
    ...mapMutations('smeta', ['setExpandAfterLoadUuid', 'setEditRuleItem', 'setScrollTo']),

    findElementRules(mode) {
      this.parentCount = 0
      this.wasLoading = true
      this.isLoading = true
      api.smeta.findElementRules(this.element.uuid, mode).then(elementRules => {
        this.isLoading = false
        elementRules.forEach(classificator => {
          this.parentCount++
          const tt = (node) => {
            this.parentCount += node.children.length > 0
            node.projectClassificatorUuid = classificator.uuid
            node.children
            .sort((a, b) => {
              const apressmark = eval(a.pressmark.replace(/[^\d]/g, '+').split('+').map(Number).join('+'))
              const bpressmark = eval(b.pressmark.replace(/[^\d]/g, '+').split('+').map(Number).join('+'))

              return apressmark - bpressmark
            })
            .forEach(tt)
          }
          classificator.children.forEach(tt)
        })

        
        this.elementRules = elementRules
      })
    },

    expandTree() {
      this.$refs['element-rules-treeview'].updateAll(true)
    },

    collapseTree() {
      this.$refs['element-rules-treeview'].updateAll(false)
    },

    goTo(item) {
      if (item.type.name == 'RULE') {
        
        this.setActiveGlobalTab('smeta')

        this.setScrollTo(item.uuid)
        const projectSettings = JSON.parse(JSON.stringify(store.state.project.projectSettings))
        if (projectSettings.projectClassificator != item.projectClassificatorUuid) {
          projectSettings.projectClassificator = item.projectClassificatorUuid
          store.dispatch('project/updateSettingsProjectClassificator', projectSettings)
          this.setExpandAfterLoadUuid(item.uuid)
        }
        else {
          this.expandTo(item.uuid)
          const fullNode = this.getNode(item.uuid)
          this.setEditRuleItem(fullNode)
        }
      }
    },

    openElementRulesMenu(event, item) {
      if (item.type.name == 'RULE') {
        this.$refs['elementRulesMenu'].show(event)
        this.menuItem = item
      }
    },
  }
}
</script>

<style scoped>
  .element-rules-header {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;
  }

  .text-over {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }

  .element-rules >>> .v-icon.v-icon {
    font-size: 16px;
  }
</style>