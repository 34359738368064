<template lang='pug'>
v-tooltip( :disabled='!tooltip || disabled' :[tooltipSide]="true" )
  template( v-slot:activator='{ on, attrs }' )
    .relative( v-bind='attrs' v-on='on' :style='buttonStyle' )
      v-btn.pa-0( v-on='$listeners' height="32" :disabled='disabled' color="transparent" tile block elevation="0" )
        v-icon( size="32" color="var(--v-primary-darken2)" )
          slot

  span( v-html="tooltip" )
</template>

<script>
export default {
  props: {
    active: Boolean,
    disabled: Boolean,
    tooltip: String,
    tooltipSide: {
      type: String, // top | right | bottom | left
      default: "bottom",
      validator(value) {
        return !value || ['left', 'right', 'bottom', 'top'].includes(value) 
      }
    },
  },

  computed: {
    buttonStyle() {
      return {
        width: '32px',
        height: '32px',
        backgroundColor: this.active ? 'var(--v-surface-darken3)' : 'var(--v-surface-base)',
        opacity: this.disabled ? 0.2 : 1,
      }
    }
  }
}
</script>