<template lang="pug">
//- app-panel(width = "450")
app-panel.pt-1.px-1.py-2( ident='api' padding="4px" )
  task-header.mb-2( :title="$t('token.header.name')" headerItemsCenter mainTitle )
    template( v-slot:headerAfter )
      app-icon-button( icon="mdi-close" @click="handleClose"  )
      
  app-section.m-2
    .d-flex.align-center(style="background-color: #E5E5E5; color: grey; font: normal 12px/16px Roboto, sans-serif; height: 32px; border-radius: 5px;")
        span.d-inline-flex.ml-3.header-name {{$t("token.form.code")}}
        span.d-inline-flex.header-accord {{$t("token.form.dateTo")}}
        span.d-inline-flex.header-accord1 {{$t("token.form.scope")}}
    .edit-project__tokens-container.px-3.py-4
      .d-flex(v-for="(token, index) in tokenlist" :key="index")
        div.token-line
          button.button-copy( type="button" @click="copyToken(token.token)" )
            .edit-project__tokens-name.primary--text.text--darken-4.text-caption.mt-2.mx-3.token.button-copy( v-line-clamp="2" ) {{ prettyToken(token.token) }} 
            v-btn.btn-copy(icon color="grey" x-small)
              v-icon mdi-content-copy
          .edit-project__tokens-name.primary--text.text-caption.mt-2.button-copy(style = "padding-left:20px" v-line-clamp="2" v-bind:class="checkSpoiledToken(token.expiredDate) ? 'text--red-spoiled-token' : 'text--darken-4'" ) {{ token.expiredDate.slice(0, -6) }} 
          .edit-project__tokens-name.primary--text.text--darken-4.text-caption.mt-2.button-copy( v-line-clamp="2" style = "padding-left: 50px" ) {{ $t(token.level.title) }} 
          v-btn.btn-copy(@click="confirmDelTokenShow(token)" icon color="red" x-small)
            v-icon mdi-delete
      app-dialog-confirm(v-model="dialog.delToken" ref="delToken" @confirm="onDeleteToken(selectedToken)")
        app-text {{$t("token.dialog.deleteConfirm")}} "{{ prettyToken(selectedToken.token) }}"?
      .d-flex.mt-4
        v-spacer
        .align-content-end
          v-btn.text-none.edit-project__tokens-button( color="accent" @click="onTokenAdd" ) {{ $t('button.add') }}
    app-dialog(v-if="editableToken" v-model="dialog.addToken" :header="$t('token.dialog.add.title')" min-width="376")
      token-add-form(:token="editableToken" @change="addTokenAction")
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import TokenAddForm from '@forms/token/TokenAddForm'
import { AlertService } from '@app/AlertService'
import { TokenDto } from '@models/Token'
import { ProjectShortcut } from '@models/Project'
import TaskHeader from '@/components/task/TaskHeader'

export default{
  components: {
    TokenAddForm,
    TaskHeader
  },
  
  props: {
    project: {
      type: ProjectShortcut,
      default: null
    },
    withDelete: Boolean
  },

  data () {
    return {
      editableToken: null,
      tabs: null,
      preview: '',
      dialog: {
        addToken: false,
        delToken: false,
      },
      selectedToken: {
        uuid: null,
        token: "",
        project: null,
        createDate: null,
        expiredDate: null,
        discribe: null,
        frequency: null,
        level: null
      }
    }
  },

  computed: {
    ...mapState('tokens', ['listTokens']),
    ...mapGetters("project", ["projectShortcut"]),

    tokenlist () {
      return this.listTokens.filter(token=>!token.invisibleToken)
    },
  },

  mounted() {
    this.LOAD_TOKENS(this.projectShortcut.uuid)
    console.log(this.projectShortcut)
  },
  
  methods: {
    ...mapActions('project', ['setProjectPreview', 'postPanelsStatus']),
    ...mapActions('tokens', ['LOAD_TOKENS', 'deleteToken']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    async preparePreview() {
      var imagefile = document.getElementById('upload-preview')
      if (imagefile.files[0] == null) return
      await this.setProjectPreview({ uuid: this.projectShortcut.uuid, file: imagefile.files[0] }).then(preview => {
        this.preview = preview
        this.projectShortcut.preview = this.preview 
      })
    },

    copyToken(token) {
      navigator.clipboard.writeText(token)
      AlertService.success({info:this.$t('token.action.copied')})
    },

    prettyToken(token){
      return token[0]+token[1]+token[2]+"..."+token[token.length-4]+token[token.length-3]+token[token.length-2]+token[token.length-1]
    },
    
    checkSpoiledToken(expiredDate){
      let date = new Date();
      let expiredDateFormat = new Date(expiredDate);
      if(date>expiredDateFormat){
        return true;
      }
      else{
        return false;
      }
    },
    
    confirmDelTokenShow(token){
      this.selectedToken = token;
      this.dialog.delToken=true;
    },

    onDeleteToken(token){
      this.deleteToken(token)
      AlertService.success({info:this.$t('token.action.removed')})
    },

    onTokenAdd () {
      this.editableToken = new TokenDto({"project":this.projectShortcut.uuid});
      console.log(this.editableToken)
      this.dialog.addToken = true
    },

    addTokenAction() {
      this.dialog.addToken = false

      this.LOAD_TOKENS(this.projectShortcut.uuid)
    },

    onEdit () {
      this.$emit('change')
    },

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    }
  },
}

</script>

<style scoped lang="scss">
.header-name{
  padding-left: 20px;
}
.header-accord{
  padding-left: 60px;
}
.header-accord1{
  padding-left: 35px;
}
.edit-project__tokens-container{
  background: #fff;
  width:420;
  min-height:210;
  border-radius: 5px;

}
.token{
  width: 70px;
  margin-right: 0px!important;
}
.button-copy{
  float: left;
  white-space: nowrap;
  margin-left: 5px!important;
}
.btn-copy{
  margin-top: 5.5px;
  float: right;
}
.token-list{
  overflow-y: auto;
  scrollbar-width: none;
}
.token-line{
  width:100%;
  border-bottom: 0.5px solid #1e2224;
}
</style>