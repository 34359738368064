import { render, staticRenderFns } from "./TaskSubTasksSection.vue?vue&type=template&id=6939bc80&scoped=true&lang=pug&"
import script from "./TaskSubTasksSection.vue?vue&type=script&lang=js&"
export * from "./TaskSubTasksSection.vue?vue&type=script&lang=js&"
import style0 from "./TaskSubTasksSection.vue?vue&type=style&index=0&id=6939bc80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6939bc80",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
