<template lang="pug">
   v-hover( v-slot="{ hover }" test-id="task-comment-card")
    .relative.px-2( id="commentBlock" :class="commentCreator(comment).uuid == user.uuid ? 'user-comment' : 'another-user-comment'" )
    
      div.pt-2( v-if="comment.quotedCommentUuid")

        task-comment-creator( :comment="comment" )

        div.pt-2(v-if="comment.quotedCommentUuid" :class="widthComment ? 'comment-size-end' : 'comment-size'")
          div.flex-1.d-flex(:class="commentCreator(comment).uuid == user.uuid ? 'user-quoted-comment' : 'another-user-quoted-comment'")
            v-img.reply-icon( :src="`/img/project/tools/reply-dark.svg`" width="20" height="20" light)
              //- div.comment-card.pt-1.pl-2.pb-2(:min-width="{returnCommentMinWidth}")
            div.comment-card.pt-1.pl-2.pb-2
              task-comment.size-comment( ref="taskComment" :editable="false" :comment="comment.quotedComment" )
              task-comment-creator( :comment="comment" short )

          //- v-divider( light )
        
        task-comment-menu( v-if="hover"
          :comment="comment" 
          @editComment="editComment()" 
          @deleteComment="deleteComment()" 
          @quotedComment="$emit('quotedComment', comment)"
        )

        task-comment.pt-2.pb-2.size-comment( ref="taskComment" :comment="comment" :class="widthComment ? 'comment-size-end' : 'comment-size'" )
        

      div.pt-2(v-else)
        task-comment-menu( v-if="hover"
          :comment="comment" 
          @editComment="editComment()" 
          @deleteComment="deleteComment()" 
          @quotedComment="$emit('quotedComment', comment)"
        )

        task-comment-creator( :comment="comment" )

        task-comment.pt-2.pb-2( ref="taskComment" :comment="comment" :class="widthComment ? 'comment-size-end' : 'comment-size'")

</template>

<script>
import { SourcePath } from '@/assets/app/SourcePath'
import { mapState, mapActions } from 'vuex'
import TaskSimpleAttachment from '@/components/task/taskDetailsSections/TaskSimpleAttachment'
import TaskAttachment from '@/components/task/taskDetailsSections/TaskAttachment'
import TaskComment from '@/components/task/taskDetailsSections/taskComments/TaskComment'
import TaskCommentCreator from '@/components/task/taskDetailsSections/taskComments/TaskCommentCreator'
import TaskCommentMenu from '@/components/task/taskDetailsSections/taskComments/TaskCommentMenu'
import { TaskBimAnnotations } from '../../taskBimAnnotations'

export default {
  name:"TaskCommentCard",
  data (){
    return {
      commentParsed: false,
    }
  },

  components: {
    TaskSimpleAttachment,
    TaskAttachment,
    TaskComment,
    TaskCommentCreator,
    TaskCommentMenu
  },

  props:{
    comment: null,
  },

  computed:{
    ...mapState('authUser', ['user']),
    ...mapState('task', ['selectedTask']),
    ...mapState('layoutControler', ['detailsWidth']),

    changeParseState: {
      get() {
        return this.parsedComments
      },

      set(value) {
        this.parsedComments = value
      }
    },

    widthComment () {
      

      return this.detailsWidth > 500
    },

    // returnCommentMinWidth(){
    //   let width
    //   if (document.getElementById("commentBlock")){
    //     width = document.getElementById('commentBlock').offsetWidth
    //   }
    //   return width / 2
    // }
  },

  methods:{
    ...mapActions('task', ['delComment', 'delCommentOrAttachment']),

    editComment() {
      this.$refs.taskComment.editCommentMarkdown()
    },

    deleteComment() {
      this.delCommentOrAttachment({taskUid: this.selectedTask.uuid, comment: this.comment}).then(()=>{
        TaskBimAnnotations.showTaskBimAnnotations()
      })
    },

    avatarText (profile) {
      return profile.archive ? profile.name + this.$t('section.structure.model.memberDeleted') : profile.name
    },

    commentCreator(comment) {
      if(comment.author){
        return comment.author
      } else if (comment.creator){
        return comment.creator
      } else {
        return ""
      }
    },

    avatarUrl(profile) {
      return profile.avatar ? SourcePath.avatar(profile.avatar) : ''
    },
    
  },
 
}
</script>

<style scoped>
  .light {
    background-color: #ffffff;
  }

  .another-user-comment {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
    border-radius: 3px;

  }
  .user-comment {
    background-color:  #DFF6FD;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
    border-radius: 2px;
  }
  .date-text {
    color: #757575 !important;
  }
  .header-box{
    position: absolute; 
    display: flex;
    align-items: center;
    max-width: 185px;
    height: 28px;
    right: -5px;
    top:0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
    border-radius: 3px;
    padding-left: 5px;
    padding-right: 5px
  }
  .header-box:hover{
    transition: 100ms;
  }
  .padding-right{
    padding-right: 13px;
  }
  .margin-right{
    margin-right: 5px;
  }
  
  .reply-icon{
    flex: 0 1 auto;
  }
  .comment-card{
    /* position: relative; */
    /* flex: 0 1 auto; */
    min-width: 100px;
  }
  .d-grid{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: 1fr auto auto;
    /* grid-template-rows: repeat(2, 210); */

  }
  .v-divider{
    border-color: black;
  }
  .comment-size-end {
    padding-left: 27px;
  }
  .comment-size {
    padding-left: 0%;
  }
  .user-quoted-comment{
    background: #FFFFFF;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-family: 'Roboto';
    font-style: italic;
    font-size: 14px;
  }
  .size-comment{
    font-size: 14px;
    font-family: 'Roboto';
  }
  .another-user-quoted-comment{
    background: #F4F4F4;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-style: italic;
    font-size: 14px;
  }
</style>