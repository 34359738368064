<template lang="pug">
  app-dialog( v-model="visible" width="350" :header="$t('section.collision.menu.export.default')" :confirm-text="$t('button.export')" @confirm="exportTo")
    app-section
      app-select( v-model="selectedType" :items="items" :label="$t('section.collision.menu.export.format')")
      v-checkbox( v-if="!isAttr" light v-model="attachImage" :label="$t('section.collision.menu.export.images')")

</template>

<script>
import { mapActions } from 'vuex'
import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'

export default {
  props: {
    isAttr: {
      default: false,
      type: Boolean
    },
  },

  data() {
    return {
      visible: false,
      items: this.isAttr ? ['XLSX', 'PDF', 'BCF'] : ['XLSX', 'PDF', 'DOCX'],
      selectedType: null,
      attachImage: false,
    }
  },

  computed: {
    ...mapStores(useAttributeCheckingTableStore),
  },

  methods: {
    ...mapActions('collision/search', ['exportToReport']),

    show() {
      this.selectedType = this.items[0]
      this.attachImage = false
      this.visible = true
    },

    exportTo() {
      if (this.isAttr) this.attributeTableStore.exportToReport(this.selectedType)
      else this.exportToReport({fileType:{value:this.selectedType}, attachImage:this.attachImage})
    },
    
  },
}
</script>
