<template lang="pug">
  app-dialog(v-model="$_show" width="700" :header="$t('section.journal.eventProfiles')" complex) 
    v-row(no-gutters)
        v-col(cols=4)
           app-section-external-header {{ $t('section.equipment.warning.event') }}
        v-col.pl-2(cols=8)
           app-section-external-header  {{ $t('section.equipment.warning.listSigns') }}

    v-row(no-gutters)
        v-col(cols=4)
           app-section.h-100
             v-row(no-gutters v-for="item in profiles" :key="item.uuid" :style="selectedProfile && selectedProfile.uuid == item.uuid ? activeClass : noActiveClass" @click="setSelectedProfile(item)") 
               v-col(v-if="item.title") {{ item.title }}
               v-col(cols="auto" v-if="item.level") {{ $t(item.level.message) }}

           v-row.mt-n7(no-gutters)
             v-col.pl-2
               v-icon.icon-wrapper( size="18" @click="addProfile") add
               v-icon.icon-wrapper.ml-2( v-if="selectedProfile" size="18" @click="editProfile") edit
               v-icon.icon-wrapper.ml-2( v-if="selectedProfile" size="18" @click="confirmDeleteProfile") clear

    
        v-col.pl-2(cols=8)
           app-section.h-100
             template(v-if="selectedProfile && selectedProfile.warningFeatures.length>0")
               v-row(no-gutters v-for="item in selectedProfile.warningFeatures" :key="item.uuid" :style="selectedFeature && selectedFeature.uuid == item.uuid ? activeClass : noActiveClass" @click="setSelectedFeature(item)") 
                 v-col 
                   template(v-if="item.warningFeatureType == warningFeatureType[0].key")
                     span {{ channelTitle(item) }} {{ $t('section.equipment.warning.includedRange', {min: item.min, max: item.max}) }}

                   template(v-if="item.warningFeatureType == warningFeatureType[1].key")
                     span {{ equipmentTitle(item) }} {{ $t('section.equipment.warning.delay') }} {{ item.delay }} {{ periodType(item) }} 

           v-row.mt-n7(no-gutters v-if="selectedProfile")
             v-col.pl-2
               v-icon.icon-wrapper( size="18" @click="addFeature") add
               v-icon.icon-wrapper.ml-2( v-if="selectedFeature" size="18" @click="editFeature") edit
               v-icon.icon-wrapper.ml-2( v-if="selectedFeature" size="18" @click="confirmDeleteFeature") clear
    
    v-row(no-gutters)
        v-col(cols=4)
        v-col.text-right(cols=8)
           app-button.mt-3( action @click="apply" :disabled="!valid") {{ $t('section.equipment.warning.apply') }}
    

    app-dialog(v-if="selectedProfile && dialog.saveProfile" v-model="dialog.saveProfile" :header="$t('section.equipment.warning.event')" :confirmDisabled="!editedProfileItem.title" @close="closeSaveProfileDialog" @confirm="saveWarningProfile(editedProfileItem)" :confirmText="$t('button.save')" width="430")
      app-text-field.mb-2(v-model="editedProfileItem.title" :label="$t('form.dictionaries.name')" hide-details)
      app-select( v-model="editedProfileItem.level" :items="warningLevels" item-value="uuid" item-text="message" :label="$t('section.equipment.warning.level')" return-object)

    app-dialog-confirm(v-if="selectedProfile" v-model="dialog.deleteProfile" @confirm="deleteProfile(selectedProfile.uuid)")
      .text-subtitle-1.pt-5 {{ $t('section.equipment.form.remove') }} - {{ selectedProfile.title }} ?

    app-dialog(v-if="selectedFeature" v-model="dialog.saveFeature" :header="$t('section.equipment.warning.sign')" @confirm="saveWarningFeature(editedFeature)" :confirmText="$t('button.save')" width="420")
      app-select.mb-2( v-if="selectedFeature.uuid == null" v-model="selectedFeature.warningFeatureType" :items="warningFeatureType" item-value="key" item-text="message" :label="$t('section.equipment.warning.type')")

      template(v-if="selectedFeature.warningFeatureType == warningFeatureType[0].key")
        app-autocomplete.my-2(v-model="selectedFeature.channel" :items="channelList" :label="$t('section.equipment.warning.channel')" item-value="uuid")
        app-text-field.mb-2(v-model="selectedFeature.min" :label="$t('section.equipment.warning.minimal')" hide-details)
        app-text-field(v-model="selectedFeature.max" :label="$t('section.equipment.warning.maximum')" hide-details)

      template(v-if="selectedFeature.warningFeatureType == warningFeatureType[1].key")
        app-autocomplete.my-2(v-if="isEquipmentsLoaded" v-model="selectedFeature.equipmentSpecification" :items="equipments" item-text="title" :label="$t('corp.equipment')" item-value="uuid")
        v-row.my-2(no-gutters)
          v-col
            app-text-field(v-model="selectedFeature.delay" :label="$t('section.equipment.warning.period')" hide-details)
          v-col
            app-select.ml-2( v-if="isPeriodLoaded" v-model="selectedFeature.periodType" :items="maintenancePeriod" :label="$t('section.equipment.warning.periodType')" item-text="title")


    app-dialog-confirm(v-if="selectedFeature" v-model="dialog.deleteFeature"  @confirm="deleteWarningFeature(selectedFeature.uuid)")
      .text-subtitle-1.pt-5 {{ $t('section.equipment.warning.deleteFeature') }}

      
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable'

export default {
  mixins: [DialogToggable],
  data () {
    return {
      dialog: {
        saveProfile: false,
        deleteProfile: false,
        saveFeature: false,
        deleteFeature: false,
      },
      editedProfileItem: null,
    }
  },

  mounted () {
  },

  computed: {
    ...mapState('equipmentWarning', ['warningFeatureType', 'profiles', 'loaded', 'selectedProfile', 'selectedFeature', 'warningLevels']),
    ...mapGetters('equipmentWarning', ['editedProfile', 'editedFeature']),
    ...mapGetters('corp', ['maintenancePeriod', 'isPeriodLoaded']),
    ...mapGetters('project', ['projectOrgId']),
    ...mapGetters('corp', [ 'equipments', 'isEquipmentsLoaded']),
    ...mapState('receiver', ['receiversWithChannels']),

    valid() {
      return this.selectedProfile && this.selectedProfile.uuid && this.selectedProfile.warningFeatures.length>0
    },

    activeClass() {
      return "font-size: 12px; font-weight: 500; color: "+this.$vuetify.theme.themes.dark.accent.base
    },

    noActiveClass() {
      return "font-size: 12px; font-weight: 500; color: "+this.$vuetify.theme.themes.dark.primary.base
    },

    channelNameMap() {
      let res = new Map()
      this.receiversWithChannels.forEach(r => {
        r.channels.forEach(c => {
          res.set(c.uuid, c.title)
        })
      })
      return res
    },

    channelList() {
      let res = []
      this.receiversWithChannels.forEach(r => {
        let m = { ...r }
        m.disabled = true
        m.text = m.name
        res.push(m)
        r.channels.forEach(c => {
          let n = { ...c }
          n.text = '| - ' + n.title
          res.push(n)
        })
      })
      return res
    }
  },

  methods: {
    ...mapActions('equipmentWarning', ['addWarningProfile', 'saveWarningProfile', 'deleteWarningProfile', 'addWarningFeature', 'saveWarningFeature', 'deleteWarningFeature']),
    ...mapMutations('equipmentWarning', ['setSelectedProfile', 'setSelectedFeature']),

    loadData() {
      this.$store.dispatch('corp/LOAD_MAINTENANCE_PERIOD')
      this.$store.dispatch('receiver/loadReceiversWithChannels')
      if(this.projectOrgId != null)
        this.$store.dispatch('corp/LOAD_ORG_EQUIPMENTS', this.projectOrgId)
    },

    addProfile() {
      this.addWarningProfile()
      this.editedProfileItem = {...this.editedProfile}
      this.dialog.saveProfile = true
    },

    editProfile() {
      this.editedProfileItem = {...this.editedProfile}
      this.dialog.saveProfile = true
    },

    confirmDeleteProfile() {
      this.dialog.deleteProfile = true
    },

    deleteProfile(uuid) {
      this.deleteWarningProfile(uuid)
      this.setSelectedProfile(null)
    },

    addFeature() {
      this.addWarningFeature()
      this.dialog.saveFeature = true
    },

    editFeature() {
      this.dialog.saveFeature = true
    },

    confirmDeleteFeature() {
      this.dialog.deleteFeature = true
    },

    periodType(item) {
      return item.periodType?.title || ""
    },

    equipmentTitle(item) {
      return this.isEquipmentsLoaded ? "'" + this.equipments.find(e => e.uuid == item.equipmentSpecification)?.title + "'" : ""
    }, 

    channelTitle(item) {
      return this.receiversWithChannels ? "'" + this.channelNameMap.get(item.channel) + "'" : ""
    }, 

    apply() {
      this.$emit('edited')
    },

    closeSaveProfileDialog(){
      this.editedProfileItem = null
      this.setSelectedProfile(null)
    }
  }
}
</script>

<style lang="scss" scoped>
.h-100 {
  height:100%;
  max-height:500px;
  min-height:300px;
  overflow: auto;
  padding-bottom: 35px;
}
.header {
  font-size: 11px;
  color: var(--v-surface-lighten3)!important;
  border-bottom: 1px solid var(--v-surface-lighten3);
}
.icon-wrapper {
  background-color: #C4C4C4;
  border-radius: 2px;
  cursor: pointer;
}
</style>