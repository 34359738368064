import { fetchGroupPaletteForGroupWithUuid } from "@/facades/groupPalette.facade"
import { fetchAllGroups, fetchElementsForGroupWithUuid } from "@/facades/groups.facade"

function allGroupsWithPalletes() {
  let list = fetchAllGroups()
  list = list.map(({ uuid, title }) => (
    {
      uuid,
      title,
      disabled: !fetchElementsForGroupWithUuid(uuid) && !fetchGroupPaletteForGroupWithUuid(uuid)
    }
  ))
  return list
}

export const GroupsPalettesAdapter = {
  allGroupsWithPalletes,
}