<template lang="pug">
app-dialog(v-model="$_show" width="700" :header="$t('form.dictionaries.default')" complex ) 
  v-row(no-gutters)
    v-col(cols=4)
        app-section-external-header {{ $t('form.dictionaries.composition') }}
    v-col(cols=8)
        app-section-external-header  {{ $t('form.dictionaries.listElements') }}

  v-row(no-gutters)
    v-col(cols=4)
      app-section.h-100.base-scroll-body.d-flex.flex-column.justify-space-between( :rounded="false" )
        div
          v-row.header.mb-1(no-gutters)
            v-col.clr {{ $t('form.dictionaries.name') }}
            v-col.clr(cols="auto") {{ $t('token.form.code') }}
          v-row(no-gutters v-for="item in dictionaries" :key="item.uuid" :style="dictionary && dictionary.uuid == item.uuid ? activeClass : noActiveClass" @click="selectDict(item)") 
            v-col {{ item.name }}
            v-col(cols="auto") {{ item.alias }}

        .d-flex.pt-1
          app-icon-button( icon="$add-box-outlined" buttonSize="20px" light @click="addDictionary" )
          app-icon-button.pl-1( v-if="dictionary" icon="$edit-outlined" iconSize="18px" buttonSize="20px" light @click="editDictionary" )
          app-icon-button.pl-1( v-if="dictionary" icon="$close-outlined" buttonSize="20px" light @click="dialog.delDictionary = true" )

    v-col( cols=8 style="padding-left: 1px;")
      app-section.h-100.base-scroll-body.d-flex.flex-column.justify-space-between( :rounded="false" )
        template(v-if="dictionary && dictionary.words.length>0")
          div
            v-row.header.mb-1(no-gutters)
              v-col.clr {{ $t('form.dictionaries.name') }}
              v-col.clr(cols="auto") {{ $t('token.form.code') }}
            v-row(no-gutters v-for="item in dictionary.words" :key="item.uuid" :style="word.uuid == item.uuid ? activeClass : noActiveClass" @click="selectWord(item)") 
              v-col {{ item.name }}
              v-col(cols="auto") {{ item.alias }}

          .d-flex.pt-1(no-gutters v-if="dictionary")
            app-icon-button( icon="$add-box-outlined" buttonSize="20px" light @click="addWord" )
            app-icon-button.pl-1( v-if="word.uuid" icon="$edit-outlined" iconSize="18px" buttonSize="20px" light @click="dialog.saveWord = true" )
            app-icon-button.pl-1( v-if="word.uuid" icon="$close-outlined" buttonSize="20px" light @click="dialog.delWord = true" )

  v-row.justify-end.white.pa-2(no-gutters style="margin-top: 1px;")
    app-button(@click="apply" color="accent" :disabled="!valid") {{ $t('button.save') }}
  

  app-dialog(v-if="dictionary" v-model="dialog.saveDictionary" :header="$t('form.formFields.dictionary')" :confirmDisabled="!dictItem.name" @confirm="saveDictionary(dictItem)" @close="closeSaveDictionaryDialog" :confirmText="$t('button.save')" width="430")
    app-text-field(v-model="dictItem.name" :label="$t('form.dictionaries.name')")
    app-text-field(v-model="dictItem.alias" :label="$t('form.dictionaries.alias')")

  app-dialog-confirm(v-if="dictionary" v-model="dialog.delDictionary" @confirm="preDeleteDictionary()")
    .text-subtitle-1.pt-5 {{ $t('form.dictionaries.deleteDictionary') }} - {{ dictionary.name }} ?

  app-dialog(v-model="dialog.saveWord" :header="$t('form.dictionaries.word')" @confirm="preSaveWord()" :confirmText="$t('button.save')" width="420")
    app-text-field(v-model="word.name" :label="$t('form.dictionaries.name')")
    app-text-field(v-model="word.alias" :label="$t('form.dictionaries.alias')")

  app-dialog-confirm(v-model="dialog.delWord"  @confirm="preDeleteWord()")
    .text-subtitle-1.pt-5 {{ $t('form.dictionaries.deleteWord')}} - {{ word.name }} ?

      
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable'

export default {
  props: {
    dictionaryId: null
  },
  mixins: [DialogToggable],
  data () {
    const defaultDict = Object.freeze({
          uuid: null,
          name: null,
          alias: null,
          bind: null,
          words: []
        })

    const defaultWord = Object.freeze({
          uuid: null,
          name: null,
          alias: null,
        })

    return {
      word: Object.assign({}, defaultWord), 
      dialog: {
        saveDictionary: false,
        delDictionary: false,
        saveWord: false,
        delWord: false,
      },
      dictItem: null,
      defaultDict,
      defaultWord,
    }
  },

  watch: {
    $_show (value) {
      if (value && this.dictionaryId) {
         if(this.isDictionariesLoaded){
            let curr = this.dictionaries.find(item => item.uuid === this.dictionaryId)
            if(curr !== undefined) {
              this.setDictionary(curr)
            }
         }
      }
    }
  },

  mounted () {
    this.$store.dispatch('forms/LOAD_DICTIONARIES', { bind: this.projectUuid })
  },

  computed: {
    ...mapGetters('forms', ['dictionary', 'dictionaries', 'isDictionariesLoaded']),
    ...mapGetters('project', ['projectUuid']),

    valid() {
      return this.dictionary && this.dictionary.uuid && this.dictionary.words.length>0
    },

    activeClass() {
      return "font-size: 12px; font-weight: 500; color: "+this.$vuetify.theme.themes.dark.accent.base
    },

    noActiveClass() {
      return "font-size: 12px; font-weight: 500; color: "+this.$vuetify.theme.themes.dark.primary.base
    },

  },

  methods: {
    ...mapActions('forms', ['saveDictionary', 'deleteDictionary', 'saveWord', 'deleteWord']),
    ...mapMutations('forms', ['setDictionary']),

    addDictionary () {
      let newDict = JSON.parse(JSON.stringify(this.defaultDict))
      newDict.bind = this.projectUuid
      this.setDictionary(newDict)
      this.dictItem = {...this.dictionary}
      this.dialog.saveDictionary = true
    },

    editDictionary() {
      this.dialog.saveDictionary = true
    },

    addWord () {
      let newWord = JSON.parse(JSON.stringify(this.defaultWord))
      this.word = newWord 
      this.dialog.saveWord = true
    }, 

    preDeleteDictionary () {
      this.deleteDictionary(this.dictionary.uuid) 
      this.setDictionary(null)
    }, 

    preSaveWord () {
      if(!this.word.uuid)
        this.dictionary.words.push(this.word)
      this.word.dictionary = { "uuid" : this.dictionary.uuid }
      this.saveWord(this.word)
    },

    preDeleteWord () {
      this.deleteWord(this.word.uuid)
      this.word = Object.assign({}, this.defaultWord)
    },

    selectDict (item) {
      this.setDictionary(item)
      this.dictItem = {...this.dictionary}
      this.word = Object.assign({}, this.defaultWord)
    },

    selectWord (item) {
      this.word = item
    },

    apply() {
      this.$emit('update:dictionaryId', this.dictionary.uuid)
      this.$emit('edited')
    },

    closeSaveDictionaryDialog(){
      this.setDictionary(null)
      this.dictItem = this.defaultDict
    }
  }
}
</script>

<style lang="scss" scoped>
.h-100 {
  height:100%;
  max-height:500px;
  min-height:300px;
  overflow: auto;
}
.header {
  font-size: 11px;
  color: var(--v-surface-lighten3)!important;
  border-bottom: 1px solid var(--v-surface-lighten3);
}
.icon-wrapper {
  background-color: #C4C4C4;
  border-radius: 2px;
  cursor: pointer;
}
.clr{
  color: #505254;
}
</style>