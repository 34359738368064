import { redrawSceneElements, recolorizeScene } from "@/facades/scene.facade"

export function decorateToRebuildScene(callee) {
  return (...args) => {
    callee(...args)
    redrawSceneElements()
  }
}

export function decorateToRecolorScene(callee) {
  return (...args) => {
    callee(...args)
    recolorizeScene()
  }
}