import { useDrawingsStore } from "@/pinia"
import { DrawingPageContextMenuService } from "./drawingPageContextMenuService"
import { DrawingPageScalingService } from "./drawingPageScalingService"
import vuexStore from "@/store"

export class DrawingPageCommentsService {

	static get #store() { return useDrawingsStore() }

  static importCommentNewPage() {
		const commentsToImport = this.#store.commentsToImport.map(comment => { return comment })
		if (this.#store.flagCommentToImport) this.#store.importCommentToNewPage(this.#store.selectedDrawingPage, commentsToImport)
		else this.#store.importCommentToNewPage(this.#store.selectedDrawingPage,commentsToImport)
	}

	static showCreateCommentDialog() {
		DrawingPageContextMenuService.hideContextMenu()

		this.#store.dialogVisible = true
		this.#store.newCommentForm.text = ''
		
		if (this.selectedTask) {
			this.#store.newCommentForm.task = this.selectedTask
			this.#store.showCommentTaskBinding = true
		}
		else {
			this.#store.newCommentForm.task = null
			this.#store.showCommentTaskBinding = false
		}
		
		if (vuexStore.state.task.tasks.length == 0) {
			this.#store.tasksIsLoading = true

			vuexStore.dispatch('task/loadTasks').then(() => {
				this.#store.tasksIsLoading = false
			})
		}
	}

	static toggleCommentMode() {
		this.#store.commentMode = !this.#store.commentMode
		DrawingPageContextMenuService.hideContextMenu()
	}

	static scrollPageToComment(comment) {
		const selectedPageBody = document.getElementById("drawingSelectedPageBody")
		const pseudoPagePanel = document.getElementById("drawingPseudoPagePanel")
		const pageBodyClientRect = selectedPageBody.getBoundingClientRect()

		this.#store.setScaleDrawing(comment.scale)
		DrawingPageScalingService.scaleSelectedPage(false)

		setTimeout(() => {
			const xCoord = ((pseudoPagePanel.getBoundingClientRect().width * (comment.x / 100))) - pageBodyClientRect.width / 2
			const yCoord = ((pseudoPagePanel.getBoundingClientRect().height * (comment.y / 100))) - pageBodyClientRect.height / 2

			selectedPageBody.scrollTo(xCoord, yCoord)

			this.#store.selectedDrawingPage.offsets = {
				scale: this.scaleDrawing,
				scroll: {
					x: xCoord,
					y: yCoord
				}
			}
		})
	}

	static getCommentPositionStyle(comment) {
		return {
			left: comment.x + "%",
			top: `calc(${comment.y}% - 30px`,
			position: "absolute"
		}
	}
}