<template lang='pug'>
task-new-gantt
</template>

<script>
import TaskNewGantt from '@/components/task/gantt/TaskNewGantt.vue'

export default {
  name: 'TaskGantt',

  components: {
    TaskNewGantt,
  },
}
</script>

<style>

</style>