<template lang="pug">
div.task-details-container.justify-space-between( v-if="selectedTask.uuid" )
  div.base-scroll-body( id="task-details-section-body" test-id="task-details-section-body" )
    div.mr-1
      app-section.section-style.pb-1
        task-info-section.mt-1( ref="taskInfoSec" :selectedTask="selectedTask" )
        task-status( ref='taskStatus' :task="selectedTask" useMenu onlyMenu )
        task-priority.mt-2.ml-2( ref='taskPriority' :task="selectedTask" useMenu theme="dark" onlyMenu )
        task-smeta-section.select-sec( :task="selectedTask" )
        task-sub-tasks-section.select-sec.mt-1( :selectedTask="selectedTask" test-id="task-subtask-section" )
        task-appointed-section.select-sec.mt-1( ref="taskAppointed" :selectedTask="selectedTask" )
        task-models-section.select-sec.mt-1( ref="taskModel" :selectedTask="selectedTask" )
        task-collisions-section.select-sec.mt-1( :selectedTask="selectedTask" )
        task-attachments-section.select-sec.mt-1( :selectedTask="selectedTask" )
      
      task-comments-section( :selectedTask="selectedTask" @quotedComment="quoteComment" )
  
  task-create-comment-section( ref="createComment" :selectedTask="selectedTask" :quotedComment="quotedComment" @cancelQuote="quotedComment = null" )
  
  app-dialog( :header="$t('module.task.selectTask')" v-model='dialog.taskTreeDialog' @close="dialog.taskTreeDialog = false" )
    app-section.section-height
      div.ml-2
        div( v-for='task in taskList' style="width: 100%;" )
          task-tree-row-theme.pointer( :task='task' :style="task.uuid == replacingTaskUuid ? 'background: #AAA; width: 100% ' : 'background: white; width: 100%'" :open='taskTreeCache.isOpened(task)' :flat='!!taskTreeCache.sort_option.name' @expand='toggleOpen(task)' @click.native='setSelected(task)' )
    div.d-flex.justify-end.align-center.mt-2
      v-btn.ml-2.text-none.dialog-confirm-action( color="accent" small depressed light :disabled='selectedTask.parent == null' @click="taskOutParent" ) {{ $t('module.task.taskOutControl') }}
      v-btn.ml-2.text-none.dialog-confirm-action( color="accent" small depressed light :disabled='!replacingTaskUuid' @click="saveNewTaskParent" ) {{ $t('button.save') }}
  
  workspace-select-dialog( v-model='dialog.workspaceDialog' :workspace='selectedWorkspace' @confirm='setTaskWorkspace($event)' )

div( v-else )
  task-info-section.mt-1( ref="taskInfoSec" :selectedTask="selectedTask" @createTask="createTask" )

</template>

<script>
import { api } from "@/api"

import TaskSubTasksSection from '@/components/task/taskDetailsSections/TaskSubTasksSection.vue'
import TaskInfoSection from '@/components/task/taskDetailsSections/TaskInfoSection.vue'
import TaskAppointedSection from '@/components/task/taskDetailsSections/TaskAppointedSection.vue'
import TaskModelsSection from '@/components/task/taskDetailsSections/TaskModelsSection.vue'
import TaskCollisionsSection from '@/components/task/taskDetailsSections/TaskCollisionsSection.vue'
import TaskAttachmentsSection from '@/components/task/taskDetailsSections/TaskAttachmentsSection.vue'
import TaskCommentsSection from '@/components/task/taskDetailsSections/taskComments/TaskCommentsSection.vue'
import TaskSmetaSection from '@/components/task/taskDetailsSections/TaskSmetaSection.vue'
import TaskCreateCommentSection from '@/components/task/taskDetailsSections/taskComments/TaskCreateCommentSection.vue'
import TaskTreeRowTheme from '@/components/task/taskTree/components/TaskTreeRowTheme.vue'
import TaskStatus from '@/components/task/TaskStatus.vue'
import TaskPriority from '@/components/task/TaskPriority.vue'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import WorkspaceSelectDialog from './WorkspaceSelectDialog.vue'

export default {
  name: 'TaskDetailsSection',
  
  components: {
    TaskSubTasksSection,
    TaskInfoSection,
    TaskAppointedSection,
    TaskModelsSection,
    TaskCollisionsSection,
    TaskAttachmentsSection,
    TaskCommentsSection,
    TaskSmetaSection,
    TaskStatus,
    TaskPriority,
    TaskCreateCommentSection,
    TaskTreeRowTheme,
    WorkspaceSelectDialog,
  },

  data () {
    return {
      quotedComment: null,
      
      parentSelectedTask: null,
      replacingTaskUuid: null,
      
      dialog: {
        confirmDelTask: false,
        workspaceDialog: false,
        taskTreeDialog: false,
      },
    }
  },

  computed: {
    ...mapState('task', ['creatingTask', 'selectedTask', 'taskTreeCache']),
    ...mapState('project', ['project', 'projectSettings']),
    ...mapGetters('task', ['getCreatingTaskType', 'taskTreeIsOpened']),
    ...mapGetters('workspace', [ 'workspaces']),
    ...mapState('collision/search', ['selectedCollision']),

    getLastSortOrder() {
      let mainTasks = this.taskTreeCache.tree.tasks.filter(task => task.level === 0)
      return mainTasks[mainTasks.length - 1].sortOrder
    },

    taskList() {
      let filteredTasks = []
      let taskList = this.taskTreeCache.tree.tasks
      
      if (this.selectedTask.parent !== null) {
        this.taskTreeCache.tree.tasks.map(task => {
          if (task.uuid === this.selectedTask.parent) {
            this.parentSelectedTask = task
          }
        })

        if (this.parentSelectedTask?.children.length > 1) {
          taskList = this.taskTreeCache.tree.tasks
        }

        else {
          taskList = this.taskTreeCache.tree.tasks.filter(task => task.uuid != this.selectedTask.parent)
        }
      }

      this.filterTaskTree(this.selectedTask, filteredTasks);
      return taskList.filter(task => !filteredTasks.some(uuid => task.uuid === uuid))
    },

    selectedWorkspace: {
      get() {
        let projectSettings = JSON.parse(JSON.stringify(this.projectSettings))
        return projectSettings.workSpace || null
      },

      set(newValue) {
        let projectSettings = JSON.parse(JSON.stringify(this.projectSettings))
        projectSettings.workSpace = newValue

        this.setTasks([])

        this.$store.dispatch('project/updateSettings', projectSettings).then(() => {
          this.loadTasks()
        })
      }
    },
  },
  
  watch: {
    selectedCollision() {
      scrollToSelectedCollision(this)
    },
  },
  
  mounted() {
    if (this.selectedCollision) {
      scrollToSelectedCollision(this)
    }
  },

  methods: {
    ...mapActions('task', ['addTask', 'deleteTask', 'loadTasks', 'changeWorkspace', 'changeParent', 'taskOutOfParent']),
    ...mapMutations('task', ['setTasks', 'handleTreeClose', 'handleTreeOpen']),

    filterTaskTree(task, list) {
      list.push(task.uuid)
      
      if (task.children.length) {
        task.children.forEach(taskCh => this.filterTaskTree(taskCh, list))
      }
    },

    createTask() {
      this.creatingTask.project = this.project.uuid

      if (this.taskTreeCache.tree.tasks.length !== 0) {
        this.creatingTask.sortOrder = this.getLastSortOrder + 1
      }
      else {
        this.creatingTask.sortOrder = this.taskTreeCache.tree.tasks.length
      }

      this.addTask(this.creatingTask).then(bind => {
        if (bind && this.getCreatingTaskType.form && this.getCreatingTaskType.form.uuid) {
          api.forms.fill(this.getCreatingTaskType).then(data => {
            api.forms.dataList(data.bind).then(d => {
              if (d.length > 0) {
                this.$store.commit('task/setSelectedTaskType', { taskType: d[d.length-1] })
              }
            })
          })
        }
      })
    },

    setSelected(task) {
      let list = []
      this.replacingTaskUuid = task.uuid
      this.filterTaskTree(this.selectedTask, list)
    },

    toggleOpen(task) {
      if (this.taskTreeIsOpened(task)) {
        this.handleTreeClose(task)
      }
      else {
        this.handleTreeOpen(task)
      }
    },

    changeTaskType(event) {
      this.$refs.taskInfoSec.changeTaskType(event)
    },

    showDialogChangeWorkspace() {
      this.dialog.workspaceDialog = true
    },

    showDialogChangeParent() {
      this.dialog.taskTreeDialog = true
    },

    saveNewTaskParent() {
      this.changeParent({ uuid: this.selectedTask.uuid, value: this.replacingTaskUuid })
      this.replacingTaskUuid = null
      this.dialog.taskTreeDialog = false
    },

    taskOutParent() {
      this.taskOutOfParent({ uuid: this.selectedTask.uuid })
      this.dialog.taskTreeDialog = false
    },

    setTaskWorkspace(workspace) {
      this.changeWorkspace({ uuid: this.selectedTask.uuid, value: workspace })
    },

    showModel(event) {
      this.$refs.taskModel.showModel(event)
    },

    showAttachmentDialog(event) {
      this.$refs.createComment.showAttachmentDialog(event)
    },

    showResponderDialog(event) {
      this.$refs.taskAppointed.showResponderDialog(event)
    },

    quoteComment(comment) {
      this.quotedComment = comment
    }
  },
}

function scrollToSelectedCollision(e) {
  const taskPanel = document.getElementById('task-details-section-body')

  const collision = document.getElementById(`${e.selectedCollision.uuid}`)
  
  if (!collision){
    setTimeout(() => {
      scrollToSelectedCollision(e)
    }, 100);
  }
  else {
    const collisionsList = document.querySelectorAll('.t-attach')
    let selectedCollisionIndex = 0
    

    for (let i = 0; i < collisionsList.length; i++) {
      
      const colElement = collisionsList[i];
      
      if (colElement.id == e.selectedCollision.uuid.toString()) {
        selectedCollisionIndex = i + 1
      }
    }

    const collisionCardHeight = collision.offsetHeight
    
    const scrollValue = collisionCardHeight * selectedCollisionIndex
    
    taskPanel.scrollTo(0, scrollValue)
  }
}
</script>

<style>
.t-desc textarea,
.t-title textarea {
  line-height: 16px;
}

.attach-menu {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 1;
}

.relative {
  position: relative;
}

.interactive {
  pointer-events: all;
}

/* .active {
  border: 2px solid #3b93af;
} */

.lockAnnotation {
  position: absolute; 
  background: #959595; 
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px; 
  opacity: 0.5; 
  z-index: 1;
}

</style>

<style lang="scss" scoped>
.task-details-container{
  flex: 1;
  // background: #e5e5e5;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.t-row {
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}
.t-title {
  font: bold 14px/16px $roboto;
  color: black
}
.t-desc {
  font: normal 13px/16px $roboto;
  color: #757575;
}
.priority {
  width: 10px;
  height: 20px;
  position: relative;
}
.priority-icon{
  position: absolute;
  bottom: 6px;
}

.t-attach {
  background: #F0F0F0;
  border-radius: 5px;
  width: 100%;
}
.t-attach-icon {
  width: 24px;
  height: 24px;
}
.t-attach-status {
  font: italic 10px/24px $roboto;
  text-align: right;
  color: #27AE60;
}

.t-scroll-body {
  overflow-y: auto;
  height: 100%;
  // height: 100%;
  overflow-x: hidden;
  scrollbar-width: none !important;
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-primary-lighten3);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-surface-lighten3);
}

// .comments-container {
//   height: 2px;
//   background-color: black;
// }

.collision-text {
  word-break: break-all;
}

.select-sec:hover{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
}
.section-style{
  background-color: #E5E5E5;
  padding:0; 
  border-radius: 3px 3px 0px 0px !important;
  height: 100%; 
  overflow-y: auto;
  overflow-x: hidden;
}
.pointer{
  color: #4D4D4D;
  
}
.section-height{
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>