<template lang="pug">
  div
    app-panel.pt-1.px-1.py-2( ident='forms' padding="4px" )
      task-header.mb-2( :title="$t('form.form')" headerItemsCenter mainTitle )
        template( v-slot:headerAfter )
          app-icon-button( icon="mdi-close" @click="handleClose" test-id="close-app-panel" )

      app-section.base-scroll-body(style="height: calc(100% - 32px); width:  100%; border-radius: 3px; padding: 10px;  overflow-x: hidden;")
        div(v-for="type in formTypes")
          app-header-text {{ type.title }}
          app-form-item.my-2( v-for="form in formsByType(type.value) " :key="form.uuid" :item="form" itemKey="name" prevent test-id="forms-item" @menu="onMenu" @select="onSelect" )
          span(v-if="type.title == 'Задачи'")

        app-menu( ref="formsToolbar" :menu="formsMenu" )
            
        app-dialog-confirm(v-if="form" v-model="dialog.delForm" ref="delForm" @confirm="deleteForm(form.uuid)")
          app-text {{$t('form.deleteForm')}} "{{ form.name }}"?

        div.mt-2( style="width: fit-content;" )
          app-icon-button( icon="mdi-plus" buttonSize="small" buttonColor="var(--v-accent-base)" iconColor="white"  @click="addForm" test-id="forms-plus-button" )

    app-panel.ml-1.pt-1.px-1.py-2(v-if="dialog.saveForm" width="365" padding="4px")

      task-header.mb-2( :title="$t('form.forms')" headerItemsCenter bigTitle )
        template( v-slot:headerBefore )
          app-icon-button.mr-2( icon="mdi-arrow-left" @click="dialog.saveForm = false" )

        template( v-slot:headerAfter )
          app-icon-button.mr-1( v-if="!newForm" icon="$type-viewer-icon" buttonSize="small" @click="fillForm" test-id="forms-fill-button" )
          //- Срабатывает @change у field.vue
          app-icon-button( icon="mdi-content-save-outline" test-id="forms-fill-button" )
   
      v-form.base-scroll-body( ref="saveForm" v-model="valid" @submit.prevent="submit" style="height: calc(100% - 30px);" )

        app-section.mb-1
          app-text-field.my-2(v-model="form.name" :label="$t('form.formName')" hide-details @change="changeForm(form)" test-id="forms-form-title")
          app-select.my-2(v-if="isFormTypesLoaded" v-model="form.formType" :items="formTypes" item-text="title" :label="$t('form.visibility')" :disabled="!valid" @change="saveForm(form)")

        app-section.mb-2.surface.lighten-5.px-0(v-if="!newForm")
          .app-text_color-light.mx-2.mb-2 {{$t('form.formFields.default')}}
          draggable(v-model="form.fields" @end="saveForm(form)")
            field(v-for="(f, index) in form.fields" :field="f" :key="f.uuid" :order="index" @showDict="showDict")

          //-app-button.mt-3(@click="saveForm(form)" :disabled="!valid") {{ $t('button.save') }}
          div.mt-2.mx-1.mb-5( style="width: fit-content;" )
            app-icon-button( icon="mdi-plus" buttonSize="small" buttonColor="var(--v-accent-base)" iconColor="white" :disabled="hasNewField"  @click="addField" test-id="forms-add-field" )

      fill-form( v-model="dialog.fillForm" :formId="form.uuid")

    dict-dialog( v-model="dialog.dict" @edited="onEditingDict" :dictionaryId.sync="currentDictId")

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Field from "./FieldEdit"
import FillForm from './Form'
import draggable from 'vuedraggable'
import DictDialog from "../dictionary/Dicts"
import appFormItem from "./FormItem"
import appDialogWindow from "./DialogWindow"
import appHeaderText from "./HeaderText"
import TaskHeader from '@/components/task/TaskHeader'
import ToolbarMenu from '@/components/project/panel/left/floors/ToolbarMenu'
import { $_validate_rules } from '@/common/ValidateRules'

export default {
  mixins: [$_validate_rules],
  components: {
    Field,
    DictDialog,
    FillForm,
    draggable,
    appFormItem,
    appDialogWindow,
    appHeaderText,
    TaskHeader,
    ToolbarMenu
  },
  data () {
    const defaultForm = Object.freeze({
          uuid: null,
          name: null,
          formType: null,
          bind: null, 
          fields: []
        })

    const defaultField = Object.freeze({
          uuid: null,
          name: null,
          alias: null,
          type: null,
          value: null,
          defaultValue: null,
          extraCheck: false,
          sortOrder: 0,
        })

    return {
      currentDictId: null,
      currentField: null,
      valid: false,
      dialog: {
       dict: false,
       saveForm: false,
       delForm: false,
       fillForm: false
      },
      defaultForm,
      defaultField,
    }
  },

  mounted () {
    this.$store.dispatch('forms/INIT', { bind: this.projectUuid })
  },

  computed: {
    ...mapGetters('forms', ['form', 'forms', 'isFormsLoaded', 'formTypes', 'isFormTypesLoaded', 'fieldTypes', 'isFieldTypesLoaded']),
    ...mapGetters('project', ['projectUuid']),

    formsMenu () {
      return [
        // { title:'Редактировать', icon:'mdi-pencil-outline', action: () => this.editForm()},
        // { title:'Удалить', icon:'mdi-delete-outline', action: () => this.delForm()},
        { title:this.$t('floor.floorUnit.edit'), action: this.editForm},
        { title:this.$t('floor.floorUnit.delete'), action: this.delForm},
      ]
    },

    hasNewField () {
      return this.form.fields.find(item => item.uuid === null) == undefined ? false : true
    },

    newForm () {
      return this.form.uuid === null ? true : false
    }
  },

  methods: {
    ...mapActions('forms', ['saveForm', 'deleteForm', 'gotoForm', 'saveField']),
    ...mapMutations('forms', ['setForm']),
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('project', ['postPanelsStatus']),

    formsByType (type) {
      return this.forms.filter(f => f.formType != null && f.formType.value == type)
    },


    fillForm() {
      this.dialog.fillForm = true
    },

    changeForm(form) {
      if(form?.name?.trim()) {
        this.saveForm(form)
      }
      else{
        form.name=this.$t('form.form')
        this.saveForm(form)
      }
    },

// Добавление
    addForm () {
      let newForm = Object.assign({}, this.defaultForm)
      if(this.formTypes[0] != null) {
        newForm.formType = this.formTypes[0]
        newForm.bind = this.projectUuid
        this.setForm(newForm)
        this.dialog.saveForm = true
      }
    },

    editForm () {
      this.dialog.saveForm = true
    },

    delForm () {
      this.dialog.saveForm = false
      this.$refs.delForm.show()
    },

    onMenu (item, event) {
      this.setForm(item)
      this.$refs.formsToolbar.show(event)
    },

    onSelect (item) {
      this.setForm(item)
      this.dialog.saveForm = true
    },

    submit () {
    },

    addField () {
      let newField = Object.assign({}, this.defaultField)
      newField.form = { "uuid" : this.form.uuid }
      if( this.form.fields.length>0 )
         newField.sortOrder = this.form.fields[this.form.fields.length-1].sortOrder + 1
      this.saveField(newField)
    },

    addField_v1 () {
      let newField = Object.assign({}, this.defaultField)
      if( this.form.fields.length>0 )
         newField.sortOrder = this.form.fields[this.form.fields.length-1].sortOrder + 1
      this.form.fields.push(newField)
    },

    showDict (field) {
      this.currentField = field
      this.currentDictId = field.value
      this.dialog.dict = true
    },

    onEditingDict () {
      this.currentField.value = this.currentDictId
      this.dialog.dict = false
    }, 

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    }

  }
}
</script>

<style lang="scss" scoped>
  .t-scroll-body {
    overflow-y: auto;
    height: calc(100% - 35px);
    overflow-x: hidden;
    scrollbar-width: thin;
  }
  .t-scroll-body::-webkit-scrollbar {
      width: 5px;
      background-color: var(--v-primary-lighten3);
  }
  .t-scroll-body::-webkit-scrollbar-thumb  {
    background-color: var(--v-surface-lighten3);
  }

  .app-text_color-light {
    font: normal 12px/16px $roboto;
    color: var(--v-surface-lighten3) !important;
  }
</style>