import { render, staticRenderFns } from "./NotificationFilterMenu.vue?vue&type=template&id=ef6f3d0e&scoped=true&lang=pug&"
import script from "./NotificationFilterMenu.vue?vue&type=script&lang=js&"
export * from "./NotificationFilterMenu.vue?vue&type=script&lang=js&"
import style0 from "./NotificationFilterMenu.vue?vue&type=style&index=0&id=ef6f3d0e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef6f3d0e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCheckbox,VListItemTitle,VMenu})
