<template lang="pug">
div
  .justify-end.font-text.pointer( v-if="isFile" @click="attachmentDownload()" ) {{ attach.name || $t('module.task.noName') }}

  v-hover.attachment-container( v-else #default="{ hover }" )
    .t-attach.app-cursor--pointer.relative( 
      :class="{ 'elevation-4': hover, 'active' : isCurrentAttach}"
      @click="$emit('location')" 
    )

      v-img.attachment-img( v-if="isAttachHaveScreenShoot(attach)" :src="filePath(attach)" :width="editable ? 247 : 210" :height="126" )

      v-img.attachment-img( v-else-if="isAttachHaveImage(attach)" :src="filePath(attach)" :width="editable ? 247 : 210" :height="126" )

      .scroll-hidden(v-else-if="isAttachHaveDrawing(attach)" ref='drawingAttachmentWindow')
        img.attachment-img(ref='drawingAttachment' :width="editable ? 247 : 210" :height="126")

      div.attachment-menu-body( :class="{ 'blackout-img': hover}" )
        .d-flex.img-buttons-flex( v-if="$app.device.isPortable ? true : hover" )

          v-tooltip( v-if="showLocationBtn" bottom )
            template( v-slot:activator="{ on, attrs }" )
              task-button-action.img-button.ma-1( v-on="on" icon="project/tools/my_location.svg" @click.stop="$emit('location')")
            span {{ $t('module.task.goCoords') }} 
            span( v-if="is3DAttach" ) (3D)

          v-tooltip( bottom )
            template( v-slot:activator="{ on, attrs }" )
                task-button-action.img-button.ma-1( v-on="on" icon="project/tools/cloud_download.svg" @click.stop="attachmentDownload()")
            span {{ $t('button.save') }}

          v-tooltip( bottom )
            template( v-slot:activator="{ on, attrs }" )
              task-button-action.img-button.ma-1( v-on="on" icon="project/tools/eye.svg" @click.stop="showImage()")
            span {{ $t('button.open') }}

  image-dialog( v-model="showFullImage"  withScrollPanel width="auto")
    v-img(:src="imagePath")
</template>

<script>
import { SourcePath } from "@app/SourcePath";
import ImageDialog from "@/components/task/taskDetailsSections/ImageDialog.vue";
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'
import { useDrawingsStore } from "@/pinia";

export default {
  name: "TaskSimpleAttachment",

  components: {
    ImageDialog,
    TaskButtonAction
  },

  props: {
    attach: Object,

    editable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showFullImage: false,
    };
  },

  computed: {
    store: () => useDrawingsStore(), 

    showLocationBtn() {
      if (
        (this.attach.sourceType.title === "VIEW" ||
          this.attach.sourceType.title === "DRAWINGCOMMENT") &&
        !this.isTaskManager
      )
        return true;
      else return false;
    },

    is3DAttach() {
      if (this.attach.sourceType.title === "VIEW")
        return this.attach.sourceData?.camera;
      else return false;
    },

    isCurrentAttach() {
      return this.currentAttach && this.attach.uuid === this.currentAttach.uuid;
    },

    isFile() {
      return (
        this.attach.sourceType.title === "FILE" &&
        !this.attach.contentType.startsWith("image")
      );
    },

    isTaskManager() {
      return window.location.href.includes("task/manager");
    },

    imagePath() {
      return SourcePath.attachment(this.attach.file);
    },
  },

  mounted() {
    if (this.attach.sourceType.title === "DRAWINGCOMMENT") {
      let drawingAttachmentWindow = this.$refs.drawingAttachmentWindow;
      let drawingAttachment = this.$refs.drawingAttachment;

      this.store.getDrawingPagePreview(this.attach.cameraParse.page).then((url) => {
        url = URL.createObjectURL(url);
        drawingAttachment.src = url;
        drawingAttachment.onload = () => {
          drawingAttachment.setAttribute(
            "style",
            `transform-origin: top left; transform: scale(${
              this.attach.cameraParse.scale / 100
            });`
          );
          drawingAttachment.style.transformOrigin = "top left";
          drawingAttachment.style.transform = `scale(${
            this.attach.cameraParse.scale / 100
          })`;

          let drawingPreviewRect = drawingAttachment.getBoundingClientRect();
          drawingAttachmentWindow.scrollTo(
            (this.attach.cameraParse.x / 100) * drawingPreviewRect.width -
              drawingPreviewRect.width / 4,
            (this.attach.cameraParse.y / 100) * drawingPreviewRect.height -
              drawingPreviewRect.height / 4
          );
        };
      });
    }
  },

  methods: {

    filePath(attachment) {
      return SourcePath.attachment(attachment.file);
    },

    showFile(file, contentType) {
      if (contentType === "application/pdf") {
        window.open(SourcePath.attachment(file), "_blank");
      } 
      else {
        if (contentType.startsWith("image")) {
          let w = window.open();
          let img = new Image();
          img.src = SourcePath.attachment(file);
          img.setAttribute(
            "style",
            "text-align: center;position: absolute;inset: 0;margin: auto;"
          );
          w.document.body.appendChild(img);
        } else {
          window.open(SourcePath.attachment(file), "_blank");
        }
      }
    },

    attachmentDownload() {
      if (this.attach.sourceType.title === "DRAWINGCOMMENT")
        this.downloadDrawing(this.attach);
      else {
        this.showFile(this.attach.file, this.attach.contentType);
      }
    },

    downloadDrawing(attachment) {
      this.store.getDrawingPage(attachment.cameraParse.page).then((svg) => {
        svg = new DOMParser().parseFromString(svg, "application/xml");

        let xmlSvg = new XMLSerializer().serializeToString(svg);
        let svgData = xmlSvg;
        let svgBlob = new Blob([svgData], {
          type: "image/svg+xml;charset=utf-8",
        });
        let svgUrl = URL.createObjectURL(svgBlob);

        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = attachment.file;
        downloadLink.click();
      });
    },

    isAttachHaveImage(attach) {
      if (attach.contentType.startsWith("image")) {
        return true;
      }
      return false;
    },

    isAttachHaveScreenShoot(attach) {
      if (attach.sourceType.title == "VIEW") {
        return true;
      }
      return false;
    },

    isAttachHaveDrawing(attach) {
      if (attach.sourceType.title == "DRAWINGCOMMENT") {
        return true;
      }
      return false;
    },

    showImage() {
      this.showFullImage = !this.showFullImage;
    },
  },
};
</script>

<style scoped>
.attachment-img {
  background-color: #262626;
  border-radius: 0px;
}

.attachment-img-box {
  padding: 0px;
}

.relative {
  background-color: #e5e5e5;
  max-height: 48;
}

.icon-box-doc {
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
}

.icon-box-img {
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.gray-bg {
  background-color: white;
}

.attach-meta {
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: space-between;
}

.file-text {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.font-text {
  font-family: "Roboto";
  line-height: 16px;
  color: #3b93af;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #3b93af;
  /* width: 215px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.scroll-hidden {
  overflow: hidden;
}
.img-button {
  background-color: transparent;
}
</style>