<template lang="pug">
  //- Активность
  task-section-expansion( :title="`${$t('module.task.activity')} (${actionsList.length})`" show-toggle style="border-radius: 0px;"
    :headerColorDark="true")
    .overflow
      .my-2.pa-1( v-for="(c, index) in actionsList" :key="c.uuid" )
        .comment(v-if="c.author")
          v-row.pa-2
            v-col.pa-0(cols="auto")
              app-avatar.ml-2.mt-1.d-inline-block( :text="avatarText(c.author)" :colorObj="getBgColor(c.author.uuid)" :url="avatarUrl(c.author)" :disabled="c.author.archive" )
            v-col.pa-0
              app-text.ml-2.d-inline-block.font-weight-bold.text-left {{ c.author.name }}
            v-col.pa-0(cols="auto")
              app-text.ml-2.d-inline-block {{ c.createDate | prettyDate }}
          task-comment-markdown-textarea(v-model="c.text" background-color="#F0F0F0" color="#6B6B6B" 
            auto-grow hide-details light solo flat dense :buttons="c.author.uuid === user.uuid" :onlyViewer="c.author.uuid !== user.uuid"
            @click="changeComment(c)"
          )
            template(v-slot:buttons)
              v-btn.text-capitalize(color="#C4C4C4" @click="delComment({taskUid: selectedTask.uuid, uuid: c.uuid})" x-small text) {{ $t('button.delete') }}
        
        .attachment(v-if="c.creator")
          v-row.pa-2
            v-col.pa-0(cols="auto")
              app-avatar.ml-2.mt-1.d-inline-block( :text="avatarText(c.creator)" :url="avatarUrl(c.creator)" :disabled="c.creator.archive" )
            v-col.pa-0
              app-text.ml-2.d-inline-block.font-weight-bold.text-left {{ c.creator.name }}  
            v-col.pa-0(cols="auto")
              app-text.ml-2.d-inline-block {{ c.createDate | prettyDate }}
          app-text.ml-2.d-inline-block {{ $t('module.task.attachedAttachment') }}
          .relative
            //- .lockAnnotation(v-if="!c.annotation && c.camera.indexOf('worldPos')!=-1")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-hover( #default="{ hover }" )
                  //- .t-attach.app-cursor--pointer.mt-2.pa-1( :class="{ 'elevation-4': hover }" @click="showFile(c.file)" @contextmenu.stop="showAttachmentContextMenu($event, c)" )
                  .t-attach.app-cursor--pointer.mt-2.pa-1( :class="{ 'elevation-4': hover }" @click="(c.sourceData) ? showCamera(c) : showFile(c.file)" v-on="on")
                    .row.mb-0
                      .col-auto
                        v-img( v-if="c.contentType.startsWith('image')" :src="filePath(c)" max-height=60 max-width=60)
                        v-icon(v-else color="#3b93af") attach_file
                      .col
                        app-text.mx-2 {{ c.name || $t('module.task.noName') }}
                    div(v-if="c.cameraParse && c.cameraParse.comment")
                      v-divider(light)
                      app-text.ml-2.d-inline-block {{ $t('module.task.comment') }} : {{ c.cameraParse.comment }}
              span {{ $t('module.task.goCoords') }}

        v-divider.ma-5(v-if="index != actionsList.length - 1" light)
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SourcePath } from '@app/SourcePath'
import uniqolor from 'uniqolor';
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { TaskScreenshot } from '../taskScreenshot';
import TaskCommentMarkdownTextarea from '@/components/task/taskDetailsSections/taskComments/TaskCommentMarkdownTextarea.vue';
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';

export default {
  name: 'TaskActivitySection',
  components: {
    TaskCommentMarkdownTextarea,
    TaskSectionExpansion
  },

  filters: {
    prettyDate: (fulldate) => {
      let [date, time] = fulldate.split(' ')
      let [hour, minutes] = time.split(':')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year} ${hour}:${minutes}`
    },
  },

  props:{
    selectedTask: null,
  },

  computed:{
    ...mapState('authUser', ['user']),
    allComments () {
      return this.selectedTask.comment || []
    },
    hasAttachments () {
      return this.selectedTask.attachments && this.selectedTask.attachments.length
    },
    attachmentsParseCam() {
      return this.hasAttachments ? this.selectedTask.attachments.map(item => {
        if (item.sourceData) item.cameraParse = item.sourceData
        return item
      }).sort((a, b) => a.createDate.localeCompare(b.createDate)) : []
    },
    actionsList () {
      let comments = this.allComments
      let attachments = this.attachmentsParseCam || []
      let list = [ ...attachments, ...comments]
      return list.sort((a, b) => a.createDate.localeCompare(b.createDate))
    },
  },
  methods:{
    ...mapActions('task', ['editComment', 'delComment']),
    changeComment (comment) {
      if (comment.author.uuid === this.user.uuid)
        this.editComment({ uuid: comment.uuid, text: comment.text })
    },
    avatarText (profile) {
      return profile.archive ? profile.name + this.$t('section.structure.model.memberDeleted') : profile.name
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    avatarUrl (profile) {
      return profile.avatar ? SourcePath.avatar(profile.avatar) : ''
    },

    showCamera(attach, shownLabel) {
      if (attach.annotation && attach.annotation !== undefined) TaskBimAnnotations.setSelectedTaskBimAnnotationByAttachment(attach, shownLabel)
      else TaskBimAnnotations.noSelectedTaskBimAnnotation()

      if (attach.sourceData && attach.sourceData !== undefined) {
        TaskScreenshot.showTaskScreenshot(attach)
      }
      this.currentAttach = attach
    },

    showFile (file) {
      window.open(SourcePath.attachment(file), '_blank')
    },

    filePath (attachment) {
       return SourcePath.attachment(attachment.file)
    },
  }
}
</script>
