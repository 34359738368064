<template lang="pug">
  app-dialog( v-model="visible" :header="$t('module.task.choose')" :confirm-text="$t('button.choose')" :confirm-disabled="!tasksSetting.selected" @confirm="attachAttrCollisionsToTask" )
    app-section.base-scroll-body
      div( v-if="tasksSetting.loading" )
        v-skeleton-loader( type="list-item-two-line" light )
      .select-task( v-else )
        project-task-card.mb-2( :key="t.uuid" v-for="t in tasks" :class="tasksSetting.selected && t.uuid == tasksSetting.selected.uuid ? 'selected' : 'simple'" :task="t" @click.native="toggleTask(t)" )
</template>

<script>
import { api } from '@/api'
import { mapState, mapMutations } from 'vuex'
import ProjectTaskCard from '@/components/project/common/TaskCard'
import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'

export default {
  components: {
    ProjectTaskCard,
  },

  data() {
    return {
      visible: false,
      tasksSetting: {
        selected: null,
        loading: false,
        single: false,
      },
      selectedRow: null,
    }
  },

  computed: {
    ...mapState('task', ['tasks']),
    ...mapStores(useAttributeCheckingTableStore),
  },

  methods: {
    ...mapMutations('task', ['setSelectedTask']),
    ...mapMutations('project', ['setTopUnit']),

    show({ single, item = null }) {
      this.tasksSetting.loading = true
      this.tasksSetting.single = single
      this.selectedRow = item
      this.visible = true
      this.$store.dispatch('task/loadTasks').then(() => {
        this.tasksSetting.loading = false
      })
    },

    toggleTask(task) {
      let { selected } = this.tasksSetting
      this.tasksSetting.selected = selected && task.uuid == selected.uuid ? null : task
    },

    attachAttrCollisionsToTask() {
      let type = this.selectedRow.level == 0 ? 'rule' : 'attribute'
      // let newlist = this.tasksSetting.single ?  : thisselectedCollisionsUuid.
      let taskAttachments = this.tasks.find((task) => task.uuid === this.tasksSetting.selected.uuid)[type].map((el) => el.uuid)
      let uuids = [this.selectedRow.uuid, ...taskAttachments]

      api.tasks.patchTask(this.tasksSetting.selected.uuid, uuids, type).then(async (task) => {
        api.collisions.attrUpdateStatus(uuids, 1).then(() => {
          this.openSelectedTaskPanel(task)
          this.attributeTableStore.changeItemsSource(true, task.uuid)
        })
      })
    },

    openSelectedTaskPanel(task) {
      this.$store.dispatch('task/loadTasks').then(() => {
        let selectedTask = this.tasks.find((loadedTask) => loadedTask.uuid === task.uuid)
        this.setSelectedTask(selectedTask)
        // this.loadTaskCollisions(task) TODO: dodelay
        this.setTopUnit('task')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.selected {
  border: 4px solid #3b93af;
}
.simple {
  border: 1px solid #c4c4c4;
}

.select-task {
  height: 400px;
}

</style>
