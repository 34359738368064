<template lang="pug">
.head-sizable-block( @mousedown="verticalSizable" )
  .horizontal-icon-pos
    app-icon-action.icon( icon="$horizontal-sizable-icon" size="60" color="accent" style="cursor: default !important;")
  
</template>

<script>
let last
  export default {
    props: {
      height: Number,
    },

    mounted() {
      document.onmouseup = this.verticalSizableUp
      this.resizeCanvas()
    },

    beforeDestroy() {
      document.onmouseup = null
      document.onmousemove = null
    },

    computed: {
      tableHeight: {
        get() {
          return this.height
        },
        set(value) {
          this.$emit('update:height', value)
        },
      },
    },

    methods: {
      verticalSizableMove(ev) {
        let height = this.tableHeight + (last - ev.clientY)
        last = ev.clientY
        if (height >= 0 && height <= 600){
          this.tableHeight = height
          this.resizeCanvas(height)
        } 
      },

      verticalSizable(ev) {
        last = ev.clientY
        document.addEventListener('mousemove', this.verticalSizableMove)
        // document.onmousemove = this.verticalSizableMove
      },

      verticalSizableUp() {
        this.$emit('onSlide')
        document.removeEventListener('mousemove', this.verticalSizableMove)
        // document.onmousemove = null
      },

      resizeCanvas(height) {
        if (height <= 0){
          height = this.tableHeight
          document.getElementById('myCanvas').height = window.innerHeight - height
        } 
        document.getElementById('measurementScrim').style.paddingBottom = height ? height + 'px' : 0
        // document.getElementById("myAxisCanvas").style.paddingBottom = height ? height + 'px' : 0
      },
    },
  }
</script>

<style scoped>
  .horizontal-icon-pos {
    display: inline-block;
    position: relative;
    left: 50%;
    top: -15px;
    rotate: 90deg;

  }

  .head-sizable-block {
    width: 100%;
    height: 5px;
    background-color: var(--v-surface-base);
    cursor: row-resize;
  }
  ::v-deep.icon span{ 
    cursor: row-resize;   
  }
</style>
