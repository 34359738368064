<template lang="pug">
  v-form.add-form-token
    app-section.m-2
        //app-text-field( v-model.trim="token.expiredDate" :label="$t('project.form.name')" )
        v-menu.py-0.my-0(
            ref="expired"  
            :close-on-content-click="false" :return-value.sync="token.expiredDate"
            transition="scale-transition" offset-y min-width="auto"
            )
            template(v-slot:activator="{ on }")
                v-text-field.py-0(
                    v-model="token.expiredDate" :label="$t('token.form.dateTo')" 
                    prepend-icon="mdi-calendar" readonly
                    v-on="on" light clearable
                )
            v-date-picker( v-model="token.expiredDate" no-title scrollable @input="$refs.expired.save(token.expiredDate)" :min="minDate")
        
        //AppSelect.mb-7(v-model="token.level" :label="$t('token.form.scope')" :items="levels" :item-text="$t(title)" return-object)
        AppSelect.mb-7(v-model="token.level" :label="$t('token.form.scope')" :items="levels" item-text="title" locale return-object)

        app-textarea( v-model.trim="token.discribe" :label="$t('token.form.discribe')" height=130 outlined)
    .d-flex.mt-4
      v-spacer
      .align-content-end
        v-btn.text-none.add-token__footer-button( color="accent" @click="saveToken" :disabled="validation") {{ $t('button.save') }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { TokenDto } from '@models/Token'
import { AlertService } from '@app/AlertService'

//import TokenAddForm from '@forms/token/TokenAddForm'
//import { SourcePath } from '@app/SourcePath'
//import { ProjectShortcut } from '@models/Project'
//import { mapActions } from 'vuex'
//import { App } from '@app/App'

export default {

  model: {
    prop: 'token',
    event: 'change'
  },

  props: {
    token: {
      type: TokenDto,
      default: null
    },
  },

  data () {
    return {
        dialog: {
            expiredCalendar: false,
        },
        minDate: new Date(Date.now() + (3600 * 1000 * 24)).toISOString().slice(0,10),
      /*tabs: null,
      preview: '',
      ,*/
    }
  },

  mounted () {
    this.$store.dispatch('tokens/INIT')
  },

  computed: {
      ...mapGetters('tokens', [/*'tokens',*/'scopeList']),

      validation() {
        if(this.token.level && this.token.expiredDate) {
          return false 
        } else {
          return true
        }
      },

      levels () {
        return this.scopeList.map(scope => {
            scope.title = this.$t(scope.title)
            return scope
        })
      },
    /*tokenlist () {
      return this.project.token?.map(item => item) || []
    },*/
  },

  methods: {
    ...mapActions('tokens', ['createToken', 'deleteToken']),

    saveToken () {
     // this.token = JSON.parse(JSON.stringify(this.defaultForm))
      this.createToken(this.token).then(() => {})
      this.$emit('change')
      AlertService.success({info:this.$t('token.action.added')})
    },

  }

}
</script>