<template lang='pug'>
div.flex
  app-textarea(v-model='$value' v-bind='$attrs' v-on='$listeners')
  
  div.d-flex.align-center.mt-1
    v-btn( color="var(--v-primary-lighten1)" icon x-small @click='showHelp = true' )
      v-icon help
    slot
  
  app-dialog( v-model="showHelp" :header="$t('app.markdownTextarea.help')" width='600' )
    app-section.base-scroll-body( style='height: 75vh;' )
      div.primary--text
        | {{ $t('app.markdownTextarea.helperLink') }}  
        a(href="https://markdown-it.github.io/" target="_blank") markdown-it
        | - {{ $t('app.markdownTextarea.helperLink1') }}
        div.render-help(v-html="helperHtml")

</template>

<script>
import MarkdownIt from 'markdown-it/lib'
const md = new MarkdownIt({ breaks: true })

export default {
  name: "MarkdownTextarea",
  
  props: {
    value: String,
  },

  data() {
    return {
      showHelp: false,
    }
  },

  computed: {
    renderHtml() {
      const primary = this.text || ''
      return md.render(primary)
    },

    $value: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    },

    helperHtml () {
      const markdownExample = `# ${this.$t('app.markdownTextarea.helperH1')}`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `# ${this.$t('app.markdownTextarea.helperH1')}`+
      `\n\`\`\`\n\n---\n\n\n`+
      `## ${this.$t('app.markdownTextarea.helperH2')}`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `## ${this.$t('app.markdownTextarea.helperH2')}`+
      `\n\`\`\`\n\n---\n\n`+
      `**${this.$t('app.markdownTextarea.helperBold')}**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `**${this.$t('app.markdownTextarea.helperBold')}**`+
      `\n\`\`\`\n\n---\n\n`+
      `*${this.$t('app.markdownTextarea.helperItalic')}*`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `*${this.$t('app.markdownTextarea.helperItalic')}*`+
      `\n\`\`\`\n\n---\n\n`+
      `${this.$t('app.markdownTextarea.helperList')}\n`+
      `${this.$t('app.markdownTextarea.helperItem1')}\n${this.$t('app.markdownTextarea.helperItem2')}\n${this.$t('app.markdownTextarea.helperItem3')}`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `${this.$t('app.markdownTextarea.helperItem1')}\n${this.$t('app.markdownTextarea.helperItem2')}\n${this.$t('app.markdownTextarea.helperItem3')}`+
      `\n\`\`\`\n\n---\n\n`+
      `### [${this.$t('app.markdownTextarea.helperNameLink')}](https://www.google.com)\n\n`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `### [${this.$t('app.markdownTextarea.helperNameLink')}](https://www.google.com)`+
      `\n\`\`\`\n---\n\n\n`+
      `${this.$t('app.markdownTextarea.helperImage')}\n![Minion](https://octodex.github.com/images/minion.png)\n\n`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `![Minion](https://octodex.github.com/images/minion.png)`+
      `\n\`\`\``
      return md.render(markdownExample)
    },
  },

}
</script>

<style lang='scss' scoped>
  .render-help {
    width: 100%;
    overflow: hidden;
  }

  ::v-deep .render-help img {
    width: 100px;
  }
</style>