<template lang="pug">
  div
    .d-flex(v-for="(v, index) in items" :key="index")
      app-text {{v.device.title}} / {{v.deviceParam.title}} : {{getVal(v.device.uuid, v.deviceParam.uuid)}}
      v-btn.ml-auto.mt-n1(v-if="v.deviceParam.writable && v.deviceParam.dataUnit.name=='BOOL'" icon light small @click="check(v.device.uuid, v.deviceParam.uuid)") 
        v-icon {{ checkboxIcon(getVal(v.device.uuid, v.deviceParam.uuid)) }}

    v-snackbar(v-model="setSnack" color="success" :timeout="3000" vertical top) OK
      v-btn(light text @click="setSnack = false") {{ $t('button.close')}}

</template>

<script>
import { iotService } from '@/_services'
//      v-checkbox.ml-auto( :value="getVal(v.device.uuid, v.deviceParam.uuid)" light dense hide-details )

export default {
  name: 'DeviceWidget',
  props: {
    widget: null,
  },
  data () {
    return {
      setSnack: false,
      start: true,
      values: [],
    }
  },
  methods: {
    getVal(deviceId, deviceParamId) {
      let val = this.values.find(el => el!=null && el.deviceUuid==deviceId && el.deviceParam.uuid==deviceParamId)
      return (val !== undefined) ?  val.data : ""
    },
    getData() {
      if(this.start)
        iotService.getWidgetData(this.widget.uuid).then(data => {
          this.values = data
        })
    },
    loadData() {
      return this.start ? setTimeout(() => {
        this.getData()
        this.loadData()
      }, 30000) : null
    },
    check (dev, param) {
      let val = this.values.find(el => el!=null && el.deviceUuid==dev && el.deviceParam.uuid==param)
      if (val !== undefined) {
        val.data = val.data == "on" ? "off" : "on"
        iotService.setDeviceData(dev, param, val.data)
        .then(() => { this.setSnack=true })
      }
    },
    checkboxIcon (val) {
      if (val=="on") return 'toggle_on'
      return 'toggle_off'
    },
  },
  computed: {
    items() {
      let sorted = []
      Object.assign(sorted, this.widget.items)
      return sorted.sort((a, b) =>  a.device.title.localeCompare(b.device.title) || a.deviceParam.title.localeCompare(b.deviceParam.title))
    },
  },
  mounted() {
    this.getData()
    this.loadData()
  },
  destroyed() {
    this.start = false
  }
}
</script>

<style lang="scss" scoped>

</style>
