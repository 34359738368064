var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"player"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.startPlay(Date.parse(_vm.playerDate))}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.playing ? '/img/project/tools/player_pause.svg' : '/img/project/tools/play_button.svg'}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.playing ? _vm.$t("module.task.gantt.stopPlaying") : _vm.$t("module.task.gantt.startPlaying")))])]),_c('v-menu',{attrs:{"offsetX":"","offsetY":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.calendarDate))])]}}])},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":_vm.pickerMinDate,"max":_vm.pickerMaxDate},on:{"input":_vm.changeDate},model:{value:(_vm.playerDate),callback:function ($$v) {_vm.playerDate=$$v},expression:"playerDate"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 reverse",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.goToDate(-_vm.MILLISECONDS_IN_DAY * 7)}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":"/img/project/tools/player_skip_double.svg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('module.task.gantt.weekAgo')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"reverse mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.goToDate(-_vm.MILLISECONDS_IN_DAY)}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":"/img/project/tools/player_skip.svg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('module.task.gantt.dayAgo')))])]),_c('v-slider',{attrs:{"max":_vm.lastTaskDate + _vm.MILLISECONDS_IN_DAY,"min":_vm.firstTaskDate - _vm.MILLISECONDS_IN_DAY,"step":_vm.MILLISECONDS_IN_DAY,"thumb-size":"100","height":"20","color":"#757575","track-color":"#757575","ticks":"","dense":"","hide-details":""},on:{"input":_vm.changeDate},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.today)+" ")]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.goToDate(_vm.MILLISECONDS_IN_DAY)}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":"/img/project/tools/player_skip.svg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('module.task.gantt.dayAhead')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.goToDate(_vm.MILLISECONDS_IN_DAY * 7)}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":"/img/project/tools/player_skip_double.svg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('module.task.gantt.weekAhead')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }