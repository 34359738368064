<template lang="pug">
  app-panel.pt-1.format(ident="workjournal" left )
    task-header.mb-2(:title="$t('section.journal.title')" headerItemsCenter mainTitle )
      template( v-slot:menu )
        app-menu(:menu="menuItems" basic color="var(--v-primary-darken2)" )
      template(v-slot:headerAfter)
        app-icon-button( icon="mdi-close" @click="handleClose" )

    app-dialog(v-model="dialog.archive" :header="$t('section.journal.archiveRecords')" width="80%" scrollable @close="closeArchivedWindow()")
      app-section(style="max-height: 100%").base-scroll-body
        wjPanelList(:headers="headersForList" :items="dialog.archivedItems" archived)

    app-dialog(v-model="dialog.select" :header="$t('section.journal.сhoosingLog')")
      app-section
        v-btn(v-for="item in journal.list" :key="item.uuid" icon :color="!checkActiveJournal(item) ? 'accent' : 'grey'" 
          @click="!checkActiveJournal(item) ? setActiveJournal(item) : null") {{ "№" + item.number }}

    app-dialog( v-model="dialog.save" :header="$t('section.journal.confirmation')")
      app-section
        app-text {{ $t('section.journal.reallySaveChanges') }}
        div(style="height:23px")
          v-btn.mt-2.float-right(color="accent" dense text x-small outlined @click="realSaveChanges()") {{$t('button.save')}}

    app-dialog( v-model="dialog.create" :header="$t('section.journal.confirmation')")
      app-section
        app-text {{ $t('section.journal.createNewLog') }}
        div(style="height:23px")
          v-btn.mt-2.float-right(color="accent" dense text x-small outlined @click="realCreateNewJournal()") {{$t('button.create')}}

    app-dialog( v-model="dialog.discard" :header="$t('section.journal.confirmation')")
      app-section
        app-text {{ $t('section.journal.undoAllChanges') }}
        div(style="height:23px")
          v-btn.mt-2.float-right(color="accent" dense text x-small outlined @click="realDiscardChanges()") {{$t('button.confirm')}}

    workPersonDialog(v-if="dialog.workPerson" @close="dialog.workPerson = false")  

    v-tabs.mb-1( v-model="model" background-color="transparent" )
      v-tabs-slider(color="var(--v-primary-darken2)")
      v-tab(v-for="(header,j) in headersList" :key="header" light) {{header}}
    app-section( style="height: calc(100% - 63px); width: 100%; border-radius: 3px; padding: 10px;").base-scroll-body
      v-fab-transition
        v-btn(v-if="model != 0" color='accent' @click="showRefsDialog()" small dark absolute fab bottom right style="margin: 0 25px 50px 0") 
          v-icon mdi-plus
      tabs-items(v-model="model" style="background-color:white;")
        tab-item(v-for="(name,i) in headers" :key="i")
          component(v-if="workJournal" v-bind:is="section[i]" ref="newItemDialog" 
            @deleteItem="deleteItemSection" @saveItem="saveItemSection" @editItem="editItemSection" @clearItem="clearItemSection")    
        
</template>

<script>
import TaskHeader from '@/components/task/TaskHeader'
import sectionTitle from '@/components/project/panel/left/documents/WorkJournal/SectionTitle'
import section1 from '@/components/project/panel/left/documents/WorkJournal/Section1'
import section2 from '@/components/project/panel/left/documents/WorkJournal/Section2'
import section3 from '@/components/project/panel/left/documents/WorkJournal/Section3'
import section4 from '@/components/project/panel/left/documents/WorkJournal/Section4'
import section5 from '@/components/project/panel/left/documents/WorkJournal/Section5'
import section6 from '@/components/project/panel/left/documents/WorkJournal/Section6'
import TabItem from '@/components/project/panel/left/documents/components/TabItem.vue'
import TabsItems from '@/components/project/panel/left/documents/components/TabsItems.vue'

import textJournal from './textJournal'
import WorkPersonDialog from './WorkPersonDialog'

import wjPanelList from '@/components/project/panel/left/documents/components/ExpansionPanel'

import { AlertService } from '@app/AlertService'
import { documentService } from '@/_services'

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

  export default {
    name: 'WorkJournal',
    components: {
      TaskHeader, 
      sectionTitle,
      section1,
      section2,
      section3,
      section4,
      section5,
      section6,
      wjPanelList,
      WorkPersonDialog,
      TabItem,
      TabsItems
    },

    data() {
      return {
        model: null,
        dialog: {
          select: false,
          save: false,
          create: false,
          discard: false,
          archive: false,
          workPerson:false,

          archivedItems: Object.assign({}, this.archivedItemsNull),
        },
        journal: {
          dialogSelect: false,
          list: [],
        },
        nextCloud: { cloudProvider: 'nextCloud' },
        section: ['sectionTitle', 'section1', 'section2', 'section3', 'section4', 'section5', 'section6'],
      }
    },

    mounted() {
      this.INIT_Cloud()

      this.getJournalList(this.project.uuid).then(() => {
        if (this.listJournals?.length == 0)
          this.createNewJournal(this.project.uuid)
        else 
          this.changeActiveJournal(this.listJournals[0].uuid).then()
      })

      this.getWorkPersonList(this.project.uuid)
    },

    beforeDestroy() {},

    watch: {},

    computed: {
      ...mapState('document', ['workJournal', 'listJournals', 'archivedItemsNull']),
      ...mapState('project', ['project']),
      ...mapGetters('document', ['wjStaffs', 'wjSpecialJournals', 'wjWorks', 'wjControls', 'wjExecutiveDocs', 'wjStateControls']),

      headersList () {
        let translatedHeaders = [this.$t('section.journal.headers.title')]; 

        for (let i = 1; i < 7; i++) {
          translatedHeaders.push(this.$t('section.journal.headers', {number: i}));
        }

        return translatedHeaders
      },

      headersForList() {
        return textJournal.archiveList
      },

      menuItems() {
        return [
          { title: this.$t('section.journal.saveChanges'), action: () => this.dialog.save = true},
          { title: this.$t('section.journal.undoChanges'), action: () => this.dialog.discard = true},
          { title: this.$t('section.journal.selectLog'), action: () => this.showSelectDialog()},
          { title: this.$t('section.journal.newLog'), action: () => this.dialog.create = true},
          { title: this.$t('section.journal.archiveRecords'), action: () => this.showArchivedWindow()},
          { title: this.$t('section.journal.createLog'), action: () => this.exportJournal() },
          { title: this.$t('section.journal.participantsWorks'), action: () => this.dialog.workPerson = true },
        ]
      },
    },

    methods: {
      ...mapMutations('document', ['setActiveWorkJournal', 'setListJournals']),
      ...mapActions('document', ['changeActiveJournal', 'getJournalList', 'createNewJournal', 'saveWorkJournal', 'getWorkPersonList']),
      ...mapActions('cloud', ['cloudWebDavUploadFile', 'getCloudSettings', 'cloudWebDavSearch']),
      ...mapMutations('project', ['setActiveGlobalTab']),

      async INIT_Cloud() {
        let cloudAttr = await this.getCloudSettings(this.project)
        if (cloudAttr != null) {
          this.nextCloud = Object.assign({}, this.nextCloud, cloudAttr)
          this.cloudWebDavSearch(this.nextCloud)
        }
      },

      handleClose() {
        this.setActiveGlobalTab('')
      },

      findComponent() {
        return this.$refs?.newItemDialog?.find(a => a.$vnode.tag.includes(this.section[this.model]))
      },

      checkActiveJournal(journal) {
        return journal.uuid == this.workJournal.uuid
      },

      exportJournal(){
        documentService.generateWorkJournal(this.workJournal.uuid).then((data) => {
          let file = new Blob([data])

          if (file) {
            this.nextCloud.path = encodeURI('WorkJournals/workjournal_' + this.workJournal.uuid + '.docx')
            let formData = new FormData()
            formData.append('username', this.nextCloud.username)
            formData.append('passwd', this.nextCloud.passwd)
            formData.append('path', this.nextCloud.path)
            formData.append('cloudProvider', 'nextCloud')
            formData.append('cloudUrl', this.nextCloud.cloudUrl)
            formData.append('file', file)
            this.cloudWebDavUploadFile({ item: file, cloudWebDav: this.nextCloud, formData: formData })

            AlertService.success({info: this.$t('section.journal.documentCreated')})
          }
        }).catch(error => {
          const reader = new FileReader();

          reader.onload = () => AlertService.error({ data: { error_description: reader.result } })
          reader.readAsText(error.response.data)
        })
      },

      showRefsDialog() {
        let component = this.findComponent()
        component?.showNewItemDialog()
      },

      realSaveChanges() {
        this.dialog.save = false
        this.saveWorkJournal(this.workJournal)
      },

      realDiscardChanges() {
        this.changeActiveJournal(this.workJournal.uuid).then(() => {
          this.dialog.discard = false
          AlertService.info({info: this.$t('section.journal.changesCanceled')})
        })
      },

      setActiveJournal(journal) {
        this.changeActiveJournal(journal.uuid).then(() => {
          this.journal.list = []
          this.dialog.select = false
          AlertService.info({info: this.$t('section.journal.selectedLog') + journal.number})
        })
      },

      showSelectDialog() {
        this.getJournalList(this.project.uuid).then(() => {
          if (this.listJournals.length > 1) {
            this.journal.list = this.listJournals
            this.dialog.select = true
          } else 
            AlertService.info({info: this.$t('section.journal.projectOneLog')})
        })
      },

      realCreateNewJournal() {
        this.createNewJournal(this.project.uuid).then(() => {
          this.dialog.create = false
          AlertService.success({info: this.$t('section.journal.newLogActive')})
        })
      },

      closeArchivedWindow() {
        this.dialog.archive = false
        this.dialog.archivedItems = Object.assign({}, this.archivedItemsNull)
      },

      showArchivedWindow() {
        this.dialog.archivedItems = {
          s1: this.wjStaffs.archived,
          s2: this.wjSpecialJournals.archived,
          s3: this.wjWorks.archived,
          s4: this.wjControls.archived,
          s5: this.wjExecutiveDocs.archived,
          s6: this.wjStateControls.archived,
        }

        this.dialog.archive = true
      },

      deleteItemSection(items, archive, item) {
        items.splice(item.number - 1, 1)
        items = items.map((el,index)=>{el.number = index+1; return el})

        item.archive = true 
        item.status = {value: 1, title: this.$t('section.journal.deleted')}
        archive.push(Object.assign({}, item))
      },

      saveItemSection(items, item, nullItem) {
        item.number = items.length + 1
        let newItem = Object.assign({}, nullItem) 

        for(let itemName in newItem)
          newItem[itemName] = item[itemName]

        items.push(newItem)
      },

      editItemSection(archive, item) {
        item.archive = true
        item.status = {value: 2, title: this.$t('section.journal.edited')}
        item.uuid = null
        archive.push(Object.assign({}, item))
      },

      clearItemSection(dialog, item, nullItem) {
        item = Object.assign({}, nullItem) 
        dialog.oldItem = null
        dialog.newOrEdit = false
        dialog.newItem = false 
        dialog.delete = false
        dialog.deleteItem = null
      },
    },
  }
</script>

<style lang="scss" scoped>
.format{
  height: 100% !important;
}
.v-tab {
  font-size: 12px;
  color: var(--v-primary-darken2) !important;
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}
</style>
