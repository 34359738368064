<template lang="pug">
  app-dialog( v-model="visible" :header='$t("section.collision.changeStatusCollisions")' :confirm-text='$t("button.confirm")' :confirm-disabled='!selectedStatus' @confirm='applyCollisionsStatus' )
    app-section
      app-select( v-model="selectedStatus" :label='$t("section.collision.selectStatus")' :items='statuses' item-text='title' item-value='value')
  
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      visible: false, 
      selectedStatus: null,
      single: false,
      selectedRow: null
    }
  },

  computed: {
    ...mapGetters('collision/table', ['selectedCollisionsUuid']),
    ...mapGetters('projectPermissions', ['hasCollisionStatus', 'hasCollisionResolution']),

    statuses() {
      let list = []

      if(this.hasCollisionStatus) {
        list.push(
          {title: this.$t('module.task.collisionNew'), value: 'NEW'},
          {title: this.$t('module.task.collisionInWork'), value: 'WORK'},
        )
        if(this.hasCollisionResolution) list.push({title: this.$t('module.task.collisionAllowed'), value: 'ALLOWED'})
      }
      return list
    },
  },

  methods: {
    ...mapActions('collision', ['setCollisionsStatus']),

    show ({ single, item }) {
      this.single = single
      this.selectedRow = item
      this.visible = true
    },

    applyCollisionsStatus() {
      let list = this.single ? [this.selectedRow.uuid] : this.selectedCollisionsUuid
      this.setCollisionsStatus({ collisions: list, status: this.selectedStatus})
      this.selectedStatus = null
    },
  }
}
</script>