<template lang="pug">
.surface.pa-2
  v-stepper( v-model="step" light vertical max-width="500" )
    v-stepper-step( step="1" :complete="step > 1" color="var(--v-accent-base)")
      .heading-6 Установите точки привязки
    v-stepper-content( step="1" )
      .body-2 Расположите точки в соответствии с точками на чертеже в необходимой плоскости
      .d-flex.pt-2
        app-button( @click="saveAnchorPointsPlacement" small action :disabled="!isAnchorScenePointsAdded" ) Сохранить
        app-button.mx-3( @click="cancelDrawingPlacing" small cancel  ) Отменить
        app-button( @click="clearAnchorPoints" small outlined ) Сбросить
    v-stepper-step( step="2" :complete="step > 2" color="var(--v-accent-base)" )
      .heading-6 Выберите нормаль плоскости чертежа
    v-stepper-content( step="2" )
      .body-2 Укажите плоскость, параллельно которой должен лежать чертеж
      .d-flex.pt-2
        app-button( @click="applyDrawingPlacement" small action :disabled="!isDrawing3DNormalPicked" ) Сохранить
        app-button.mx-3( @click="cancelSettingNormal" small cancel ) Назад
        app-button( @click="clearNormalPosition" small outlined ) Сбросить

    v-stepper-step( step="3" color="var(--v-accent-base)" )
      .heading-6 Зафиксируйте требуемое положение
    v-stepper-content( step="3" )
      DrawingTransformSettingPanel
</template>

<script>
import DrawingTransformSettingPanel from "@/components/drawings/DrawingTransformSettingPanel.vue";
import { activateAnchorPointsSceneController, 
         cancelDrawingPlacing, 
         applySceneAnchorPointsPlacement, 
         clearAnchorPoints, 
         isAnchorScenePointsAdded, 
         isEditMode,
         deactivateAnchorPointsSceneController,
         deactivateDrawing3DNormalPicker,
         activateNormalPicker,
         clearDrawing3DNormal,
         isDrawing3DNormalPicked } from '@/components/drawings/facades/imageSectionPlanes.facade.js';
import { useDrawingTransformSettingState } from "@/pinia";

export default {
  name: 'DrawingAnchorModePanel',

  components: {
    DrawingTransformSettingPanel
  },

  data() {
    return { step: 1 }
  },

  computed: { 
    drawingTransformSettingState: () => useDrawingTransformSettingState(),
    isAnchorScenePointsAdded: () => isAnchorScenePointsAdded(),
    isDrawing3DNormalPicked: () => isDrawing3DNormalPicked(),
    isDrawing3DEditMode: () => isEditMode()
  },

  beforeMount: () => activateAnchorPointsSceneController(),

  methods: {
    cancelDrawingPlacing: () => cancelDrawingPlacing(),
    clearAnchorPoints: () => clearAnchorPoints(),

    saveAnchorPointsPlacement() {
      deactivateAnchorPointsSceneController()
      activateNormalPicker()
      this.step = 2
    },

    cancelSettingNormal() {
      deactivateDrawing3DNormalPicker()
      activateAnchorPointsSceneController()
      this.step = 1
    },

    clearNormalPosition() {
      clearDrawing3DNormal()
    },

    applyDrawingPlacement() {
      this.step = 3
      applySceneAnchorPointsPlacement()
      this.drawingTransformSettingState.setEditedImageSectionPlaneState()
    },
  },
}
</script>

<style scoped>
::v-deep.v-stepper--vertical {
  padding-bottom: 0px !important;
}

::v-deep.v-stepper--vertical > .v-stepper__step {
  padding: 12px 12px 0px !important;
}

::v-deep.v-stepper--vertical > .v-stepper__content {
  padding: 0px 60px 12px 24px !important;
  margin: 3px -36px -3px 24px !important;
}
</style>