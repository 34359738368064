<template lang="pug">
  .tree
    draggable(@change="onChange" :list="items" :group="'toolTreeNode'" handle=".unit__drag" fallback-on-body="true" swap-threshold="0.65" :disabled="disabledDrag")
      project-tool-tree-content(v-if='!parent || parent.opened' :importProjStruct="true" v-for="u in items" style='position: relative' :u="u" :disabled="disabled" :parent='parent' @childToParentMap='handleChildToParentMap' )
</template>

<script>
import ProjectToolTreeContent from "@/components/project/common/ToolTreeContent"
import draggable from 'vuedraggable'


export default {
  name: 'ToolTreeDraggableBatchUpload',

  components: { draggable, ProjectToolTreeContent },

  props: {
    items: Array,
    disabled: Boolean,
    parent: {
      default: null,
      type: Object
    },
  },

  data() {
    return {
    }
  },

  computed: {
    disabledDrag () {
      return !this.parent?.model && this.disabled
    }
  },

  methods: {
    handleChildToParentMap(child, parent) {
      this.$emit("childToParentMap", child, parent)
    },

    onChange(evt) {
      let e = evt.moved ? evt.moved : evt.added ? evt.added : null  
      if (e) {
        const dragElement = this.items.splice(e.newIndex, 1)[0]
        this.items.push(dragElement)
        e.element.parentUuid = this.parent? this.parent.uuid: null
        this.$emit("childToParentMap", e.element, this.parent)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tree {
  padding-left: 8px;
}
</style>