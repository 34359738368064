<template lang="pug">
div( v-if="short" )
  app-text.d-inline-block.text-11(style="color: #3b93af !important") {{ commentCreator(comment).name + ", " + prettyDate(comment.createDate) }}
.d-flex.align-center( v-else )
  app-avatar.d-inline-block( :size="24" :colorObj="getBgColor(comment.creator.uuid)" :text="avatarText(commentCreator(comment))" :url="avatarUrl(commentCreator(comment))" :disabled="commentCreator(comment).archive" )
  .d-flex.ml-1
    app-text.d-inline-block.text-11 {{ commentCreator(comment).name }}
</template>

<script>
import { SourcePath } from "@/assets/app/SourcePath";
import uniqolor from 'uniqolor'

export default {
  name: "TaskCommentCreator",

  props: {
    comment: null,
    short: Boolean,
  },

  methods: {
    prettyDate: (fulldate) => {
      let [date] = fulldate.split(" ");
      let [year, month, day] = date.split("-");
      return `${day}.${month}.${year[2] + year[3]}`;
    },

    commentCreator(comment) {
      return comment.author || comment.creator || ''
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    avatarText(profile) {
      return profile.archive
        ? profile.name + this.$t("section.structure.model.memberDeleted")
        : profile.name;
    },

    avatarUrl(profile) {
      return profile.avatar ? SourcePath.avatar(profile.avatar) : "";
    },
  },
};
</script>

<style scoped>

</style>