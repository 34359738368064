import { render, staticRenderFns } from "./RuleDialog.vue?vue&type=template&id=4dc08e29&scoped=true&lang=pug&"
import script from "./RuleDialog.vue?vue&type=script&lang=js&"
export * from "./RuleDialog.vue?vue&type=script&lang=js&"
import style0 from "./RuleDialog.vue?vue&type=style&index=0&id=4dc08e29&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc08e29",
  null
  
)

export default component.exports