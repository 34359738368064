<template lang="pug">
div
  app-dialog( v-model="$_show" width="450" :header="$t('section.elementTree.menu.addAttribute')" @confirm="saveAttributeElements()" 
              :confirmText="$t('button.save')" :confirmDisabled="!disabledBtnSave" )
    app-section
      .title.mb-2( v-if="formElement.elements.length == 0" class="red--text" ) {{ $t('section.elementTree.selectedElements') }}: {{ formElement.elements.length }}
      app-text-field( v-model="formElement.parentName" :label="$t('section.elementTree.nameGroupElement')" )
      app-text-field( v-model="formElement.name" :label="$t('section.elementTree.nameAttr')" :rules="ruleRequired" )
      app-select( v-model="formElement.power" :items="powers" :label="$t('section.elementTree.powerAttr')" item-value="value" item-text="nameRu" return-object hideDetails )
      app-select.mt-4( v-model="formElement.unit" :items="units" :label="$t('section.elementTree.typeAttr')" item-value="value" item-text="nameRu" :rules="ruleRequiredSimple" return-object )
      app-text-field( v-model="formElement.stringValue" :label="$t('section.elementTree.valueAttr')" :rules="ruleRequired" )

</template>
  
<script>
import { $_validate_rules } from '@/common/ValidateRules'
import DialogToggable from '@/components/app/DialogToggable'
import { mapActions, mapGetters, mapState } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@app/AlertService'

  export default {
    name: 'AddAttribute',
    
    mixins: [$_validate_rules, DialogToggable],

    data () {
      const defaultFormElement =  Object.freeze({
        parentName: null,
        name: null,
        unit: null,
        power: null,
        stringValue: null,
        project: null,
        elements: []
      })
      return {
        formElement: Object.assign({}, defaultFormElement),
        defaultFormElement,
      }
    },

    watch: {
      $_show () {
        this.formElement = Object.assign({}, this.defaultFormElement)
        this.formElement.project = this.projectUuid
        this.formElement.elements = XeokitMediator.ElementsSelection.selectedElements.filter(el => el)
      }
    },
  
    computed: {
      ...mapState('elements', ['unitTypes', 'powerTypes']),
      ...mapGetters('project', ['projectUuid']),

      units () {
        let _this = this
        return this.unitTypes.map(u => {
          if (u.unitName) {
            if (typeof _this.$t(`notify.unit.name.${u.unitName}`) ===  "object") u.nameRu = _this.$t(`notify.unit.name.${u.unitName}.default`)
            else u.nameRu = _this.$t(`notify.unit.name.${u.unitName}`)
          } 
          else u.nameRu =  _this.$t('app.select.withoutType')

          return u
        })
      },

      powers () {
        let _this = this
        return this.powerTypes.map(u => {
          u.nameRu = u.prefixName ? _this.$t(`notify.power.name.${u.prefixName}`) : _this.$t('app.select.withoutType')
          return u
        })
      },

      disabledBtnSave () {
        return this.formElement.name && this.formElement.unit && this.formElement.stringValue && this.formElement.elements.length
      },

      ruleRequired () {
        return [this.rules.required]
      },

      ruleRequiredSimple () {
        return [this.rules.requiredSimple]
      },
    },

    methods: {
      ...mapActions('elements', ['addAttributeElement']),
      ...mapActions('axis/tree', ['fetchElementUpdateDetails']),

      saveAttributeElements () {
        if (this.formElement.elements.length > 0) {
          this.addAttributeElement(this.formElement)
          setTimeout( () => this.fetchElementUpdateDetails(this.formElement.elements[this.formElement.elements.length - 1]), 1000);
          this.fetchElementUpdateDetails(this.formElement.elements[this.formElement.elements.length - 1])
        }
        else {
          AlertService.warning({ info: this.$t('section.elementTree.axis.elementNotFound') })
        }
      },
  
    },
  }
  
  </script>
  