<template lang='pug'>
div.align-center.d-flex.channel-row
    div.s-title.channelTitle {{ channel.title }}

    //- div.ml-auto.s-actions
    //-   div(v-for='([icon, action, title], index) in actions' :key='index')
    //-     v-tooltip(bottom)
    //-       template(v-slot:activator='{ on, attrs }')
    //-         v-btn(v-bind='attrs' v-on='on' icon small @click='emitAction($event, action)')
    //-           v-icon(small) {{ icon }}
    //-       span {{ $t(title) }}
    transition(name='contol-button')
          div.ml-auto
            v-btn(color="#C4C4C4" @click.stop='showChannelMenu' small icon)
              v-icon mdi-dots-vertical
            app-menu(ref="channelControl" :menu='channelSettings')
</template>

<script>
export default {
  name: 'ChannelRow',

  props: {
    channel: Object,
  },

  computed: {

    channelSettings() {
      return [
        { title: this.$t("module.mnemoschemas.receivers.channel.create"), action: this.dev},
        { title: this.$t("button.edit"), action: this.showEditMnemoschemasDialog},
        { title: this.$t("button.delete"), action: this.showDeleteDialog },
        // { title: this.$t("module.mnemoschemas.openInEditor"), icon: "mdi-pencil-outline", action: this.showEditDrawingDialog },
        // { title: this.$t("module.drawing.delete"), icon: "mdi-delete-outline", action: this.showDeleteDrawingDialog },
      ]
    },
  },

  methods: {
    emitAction(event,action) {
      console.log(action);
      event.stopPropagation()
      this.$emit('action', action)
    },

    showChannelMenu(event) {
      this.$refs.channelControl.show(event)
    },

    showDeleteDialog() {
      this.$emit('action', 'delete')
    },

    showEditMnemoschemasDialog() {
      this.$emit('action', 'edit')
    }
  }
}
</script>

<style scoped>
  .channel-row {
    padding: 4px 4px 4px 8px;
    border-radius: 5px;
    border: 2px solid grey;
    cursor: pointer;
  }
  .channel-row:hover{
    transform: scale(1.01);
    box-shadow: 0 0 10px grey;
  }
  .channelRow{
    background: rgb(163, 163, 163);
  }
  .s-actions {
    display: flex;
    align-items: center;
  }
  .channelTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }
</style>