<template lang='pug'>
  div.task-tree-row-theme(:style='{paddingLeft: !flat ? task.level * 20 + "px" : ""}')
    div.expand-btn(v-if='task.children.length && !flat' @click='expand($event)')
      v-icon(color='#4D4D4D' size='small') {{ open ? "mdi-menu-up" : "mdi-menu-down" }} 
    div.ml-1.task-title {{ task.title }}
</template>

<script>
import { Task } from '@/assets/model/task/Task'
export default {
  name: "TaskTreeRowTheme",

  props: {
    task: Task,
    flat: Boolean,
    open: Boolean, 
  },

  methods: {
    expand(event) {
      event.stopPropagation()
      this.$emit('expand')
    }
  }
}
</script>

<style lang='scss' scoped>
  .task-tree-row-theme {
    display: flex;
    align-items: center;
    min-width: 340px;
  }

  .expand-btn {
    cursor: pointer;
    padding: 6px 3px;
    background: #E5E5E5;
    border-radius: 3px;
  }

  .task-title{
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis
  }
</style>