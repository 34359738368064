<template lang="pug">
div
  div( v-for="s in samples" test-id="environment-element" )
    .d-flex.align-center
      v-tooltip(bottom)
        template(v-slot:activator="{ on, attrs }" )
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            .text-wrapper( v-bind="attrs" v-on="on" :class="{ 'elevation-1': hover, 'sample-edit': s.new || s.edit }" ripple @click="handleShowConditionDialog(s)" ) {{ s.title }}
        span {{ s.title }}
      app-icon-button.mx-1( icon="mdi-eye" iconColor="var(--v-accent-base)" :disabled="!isSampleHasConditions(s)" test-id="sampleB-show-elements" @click="showViewButton(s)" )
      .menu-item( v-if="samples.length > 1" )
        app-icon-button( icon="mdi-dots-vertical" buttonSize="24" iconColor="black" @contextmenu="onRuleMenu" @click="onRuleMenu($event, s)" )
  
  app-icon-button.float-left( v-if="isAddAllowed && hasCollisionCreateRule" icon="$add-box-outlined" iconColor="black" @click="handleShowConditionDialog(null)" test-id="add-environment-element" )
  app-menu( ref="menu" :menu="menu" )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "RuleSettingGeneralIntersection",

  data() {
    return {
      samples: [],
      
      sampleMenu: null,
    }
  },

  computed: {
    ...mapGetters('rule/details', ['rule']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule']),

    isAddAllowed() {
      return !this.samples || this.samples?.length === 0
    },

    menu() {
      return [
        { title: this.$t('section.collision.rulesFounder.ruleSetting.searchSettings'), action: () => this.showSettings() },
        { title: this.$t('button.clear'), action: () => this.clear() }
      ]
    },
  },

  mounted() {
    if (this.rule.finder.className.includes('DataBaseClashRule') || this.rule.finder.className.includes('OccurrenceClashRule') || this.rule.finder.className.includes('PluginClashRule')) {
      this.samples = [ ...this.rule.sampleB ]
    }
  },

  methods: {
    ...mapActions('rule/details', ['removeSampleB']),

    isSampleHasConditions(sample){
      return sample?.logicGroup?.condition?.length > 0 || sample?.logicGroup?.children?.length > 0
    },

    handleShowConditionDialog(condtion) {
      let isSampleB = this.rule.finder.className.includes('DublicateClashRule') ? false : true
      this.$emit('showConditionDialog', condtion, isSampleB)
    },

    showSettings() {
      this.$emit('showSettings', this.sampleMenu)
    },

    showViewButton(item){
      this.$emit('showViewButton', item)
    },

    removeCondition(condition) {
      this.removeSampleB(condition)
      this.samples = this.samples.filter(sample => sample !== condition)
    },

    updateSample(sample) {
      if (sample.edit) {
        this.updateExistingSample(sample)
      }
      else {
        this.samples.push(sample)
      }
    },

    updateExistingSample(sample) {
      for (let index = 0; index < this.samples.length; index ++) {
        if (sample.uuid && sample.uuid === this.samples[index].uuid) {
          this.samples.splice(index, 1, sample)
          break
        }
        else if (sample.deleteUuid && sample.deleteUuid === this.samples[index].deleteUuid) {
          this.samples.splice(index, 1, sample)
          break
        }
      }
    },

    clear() {
      this.$emit('clearRules')
    },

    onRuleMenu (event, sample) { 
      this.sampleMenu = sample
      this.$refs.menu.show(event)
    },

    refresh() {
      this.samples = this.rule !=undefined ? [ ...this.rule?.sampleB ]:[]
    }
  }
}
</script>

<style scoped lang="scss">
  .text-wrapper {
    background-color: #EBEBEB;
    border-radius: 3px;
    font: normal 14px/16px $roboto;
    color: #4D4D4D;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sample-edit {
    background: rgb(59, 147, 175, .3);
  }

  .menu-item {
    justify-self: end;
  }
</style>
