<template lang="pug">
v-row.column-height(no-gutters)
    v-col.h100p(cols=4)
      app-panel( padding="0px")
        //-  Источники данных 
        app-floating-panels-section( style="overflow: auto; overflow-x: hidden" )
          app-floating-panel( :title="$t('module.mnemoschemas.receivers.dataSource.title')" bodyMinHeight="150" overflowBody="hidden"
          :settings="floatingPanels('CHANNELS_LIST')")
            template( v-slot:headerButton )
              app-menu(:menu="dataSourceSettings" basic)
            channels-list.mt-2(:list='selectedReceiverChannels')                
    div.h100p(style='flex: 1;')
      //- правая часть 
      app-panel.pl-2( padding="0px")
        app-floating-panels-section( style="overflow: auto; overflow-x: hidden" )
          app-floating-panel( bodyMinHeight="150" overflowBody="hidden" :settings="floatingPanels('CHANNELS_LIST')")

    

    create-schema-dialog(v-model='dialog.addNewDataSource')
 
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ChannelsList from '@/components/project/panel/left/mnemoschemas/components/recievers/ChannelsList'
import CreateSchemaDialog from '@/components/project/panel/left/mnemoschemas/components/DataSourceCreateDialog'
export default {
  name: "ChannelPanel",

  components: {
    ChannelsList,
    CreateSchemaDialog
  },

  data: () => ({
    dialog:{
        addNewDataSource:false
      },
  }),

  computed: {
    ...mapState('receiver', ['selectedReceiverChannels']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    dataSourceSettings() {
       let act = [
        { title: this.$t('module.mnemoschemas.receivers.dataSource.create'), action: this.showAddNewMnemoschemasDialog }
      ]
      return act
    }
  },

  methods: {
    showAddNewMnemoschemasDialog(){
      this.dialog.addNewDataSource = true
    }
  }

}
</script>

<style>


</style>
<style scoped>
.column-height{
  height:100%
}
</style>