<template lang="pug">
  .d-flex
    app-floating-panels-section.leftandright(:style="{ flexBasis: leftPanelWidth + '%' }")
      app-floating-panel( :title="$t('section.elementTree.axis.ruleTree')" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" 
        :settings="floatingPanels('COLLISION_RULES_FOUNDER')")
        v-progress-linear(v-if="isRuleTreeLoading" indeterminate )
        app-section.base-scroll-body(v-else style="width: 100%; height: 100%;")
          rules-founder-treeview.wrapper( v-if="hasTree" @clearElements="clearElements")
          .wrapper.text-center( v-else )
            .py-5.primary--text {{ $t("section.collision.rulesFounder.emptyRuleTree") }}
            .py-5.blue-text.text-subtitle-2 {{ $t("section.collision.rulesFounder.rulesInGroups") }}
            app-button-add(v-if="hasCollisionCreateRule" :text="$t('section.collision.rulesFounder.addGroup')" width="100%" @click="showFolderAddDialog")
              template(#icon)
                v-icon( color="#3B93AF" small) add
      
      app-floating-panel( v-if="pickedElement" :title="$t('section.element', {name: pickedElement.name})" bodyMinHeight="150" overflowBody="hidden" :startHeight="20" 
        :settings="floatingPanels('COLLISION_RULES_ELEMENTS_DETAILS')")
        element-details( :element='pickedElement' )

    .sizable-block-right(@mousedown="horizontalSizable($event)")
      v-img.icon-pos(:src="`/img/project/tools/sizable.svg`")

    app-floating-panels-section.ml-2.leftandright.rules_founder__panel_height(:style="{ flexBasis: rightPanelWidth + '%' }" )
      app-floating-panel( :title="$t('section.collision.rulesFounder.ruleSetup', { name: ruleName })" bodyMinHeight="200" overflowBody="hidden" :startHeight="300" :settings="floatingPanels('COLLISION_RULES_SETTING')")
        template(v-slot:headerButton)
          app-toolbar-button( v-if="ruleName && hasCollisionCreateRule" header size="small" icon="$setting-icon" :title="$t('section.collision.rulesFounder.ruleSetup', { name: '' })" @click.stop="showRuleEditDialog" test-id="rules-founder-options" )
        rule-setting-panel( width="640" ref='rule-settings' style="height: 100%" @showElements="showListElements" @isLoading="isLoadingElements" @clearElements="clearElements" @clearExceptionElements="clearExceptionElements")
      
      app-floating-panel(:title="$t('section.collision.rulesFounder.viewElements')" bodyMinHeight="150" overflowBody="hidden" :startHeight="80" :settings="floatingPanels('COLLISION_RULES_VIEW_ELEMENTS')")
        v-progress-linear(v-if="loadingElements" indeterminate)
          
        app-section.full-height(v-else style="margin-right: -8px")
          div.full-height.overflow-hidden(v-if="elements")
            v-row.mb-2(no-gutters)
              v-col(align-self="center")
                app-text.text-overflow.text-nowrap {{ $t('section.elementTree.axis.totalElements') }}: {{elements.length}}
              v-spacer
              v-col
                v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassGroupElementsSelect") 
                  | {{ allGroupElementsSelected ? $t('section.elementTree.axis.clearAll') : $t('section.elementTree.axis.selectedAll') }}
                  v-icon.ml-1(  color="accent" size="16" ) {{ allGroupElementsSelected ? 'remove_done' : 'done_all'}}
            v-virtual-scroll.v-list.base-scroll-body( bench=10 :items="elements" item-height="20")
              template(v-slot:default="{ item }")
                .nodeLabel.pr-6(:title="item.title" :class="{'active-element': activeElement && activeElement.uuid == item.uuid}")
                  v-row.pointer(no-gutters @click="activateElement(item)")
                    v-col(:title="item.name")
                      div.text-overflow {{ item.name }}
                v-icon.node__checkbox( tag="button" size="17" light @click.stop="selectElement(item.uuid)") {{ isElementSelected(item.uuid) ? 'check_box' : 'check_box_outline_blank' }}
          
    rules-founder-folder-dialog( v-if="dialog.folder" v-model="dialog.folder" :header="$t('section.collision.rulesFounder.newGroup')" )
    rules-founder-rule-dialog( v-if="dialog.rule" v-model="dialog.rule" :rule="ruleForEdit" :header="$t('section.collision.rulesFounder.newRule')" )
    rules-founder-import-dialog( v-show="dialog.import" v-model="dialog.import" :header="$t('section.collision.rulesFounder.importRuleTree')")
</template>

<script>
import RulesFounderTreeview from "./RulesFounderTreeview.vue"
import RulesFounderFolderDialog from "./RulesFounderFolderDialog.vue"
import RulesFounderRuleDialog from "./RulesFounderRuleDialog.vue"
import RulesFounderImportDialog from "./RulesFounderImportDialog.vue"
import RuleSettingPanel from "./ruleSetting/RuleSettingPanel.vue"
import ElementDetails from '@/components/project/panel/left/components/element/Details.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";
import { AlertService } from '@/assets/app/AlertService'

export default {
  components: {
    RulesFounderTreeview,
    RuleSettingPanel,
    RulesFounderFolderDialog,
    RulesFounderRuleDialog,
    RulesFounderImportDialog,
    ElementDetails
  },

  props: {
    pannelWidth: [String, Number]
  },
  data () {
    return {
      dialog: {
        folder: false,
        rule: false,
        import: false,
      },
      ruleForEdit:null,
      leftPanelWidth: 50, 
      rightPanelWidth: 50, 
      resizableWidth: null,
      activeElement: null,
      elements: [],
      loadingElements: false,
      uuidItem: null,

      viewElements: [],
    }
  },

  watch: {
    pannelWidth(newWidth) {
      this.handleWidthChange(newWidth);
    },
    rule: 'updateRuleSettings',

    viewElements: 'showElements',
  },

  computed: {
    ...mapGetters('rule', ['preparedRuleTree', 'isRuleTreeLoading']),
    ...mapGetters('rule/details', ['rule']),
    ...mapState('project', ['projectSettings']),

    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('collision/search', ['collisionSelectedElement']),
    ...mapGetters('axis/tree', ['element']),
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule', 'hasCollisionView', 'hasCollisionEmportImport']),

    hasTree () {
      return this.preparedRuleTree.length > 0
    },

    ruleName(){
      if (this.rule?.title)
        return "\"" + this.rule.title + "\""
      return ''
    },

    panelMenu () {
      let list = []

      list.push(
        { title: this.$t("section.collision.rulesFounder.newGroup"), action: this.showFolderAddDialog },
        { title: this.$t("section.collision.rulesFounder.newRule"), disabled: !this.hasTree, action: this.showRuleAddDialog },
      )
      if(this.hasCollisionEmportImport) {
        list.push(
          { title: this.$t("section.collision.rulesFounder.importRules"), action: this.showImportDialog },
          { title: this.$t("section.collision.rulesFounder.exportRules"), action: this.exportRules },
        )
      }

      return list
    },

    allGroupElementsSelected(){
      return this.elements.length == this.viewElements.length
    },

    pickedElement() {
      return this.collisionSelectedElement || this.element
    },
  },

  mounted () {
    this.$store.dispatch('rule/init')
    this.$emit('loaded')
    this.resizableWidth = this.$el.offsetWidth || 100 
  },

  methods: {
    ...mapActions('rule/global', ['exportRules']),
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),

    handleWidthChange(newWidth) {      
      const totalWidth = 100; 
      const leftRatio = this.leftPanelWidth / (this.leftPanelWidth + this.rightPanelWidth); 
      const rightRatio = 1 - leftRatio; 

      this.leftPanelWidth = leftRatio * totalWidth * (newWidth / this.resizableWidth);
      this.rightPanelWidth = rightRatio * totalWidth * (newWidth / this.resizableWidth);

      const minWidthPercent = (270 / newWidth) * totalWidth;

      if (this.leftPanelWidth < minWidthPercent) {
        this.leftPanelWidth = minWidthPercent;
        this.rightPanelWidth = totalWidth - minWidthPercent;
      } else if (this.rightPanelWidth < minWidthPercent) {
        this.rightPanelWidth = minWidthPercent;
        this.leftPanelWidth = totalWidth - minWidthPercent;
      }
      this.resizableWidth = newWidth; 
    },

    horizontalSizable(event) {
      event.preventDefault();
      const startX = event.clientX;
      const initialLeftWidth = this.leftPanelWidth;

      const onMouseMove = (e) => {
        const deltaX = e.clientX - startX;
        const containerWidth = this.resizableWidth; 
        let newLeftPanelWidth = initialLeftWidth + (deltaX / containerWidth) * 100;

        const minWidthPercent = (270 / containerWidth) * 100;

        if (newLeftPanelWidth < minWidthPercent) {
          newLeftPanelWidth = minWidthPercent;
        } else if (newLeftPanelWidth > (100 - minWidthPercent)) {
          newLeftPanelWidth = 100 - minWidthPercent;
        }

        this.leftPanelWidth = newLeftPanelWidth;
        this.rightPanelWidth = 100 - newLeftPanelWidth;         
      };

      const onMouseUp = () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    },

    isLoadingElements(boolean) {
      this.loadingElements = boolean
    },

    toggleElement(item) {
      this.$refs['rule-settings'].toggleElement(item)
    },

    showFolderAddDialog () {
      this.dialog.folder = true
    },

    showRuleAddDialog () {
      this.ruleForEdit = null
      this.dialog.rule = true
    },

    showRuleEditDialog(){
      this.ruleForEdit = this.rule
      this.dialog.rule = true
      this.clearElements()
    },

    showImportDialog() {
      this.dialog.import = true
    },

    updateRuleSettings() {
      if (this.$refs['rule-settings']) {
        this.$refs['rule-settings'].updateSettings()
      }
    },

    showListElements(item){
      let offModel = []
      let checkOffModel = false
      Object.keys(this.projectSettings.workModel).forEach(modelUUID => {
        if (!this.projectSettings.workModel[modelUUID].switchon) offModel.push(modelUUID)
      })
      this.viewElements = []
      this.uuidItem = item.uuid
      this.elements = item.list
      item.list.forEach(el => {
        if (offModel.includes(el.model))
          checkOffModel = true
        this.viewElements.push(el.uuid)
      })

      if (checkOffModel) {
        AlertService.warning({ info: this.$t('section.collision.rulesFounder.hiddenRev') })
      }
    },

    showElements() {
      XeokitMediator.ElementsSelection.selectElements(this.viewElements)
    },

    activateElement(item){
      if (this.activeElement != null && item?.uuid == this.activeElement.uuid) {
        this.activeElement = null
        this.fetchElementByGlobalId(null)
      } 
      else {
        this.activeElement = item
        this.fetchElementByGlobalId(item?.uuid)
        XeokitMediator.ElementsSelection.pickElement(item?.uuid)
      }
    },

    selectElement(elementUuid){
      let index = this.viewElements.findIndex(g => g == elementUuid)
      if (index>-1) {
        this.viewElements.splice(index,1)
      } 
      else {
        this.viewElements.push(elementUuid)
      }
    },

    isElementSelected(elementUuid){
      return this.viewElements.find(el => el === elementUuid)
    },

    clearGroup(){
      this.viewElements = []
      XeokitMediator.ElementsSelection.selectElements([])
    },

    clearExceptionElements(uuid) {
      if (uuid === this.uuidItem)
        this.clearElements()
    },

    clearElements() {
      this.clearGroup()
      this.elements = []
      this.uuidItem = null
      this.activeElement = null
      // this.fetchElementByGlobalId(null)
    },

    toggleMassGroupElementsSelect(){
      if (this.allGroupElementsSelected) {
        this.clearGroup()
      } 
      else {
        this.clearGroup()
        this.elements.forEach(el => this.viewElements.push(el.uuid))
      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  min-height: 300px;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%; 
}
.overflow-hidden {
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.column-height{
  height: 100%;
}

.text-size{
  font-size: 12px;
}

.blue-text {
  color: #3B93AF;
}

.nodeLabel {
  white-space: nowrap;
  position: relative;
}

.v-list {
  color: rgba(0,0,0,.87);
  font-size:12px;
  height: calc(100% - 32px);
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rules_founder__panel_height {
  height: calc(100% - 35px);
}

.node__checkbox {
  position: absolute;
  right: 10px;
  top: 0;
}

.active-element {
  background-color: #c0c0c0;
}
.leftandright {
  overflow: auto; 
  overflow-x: hidden
}

.sizable-block-right {
  display: inline-block;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  z-index: 998;
  float: right;
}

.icon-pos {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>