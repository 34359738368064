<template lang="pug">
div
  v-btn.expansion-button.mt-2( color="accent" dense text x-small @click="onClick()" ) {{ buttonHeader }}
    v-icon(v-if="!toggleExpansion" small).pl-1 mdi-menu-down
    v-icon(v-if="toggleExpansion" small).pl-1 mdi-menu-up
  div.container-position( v-if="toggleExpansion" )
    slot
</template>

<script>
export default {
  name: 'ExpansionButton', 
  props: {
    buttonHeader: {
      type: String,
      default: "",
    }
  }, 

  data() {
    return {
      toggleExpansion: false,
    }
  },

  methods: {
    onClick() {
      this.toggleExpansion = !this.toggleExpansion
      if (this.toggleExpansion) this.$emit("open")
      else this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
.expansion-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none !important;
}
.container-position {
  padding: 10px;
}
</style>