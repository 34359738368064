<template lang="pug">
    div
      .p-text(v-if = "currentProcess.statiscticInfo"  v-html = "prettyTime" ) 
      .caption.mt-1(v-if="projectName && currentProcess.projectName") {{ $t('module.process.headers.project') + ': ' + currentProcess.projectName}}
      .caption.mt-1 {{ currentProcess.startDate ? toLocalTimeZone(currentProcess.startDate) : $t('module.process.wait') }}
      .d-flex.justify-end(style="width:100%")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ProcessCard',
  
    data: ()=>({
        prettyTime: "",
        startFormText: null,
        intervalTimer: 0,
        lastTimeToFinish: 0
    }),

    props:{
        currentProcess: null,
        projectName: null,
    },

    watch:{
        currentProcess(value){
            if(value && value.statiscticInfo)
                this.checkStatisticsInfo(value.statiscticInfo)
        }
    },

    computed: {
      ...mapGetters('authUser',['toLocalTimeZone']),
    },

    methods: {

    timer(seconds, countCollisions){
      clearInterval(this.intervalTimer)
      this.intervalTimer = setInterval(()=>{
        seconds--
        if(seconds < 60 ) clearInterval(this.intervalTimer)
        this.displayTimer(seconds, countCollisions)
      }, 1000)
    },

    displayTimer(secondsLeft, countCollisions){
      if (!secondsLeft || secondsLeft < 0) secondsLeft = 0
      if (!countCollisions) countCollisions = 0

      let days = 0
      let hours = Math.floor(secondsLeft / 3600) 
      let minutes = Math.floor(secondsLeft / 60) - (hours * 60)
      let seconds = secondsLeft % 60
      hours = hours < 10 ? "0" + hours : hours
      minutes = minutes < 10 ? "0" + minutes : minutes
      seconds = seconds < 10 ? "0" + seconds : seconds
      
      if(hours > 24) days = 1
      if(minutes >=60) minutes = 59
      if(seconds >=60) seconds = 59


      if(days != 0) this.prettyTime = "Осталось примерно: " + days +" дн. " + "<br>" + "Найдено коллизий: " + countCollisions
      else if(hours != 0) this.prettyTime = "Осталось примерно: " + hours + ":" + minutes + ":" + seconds + "<br>" + "Найдено коллизий: " + countCollisions
      else if(minutes != 0) this.prettyTime = "Осталось примерно: " + minutes + ":" + seconds + "<br>" + "Найдено коллизий: " + countCollisions
      else this.prettyTime = "Осталось менее минуты " + "<br>" + "Найдено коллизий: " + countCollisions
    },

    checkStatisticsInfo(info){
      let timeToFinish = JSON.parse(info).timeToFinish
      let countCollisions = JSON.parse(info).countCalculatedCollisions

      if(info != null && this.lastTimeToFinish !== timeToFinish){
        this.lastTimeToFinish = timeToFinish
        let currentTime = this.lastTimeToFinish
        this.startFormText = Math.floor(currentTime / 1000)
        this.timer(this.startFormText, countCollisions)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.p-text {
  font: normal 11px/16px $roboto;
  color: #505254;
}

.project-panel {
  height: calc(100% - 52px) !important;
}

.t-scroll-body {
  height: 100%;
}
</style>