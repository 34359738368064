<template lang="pug">
.d-flex.flex-column.app-gap-px.app-h-full
  SceneToolkitLayout
  PluginsToolkitLayout
  GridsToolkitLayout
  SelectionToolkitLayout
  ModesToolkitLayout
  .surface.flex-grow-1.flex-shrink-1
</template>

<script>
import SceneToolkitLayout from './toolkits/SceneToolkitLayout.vue'
import PluginsToolkitLayout from './toolkits/PluginsToolkitLayout.vue'
import GridsToolkitLayout from './toolkits/GridsToolkitLayout.vue'
import ModesToolkitLayout from './toolkits/ModesToolkitLayout.vue'
import SelectionToolkitLayout from './toolkits/SelectionToolkitLayout.vue'

export default {
  components: {
    SceneToolkitLayout,
    SelectionToolkitLayout,
    GridsToolkitLayout,
    PluginsToolkitLayout,
    ModesToolkitLayout,
  },
}
</script>