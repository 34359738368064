<template lang="pug">
app-panel( width="320" )
  div.surface.darken-1.iot
    .tree
      app-panel.px-1.py-2(v-bind="$attrs")
        app-panel-header( :title="title" )
        template(v-for="t in deviceTypes" )
          app-panel-header( :title="t")
          app-section.mb-2
            .group-row.my-2(v-for="d in sortedDevices" :key="d.uuid" v-if="d.deviceReestr.type.title==t")
              v-hover( v-slot:default="{ hover }" close-delay="150" )
                .surface.lighten-5.primary--text.pa-2.mb-2.rounded.app-font-12.pointer( @contextmenu.stop="showMenu($event,d)") {{ d.title }} 
                  span(v-if="d.element==''") (-)
                  v-icon.unit-action( v-if="hover" :color="iconColor" @click.stop="showMenu($event,d)" ) more_vert
              .d-flex(v-for="p in d.deviceReestr.deviceParam" :key="p.uuid")
                app-text.pl-2 {{p.title}} : {{getVal(d.uuid, p.uuid)}}
                v-btn.ml-auto.mt-n1(v-if="p.writable && p.dataUnit.name=='BOOL'" icon light small @click="check(d.uuid, p.uuid)") 
                  v-icon {{ checkboxIcon(getVal(d.uuid, p.uuid)) }}

        //- v-btn.my-2(@click.native="addDevice()") +
        app-button-add.mt-3.ml-1.mr-1( width="100%" :text="$t('button.add')" @click.native="addDevice()")

            
    v-snackbar(v-model="setSnack" color="success" :timeout="6000" vertical top) OK
      v-btn(light text @click="setSnack = false") {{ $t('button.close') }}

    v-snackbar(v-model="relinkSnack" color="alert" :timeout="5000" vertical top) {{ $t('section.iot.selectElement') }}

    link-device(v-model="dialogLinkDevice" :element="deviceElement" :device="device" @created="afterLinkDevice")
    app-menu( ref="deviceMenu" :menu="deviceMenu" )

</template>

<script>
import { iotService } from '@/_services'
import { mapGetters } from 'vuex'
import LinkDevice from './components/iot/LinkDevice'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name: 'IotAdmin',
  components: { LinkDevice },
  data: () => ({
      dialogLinkDevice: false,
      setSnack: false,
      relinkSnack: false,
      device: null,
      start: true,
      values: [],
  }),
  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('iot', ['devices']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    title() {
      return this.$t("section.elementTree.bindDevice")
    },
    deviceTypes() {
      let s = new Set()
      this.devices.forEach(d => s.add(d.deviceReestr.type.title));
      return s;
    },
    iconColor() {
      return this.$vuetify.theme.themes.dark.primary.lighten3
    },
    //    {  },
    deviceMenu () {
      let act = []
           act.push({ title: this.$t('section.iot.elementMenu.editDevice'), action: this.linkDevice })
           act.push({ title: this.$t('section.iot.elementMenu.relinkDevice'), action: this.relinkDevice })
           if(this.device && this.device.element != '') act.push({ title: this.$t('section.classification.menuClassification.removeBind'), action: this.unlinkDevice})
           act.push({ title: this.$t('section.iot.elementMenu.delDevice'), action: this.deleteDevice})
      return act
    }, 
    sortedDevices() {
      let sorted = []
      Object.assign(sorted, this.devices)
      sorted.sort((a, b) =>  a.title.localeCompare(b.title))
      sorted.forEach(el => { if(el.deviceReestr.deviceParam.length > 0) el.deviceReestr.deviceParam.sort((a, b) => a.title.localeCompare(b.title)) })
      return sorted
    },
    deviceElement() {
      if(this.device!=null) return this.device.element
      else return ""
    }
  },
  methods: {
    getVal(deviceId, deviceParamId) {
      let val = this.values.find(el => el!=null && el.deviceUuid==deviceId && el.deviceParam.uuid==deviceParamId)
      return (val !== undefined) ?  val.data : ""
    },
    getData() {
      if(this.start)
        iotService.getDataByProject(this.projectUuid).then(data => {
          this.values = data
        })
    },
    loadData() {
      return this.start ? setTimeout(() => {
        this.getData()
        this.loadData()
      }, 3000) : null
    },
    check (dev, param) {
      let val = this.values.find(el => el!=null && el.deviceUuid==dev && el.deviceParam.uuid==param)
      if (val !== undefined) {
        val.data = val.data == "on" ? "off" : "on"
        iotService.setDeviceData(dev, param, val.data)
        .then(() => { this.setSnack=true })
      }
    },
    checkboxIcon (val) {
      if (val=="on") return 'toggle_on'
      return 'toggle_off'
    },
    showMenu (event, device) {
      this.device = device
      this.$refs.deviceMenu.show(event)
    },
    addDevice() {
      this.device = null
      this.linkDevice()
    },
    linkDevice() {
      this.dialogLinkDevice = true
    },
    relinkDevice() {
      if (this.pickedElement){
        this.$store.dispatch('iot/relinkDevice', { device: this.device.uuid, element: this.pickedElement }).then(data => {
           this.device = data
           this.setSnack=true
        })
      } else
        this.relinkSnack=true
    },
    unlinkDevice() {
      this.$store.dispatch('iot/unlinkDevice', this.device.uuid).then(data => {
         this.device = data
         this.setSnack=true
      })
    },
    deleteDevice() {
      this.$store.dispatch('iot/deleteDevice', this.device.uuid).then(() => {
         this.device = null
         this.setSnack=true
      })
    },
    afterLinkDevice(dev) {
      this.dialogLinkDevice = false
      this.device=dev
    },
  },
  mounted() {
    this.getData()
    this.loadData()
  },
  destroyed() {
    this.start = false
  }
}
</script>

<style lang="sass">
</style>

<style lang="scss" scoped>
.tree {
  height:100%;  
}

.iot {
  display: inline-block;
  height: 100%;
  overflow: auto;
}

.group-row {
  position:relative;
  .surface {
    .unit-action {
      position: absolute;
      right: 0;
      top: 5px;
      cursor: pointer;
      .v-icon {
        font-size: 24px;
      }
    }
  }
}

</style>
