import { fetchGroupPaletesByGroupUUID, saveGroupPalette, deleteGroupPaleteWithUUID } from '@/providers/data/groupPalette.dataProvider'
import { defineStore } from 'pinia'

export const useGroupsPaletteStore = defineStore('groups-palette', {
  state: () => ({
    groupsPalettes: {},
    disabledGroupsUuids: new Set(),
  }),

  getters: {
    colorsPreview: (state) => (groupUuid) => {
      return state.groupsPalettes[groupUuid]?.paletteItems?.map(item => item.color)?.slice(0, 4) ?? []
    }
  },

  actions: {
    async prepareGroupPalleteByGroupUUID(groupUuid) {
      let palette = ""
      try {
        palette = await fetchGroupPaletesByGroupUUID(groupUuid)
      } catch (error) { 
        console.error(error.message)
      }
      this.groupsPalettes = { ...this.groupsPalettes, [groupUuid]: palette }
      return this.groupsPalettes
    },

    async savePalette (palette) {
      let groupPalette = await saveGroupPalette(palette)
      this.groupsPalettes[palette.projectAxis] = groupPalette
      return groupPalette
    },

    async deleteGroupPalette(groupUuid) {
      if (!groupUuid) return 
      let paletteUUID = this.groupsPalettes[groupUuid]?.uuid || null

      if (paletteUUID) await deleteGroupPaleteWithUUID(paletteUUID)
    }
  }
})
