<template lang="pug">
div
  app-dialog( v-if="isCreateTaskScreenshotDialogVisible" v-model="isCreateTaskScreenshotDialogVisible" :header="$t('module.task.screenshot')" width="800px" max-height="720px" )
    #screenshotTest
      #screenshotImage.mb-1
        img( :src="snapshotImageData" ).screenshot-size
      app-textarea.field.mr-1( v-model="screenshotComment" :label="$t('module.task.comment')" test-id="screenshot-dialog-input" )
    
    .d-flex.justify-center
      v-btn.mr-1( :disabled="buttonStatus" dark small color="#3B93AF" test-id="screenshot-add-btn" :alt="$t('module.task.addToTask')" @click="createTaskScreenshot()" )
          v-icon.mr-2( color="#fff" ) playlist_add
          |  {{ $t('module.task.addToTask') }}

  app-dialog( v-model="isCreateTaskWithTaskScreenshotDialogVisible" :header="$t('module.task.create')" :confirm-text="$t('button.create')" :confirm-disabled="validation" @confirm="createTaskWithTaskScreenshot()" )
    app-section
      v-textarea.mt-2( v-model="newTask.title" color="#6B6B6B" :label="$t('module.task.form.title')" row-height="12" rows="1" auto-grow light test-id="task-create-name" )
      v-textarea.mt-2( v-model="newTask.description" color="#6B6B6B" :label="$t('module.task.form.description')" row-height="30" rows="3" auto-grow light )
</template>

<script>
import { TaskScreenshot } from '@/components/task/taskScreenshot.js'
import { mapState } from 'vuex'
import { useTaskScreenshotComment } from '@/pinia/taskScreenshotComment.js'
import { useTaskScreenshotStore } from "@/pinia"

export default {
  name: 'TaskScreenshotDialog',

  computed: {
    ...mapState('task', ['selectedTask']),
    
    taskScreenshotStore: () => useTaskScreenshotStore(),

    buttonStatus() {
      return this.taskScreenshotStore.createScreenshotStatus
    },

    validation() {
      return !this.newTask.title.trim()
    },

    isCreateTaskScreenshotDialogVisible: {
      get() {
        return TaskScreenshot.isCreateTaskScreenshotDialogVisible
      },
      set(value) {
        if (!value) {
          TaskScreenshot.closeCreateTaskScreenshotDialog()
        }
      }
    },

    isCreateTaskWithTaskScreenshotDialogVisible: {
      get() {
        return TaskScreenshot.isCreateTaskWithTaskScreenshotDialogVisible
      },
      set(value) {
        if (!value) {
          TaskScreenshot.closeCreateTaskWithTaskScreenshotDialog()
          setTimeout(() => {
            this.newTask.title = ""
            this.newTask.description = ""
          })
        }
      }
    },

    screenshotComment: {
      get() {
        return TaskScreenshot.screenshotComment
      },
      set(screenshotComment) {
        TaskScreenshot.screenshotComment = screenshotComment
      }
    },

    snapshotImageData() {
      return TaskScreenshot.snapshotImageData
    },

    pickedEntityId() {
      return TaskScreenshot.pickedEntityId
    },

    pickedWorldPos() {
      return TaskScreenshot.pickedWorldPos
    },

    taskUuid() {
      return TaskScreenshot.taskUuid
    }
  },

  data () {
    return {
      taskScreenshotComment: useTaskScreenshotComment,
      newTask: {
        title: "",
        description: ""
      }
    }
  },

  methods: {
    createTaskWithTaskScreenshot() {
      TaskScreenshot.createTaskWithTaskScreenshot({
        taskTitle: this.newTask.title,
        taskDescription: this.newTask.description,
        pickedWorldPos: this.pickedWorldPos,
        pickedEntityId: this.pickedEntityId
      })
    },

    createTaskScreenshot() {
      this.isCreateTaskScreenshotDialogVisible = false
      
      TaskScreenshot.createTaskScreenshot({
        taskUuid: this.taskUuid,
        screenshotComment: this.screenshotComment,
        pickedWorldPos: this.pickedWorldPos,
        pickedEntityId: this.pickedEntityId
      })
      this.taskScreenshotComment.comment = ''
      this.taskScreenshotComment.status = true
    }
  }
}
</script>

<style scoped>
#screenshotImage {
  border: 1px solid #909090;
  border-radius: 5px;
  text-align: center;
  width: 780px;
  height: 300px;
}

#screenshotImage img {
  max-height: 100%;
}

.field >>> textarea { 
  min-height: 45px;
  max-height: 150px;
}
</style>
