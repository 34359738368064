<template lang="pug">
  v-row.profile( @contextmenu.stop="showMenu" :class="{'app-cursor--context': withContext}" dense)
    v-col
      app-avatar(:url="filePath" :colorObj="getBgColor(teammate.profile.uuid)" :text="this.teammate.profile.name")
      .u-info
        .d-flex
          div(v-bind:class="active ? 'fio' : 'fio-default'") {{ this.teammate.profile.name }} 
            .caption {{ this.teammate.profile.email }} 
            span(v-if="this.teammate.profile.org").caption.pt-1 {{ this.teammate.profile.org }}
          span.ml-2.red--text(v-if="this.teammate.profile.archive") {{ $t('section.structure.model.memberDeleted') }}  
        
        //- .caption.mt-2 {{ roles }} 
        .mt-2
          .caption( v-for="(r, index) in roles" :key="r.uuid" ) 
            span {{ r.title }}
            span.ml-1( v-if="!fromThisModel(r.model.uuid)" ) {{ $t('section.structure.model.memberRoleFrom', {title: r.model.title}) }}
            span( v-if="index != (roles.length - 1)" ) {{ "," }}
            
    v-col.pr-2(cols="auto")
      slot

</template>

<script>
import { SourcePath } from '@app/SourcePath'
import uniqolor from 'uniqolor';

export default {
  name: 'ProjectTeammate',

  props: {
    teammate: Object,
    active: Boolean,
    withContext: {
      type: Boolean,
      default: true
    },
    selectedModel: Object,
  },

  data () {
    return {
      millis: new Date().getTime()
    }
  },

  computed: {
    avatar () {
      if (this.teammate && this.teammate.profile && this.teammate.profile.name!=null) {
        let { name = ' ' } = this.teammate.profile
        return name[0].toUpperCase()
      } else return ''
    },

    roles () {
      const list = this.teammate && this.teammate.roles || []
      return list
      // return list.map(r => r.title).join(", ")
    },
    
    filePath () {
      return SourcePath.avatar(this.teammate.profile.avatar)
    },

  },

  methods: {
    showMenu (event) {
      this.$emit('menu', event, this.teammate)
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    fromThisModel (modelUuid) {
      return this.selectedModel.uuid == modelUuid
    }
  },

}
</script>

<style lang="scss" scoped>
.profile {
  margin-bottom: 16px;
  cursor: pointer;
}
.avatar {
  margin-top: 5px;
  display: inline-block;
  font: normal 14px/24px $roboto;
  color: #505254;
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
}
.u-info {
  display: inline-block;
  margin-left: 8px;
  font: normal 14px/12px $roboto;
  vertical-align: top;
  margin-top: 5px;
  // max-width: 192px;
  max-width: calc(100% - 32px);
}
.fio {
  color: #505254;
}
.fio-default {
  color: #3B93AF;
}
</style>