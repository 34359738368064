<template lang="pug">
app-panel( width="320" )
  v-container(style="height: 100%; overflow: auto;")

    v-row(dense)
      template(v-for="item in widgets")
        v-col(:cols="item.size.value==0 || item.size.value==2 ? 6 : 12" :order="item.sort")
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            app-section.widget( @contextmenu.stop="showMenu($event,item)" :class="'widget-size--' + item.size.value" )
              v-icon.unit-action( v-if="hover" :color="iconColor" @click.stop="showMenu($event,item)" ) more_vert
              app-text.title.mb-2.py-1 {{item.title}}
              device-widget(:widget="item")

    //- v-btn.mt-2(@click.native="addWidget()") +
    app-button-add.mt-3.ml-1.mr-1( width="100%" :text="$t('button.add')" @click.native="addWidget()")
  
    create-device-widget(v-model="dialogWidget" :widget="widget" @created="afterCreateWidget")

    app-menu( ref="widgetMenu" :menu="widgetMenu" )

</template>

<script>
import CreateDeviceWidget from './components/iot/CreateDeviceWidget'
import DeviceWidget from './components/iot/DeviceWidget'
import { mapGetters } from 'vuex'

export default {
  name: 'IotWidget',
  components: { CreateDeviceWidget, DeviceWidget },

  data () {
    return {
      widget: null,
      dialogWidget: false,
    }
  },
  computed: {
    ...mapGetters('iot', ['widgets']),
    widgetMenu () {
      return [
        { title: this.$t('button.edit'), action: this.editWidget },
        { title: this.$t('button.delete'), action: this.delWidget },
      ]
    }, 
    iconColor() {
      return this.$vuetify.theme.themes.dark.primary.lighten3
    },
  },
  methods: {
    afterCreateWidget(w) {
      this.$store.dispatch('iot/saveWidget', w)
      this.dialogWidget=false
    },
    addWidget() {
      this.widget = null
      this.dialogWidget=true
    },
    editWidget() {
      this.dialogWidget=true
    },
    showMenu(event, widget) {
      this.widget = widget
      this.$refs.widgetMenu.show(event)
    },

    delWidget() {
      this.$store.dispatch('iot/delWidget', { widgetId: this.widget.uuid })
    },
  },

}
</script>

<style lang="scss" scoped>
 .panel {
    width:320px
 }
 .hx2 {
    height:68px;
 }
 .widget {
   position:relative;
   &.widget-size--2, &.widget-size--3 {
     height:104px;
   }
   .unit-action {
     position: absolute;
     right: 2px;
     top: 5px;
     cursor: pointer;
     .v-icon {
       font-size: 24px;
     }
   }
 }
 .title {
   border-bottom: 2px solid rgb(196, 196, 196);
   font-weight: bold!important;
 }
</style>
