<template lang="pug">

  div.header-box.margin-right(test-id="task-comment-menu-hover-panel")

    app-text.d-inline-block.text-11.date-text(style="font-weight:400;line-height: 16px;") {{ toLocalTimeZone(comment.createDate.replace(" ", "T")) }}

    task-button-action.d-inline-block.mt-1.ml-5.mr-2(v-if="hasTaskCreateCommentAttach" icon="project/tools/reply-grey.svg" size=15 test-id="task-comment-menu-quoted"
      @click="$emit('quotedComment')")

    task-button-action.d-inline-block( v-if="hasTaskCreateCommentAttach && commentCreator(comment).uuid == user.uuid" icon="project/tools/edit-nofill.svg" test-id="task-comment-menu-edit" size=15 
      @click="$emit('editComment')" )

    task-button-action.d-inline-block.ml-1( v-if="hasTaskDeleteCommentAttach && commentCreator(comment).uuid == user.uuid" icon="project/tools/delete_fill.svg" size=15 test-id="task-comment-menu-delete"
      @click="$emit('deleteComment')")
      
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'

export default {
  name: "TaskCommentMenu",

  components: {
    TaskButtonAction
  },

  computed:{
    ...mapState('authUser', ['user']),
    ...mapGetters('authUser', ['toLocalTimeZone']),
    ...mapGetters('projectPermissions', ['hasTaskCreateCommentAttach', 'hasTaskDeleteCommentAttach']),
  },

  props:{
    comment: null
  },

  methods:{
    commentCreator(comment) {
      if(comment.author){
        return comment.author
      } else if (comment.creator){
        return comment.creator
      } else {
        return ""
      }
    },
  }
}
</script>

<style>

</style>