<template lang="pug">
  app-dialog(v-model="$_show" width="400" :header="$t('section.iot.elementMenu.linkDevice')" :confirm-text="$t('button.save')" @confirm="saveDevice") 
    app-section
      app-select.mb-4(v-model="form.deviceReestr" :items="deviceReestr" :label="$t('section.iot.device')" item-text="title" )
      app-text-field(v-model="form.name" :label="$t('section.iot.mark')")
      app-text-field(v-model="form.title" :label="$t('section.iot.name')")
      app-text-field(v-model="form.devicePath" :label="$t('section.iot.path')")
      app-textarea( v-model="form.properties" :label="$t('section.iot.setting')" outlined )

</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapGetters } from 'vuex'

export default {
  name: 'LinkDevice',
  mixins: [DialogToggable],
  props: {
    element: null,
    device: null,
  },
  data () {
    const defaultDevice = Object.freeze({
      uuid: null,
      project: null,
      element: null,
      name: null,
      title: null,
      devicePath: null,
      properties: null,
      deviceReestr:null
    })

    return {
      form: Object.assign({}, defaultDevice),
      defaultDevice,
    }
  },
  watch: {
    $_show (value) {
      if (value) {
        let baseObj = this.device || this.defaultDevice
        this.form = JSON.parse(JSON.stringify(baseObj))
      }
    },
  },
  computed: {
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('iot', ['deviceReestr']),
    valid() {
      if( this.form.title!=null ) return true
      else return false
    },
  },
  methods: {
    saveDevice() {
      this.form.element = this.element
      if(this.form.project == null) this.form.project = this.projectUuid
      this.$store.dispatch('iot/linkDevice', this.form).then(data => {
          this.$emit('created', data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
