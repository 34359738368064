import { useGroupsStore } from '@/stores/groups/groups.store'
import { useGroupsElementStore } from '@/stores/groups/groupsElement.store'
import { useGroupsVisibilityStore } from '@/stores/groups/groupsVisibility.store'
import { useSceneModelVisibilityStore } from '@/stores/scene/sceneModelVisibility.store'
import { decorateToRebuildScene } from '@/decorators/scene.decorator'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import vuexStore from '@/store'
import { useGroupsPaletteStore } from '@/stores/groups/groupPalettes.store'
import { useElementsColorStore } from '@/stores/groups/elementsColor.store'
import { hexToRgb } from '@/utils'
import { useDrawings3DStore, useImageSectionPlanesStore } from '@/pinia'

const groupsStore = useGroupsStore()
const groupsElementStore = useGroupsElementStore()
const groupsVisibilityStore = useGroupsVisibilityStore()
const groupPalettesStore = useGroupsPaletteStore()
const elementsColorStore = useElementsColorStore()

const sceneModelVisibilityStore = useSceneModelVisibilityStore()

const imageSectionPlanesStrore = useImageSectionPlanesStore()
const drawings3DStore = useDrawings3DStore()

//#region Управление кэшем

export function resetSceneCache () {
  sceneModelVisibilityStore.$reset()
  imageSectionPlanesStrore.$reset()
  drawings3DStore.$reset()
}
//#endregion

export async function prepareSceneElements() {
  vuexStore.dispatch('project/loadConditionEnum')

  const groupStore = useGroupsStore()
  if (groupStore.prepared) return

  await groupStore.prepareGroups()

  const groupsElementStore = useGroupsElementStore()
  groupStore.rawList.forEach(async (group) => {
    await groupsElementStore.prepareGroupElements(group.uuid)
    await groupPalettesStore.prepareGroupPalleteByGroupUUID(group.uuid)
    recolorizeScene()
  })
}

export function recolorizeScene () {
  elementsColorStore.$reset()

  groupsStore.rawList.forEach(group => {
    setupColorsForGroup(group.uuid)
  })

  let keys = XeokitMediator.viewer.scene.colorizedObjectIds.filter((key) => !elementsColorStore.colorizedItems.has(key))
  XeokitMediator.ElementsSettings.setElementsColorized(keys, null)

  for (let [color, ids] of elementsColorStore.colors) {
    XeokitMediator.ElementsSettings.setElementsColorized(Array.from(ids), hexToRgb(color))
  }
}

function setupColorsForGroup (groupUuid) {
  if (groupPalettesStore.disabledGroupsUuids.has(groupUuid)) return

  let palette = groupPalettesStore.groupsPalettes[groupUuid]
  if (!palette) return

  palette.paletteItems?.forEach(item => {
    setupColorForElements(item.color, item.key, groupUuid)
  })
  setupColorForElements(palette.color, null, groupUuid)
}

function setupColorForElements (color, key, groupUuid) {
  let endpoints = groupsElementStore.enpoints(groupUuid, key)
  let uuids = endpoints.map(({ uuid }) => uuid)
  elementsColorStore.setColorForItems(color, uuids)
}

export const fetchViewer = () => XeokitMediator.viewer
export const fetchScene = () => XeokitMediator.viewer.scene
export const fetchCamera = () => XeokitMediator.viewer.scene.camera

export const getIsSceneModelHidden = () => sceneModelVisibilityStore.isModelHidden
export const setSceneModelHidden = decorateToRebuildScene((value) => {
  sceneModelVisibilityStore.isModelHidden = value
})

export const isModeOrthogonal = () => XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.ORTHO
export const setPerspectiveMode = async () => XeokitMediator.ProjectionMode.setProjectionMode(XeokitMediator.ProjectionMode.Modes.PERSPECTIVE)
export const setOrthogonalMode = async () => XeokitMediator.ProjectionMode.setProjectionMode(XeokitMediator.ProjectionMode.Modes.ORTHO)

export const fetchMouseNavigation = () => XeokitMediator.MouseNavigation.mouseNav

export async function setMouseNavigation (mouseMode) {
  vuexStore.dispatch('authUser/setProfileSettingModeMouseNav', mouseMode)
  XeokitMediator.MouseNavigation.setMouseNavigation(mouseMode)
}

export function flyToElements (uuids) {
  XeokitMediator.CameraFlight.flyToElements(uuids)
}

//#region Выделение элементов
export function selectElements (uuids) {
  XeokitMediator.ElementsSelection.selectElements(uuids)
}

export function unselectElements (uuids) {
  XeokitMediator.ElementsSelection.unselectElements(uuids)
}
//#endregion


export function redrawSceneElements () {
  const store = useSceneModelVisibilityStore()
  const defaultVisibility = store.isModelHidden ? false : true

  let visibleItems = new Set()
  let hiddenItems = new Set()

  for (const group of groupsStore.rawList) {
    let items = groupsElementStore.groupElements[group.uuid] || []
    if (groupsVisibilityStore.hidden.has(group.uuid)) {
      items.forEach(item => { hiddenItems.add(item) });
    }
    else {
      items.forEach(item => { visibleItems.add(item) });
    }
  }

  let allItems = XeokitMediator.viewer.scene.objectIds
  allItems.forEach(item => {
    if (visibleItems.has(item) || hiddenItems.has(item)) return

    if (defaultVisibility) {
      visibleItems.add(item)
    }
    else {
      hiddenItems.add(item)
    }
  })

  XeokitMediator.ElementsSettings.setElementsVisible(Array.from(hiddenItems), false)
  XeokitMediator.ElementsSettings.setElementsVisible(Array.from(visibleItems), true)

  // TODO: добавить логику сокрытие элементов, если выбрана конкретная модель в структуре проекта
  // TODO: добавить логику сокрытие элементов по IFC-типам
}