import { useGroupsPaletteStore } from '@/stores/groups/groupPalettes.store'
import { useGroupsElementStore } from '@/stores/groups/groupsElement.store'
import { AlertService } from '@app/AlertService'
// import { useElementsColorStore } from '@/stores/groups/elementsColor.store'
import { recolorizeScene } from './scene.facade'
// import { resetGroupsCache, prepareGroupsCache, fetchAllGroups } from './groups.facade'
import { resetGroupsCache, prepareGroupsCache } from './groups.facade'


const groupPalettesStore = useGroupsPaletteStore()
const groupsElementStore = useGroupsElementStore()
// const elementsColorStore = useElementsColorStore()

export const groupsPalettes = (groupUUID) => {
  return groupPalettesStore.groupsPalettes[groupUUID]
}
// Метод для получения цветовой палитры для группы
export const fetchGroupPaletteForGroupWithUuid = (uuid) => groupPalettesStore.groupsPalettes[uuid]

export const topLevelElements = (uuid) => groupsElementStore.topLevelElements(uuid)

export async function saveGroupPalette(palette) {
  await groupPalettesStore.savePalette(palette)
  recolorizeScene()
  AlertService.success({ info: "Сохранено" })
}

export async function deleteGroupPalette (uuid) {
  await groupPalettesStore.deleteGroupPalette(uuid)
  resetGroupsCache()
  await prepareGroupsCache()
}

export const fetchColorsPreviewForGroup = (uuid) => groupPalettesStore.colorsPreview(uuid)

export const fetchPaletteByGroupUuid = (groupUuid) => groupPalettesStore.groupsPalettes[groupUuid]
export const getSetOfUuidsOfDisabledGroupPalettes = () => groupPalettesStore.disabledGroupsUuids
export const toggleColoringForGroupWithUuid = (groupUuid) => {
  let set = new Set(groupPalettesStore.disabledGroupsUuids)
  if (set.has(groupUuid)) {
    set.delete(groupUuid)
  } else {
    set.add(groupUuid)
  }
  groupPalettesStore.disabledGroupsUuids = set

  // overrideSceneElementColors()
  recolorizeScene()
}

// function overrideSceneElementColors () {
//   elementsColorStore.$reset()

//   fetchAllGroups().forEach(group => {
//     setupColorsForGroup(group.uuid)
//   })
// }

// function setupColorsForGroup (groupUuid) {
//   if (groupPalettesStore.disabledGroupsUuids.has(groupUuid)) return

//   let palette = groupPalettesStore.groupsPalettes[groupUuid]
//   if (!palette) return

//   palette.paletteItems?.forEach(item => {
//     setupColorForElements(item.color, item.key, groupUuid)
//   })
//   setupColorForElements(palette.color, null, groupUuid)
// }

// function setupColorForElements (color, key, groupUuid) {
//   let endpoints = groupsElementStore.enpoints(groupUuid, key)
//   let uuids = endpoints.map(({ uuid }) => uuid)
//   elementsColorStore.setColorForItems(color, uuids)
// }