<template lang="pug">
div
  .d-flex.py-2
    v-btn.px-1.search-elements-filter-button( color="accent" dense text x-small @click="setElementsSelection" ) {{ tableButtonText }} {{ searchElementsStore.currentElements.length }} элемента
    search-elements-table-filter-menu
</template>

<script>
import { mapStores } from 'pinia'
import { useSearchElementsStore } from '@/pinia/searchElements.module'
import SearchElementsTableFilterMenu from "./SearchElementsTableFilterMenu.vue"
export default ({
  components: {
    SearchElementsTableFilterMenu
  },

  data() {
    return {
      isSelected: false, 
    }
  },

  computed: {
    ...mapStores(useSearchElementsStore),

    tableButtonText() {
      if (this.searchElementsStore.isElementsSelected) return 'Сбросить все'
      return 'Выделить все'
    }
  },

  methods: {
    setElementsSelection() {
      this.searchElementsStore.setSelectedElements()
    }
  },
})
</script>

<style scoped>
.search-elements-filter-button {
  text-transform: none;
}
</style>
