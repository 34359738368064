<template lang="pug">
  app-section.t-scroll-body
    .models( v-for='(folder, index) in folders' )
      .d-flex.align-center.px-2.pointer(:style='folderTitleStyle(index)' @click='setFolderVisible(folder.uuid)')
        span.text-truncate.font-weight-bold(style="color:black") {{folder.name}}
        v-btn.icon-anim(color='#C4C4C4' v-if="index!=0" icon small  @click.stop="onMenu(folder,$event)")
          v-icon(color='#C4C4C4' ) mdi-dots-vertical
        v-btn.ml-auto(icon small)
          v-icon.close-model(color='#C4C4C4' :class='{"close-button": folder.visible}') expand_less
      .groups.py-2.pl-2.d-flex.flex-wrap.flex-row.justify-start(v-if='folder.visible' :style='folderTitleStyle(index)')
        .block(v-for='template in folder.templates')
          //- v-img.preview(contain :width="width" :aspect-ratio="1/1.414" :src='template.url' @contextmenu.stop="contextClick(template,$event)" @click="selectTemplate(template)")
          .preview(:style="`background:Url(${template.url});`" @contextmenu.stop="contextClick(template,$event)"  @click="selectTemplate(template)")
          .title.py-1
            div.text-truncate.mb-1.px-2 {{template.name}}
            span.pl-2 {{template.editDate}}
    app-menu( ref="templateToolbar" :menu="templateMenu" ) 
    app-menu( ref="folderToolbar" :menu="folderMenu" )            
    
    v-speed-dial.addFolder( v-model="fab"  :transition="'slide-y-reverse-transition'")
      template( v-slot:activator )
        v-btn.sud-fab__button(small :color="fab ? 'primary lighten-3' : 'accent'" fab dark )
          v-icon( v-if="fab" ) {{ $vuetify.icons.values.close }}
          v-icon( v-else ) {{ $vuetify.icons.values.add }}
      div(style="position: absolute; right: 0px; bottom: 15px; ")
        v-btn.new-button.white--text(small color="#1565C0" @click='showTemplateDialog(false)') {{ $t('template.button.newEmptyTemplate') }}
        //- v-btn.new-button.white--text(small color="#1565C0" @click='showSelectTemplateDialog()') {{ $t('template.button.newTemplate') }}
        v-btn.new-button.white--text(small color="#1565C0" @click='showFolderDialog(false)') {{ $t('template.button.newFolder') }}

    app-dialog(v-model="dialog.template" :header="$t('template.dialog.newTemplateHeader')" :confirmText="$t('button.add')"  :confirm-disabled="validationtTemplateName" @confirm="createTemplate")
      app-section
        AppAutocomplete.mt-2.mb-2(v-model="selectFolder" item-text="name" item-value="uuid" :items="folders" dense 
            :label="$t('template.dialog.chooseFolder')")
        AppAutocomplete.mt-2.mb-2(v-model="dialog.selectedTemplate" item-text="name" item-value="uuid" :items="dialog.templatesList" dense 
            :label="$t('template.dialog.chooseTemplate')")
        app-text-field(v-model.trim="dialog.templateName" :label="$t('template.dialog.templateName')")
    app-dialog(v-model="showDialog.folder" :header="showDialog.edit ? $t('template.dialog.editFolderHeader') : $t('template.dialog.newFolderHeader')" 
        :confirmText="showDialog.edit ? $t('button.edit') : $t('button.add')" :confirm-disabled="validationNewFolder" @confirm="showDialog.edit ? editFolder() : addFolder()")
     app-section
      app-text-field(v-model.trim="newFolderName" :label="$t('template.dialog.folderName')")
    
    app-dialog(v-model="showDialog.template" :header="showDialog.edit ? $t('template.dialog.editTemplateHeader') : $t('template.dialog.newEmptyTemplateHeader')" 
        :confirmText="showDialog.edit ? $t('button.edit') : $t('button.add')" :confirm-disabled="validationNewTemplate" @confirm="showDialog.edit ? editTemplate() : addTemplate() ")
     app-section
      AppAutocomplete.mt-2.mb-2(v-model="selectFolder" item-text="name" item-value="uuid" :items="folders" dense 
        :label="$t('template.dialog.chooseFolder')") 
      app-text-field(v-model.trim="newTemplateName" :label="$t('template.dialog.templateName')")
    
    app-dialog-confirm( v-model="confirmDialog.template" @confirm="deleteTemplate") 
      app-text {{ $t('template.dialog.deleteTemplate', {name: menuItem.name})}}
    app-dialog-confirm( v-model="confirmDialog.folder" @confirm="deleteFolder") 
      app-text {{ $t('template.dialog.deleteFolder', {name: menuItem.name})}}

</template>
<script>
import ToolbarMenu from '@/components/project/panel/left/floors/ToolbarMenu'
import { templateService } from '@/_services'
import { mapState, mapMutations, mapGetters,mapActions } from 'vuex'
import { AlertService } from '@app/AlertService'

export default {
  name:"TemplateFolders",
  components:{
    ToolbarMenu
    
  },
  data() {
    return {
      fab:false,
      showDialog:{folder:false,template:false,edit:false},
      // folders:[],
      // folderUuid:null,
      newFolderName:null,
      newTemplateName:null,
      selectFolder:null,
      confirmDialog:{template:false,folder:false},
      menuItem:{name:''},
      url: "",
      dialog: {
        template: false,
        selectedTemplate: null,
        templatesList: ["Шаблон1", "Шаблон2"], // TODO: Не забыть поменять
        templateName: null,
      },
    }
  },
  mounted(){
    // this.loadFolders();
    // this.loadTemplates();
    // this.setTest();
  },
  computed:{
    ...mapState('project', ['project']),
    ...mapState('template', ['foldersMap']),
    ...mapGetters('template', ['folders','templates']),

    validationNewTemplate() {
      return !this.newTemplateName 
    },

    validationNewFolder() {
      return !this.newFolderName
    },

    validationtTemplateName() {
      return !this.dialog.templateName 
    },

    inputFieldsValid(){
      return this.newFolderName!=null && this.newFolderName.length > 1
    },
    validForm(){
      return this.newTemplateName!=null && this.newTemplateName.length > 1
    },
    templateMenu() {
      return [
        { title: this.$t('button.edit'), action:()=>this.showTemplateDialog(true)},
        { title: this.$t('button.delete'), action:()=>this.confirmDialog.template=true},
      ];
    },
    folderMenu() {
      return [
        { title: this.$t('button.edit'), action: ()=>this.showFolderDialog(true)},
        { title: this.$t('button.delete'), action:()=>this.confirmDialog.folder=true},
      ];
    }
  },
  methods:{
    ...mapMutations('template', ['setFolderVisible']),
    ...mapActions('template', ['loadFolders','loadTemplates']),

    showFolderDialog(isEdit){
      if(isEdit) {
        this.newFolderName=this.menuItem.name
        this.showDialog.edit=true;
      } else {
        this.newFolderName=null
        this.showDialog.edit=false;
      }
      this.showDialog.folder=true;
    },
    showTemplateDialog(isEdit){
      if(isEdit) {
        this.newTemplateName=this.menuItem.name
        this.showDialog.edit=true;
        this.selectFolder=this.menuItem.folderUuid
      } else {
        this.newTemplateName=null
        this.showDialog.edit=false;
        this.selectFolder=null
      }
      this.showDialog.template=true;
    },
    showSelectTemplateDialog() {
      this.dialog.template = true
      this.dialog.selectTemplate = null
      this.dialog.templateName = null
    },
    createTemplate() {
      console.log("Hi");
    },
    selectTemplate(template){
      this.$emit("templateSelect",template)
    },
    // getList(folderUuid){
    //   return this.templates.filter((el) => el.folderUuid == folderUuid)
    // },
    // folderSort(folderUuid){
    //   return this.templates.filter((template)=>template.folderUuid==folderUuid)
    // },
    contextClick(template,event){
      this.menuItem = template
      this.$refs.templateToolbar.show(event)
    },
    onMenu (folder,event) {
      this.menuItem = folder
      this.$refs.folderToolbar.show(event)
    },
    folderTitleStyle(index) {
      if(index % 2 == 0) {
        return 'background: #F8F8F8'
      } 
      return 'background: #FFFFFF'
    },
    addTemplate(){
      this.showDialog.template=false;
      let template= {
        uuid: null,
        projectUuid: this.project.uuid,
        folderUuid:this.selectFolder,
        name: this.newTemplateName,
        createDate: Date.now(),
        editDate: Date.now()
      }

      templateService.addTemplate(template).then(()=>this.loadTemplates())
      .catch(e => {
        e.response.data.error_description = this.$t('template.error.addTemplate')
        AlertService.error(e.response)
      })
    },
    editTemplate(){
      this.showDialog.template=false;
      templateService.editTemplateNameAndFolder(this.menuItem.uuid,
        {folderUuid:this.selectFolder,name:this.newTemplateName})
          .then(()=>this.loadTemplates())
      .catch(e => {
        e.response.data.error_description = this.$t('template.error.editTemplate')
        AlertService.error(e.response)
      })
    },
    deleteTemplate(){
      templateService.deleteTemplate(this.menuItem.uuid).then(()=>{
        this.loadTemplates()
      }).catch(e => {
        e.response.data.error_description = this.$t('template.error.deleteTemplate')
        AlertService.error(e.response)
      })
    },
    addFolder(){
      this.showDialog.folder=false;
      let folder={
      uuid: null,
      projectUuid: this.project.uuid,
      name: this.newFolderName }
      templateService.addFolder(folder).then(()=>{
        this.loadFolders()
      }).catch(e => {
        e.response.data.error_description = this.$t('template.error.loadFolder')
        AlertService.error(e.response)
      })
    },
    deleteFolder(){
      templateService.deleteFolder(this.menuItem.uuid).then(()=>{
        this.loadFolders()
      }).catch(e => {
        e.response.data.error_description = this.$t('template.error.deleteFolder')
        AlertService.error(e.response)
      })
    },
    editFolder(){
      this.showDialog.folder=false;
      templateService.editFolderName(this.menuItem.uuid,{name:this.newFolderName}).then(()=>{
        this.loadFolders()
      }).catch(e => {
        e.response.data.error_description = this.$t('template.error.editFolder')
        AlertService.error(e.response)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.select-button{
    position: absolute;
    top: 6px;
    right: 6px;
  }
.dialog-input{
    background: #E5E5E5;
    border: none;
    outline: none;
    height: 32px;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 2px;
    color: #4D4D4D;
    font-size: 14px;
    width: 100%;
  }
  .dialog-input::placeholder{
    color: #4D4D4D;
  }
.new-drawing-header{
    color: #757575;
    font-size: 12px;
  }
.addPage{
  border: 1px dashed;
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%,-50%)
}
.folder{
  overflow: auto;
  height: 100%;
}
.t-scroll-body {
  overflow-y: auto;
  height: calc(100% - 35px);
  overflow-x: hidden;
  scrollbar-width: thin;
  width: 100%; 
  padding-bottom: 2px;
}
.drawing-models{
  overflow: auto; 
  position: relative; 
  top: 0px; left: 0px; right: 0px; bottom: 0px; 
  width:100%; 
  height:100%;
  background: #FFFFFF;
  border-radius: 3px;
}
.models{
  width: 100%;
}
.font-weight-bold{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
}
.close-model{
  transform: rotate(0deg);
}
.close-button{
  transform: rotate(180deg);
}
.groups{
  gap: 28px;
}
.preview:hover{
  border-color:#505254 ;
  box-shadow: 0px 1px 10px 3px rgba(0,0,0,0.15);
}
.preview{
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 210px;
  height: 297px;
  border: 1px solid grey;
  border-radius: 3px;
}
.title{
  width: 210px;
  height: 45px;
}
.addFolder{
  position: absolute;
  bottom: 40px;
  right: 40px;
  
}
.dialog-select{
  border-radius: 2px;
}
.icon-anim{
  opacity: 0;
  transition-duration: 0.5s;
}
.pointer:hover .icon-anim{
  opacity: 1;
  transition-duration: 0s;
}
.new-button {
  width: 170px;
}
</style>