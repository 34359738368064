<template lang="pug">
div
  .search-elements-table-body-item--wrap.pointer( v-if="isElementExist" :class="{ 'item--hightlighted' : hightlight }" )
    v-checkbox( v-model="element.selected" hide-details light :ripple="false" @change="$emit('change', { uuid: element.uuid, selected: $event })" )
    v-tooltip( top )
      template( v-slot:activator="{ on, attrs }" )
        .search-elements--text( v-on="on" @click="$emit('click', element)" ) {{ element.name }}
      span.search-elements--tooltip {{ element.name }}
  v-skeleton-loader( v-else type="text" light )
</template>

<script>
import { mapStores } from 'pinia'
import { useSearchElementsStore } from '@/pinia/searchElements.module'

export default ({
  props: {
    element: null,
    pickedElementUuid: null,
  },

  computed: {
    ...mapStores(useSearchElementsStore),

    isElementExist() {
      return this.element.uuid && this.element.name 
    },

    hightlight() {
      return this.element?.uuid == this.pickedElementUuid
    }
  },
})
</script>

<style scoped>
.search-elements-table-body-item--wrap {
  display: flex;
  align-items: center;
}

.search-elements-table-body-item--wrap > .search-elements--text {
  text-align: left;
  font-size: 12px;
  color: var(--v-primary-lighten1);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.item--hightlighted {
  background-color: var(--v-primary-lighten5);
  box-shadow: inset 0px 0px 0px 1px var(--v-primary-lighten4);
}

.search-elements--tooltip {
  font-size: 12px;
}

::v-deep .v-input--selection-controls{
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
