import { defineStore } from 'pinia'
import { fetchSortedGroups, deleteGroupWithUUID, changeGroupPosition } from '@/providers/data/groups.dataProvider'

export const useGroupsStore = defineStore('groups', {
  state: () => ({
    rawList: [],
    prepared: false,
  }),

  actions: {
    async prepareGroups () {
      this.rawList = await fetchSortedGroups()
      this.prepared = true
    },

    async deleteGroupWithUuid (groupUuid) {
      if (!groupUuid) return
      await deleteGroupWithUUID(groupUuid)
      this.rawList = this.rawList.filter(({ uuid }) => uuid !== groupUuid)
    },

    async changeGroupPosition(uuid, to) {
      if (uuid) {
        await changeGroupPosition(uuid, to)
      }
    },

    saveGroup (group) {
      let list = this.rawList.map(rawData => rawData.uuid === group.uuid ? group : rawData)
      if (list.findIndex(item => item.uuid === group.uuid) < 0) {
        list.push(group)
      }
      this.rawList = list
    },
  }
})
