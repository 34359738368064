<template lang="pug">
    app-dialog(v-model="$_show" :header="$t('module.scannerQR.namePosition')" @confirm="method(form)" :confirm-text="$('button.save')")
        app-text-field(v-model="form.name" :label="$t('module.scannerQR.name')")
        v-snackbar.snackbar-save(v-model="successSaveDialog" color="success" timeout="1000" left bottom rounded) {{ $t('module.scannerQR.positionSaved') }}
</template>

<script>

import DialogToggable from '@/components/app/DialogToggable'

export default {
   
    name:"PositionParam",
    mixins: [DialogToggable],
    props:{
        method: Function,
        form: null
    },   
    data() {
        return {
            successSaveDialog: false,
        }
    }

}
</script>

<style lang="scss" scoped>
    .snackbar-save{
        margin-left:120px
    }
</style>