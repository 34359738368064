<template lang="pug">
  v-menu( v-model="showMenu" :position-x="x - 145" :position-y="y" :absolute="isAbsolute" transition="slide-x-transition" offset-y)
    //- template( v-slot:activator="{ on }" )
    //-   v-img.header-icon( v-if="basic" src="@/assets/img/playlist_play.png" :width="iconSize" :height="iconSize" v-on="on" :class="['app-theme--' + themeName]")
    //-   v-img.header-icon( v-else-if="icon" :src="`/img/${icon}.svg`" :width="iconSize" :height="iconSize" v-on="on" :class="['app-theme--' + themeName]")
    //-   v-img.header-icon( v-else-if="svgIcon" :src="`/img/project/tools/delete.svg`" :width="iconSize" :height="iconSize" v-on="on" :class="['app-theme--' + themeName]")
    //-   //- slot( v-bind:on="on" )
    .m-content 
      .m-content-body
        template(v-for="(option, idx) in menu")
          v-divider(v-if="option.divider" light)
          .m-content-text.mx-4.my-2( v-else ripple @click="(event) => handleSelect(event, option, idx)" )
            .m-content-text--disabled( v-if="option.disabled" )
              .m-content-priority.mr-2( v-if="option.color" :style="{ backgroundColor: option.color }")
              template(v-if="option.icon")
                v-icon(color="#505254").mr-2 {{ option.icon }}
              | {{ option.title }}
            .m-content-text--active( v-else )
              .m-content-priority.mr-2( v-if="option.color")
                img(:src="`/img/project/tools/${checkPriority(option)}`")
              template(v-if="option.icon")
                v-icon(color="#505254").mr-2 {{ option.icon }}
              | {{ option.title }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'appDialogWindow',
  props: {
    menu: Array,
    basic: Boolean,
    iconSize: {
      type: Number,
      default: 24
    },
    icon: String,
    svgIcon: String,
    item: null
  },

  data: () => ({ showMenu: false, moving: false, x: 0, y: 0 }),

  computed: {
    ...mapGetters('theme', ['themeName']),

    isAbsolute () {
      return !this.basic && !this.icon
    },

    
  },

  watch: {
    showMenu (newValue) {
      if (!newValue && !this.moving) this.$emit('close')
    },
  },

  methods: {
    handleSelect (event, option, idx) {
      if (!option.disabled) {
        if (option.action) option.action(event, this.item)
        else this.$emit('menu:select', option, idx, this.item)
      }
    },

    show (e) {
      e.preventDefault()
      this.moving = true
      this.showMenu = false
      this.x = e.clientX 
      this.y = e.clientY
      this.$nextTick(() => {
        this.moving = false
        this.showMenu = true
      })
    },

    checkPriority(option){
      switch(option.name){
        case "LOW":
          return "task_priority_light_1.svg"
        case "NORMAL":
          return "task_priority_light_2.svg"
        case "HIGH":
          return "task_priority_light_3.svg"
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 32px;
  display: flex;

  &-icon {
    display: inline-block;
    // margin-right: 8px;
    cursor: pointer;
    flex: 0 1 auto;
  }

  &-text {
    font: bold 14px/24px $roboto;
    vertical-align: top;
    flex: 1 1 auto;
  }
}
.m-content {
  height: 72px;
  border-radius: 0px;
  background-color: white;
  padding: 0px;
  margin-top: -8px;
  font-size: 10px;
}
.m-content-body {
  border-radius: 0px;
  background-color: white;
}
.m-content-priority {
  display: inline-block;
  width: 10px;
  height: 16px;
  font-size: 10px;

  // vertical-align: middle;
}
.m-content-text {
  font: normal 12px/32px $roboto;
  color: #505254;
}
.m-content-text--disabled {
  color: var(--v-primary-lighten4);
  cursor: not-allowed;
}
.m-content-text--active {
  cursor: pointer;
}
</style>

<style>
.v-menu__content {
  border-radius: 0px;
  background-color: #4D4D4D;
}
</style>
