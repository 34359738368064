<template lang="pug">
tbody
  tr(v-if="start > 0")
    td(:colspan="store.visibleHeaders.length" :style="{ paddingTop: startHeight + 'px' }")

  tr(v-for="(item, i) in sliceItems" :key="item.id")
    td(v-for="(header, index) in store.visibleHeaders" :key="index")
      .task-tree-row-theme.d-flex( :style="treeStyle(header.value, item.level)" :class="{'justify-center' : !isStructure(header.value)}")
        .expand-btn(v-if="item.children.length && isStructure(header.value)" @click="open(item)")
          v-icon(color='#4D4D4D' size='small' ) {{ isOpen(item) ? "mdi-menu-down" : "mdi-menu-right" }} 
        .ml-1.font-weight-bold(v-if="isStructure(header.value) && checkLevel(item)") {{ getAxisMeasure(item.level) }}
        .ml-1.text-ellipsis(v-if="header.value != 'color'") {{ taskTreeItem(item, header.value) }}
        .group_color(v-else :style="{ background: sliceItems[i].color }") 

  tr(v-if="start + sliceItems.length < store.treeAllVisibleGroupingNodes.length")
    td(:colspan="store.visibleHeaders.length" :style="{ paddingBottom: endHeight + 'px' }")
</template>

<script>
import { useAxisGroupTableStore } from '@/pinia'

export default {
  props: {
    sliceItems: Array,
    start: Number,
    groupColor: String,
    startHeight: Number,
    endHeight: Number,
  },

  computed: {
    store: () => useAxisGroupTableStore(),
  },

  methods: {
    checkLevel(item) {
      return item.level < this.store.groupingTableAxisMeasure.length
    },

    isStructure(column) {
      return column === 'oname'
    },

    treeStyle(column, itemLevel) {
      return { paddingLeft: column === 'oname' ? itemLevel * 20 + 'px' : '' }
    },

    getAxisMeasure(level) {
      return this.store.groupingTableAxisMeasure[level].axis.title + ':'
    },

    isOpen(item) {
      return this.store.isTreeNodeDisclosed(item)
    },

    taskTreeItem(item, column) {
      if (this.isStructure(column)) return !item.oname ? item.elementClassTitle : item[column]
      if (column.includes('attribute')) {
        if (this.checkLevel(item)) return ''
        return this.store.getItemSelectedAttribute(item, column.replace(/^attribute\./, ''))
      }
      const value = column.split('.').reduce((acc, part) => acc && acc[part], item)
      return value !== undefined ? value : ''
    },

    open(item) {
      this.store.toggleDisclosedNode(item)
      this.$emit('updateAxisList', this.store.isTreeNodeDisclosed(item))
    },
  },
}
</script>

<style scoped>
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group_color {
  border-radius: 20px;
  border: 0.5px solid #535353;
  height: 25px;
  width: 85%;
}
</style>
