<template lang='pug'>
  app-dialog(v-model='show' width='650')
    div.d-flex
      app-section.r-list-section
        
        v-progress-linear(v-if='isLoading' indeterminate color='accent')
        div.mt-1(v-else)

        receivers-list(:list='receivers' :selected='selectedReceiver' @action='handleReceiverAction($event)')
        
        div.d-flex
          v-btn.ml-auto(color='accent' icon small @click='createNewReceiver()')  
            v-icon mdi-plus
      
      app-section.r-details-section.ml-2
        div.mt-1
          div(v-if='!selectedReceiver') {{ $t('module.mnemoschemas.receivers.chooseReceiverForViewingInfo') }}
          div(v-else) {{ selectedReceiver.name }}

    receiver-form-dialog(v-model='showReceiverFormDialog' :receiver.sync='actionReceiver' @save='updateReceiver(actionReceiver)')
    app-dialog-confirm(v-model='showDeleteReceiverDialog' delete @confirm='removeReceiver(actionReceiver)') {{ $t('module.mnemoschemas.receivers.confirmReceiverDelete', {receiverTitle: actionReceiver && actionReceiver.name}) }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReceiversList from './recievers/ReceiversList'
import ReceiverFormDialog from './recievers/ReceiverFormDialog'

const defaultReceiver = {
  name: '',
  project: ''
}

export default {
  name: "ReceiversDialog",

  components: {
    ReceiversList,
    ReceiverFormDialog,
  },

  props: ["value"],

  provide() {

  },

  data: () => ({
    receivers: [],

    actionReceiver: null,
    
    showReceiverFormDialog: false,
    showDeleteReceiverDialog: false,

    isLoading: false,

    selectedReceiver: null,
  }),

  beforeMount() {
    this.updateReceiversList()
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),

    show: {
      get() {
        this.value && this.updateReceiversList()
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    ...mapActions('receiver', ['getReceiversList', 'deleteReceiver', 'saveReceiver']),

    handleReceiverAction($event) {
      this.actionReceiver = { ...$event.receiver }
      
      switch ($event.type) {
        case 'edit': {
          this.showReceiverFormDialog = true
          break
        }
        case 'delete': {
          this.showDeleteReceiverDialog = true
          break
        }
        case 'select': {
          this.selectedReceiver = $event.receiver === this.selectedReceiver ? null : $event.receiver
          break
        }

        default: {
          console.log($event.type, $event.receiver)
        }
      }
    },

    updateReceiver(receiver) {
      this.isLoading = true
      this.saveReceiver(receiver).then((response) => {
        
        if (response.data.uuid === this.selectedReceiver?.uuid) {
          this.selectedReceiver = response.data
        }

        this.updateReceiversList()
      })
    },

    removeReceiver(receiver) {
      this.isLoading = true

      if (receiver.uuid === this.selectedReceiver?.uuid) {
        this.selectedReceiver = null
      }

      this.deleteReceiver(receiver).then(() => {
        this.updateReceiversList()
      })
    },

    createNewReceiver() {
      this.actionReceiver = { ...defaultReceiver, project: this.projectUuid }
      this.showReceiverFormDialog = true
    },

    updateReceiversList() {
      this.isLoading = true
      this.getReceiversList(this.projectUuid).then((response) => {
        this.receivers = response.data
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>
  .r-list-section {
    flex: 1;
    height: 250px;
    overflow: hidden auto;
  }

  .r-details-section {
    flex: 1;
    color: var(--v-surface-base);
  }

  .r-form-section {
    flex: 1;
    width: 232px;
  }
</style>