<template lang="pug">
  div
    app-dialog(v-model="$_show" :header="$t('registration.title')" :width="( noDialog ? 400 : 270)" @close="handleClose" :closeButtonHidden="(noDialog ? true : false)" :hideOverlay="(noDialog ? true : false)" complex)
      social-button(@click.native="register" ) {{ $t('registration.button') }}
      social-button.my-2(@click.native="login" ) Вход через социальные сети

    AccessRegistation(v-model="dialog.register" @close="handleCloseFormRegistration" :noDialog="noDialog")
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import Registation from '@/components/access/Registation.vue'
import SocialButton from '@/components/social/SocialButton.vue'
import { authUserService } from "@/_services";


export default {
  name: 'BeforeRegistration',
  components: {

    [Registation.name]: Registation,
    SocialButton
  },
  props: {
    noDialog: {
      default: false,
      type: Boolean
    },
  },
  mixins: [DialogToggable],
  data () {
    return {
      dialog: {
        register: false
      }
    }
  },

  methods: {
    register () {
      this.dialog.register = true
      this.$_show = false
    },

    login () {
      authUserService.redirectLogin()
    },

    handleClose() {
        this.$emit('close')
    },

    handleCloseFormRegistration () {
      this.$_show = true
      this.$emit('close')
    }
  },
  
}
</script>