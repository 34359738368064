<template lang="pug">
  div
    app-hover-item.mt-1.collisiions-unit( 
      v-for="gr in collisionGroups"
      backgroundColor="white"
      :hoverHeight='24'
      :shadow="false"
      :closeDelayHover="0" 
      :key="gr.uuid" 
      :item="gr" 
      :selected="selectedGroupUuid == gr.uuid" 
      @select="selectGroup(gr)" 
      @menu="showMenu" 
      )
  
    app-menu( ref="groupMenu" :menu="groupMenu" )

    app-dialog-confirm( v-if="groupInWIP" v-model="dialog.allow" @confirm="onAllowingGroup") 
      | {{ $t('section.collision.groupMenu.allow', {group: groupInWIP.title}) }}
      app-section
        app-text-field( v-model="note" :label="$t('section.collision.allowNote')" )

    app-dialog-confirm( v-if="groupInWIP" v-model="dialog.delete" delete @confirm="onDeletingGroup") {{ $t('section.elementTree.delGroup', {group: groupInWIP.title}) }}
    
    app-dialog( v-model="dialog.edit" :header="$t('section.collision.editGroup')" :confirmDisabled='!groupName' :confirm-text="$t('button.save')"  @confirm="onSavingGroup" )
      app-section
        app-text-field( v-model="groupName" :label="$t('section.collision.nameGroup')" )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppHoverItem from '@/components/project/common/HoverItem.vue'

export default {
  data () {
    return {
      groupInWIP: null,
      groupName: '',
      note: '',
      dialog: {
        edit: false,
        delete: false,
        allow: false,
      }
    }
  },

  components: {
    AppHoverItem
  },

  computed: {
    // ...mapGetters('collision/group', ['collisionGroups']),
    ...mapGetters('collision/search', ['selectedGroupUuid']),

    groupMenu () {
      return [
        { title: this.$t('section.collision.groupMenu.view'), action: this.showOnlyGroupCollisions },
        { title: this.$t('section.collision.groupMenu.allow'), action: this.showAllowGroupDialog },
        { title: this.$t('button.edit'), action: this.showEditGroupDialog },
        { title: this.$t('button.delete'), action: this.showDeleteGroupDialog },
      ]
    },
  },

  mounted() {
    // this.$store.dispatch('collision/group/init')
  },

  methods: {
    // ...mapActions('collision/group', ['deleteGroup', 'editGroup', 'allowGroup']),
    ...mapActions('collision/search', ['selectGroup', 'showGroupOnly']),

    showMenu (item, event) {
      this.groupInWIP = item
      this.$refs.groupMenu.show(event)
    },

    showOnlyGroupCollisions () {
      this.showGroupOnly(this.groupInWIP)
    },

    showEditGroupDialog () {
      this.groupName = this.groupInWIP.title
      this.dialog.edit = true
    },

    showDeleteGroupDialog () {
      this.dialog.delete = true
    },

    showAllowGroupDialog () {
      this.dialog.allow = true
    },

    onDeletingGroup () {
      this.deleteGroup(this.groupInWIP)
    },

    onSavingGroup () {
      this.editGroup({ uuid: this.groupInWIP.uuid, title: this.groupName })
    },

    onAllowingGroup () {
      this.allowGroup({uuid: this.groupInWIP.uuid, note: this.note})
    }
  }
}
</script>