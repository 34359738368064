<template lang="pug">
  app-panel.px-1.py-2( width="256" style="overflow:hidden" )
    app-panel-header( :title="$t('profile.settings.title')" hideClose)
    action-profile(isPanel)
</template>

<script>
import ActionProfile from '@/components/access/ActionProfile' 

export default {
  name: 'ProfileSettings',

  components: {
    ActionProfile,
  }

}
  
</script>

<style lang="scss" scoped>

</style>