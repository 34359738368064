<template lang="pug">
div
  .bottom-table
    .inline.coordinates
      bim-cursor-navigation
      bim-speed-control.interactive.inline( v-if='mouseNav === "professional"' )
      bim-axis-gizmo-widget.interactive.inline.gizmo.under-all
      bim-slider-widget.interactive( v-if="$app.device.isPortable" )
    collision-list.interactive( v-if="activeGlobalTab === 'collision' && !attributeChecking" )
    groupings-list.interactive( v-if="activeGlobalTab === 'groupTable' && groupingStore.groupsTableActive" )
    attribute-checking-table.interactive( v-if="activeGlobalTab === 'collision' && attributeChecking" )
  .bottom-table
    plugin-result-table.interactive( v-if="activeGlobalTab === 'plugins' || activeGlobalTab === 'reports'")
  .drawing__info_transform( :style="isDrawingPanelOpen" )
    drawing-anchor-mode-panel.interactive( v-if="isDrawingSettingMode" )
    .surface.pa-2( v-if="isDrawing3DEditMode" )
      app-section
        drawing-transform-setting-panel.interactive
</template>

<script>
import PluginResultTable from '@/components/project/panel/left/plugin/PluginResultTable.vue'
import CollisionList from '@/components/project/panel/left/CollisionList.vue'
import AttributeCheckingTable from '@/components/project/panel/left/components/attributeChecking/AttributeCheckingTable.vue'
import BimCursorNavigation from '@/components/project/bim/BimCursorNavigation.vue'
import BimAxisGizmoWidget from '@/components/project/bim/BimAxisGizmoWidget.vue'
import BimSliderWidget from '@/components/project/bim/BimSliderWidget.vue'
import BimSpeedControl from '@/components/project/bim/BimSpeedControl.vue'
import DrawingAnchorModePanel from '@/components/drawings/cropping/DrawingAnchorModePanel.vue'
import DrawingTransformSettingPanel from '@/components/drawings/DrawingTransformSettingPanel.vue'

import { mapState } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import GroupingsList from '../panel/left/components/element/elementsGroupsTable/GroupingsList.vue'
import { useDrawingCroppingStore, useDrawingTransformSettingState, useAxisGroupTableStore } from '@/pinia'

export default {
  name: 'ProjectLayerBottom',

  components: {
    PluginResultTable,
    CollisionList,
    BimAxisGizmoWidget,
    BimSliderWidget,
    BimCursorNavigation,
    BimSpeedControl,
    AttributeCheckingTable,
    GroupingsList,
    DrawingAnchorModePanel,
    DrawingTransformSettingPanel,
  },

  computed: {
    ...mapState('project', ['activeGlobalTab', 'attributeChecking']),

    groupingStore: () => useAxisGroupTableStore(),

    drawing3DTransformState: () => useDrawingTransformSettingState(),

    mouseNav() {
      return XeokitMediator.MouseNavigation.mouseNav
    },

    isDrawingSettingMode() {
      return useDrawingCroppingStore().isDrawingSettingMode
    },

    isDrawing3DEditMode() {
      // const isEditMode = this.drawing3DTransformState.isEditMode
      // if (isEditMode) {
      //   this.drawing3DTransformState.setEditedImageSectionPlaneState()
      // }
      return this.drawing3DTransformState.isEditMode
    },

    isDrawingPanelOpen() {
      if (this.activeGlobalTab == 'drawings') {
        return {
          left: 'calc(var(--left-panel-width) + 10%)',
        }
      }
      return {
        left: '35vw',
      }
    },
  },
}
</script>

<style scoped>
.bottom-table {
  display: inline-block;
  pointer-events: none;
  position: absolute;
  left: calc(var(--left-panel-width) + 10px);
  bottom: 5px;
  right: calc(var(--right-panel-width) + 25px);
}

.drawing__info_transform {
  display: inline-block;
  pointer-events: none;
  position: absolute;
  bottom: 5px;
  width: 450px;
}

.coordinates {
  white-space: nowrap;
  position: relative;
  bottom: 50px;
  left: 30px;
  width: 100px;
  height: 100px;
}

.interactive {
  pointer-events: all;
}

.gizmo {
  position: relative;
  bottom: 20px;
}

.under-all {
  z-index: -1000;
}
</style>
