<template lang='pug'>
  div.loading-overlay(v-if='show || showPointCloud' :style='marginBottomStyle')
    div.surface.base.primary--text.text--darken-2.pa-2.elevation-5.rounded(style='width: 350px;' test-id="project-loading")
      div(v-if='show').d-flex.align-center 
        strong.mr-3 <span> Моделей загружено: </span>
        strong <span class='accent--text'>{{ loadedModelsCount }}</span>/{{switchonModelsCount}}
        strong.ml-2.project-loading--title {{ currentModel && $t(currentModel.title) }}
        v-progress-circular.ml-auto(color='accent' indeterminate size='16' width='2')
      
      div(v-if='show')
        div.mt-1
          v-progress-linear(:value='progress' height='6' color='accent' background-color='#D9D9D9')
        
        div.mt-1
          span(v-if='rendering') Отображение
          span(v-else-if='processing') Обработка
          span(v-else) Загружено {{ bytes }} {{ totalBytes ? `из ${totalBytes}` : '' }}

        div(v-if='showPointCloud').mt-8

      div(v-if='showPointCloud') 
        div.d-flex.align-center
          strong.mr-3 <span> Облаков загружено: </span>
          strong <span class='accent--text'>{{ loadedModelsCloudCount }}</span>/{{switchonModelsCloudCount}}
          strong.ml-2 {{ currentCloudModel && $t(currentCloudModel.title) }}
          v-progress-circular.ml-auto(color='accent' indeterminate size='16' width='2')

        div.mt-1
          v-progress-linear(:value='progressCloud' height='6' color='accent' background-color='#D9D9D9')

        div.mt-1
          span(v-if='renderingCloud') Отображение
          span(v-else) Загружено {{ bytesCloud }} {{ totalBytesCloud ? `из ${totalBytesCloud}` : '' }} 
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { mapGetters, mapState } from 'vuex'
import { byteFormatter } from '@/utils'

export default {
  name: "ProjectLoadingProgress",
  data: () => ({
    // loading: {
    //   currentModel: {
    //     title: "",
    //     index: 0
    //   },
    //   totalModels: 0,
    //   progress: 0,
    //   done: false,
    //   processing: true,

    //   loaded: 0,
    //   totalSize: 0
    // },
    activeModel: 0,
    activeModelCloud: 0,
    show: false,
    showPointCloud: false,
    currentModel: null,
    currentCloudModel: null,
    bytes: null,
    bytesCloud: null,
    totalBytes: null,
    totalBytesCloud: null,
    progress: null,
    progressCloud: null,
    loadedModelsCount: 0,
    activeCloudModel: null,
    loadedModelsCloudCount: 0,
    countCloudModels: null,
    uuidCloudModel: [],
    sceneCloudModels: [],

    pointCloudsLoading: false,
    processing: false,
    processingCloud: false,
    rendering: false,
    renderingCloud: false,
    loader: null,
    downloadPointCloud: false,
    openPointCloud: false,
  }),

  mounted() {
    this.configureLASLoader()
    this.configureXKTLoader()
  },
  
  updated() {
    this.activeModel = Object.values(this.projectSettings.workModel).filter(modelSetting => modelSetting.switchon && modelSetting.revisionUuid).length + this.patchRevList.length
    this.activeModelCloud = Object.values(this.projectSettings.workModel).filter(modelSetting => modelSetting.switchon && modelSetting.revisionUuid).length + this.patchRevList.length
  },

  watch: {
    loadedModelsCount() {
      this.loadedModelsCount = (this.loadedModelsCount > this.switchonModelsCount) ? this.loadedModelsCount - 1 : this.loadedModelsCount
    },

    activeModel() {
      if (this.loadedModelsCount >  this.switchonModelsCount) {
        this.loadedModelsCount--
        this.loadedModelsCloudCount--
      } 
    },

    activeModelCloud() {
      if (this.loadedModelsCloudCount >  this.loadedModelsCloudCount) {
        this.loadedModelsCount--
      } 
    }
  },

  computed: {
    ...mapState('layoutControler', ['collisionTable']),
    ...mapState('project', ['projectSettings', 'patchRevList']),
    ...mapGetters('project', ['flatlist']),

    marginBottomStyle() {
      let collisionTableHeight = 450 // init height of collision table
      return {
        bottom: this.collisionTable.active ? collisionTableHeight - 24 + 'px' : '24px'
      }
    },

    switchonModelsCount() {
      return Object.values(this.projectSettings.workModel).filter(modelSetting => modelSetting.switchon && modelSetting.revisionUuid).length + this.patchRevList.length
    },

    switchonModelsCloudCount() {
      let workModel = this.projectSettings.workModel
      return Object.keys(this.projectSettings.workModel).filter(modelUUID => this.uuidCloudModel.find(uuid => uuid == modelUUID) && workModel[modelUUID].switchon).length
    }
  },

  methods: {
    configureLASLoader() {
      XeokitMediator.lasLoader.on("pointCloudLoadingStart", () => {
        this.showPointCloud = true
        this.progressCloud = 0
        this.currentCloudModel = null
      })

      XeokitMediator.lasLoader.on("counterCloudModel", (uuid) => {
        this.uuidCloudModel.push(uuid)
      })

      XeokitMediator.lasLoader.on("pointCloudLoadingDone", () => {
        this.showPointCloud = false
      })

      XeokitMediator.lasLoader.on('renderCloudStart', () => {
        this.renderingCloud = true
        this.progressCloud = 0
      })

      XeokitMediator.lasLoader.on('renderCloudDown', () => {
        this.renderingCloud = false
        this.loadedModelsCloudCount++
      })

      XeokitMediator.lasLoader.on('processingCloud', () => {
        this.processingCloud = true
      })

      XeokitMediator.lasLoader.on('countVisibleCloudModel', () => {
        this.loadedModelsCloudCount++
      })
      
      XeokitMediator.lasLoader.on('resetCountVisibleCloudModel', () => {
        this.loadedModelsCloudCount = -1
      })

      XeokitMediator.lasLoader.on('deletePointCloud', () => {
        this.showPointCloud = false
        this.downloadPointCloud = false
        this.processingCloud = false
        this.loadedModelsCloudCount--
      })

      XeokitMediator.lasLoader.on('receivedBytesLengthCloud', (value) => {
        this.showPointCloud = true
        this.downloadPointCloud = true
        this.processingCloud = false

        const modelId = value.modelId 
        this.bytesCloud = byteFormatter(value.bytes)

        let sizeCloudModel = null
        this.flatlist.forEach((item) => {
          if(item?.pointCloud?.filesize) {
            sizeCloudModel = item.pointCloud.filesize
          }
        })

        const wm = this.flatlist.find(model => model?.pointCloud?.uuid === modelId)
        const modelTitle = wm?.title ? (wm?.title) : ''
        
        let bytesLas = sizeCloudModel ? Math.floor(sizeCloudModel * 1024 * 1024 * 1024) : 0

        this.totalBytesCloud = byteFormatter(sizeCloudModel * 1024 * 1024 * 1024)
        this.progressCloud = (value.bytes / bytesLas * 100) || 0

        if(this.progressCloud > 99) {
          this.renderingCloud = true
          this.progressCloud = 0
        }

        if (this.currentCloudModel?.uuid === modelId) {
          return
        }

        this.currentCloudModel = {
          title: modelTitle,
          uuid: modelId        
        }
      })

      XeokitMediator.lasLoader.on('progressRenderCloud', () => {
        this.renderingCloud = true
        this.progressCloud = this.progressCloud + 20
      })

      XeokitMediator.lasLoader.on('chunkParseCloud', (value) => {
        this.progressCloud = value.current / value.total * 100
      })

    },
    configureXKTLoader() {
      XeokitMediator.xktLoader.on('loadingDone', () => {
        this.show = false
      })
      XeokitMediator.xktLoader.on('loadingStart', () => {
        this.show = true
        this.progress = 0
        this.currentModel = null
      })

      XeokitMediator.xktLoader.on('processing', () => {
        this.processing = true
      })

      XeokitMediator.xktLoader.on('renderStart', () => {
        this.rendering = true
      })

      XeokitMediator.xktLoader.on('renderDone', () => {
        this.rendering = false
        this.loadedModelsCount++
      })

      XeokitMediator.xktLoader.on('receivedBytesLength', (value) => {
        this.processing = false

        const modelId = value.modelId
        this.bytes = byteFormatter(value.bytes)

        this.progress = (value.bytes / this.totalBytesNum * 100) || 0

        if (this.currentModel?.uuid === modelId) {
          return
        }

        this.progress = 0
        
        const wm = Object.values(this.projectSettings.workModel).find(modelSetting => modelSetting.revisionUuid === modelId)
        const model = this.flatlist.find(model => model.uuid === wm?.modelUuid)
        const modelTitle = model?.title
        const convexHull = wm?.convexHull
        
        const revision = model?.revision
        const revisionSize = convexHull ? revision?.modelRevisionPassport?.convexHullFileSize : revision?.modelRevisionPassport?.xktFileSize
        this.totalBytes = revisionSize ? byteFormatter(revisionSize * 1024 * 1024) : ''
        this.totalBytesNum = revisionSize ? Math.floor(revisionSize * 1024 * 1024) : 0

        this.currentModel = {
          title: modelTitle,
          uuid: modelId        
        }
      })

      XeokitMediator.xktLoader.on('chunkParse', (value) => {
        this.progress = value.current / value.total * 100
      })

      XeokitMediator.loadingProgress.on('update', (newProgress) => {
        this.loading.currentModel = newProgress.currentModel
        this.loading.totalModels = newProgress.totalModels
        this.loading.progress = newProgress.progress
        this.loading.done = newProgress.done
        this.loading.processing = newProgress.processing

        this.loading.loaded = byteFormatter(newProgress.loaded)
        this.loading.totalSize = newProgress.totalSize ? byteFormatter(newProgress.totalSize) : ''
      })
    }
  }
}
</script>

<style lang='scss' scoped>
  .loading-overlay {
    width: calc(100vw - var(--right-panel-width) - var(--left-panel-width));
    min-width: 150px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;
    bottom: 24px;
    right: var(--right-panel-width);
    left: var(--left-panel-width);
  }

  .loading-inner-content {
    background: var(--v-surface-base);
    color: var(--v-surface-lighten3);
    margin: 0 20px;
    padding: 8px;
    width: 350px;
    max-width: calc(100% - 40px);
    pointer-events: all;
  }

  .model-title {
    flex: 1;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .circle-loading-active {
    width: 18px;
    height: 18px;

    border-radius: 50%;
    border: 2px dashed var(--v-accent-base);
    
    animation-name: spinner;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }

  .loading {
    background-color: var(--v-surface-base);
    color: var(--v-primary-darken2);
  }

  .circle-loading-disactive {
    width: 18px;
    height: 18px;

    border-radius: 50%;
    border: 2px solid var(--v-primary-lighten2);
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .project-loading--title {
    word-wrap: break-word;
    max-width: 100px;
  }
</style>