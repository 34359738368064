<template lang="pug">
div( :style="isAxisGroupsSelected ? 'height: 100%' : 'height: auto'" )
    slot( v-if="$slots.prepend" name="prepend" )
    div.axis-section-container
      app-section-header-tabs.mb-1( :items="fixedAxisGroup" :value="selectedAxis.value" @select="onSelect" )

      .add-group-btn.d-flex.justify-space-between( v-if='isAxisGroupsSelected' )
        v-btn.dict-button.px-1( color="accent" dense text x-small test-id="tree-add-group" @click="handleAddingAxisGroup()" )
          v-icon( color="accent" size="16" ) mdi-plus
          | {{ $t('section.elementTree.menu.addGroup') }}
        //- v-btn.dict-button.px-1(v-if="hasCollisionView" color="accent" dense text x-small test-id="tree-color-group" @click="colorize()" )
          //- v-icon( color="accent" size="16" ) mdi-format-color-fill
          //- | {{ $t('section.elementTree.colorize') }}

      div(v-if='isAxisGroupsSelected').axis-group-section-container
        //- .selected-axis-group.mt-1.mb-2( v-if='selectedAxisGroup' )
          app-hover-item( v-bind='selectedAxle' prevent selected @menu='onMenu' test-id="tree-group-item-selected" )
            template(v-slot:preTitle v-if="selectedAxle && selectedAxle.item.data.colorize")
              div(:style="swatchStyle(selectedAxle.item.data.color)")
          axis-section-treeview

        .axis-section-groups.base-scroll-body
          app-button-add.mx-1.my-1( v-if='preparedAxisGroups.length === 0' :text='$t("section.elementTree.menu.addGroup")' width='100%' @click='handleAddingAxisGroup()' )
          app-hover-item.my-1( v-for='(axle, index) in preparedAxisGroups' :key='index' v-bind='axle' :class="{'selected-one-item': axle.selected }" prevent @menu='onMenu' test-id="tree-group-item" @select='handleSelectGroup(axle)' )
            template( v-slot:preTitle )
              v-icon( :class="{'closed-one-item': !axle.selected }" light small ) arrow_drop_down
              div( v-if="axle && axle.item.data.colorize" :style="swatchStyle(axle.item.data.color)" )
            template(v-slot:postTitle)
              v-icon.item__share(v-if="axle && !showShare && hasProjectGroupsSharedAccess" :color="axle.item.data.shared ? 'primary' : '#A0A4A8'" size="18" @click.stop="onShare(axle)" test-id="axis-shared") {{axle.item.data.shared ? 'link' : 'share'}}
              v-icon.item__table(@click.stop="showGroupsTable(axle)") mdi-table-arrow-right
          axis-section-treeview.panel-scroll-list( v-if='true' )
      slot
    
    slot( v-if="$slots.append" name="append" )
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import AxisSectionTreeview from './AxisSectionTreeview.vue'
import AppHoverItem from '@/components/project/common/HoverItem.vue'
import { useAxisGroupTableStore } from '@/pinia'

export default {
  components: { AxisSectionTreeview, AppHoverItem },

  data() {
    return {
      slotPosition: 0,
    }
  },

  props: {
    showShare: Boolean,
  },

  computed: {
    ...mapGetters('axis', ['fixedAxisGroup', 'customAxisGroup', 'selectedAxis']),
    ...mapGetters('projectPermissions', ['hasCollisionView', 'hasProjectGroupsSharedAccess']),
    ...mapState('axis', ['selectedAxisGroup']),
    ...mapState('project', ['activeGlobalTab']),

    store: () => useAxisGroupTableStore(),

    preparedAxisGroups() {
      //if (this.selectedAxisGroup) return [this.mapToCustomItemAttrs(this.selectedAxisGroup)]
      if (this.selectedAxisGroup)
        return this.customAxisGroup.filter((group) => group.data.uuid == this.selectedAxisGroup?.data.uuid).map(this.mapToCustomItemAttrs)
      return this.customAxisGroup
        .filter((group) => group || group.data.uuid !== this.selectedAxisGroup?.data.uuid)
        .map(this.mapToCustomItemAttrs)
    },

    selectedAxle() {
      return this.mapToCustomItemAttrs(this.selectedAxisGroup)
    },

    preAxis() {
      return this.customAxisGroup.slice(0, this.slotPosition).map(this.mapToCustomItemAttrs)
    },

    postAxis() {
      return this.customAxisGroup.slice(this.slotPosition).map(this.mapToCustomItemAttrs)
    },

    isAxisGroupsSelected() {
      return this.selectedAxis.value == 5
    },
  },

  beforeDestroy() {
    this.selectAxisGroup(null)
  },

  methods: {
    ...mapActions('axis', ['selectAxis', 'selectAxisGroup', 'colorize', 'colorizeItemsByGroup']),
    ...mapActions('axis/tree', ['expandAllTreeNodes']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    showGroupsTable(axle) {
      this.setActiveGlobalTab('groupTable')

      const openTable = () => {
        this.store.groupsTableActive = true
        this.store.selectedAxisGroupTable = axle?.item
        this.store.getAxisTreeFromTable(axle.item.data.uuid)
      }

      if (this.selectedAxisGroup) {
        if (
          !this.store.groupsTableActive ||
          (this.store.groupsTableActive && this.store.selectedAxisGroupTable?.value != axle.item.data.uuid)
        ) {
          openTable()
        } else this.store.groupsTableActive = false
      } else {
        this.handleSelectGroup(axle)
        openTable()
      }
    },

    mapToCustomItemAttrs(item) {
      return { item, selected: item.isEqual(this.selectedAxisGroup) }
    },

    onMenu(item, event) {
      this.$emit('menu', item, event)
    },

    onShare(item, event) {
      if (this.prevent) {
        event.stopPropagation()
        event.preventDefault()
      }

      this.$emit('share', item.item.data)

      // item.item.data.shared = !item.item.data.shared
    },

    onSelect(item) {
      let repeated = item.isEqual(this.selectedAxis)
      repeated || this.selectAxis(item)
      !repeated && this.selectAxisGroup(null)
    },

    handleSelectGroup(group, postAxis = true) {
      if (this.selectedAxisGroup?.value == group.item.value) {
        this.selectAxisGroup(null)
        this.colorize()
      } else {
        let selectedGroup = this.customAxisGroup.find((axis_group) => axis_group.data.uuid === group.item.value)
        this.selectAxisGroup(selectedGroup)
        this.colorizeItemsByGroup(group)
      }

      // TODO Старая реализация, оставил для сравнения
      postAxis
      // if (postAxis) {
      //   this.slotPosition = index + 1 + this.preAxis.length
      //   selectedGroup = this.customAxisGroup[this.slotPosition - 1]
      // }
      // else {
      //   this.slotPosition = index + 1
      //   selectedGroup = this.customAxisGroup[this.slotPosition - 1]
      // }
    },

    handleAddingAxisGroup() {
      this.$emit('addingaxisgroup')
    },

    swatchStyle(color) {
      return {
        display: 'inline-block',
        verticalAlign: 'top',
        backgroundColor: color,
        height: '16px',
        width: '16px',
        borderRadius: '4px',
        marginRight: '5px',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.post-axis-section-groups,
.pre-axis-section-groups,
.axis-section-groups {
  height: 100%;
  max-height: 100% !important;
  overflow-y: auto;
}

.less-section {
  max-height: 10vh;
}

.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;

  background: white;
}

.add-group-btn {
  position: sticky;
  top: 0px;
  z-index: 1;

  width: 100%;
}

.selected-axis-group {
  padding: 4px;

  box-shadow: 0px 0px 12px 0px rgba(34, 60, 80, 0.2);
  border-radius: 3px;
}

.axis-section-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.axis-group-section-container {
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
}

.closed-one-item {
  transform: rotate(-90deg);
}

.item__share {
  position: absolute;
  right: 26px;
  top: 8px;
  cursor: pointer;
  color: rgb(160, 164, 168);
}

.item__table {
  position: absolute;
  right: 52px;
  top: 5px;
  cursor: pointer;
  color: rgb(160, 164, 168);
}

.panel-scroll-list {
  width: 100%;
}
</style>
