<template lang="pug">
  app-dialog( v-model="visible" :header="$t('module.task.create')" :confirm-text="$t('button.save')" :confirm-disabled="validation" @confirm="createTaskWithCollisions" )
    app-section
      app-text-field( v-model="name" :label="$t('module.task.form.title')" test-id="collision-create-task-input" )
</template>

<script>
  import { api } from '@/api'
  import { mapGetters } from 'vuex'
  import { TaskScreenshot } from '@/components/task/taskScreenshot'

  export default {
    data() {
      return {
        visible: false,
        single: false,
        name: '',
        selectedRow: null,
      }
    },

    computed: {
      ...mapGetters('collision/table', ['selectedCollisionsUuid', 'selectedCollisions']),

      validation() {
        return !this.name.trim()
      }
    },

    methods: {

      show({ single, item = null }) {
        this.name = ''
        this.single = single
        this.selectedRow = item
        this.visible = true
      },

      createTaskWithCollisions() {
        let list = this.single ? [this.selectedRow.uuid] : this.selectedCollisionsUuid
        api.tasks.createTaskWithAttachCollisions(this.name, list, TaskScreenshot.createTaskScreenshotSourceData())
      },
    },
  }
</script>
