<template lang="pug">
.layout( ref="drawing-cropping-layout" id="drawingCroppingLayout" )
  CroppingRectangle( ref="drawing-cropping-rectanlge" )
</template>

<script>
import { useDrawingCroppingState } from '@/pinia';
import CroppingRectangle from '@/components/drawings/cropping/croppingRectangle/CroppingRectangle.vue'

export default {
  components: {
    CroppingRectangle,
  },

  computed: {
    state: () => useDrawingCroppingState(),
  },

  mounted() {
    this.state.activateCroppingRectangle(this.$refs["drawing-cropping-rectanlge"].$el, this.$el) 
  },

  beforeDestroy() {
    this.state.deactivateCroppingRectangle()
    this.state.setCroppingMode(false)
  },
}
</script>

<style scoped>
.layout {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  user-select: none;
}
</style>