<template lang="pug">
v-hover( #default="{hover}" )
  .d-flex.flex-column.px-2
      .d-flex
        span {{ drawing.name }}  
        //- Управление мнемосхемой
        transition(name='contol-button')
          div.ml-auto
            v-btn(color="#C4C4C4" @click='showMnemoschemasControlMenu' small icon)
              v-icon mdi-dots-vertical
            app-menu(ref="mnemoschemasControl" :menu='mnemoschemasSettings')
        //- Удаление чертежа
        app-dialog(v-model='deleteMnemoschemaDialog.show')
          app-section.black--text(v-if='deleteMnemoschemaDialog.show')
            span {{ $t('module.mnemoschemas.deleteMnemonic') }}
          template(v-slot:footer)
            .d-flex.mt-3
              v-btn.ml-auto(color='accent' small @click='deleteMnemoschema()') {{ $t('button.continue') }}
</template>

<script>
import { Drawing } from '@/assets/model/drawing/Drawing'
export default {
  props: {
    drawing: {
      type: Drawing,
      required: true
    }
  },

  data(){
    return {
      deleteMnemoschemaDialog: {
        show: false
      }
    }
    
  },

  computed: {
    mnemoschemasSettings () {
      return [
        { title: this.$t("button.edit"), icon: "mdi-pencil-outline", action: this.dev },
        { title: this.$t("button.delete"), icon: "mdi-delete-outline", action: this.showDeleteMnemoschemaDialog },
      ]
    },
  },

  methods: {
    showMnemoschemasControlMenu(event) {
      this.$refs.mnemoschemasControl.show(event)
    },

    showDeleteMnemoschemaDialog(){
      this.deleteMnemoschemaDialog.show = true
    },

    deleteMnemoschema() {
      this.deleteMnemoschemaDialog.show = false
    },
  }
}
</script>

<style>

</style>