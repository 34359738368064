import { api } from '@/api'
import { defineStore } from 'pinia'
import vuexStore from '@/store'

// TODO: Вынести
export const useHighlightGroupsStore = defineStore('highlight.groups', {
  state: () => ({
    items: [],
    proxyGroups: new Map(),
    loading: false,
    controller: null
  }),

  getters: {
    highlightGroups: (state) => state.items,
    totalElements: (state) => state.items.reduce((acc, cur) => acc += cur?.elements?.length || 0, 0),
    proxyGroupsLength: (state) => state.proxyGroups.size
  },

  actions: {
    loadHighLigthsGroup(ruleUuid) {
      if (this.controller) this.controller.abort()
      this.controller = new AbortController()
      this.resetState()
      const projectUuid = vuexStore.state.project.project.uuid
      const projectClassificatorUuid = vuexStore.state.smeta.projectClassificatorUuid
      const nodeUuid = vuexStore.state.smeta.editRuleItem.classificatorNode.uuid

      this.loading = true
      return api.smeta.loadHighLigthsGroup(projectUuid, ruleUuid, nodeUuid, projectClassificatorUuid, this.controller).then(data => {
        this.items = data.sort((a, b) => a.title.localeCompare(b.title))
        this.loading = false
        this.proxyGroups = new Map(data.map(item => [item.uuid, item.elements.map(e => e.uuid)]))
      })
    },

    loadDictHighLigthsGroup() {
      this.resetState()
      const projectUuid = vuexStore.state.project.project.uuid
      const attributeUuid = vuexStore.state.projectDict.selectedAttribute.uuid

      this.loading = true
      return api.projectDict.hightLightGroup(projectUuid, attributeUuid).then((data) => {
        this.items = data.sort((a, b) => a.title.localeCompare(b.title))
        this.loading = false
        this.proxyGroups = new Map(data.map(item => [item.uuid, item.elements.map(e => e.uuid)]))
      })
    },

    loadWormHighLightsGroup(ruleUuid) {
      this.resetState()
      const projectWormUuid = vuexStore.state.project.projectSettings.projectWorm

      this.loading = true
      return api.worm.loadHighLigthsGroup(projectWormUuid, ruleUuid).then((data) => {
        this.items = data.sort((a, b) => a.title.localeCompare(b.title))
        this.loading = false
        this.proxyGroups = new Map(data.map(item => [item.uuid, item.elements.map(e => e.uuid)]))
      })
    },

    getProxyGroupElementsUuids(uuid) {
      return [].concat(this.proxyGroups.get(uuid))
    },

    getProxyGroupsUuid() {
      return Array.from(this.proxyGroups.keys())
    },

    resetState() {
      this.items = []
      this.proxyGroups = new Map()
      this.loading = false
    }
  }
})