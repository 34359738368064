<template lang="pug">
v-menu( background-color="white" offset-x offset-y )
  template( v-slot:activator='activator' )
    .relative
      ToolButton( 
        :active="isActive" 
        :tooltip="activePluginTitle" 
        :disabled="isPluginsDisabled"
        corner="left"
        @click="toggle" 
        @mousedown='onTouchStart(activator)'
        @mouseup="onTouchEnd" 
      ) {{ activePluginIcon }}
      v-icon.corner-left( size="32" color="white" ) $toolbar-corner-left-icon
  RulerModeMenu
  //- PluginMenu
</template>

<script>
import ToolButton from './ToolButton.vue'
import RulerModeMenu from './RulerModeMenu.vue'
import PluginMenu from './PluginMenu.vue'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
// import { ViewerTool, useViewerToolsStore } from "@/pinia"
// import { useViewerPluginsStore, ViewerPlugin } from "@/pinia"
import { useViewerPluginsStore } from "@/pinia"
import { useRulerStore } from "@/pinia/viewer/ruler.store"
import { toolbarIcons, titles } from './RulerModeService'

import { ToolWindow, useActiveToolWindowStore } from "@/stores/viewerTools/activeToolWindow.store"
const store = useActiveToolWindowStore()

// const store = useViewerToolsStore()
const pluginStore = useViewerPluginsStore()
const rulerStore = useRulerStore()

let timer
 
export default {
  components: {
    ToolButton,
    RulerModeMenu,
    PluginMenu,
  },

  computed: {
    // TODO: Вынести метод xeokit
    isActive: () => (store.activeWindow == ToolWindow.PLUGIN_RULER) || XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive,
    // tooltip: () => 'Поиск координат',

    activeTool: () => store.activeTool,
    isPluginsDisabled: () => pluginStore.isPluginsDisabled,

    // rulerBtn () { return ({ tool: ViewerTool.PLUGINS, icon: this.activePluginIcon, size: '32', iconSize: '32', hasMenu: true }) },
    // props () { return this.rulerBtn },

    activePluginIcon() {
      return toolbarIcons[rulerStore.mode]
    },
      
    activePluginTitle() {
      return titles[rulerStore.mode]
    },
  },

  watch: {
    isPluginsDisabled() {
      // Надо закрыть окно, если у нас есть смещение или блокировать его содержимое
      // store.activeTool = ViewerTool.NONE
      // pluginStore.setActiveTab(ViewerPlugin.NONE)
      pluginStore.deactivateAll()
    },
  },

  methods: {
    toggle () {
      store.toggleActiveWindow(ToolWindow.PLUGIN_RULER)
    },

    // toggleTool: (tool) => store.activeTool = store.activeTool != tool ? tool : ViewerTool.NONE,

    onTouchStart(activator) {
      timer = setTimeout(() => { activator.value = true }, 500)
    },

    onTouchEnd() {
      if (timer) clearTimeout(timer)
    }
  }
}
</script>

<style scoped>
.corner-left {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}
</style>