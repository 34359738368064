<template lang="pug">
AppTooltip( title="Добавить комментарий" bottom )
  AppIconButton( icon="mdi-message-plus-outline" :iconColor="state.isCommentModeActive ? '#EB5757': '#4D4D4D'" @click="state.toggleCommentMode()")
</template>

<script>
import { useDrawingPageState } from '@/pinia'

export default {
  name: 'DrawingOpenComment',

  computed: {
    state: () => useDrawingPageState()
  },
}
</script>