<template lang="pug">
  app-panel.pt-1.format(ident="filemanager" left )
    task-header(:title="$t('section.fileManager.title')" headerItemsCenter mainTitle  )
      template( v-slot:headerAfter )
        div.search-input-container
          input.search-input.mr-1(v-model.trim="searchText")
          v-icon.magnify mdi-magnify
        app-icon-button( icon="mdi-close" @click="handleClose" )
      template( v-slot:menu )
          app-menu(:menu="menuItems" basic color="var(--v-surface-lighten3)")
    fileManagerTree(ref="tree" :search="searchText")

</template>

<script>
  import TaskHeader from '@/components/task/TaskHeader'
  import FileManagerTree from '@@/project/panel/left/fileManager/FileManagerTree'
  import { mapMutations } from 'vuex'

  export default {
    name: 'FileManager',
    components: {
      TaskHeader,
      FileManagerTree,
    },

    data() {
      return {
        searchText:""
      }
    },

    mounted() {},

    beforeDestroy() {},

    computed: {
      menuItems() {
        return [
          { title: this.$t('section.journal.addFile'), action: () => this.$refs.tree.showRootCreateDialog('addFile')},
          { title: this.$t('section.journal.addFolder'), action: () => this.$refs.tree.showRootCreateDialog()},
        ]
      },
    },

    methods: {
      ...mapMutations('project', ['setActiveGlobalTab']),

      handleClose() {
        this.setActiveGlobalTab('')
      },
    },
  }
</script>

<style scoped>
.search-input {
  /* background: #E5E5E5; */
  background-color: var(--v-surface-base);
  border: 1px solid var(--v-surface-lighten3);
  color: var(--v-surface-lighten3);
  /* border: none; */
  outline: none;
  width: 200px;
  border-radius: 3px;
  padding: 0px 45px 0px 6px;
}
.search-input-container{
  position: relative;
}
.magnify{
  position: absolute;
  right: 15px;
  color: var(--v-surface-lighten3);
}
.format{
  height: 100% !important;
}
</style>
