<template lang="pug">
.d-flex.flex-column.px-2
  app-text-field.search-field( v-model="filter" label="Поиск" outlined dense hide-details append-icon="search" )
  .adding-color_fullheight.d-flex.flex-column.flex-grow-1.flex-shrink-1.overflow-y-auto
    v-skeleton-loader( v-if="!isGroupsPrepared" type="list-item-three-line" light )
    .primary--text.text--lighten-2.align-self-center.mt-6( v-else-if="!groupsList.length" ) Элементы не найдены
    .overflow-y-auto.base-scroll-body( v-else )
      .px-1.d-flex.align-center( v-for="group in groupsList" :key="group.uuid" ) 
        .flex-grow-1.text-caption( v-line-clamp="1" :class="elementsStyle(group)" 
                                  @click="selectGroup(group)" :id="group.uuid" ) {{ group.title }}
        v-progress-circular( v-if="group.disabled" :size="14" :width="2" color="accent" indeterminate )
</template>

<script>
import { useGroupsListState } from '@/states/groupPalette/groupsList.state.js'
import { useGroupsWindowAssembly } from '@/states/groups/groupsWindow.assembly'

const assembly = useGroupsWindowAssembly()
const state = useGroupsListState()

export default {
  props: {
    selectedGroup: null
  },

  mounted() {
    if(assembly.groupUuid) {
      this.$nextTick(() => {
        const element = document.getElementById(assembly.groupUuid)
        element.scrollIntoView()
      })
    }
  },

  computed: {
    filter: {
      get: () => state.filter,
      set: (value) => state.filter = value
    },
    groupsList: () => state.groupsList,
    isGroupsPrepared: () => state.isGroupsPrepared,
  },

  methods: {
    selectGroup(group) {
      if(!group.disabled && !assembly.disabledAllGroups) this.$emit('select', group.uuid)
    },

    elementsStyle(group) {
      if(group.disabled) return 'text--lighten-2 primary--text'
      if(this.selectedGroup == group.uuid) return 'selected-item pointer'
      if(assembly.disabledAllGroups) return 'text--lighten-2 primary--text'
      return 'text--darken-1 black--text pointer'
    }
  }
}
</script>

<style scoped>
.adding-color_fullheight {
  height: 220px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.search-field {
  border-radius: unset !important;
  border-bottom: 0px;
}
.selected-item {
  font-weight: 500; 
  color: rgb(59, 147, 175);
}
</style>