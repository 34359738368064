<template lang="pug">
  div.my-2(:class="{'warning': compare}")
    template(v-if="item.wormNodeType.name == 'TOPIC'")
      app-text {{ item.wormNodePressmark }} - {{ item.wormNodeTitle }}
    template(v-else-if="item.wormNodeType.name == 'CLASS'")
      app-text {{ item.wormNodePressmark }} - {{ item.wormNodeTitle }}
    template(v-else-if="item.wormNodeType.name == 'RULE'")
      v-container.pb-0.pt-0
        v-row
          v-col(cols=6).pl-0
            div
              app-text
                b {{ item.wormNodeClassificatorNodePressmark }}
            div
              app-text {{ item.wormNodeClassificatorNodeTitle }}
          v-col
            app-text {{ item.cnt }}
          v-col
            app-text {{ item.classificatorUnitValue + (item.classificatorUnitType? item.classificatorUnitType.title : '')}}
          v-col.pr-0
            app-text {{ item.value }}
    div.ml-3
      version-tree(:items="item.children" :compare-to="compareTo")
    //- v-row(style="width: 100%; box-sizing: border-box")
</template>

<script>
import WormVersionNode from '@/assets/model/worm/WormVersionNode'
export default {
  name:'version-tree-item',
  
  components: {
    VersionTree: () => import('./VersionTree.vue')
  },

  props: {
    item: Object,
    compareTo: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      
    }
  },

  mounted() {
    
  },

  computed: {
    compare(){
      if (this.compareTo) {
        if (this.item.wormVersionUuid == this.compareTo.uuid) return false
        let otherItem = WormVersionNode.query().where('wormVersionUuid',this.compareTo.uuid).where('wormNodeUuid',this.item.wormNodeUuid).first()
        if (!otherItem) return true
        if (this.item.cnt != otherItem.cnt || this.item.value != otherItem.value) return true
      }
      return false
    }
  },

  watch: {
    
  },

  methods: {
    
  }
}
</script>