import { render, staticRenderFns } from "./AttributeRules.vue?vue&type=template&id=37e4322e&scoped=true&lang=pug&"
import script from "./AttributeRules.vue?vue&type=script&lang=js&"
export * from "./AttributeRules.vue?vue&type=script&lang=js&"
import style0 from "./AttributeRules.vue?vue&type=style&index=0&id=37e4322e&prod&lang=scss&scoped=true&"
import style1 from "./AttributeRules.vue?vue&type=style&index=1&id=37e4322e&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37e4322e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VIcon,VSkeletonLoader,VTreeview})
