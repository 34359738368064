<template lang="pug">
  div(v-if="!isShare")
    app-button-add.mt-3(v-if="listProjects.length > 0" width="100%" :text="$t('cloud.openProject')" @click="showProjectsByHash") 
    app-button-add.mt-3( width="100%" :text="$t('cloud.createProject')" @click="setConverting") 
    app-button-add.mt-3( width="100%" :text="$t('cloud.addToProject')" @click="showProjects") 

    app-dialog(v-model="dialog.showProjects" ref="addModeltoProject"  :header="$t('cloud.addToProject')" :confirmText="$t('button.add')" @confirm="addRevisionToModelProject()" :confirmDisabled="disabledConvert")
      app-section
        app-select(:items="filteredProjects" itemValue="uuid" itemText="title" :label="$t('project.form.tabs.project')" @input="getTreeModelByProjectUuid")
        app-select.mt-3(v-if="flatlistTreeModel.length > 0" :items="flatlistTreeModel" itemValue="uuid" itemText="title" :label="$t('section.structure.model.header')" @input="setCloudSelectedModel")

    app-dialog(v-model="dialog.showProjectsByHash" ref="openProject"  :header="$t('cloud.openProject')" :confirmText="$t('button.open')" @confirm="openProject" :confirmDisabled="projectUuid == null")
      app-section
        app-select(:items="listProjects" itemValue="uuid" itemText="title" :label="$t('project.form.tabs.project')" @input="setProjectUuid")

    app-dialog(v-model="dialog.accessLogin" :header="$t('access.default')" :items="registerItems" @close="dialog.showProjects = false")
    AccessLogin(v-model="dialog.login" @close="dialog.showProjects = false")
    BeforeRegistration(v-model="dialog.registation" @close="dialog.showProjects = false")
</template>

<script>
import Login from '@/components/access/Login'
import BeforeRegistration from '@/components/access/BeforeRegistration' 

import { App } from '@app/App'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {

  components: {
    [Login.name]: Login,
    [BeforeRegistration.name]: BeforeRegistration,
  },

  data () {
    return {
      dialog: {
        actionConverting: false,
        showProjects: false,
        login: false,
        registation: false,
        accessLogin: false,
        showProjectsByHash: false,
      },
      disabledConvert: false,
      projectUuid: null,

      open: null
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapGetters('dashboard', ['filteredProjects']),
    ...mapGetters('cloud', ['flatlistTreeModel', 'listProjects']),

    registerItems () {
      return [
        { icon:'login', title: this.$t('signIn.default'), action: () =>  this.dialog.login= true },
        { icon:'how_to_reg', title: this.$t('registration.default'), action: () =>  this.dialog.registation= true },
      ]
    },

    isShare () {
      let url = new URL(location.href);
      let share = url.searchParams.get("share");
      return share == 1
    }
  },

  watch: {
    'dialog.login'(value) {
      if (!value && App.auth.accessToken) {
        if (this.open==="projects") this.showProjects()
        else if (this.open==="converts") this.setConverting()
      }
    },
    
    'dialog.registation'(value) {
      if (!value && App.auth.accessToken) {
        if (this.open==="projects") this.showProjects()
        else if (this.open==="converts") this.setConverting()
      }
    },
  },

  methods: {
    ...mapActions('cloud', ['getTreeModel', 'convert', 'addCloudModelToProject']),

    setConverting () {
      if (!App.auth.accessToken) {
        this.open = "converts"
        //return this.dialog.accessLogin = true
      }
      this.open = null
      this.convert(this.project.uuid).catch(err => {
        console.log("error converting", err);
        this.open = "converts"
        //return this.dialog.accessLogin = true
      })
    },

    showProjectsByHash () {
      return this.dialog.showProjectsByHash = true
    },

    showProjects () {
      if (!App.auth.accessToken) {
        this.open="projects"
        //return this.dialog.accessLogin = true
      }

      this.disabledConvert = true
      this.$store.dispatch('dashboard/init').catch( err => {
        console.log("show projects", err);
        this.open="projects"
        //return this.dialog.accessLogin = true
      })
        
      this.$refs.addModeltoProject.show()

    },

    getTreeModelByProjectUuid (projectUuid) {
      this.getTreeModel(projectUuid)
    },

    setCloudSelectedModel(modelUuid) {
      this.cloudSelectedModel= modelUuid
      this.disabledConvert = false
    },

    addRevisionToModelProject () {
      this.open = null
      let obj = {
        projectUuid: this.project.uuid,
        modelUuid: this.cloudSelectedModel
      }
      this.addCloudModelToProject(obj)
    },

    setProjectUuid (projectUuid) {
      console.log(projectUuid);
      this.projectUuid = projectUuid
    },

    openProject () {
      window.location.href = `/project/${this.projectUuid}`
    },
    
  }
}
</script>