<template lang="pug">
  app-dialog( v-model="visible" :header="$t('section.collision.editGroup')" :confirmDisabled='!groupTitle' :confirm-text="$t('button.save')"  @confirm="onSavingGroup" )
    app-section
      app-text-field( v-model="groupTitle" :label="$t('section.collision.nameGroup')" )
</template>

<script>
import { mapActions } from 'vuex'

export default {

  data() {
    return {
      groupUuid: null,
      groupTitle: null,
      visible: false,
    }
  },

  methods: {
    ...mapActions('collision/table', ['editGroup']),

    show(group) {
      this.groupUuid = group.groupUuid
      this.groupTitle = group.groupTitle
      this.visible = true
    },

    onSavingGroup () {
      this.editGroup({ uuid: this.groupUuid, title: this.groupTitle })
    }
  }
}
</script>
