import { useDrawingsStore } from "@/pinia"

export class DrawingPageContextMenuService {

	static get #store() { return useDrawingsStore() }

  static showContextMenu(event) {
		event.preventDefault()
		const targetBoundingClientRect = event.target.getBoundingClientRect()

		const left = ((event.clientX - targetBoundingClientRect.left) / targetBoundingClientRect.width ) * 100
		const top = ((event.clientY - targetBoundingClientRect.top) / targetBoundingClientRect.height ) * 100

		this.#store.contextMenuPosition = {
			top: `calc(${top}% - 34px)`,
			left: left + "%",
			position: "absolute",

			originalCoords: {
				xCoord: left,
				yCoord: top
			}
		}

		this.#store.show.contextMenu = true

		if (!this.#store.commentMode) {
			document.body.addEventListener('click', hideContextMenuFunc)
		}
	}

	static hideContextMenu() {
		hideContextMenuFunc()
	}
}

function hideContextMenuFunc(event = null) {
	event?.preventDefault()
	document.body.removeEventListener('click', hideContextMenuFunc)

	useDrawingsStore().show.contextMenu = false
}