<template lang="pug">

  div.maintenanace__event-card(v-if="!lineView" :class="short && selectedEvent != null && selectedEvent.uuid == event.uuid ? 'selected': ''")
    v-row.maintenanace__event-card-title(no-gutters)
      v-col.maintenanace__event-card-event-date(cols="auto" :class="'profile-level--' + profileLevelName") {{event.warningDate}}
      v-col.title(v-if="short") {{ profileTitle }}

    .maintenanace__event-card-descr(v-if="short") {{ eventDescr }}

    template(v-if="!short")
      div.maintenanace__event-card-fulltitle {{ profileTitle }}
      div(v-for="data in event.warningDatas" :key="data.uuid")
        .maintenanace__event-card-descr {{ dataDescr(data) }}
        equipment-details(v-if="findEquipment(data.equipment)" :equipment="findEquipment(data.equipment)" showGoto @goToEquipment="goToEquipment")

  v-row(v-else no-gutters)
    v-col 
      app-text {{ profileTitle }}
    v-col(cols="auto") 
      app-text {{event.warningDate}}


</template>

<script>
import { mapState } from 'vuex'
import EquipmentDetails from './EquipmentDetails'

export default {
  components: {
    EquipmentDetails
  },

  props: {
    event: null,
    short: {
      type: Boolean,
      default: false
    },
    lineView: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState('equipmentWarning', ['warningFeatureType', 'profiles', 'selectedEvent']),
    ...mapState('maintenance', ['tree']),

    profile() {
      return this.profiles.find(p => p.uuid == this.event.warningProfileUuid)
    },

    profileLevelName() {
      return this.profile?.level.name || ''
    },

    profileTitle() {
      return this.profile?.title || ''
    },

    eventDescr() {
      let text = ''
      this.event.warningDatas.forEach( data => {
        text += this.dataDescr(data)
      })
      return text
    }
  },

  methods: {
    dataDescr(data) {
      let text = ''
      if(data.warningFeature != null ) {
        let feature = this.profile?.warningFeatures.find(f =>  f.uuid == data.warningFeature)
        if (feature !== undefined) {
           if(feature.warningFeatureType == this.warningFeatureType[0].key)
              text += this.$t('section.equipment.warning.readingEqual', {value: data.value, min: feature.min, max: feature.max})
           else if(feature.warningFeatureType == this.warningFeatureType[1].key)
              text += this.$t('section.equipment.warning.delays', {delay: feature.delay, title: feature.periodType.title})
        }
      }
      return text
    },

    dataDescr_TMP(data) {
      let text = ''
      if(data.warningFeature != null ) {
        if(data.warningFeature.warningFeatureType == this.warningFeatureType[0].key)
           text += this.$t('section.equipment.details.readingEqual', {value: data.value}, {min: data.warningFeature.min}, {max: data.warningFeature.max})
        if(data.warningFeature.warningFeatureType == this.warningFeatureType[1].key)
           text += this.$t('section.equipment.details.delay', {delay: data.warningFeature.delay}, {title: data.warningFeature.periodType.title})
      }
      return text
    },

    goToEquipment(item) {
      this.$emit('goToEquipment', item)
    },

    findEquipment(equipmentId) {
      return equipmentId != null ? this.tree.flatlist.find(i => !i.isGroup && i.uuid == equipmentId) : null
    }

  }
}
</script>

<style lang="sass" scoped>
.maintenanace__event-card 
  border: 1px solid var(--v-primary-lighten4)
  margin: 8px
  padding: 8px
  position: relative
  color: var(--v-primary-base)
  &.selected 
    background-color: var(--v-primary-lighten5)

  .maintenanace__event-card-fulltitle
    margin-top: 8px

  .maintenanace__event-card-title
    margin: -9px 0 0 -8px
    .title 
      margin: 7px 0 0 8px


  .maintenanace__event-card-descr
    font-size: 13px
    color: var(--v-primary-lighten2)
    margin-top: 8px

  .maintenanace__event-card-event-date
    color:#FFFFFF
    font-size: 12px
    padding: 5px
    &.profile-level--INFO 
      background-color:#3ac730
    &.profile-level--WARNING
      background-color:#cddd16
    &.profile-level--ALARM
      background-color:#db1616
    &.profile-level--CRITICAL
      background-color:#770b0b
</style>
