import { render, staticRenderFns } from "./DrawingPageSelecting.vue?vue&type=template&id=fd2c74e0&scoped=true&lang=pug&"
import script from "./DrawingPageSelecting.vue?vue&type=script&lang=js&"
export * from "./DrawingPageSelecting.vue?vue&type=script&lang=js&"
import style0 from "./DrawingPageSelecting.vue?vue&type=style&index=0&id=fd2c74e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2c74e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VHover,VIcon,VSwitch})
