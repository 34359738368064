<template lang="pug">
  div.equipment-treeview-container( ref="equipment-treeview-container" )
    div( v-if="!loaded" ) 
      v-skeleton-loader( type="list-item-two-line" light )


    div( v-else :style="'height: 100%'")
      .d-flex.justify-space-between(v-if="treeAllVisibleNodes && treeAllVisibleNodes.length > 0")
        v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleMassFolding" ) 
          v-icon.mr-1( color="accent" size="16" left ) unfold_more
          | {{ areAllTreeNodesDisclosed ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
      virtual-list(
        ref="eqTree"
        data-key="uuid"
        :data-sources="treeAllVisibleNodes"
        :data-component="itemComponent"
        :estimate-size="treeElementHeight"
        :keeps="treeElementKeeps"
        test-id="equipment-tree-list"
        :extra-props="{showMenu: handleContextMenu}"
      )
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import VirtualList from 'vue-virtual-scroll-list'
import EquipmentTreeviewItem from './EquipmentTreeviewItem.vue'

export default {
  name: 'EquipmentTreeview',

  inheritAttrs: false,

  components: {
    VirtualList
  },

  props: {
  },

  data () {
    return {
      itemComponent: EquipmentTreeviewItem,
      treeElementHeight: 24,
      treeElementKeeps: 20
    }
  },

  mounted(){
    const equipmentTreeContainer = this.$refs['equipment-treeview-container']

    this.pageResizeObserver = new ResizeObserver(() => {
      this.treeElementKeeps = Math.ceil(equipmentTreeContainer.clientHeight / this.treeElementHeight) + 10
    })

    this.pageResizeObserver.observe(equipmentTreeContainer)
  },

  computed: {
    ...mapState('maintenance', ['loaded']),
    ...mapGetters('maintenance', ['treeAllVisibleNodes', 'areAllTreeNodesDisclosed']),

  },

  methods: {
    ...mapActions('maintenance', ['expandAllTreeNodes', 'collapseAllTreeNodes']),

    handleContextMenu (event, item) {
      this.$emit('menu', event, item)
    },

    toggleMassFolding () {
      this.areAllTreeNodesDisclosed ? this.collapseAllTreeNodes() : this.expandAllTreeNodes()
    },

  }
}
</script>

<style lang="scss" scoped>
.total {
  text-align: center;
  margin-right: 12px;
  font-size: 12px;
  color: var(--v-accent-base);
  border-top: 1px dashed var(--v-accent-base);
}

.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;
}

.t-scroll-body {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-primary-lighten4);
}
.equipment-treeview-container{
  height: calc(100% - 58px);
}
</style>