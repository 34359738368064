/*eslint-disable no-dupe-class-members*/
import { execute } from 'crypto-pro'
import { api } from '@/api'

export default class CryptoPro {
  chunkSize = 3 * 2 ** 20

  getAllSignsId(fileId) {
    let data = new FormData()
    data.append('fileId', fileId)

    return api.nextCloud.getAllSignsId(data)
  }

  downloadFile(fileId, progressEvent) {
    let data = new FormData()
    data.append('fileId', fileId)

    return api.nextCloud
      .downloadFile(data, progressEvent)
  }

  downloadSign(signId) {
    let data = new FormData()
    data.append('fileId', signId)

    return api.nextCloud
      .downloadSign(data)
  }

  verifySign(hash, key) {
    return execute(async (API) => {
      let oSignedhash = await API.cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')
      let oSignedData = await API.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData')
      oSignedhash.propset_Algorithm(API.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256)
      oSignedhash.SetHashValue(hash)
      try {
        await oSignedData.VerifyHash(oSignedhash, key, API.cadesplugin.CADESCOM_PKCS7_TYPE) // TODO: CADESCOM_PKCS7_TYPE - указывать вернее чем CADESCOM_CADES_BES, но не набыть поменять на NX
      } catch (e) {
        let err = API.cadesplugin.getLastError(e)
        console.log('Failed to verify signature. Error: ' + err)
        return
      }
      let oSigner = await oSignedData.Signers
      oSigner = await oSigner.Item(1)

      // let ab = await oSigner.Certificate
      // console.log("1", await ab.SubjectName);
      // console.log("2", await oSignedData.Signers);

      let date = await oSigner.SigningTime
      return date
    })
  }

  hashFileAsChunks(file, progressEvent) {
    this.file = file
    return execute(async (API) => {
      this.oSignedhash = await API.cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')
      this.oSignedhash.propset_Algorithm(API.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256)
      this.oSignedhash.propset_DataEncoding(API.cadesplugin.CADESCOM_BASE64_TO_BINARY)

      this.chunks = Math.ceil(this.file.size / this.chunkSize)
      this.currentChunk = 0

      while (this.currentChunk < this.chunks) {
        this.#setHashPart(await this.#readFileChunk())
        this.currentChunk++
        progressEvent(((this.currentChunk) / this.chunks) * 100)
      }
      return await this.oSignedhash.Value
    })
  }

  #setHashPart(sFileData) {
    let header = ';base64,'
    let sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length)
    this.oSignedhash.Hash(sBase64Data)
  }

  #readFileChunk() {
    return new Promise((resolve, reject) => {
      let sliceBlob = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice
      let reader = new FileReader()

      reader.onload = () => resolve(reader.result)
      reader.onerror = () => reject(reader.error)

      let start = this.currentChunk * this.chunkSize
      let end = start + this.chunkSize >= this.file.size ? this.file.size : start + this.chunkSize
      reader.readAsDataURL(sliceBlob.call(this.file, start, end))
    })
  }

  uploadKey(fileId, key, certname) {
    let data = new FormData()
    data.append('fileId', fileId)
    data.append('key', key)
    data.append('sertname', certname)

    return api.nextCloud.uploadKey(data)
  }

  createCoSign(certificate, key, hash) {
    return execute(async (API) => {
      let oSignedData = await API.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData')
      let oSigner = await API.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner')
      oSigner.propset_Certificate(certificate._cadesCertificate)
      oSigner.propset_CheckCertificate(true)

      let oSignedhash = await API.cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')
      oSignedhash.propset_Algorithm(API.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256)
      oSignedhash.SetHashValue(hash)

      try {
        await oSignedData.VerifyHash(oSignedhash, key, API.cadesplugin.CADESCOM_PKCS7_TYPE)
      } catch (e) {
        let err = API.cadesplugin.getLastError(e)
        console.log('Failed to verify signature. Error: ' + err)
        return
      }
      let message = await oSignedData.CoSignHash(oSignedhash, oSigner, API.cadesplugin.CADESCOM_PKCS7_TYPE)
      return message
    })
  }
}







//Это для создания паралельной подписи
/* async createCoSign() {
     execute(async (API) => {
       let oSignedhash = await API.cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')
       let oSignedData = await API.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData')
       oSignedhash.propset_Algorithm(API.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256)
       oSignedhash.propset_DataEncoding(API.cadesplugin.CADESCOM_BASE64_TO_BINARY)
       oSignedhash.Hash(btoa('123'))

       console.log('dsfsdf', await oSignedhash.Value)

       let oSigner = await API.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner')
       oSigner.propset_Certificate(this.certificates[0]._cadesCertificate)
       oSigner.propset_CheckCertificate(true)

       let message = await oSignedData.SignHash(oSignedhash, oSigner, API.cadesplugin.CADESCOM_PKCS7_TYPE)
       console.log('test', message)
       try {
         await oSignedData.VerifyHash(oSignedhash, message, API.cadesplugin.CADESCOM_PKCS7_TYPE)
       } catch (e) {
         let err = API.cadesplugin.getLastError(e)
         console.log('Failed to verify signature. Error: ' + err)
         return
       }

       // oSigner.propset_Certificate(this.certificates[1]._cadesCertificate) 
       // let message1 = await oSignedData.CoSignHash(oSignedhash, oSigner, API.cadesplugin.CADESCOM_PKCS7_TYPE) //вар 1 создание паралельной подписи у уже созданной в ТОМ ЖЕ месте
       // console.log("test2",message1);

       let oSignedData2 = await API.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData')
       oSignedData2.propset_ContentEncoding(API.cadesplugin.CADESCOM_BASE64_TO_BINARY)
       oSignedData2.propset_Content(btoa('123'))
       oSignedData2.VerifyCades(message, API.cadesplugin.CADESCOM_PKCS7_TYPE, true) //message - первая подпись в формате PKCS7
       let message1 = await oSignedData2.CoSignCades(oSigner, API.cadesplugin.CADESCOM_PKCS7_TYPE) //вар 2 паралельная подпись для любой уже созданной из вне или тут
       console.log('test2', message1)

       try {
         await oSignedData.VerifyHash(oSignedhash, message1, API.cadesplugin.CADESCOM_PKCS7_TYPE)
       } catch (e) {
         let err = API.cadesplugin.getLastError(e)
         console.log('Failed to verify signature. Error: ' + err)
         return
       }

       let a = document.createElement('a')
       let file = new Blob([message1], { type: 'text/plain' })
       a.href = URL.createObjectURL(file)
       a.download = '1example.sig'
       a.click()

       console.log('good')
     })
   }, */