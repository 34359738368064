<template lang="pug">
div
  expansion-button( :buttonHeader="$t('section.structure.model.setOffsetTurn')" )
    coordinates-menu( ref="coordsMenu" :withLocker="false" :realOffsetCoord="getRealOffsetCoord" :realEulerCoord="getRealEulerCoord" :offset="getOffsetCoord" :euler="getEulerCoord"
                      @saveCoord="saveChanges" @viewCoord='viewChanges')    
</template>


<script>
import CoordinatesMenu from '../../components/CoordinatesMenu.vue'
import ExpansionButton from '../../components/ExpansionButton.vue'
import { mapState, mapActions } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@app/AlertService'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { MODEL_TRANSFORM_ID } from '@/plugins/xeokit/RevisionLoader'
import { math } from '@xeokit/xeokit-sdk'

export default {
  name: "PatchCoords",

  components: {
    ExpansionButton, 
    CoordinatesMenu
  },

  computed: {
    ...mapState('patches', ['selectedPatch']),

    getRealOffsetCoord() {
      return { x: this.selectedPatch.realOffsetX, y: this.selectedPatch.realOffsetY, z: this.selectedPatch.realOffsetZ }
    },

    getRealEulerCoord() {
      return { x: this.selectedPatch.realEulerX, y: this.selectedPatch.realEulerY, z: this.selectedPatch.realEulerZ }
    }, 

    getOffsetCoord() {
      return { x: this.selectedPatch.offsetX, y: this.selectedPatch.offsetY, z: this.selectedPatch.offsetZ }
    }, 

    getEulerCoord() {
      return { x: this.selectedPatch.eulerX, y: this.selectedPatch.eulerY, z: this.selectedPatch.eulerZ}
    }
  },

  methods: {
    ...mapActions('patches', ['editPatchOffset', 'loadPatchList']), 
    ...mapActions('project', ['updateProject', 'loadPatchRevList']), 

    saveChanges(modelPosition, sendData) {
      this.editPatchOffset(sendData).then(async (data) => {
        this.setFormValue(modelPosition)
        for( let key in XeokitMediator.viewer.scene.models) {
          XeokitMediator.viewer.scene.models[key].destroy()
        }
        await this.loadPatchList()
        await this.loadPatchRevList()
        this.updateProject(data)

        TaskBimAnnotations.clearTaskBimAnnotations()

        AlertService.success({ info: this.$t('section.structure.model.revisionMoving.successSave') })
      }).catch(e => {
          this.setFormValue(modelPosition) 
          AlertService.error(e.response)
      })
    }, 

    viewChanges(modelPosition) {
      const sceneModel = XeokitMediator.viewer.scene.models[this.selectedPatch.uuid]

      if(!sceneModel) return
      
      let { eulerX, eulerY, eulerZ, offsetX, offsetY, offsetZ } = modelPosition 
      const modelTransform = sceneModel.transforms[MODEL_TRANSFORM_ID]

          const inverseMatrix = math.mat4()
          math.inverseMat4(modelTransform.worldMatrix, inverseMatrix)

          const position =   math.vec3()
          const quaternion = math.vec4()
          const scale =      math.vec3()

          math.decomposeMat4(inverseMatrix, position, quaternion, scale)

          const q1 = math.vec4()
          math.eulerToQuaternion([eulerX, eulerY, eulerZ], 'XYZ', q1)

          const q2 = math.vec4()
          math.mulQuaternions(q1, quaternion, q2)

          const mat1 = math.mat4()
          math.quaternionToMat4(q1, mat1)

          const rotatedPosition = math.vec3()
          math.transformVec3(mat1, position, rotatedPosition)

          const translation = [offsetX, offsetY, offsetZ]
          const newPosition = [
            rotatedPosition[0] + translation[0],
            rotatedPosition[1] + translation[1],
            rotatedPosition[2] + translation[2]
          ]

          sceneModel.quaternion = q2
          sceneModel.position = newPosition
    },

    setFormValue(form) {
      this.selectedPatch.offsetX = form.offsetX
      this.selectedPatch.offsetY = form.offsetY
      this.selectedPatch.offsetZ = form.offsetZ
      this.selectedPatch.eulerX = form.eulerX
      this.selectedPatch.eulerY = form.eulerY
      this.selectedPatch.eulerZ = form.eulerZ
      this.selectedPatch.realOffsetX = form.realOffsetX
      this.selectedPatch.realOffsetY = form.realOffsetY
      this.selectedPatch.realOffsetZ = form.realOffsetZ
      this.selectedPatch.realEulerX = form.realEulerX
      this.selectedPatch.realEulerY = form.realEulerY
      this.selectedPatch.realEulerZ = form.realEulerZ
    }
  }

}
</script>
