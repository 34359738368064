<template lang="pug">
ToolButton( 
  :tooltip="$t('viewer.tools.hideSelObjs')" 
  :active="store.isHideSelectedElements" 
  @click="store.toggleHideSelectedObjects" 
) $viewer-hide-clear-icon
</template>

<script>
import { useSelectionStore } from '@/stores/viewerTools/selection.store'
import ToolButton from './ToolButton.vue'

export default {
  components: {
    ToolButton,
  },

  computed: {
    store: () => useSelectionStore(),
  },
}
</script>