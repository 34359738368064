<template lang="pug">
app-dialog( v-model="$_show" :header="$t('section.organization.group')" )
  app-section(v-if="teammate && teammate.orgGroup")
    div.mb-2(v-for="employe in teammate.orgGroup.employees")
      app-avatar(:url="filePath(employe)" :colorObj="getBgColor(employe.profile.uuid)" :text="employe.profile.name")
      .u-info
        .fio {{ employe.profile.name }} 
          .caption {{ employe.profile.email }} 
    
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { SourcePath } from '@app/SourcePath'
import uniqolor from 'uniqolor';

export default {
  name: 'ProjectTeammateOrgGroup',
  mixins: [DialogToggable],

  props: {
    teammate: Object,
  },

  methods: {
    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    filePath (teammate) {
      return SourcePath.avatar(teammate.profile.avatar)
    },
  }
}
</script>

<style lang="scss" scoped>
.profile {
  margin-bottom: 16px;
  cursor: pointer;
}
.avatar {
  margin-top: 5px;
  display: inline-block;
  font: normal 14px/24px $roboto;
  color: #505254;
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
}
.u-info {
  display: inline-block;
  margin-left: 8px;
  font: normal 14px/12px $roboto;
  vertical-align: top;
  margin-top: 5px;
  // max-width: 192px;
  max-width: calc(100% - 32px);
}
.fio {
  color: #505254;
}
.fio-default {
  color: #3B93AF;
}
</style>