<template lang="pug">
div
    equipment-treeview(@menu="showContextMenu")

    app-dialog(v-if="selectedGroup && writingEditeGroup" v-model="dialog.saveGroup" :header="$t('section.equipment.form.group')" :confirmDisabled='!writingEditeGroup.title' @confirm="saveEquipmentGroup(writingEditeGroup)" :confirmText="$t('button.save')" width="430")
      app-section
        app-select( v-model="writingEditeGroup.parent" :items="preparedGroups" item-value="uuid" :label="$t('section.equipment.form.group')" clearable return-object)
        app-text-field.mt-2( v-model.trim="writingEditeGroup.title" :rules="rulesRequired" :label="$t('corp.title')" hide-details)
      
    app-dialog-confirm(v-if="selectedGroup" v-model="dialog.deleteGroup" @confirm="deleteEquipmentGroup(selectedGroup.uuid)")
      .text-subtitle-1.pt-5 {{ $t('section.equipment.form.remove') }} - "{{ selectedGroup.title }}"?

    app-dialog-confirm(v-if="selectedEquipment" v-model="dialog.deleteEquipment" @confirm="deleteEquipment(selectedEquipment.uuid)")
      .text-subtitle-1.pt-5 {{ $t('section.equipment.form.remove') }} - "{{ selectedEquipment.name }}"?

    equipment-form(v-if="selectedEquipment" v-model="dialog.saveEquipment" :equipment="editedEquipment")
    equipment-specification-form(
        v-if="selectedEquipment && selectedEquipment.equipmentSpecification" 
        v-model="dialog.editEquipmentSpec" :equipment="editedEquipmentSpecification" 
        @save="saveEquipmentSpecification(editedEquipmentSpecification)")

    app-menu( ref="contextMenu" :menu="selectedEquipment ? equipmentMenu : groupMenu" )

    maintenance-recalc-dialog(ref="recalcDialog")
            
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { $_validate_rules } from '@/common/ValidateRules'
import EquipmentTreeview from './EquipmentTreeview.vue'
import EquipmentForm from './EquipmentForm.vue'
import EquipmentSpecificationForm from '@/components/corp/views/equipment/EquipmentSpecificationForm.vue'
import MaintenanceRecalcDialog from '../dialogs/MaintenanceRecalcDialog.vue'

export default {
  mixins: [$_validate_rules],
  components: {
    MaintenanceRecalcDialog,
    EquipmentTreeview,
    EquipmentForm,
    EquipmentSpecificationForm
  },

  data () {
    return {
      dialog: {
        saveGroup: false,
        deleteGroup: false,
        saveEquipment: false,
        deleteEquipment: false,
        editEquipmentSpec: false,
        recalcTask: false,
      },
      writingEditeGroup: null,
    }
  },

  computed: {
    ...mapState('maintenance', ['selectedGroup', 'selectedEquipment', 'tree']),
    ...mapGetters('maintenance', ['editedGroup', 'editedEquipment', 'editedEquipmentSpecification']),
    ...mapGetters('projectPermissions', ['hasMaintainceEquipment', 'hasMaintainceCalculateTO']),

    rulesRequired () {
      return [this.rules.required]
    },
    
    equipmentMenu () {
      let menu = []
      if (this.hasMaintainceCalculateTO) {
        menu.push(
          { title: this.$t('section.journal.calculategraph'), action: () => this.$emit('calcTO', false) },
          { title: "Пересчитать ТО по дате", action: () => this.$refs.recalcDialog.showDialog() }
        )
      }

      if (this.hasMaintainceEquipment) {
        menu.push(
          { title: this.$t('button.edit'), action: () => this.editEquipment(  ) },
          { title: this.$t('section.equipment.tree.editSpecification'), action: () => this.editEquipmentSpecification() },
          { title: this.$t('button.delete'), action: () => this.confirmDeleteEquipment() }
        )
      }

      return menu
    },

    groupMenu () {
      if (this.hasMaintainceEquipment) {
        return [
          { title: this.$t('button.add'), action: () => this.addGroup() },
          { title: this.$t('button.edit'), action: () => this.editGroup() },
          { title: this.$t('button.delete'), action: () => this.confirmDeleteGroup() },
          { title: this.$t('section.equipment.tree.installEquipment'), action: () => this.installEquipment() },
        ]
      }
      return []
    },

    preparedGroups () {
      let filteringUUIDs = this.selectedGroup ? [this.selectedGroup.uuid] : []
      return this.tree.flatlist.filter(i => i.isGroup).map(original => {
        let m = { ...original }
        if (filteringUUIDs.includes(m.uuid)) {
          m.disabled = true
          filteringUUIDs.push(...m.children.map(i => i.uuid))
        }

        let fulltext = []
        for (let i = 0; i < m.level; i++) fulltext.push('| - ')
        fulltext.push(m.title)
        m.text = fulltext.join('')
        return m;
      })
    }

  },

  methods: {
    ...mapActions('maintenance', ['addEquipmentGroup', 'saveEquipmentGroup', 'deleteEquipmentGroup', 'addEquipment', 'deleteEquipment', 
                  'saveEquipmentSpecification', 'openNode']),
    ...mapMutations('maintenance', ['setSelectedGroup', 'setSelectedEquipment']),


    showContextMenu(event, item) {
      if (item.isGroup) {
        this.setSelectedGroup(item) 
        this.setSelectedEquipment(null) 
      } 
      else {
        this.setSelectedGroup(null) 
        this.setSelectedEquipment(item) 
      }
      this.$refs.contextMenu.show(event)
    },

    addGroup() {
      this.addEquipmentGroup(this.selectedGroup)
      this.writingEditeGroup = this.editedGroup
      this.dialog.saveGroup = true
    },

    editGroup() {
      this.writingEditeGroup = this.editedGroup
      this.dialog.saveGroup = true
    },

    confirmDeleteGroup() {
      this.dialog.deleteGroup = true
    },

    addRootGroup() {
      this.addEquipmentGroup()
      this.dialog.saveGroup = true
      this.writingEditeGroup = this.editedGroup
    },

    installEquipment() {
      this.addEquipment()
      this.dialog.saveEquipment = true
    },

    editEquipment() {
      this.dialog.saveEquipment = true
    },

    confirmDeleteEquipment() {
      this.dialog.deleteEquipment = true
    },

    editEquipmentSpecification() {
      this.dialog.editEquipmentSpec = true
    },

    openEquipment(item) {
      this.setSelectedEquipment(item) 
      this.openNode(item.equipmentGroup)
    }

  }
}
</script>

<style scoped>

</style>

