<template lang="pug">
  v-card.table-container.base-scroll-body
    v-data-table(
      light
      fixed-header
      class="flex-table"
      :headers="translationHeaders"
      :items="visibleTask"
      :hide-default-footer="true"
      :items-per-page = "visibleTask.length"
      :sort-by.sync= "sortBy"
    )
      template( v-slot:item.title="{ item }" )
        TaskTreeRowTheme.table__td.pointer( :task='item' :flat='isFilter || !!sortBy.length' :open='taskTreeCache.isOpened(item)' test-id="theme-table-tree" @expand='toggleOpen(item)' @click.native='setSelected(item)')
      template( v-slot:item.completeStatus="{ item }" )
        TaskTreeRowStatus.table__td( :task='item')
      template( v-slot:item.progress="{ item }" )
        TaskTreeRowProgress.table__td.sizeW( :task='item' @click.native.stop='reportTask = item' )
      template( v-slot:item.workers="{ item }" )
        TaskTreeRowExecutor.table__td( :task='item' )
      template( v-slot:item.startDate="{ item }" )
        TaskTreeRowDate.table__td( :task='item' test-id="start-date-table-tree" start )
      template( v-slot:item.endDate="{ item }" )
        TaskTreeRowDate.table__td( :task='item')

    task-report(v-if="reportTask" @close="reportTask = null" :selectedTask="reportTask" )
</template>


<script>
import TaskTreeRow from './TaskTreeRow.vue'
import TaskTableHeader from './TaskTableHeader.vue'
import TaskTreeTaskDetails from './TaskTreeTaskDetails.vue'
import TaskDetailsSection from '@/components/task/TaskDetailsSection'
import TaskReport from '@/components/task/taskReport/TaskReport'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { Task } from '@/assets/model/task/Task'
import DraggableVirtualList from 'vue-draggable-virtual-scroll-list'

import TaskTreeRowExecutor from './components/TaskTreeRowExecutor'
import TaskTreeRowProgress from './components/TaskTreeRowProgress'
import TaskTreeRowStatus from './components/TaskTreeRowStatus'
import TaskTreeRowTheme from './components/TaskTreeRowTheme'
import TaskTreeRowDate from './components/TaskTreeRowDate'
import draggable from 'vuedraggable'

import _ from "lodash";
import { api } from '@/api'

import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'

export default {
  name: "TaskTable",
  
  components: { 
    TaskTreeRow, 
    TaskTableHeader, 
    TaskTreeTaskDetails, 
    TaskDetailsSection,
    TaskReport,
    DraggableVirtualList,

    TaskTreeRowExecutor,
    TaskTreeRowProgress,
    TaskTreeRowStatus,
    TaskTreeRowTheme,
    TaskTreeRowDate,
    draggable,
  },

  props: {
    tasks: Array,
    headers: Array
  },
  
  data() {
    return {
      sort: {
        option: null,
        reverse: true
      },
      dialog:{
        progress: false,
      },
      openReportTask: null,
      treeRowComponent: TaskTreeRow,
      startTaskIndex: null,
      taskPosition: null,

      elementHeight: 36,
      firstRowHeight: 0,
      lastRowHeight: 0,
      lastScrollPosition: window.scrollY,
      startIndex: 0,
      step: 1,
      tasksVirtual: [],
      reportTask: null,
      openList: [],
      sortBy: [],
    }
  },

  created() {
    _.debounce(() => {
      this.handleScroll()
    }, 100)
  },

  mounted() {
    this.tasksVirtual = [...this.taskTreeCache.tree.tasks]
    // this.initTable();
    // this.$refs.table.addEventListener("scroll", this.handleScroll);
    this.openList = this.tasks.filter(t => t.children.length).map(t => t.uuid)
  },
  
  // beforeDestroy() {
  //   this.$refs.table.removeEventListener("scroll", this.handleScroll);
  // },

  computed: {
    ...mapState('task', ['selectedTask', 'taskTreeCache', 'filter']),
    ...mapGetters('task', ['taskTreeIsOpened', 'filteredTasks']),
    ...mapGetters("theme", ['themeName']),

    translationHeaders(){
      return this.headers.filter(header => header.show).map(header =>({
        ...header,
        text: this.$t(header.text)
      }))
    },

    sortedTasks() {
      if(this.sort.option){
        return this.sortArray(this.flatList, this.sort)
      }
      return this.taskTree
    },

    activeHeaders(){
      return this.headers.filter(header => header.show)
    },
    
    taskTree() {
      return this.sortChildren(this.tasks)
    },

    isFilter() {
      return Object.keys(this.filter).filter(key => key !== 'project').some(key => Array.isArray(this.filter[key]) ? this.filter[key].length : !!this.filter[key]) 
    },

    flatList() {
      return this.getTasksFlatSortedList(this.tasks).map(task => {
        task.children = []
        task.level = 0
        return task
      })
    },

    renderTasks() {
      if(this.isFilter){  
        return this.filteredTasks
      } else {
        return this.tasks
      }
      // const filterTasks = (this.taskTreeCache.tree.tasks, task => task.uuid)
      // return filterTasks.slice(this.startIndex, this.startIndex + this.step * 2)
    },

    visibleTask(){
      if(this.isFilter){  
        return this.filteredTasks
      } else {
        let parentTasks = this.tasks.filter(task => {
          let show = !task.parent
          if(show){
            return show
            }
            let taskParent = null
            for (let i=0; i < task.level; i++ ){
              taskParent = this.tasks.find(t => t.uuid === (taskParent?.parent || task.parent ))
              show = this.openList.includes(taskParent?.uuid)
              if(!show){
                break
              }
            }
            return show 
        })
        return parentTasks
      }

    },

  },

  methods: {
    ...mapActions('task', ['reorderTaskChildren', 'reorderTask', 'taskOutOfParent']),
    ...mapMutations('task', ['handleTreeClose', 'handleTreeOpen', 'setSelectedTask', 'addSubTaskOpen']),

    initTable() {
      const tableTop = this.$refs.table.getBoundingClientRect().top - 30;
      const viewPortY = document.documentElement.clientHeight;
      if (tableTop > 0) {
        this.step = Math.floor((viewPortY - tableTop) / this.elementHeight);
      } else {
        this.step = Math.floor(viewPortY / this.elementHeight);
        this.startIndex = Math.floor(-tableTop / this.elementHeight);
      }

      this.firstRowHeight = this.startIndex * this.elementHeight;
      
      const filterTasks = _.uniqBy(this.taskTreeCache.tree.tasks, task => task.uuid)
      this.lastRowHeight = filterTasks.length * this.elementHeight - this.step * this.elementHeight;
    },

    handleScroll() {
      const top = this.$refs.tbody.getBoundingClientRect().top - 30;
      const viewportY = document.documentElement.clientHeight;
      if (top < 0) {
        this.step = Math.floor(viewportY / this.elementHeight);
        this.startIndex = Math.floor(-top / this.elementHeight);
      } else {
        this.startIndex = 0;
        this.step = Math.floor((viewportY - top) / this.elementHeight);
      }

      this.$nextTick(() => {
        this.firstRowHeight = this.startIndex * this.elementHeight;
        
        const filterTasks = _.uniqBy(this.taskTreeCache.tree.tasks, task => task.uuid)
        this.lastRowHeight = filterTasks.length * this.elementHeight - this.step * this.elementHeight - this.firstRowHeight;
        this.lastRowHeight = this.lastRowHeight > 0 ? this.lastRowHeight : 0
      });
    },

    toggleOpen(task) {
      if( this.openList.includes(task.uuid) ){
        let index = this.openList.indexOf(task.uuid)
        this.openList.splice(index, 1)
      } else {
        this.openList.push(task.uuid)
      }      
      if (this.taskTreeIsOpened(task)) {
        this.handleTreeClose(task)
      }
      else {
        this.handleTreeOpen(task)
      }
      // const filterTasks = _.uniqBy(this.taskTreeCache.tree.tasks, task => task.uuid)
      // this.lastRowHeight = filterTasks.length * this.elementHeight - this.step * this.elementHeight;
      // this.lastRowHeight = this.lastRowHeight > 0 ? this.lastRowHeight : 0
    },

    // setDefault(set) {
    //   set && this.taskTreeCache.sort("DEFAULT")
    // },

    setSelected(task) {
      this.setSelectedTask(task)
      this.addSubTaskOpen(this.selectedTask.uuid)
      this.loadTaskType(this.selectedTask)
      TaskBimAnnotations.showTaskBimAnnotations()
    },

    // sortBy(option) {
    //   let order = !this.taskTreeCache.sort_option.reverse
    //   this.taskTreeCache.sort(option, order)
    // },
    
    getTasksFlatSortedList(tree) {
      let array = []
      tree.forEach(element =>{
        var task = new Task(element)
        array.push(task, ...this.getTasksFlatSortedList(task.children))
      })
      return array
    },

    openReport(task) {
      this.openReportTask=task
      this.dialog.progress=true
    },

    sortArray(array, sort) {
      let sorted = []
      switch(sort.option){
        case "STATUS":
          sorted = array.sort((task, t) => task.completeStatus.value - t.completeStatus.value)
          return sort.reverse? sorted.reverse(): sorted
        case "PROGRESS":
          sorted = array.sort((task, t) => task.progress - t.progress)
          return sort.reverse? sorted.reverse(): sorted
        case "START":
          sorted = array.sort((task, t) => new Date(t.createDate) - new Date(task.createDate))
          return sort.reverse? sorted.reverse(): sorted
        case "END":
          sorted = array.sort((task, t) => new Date(t.endDate) - new Date(task.endDate))
          return sort.reverse? sorted.reverse(): sorted
      }
    },

    defaultView(state = false) {
      if(state)
        this.sort.option = null
    },

    showModel (event) {
      this.$refs.taskDetailsSec.showModel(event)
    },
    
    showPriorityMenu (event) {
      this.$refs.taskDetailsSec.showPriorityMenu(event)
    },

    showStatusMenu (event) {
      this.$refs.taskDetailsSec.showStatusMenu(event)
    },

    showResponderDialog(event){
      this.$refs.taskDetailsSec.showResponderDialog(event)
    },

    takeScreenshotToTask() {
      this.$refs.taskDetailsSec.takeScreenshotToTask()
    },

    showAttachmentDialog (event) {
      this.$refs.taskDetailsSec.showAttachmentDialog(event)
    },

    showDeleteTaskDialog(){
      this.$refs.taskDetailsSec.showDeleteTaskDialog()
    },

    sortChildren(childrens){
      return childrens.map(children => {
        if(children.children.length > 0){
          this.sortChildren(children.children)
        }
        return children
      }).sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime())
    },

    handleDragStart(event) {
      console.log("DRAG STARTED")
      let items = this.taskTreeCache.tree.tasks

      let index = event.item.getAttribute('data-index')
      let choosenItem = items[index]

      let simillarLevelItems = items.filter(item => item.level === choosenItem.level && item.parent === choosenItem.parent)

      simillarLevelItems.map(item => {
        if(this.taskTreeIsOpened(item)) {
          this.taskTreeCache.close(item)
        }
      })
      console.log("DRAG END")
    },

    handleDragEnd(event) {
      const newIndex = event.realNewIndex
      const oldIndex = event.realOldIndex

      let visibleItems = this.taskTreeCache.tree.tasks
      let oldItem = visibleItems[oldIndex]
      let newItem = visibleItems[newIndex]

      if (oldItem.level !== newItem.level) {
        return
      }
      const temp = visibleItems[newIndex]
      visibleItems.splice(newIndex, 1, visibleItems[oldIndex])
      visibleItems.splice(oldIndex, 1, temp)
    },
    
    handleUpdate(tasks) {
      let taskUuid = tasks.map(task => task.uuid)
      this.reorderTaskChildren(taskUuid)
    },

    startDrag(task) {
      if (task.children && this.taskTreeIsOpened(task)){
        this.handleTreeClose(task)
      }
      this.startTaskIndex = this.taskTreeCache.tree.tasks.indexOf(task)
    },

    overDrag(event){
      this.taskPosition = event.y
      event.preventDefault()
    },
    
    endDrag(dragEvent, task) {
      let dragEndY = this.taskPosition - dragEvent.target.parentElement.getBoundingClientRect().top
      let elementHeight = dragEvent.target.clientHeight
      let taskList = []
      let endIndex = ((dragEndY - (dragEndY % elementHeight)) / elementHeight)
      let startElement = this.taskTreeCache.tree.tasks[this.startTaskIndex]

      if (endIndex > this.taskTreeCache.tree.tasks.length) {
        let newTaskList = this.taskTreeCache.tree.tasks.filter(task => task.uuid != startElement.uuid)
        taskList = [...newTaskList, startElement]
      }
      else if (endIndex <= 0) {
        let newTaskList = this.taskTreeCache.tree.tasks.filter(task => task.uuid != startElement.uuid)
        taskList = [startElement, ...newTaskList]
      }
      else {
        let newTaskList = this.taskTreeCache.tree.tasks.filter(task => task.uuid != startElement.uuid)
        const before = newTaskList.slice(0, endIndex)
        const after = newTaskList.slice(endIndex)

        taskList = [...before, startElement, ...after]
      }
      if (task.parent == this.taskTreeCache.tree.tasks[endIndex].parent){
        this.taskTreeCache.tree.tasks = [...taskList]
        this.handleUpdate(taskList)
      }
      else{
        if (!this.taskTreeCache.tree.tasks[endIndex].parent){
          this.taskTreeCache.tree.tasks = [...taskList]
          this.taskOutOfParent({uuid: task.uuid}).then(() => this.handleUpdate(taskList))
          // this.handleUpdate(taskList)
          // this.reorderTask({uuid: task.uuid, order: endIndex, parent: null})
          
        }
        else {
          let parentTask = this.taskTreeCache.tree.tasks.find(task => task.uuid == this.taskTreeCache.tree.tasks[endIndex].parent)
          let endChildIndex = parentTask.children.findIndex(task => task.uuid == this.taskTreeCache.tree.tasks[endIndex].uuid)
          this.reorderTask({uuid: task.uuid, order: this.startTaskIndex < endIndex ? endChildIndex + 1 : endChildIndex, parent: parentTask.uuid})
          this.taskTreeCache.tree.tasks = [...taskList]
        }
      }
        
    },

    loadTaskType(task) {
      if(task?.uuid && task.uuid != null && task.taskType && (task.taskType.uuid == null || task.taskType.uuid == undefined)) {
        api.forms.dataList(task.uuid).then(data => {
          if(data.length > 0){
            this.$store.commit('task/setSelectedTaskType', {taskType: data[data.length-1]})
          }
        })
      }
    },
  }
}
</script>

<style scoped>
  .table__td {
    height: 36px;
    color: black;
    white-space: nowrap
  }
  .sizeW{
    width: 120px;
  }
  ::v-deep tbody td:first-child{
    width: 100%;
    max-width: 360px;
  }
  .table-container{
    overflow-wrap: unset;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  .flex-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--v-scroll-base);
  }

  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background-color: var(--v-accent-base);
  }
  ::v-deep .v-data-table__wrapper{
    min-height: 100%
  }

@-moz-document url-prefix() {
  .v-data-table__wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--v-accent-base) var(--v-scroll-base)
  }
}
</style>