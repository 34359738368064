<template lang="pug">
  div
    expansion-button( :buttonHeader="$t('section.structure.model.setOffsetTurn')" )
      coordinates-menu( :withLocker="true" :realOffsetCoord="getRealOffsetCoord" :realEulerCoord="getRealEulerCoord" :offset="getOffsetCoord" :euler="getEulerCoord"
                         @saveCoord="saveChanges" @viewCoord='viewChanges')
</template>

<script>
import CoordinatesMenu from './CoordinatesMenu.vue'
import ExpansionButton from './ExpansionButton.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@app/AlertService'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'

import { math } from '@xeokit/xeokit-sdk'
import { MODEL_TRANSFORM_ID } from '@/plugins/xeokit/RevisionLoader'

export default {
  name: 'ModelCoords',

  components: {
    CoordinatesMenu, 
    ExpansionButton
  }, 

  computed: {
    ...mapState('project', ['selectedModel', 'project']),
    ...mapGetters('project', ['flatlist']),

    getRealOffsetCoord() {
      return { x: this.selectedModel.realOffsetX, y: this.selectedModel.realOffsetY, z: this.selectedModel.realOffsetZ}
    }, 

    getRealEulerCoord() {
      return { x: this.selectedModel.realEulerX, y: this.selectedModel.realEulerY, z: this.selectedModel.realEulerZ }
    }, 

    getOffsetCoord() {
      return { x: this.selectedModel.offsetX, y: this.selectedModel.offsetY, z: this.selectedModel.offsetZ }
    }, 

    getEulerCoord() {
      return {x: this.selectedModel.eulerX, y: this.selectedModel.eulerY, z: this.selectedModel.eulerZ}
    },
  },

  watch: {
    selectedModel(selectedModel, oldSelectedModel) {
      this.restoreModelTransform(oldSelectedModel)
      this.setAtModelPositioning(false)

      this.modelForPositioning = selectedModel
    },
  },

  mounted() {
    this.modelUnloadedSubId = XeokitMediator.viewer.scene.on('modelUnloaded', revisionUuid => {
      if (this.selectedModel?.revision?.uuid === revisionUuid) {
        this.restoreModelTransform(this.selectedModel)
        this.setAtModelPositioning(false)
      }
    })

    this.modelForPositioning = this.selectedModel
  },

  beforeDestroy() {
    XeokitMediator.viewer.scene.off(this.modelUnloadedSubId)

    this.setAtModelPositioning(false)
    this.restoreModelTransform(this.modelForPositioning)
  },

  methods: {
    ...mapActions('project', ['editModelOffset', 'updateProject']), 
    ...mapMutations('project', ['setAtModelPositioning']), 

    restoreModelTransform(model) {
      const sceneModel = XeokitMediator.viewer.scene.models[model.revision?.uuid]
      if (sceneModel) {
        sceneModel.position = sceneModel.rotation = [0, 0, 0]
      }
      model.model.forEach((model) => this.restoreModelTransform(model))
    },

    async viewChanges(modelPosition) {

      this.setAtModelPositioning(true)
      
      if (this.selectedModel.inheritOffset) {
        for (let i = 2; i <= this.selectedModel.stack.length; i++) {
          const parentModelUuid = this.selectedModel.stack[this.selectedModel.stack.length - i]
          const parentModel = this.flatlist.find(model => model.uuid === parentModelUuid)

          const parentPosition = [parentModel.realOffsetX, parentModel.realOffsetY, parentModel.realOffsetZ]
          // const parentRotation = [parentModel.realEulerX, parentModel.realEulerY, parentModel.realEulerZ] // Наследование отключили
          const parentRotation = [0, 0, 0]

          const childPosition = [modelPosition.offsetX, modelPosition.offsetY, modelPosition.offsetZ]
          const childRotation = [modelPosition.eulerX, modelPosition.eulerY, modelPosition.eulerZ]

          const inherited = inheritTransform(parentRotation, parentPosition, childRotation, childPosition)

          modelPosition = {
            eulerX: inherited.rotation[0],
            eulerY: inherited.rotation[1],
            eulerZ: inherited.rotation[2],

            offsetX: inherited.position[0],
            offsetY: inherited.position[1],
            offsetZ: inherited.position[2],
          }

          break
        }
      }

      const applyTransform = (selectedModel, modelPosition) => {
        const sceneModel = XeokitMediator.viewer.scene.models[selectedModel.revision?.uuid]

        if (sceneModel && selectedModel.revision?.xktversion === 10) {
          let { eulerX, eulerY, eulerZ, offsetX, offsetY, offsetZ } = modelPosition 

          const modelTransform = sceneModel.transforms[MODEL_TRANSFORM_ID]

          const inverseMatrix = math.mat4()
          math.inverseMat4(modelTransform.worldMatrix, inverseMatrix)

          const position =   math.vec3()
          const quaternion = math.vec4()
          const scale =      math.vec3()

          math.decomposeMat4(inverseMatrix, position, quaternion, scale)

          const q1 = math.vec4()
          math.eulerToQuaternion([eulerX, eulerY, eulerZ], 'XYZ', q1)

          const q2 = math.vec4()
          math.mulQuaternions(q1, quaternion, q2)

          const mat1 = math.mat4()
          math.quaternionToMat4(q1, mat1)

          const rotatedPosition = math.vec3()
          math.transformVec3(mat1, position, rotatedPosition)

          const translation = [offsetX, offsetY, offsetZ]
          const newPosition = [
            rotatedPosition[0] + translation[0],
            rotatedPosition[1] + translation[1],
            rotatedPosition[2] + translation[2]
          ]

          sceneModel.quaternion = q2
          sceneModel.position = newPosition
        }

        selectedModel.model.filter(model => model.inheritOffset).forEach(model => {
          const parentPosition = [modelPosition.offsetX, modelPosition.offsetY, modelPosition.offsetZ]
          // const parentPosition = [modelPosition.eulerX, modelPosition.eulerY, modelPosition.eulerZ] // Наследование отключили
          const parentRotation = [0, 0, 0]

          const childPosition = [model.offsetX, model.offsetY, model.offsetZ]
          const childRotation = [model.eulerX, model.eulerY, model.eulerZ]

          const inherited = inheritTransform(parentRotation, parentPosition, childRotation, childPosition)

          applyTransform(model, {
            eulerX: inherited.rotation[0],
            eulerY: inherited.rotation[1],
            eulerZ: inherited.rotation[2],

            offsetX: inherited.position[0],
            offsetY: inherited.position[1],
            offsetZ: inherited.position[2],
          })
        })
      }

      applyTransform(this.selectedModel, modelPosition)
    },


    saveChanges(modelPosition, sendData) {
      const model = this.selectedModel

      this.editModelOffset(sendData).then((data) => {
        this.setFormValue(modelPosition)

        ;(function destroy(model) {
          XeokitMediator.viewer.scene.models[model.revision?.uuid]?.destroy()
          model.model.filter(model => model.inheritOffset).forEach(destroy)
        })(model)
        this.setAtModelPositioning(false)

        this.updateProject(data)

        TaskBimAnnotations.clearTaskBimAnnotations()

        AlertService.success({ info: this.$t('section.structure.model.revisionMoving.successSave') })
      }).catch(e => {
          this.setFormValue(modelPosition) 
          AlertService.error(e.response)
      })
    }, 

    setFormValue(form) {
      this.selectedModel.offsetX = form.offsetX
      this.selectedModel.offsetY = form.offsetY
      this.selectedModel.offsetZ = form.offsetZ
      this.selectedModel.eulerX = form.eulerX
      this.selectedModel.eulerY = form.eulerY
      this.selectedModel.eulerZ = form.eulerZ
      this.selectedModel.realOffsetX = form.realOffsetX
      this.selectedModel.realOffsetY = form.realOffsetY
      this.selectedModel.realOffsetZ = form.realOffsetZ
      this.selectedModel.realEulerX = form.realEulerX
      this.selectedModel.realEulerY = form.realEulerY
      this.selectedModel.realEulerZ = form.realEulerZ
    }
  }, 
}

function inheritTransform(parentRotation, parentPosition, childRotation, childOffset) {
  const parentQuaternion = math.vec4()
  math.eulerToQuaternion(parentRotation, 'XYZ', parentQuaternion)

  const parentQuaternionMat = math.mat4()
  math.quaternionToMat4(parentQuaternion, parentQuaternionMat)
  
  const rotatedOffset = math.vec3()
  math.transformVec3(parentQuaternionMat, childOffset, rotatedOffset)

  const inheritedPosition = [
    parentPosition[0] + rotatedOffset[0],
    parentPosition[1] + rotatedOffset[1],
    parentPosition[2] + rotatedOffset[2]
  ]

  const inheritedRotation = [
    parentRotation[0] + childRotation[0],
    parentRotation[1] + childRotation[1],
    parentRotation[2] + childRotation[2]
  ]

  return { position: inheritedPosition, rotation: inheritedRotation }
}
</script>