<template lang="pug">
app-panel( ident="modelvisualsettings" v-bind="$attrs" )
  task-header.mb-2( :title="$t('modelVisualSettings.modelVisualSettings')" headerItemsCenter mainTitle )
    template( v-slot:headerAfter )
      v-tooltip( right open-delay=300 )
        template( v-slot:activator="{ on, attrs }" )
          app-icon-button( icon="mdi-feather" @click="goToProjectLightVersion()" ) 

        span {{$t('project.lightVersionBtnTitle')}}

      app-icon-button( icon="mdi-close" @click="handleClose()" )
   
  app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow-x: hidden" ).base-scroll-body
    app-floating-panel( :title="$t('modelVisualSettings.lightControl')" bodyMinHeight="150" :startHeight="20" :settings="floatingPanels('VISUAL_SETTINGS_LIGHTS')" ).mr-1.ml-1
      bim-lights-settings()

    app-floating-panel( :title="$t('modelVisualSettings.controlLightAndQuality')" bodyMinHeight="150" :startHeight="20" :settings="floatingPanels('VISUAL_SETTINGS_QUALITY')" ).mr-1.ml-1
      bim-quality-settings.base-scroll-body()
    
    app-floating-panel( :title="$t('modelVisualSettings.pointCloudVisualSettings')" bodyMinHeight="150" :startHeight="20" :settings="floatingPanels('POINT_CLOUDS_VISUAL_SETTINGS')" ).mr-1.ml-1
      point-cloud-settings.base-scroll-body()
    
    app-floating-panel( :title="$t('modelVisualSettings.taskBimAnnotationsVisibleSettings.title')" bodyMinHeight="150" :startHeight="20" :settings="floatingPanels('POINT_CLOUDS_VISUAL_SETTINGS')" ).mr-1.ml-1  
      v-radio-group.px-2(v-model='projectTaskBimAnnotationsVisibleMode' light)
        v-radio(v-for='mode in taskBimAnnotationsVisibleModes' :key='mode.value.value' :value='mode.value.name' :label='mode.title' color='accent')

    //- Временно перенес аннотации в раздел настроек освещения и качества
    //- app-floating-panel( :title="$t('modelVisualSettings.annotations')" bodyMinHeight="150" :startHeight="20" :settings="floatingPanels('VISUAL_SETTINGS_ANNOTATIONS')" )
    //-   annotations.base-scroll-body()
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import TaskHeader from '@/components/task/TaskHeader.vue'
import BimLightsSettings from './components/bimLights/BimLightsSettings.vue'
import BimQualitySettings from './components/bimQuality/BimQualitySettings.vue'
import Annotations from './components/Annotations.vue'
import PointCloudSettings from './components/pointCloud/PointCloudSettings.vue'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { taskBimAnnotationsVisibleMode } from '@/store/project.module'

export default {
  name: "ModelActionsMenu",

  components: {
    TaskHeader,
    BimLightsSettings,
    BimQualitySettings,
    Annotations,
    PointCloudSettings
  },

  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('project', ['projectUuid']),

    projectTaskBimAnnotationsVisibleMode: {
      get() {
        return this.$store.state.project.taskBimAnnotationsVisibleMode.name
      },
      set(modeName) {
        this.$store.dispatch('project/updateBimAnnotationsVisibleMode', taskBimAnnotationsVisibleMode[modeName])
        TaskBimAnnotations.showTaskBimAnnotations()
      },
    },

    taskBimAnnotationsVisibleModes() {
      return [
        { value: taskBimAnnotationsVisibleMode.ALL, title: this.$t('modelVisualSettings.taskBimAnnotationsVisibleSettings.mode.all') },
        { value: taskBimAnnotationsVisibleMode.FIRST_ONLY, title: this.$t('modelVisualSettings.taskBimAnnotationsVisibleSettings.mode.firstOnly') },
        { value: taskBimAnnotationsVisibleMode.NONE, title: this.$t('modelVisualSettings.taskBimAnnotationsVisibleSettings.mode.none') },
      ]
    },
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('project', ['postPanelsStatus']),

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },

    goToProjectLightVersion() {
      window.location.replace(`/project-light/${this.projectUuid}`)
    }
  }
}
</script>