<template lang='pug'>
div 
  app-section.primary.lighten-5(class="app_s")
    app-text-field( v-model='title' :label='$t("module.task.form.title")' test-id="task-info-title-input" )
    app-select( v-model='type' :label='$t("module.task.form.type")' :items='taskForms' item-text='name' return-object test-id="task-type-selection-input")
    div.d-flex.mt-2(v-if='type')
      app-button.ml-auto(
        action
        :disabled='!type'
        small test-id="task-form-detail-clear-input" 
        @click='clearTypeFields()') {{ $t('button.clear') }}

    app-section.mt-2(v-if='type')
      div(v-for="field in type.fields" style="color: primary")
        field.mt-1( :field="field" :key="field.type + ':' + field.uuid" :valueList="taskTypeFields" )

    div.d-flex.mt-2
      app-button.ml-auto( 
        action
        :disabled='titleStatus' 
        small test-id="task-form-detail-create-input" 
        @click='createTask()' ) {{ $t('button.create') }}
</template>

<script>
import Field from '@/pages/admin/forms/Field.vue'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import { mapActions, mapGetters } from 'vuex'
import { api } from '@/api'

export default {
  name: "CreateTaskForm",
  
  components: {
    Field,
  },

  data() {
    return {
      title: null,
      type: null,

      taskTypeFields: new Map(),
    }
  },

  computed: {
    ...mapGetters('forms', ['forms']),
    ...mapGetters('project', ['projectUuid']),

    titleStatus() {
      if(!this.title) {
        return !this.title
      } else {
        return !this.title.trim()
      }
    },

    taskForms() {
      return this.forms.filter(form => form.formType?.value === 0) 
    },
  },

  methods: {
    ...mapActions('task', ['addTask']),

    clearTypeFields() {
      this.type = null;
    },

    createTask() {
      this.$emit('create')

      const task = {
        title: this.title,
        project: this.projectUuid
      }
      
      this.addTask(task).then(created => {
        TaskBimAnnotations.showTaskBimAnnotations()
        if (this.type) {
          api.forms.fill({
            bind: created,
            form: {
              uuid: this.type.uuid,
            },
            fields: (() => {
              const fields = []
              this.taskTypeFields.forEach(values => {
                fields.push(values)
              })
              this.taskTypeFields.clear()
              return fields
            })()
          }).then(() => {
            this.loadTaskType({uuid: created})
          })
        }
      })
    },

    loadTaskType(task) {
      // if(task?.uuid && task.uuid != null && task.taskType && (task.taskType.uuid == null || task.taskType.uuid == undefined)) {
        api.forms.dataList(task.uuid).then(data => {
          if(data.length > 0){
            this.$store.commit('task/setSelectedTaskType', {taskType: data[data.length-1]})
          }
        })
      // }
    },
  }

}
</script>

<style scoped>
.app_s {
  max-height: 71%;
  overflow: auto;
}
.btn_create:disabled {
  background-color: gray !important; 
  color: white !important;
}
</style>