<template lang="pug">
.comment-body( ref='comment' )
  v-hover( #default='{hover}' )
    .comment
      .drawing-drag-button( ref='dragButton' v-on:contextmenu.stop.prevent )
        v-btn( x-small icon )
          v-icon( color='#4D4D4D' ) mdi-resize-bottom-right
      .circle-baloon
        .inner-circle( :class="{'inner-circle-hover': comment.hover}" ) {{ avatar }}
      .comment-import( v-if='hover && store.commentImportMode' )
        v-btn.flex-grow-1( small icon )
          img( src='/img/project/tools/arrow-left-top-double.svg' )
        span.align-self-center {{ $t('module.drawing.import') }}
      transition( name='full-comment' )
        .full-comment.px-2( v-if='(hover || (store.commentGrabbing && store.commentGrabbing.uuid == comment.uuid)) && !store.commentImportMode'  v-on:contextmenu.stop.prevent )
          .comment-data.d-flex.align-center
            .comment-user-name.mr-2 {{ comment.user.name }}
            .comment-create-date {{ prettyCommentCreateDate }}
          .comment-text {{ comment.text }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DrawingComment } from '@models/drawing/DrawingComment'
import { DateTransform } from '@/assets/utils/dateTransform.js'
import { useDrawingsStore } from '@/pinia';

export default {
  name: "DrawingComment",

  props: {
    comment: Object,
    context: Object,
  },

  computed: {
    ...mapState('task', ['tasks']),

    store: () => useDrawingsStore(),

    avatar() {
      return this.comment.user.name[0]?.toUpperCase()
    },

    prettyCommentCreateDate() {
      return DateTransform.splitDateWithoutTime(this.comment.createDate)
    }
  },

  mounted() {
    if (this.comment.task) {
      this.comment.task = this.comment.task.uuid ? this.comment.task : this.tasks.find(task => task.uuid == this.comment.task)
    }
    this.dragElement(this.$refs.comment, this)
  },

  methods: {
    ...mapActions('task', ['loadTasks']),

    getAbsoluteCoords() {
      return {
        x: (this.comment.x / 100) * this.context.width,
        y: (this.comment.y / 100) * this.context.height,
      }
    },

    // Перенос Комментария
    dragElement(elmnt, self) {
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0
      let wasMoved = false
      let dragButton = self.$refs.dragButton
      if (elmnt) {
        dragButton.onmousedown = dragMouseDown
      } 
      else {
        elmnt.onmousedown = dragMouseDown
      }
      
      function dragMouseDown(e)  {
        if (!self.store.commentImportMode) {
          if (e.button === 2)
            return
          e = e || window.event
          pos3 = e.clientX
          pos4 = e.clientY
          document.onmouseup = closeDragElement
          document.onmousemove = elementDrag
        }
      }

      function elementDrag(e) {
        self.store.setCommentGrabbing(self.comment)
        wasMoved = true
        e = e || window.event
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        let y = (elmnt.offsetTop - pos2)
        let x = (elmnt.offsetLeft - pos1)
        let offsetX = ((self.getAbsoluteCoords().x + x) / self.context.width) * 100
        let offsetY = ((self.getAbsoluteCoords().y + y) / self.context.height) * 100
        if ((offsetX <= 100 && offsetX >= 0) && (offsetY <= 100 && offsetY >= 0 ) ){
          self.comment.x = offsetX
          self.comment.y = offsetY
        }
      }

      function closeDragElement() {
        if (!wasMoved && !self.store.commentImportMode) {
          self.store.changeCommentDialog.comment = new DrawingComment(null, self.comment)
          self.store.getDrawingCommentAttachment(self.store.changeCommentDialog.comment.uuid).then(data => {
              self.store.changeCommentDialog.comment.task = data;
          })
  
          if (self.tasks.length === 0) {
            self.store.tasksIsLoading = true
            self.loadTasks().then(() => {
              self.store.tasksIsLoading = false
            })

          }
          self.store.changeCommentDialog.show = true
        }
        wasMoved = false
        self.store.setCommentGrabbing(null)
        document.onmouseup = null
        document.onmousemove = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.comment-body{
  position: absolute;
  display: flex;
}
.circle-baloon{
  border-radius: 50% 50% 50% 0;
  background: white;
  border: 1px grey solid;
  display: flex;
  place-content: center;
  padding: 4px;
  width: 30px;
  height: 30px;
}
.inner-circle{
  background: #14ae5c;
  display: flex;
  border-radius: 50%;
  place-content: center;
  place-items: center;
  width: 20px;
  height: 20px;
  font-family: 'Roboto';
  color: white;
  transition-duration: .2s;
}
.inner-circle-hover{
  background: red;
}
.comment{
  position: relative;
}
.comment-import{
  position: absolute;
  width: 100px;
  height: 60px;
  background: #FFFFFF;
  top: -10px;
  left: -35px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
  border-radius: 3px;
  opacity: 0.9;
  z-index: -1;
  display: flex;
  flex-direction: column;
  font-size: 10px;
}
.full-comment {
  height: 50px;
  max-width: 300px;
  border-radius: 10px 10px 10px 0px;
  position: absolute;
  top: -20px;
  left: -1px;
  border: 1px grey solid;
  background: white;
}
.comment-user-name {
  max-width: 220px;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 600;
}
.comment-create-date{
  color: #666666;
  font-size: 13px;
}
.comment-text{
  color: black;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -o-user-select: text;
}
.full-comment-enter-active {
  animation: full-comment-animation .1s;
  transform-origin: bottom left;
}
.full-comment-leave-active {
  animation: full-comment-animation .1s reverse;
  transform-origin: bottom left;
}
.drawing-drag-button {
  position: absolute;
  bottom: -3px;
  left: -1px;
  transform: rotateZ(90deg);
  z-index: 1;
}
@keyframes full-comment-animation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.edit-section {
  position: relative;
}

.tasks-loading-panel {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  
  background: white;
  opacity: .8;

  position: absolute;
  top: 0px;
  left: 0px;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.overflow-auto ::v-deep textarea {
  overflow-x: hidden !important;
  margin: 2px 2px 2px 0px !important;
}
</style>