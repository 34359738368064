import { geometry } from '../xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '../xeokit/XeokitMediator'

/*eslint-disable no-dupe-class-members*/
export class IFCAnnotationsDrawer {
  static #_drawedEdges = []
  static #_drawedPoints = []
  static #_drawedTexts = []

  static drawIFCAnnotations(annotData) {
    annotData.forEach((data) => {
      for (let i = 0; i < data.annotations.length; i++) {
        this.drawIFCAnnotation(data.annotations[i])
      }
    })
  }

  static drawIFCAnnotation(annot) {
    let edges = []
    let points = []
    let texts = []

    edges.push(...this.#_getEdgesFromAnnotation(annot.curves, annot.matrix))
    points.push(...this.#_getPointsFromAnnotation(annot.points, annot.matrix))
    texts.push(...this.#_getTextsFromAnnotation(annot.texts, annot.matrix))

    this.#_drawEdges(edges)
    //this.#_drawPoints(points)
    this.#_drawTexts(texts, annot.matrix)
  }

  static clearDrawedEdges() {
    if (this.#_drawedEdges) {
      this.#_drawedEdges.forEach((edge) => {
        edge?.destroy()
      })
    }

    this.#_drawedEdges = []
  }

  static clearDrawedPoints() {
    if (this.#_drawedPoints) {
      this.#_drawedPoints.forEach((label) => {
        label?.destroy()
      })
    }

    this.#_drawedPoints = []
  }

  static clearDrawedTexts() {
    if (this.#_drawedTexts) {
      this.#_drawedTexts.forEach((text) => {
        text?.destroy()
      })
    }

    this.#_drawedTexts = []
  }

  static #_getPointsFromAnnotation(points, matrix) {
    // let uvwArray = []
    // uvwArray.push(...grid.u)
    // uvwArray.push(...grid.v)
    // uvwArray.push(...grid.w)

    // let labels = []

    // for(let i = 0; i < uvwArray.length; i++) {
    //   labels.push(uvwArray[i].label)

    // }
    matrix
    return points
  }

  static #_getTextsFromAnnotation(texts, matrix) {
    let builtText = []
    let newMat = new Float64Array(matrix)

    texts.forEach((text) => {
      let point = geometry.math.mulMat4v3(newMat, [text.point[0], text.point[1], 0])

      let textObj = {
        literal: text.literal,
        point: [point[0], point[1], point[2]],
      }

      builtText.push(textObj)
    })

    return builtText
  }

  static #_drawTexts(texts, matrix) {
    texts.forEach((text) => {
      let textMesh = XeokitMediator.SceneObjects.createTextMesh({
        text: text.literal,
        size: 0.13,
        rotationMatrix: matrix,
        worldPos: text.point,
      })
      this.#_drawedTexts.push(textMesh)
    })
  }

  static #_getEdgesFromAnnotation(pointsArr, matrix) {
    let edges = []

    pointsArr.forEach((edge) => {
      for (let i = 0; i < edge.vertexes.length; i += 6) {
        let p1 = geometry.math.mulMat4v3(matrix, [edge.vertexes[i], edge.vertexes[i + 1], edge.vertexes[i + 2]])
        let p2 = geometry.math.mulMat4v3(matrix, [edge.vertexes[i + 3], edge.vertexes[i + 4], edge.vertexes[i + 5]])

        edges.push([p1, p2])
      }
    })

    return edges
  }

  static #_drawEdges(edges) {
    edges.forEach((edge) => {
      let edgeMesh = XeokitMediator.SceneObjects.createSegmentMesh({
        segmentPositions: edge,
        segmentColor: [0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
        selected: false,
      })
      this.#_drawedEdges.push(edgeMesh)
    })
  }
}
