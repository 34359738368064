import { render, staticRenderFns } from "./DrawingPreview.vue?vue&type=template&id=daf20ace&scoped=true&lang=pug&"
import script from "./DrawingPreview.vue?vue&type=script&lang=js&"
export * from "./DrawingPreview.vue?vue&type=script&lang=js&"
import style0 from "./DrawingPreview.vue?vue&type=style&index=0&id=daf20ace&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daf20ace",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VHover,VImg})
