<template lang="pug">
  div
    v-btn.dict-button( color="accent" dense text x-small @click="showAnnotationDialog()" ) {{ $t('section.structure.model.annotation') }}

    app-dialog( v-model="dialog.annotation" :header="$t('section.structure.model.annotation')" :confirmText="hasProjectReconstruction ? $t('button.add') : $t('button.hide')" @confirm="saveAnnotation()" ) 
      app-section
        app-textarea( v-if="selectedPatch" v-model="newAnnotation" :label="$t('section.structure.model.annotation')")
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

export default {
  mixins: [DialogToggable],
  
  name: "PatchAnnotation",

  data() {
    return {
      dialog: {
        annotation: false
      },

      newAnnotation: ""
    }
  },

  computed: {
    ...mapState('patches', ['selectedPatch']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction"]),
  },

  methods: {
    ...mapActions('patches', ['saveEditedPatch']),
    ...mapMutations('patches', ['setPatchAnnotation']),

    saveAnnotation() {
      if(this.hasProjectReconstruction)
      this.setPatchAnnotation(this.newAnnotation)
      this.saveEditedPatch(this.selectedPatch)
    },

    showAnnotationDialog() {
      this.dialog.annotation = true
      this.newAnnotation = this.selectedPatch.annotation
    }
  }
}
</script>

<style lang="scss" scoped>
.dict-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none;
}
</style>