<template lang="pug">
div 
  div.section-header.mt-3 IDS файлы
  div( v-for="file in rule.idsFiles")
    div.d-flex.align-center.mt-2 
      v-tooltip(bottom)
        template(v-slot:activator="{ on, attrs }" )
          v-hover( v-slot:default="{ hover }" close-delay="150" )
            .text-wrapper( v-bind="attrs" v-on="on" :class="{ 'elevation-1': hover }" ripple ) {{ file.name }}
        span {{ file.name }}
      app-icon-button( icon="mdi-dots-vertical" iconColor="black" @contextmenu="onRuleMenu(file, $event)" @click="onRuleMenu(file, $event)" )
  app-icon-button.float-left.mt-1( icon="$add-box-outlined" iconColor="black" @click="show.importIDSDialog = true" )
  rule-import-ids-dialog( v-model="show.importIDSDialog" )

  app-menu( ref="attributeMenu" :menu="attributeMenu" )

</template>

<script>
import { mapGetters } from 'vuex'
import RuleImportIdsDialog from '@/components/project/panel/left/components/rulesFounder/ruleSetting/RuleImportIDSDialog.vue'
import { AlertService } from '@app/AlertService'
import { api } from "@/api"

export default {
  name: "IfcChecking",

  components: { RuleImportIdsDialog },
  
  data() {
    return {
      show: {
        importIDSDialog: false
      },
      menuItem: null,
    }
  },

  computed: {
    ...mapGetters('rule/details', ['rule']),

    attributeMenu() {
      return [
        { title: this.$t('button.delete'), action: () => this.deleteFile(this.menuItem)},
      ]
    },
  },

  methods: {
    onRuleMenu (item,event) {
      this.menuItem = item
      this.$refs.attributeMenu.show(event)
    },

    deleteFile(file) {
      api.collisionRules.deleteIDS(file.uuid).then(() => {
        let index = this.rule.idsFiles?.findIndex(item => item.uuid === file.uuid)
        this.rule.idsFiles.splice(index, 1)
      }).catch(e => AlertService.info({info: e}))
    },

  }
}
</script>

<style lang="scss" scoped>
  .text-wrapper {
    background-color: #EBEBEB;
    border-radius: 3px;
    font: normal 14px/16px $roboto;
    color: #4D4D4D;
    padding: 8px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .text-wrapper:hover {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  }

  .section-header {
    font: normal 12px/16px $roboto;
    color: #303030;
  }
</style>