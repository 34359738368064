<template lang="pug">
ToolkitButton( :tooltip="$t(tooltipKey)" active @click="toggle" ) {{ icon }}
</template>

<script>
import ToolkitButton from '@/components/buttons/ToolkitButton.vue'
import { useSceneNavigationState } from '@/states/scene/sceneNavigation.state'
const store = useSceneNavigationState()

export default {
  components: {
    ToolkitButton,
  },

  computed: {
    tooltipKey: () => store.isCurrentModePro ? 'navigation.professional' : 'navigation.user',
    icon: () => store.isCurrentModePro ? '$bird-icon' : '$3-d-view-icon',
  },

  methods: {
    toggle: () => store.toggleNavigation(),
  }
}
</script>