import { api } from '@/api'
import { AxisUtils } from '@/assets/model/axis'
import { fetchProjectUuid, fetchProjectHash } from './project.dataProvider'

export async function fetchGroupElementEndpointsUUIDs (groupUuid) {
  let tree = await fetchGroupElementTree(groupUuid)
  let onlyEndpoints = tree.reduce(AxisUtils.onlyEndpointsReducer, [])
  return onlyEndpoints.map(item => item.uuid)
}

export async function fetchGroupElementTree (groupUuid) {
  let projectUuid = fetchProjectUuid()
  let hashProject = fetchProjectHash()
  let tree = await api.axes.fetchElementTree(projectUuid, groupUuid, true, hashProject)
  return tree
}

export async function fetchSortedGroups () {
  let projectUuid = fetchProjectUuid()
  let hashProject = fetchProjectHash()
  let groups = await api.axes.fetchCustomAxisGroup(projectUuid, hashProject)
  // TODO: Нарушение - перенести в кэш либо левее 
  groups.sort((a, b) => a.sortIndex - b.sortIndex)
  return groups
}

export async function deleteGroupWithUUID(uuid) {
  return api.axes.delete(uuid).then(() => uuid)
}

export async function changeGroupPosition(uuid, to) {
  return api.axes.changeGroupPosition(uuid, to)
}