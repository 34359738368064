<template lang="pug">
  tr(@contextmenu="openRowMenu($event, item)" :class="{'select-row': isSelected}")
    td
      v-checkbox(v-model="item.selected" color="accent" dense hide-details :ripple="false" @change="selectItems()")
    td(v-for="header of attributeTableStore.visibleHeaders")
      div(:style="'text-align: ' + header.align") 
        div(v-if="header.value == 'rule'")
          div(v-if="item.level == 0")
            v-btn(icon small @click="toggleGroup(item)")
              v-icon {{ item.isOpen ? 'mdi-menu-down' : 'mdi-menu-up'}}
            span.rule {{item.title}}
          div.ml-4(v-if="item.level == 1")
            v-btn(icon small @click="toggleGroup(item)")        
              v-icon {{ item.isOpen ? 'mdi-menu-down' : 'mdi-menu-up'}} 
            span {{ (item.attrName ? item.attrName : item.title) || item.attribute }}
          div.ml-14.pointer(v-if="item.level == 2" @click="selectElement(item)") 
            span {{item.name}} 
        div(v-if="header.value == 'attr'")
          span(v-if="item.level == 1") {{ item.attrFullName }}
        div(v-if="header.value == 'condition'")
          span(v-if="item.level == 1") {{ item.operand }}
        span(v-else :class="{rule:item.level == 0}") {{item[header.value]}}
</template>

<script>
import { useAttributeCheckingTableStore } from '@/pinia'
import { mapStores } from 'pinia'
import AttributeHighlightService from './AttributeHighlightService.js'

export default {
  props: {
    item: Object,
  },

  computed: {
    ...mapStores(useAttributeCheckingTableStore),

    isSelected(){
      return this.item.uuid == this.attributeTableStore.selectedElementId || this.item.uuid == this.attributeTableStore.selectedAtrrOrRuleUuid
    }
  },

  methods: {
    toggleGroup(item) {
      this.attributeTableStore.changeItemsVisible(item, item.isOpen)
      if (!item.isOpen && item.level == 1 && !item.elementsloaded) {
        this.attributeTableStore.getElements(item)
      }
      item.isOpen = !item.isOpen
    },

    selectItems() {
      let { level, selected, index } = this.item
      let items = this.attributeTableStore.items
      for (let i = index + 1; i < items.length; i++) {
        if (items[i].level <= level) break
        items[i].selected = selected
      }

      let globalIds = new Set()
      items.forEach(el => {
        if(el.level == 2 && el.selected)  globalIds.add(el.uuid)
        if(el.selected && el.globalIds) el.globalIds.forEach(globalIds.add, globalIds)
      })

      AttributeHighlightService.highlightSelectedAttributes([...globalIds])
    },

    selectElement(item) {
      AttributeHighlightService.selectElement(item)
    },

    openRowMenu(event, item) {
      if (item.level == 0 || item.level == 1)
        this.$emit('rowMenu', event, item, true)
    }
  },
}
</script>

<style scoped>
.rule {
  font-weight: bold;
}
.select-row {
  background: var(--v-surface-lighten2) !important;
}
</style>
