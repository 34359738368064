<template lang="pug">
div
  task-section-expansion(:title="`${$t('module.task.models')} (${chipsModel.length})`" :showToggle="getModelPanel" :additionalIcon="addIcon" headerColorDark @additionalIconClick="showModel" @openPanel="setModelPanel($event)")
    div.mx-1
      div.model-selector.d-flex.align-center.px-1.mb-1(v-for="model in chipsModel" :key="model.uuid") 
        div.primary--text.text-truncate {{ model.title }}
        task-button-action.ml-auto( icon="project/tools/delete.svg" size='16' @click="confirmDeleteModel(model)" )

  task-models-select-dialog( v-model='dialog.model' :models='translatedFlatList' :selected-models='selectedTask.models' :header='$t("module.task.addModel")' :confirmText='$t("button.save")' @confirm='addModel($event)' )
  app-dialog-confirm( v-model="dialog.deleteModel" @confirm="deleteModel()" ) 
    app-text {{ $t('module.task.deleteModel', { title: selectedModel ? selectedModel.title : '' }) }}
</template>

<script>
import TaskModelsSelectDialog from '@/components/task/TaskModelsSelectDialog.vue'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'


import { mapGetters, mapActions } from 'vuex'
import { NotificationService } from '@/assets/model/notifications/NotificationService'

export default {
  name: 'TaskModelsSection',

  components: {
    TaskModelsSelectDialog,
    TaskSectionExpansion,
    TaskButtonAction
  },

  props: {
    selectedTask: Object,
  },

  data () {
    return {
      dialog: {
        model: false,
        deleteModel: false,
      },

      selectedModel: null,
    }
  },

  computed:{
    ...mapGetters('project', ['flatlist']),
    ...mapGetters('taskDetails', ['getModelPanel']),

    ...mapGetters('projectPermissions', ['hasTaskCreate']),

    addIcon() {
      if (this.hasTaskCreate) return 'add_box.png'
      return null
    },

    chipsModel() {
      return this.flatlist.filter(model => {
        if (this.selectedTask.models && this.selectedTask.models.find(m => m === model.uuid)) return model
      })
    },

    translatedFlatList () {
      return this.flatlist.map(model => {
        model.name = this.$t(NotificationService.NotificationReplace(model.name))
        return model
      })
    }
  },

  methods:{
    ...mapActions('task', ['setModels']),
    ...mapActions('taskDetails', ['setModelPanel']),
    
    showModel () {
      this.dialog.model = true
    },

    delTaskModels(modelUuid) {
      let models = this.selectedTask.models.filter(item => item !== modelUuid)
      this.setModels({ uuid: this.selectedTask.uuid, models: models})
    },

    addModel(models) {
      this.setModels({ uuid: this.selectedTask.uuid, models })
    },
    
    confirmDeleteModel(model) {
      this.selectedModel = model
      this.dialog.deleteModel = true
    },

    deleteModel() {
      this.delTaskModels(this.selectedModel.uuid)
      this.dialog.deleteModel = false
    },

  }
}
</script>

<style>
.model-selector:hover{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
}
</style>