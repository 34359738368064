<template lang="pug">
  app-panel.format( ident="pluginpanel" :width="panelWidth" left v-bind="$attrs" )
    app-panel-header( :title="$t('section.plugins.default')" :menu="pluginMenu" showClose )
      template( v-if="loadedPlugin" )
        v-spacer
        app-toolbar( horizontal )
          app-toolbar-button( header icon="mdi-cog" :title="$t('plugin.pluginSettings')" @click="showPluginSettings" )
          app-toolbar-button( v-if="loadedPlugin.sharedForCompany" header icon="mdi-share-variant" :title="$t('plugin.menu.removePluginAccess')" @click="sharePluginToOrg" )
          app-toolbar-button( v-else header icon="mdi-share-variant-outline" :title="$t('plugin.menu.sharePluginCompany')" @click="sharePluginToOrg" )
          app-toolbar-button( header icon="mdi-function" title="Справочник" @click="showMethodDialog" )
          app-toolbar-button( header icon="mdi-bug" :title="$t('plugin.check')" @click="validatePlugin" )
          app-toolbar-button( v-if="isAdminPluginType" header icon="mdi-play" :title="$t('plugin.runPlugin')" @click="runPluginFillForm" )
            
    app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )

      app-floating-panel(:title="loadedPlugin ? loadedPlugin.name : $t('plugin.loadOrCreatePlugin') " bodyMinHeight="150" overflowBody="hidden" :startHeight="20" :settings="floatingPanels('PLUGIN_CODE')" )
        v-row(v-if="!loadedPlugin")
          v-col.mt-2.ml-2
            app-button-add( width="100%" :text="$t('plugin.createPlugin')" @click="createPlugin" )
          v-col.mt-2.mr-2
            app-button-add( width="100%" :text="$t('plugin.uploadPlugin')" @click="showPluginList")
        monaco-editor( v-else-if="loadedPlugin && scriptIsLoaded" ref="editor" @methods="showMethodDialog" @save="savePlugin")
        v-progress-linear.mt-2(v-if="running" indeterminate color="accent")
            
      app-floating-panel( v-if="loadedPlugin" :title="$t('plugin.additionalInfo')" bodyMinHeight="150" overflowBody="hidden"  :settings="floatingPanels('PLUGIN_CONSOLE')" )
        element-details(v-if="element" :element="element" :isPlugin="true" @clearPluginConsole='clearConsole' @updatePluginConsole="loadPluginLog")
        plugin-console-header( v-else :menu="mode" @clear="clearConsole" @update="loadPluginLog")
        v-card.base-scroll-body(  tile flat light style="flex:1;")
          v-card-text( ref="consoleFrame" v-html="loadedPluginLog ? loadedPluginLog : ''" style="height: 100%;")

    load-plugin-list-dialog( ref="loadPluginListDialog" @loadPlugin="loadPlugin" )
    plugin-settings-dialog( v-if="formPlugin" v-model="dialog.pluginSettings" :formPlugin="formPlugin" @savePluginSettings="savePluginSettings" )
    delete-plugin-dialog( v-if="pluginToDelete" v-model="dialog.deletePlugin" @aproveDeletePlugin="aproveDeletePlugin" @cancelDeletePlugin="cancelDeletePlugin" :pluginToDelete="pluginToDelete" )
    plugin-function-dialog( ref="pluginFunctionDialog" @insertText="insertTextInEditor")
    plugin-information-dialog( ref="pluginInformationDialog" :pluginResInfoData="pluginResInfoData" )
    plugin-template-dialog( ref="pluginTemplateDialog" @loadPlugin="loadPlugin" )
    plugin-export-dialog( ref="pluginExportDialog" )

    v-snackbar(v-if="error" v-model="errorSnackBar" :multi-line="true" timeout="-1" color="alert") 
      div {{$t('plugin.error')}}: {{ error }}
      template(v-slot:action="{ attrs }")
        v-btn(color="red" icon v-bind="attrs" @click="clearError" :title="$t('plugin.close')")
          v-icon mdi-cancel

    fill-form( v-model="dialog.fillForm" :formId="formUuid" :returnData="true" @filled="afterFormFilled" )

    plugin-repository-components(:pluginActiveInfo.sync="pluginActiveInfo" ref="repository" @loadRepPlugin="loadRepositoryPlugin" @editRepPlugin="editRepositoryPlugin" @deletePlugin="showDeletePlugin")

</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { pluginService } from '@/_services'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { ObjectsMemento } from "@xeokit/xeokit-sdk"
import { AlertService } from '@app/AlertService'
import MonacoEditor from './MonacoEditor.vue'
import ElementDetails from '@/components/project/panel/left/components/element/Details' 
import PluginConsoleHeader from '@/components/project/panel/left/plugin/PluginConsoleHeader';
import PluginRepositoryComponents from '@/components/project/panel/left/plugin/PluginRepositoryComponents'
import FillForm from "@/pages/admin/forms/Form"
import LoadPluginListDialog from '@/components/project/panel/left/plugin/dialogs/LoadPluginListDialog.vue'
import DeletePluginDialog from '@/components/project/panel/left/plugin/dialogs/DeletePluginDialog.vue'
import PluginFunctionDialog from '@/components/project/panel/left/plugin/dialogs/PluginFunctionDialog.vue'
import PluginInformationDialog from '@/components/project/panel/left/plugin/dialogs/PluginInformationDialog.vue'
import PluginSettingsDialog from '@/components/project/panel/left/plugin/dialogs/PluginSettingsDialog.vue'
import PluginTemplateDialog from './dialogs/PluginTemplateDialog.vue'
import PluginExportDialog from './dialogs/PluginExportDialog.vue'

export default {
  name: 'Plugins',
  components: {
    MonacoEditor,
    ElementDetails,
    PluginConsoleHeader,
    PluginRepositoryComponents,
    FillForm,
    LoadPluginListDialog,
    DeletePluginDialog,
    PluginFunctionDialog,
    PluginInformationDialog,
    PluginSettingsDialog,
    PluginTemplateDialog,
    PluginExportDialog
  },

  data() {
    return {
      formPlugin: null,
      dialog: {
        pluginSettings: false,
        deletePlugin: false,
        fillForm: false,
      },
      formParam: {},

      pluginResInfoData: '',

      pluginActiveInfo: null,

      pluginToDelete:null,
      objectsMemento: new ObjectsMemento(),
      running: false,
      errorSnackBar: false,
      error: null,

      formUuid: null,
    }
  },

  mounted() {
    this.init()
    this.templateINIT(this.project.uuid)

    this.resizeCanvas()
  },

  beforeDestroy() {
    if(this.activeGlobalTab !== 'empty') {
      document.getElementById("myAxisCanvas").style.paddingBottom = 0
    }
  },

  computed: {
    ...mapState('authUser', ['user']),
    ...mapState('project', ['project', 'activeGlobalTab']),
    ...mapState('plugins', ['loadedPlugin','loadedPluginCode','loadedPluginLog','scriptIsLoaded']),

    ...mapGetters('authUser', ['isAdmin']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('axis/tree', ['element']),

    ...mapGetters('floatingPanels', ['floatingPanels']),

    mode() {
      this.setPluginConsole(true)
      return [{ title: this.$t('plugin.console')}]
    },

    isAdminPluginType() {
      switch (this.loadedPlugin.type.name) {
        case 'SAMPLE':
        case 'BUSINESS_PROCESS':
        case 'REPORT':
        case 'ANALYTICAL_REPORT':
          return true
      }
      return false
    },
    
    panelWidth() {
      return window.innerWidth / 2 - 45
    },

    viewer () {
      return XeokitMediator.viewer
    },

    objectIds () {
      return this.viewer.scene.objectIds
    },

    pluginMenu () {
      let act = []
      if (this.loadedPlugin) {
        act.push(
          { title: this.$t('plugin.createPlugin'), action: this.createPlugin},
          { title: this.$t('plugin.uploadPlugin'), action: this.showPluginList },
          { title: this.$t('plugin.uploadPluginTemplate'), action: this.showPluginTemplateList },
          { divider: true },
          { title: this.$t('plugin.menu.savePlugin'), action: this.savePlugin }
        )
        
        if (this.isAdmin) 
          act.push({ title: this.$t('plugin.pluginTemplate.exportBtn'), action: this.showExportPlugin })

        act.push(
          { title: this.$t('plugin.pluginSettings'), action: this.showPluginSettings },
          { title: this.$t('plugin.info.deletePlugin'), action: this.showDeletePlugin },
          { divider: true },
          { title: this.$t('plugin.publishPlugin'), action: this.showPublishDialog },
          { title: this.$t('plugin.info.publicRepositoryPlugin'), action: this.showRepPluginList },
          { title: this.loadedPlugin.sharedForCompany ? this.$t('plugin.menu.removePluginAccess') : this.$t('plugin.menu.sharePluginCompany'), action: this.sharePluginToOrg }, 
        )
        if(this.loadedPlugin.sharedForCompany)
          act.push(
            { divider: true },
            { title: this.$t('plugin.cloneCopanyPlugin'), action: this.cloneOrgPlugin }
          )
      } else {
        act.push(
          { title: this.$t('plugin.createPlugin'), action: this.createPlugin },
          { title: this.$t('plugin.uploadPlugin'), action: this.showPluginList },
          { title: this.$t('plugin.uploadPluginTemplate'), action: this.showPluginTemplateList },
          { title: this.$t('plugin.info.publicRepositoryPlugin'), action: this.showRepPluginList }
        )
      } 

      return act
    },

    pluginPanel() {
      return this.floatingPanels('PLUGIN_CODE').height
    },
  },

  watch:{
    error(val){
      this.errorSnackBar = false
      if (val) this.errorSnackBar = true
    },

    'dialog.deletePlugin'(val){
      if (!val) this.pluginToDelete = null
    },
  },

  methods: {
    ...mapActions('plugins',['INIT', 'LOAD_PLUGINS', 'LOAD_PLUGINS_BY_PROFILE', 'LOAD_TABLE', 'LOAD_REPOSITORY_PLUGINS', 'sharePluginForCompany', 'clonePluginToProject', 'getValidatePlugins', 'getDeniedPlugins', 'getMethodsByPluginType', 'getApiModels', 'getPluginsTemplate']),
    ...mapMutations('plugins',['setLoadedPlugin','setLoadedPluginCode','setLoadedPluginLog','setPluginTable', 'setPluginConsole', 'setScriptIsLoaded']),
    ...mapActions('template', {templateINIT:'initPlugin'}),
    ...mapActions('forms', ['loadFormForPlugin']),

    resizeCanvas() {
      document.getElementById("myCanvas").width = window.innerWidth - this.panelWidth
      document.getElementById("measurementScrim").style.paddingLeft = this.panelWidth + 'px'
    },

    selectElements(arr){
      XeokitMediator.ElementsSelection.selectElements([])
      if (arr && arr.length > 0) XeokitMediator.ElementsSelection.selectElements(arr)
    },

    sharePluginToOrg() {
      let info = this.loadedPlugin.sharedForCompany ? this.$t('plugin.alert.pluginAccessRemove') : this.$t('plugin.alert.pluginPublishCompany')
      this.sharePluginForCompany(this.loadedPlugin.uuid)
      .then(AlertService.info({info: info}))
    },

    init(){
      this.INIT()
      this.loadPluginsList()
      this.getPluginsTemplate()
      this.loadFormForPlugin(this.projectUuid)
      this.getApiModels()
    },

    showPublishDialog() {
      this.$refs.repository.showPublishDialog() 
    },
    
    loadPluginsList() {
      this.LOAD_PLUGINS(this.projectUuid)
      this.LOAD_PLUGINS_BY_PROFILE()
    },

    loadRepositoryPluginList() {
      this.LOAD_REPOSITORY_PLUGINS()
      this.getValidatePlugins()
      this.getDeniedPlugins()
    },

    showPluginList() {
      this.loadPluginsList()
      this.$refs.loadPluginListDialog.show()
    },

    showPluginTemplateList() {
      this.$refs.pluginTemplateDialog.show()
    },

    showExportPlugin() {
      this.$refs.pluginExportDialog.show()
    },

    showRepPluginList(){
      this.loadRepositoryPluginList()
      this.$refs.repository.showRepPluginList()
    },

    cloneOrgPlugin(){
      let pluginUuid = this.loadedPlugin.uuid
      this.cleanLoadedPlugin()
      
      this.clonePluginToProject({plugin: pluginUuid, project: this.projectUuid}).then(() => {
        // this.getHintByPluginType(this.loadedPlugin.type.name)
        this.getMethodsByPluginType(this.loadedPlugin.type.name)
        this.loadPlugin()
      })
    },

    loadRepositoryPlugin(){
      this.cleanLoadedPlugin()
      this.clonePluginToProject({plugin: this.pluginActiveInfo.uuid, project: this.projectUuid}).then(() => {
        this.$refs.repository.hideRepPluginList()
        // this.getHintByPluginType(this.pluginActiveInfo.type.name)
        this.getMethodsByPluginType(this.pluginActiveInfo.type.name)
        this.loadPlugin()
        this.pluginActiveInfo = null
      })
    },

    editRepositoryPlugin(){
      this.cleanLoadedPlugin()
      pluginService.load(this.pluginActiveInfo.uuid).then(data => {
        this.setLoadedPlugin(data)
        // this.getHintByPluginType(this.pluginActiveInfo.type.name)
        this.getMethodsByPluginType(this.pluginActiveInfo.type.name)
        this.$refs.repository.hideRepPluginList()
        this.loadPlugin()
        this.pluginActiveInfo = null
      })
    },

    loadPluginScript(){
      this.setScriptIsLoaded(false)
      pluginService.scripts(this.loadedPlugin.script).then(data => {
        this.setLoadedPluginCode(data)
        this.setScriptIsLoaded(true)
      })
    },

    loadPluginLog(){
      return pluginService.logs(this.loadedPlugin.console).then(data => {
        this.setLoadedPluginLog(data)

        this.$nextTick(() => {
          if(this.$refs.consoleFrame)
            this.$refs.consoleFrame.scrollTop = this.$refs?.consoleFrame?.scrollHeight;
        })
      })
    },

    loadPlugin() {
      if (this.loadedPlugin.script) this.loadPluginScript()
      if (this.loadedPlugin.console) this.loadPluginLog()
    },

    cleanLoadedPlugin() {
      this.setLoadedPlugin(null)
      this.setLoadedPluginCode(null)
    },

    createPlugin(){
      this.formPlugin = {
        name: this.$t('plugin.newPlugin'),
        type:null,
        formUuid:null,
        templateUuid:null,
        projectUuid:this.project.uuid,
        profileUuid:this.user.uuid,
        scriptContent:''
      }
      this.dialog.pluginSettings = true
    },

    showPluginSettings() {
      this.formPlugin = JSON.parse(JSON.stringify(this.loadedPlugin))
      this.formPlugin.scriptContent = this.loadedPluginCode
      this.dialog.pluginSettings = true
    },

    savePluginSettings() {
      pluginService.createPlugin(this.formPlugin).then(data => {
        this.dialog.pluginSettings = false
        this.formPlugin = null
        this.setLoadedPlugin(data)
        this.loadPlugin()
        this.loadPluginsList()
        
      }).catch(error => {
        this.error = error
      })
    },

    savePlugin() {
      this.formPlugin = JSON.parse(JSON.stringify(this.loadedPlugin))
      this.formPlugin.scriptContent = this.loadedPluginCode

      if (this.formPlugin.validateType.value === 2)
        this.formPlugin.version++

      pluginService.updatePlugin(this.formPlugin).then(() => {
        AlertService.success({info:this.$t('plugin.alert.saveTemplate')})
        this.dialog.pluginSettings = false
        this.formPlugin = null

        // this.setLoadedPlugin(data)
        // this.loadPlugin()
        this.loadPluginsList()
        
      }).catch(error => {
        this.error = error
      })
    },

    showMethodDialog(){
      this.$refs.pluginFunctionDialog.showGuide()
    },

    insertTextInEditor(text){
      this.$refs.editor.insertText(text)
    },

    showDeletePlugin(){
      if (this.pluginActiveInfo)
        this.pluginToDelete = this.pluginActiveInfo
      else
        this.pluginToDelete = this.loadedPlugin

      this.dialog.deletePlugin = true
    },

    aproveDeletePlugin(){
      pluginService.deletePlugin(this.pluginToDelete.uuid).then(() => {
        this.formPlugin = null

        if (!this.pluginActiveInfo) {
          this.cleanLoadedPlugin()
          this.setLoadedPluginLog(null)
        }

        this.loadPluginsList()
        this.loadRepositoryPluginList()

        this.pluginActiveInfo = null

        this.dialog.deletePlugin = false
      }).catch(error => {
        this.error = error
      })
    },

    cancelDeletePlugin() {
      this.dialog.deletePlugin = false
    },

    clearError(){
      this.error = null
    },

    afterFormFilled(data) {
      data.forEach( field => {
        if(field.alias)
          this.formParam[field.alias] = field.value
      })
      this.runPlugin()
    },

    runPluginFillForm() {
      this.formParam = {}
      if(this.loadedPlugin.formUuid) {
        this.formUuid = this.loadedPlugin.formUuid
        this.dialog.fillForm = true 
      } 
      else this.runPlugin()
    },

    validatePlugin(){
      this.formPlugin = JSON.parse(JSON.stringify(this.loadedPlugin))
      this.formPlugin.scriptContent = this.loadedPluginCode

      pluginService.updatePlugin(this.formPlugin).then(data => {
        this.dialog.pluginSettings = false
        this.formPlugin = null

        this.setLoadedPlugin(data)

        // this.loadPlugin() TODO: Перезагружает редактор
        this.loadPluginsList()

        pluginService.validate(this.loadedPlugin.uuid).then(data => {
          console.log(data)
          this.loadPluginLog()
        }).catch(error => {
          this.error = error
        })
        
      }).catch(error => {
        this.error = error
      })
    },

    runPlugin(){
      this.selectElements([])
      XeokitMediator.clearColorizeModel()
      this.setPluginTable(null)
      this.LOAD_TABLE(false)
      this.clearError()
      console.log("TYPE: ",this.loadedPlugin.type.name);

      this.running = true

      this.formPlugin = JSON.parse(JSON.stringify(this.loadedPlugin))
      this.formPlugin.scriptContent = this.loadedPluginCode

      pluginService.updatePlugin(this.formPlugin).then(data => {
        this.xRayedElements(false);

        this.dialog.pluginSettings = false
        this.formPlugin = null

        this.setLoadedPlugin(data)

        // this.loadPlugin() TODO: Перезагружает редактор
        this.loadPluginsList()

        this.formParam['refresh'] = true

        pluginService.executeWithParam(this.project.uuid, this.loadedPlugin.uuid, this.formParam).then(data => {
          this.running = false
    //TODO: переделать на this.loadedPlugin.type.name
          if (data instanceof Array) {
            if (data[0]!=null && this.isString(data[0])) {
              this.selectElements(data) 
            } else {
              if (data[0] != null && data[0].elements && data[0].color) {
                this.xRayedElements(true)
                for (var group of data) {
                  var elements = group.elements
                  var color = group.color
                  this.highlightElements(this.hexToRgb(color), elements)
                }
              } else {
                this.$refs.pluginInformationDialog.show()
                this.pluginResInfoData = data
              }
            }  
          } 
          else if (data != null && data.toString() != "" && this.loadedPlugin.type.name != 'ANALYTICAL_REPORT' && this.loadedPlugin.type.name != 'REPORT') {
            this.$refs.pluginInformationDialog.show()
            this.pluginResInfoData = data
          }

          if(data!=null && data.elements && data.headers){
            data.pluginUuid = this.loadedPlugin.uuid
            this.setPluginTable(data)
            this.LOAD_TABLE(true)
          }

          this.loadPluginLog().then(() => {
            if(data != null && (this.loadedPlugin.type.name === "REPORT" || this.loadedPlugin.type.name === "SMETA")) {
              let href = `data:${data.headers["Content-Type"][0]};base64,${data.body}`

              let prettyDate = new Date()
              let month = prettyDate.getUTCMonth() + 1 
              month = month < 10 ? "0" + month : month
              let date = prettyDate.getUTCFullYear() + "-" + month + "-" + prettyDate.getUTCDate() + " " + prettyDate.getHours()
                + ":" + prettyDate.getMinutes() + ":" + prettyDate.getSeconds() + "." + prettyDate.getMilliseconds()

              let link = `<div>${date}<span style="color:steelblue;"> SYSTEM </span><a href="${href}" download="${this.loadedPlugin.uuid}">Скачать файл-результат выполнения плагина</a></div>`
              this.setLoadedPluginLog(this.loadedPluginLog + link)
            }
          })

          this.formParam = {}
        }).catch(error => {
          this.error = error
          this.running = false
          this.loadPluginLog()
        })
        
      }).catch(error => {
        this.error = error
        this.running = false
      })
    },

    isString(s) {
      return typeof(s) === 'string' || s instanceof String;
    },

    clearConsole(){
      pluginService.clearConsole(this.loadedPlugin.uuid).then(() => {
        this.setLoadedPluginLog(null)
      }).catch(error => {
        this.error = error
      })
    },

    xRayedElements (flag) {
        let scene = this.viewer.scene 
        if (flag) {
          this.objectsMemento.saveObjects(scene);
        }

        XeokitMediator.ElementsSettings.setElementsXRayed( this.objectIds, flag)

        if (!flag) {
          if (this.objectsMemento.numObjects != 0)
            this.objectsMemento.restoreObjects(scene);
        }
    },

    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [
        parseInt(result[1], 16)/255,
        parseInt(result[2], 16)/255,
        parseInt(result[3], 16)/255
      ] : [1,1,0];
    },

    highlightElements (color, list) {
      if ( list.length > 0) {
        XeokitMediator.ElementsSettings.setCollisionElementsSettings(list, list, false, color, 0.1, 0.1)
        XeokitMediator.ElementsSettings.setElementsOpacity(list, 1.0)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .column-height{height: calc(100% - 80px);}
  .p-repative {position: relative;}
  .p-absolute {position: absolute;}
  .format{
    height: 100% !important;
  }

  .gap{
    gap:14px
  }

  .v-menu__content{
    max-width: 346px;
  }

  .func-btn {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    text-transform: none;
  }
</style>