import { render, staticRenderFns } from "./ReplaceWormCompoundDialog.vue?vue&type=template&id=53b4620d&scoped=true&lang=pug&"
import script from "./ReplaceWormCompoundDialog.vue?vue&type=script&lang=js&"
export * from "./ReplaceWormCompoundDialog.vue?vue&type=script&lang=js&"
import style0 from "./ReplaceWormCompoundDialog.vue?vue&type=style&index=0&id=53b4620d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b4620d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAlert,VForm,VList,VListItem,VListItemGroup,VSkeletonLoader,VSpacer,VTreeview})
