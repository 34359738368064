<template lang="pug">
div.relative.notif-header(:style="'background-color:' + background" )
  v-tooltip(:bottom="tooltipBottom" :left="tooltipLeft" :top="tooltipTop" :right="tooltipRight" open-delay=300)
    template(v-slot:activator="{ on, attrs }")
      div(v-on="tooltipOn ? on :  false")
        v-img.header-action(:src="`/img/${icon}`" :width="size" :height="size" v-bind="$attrs" v-on="$listeners" :class="['app-theme--' + theme]" )
        span.ellipse( v-if="badge" )
   
    span {{ text }}
</template>

<script>
export default {
  name: 'TaskButtonAction', 
  
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 24
    },
    theme: {
      type: String,
      default: "light"
    },
    badge: Boolean,
    background: String,
    text: String,
    tooltipLeft: Boolean,
    tooltipRight: Boolean,
    tooltipTop: Boolean,
    tooltipBottom: Boolean,
  },
  computed: {
    tooltipOn () {
      if (this.tooltipLeft || this.tooltipRight || this.tooltipTop || this.tooltipBottom) return true
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
.header-action {
  flex: 0 1 auto;
  cursor: pointer;
  // margin-left: 2px;
  
}

.ellipse {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #ED0E51;
  position: absolute;
  right: 0px;
  bottom: 0px;
  animation: glowing 1500ms infinite;
}
@keyframes glowing {
  0% { background-color: #ED0E51; }
  50% { background-color: transparent; }
  100% { background-color: #ED0E51; }
}
.notif-header{
  position: relative;
}
</style>