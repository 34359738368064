import { setGridsGlowingThrough, requestGridsIfcTree, gridsIfcTree, toggleModelGridsVisible, setGridsDebounce, needDebounce, gridsGlowingThrough } from "@/facades/grids.facade"
import { defineStore } from "pinia"

export const useGridsSettingsState = defineStore('grids-settings-state', {
  state: () => {
    return {
      gridsGlowingThrough: true,
      needDebounce: true
    }
  },

  getters: {
    gridsIfcTree() { return gridsIfcTree() },
  },

  actions: {
    loadState() {
      this.gridsGlowingThrough = gridsGlowingThrough()
      this.needDebounce = needDebounce()
    },

    toggleGridsGlowingThrough() {
      setGridsGlowingThrough(this.gridsGlowingThrough)
    },

    toggleGridsDebounce() {
      setGridsDebounce(this.needDebounce)
    },

    toggleModelGridsVisible(revisionUuid) {
      toggleModelGridsVisible(revisionUuid)
    },

    requestGridsIfcTree() {
      requestGridsIfcTree()
    },
  }
})