<template lang="pug">
app-dialog( v-model="$_show" :header="title" width="700" )
  app-section( :rounded="false" )
    app-text-field( :value="searchText"
                    :label="$t('section.classification.menuClassification.codeSearch')"
                    outlined dense clearable hide-details
                    test-id="worm-code-search"
                    @input="handleInput($event)")

    v-form( ref="addWormRuleForm" @submit.prevent="handleSave" )
      .base-scroll-body.mh350.my-2
        
        v-skeleton-loader(v-if="treeLoading" type="list-item-two-line" light)

        div(v-else)
          .d-flex.mb-2.justify-space-between
            v-btn.dict-button.px-1( color="accent" dense text x-small @click="toggleAll" test-id="worm-expand-collapse-tree")
              v-icon.mr-1( color="accent" size="16" left ) unfold_more
              | {{ expand ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}

            v-menu(offset-y :close-on-content-click="false" open-on-hover light left)
              template(v-slot:activator="{ on, attrs }")
                v-btn.dict-button.px-1(color="accent" dense text x-small v-bind="attrs" v-on="on" test-id="smeta-filter-btn")
                  v-icon.mr-1( color="accent" size="16" left ) mdi-filter
                  | {{$t("section.classification.menuClassification.filters")}}
              v-list.pa-0
                v-list-item
                  v-switch.mt-0( v-model="showWithValidRules" dense hide-details color="var(--v-accent-base)" )
                    template( v-slot:label ) 
                      .worm-tree__filter_text {{$t("section.classification.menuClassification.withValidRules" )}}

          app-treeview-with-search.__project_view_tree(
            ref="simpleSmetaItemsTree"
            dense
            light
            hoverable
            item-disabled="disabled"
            :active.sync="activeItem"
            activatable
            multiple-active
            open-on-click
            :itemSearchFor="(item) => item.pressmark + item.title"
            :itemHighlighted="(item) => (item.pressmark ? item.pressmark + ' - ' : '') + item.title"
            item-key="uuid"
            :items="filteredValidItems"
            :search="searchText"
            return-object )
            template(v-slot:label="{ item, highlighted }")
              //- @click="selectItem(item)"
              div(:title="item.pressmark ? item.pressmark+' - '+item.title : item.title")
                template(v-if="item.type.name == 'RULE'")
                  v-progress-circular(v-if="hiddenLoading" size="12" width="2" color="accent" indeterminate)
                  v-icon( v-else-if="item.validy" color="#0f0" ) mdi-circle-medium

                span( :class="{strong: item.type.name == 'RULE'}" v-html="highlighted" )

      v-divider.my-2(light)
      .d-flex
        app-text(v-if="activeItem.length > 0 && !savingErrorText") {{ $t('section.worm.selected')}} {{ activeItem.length }}
        v-progress-circular.mr-2(v-if="saving && !savingErrorText" color="accent" size="25" indeterminate)
        app-text(v-if="saving && !savingErrorText" test-id="worm-saving-calculating") {{ $t('section.worm.savingCalculating') }}
        v-alert(v-if="savingErrorText" type="error" dense) {{ savingErrorText }}
        v-spacer
        app-button(action type="submit" light :disabled="saving || saveDisable" test-id="worm-save") {{ $t('button.save') }}
</template>

<script>
import DialogToggable from "@/components/app/DialogToggable"
import { api } from "@/api"
import { mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name:'add-worm-rule-dialog',
  
  mixins: [DialogToggable],

  components: {
    
  },

  props: {
    title: String,
    savingErrorText: String
  },

  data() {
    return {
      hiddenLoading: false,
      treeLoading: false,
      saving: false,
      expand: false,

      activeItem: [],

      searchText: '',
      filteredItems: [],
      selectedRule: null,
      handleInput: _.debounce((val) => {
        this.searchText = val 
      }, 400),
      showWithValidRules: false,
    }
  },


  mounted() {
    
  },

  computed: {
    ...mapState("project", ["project"]),
    ...mapState('worm', ['addRulesTree', 'openedRuleTree']),

    saveDisable() {
      // if (this.selectedRule == null) return true
      // if (this.selectedRule != null && this.saving) return true

      if (this.activeItem.length == 0 && this.saving) return true
      return false
    },

    openedNodes: {
      get() {
        return this.openedRuleTree
      },
      set(v) {
        this.setOpenedRuleTree(v)
      }
    },

    filteredValidItems () {
      if(this.showWithValidRules === false) {
        return this.addRulesTree
      }
      else {
        return this.filterValidItems(this.addRulesTree);
      }
    },
  },

  watch: {
    $_show(val){
      this.selectedRule = null
      this.saving = false
      this.activeItem = []
      if (val) this.listProjectClassofocators()
    },

    searchText(val) {
      this.expand = !!val
      ; this.$refs.simpleSmetaItemsTree.updateAll(!!val)
    },

    showWithValidRules(val) {
      this.expand = !!val
      ; this.$refs.simpleSmetaItemsTree.updateAll(!!val)
    }
  },

  methods: {
    ...mapMutations('worm',['setAddRulesTree', 'setOpenedRuleTree']),

    toggleAll(){
      this.expand = !this.expand
      this.$refs.simpleSmetaItemsTree.updateAll(this.expand)
    },

    listProjectClassofocators() {
      this.hiddenLoading = true
      if (this.addRulesTree.length == 0) this.treeLoading = true
      api.smeta.onlywithrulestree(this.project.uuid).then(data => {
        this.treeList = data
        this.setAddRulesTree(data)
        this.filteredItems = this.addRulesTree
        this.treeLoading = false
        this.hiddenLoading = false
      })
    },

    // selectItem(item){
    //   if (item.type.name == 'RULE') {
    //     this.activeItem = []
    //     this.activeItem.push(item)
    //     this.selectedRule = item
    //   }
    // },

    handleSave(){
      if (this.activeItem.length > 0) {
        this.saving = true
        this.$emit('saverule',this.activeItem)
      }

      // if (this.selectedRule) {
      //   this.saving = true
      //   this.$emit('saverule',this.selectedRule)
      // }
    },

    filterValidItems(items) {
      return items
        .map(item => {
          if (item.children && item.children.length > 0) {
            const filteredChildren = this.filterValidItems(item.children);
            if (filteredChildren.length > 0) {
              return {
                ...item,
                children: filteredChildren,
              };
            } else {
              return null;
            }
          } else if (item.validy) {
            return item;
          } else {
            return null;
          }
        })
        .filter(item => item !== null);
    },
  }
}
</script>

<style lang="scss" scoped>
.strong {
  font-weight: bold;
}

.mh350{
  height: calc(75vh - 350px)
}

::v-deep .v-treeview-node__label {
  white-space: wrap !important;
}

.worm-tree__filter_text {
  color: var(--v-accent-base); 
  font-size: 10px; 
  text-transform: uppercase;
}
</style>