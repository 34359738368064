var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"r-list-item"},[_c('div',{staticClass:"r-list-item-title",class:{"r-selected": _vm.selected}},[_c('span',[_vm._v(_vm._s(_vm.receiver.name))])]),_c('div',{staticClass:"r-list-item-actions ml-auto d-flex"},_vm._l((_vm.actions),function(ref){
var icon = ref[0];
var action = ref[1];
var title = ref[2];
return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.emitAction($event, action)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#4d4d4d","small":""}},[_vm._v(_vm._s(icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(title)))])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }