<template lang="pug">
.app-toolkit-column
  ToolSwitchButtonProjection
  ToolSwitchButtonNavigation
</template>

<script>
import ToolSwitchButtonProjection from './buttons/ToolSwitchButtonProjection.vue'
import ToolSwitchButtonNavigation from './buttons/ToolSwitchButtonNavigation.vue'

export default {
  components: {
    ToolSwitchButtonProjection,
    ToolSwitchButtonNavigation,
  },
}
</script>