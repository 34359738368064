
import { CustomAxis } from "@/assets/model/axis"
import vuexStore from '@/store'

// TODO: надо разделить логику устнаовки активной группы и логику перехода к показу дерева элементов группы
export function showTreeOfGroup (group) {
  let axisGroup = CustomAxis(group)
  let topUnit = vuexStore.state.project.topUnit

  // TODO: проверки текущей открытой панели тут быть не должно, должна быть только логика активации нужного дерева
  if (topUnit == 'elementTree') {
    // MARK: [RightElementTree]
    // TODO: Добавил костыль в виде right-axis.module чтоб исчезла проблема с отображением деревьев
    // в левой и правой панелях
    // требуется серъезный рефактор в axis
    if (window?.settings?.rework?.elementTree || false) {
      let axisTab = vuexStore.getters['right-axis/fixedAxisGroup'][4]
      vuexStore.dispatch('right-axis/selectAxis', axisTab)
      vuexStore.dispatch('right-axis/selectAxisGroup', axisGroup)
    } else {
      let axisTab = vuexStore.getters['axis/fixedAxisGroup'][4]
      vuexStore.dispatch('axis/selectAxis', axisTab)
      vuexStore.dispatch('axis/selectAxisGroup', axisGroup)
    }
  }
  else {
    if (window?.settings?.rework?.elementTree || false) {
      vuexStore.commit('right-axis/setPresetAxisGroup', axisGroup)
    } else {
      vuexStore.commit('axis/setPresetAxisGroup', axisGroup)
    }
    // TODO: логика переключения вьюх должна быть вынесена в роутер или ассемблер
    vuexStore.commit('project/setTopUnit', 'elementTree')
  }
}