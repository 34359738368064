import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import { useTaskScreenshotStore } from "@/pinia"
import { AlertService } from '@/assets/app/AlertService'
import { TaskBimAnnotations } from '@/components/task/taskBimAnnotations'
import store from "@/store"
import { Snapshot } from "@/plugins/xeokit/snapshot/snapshot"
import { math } from "@xeokit/xeokit-sdk";
import CollisionHighlightService from "../project/panel/left/components/collision/CollisionHighlightService"
import { api } from "@/api"
import { MODEL_TRANSFORM_ID } from "@/plugins/xeokit/RevisionLoader"

/*eslint-disable no-dupe-class-members*/
export class TaskScreenshot {

  static get #_project() {
    return store.state.project.project
  }

  static get #_taskScreenshotStore() {
    return useTaskScreenshotStore()
  }

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get snapshotImageData() {
    return this.#_taskScreenshotStore.snapshotImageData
  }

  static get pickedEntityId() {
    return this.#_taskScreenshotStore.pickedEntityId
  }
  
  static get pickedWorldPos() {
    return this.#_taskScreenshotStore.pickedWorldPos
  }
  
  static get taskUuid() {
    return this.#_taskScreenshotStore.taskUuid
  }

  static get screenshotComment() {
    return this.#_taskScreenshotStore.screenshotComment
  }

  static get isCreateTaskWithTaskScreenshotDialogVisible() {
    return this.#_taskScreenshotStore.createTaskWithTaskScreenshotDialogVisible
  }

  static get isCreateTaskScreenshotDialogVisible() {
    return this.#_taskScreenshotStore.createTaskScreenshotDialogVisible
  }

  static get #_collisionSelectedGlobalIds() {
    return store.state.collision.search.collisionSelectedGlobalIds
  }

  static get #_collisionSelectedElements() {
    return store.state.collision.table.selectedCollisions
  }

  static get #_selectedCollisionsElementAB() {
    return store.getters['collision/table/selectedCollisionsElementAB']
  }

  static get #_collisionSelectedElementsModel() {
    return store.state.collision.search.collisionSelectedElementsModel
  }

  static get #_selectedCollisionsModelAB() {
    return store.getters['collision/table/selectedCollisionsModelAB']
  }

  static get #_activeGlobalTab() {
    return store.state.project.activeGlobalTab
  }

  static get #_currentViewType() {
    return store.state.collision.table.selectedType
  }

  static set screenshotComment(screenshotComment) {
    this.#_taskScreenshotStore.setScreenshotComment(screenshotComment)
  }

  static #_addAttachmentWithCam(attachData) {
    return store.dispatch("task/addAttachmentWithCam", attachData)
  }

  static #_addTaskOpenPanel(taskData) {
    return store.dispatch("task/addTaskOpenPanel", taskData)
  }

   /** Открыть окно создания скриншота к задаче
     * @param {Object} cfg Настройки скриншота
     * @param {String} [cfg.pickedEntityId = null] Выбранная модель
     * @param {Array<Number>} [cfg.pickedWorldPos = []] Выбранная координата на модели
     * @param {String} [cfg.taskUuid = null] UUID задачи к которой приклепляется скриншот
     * @param {String} [cfg.screenshotComment = null] Комментарий к скриншоту
   */
  static async showCreateTaskScreenshotDialog(cfg) {
    const pickedEntityId = cfg.pickedEntityId || null
    const pickedWorldPos = cfg.pickedWorldPos || null
    const taskUuid = cfg.taskUuid || null
    const screenshotComment = cfg.screenshotComment || null

    this.#_taskScreenshotStore.setCreateTaskScreenshotDialogVisible(true)

    this.#_taskScreenshotStore.setPickedEntityId(pickedEntityId)
    this.#_taskScreenshotStore.setPickedWorldPos(pickedWorldPos)
    this.#_taskScreenshotStore.setScreenshotComment(screenshotComment)
    this.#_taskScreenshotStore.setSnapshotImageData(await XeokitMediator.Snapshot.getSnapshot())
    this.#_taskScreenshotStore.setTaskUuid(taskUuid)
  }

  /** Открыть окно создания задачи со скриншотом
     * @param {Object} cfg Настройки скриншота
     * @param {String} [cfg.pickedEntityId = null] Выбранная модель
     * @param {Array<Number>} [cfg.pickedWorldPos = []] Выбранная координата на модели
   */
  static showCreateTaskWithTaskScreenshotDialog(cfg) {
    const pickedEntityId = cfg.pickedEntityId
    const pickedWorldPos = cfg.pickedWorldPos

    this.#_taskScreenshotStore.setCreateTaskWithTaskScreenshotDialogVisible(true)

    this.#_taskScreenshotStore.setPickedEntityId(pickedEntityId)
    this.#_taskScreenshotStore.setPickedWorldPos(pickedWorldPos)
  }

  static closeCreateTaskScreenshotDialog() {
    this.#_taskScreenshotStore.setCreateTaskScreenshotDialogVisible(false)
  }

  static closeCreateTaskWithTaskScreenshotDialog() {
    this.#_taskScreenshotStore.setCreateTaskWithTaskScreenshotDialogVisible(false)
  }

   /** Создание скриншота для задачи
     * @param {Object} cfg 
     * @param {String} [cfg.taskUuid] UUID задачи к которой приклепляется скриншот
     * @param {String} [cfg.screenshotComment] Комментарий к скриншоту
     * @param {String} [cfg.collisionUuid] UUID выбранной коллизии
     * @param {String} [cfg.pickedWorldPos] Координаты аннотации
     * @param {String} [cfg.pickedEntityId] UUID выбранной модели для аннотации
   */
  static async createTaskScreenshot(cfg) {
    const taskUuid = cfg.taskUuid || null
    const screenshotComment = cfg.screenshotComment || null
    const collisionUuid = cfg.collisionUuid || []
    const pickedWorldPos = cfg.pickedWorldPos || null
    const pickedEntityId = cfg.pickedEntityId || null

    let sourceData = {
      version: 2,
      camera: XeokitMediator.Snapshot.getCamera(),
      collisionData: null,
      tools: XeokitMediator.Snapshot.getTools(),
    }
 
    if (collisionUuid < 1) sourceData.collisionData = this.#_generateCollisionData() // Приходит, только если задача создается прямо из таблицы коллизий
    
    let pickedEntity = null
    if (pickedEntityId) pickedEntity = XeokitMediator.viewer.scene.objects[pickedEntityId]

    if (pickedEntity && pickedWorldPos) {
      sourceData.camera.eye = this.#_convertToZeroWorldPos(sourceData.camera.eye, pickedEntity)
      sourceData.camera.look = this.#_convertToZeroWorldPos(sourceData.camera.look, pickedEntity)

      const zeroWorldPos = this.#_convertToZeroWorldPos(pickedWorldPos, pickedEntity)
      sourceData.annotation = {
        worldPos: zeroWorldPos,
        entityId: pickedEntityId
      }
    }
    
    if (screenshotComment) {
      sourceData.comment = screenshotComment
    }

    const flatlist = store.getters['project/flatlist']
    const model = flatlist.find(model => model.revision && model.revision.uuid === pickedEntity?.model.id)

    if (model) {
      sourceData.model = model.uuid
    }
    
    let imageData = await XeokitMediator.Snapshot.getSnapshot()
    this.urlToFile(imageData, 'screenshot.png','image/png').then((file) => { 
      this.#_addAttachmentWithCam({
        uuid: taskUuid,
        name: "screenshot " + new Date().getHours() + ":" + new Date().getMinutes(),
        collision: collisionUuid,
        file,
        sourceData: JSON.stringify(sourceData)}).then(() => {
          TaskBimAnnotations.showTaskBimAnnotations()
        }).catch(error => {
          AlertService.error(error.response)
        })
    })
  }

    /** 
      * Создание настроек скриншота для задачи
    */
  static createTaskScreenshotSourceData() {
    let sourceData = {
      version: 2,
      collisionData: null,
      tools: XeokitMediator.Snapshot.getTools(),
    }
    return sourceData
  }

  static #_generateCollisionData() {
    const collisionId = this.#_collisionSelectedGlobalIds.collisionUuid
    const choosenId = new String(this.#_collisionSelectedGlobalIds.choosen)
    const currentViewType = this.#_currentViewType
    const checkboxSelectedCollisions = [...this.#_selectedCollisionsElementAB]
    const checkboxSelectedCollisionModels = [...this.#_selectedCollisionsModelAB]

    let collisionData = null

    if(currentViewType == 0) {
      collisionData = {
        collisionUuid: collisionId,
        choosenUuid: choosenId,
        currentViewType: currentViewType,
        selectedCollisionList: checkboxSelectedCollisions,
        selectedCollisionModelsList: checkboxSelectedCollisionModels
      }
    }

    if(currentViewType == 1) {
      collisionData = {
        collisionUuid: collisionId,
        choosenUuid: choosenId,
        currentViewType: currentViewType,
      }
    }

    if(currentViewType == 2) {
      collisionData = {
        collisionUuid: collisionId,
        choosenUuid: choosenId,
        currentViewType: currentViewType,
      }
    }

    if(currentViewType == 3) {
      collisionData = {
        collisionUuid: collisionId,
        currentViewType: currentViewType,
      }
    }

    return collisionData
  }

  static #_convertToZeroWorldPos(worldPos, entity = null) {
    const tempMat4 = math.mat4();
    const tempVec4 = math.vec4();
    const defaultScale = math.vec3([1, 1, 1]);
    const defaultPosition = math.vec3([0, 0, 0]);
    const defaultRotation = math.vec3([0, 0, 0]);

    const defaultTransform = {
      position: defaultPosition,
      rotation: defaultRotation,
      scale: defaultScale
    }
    
    const transform = entity?.model?.transforms[MODEL_TRANSFORM_ID] || defaultTransform
    const modelPosition = transform.position || defaultPosition;
    const modelRotation = transform.rotation || defaultRotation;
    const modelScale = transform.scale || defaultScale;

    const oppositeModelPosition = math.vec3([-modelPosition[0], -modelPosition[1], -modelPosition[2]]) ;
    const oppositeModelRotation = math.vec3([-modelRotation[0], -modelRotation[1], -modelRotation[2]]) ;
    
    const oppositeModelQuaternion = math.eulerToQuaternion(oppositeModelRotation, "ZYX");
    const meshMatrix = math.composeMat4([0, 0, 0], oppositeModelQuaternion, modelScale, tempMat4);

    const oldWorldPos = math.vec3([worldPos[0] + oppositeModelPosition[0], worldPos[1] + oppositeModelPosition[1], worldPos[2] + oppositeModelPosition[2], 1]);
    const newMeshPos = math.transformPoint4(meshMatrix, oldWorldPos, tempVec4);

    return [newMeshPos[0], newMeshPos[1], newMeshPos[2]]
  }

  static #_restoreWorldPos(worldPos, entity = null, version) {
    if (version == 2) {
      const tempMat4 = math.mat4();
      const tempVec4 = math.vec4();
      const defaultScale = math.vec3([1, 1, 1]);
      const defaultPosition = math.vec3([0, 0, 0]);
      const defaultRotation = math.vec3([0, 0, 0]);
      const defaultQuaternion = math.identityQuaternion();
  
      const defaultTransform = {
        position: defaultPosition,
        rotation: defaultRotation,
        scale: defaultScale
      }
      
      const transform = entity?.model?.transforms[MODEL_TRANSFORM_ID] || defaultTransform
      const modelPosition = transform.position || defaultPosition;
      const modelRotation = transform.rotation || defaultRotation;
      const modelScale = transform.scale || defaultScale;
  
      // const rotation = [modelRotation[0], modelRotation[1], -modelRotation[2]]
      math.eulerToQuaternion(modelRotation, "XYZ", defaultQuaternion);
      const meshMatrix = math.composeMat4(modelPosition, defaultQuaternion, modelScale, tempMat4);
  
      const oldWorldPos = math.vec3([worldPos[0], worldPos[1], worldPos[2], 1]);
      const newMeshPos = math.transformPoint4(meshMatrix, oldWorldPos, tempVec4);
  
      return math.vec3([newMeshPos[0], newMeshPos[1], newMeshPos[2]]);
    }
    else {
      // TODO: ДАСТ БОГ, УДАЛИМ. КОГДА НА БЕКЕ ПРИВЕДУТ ВСЕ МЕТКИ К ОДНОМУ ВИДУ!!!!
      return this.#_oldRestoreWorldPos(entity, worldPos)
    }
  }

  /** Создание скриншота для задачи
     * @param {Object} cfg
     * @param {String} [cfg.taskTitle] Название задачи
     * @param {String} [cfg.taskDescription] Описание задачи
     * @param {String} [cfg.pickedWorldPos] Выбранная координата
     * @param {String} [cfg.pickedEntityId] UUID выбранной модели 
   */
  static createTaskWithTaskScreenshot(cfg) {
    const taskTitle = cfg.taskTitle || null
    const taskDescription = cfg.taskDescription || null
    const pickedWorldPos = cfg.pickedWorldPos || null
    const pickedEntityId = cfg.pickedEntityId || null

    let taskData = {
      project: this.#_project.uuid,
      name: this.#_project.name,
      title: taskTitle,
      description: taskDescription,
      worldX: pickedWorldPos[0],
      worldY: pickedWorldPos[1],
      worldZ: pickedWorldPos[2],
      entityId: pickedEntityId
    }

    this.#_addTaskOpenPanel(taskData).then(taskObject => {
      this.createTaskScreenshot({
        taskUuid: taskObject.uuid,
        pickedWorldPos: pickedWorldPos,
        pickedEntityId: pickedEntityId
      })
    })
  }

  static showTaskScreenshot(attachment) {
    const mappedSourceData = this.#_mapSourceData(attachment.sourceData)
    
    this.#_createDefaultScene() 

    this.#_restoreCollision(attachment)

    this.#_restoreOnlyViewCollision(attachment.sourceData.collisionData)

    const annotation = mappedSourceData?.annotation

    if (annotation) {
      const pickedEntity = XeokitMediator.viewer.scene.objects[annotation.entityId]
      Snapshot.restoreCamera({
        eye: this.#_restoreWorldPos(mappedSourceData.camera.eye, pickedEntity, mappedSourceData?.version),
        look: this.#_restoreWorldPos(mappedSourceData.camera.look, pickedEntity, mappedSourceData?.version),
        up: mappedSourceData.camera.up, pickedEntity,
        scale: mappedSourceData.camera.scale,
        projection: mappedSourceData.camera.projection
      })
    }
    else {
      Snapshot.restoreCamera({
        eye: mappedSourceData.camera.eye,
        look: mappedSourceData.camera.look,
        up: mappedSourceData.camera.up,
        scale: mappedSourceData.camera.scale,
        projection: mappedSourceData.camera.projection
      })
    }
    if (mappedSourceData.selectedElements) {
      mappedSourceData.tools.selectedElements = mappedSourceData.selectedElements // BCF Screenshots
    } 
    Snapshot.restoreTools(mappedSourceData.tools, true)
  }

  static dropScreenshot() {
    store.commit("collision/search/setTabCollision", false)
    store.commit("project/setActiveGlobalTab", null)
    Snapshot.removeTools()
  }

  static #_createDefaultScene() {
    XeokitMediator.ElementsSettings.setElementsXRayed(this.#_scene.objectIds, false)
    this.#_scene.setObjectsPickable(this.#_scene.objectIds, true)
  }

  static #_restoreOnlyViewCollision(restoreData) {
    if(restoreData && restoreData.collisionUuid !== null) {
      api.collisions.getCollisionsList([restoreData.collisionUuid]).then(collision => {
        CollisionHighlightService.highlightViewCollision(restoreData, collision[0])
      })
    }
  }

  static async #_restoreCollision(attachment) {
    if (!attachment.collision) {
      store.commit("collision/search/setTabCollision", false)
      store.commit("project/setActiveGlobalTab", null)
      return
    }

    const selectedCollisionUuid = attachment.collision
    store.commit("project/setActiveGlobalTab", "collision")
    store.commit("collision/search/setTabCollision", false)

    store.commit("collision/setTab", 1)
    store.commit("project/setAttributeChecking", false)

    const task = await store.dispatch('task/getTaskById', attachment.taskUuid)
    const selectedCollision = task.collision.find(col => col.uuid === selectedCollisionUuid)

    if (selectedCollision) {
      store.commit('collision/search/SET_COLLISIONS_TASK', task.collision, { root: true })
      store.commit('collision/table/CHANGE_COLLISION_SOURCE', { isActive: true, id: task.uuid, list: store.getters['collision/search/preparedCollisionsTask'] })
      setTimeout(CollisionHighlightService.setCollisionSelected(selectedCollision), 0)
    }
    else {
      return api.collisions.getCollisionsList([selectedCollisionUuid]).then(collision => {
        store.commit('collision/search/SET_COLLISIONS_TASK', collision, { root: true })
        store.commit('collision/table/CHANGE_COLLISION_SOURCE', { isActive: true, id: task.uuid, list: store.getters['collision/search/preparedCollisionsTask'] })
        setTimeout(CollisionHighlightService.setCollisionSelected(collision[0]), 0)
      })
    }
  }

  static #_mapSourceData(sourceData) {
    if (sourceData?.version) {
      return sourceData
    }
    else {
      return this.#_mapSourceDataV0(sourceData)
    }
  }

  static #_mapSourceDataV0(sourceData) {
    let mappedSourceData = {}

    if (sourceData?.entityId && sourceData?.worldPos && sourceData.worldPos != "NaN,NaN,NaN") {
      mappedSourceData.annotation = {
        entityId: sourceData.entityId,
        worldPos: sourceData.worldPos
      }
    }

    mappedSourceData.camera = {
      eye: sourceData?.camera?.eye?.split(",").map(Number),
      look: sourceData?.camera?.look?.split(",").map(Number),
      up: sourceData?.camera?.up?.split(",").map(Number),
      scale: sourceData?.camera?.scale,
      projection: sourceData?.projection 
    }

    let measurements = []
    sourceData?.measurements?.forEach(measurement => {
      measurements.push({
        origin: {
          worldPos: measurement.origin.worldPos.split(",").map(Number)
        },
        target: {
          worldPos: measurement.target.worldPos.split(",").map(Number)
        }
      })
    })

    let sectionPlanes = []
    sourceData?.sectionPlanes?.forEach(sectionPlane => {
      sectionPlanes.push({
        pos: sectionPlane.sectionPlain.pos.split(',').map(Number),
        dir: sectionPlane.sectionPlain.dir.split(',').map(Number),
        active: sectionPlane.sectionPlain.active
      })
    })

    let sectionCube = {
      active: !!sourceData?.sectionCube,
      visible: true,
      aabb: sourceData?.sectionCube,
    }

    mappedSourceData.tools = {
      activeHideSelectedObjects: sourceData?.activeHideSelectedObjects,
      activeShowOnlySelectedObjects: sourceData?.activeShowOnlySelectedObjects,
      measurements: measurements,
      pickedElement: null,
      sectionCube: sectionCube,
      sectionPlanes: sectionPlanes,
      selectedElements: sourceData?.selectedElements
    }

    return mappedSourceData
  }

  static urlToFile(url, filename, mimeType){
    return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }



  // TODO: ДАСТ БОГ, УДАЛИМ. КОГДА НА БЕКЕ ПРИВЕДУТ ВСЕ МЕТКИ К ОДНОМУ ВИДУ!!!!
  static #_oldRestoreWorldPos(entity, worldPos) {
    const tempMat4 = math.mat4();
    const tempVec4 = math.vec4();
    const defaultScale = math.vec3([1, 1, 1]);
    const defaultPosition = math.vec3([0, 0, 0]);
    const defaultRotation = math.vec3([0, 0, 0]);

    const defaultTransform = {
      position: defaultPosition,
      rotation: defaultRotation,
      scale: defaultScale
    }

    const transform = entity?.model?.transforms[MODEL_TRANSFORM_ID] || defaultTransform
    const modelPosition = transform.position || defaultPosition;
    const modelRotation = transform.rotation || defaultRotation;
    const modelScale = transform.scale || defaultScale;

    const scale = modelScale || defaultScale;
    const position = modelPosition || defaultPosition;
    const rotation = math.vec3([modelRotation[0] - 90, -modelRotation[2], modelRotation[1]]) ;

    const quaternion = math.eulerToQuaternion(rotation, "XZY");
    const meshMatrix = math.composeMat4([position[0], position[1], position[2]], quaternion, scale, tempMat4);

    const oldWorldPos = math.vec3([worldPos[0], worldPos[1], worldPos[2], 1]);
    const newMeshPos = math.transformPoint4(meshMatrix, oldWorldPos, tempVec4);

    return [newMeshPos[0], newMeshPos[1], newMeshPos[2]]
  }
}