import { defineStore } from 'pinia'

export const useGroupsVisibilityStore = defineStore('groups-visibility', {
  state: () => ({
    hidden: new Set(),
  }),

  actions: {
    show (uuid) {
      this.hidden.delete(uuid)
    },

    hide (uuid) {
      this.hidden.add(uuid)
    },
  }
})
