import { RulerMode } from '@/pinia/viewer/ruler.store.js'

export const toolbarIcons = Object.freeze({
  [RulerMode.edge]: "$viewer-ruler-edge-menu-icon",
  [RulerMode.edgeToEdge]: "$viewer-ruler-edge-to-edge-menu-icon",
  [RulerMode.pointToEdge]: "$viewer-ruler-point-to-edge-menu-icon",
  [RulerMode.pointToPoint]: "$viewer-ruler-point-to-point-menu-icon",
  [RulerMode.pointToPlane]: "$viewer-ruler-point-to-plane-menu-icon",
  [RulerMode.pointToFace]: "$viewer-ruler-point-to-face-menu-icon",
  [RulerMode.planeToPlane]: "$viewer-ruler-plane-to-plane-menu-icon",
  [RulerMode.faceToFace]: "$viewer-ruler-face-to-face-menu-icon",
  [RulerMode.ruler]: "$viewer-ruler-ruler-menu-icon",
})

export const menuIcons = Object.freeze({
  [RulerMode.edge]: "$viewer-ruler-edge-icon",
  [RulerMode.edgeToEdge]: "$viewer-ruler-edge-to-edge-icon",
  [RulerMode.pointToEdge]: "$viewer-ruler-point-to-edge-icon",
  [RulerMode.pointToPoint]: "$viewer-ruler-point-to-point-icon",
  [RulerMode.pointToFace]: "$viewer-ruler-point-to-face-icon",
  [RulerMode.faceToFace]: "$viewer-ruler-face-to-face-icon",
  [RulerMode.ruler]: "$viewer-ruler-ruler-icon",
})

export const titles = Object.freeze({
  [RulerMode.edge]: "Ребро",
  [RulerMode.edgeToEdge]: "Ребро к ребру",
  [RulerMode.pointToEdge]: "Точка к ребру",
  [RulerMode.pointToPoint]: "Точка к точке",
  [RulerMode.pointToFace]: "Точка к плоскости",
  [RulerMode.faceToFace]: "Плоскость к плоскости",
  [RulerMode.ruler]: "Рулетка",
})