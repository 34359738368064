<template lang="pug">
    app-section.my-2(v-if="(dataPositions[0] != undefined || savePositionDialog === true) && activeGlobalTab === 'iot'")
        .header.primary--text
            v-row(no-gutters).py-2
                v-col.mx-2 {{ $t('module.scannerQR.savedPositions') }}
        app-section(v-for="(position, index) in dataPositions" :key="index" cols="4" )
            v-hover( v-slot:default="{ hover }" close-delay="150" )
                .surface.lighten-5.primary--text.pa-2.rounded.app-font-12.pointer( @click="setPosition(position)") {{ position.name }} 
                    v-icon.unit-action( v-if="hover" :color="iconColor" @click.stop="showDetails($event, position)" ) more_vert
        position-param(v-model="editPositionDialog" :form="cameraPosition" :method="editDataPosition")
        position-param(v-model="savePositionDialog" :form="cameraPosition" :method="saveDataPosition")
        app-dialog-confirm(v-model="deleteDataPositionDialog" @confirm="deleteDataPosition(cameraPosition.uuid)")
            .text-subtitle-1.pt-5 {{ $t('module.scannerQR.deletePosition', {name: cameraPosition.name}) }}
        app-menu( ref="positionMenu" :menu="positionMenu" )
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import DialogToggable from '@/components/app/DialogToggable'
import PositionParam from './PositionParam.vue'

export default {
    name:"DetailsPosition",

    components:{
        PositionParam,
    },
    
    props: {
        element: null
    },

    mixins: [DialogToggable],
    
    data () {
        const data = Object.freeze({
            uuid: null,
            globalElementId: null,
            cameraPositionEye: null, 
            cameraPositionLook:  null,
            cameraPositionUp: null, 
            elementUuid: null,
            projectUuid: null,
            name: null
        })
        return {
            savePositionDialog: false,
            deleteDataPositionDialog: false,
            editPositionDialog: false, 
            cameraPosition: Object.assign({}, data),
            data
        }
    },


    
    computed:{
        ...mapState('project', ['project', 'activeGlobalTab']),
        ...mapGetters('position', ['dataPositions']),
        positionMenu () {
            return [
                { title: this.$t('module.scannerQR.positionMenu.fixPosition'), action: this.editPosition },
                { title: this.$t('module.scannerQR.positionMenu.delPosition'), action: this.deletePosition},
                { title: this.$t('module.scannerQR.positionMenu.showQR'), action: () => this.showQr(this.cameraPosition)}
            ]
        },
        iconColor() {
            return this.$vuetify.theme.themes.dark.primary.lighten3
        },
        
        bottomLine() {
            return "border-bottom: 2px solid "+this.$vuetify.theme.themes.dark.surface.lighten3
        },
       
    },

    methods: {
        ...mapActions('position', ['saveDataPosition', 'deleteDataPosition', 'getDataPosition','editDataPosition']),
        configurePosition() {
            if(this.element.globalId != null && this.element.globalId != undefined ){
                this.cameraPosition.globalElementId = this.element.globalId
                this.cameraPosition.elementUuid = null
                this.cameraPosition.projectUuid = this.project.uuid
                if(XeokitMediator.viewer != null && this.cameraPosition.globalElementId != null){
                    this.cameraPosition.cameraPositionEye = Object.values(XeokitMediator.viewer.camera.eye)
                    this.cameraPosition.cameraPositionLook = Object.values(XeokitMediator.viewer.camera.look)
                    this.cameraPosition.cameraPositionUp = Object.values(XeokitMediator.viewer.camera.up)    
                }
            }
        },
        setPosition(position) {
            XeokitMediator.ElementsSelection.selectElements([position.globalElementId])
            XeokitMediator.viewer.camera.eye = position.cameraPositionEye 
            XeokitMediator.viewer.camera.look = position.cameraPositionLook 
            XeokitMediator.viewer.camera.up = position.cameraPositionUp
        },
        showQr(form) {
            window.open(`/qr/forprint/${form.name}/${form.uuid}`)
        },

        saverPosition: function() {
            this.cameraPosition = JSON.parse(JSON.stringify(this.data))
            this.configurePosition()
            this.savePositionDialog = true
        },
        editPosition() {
            XeokitMediator.ElementsSelection.selectElements([this.cameraPosition.globalElementId])
            this.configurePosition()
            this.editPositionDialog = true
        },
        deletePosition(){
            this.deleteDataPositionDialog = true

        },
        showDetails (event, position) {
            this.cameraPosition = position
            this.$refs.positionMenu.show(event)
        },
    }
}
</script>

<style lang="scss" scoped>
    .header {
        border-radius: 3px;
        font-size: 13px;
    }
    .surface {
        position:relative;
        .unit-action {
        position: absolute;
        right: 6px;
        top: 5px;
        }
    }
</style>