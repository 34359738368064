<template lang="pug">
div.d-flex
  div.d-flex.flex-column.align-start.justify-start.viewer-ruler-menu
    div.button.full-width( 
      v-for="regimeMeasurement in regimeMeasurementsModes" 
      @click="regimeMeasurement.action" 
    )
      v-hover( #default="{ hover }" )
        div.full-width( :style="hover ? 'background-color: #c0c0c0;' : ''" )
          div.pl-4.d-flex.align-center.justify-start
            v-icon.style( size="32" color="white" :class="{ 'active-viewer-ruler-menu': regimeMeasurement.active }" ) {{ regimeMeasurement.icon }}
            div.d-flex( :class="{ 'bolder': regimeMeasurement.active }" )
              span.ml-2( v-html="regimeMeasurement.title" )
</template>

<script>
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import { ViewerPlugin, useViewerPluginsStore } from '@/pinia'
let pluginStore = useViewerPluginsStore()

export default {
  computed: {
    RegimeMeasurement() {
      return XeokitMediator.RegimeMeasurement
    },

    rulerMode() {
      return this.RegimeMeasurement.RegimeMeasurementModeClass
    },

    rulerModes() {
      return this.RegimeMeasurement.modeClasses
    },

    isRulerTabActive() {
      return pluginStore.activePlugin == ViewerPlugin.MEASUREMENTS && ViewerPlugin.MEASUREMENTS == pluginStore.activeTab
    },

    regimeMeasurementsModes() {
      return [
        { 
          icon:"$viewer-ruler-edge-icon", 
          title: "Ребро", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.EdgeMeasurement, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.EdgeMeasurement) 
        },
        { 
          icon:"$viewer-ruler-edge-to-edge-icon", 
          title: "Ребро к ребру", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.EdgeToEdgeMeasurement, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.EdgeToEdgeMeasurement) 
        },
        { 
          icon:"$viewer-ruler-point-to-edge-icon", 
          title: "Точка к ребру", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.PointToEdgeMeasurement, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.PointToEdgeMeasurement) 
        },
        { 
          icon:"$viewer-ruler-point-to-point-icon", 
          title: "Точка к точке", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.PointToPointMeasurement, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.PointToPointMeasurement) 
        },
        { 
          icon:"$viewer-ruler-point-to-face-icon", 
          title: "Точка к плоскости", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.PointToFaceMeasurement, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.PointToFaceMeasurement) 
        },
        { 
          icon:"$viewer-ruler-face-to-face-icon", 
          title: "Плоскость к плоскости", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.FaceToFaceMeasurement, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.FaceToFaceMeasurement) 
        },
        { 
          icon:"$viewer-ruler-ruler-icon", 
          title: "Рулетка", 
          active: this.isRulerTabActive && this.rulerMode == this.rulerModes.Ruler, 
          action: () => this.toggleRegimeMeasurements(this.rulerModes.Ruler) 
        },

        {
          icon: "$viewer-tool-sections-icon",
          title: "Сечение модели",
          active: ViewerPlugin.SECTION_PLANES == pluginStore.activeTab,
          action: () => {
            pluginStore.setActiveTab(ViewerPlugin.SECTION_PLANES)
            pluginStore.activateSectionPlanesPlugin()
          }
        },
        {
          icon: "$viewer-tool-cube-icon",
          title: "Куб сечений",
          active: ViewerPlugin.SECTION_CUBE == pluginStore.activeTab,
          action: () => {
            pluginStore.setActiveTab(ViewerPlugin.SECTION_CUBE, true)
            pluginStore.activateSectionCubePlugin()
          }
        },
        {
          icon: "$viewer-tool-coords-icon",
          title: "Координаты",
          active: ViewerPlugin.COORDINATE_PICKER == pluginStore.activeTab,
          action: () => {
            pluginStore.setActiveTab(ViewerPlugin.COORDINATE_PICKER)
            pluginStore.activateCoordinatePickerPlugin()
          }
        },
      ]
    },

    isRegimeMeasurementActive() {
      return XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive
    },
  },

  methods: {
    toggleRegimeMeasurements(mode) {
      if (this.isRegimeMeasurementActive) {
        if (mode) XeokitMediator.RegimeMeasurement.setRegimeMeasurementMode(mode)
        else XeokitMediator.RegimeMeasurement.deactivate()
      }
      else {
        pluginStore.setActiveTab(ViewerPlugin.MEASUREMENTS)
        XeokitMediator.RegimeMeasurement.activate({ mode: mode })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bolder {
  font-weight: bold;
}
  .v-menu__content {
    background-color: transparent;
    box-shadow: none;
  }

  .viewer-ruler-menu {
    // margin-top: 32px;
    // border-left: 1px solid #535353;
    background: white;
    background-color: white;
    width: 210px;
    // height: 297px;
    padding: 2px;

    border: 1px solid rgba(219, 219, 219, 1);

    color: #303030;

    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .active-viewer-ruler-menu {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #e6e6e6;
  }

  .full-width {
    width: 100%; 
    width: stretch;
    width: -moz-available; 
    width: -webkit-fill-available;
  }
</style>