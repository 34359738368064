<template lang="pug">
div
  app-section.base-scroll-body( :class="defaultClass ? 'h-100' : ''" :rounded="false" )
    draggable( v-model="conditionRows" @change="changeDraggable" )
      v-row.align-center.my-1( no-gutters v-for="(row, index) in conditionRows" :key="index" test-id="condition-row" )
        v-col( cols="auto" )
          v-menu
            template( v-slot:activator="{ on }" )
              v-btn.px-0( v-on="on" tile block elevation="0" height="24" width="24" min-width="24" color="white" dark ) 
                span.logic-group__text_color(v-if="row.start!=null && row.start!=''" ) {{ row.start }}
                v-icon( v-else color="primary" small test-id="first-staples" ) arrow_drop_down
            v-list( light dense )
              v-list-item.body-2( v-for="(item, ind) in startBracket" :key="ind" @click="setValue(index, 'start', item)" ) {{ item }}
        v-col.overflow-hidden
          div.pseudoinput.d-flex.flex-row-reverse.flex-nowrap.pa-1.mx-2.rounded( @click="editCondition(row.attr)" )
            app-text.text-nowrap {{row.attr.title}}
        v-col( cols="auto" )
          v-menu
            template( v-slot:activator="{ on }" )
              v-btn.px-0( v-on="on" tile block elevation="0" height="24" width="24" min-width="24" color="white" dark ) 
                span.logic-group__text_color( v-if="row.end!=null && row.end!=''" ) {{ row.end }}
                v-icon( v-else color="primary" small test-id="second-staples" ) arrow_drop_down
            v-list.p-0( light dense )
              v-list-item.body-2( v-for="(item, ind) in endBracket" :key="ind" @click="setValue(index, 'end', item)" ) {{ item }}
        v-col( cols="auto" )
          v-menu
            template( v-slot:activator="{ on }" )
              v-btn.px-0( v-on="on" tile block elevation="0" height="24" width="24" min-width="24" color="white" dark test-id="logic-operator" ) 
                span.caption.logic-group__text_color( v-if="row.logic!=null && row.logic.title!=''" ) {{ row.logic.title }}
                v-icon( v-else color="primary" small ) arrow_drop_down
            v-list.p-0(light dense)
              v-list-item.body-2( v-for="item in logic" :key="item.value" @click="setValue(index, 'logic', item)" ) {{ item.title }}
        v-col( cols="auto" )
          app-icon-button(v-if="hasClassificatorCreateRule" icon="mdi-dots-vertical" buttonSize="24" iconColor="black" light  @click="showMenu($event, row)" )
    app-menu( ref="settingRule" :menu="settingMenu" )
    .d-flex.flex-column
      .d-inline-flex.align-center.add-container.mt-1( @click="createCondition" test-id="add-selection-condition" )
        app-icon-button( icon="$add-box-outlined" light )
        .section-header.ml-1 {{ $t('section.elementTree.addSelectionCondition') }}

      .d-inline-flex.align-center.add-container.mt-4( v-if='copiedFindRuleConditions.length > 0' @click="pasteConditions()" test-id="paste-condition-row" )
        v-icon.icon-wrapper( size="18" ) mdi-content-paste
        .section-header.ml-3 {{ $t('section.classification.clipboard.paste') }}
      .d-inline-flex.align-center.add-container.mt-4( v-if='!checkingCorrectnessBrackets && disabledFormula' @click="showFormula = !showFormula" test-id="paste-condition-row")
        v-icon.icon-wrapper( size="18" ) mdi-eye-outline
        .section-header.ml-3 Проверить условие отбора 
    app-section( v-if="showFormula && !checkingCorrectnessBrackets" )
      div.pt-1.pb-1.pl-4.d-flex.formula-container
        div( v-if="conditionRows" v-for="(row,index) in conditionRows" )
          span.ml-1.formula-brackets( v-if="row.start" ) {{ row.start }} 
          span.ml-1 B{{ index + 1 }} 
          span.ml-1.formula-brackets( v-if="row.end" ) {{ row.end }}
          span.ml-1.formula-operand( v-if="row.logic" ) {{ row.logic.title }}
      v-row.align-center( no-gutters v-for="(row, index) in conditionRows" :key="index" )
          v-col.pa-1( v-if="disabledFormula" cols="auto" )
            span.mr-1 B{{ index + 1 }} -
          v-col.overflow-hidden
            div.pt-2.pb-2( @click="editCondition(row.attr)" )
              app-text.text-nowrap {{row.attr.title}}

  element-condition( v-model="dialogСondition" :condition.sync="condition" @created="afterCreateCondition" :groupUuid="groupUuid" :axisFilter="axisFilter" :operatorFilter="operatorFilter" ) 

  v-snackbar( v-model="errorSnack" color="error" :timeout="6000" vertical top ) {{errorSnackText}}
    v-btn( light text @click="errorSnack = false" ) {{ $t('button.clear') }}
  </template>
  
  <script>
  import { mapGetters, mapState, mapMutations } from 'vuex'
  import draggable from 'vuedraggable'
  import ElementCondition from './Condition.vue'
  import {$_parse} from './parse'
  import i18n from '@/plugins/i18n'
  
  
  export default {
    name: 'LogicGroup',
    mixins: [$_parse],
    components: { ElementCondition, draggable },
    props: {
      groupUuid: null,
      logicGroup: null,
      defaultClass: {
        type: Boolean,
        default: true
      },
      lockable: {
        type: Boolean,
        default: false
      },
      disabledFormula: {
        type: Boolean,
        default: false
      },
      axisFilter: Array,
      operatorFilter: Array
    },
    data () {
      const defaultConditionRow = Object.freeze({
        start: null,
        attr: null,
        end: null,
        logic: null
      })
  
      return {
        errorSnackText: i18n.t('section.elementTree.errorSnackText'), 
        errorSnack: false,
        valid: false,
        dialogСondition:  false,
        defaultConditionRow,
        conditionRows: [],
        condition: null,
        selectedRow:null,
        showFormula:false
      }
    },
    watch: {
      logicGroup (value) {
        if (value) {
          this.updateForm()
        }
      },
    },
    computed: {
      ...mapGetters('project', ['logicOperator', 'isLogicOperatorLoaded']),
      ...mapState('copyutil', ['copiedFindRuleConditions']),
      ...mapGetters('projectPermissions', ['hasClassificatorCreateRule', 'hasCollisionView']),
      ...mapState('projectPermissions', ['roleTypes']),

      settingMenu() {
        if (this.lockable) {
          return [
            { title: this.$t('section.classification.clipboard.copy'), action: this.copyCondition },
            { title: this.$t('section.worm.blockingSubstitution'), action: this.toggleLockCond },
            { title: this.$t('button.delete'), action: this.removeCond },
          ]
        }
        return [ { title: this.$t('button.delete'), action: this.removeCond } ]
      },
      startBracket() {
        return ["", "(", "((", "((("]
      },
      endBracket() {
        return ["", ")", "))", ")))"]
      },
      logic() {
        let l = this.logicOperator.filter(lo => lo.name!='NOT').slice(0)
        l.unshift({"value": -1, "title": "", "name": ""})
        return l
      },
      checkingCorrectnessBrackets(){
        let start = null
        let end = null
        this.conditionRows.map(row => {
          if(row.start){

            start += row.start.split("").length
          }
          if(row.end){
            end += row.end.split("").length
          }
        })
        if(start != end){
          this.closeFormula()
        }
        return start == end
      }
    },

    methods: {
      ...mapMutations('condition', ['setReducedСonditions'] ),

      closeFormula(){
        this.showFormula = false
      },
      showMenu (event, row) {
        this.selectedRow = row
        this.$refs.settingRule.show (event)
      },
      changeDraggable(){
        this.conditionRows.forEach((cond, index) => {
          if(cond.logic == null && index < this.conditionRows.length-1) cond.logic=this.logicOperator[0]
          else if( index == this.conditionRows.length-1) cond.logic=null
        })
        this.$emit('changed', this.logicGroup)
      },
      setValue(index, param, val) {
        this.conditionRows[index][param] = val
        this.$emit('changed', this.logicGroup)
      },
      showErrorSnack(text) {
        this.errorSnackText = text
        this.errorSnack = true
      },
      toggleLockCond(){
        this.selectedRow.attr.locked = !this.selectedRow.attr.locked
      },
      removeCond() {
        let index = this.conditionRows.findIndex(row => row == this.selectedRow)
        this.conditionRows.splice(index, 1)
        this.$emit('changed', this.logicGroup)
      },
      createCondition() {
        this.setReducedСonditions( false )
        this.condition = null
        this.dialogСondition = true
      },
      editCondition(cond) {
        this.condition = cond
        this.dialogСondition = true
      },
      afterCreateCondition(cond) {
        if(this.condition==null) {
          if(this.conditionRows.length>0){
            let prevCond = this.conditionRows[this.conditionRows.length-1]
            if(prevCond.logic==null) prevCond.logic=this.logicOperator[0]
          }
          
          let newRow = Object.assign({}, this.defaultConditionRow)
          newRow.attr = cond
          this.conditionRows.push(newRow)
        } else {
          Object.assign(this.condition, cond)
        }
        this.dialogСondition=false
        
        this.$emit('changed', this.logicGroup)
      },
      makeLogicGroup() {
        let lg_uuid = this.logicGroup.uuid!==undefined ? this.logicGroup.uuid : null
        let newLogicGroup = {}
        newLogicGroup.uuid = lg_uuid
  
        if(this.conditionRows.length > 0) {
          let prepareCondition = this.$parse_to_back(this.conditionRows)

          if (prepareCondition.status == "OK") {
            newLogicGroup = prepareCondition.expr
            newLogicGroup.uuid = lg_uuid
            return newLogicGroup
          } 
          else {
            this.showErrorSnack(prepareCondition.err)
            return null
          }
        } else 
          return newLogicGroup
      },
      makeConditionRow_NoSORT(logicGroup, end, prevOperatorType) {
        if(logicGroup.condition!=null){
          if(logicGroup.condition.length==0) end=""
          logicGroup.condition.forEach((cond, index) => {
             let newRow = Object.assign({}, this.defaultConditionRow)
             newRow.attr = cond
             if(index==0 || index<logicGroup.condition.length-1 || (index==logicGroup.condition.length-1 && logicGroup.children.length>0) ) 
                newRow.logic = logicGroup.logicOperatorType
             else if(prevOperatorType!=null)
                newRow.logic = prevOperatorType
             if(index==0 ) 
                newRow.start="("
             if(index==logicGroup.condition.length-1 && (logicGroup.children === undefined || logicGroup.children.length==0) ) {
                newRow.end=end
                end=""
             }
             this.conditionRows.push(newRow)
          })
        }
        if(logicGroup.children!=null){
          logicGroup.children.forEach((lg, index) => {
             if(index<logicGroup.children.length-1 ) 
                 this.makeConditionRow(lg, end+")", logicGroup.logicOperatorType)
             else
                 this.makeConditionRow(lg, end+")", null)
          })
        }
      },
  
      makeConditionRow(logicGroup, start, end, prevOperatorType) {
  
        let rows = []
        if(logicGroup.condition)
          logicGroup.condition.forEach(cond => rows.push(cond))
        if (logicGroup.children) {
          logicGroup.children.forEach(lg => rows.push(lg))
        }
        rows.sort((a, b) => a.sortOrder - b.sortOrder)
  
        
        rows.forEach((row, index) => {
          if(row.logicOperatorType !== undefined ){
              if(rows.length==1) 
                  this.makeConditionRow(row, start+"(", end+")", prevOperatorType)
              else if(index==0) 
                  this.makeConditionRow(row, start+"(", ")", logicGroup.logicOperatorType)
              else if(index<rows.length-1) 
                  this.makeConditionRow(row, "(", ")", logicGroup.logicOperatorType)
              else                                  
                  this.makeConditionRow(row, "(", end+")", prevOperatorType)
          } else {
              let newRow = Object.assign({}, this.defaultConditionRow)
              newRow.attr = row
              
              if(index==0 || index<rows.length-1) 
                 newRow.logic = logicGroup.logicOperatorType
              else if(prevOperatorType!=null)
                 newRow.logic = prevOperatorType
              if(index==0 ) {
                 newRow.start=start
                 start=""
              }
              if(index==rows.length-1) {
                 newRow.end=end
                 end=""
              }
              this.conditionRows.push(newRow)
          }
        })
      },
  
      updateForm() {
        this.conditionRows = []
        this.makeConditionRow(this.logicGroup, "", "", null)
      },
  
      copyCondition() {
        let condition = this.selectedRow.attr
        this.$emit('copy', condition)
      },
  
      pasteConditions() {
        this.copiedFindRuleConditions.forEach(condition => this.afterCreateCondition(JSON.parse(JSON.stringify(condition))))
      }
    },
    mounted() {
      this.updateForm()
    }
  }
  
  </script>
  
  <style lang="scss" scoped>
  .add-container {
    cursor: pointer;
    padding-bottom: 10px;
    width: 100%;
  }
  .section-header {
    font: normal 12px/16px $roboto;
    color: #303030;
  }
  .icon-wrapper {
    background-color: #C4C4C4;
    border-radius: 2px;
  }
  
  .h-100 {
    max-height:500px !important;
    min-height:350px !important;
    height: 350px;
  }
  .w85 {
    width:85px;
  }
  .w70 {
    width:70px;
  }
  .w24 {
    width:24px;
  }
  .abs-but {
    position: absolute;
  }
  .text-nowrap {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pseudoinput {
    cursor: pointer;
    background-color: #f0f0f0;
  }
  .formula-brackets {
    color: #f407b9;
  }
  .formula-operand {
    color: blue;
  }
  .formula-container {
    background-color: #f0f0f0;
    flex-wrap: wrap
  }

  .theme--dark.v-btn:hover::before {
    opacity: 0.8;
    background-color: #F0F0F0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' transform='scale(-1 1)' width='24' height='24' viewBox='0 0 32 32'%3E%3Cpath d='M2,30L2,26L6,30L2,30Z' fill='303030'/%3E%3C/svg%3E");
  }

  .logic-group__text_color {
    color: #303030;
  }
</style>
  