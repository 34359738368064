<template lang='pug'>
app-panel#mnemoschemas(ident="mnemoschemas" left flex)
    .d-flex
      v-tabs.ml-2( height="36px" background-color="transparent" light)
        v-tabs-slider( color="var(--v-primary-darken2)" )
        v-tab(@click='tableName="MNEMO"') {{ $t('module.mnemoschemas.title') }}
        v-tab(@click='tableName="CHANNELS"') {{ $t("module.mnemoschemas.receivers.channel.title") }}
      app-icon-button( icon="mdi-close" @click="handleClose" )
    //- mnemoschema-panel-header(@searchMnemoschema="findMnemo")
    v-row.column-height(v-if="tableName=='MNEMO'" no-gutters)
      v-col.h100p(v-if="mnemoListFlag" cols=4 style="display:inline")
        app-panel( padding="0px")
          app-floating-panels-section( style="overflow: auto; overflow-x: hidden" )
            app-floating-panel( :title="$t('module.mnemoschemas.title')" bodyMinHeight="150" overflowBody="hidden"
            :settings="floatingPanels('MNEMOSCHEMAS_LIST')")
              template( v-slot:headerButton )
                app-menu(:menu="mnemoschemasMenu" basic)

              app-section.d-flex.flex-1.mt-2
                div.flex-1.d-flex(v-if='list.length' style="max-width: 100%")
                  div.list.mnemoschemaListHalfScreen
                    temp-mnemoschemas-list(v-if="!searchBlockFlag" @selectMnemoschema='mnemoschemaSelect')
                    div.searchBlockHalfScreen
                      div.mnemoschemas-list  
                        div(v-for="mnemo in findedItems")
                          div.findedMnenoItem(@click="openFindedMnemoschems(mnemo)") {{mnemo.title}}
                    
                  
                //- div.d-flex.flex-1(v-else)
                //-   v-progress-circular.ma-auto(v-if='isLoading' indeterminate color='accent')
                //-   new-schema-btn.ma-auto(v-else)
      v-col.h100p(:cols="mnemoListFlag ? 8 : 0")
        div.p-relative( style="height: 100%" )
          //- app-section.mh-49
          div.graph-viewer.grapheditorFull.d-flex.flex-1
              graph-viewer( :schema='selectedMnemoschema' @coverGraphViewer="shutDownGraphViewer" @coverMnemoList="shutDownMnemoList")
    channel-panel(v-if="tableName=='CHANNELS'")
    create-schema-dialog(v-model='dialog.addNewMnemoschemas')
</template>

<script>
import TaskHeader from '@/components/task/TaskHeader'
import MnemoschemasList from './MnemoschemasList'
import TempMnemoschemasList from './components/TempMnemoschemasList'
import NewSchemaBtn from './components/NewSchemaBtn'
import GraphViewer from './components/GraphViewer'
import MnemoschemaPanelHeader from './components/MnemoschemaPanelHeader'
import CreateSchemaDialog from './components/CreateSchemaDialog'
import ChannelPanel from './components/channels/ChannelPanel'

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  name: "MnemoschemasPanelNew",

  components: {
    TaskHeader,
    MnemoschemaPanelHeader,
    MnemoschemasList,
    TempMnemoschemasList,
    NewSchemaBtn,
    GraphViewer,
    CreateSchemaDialog,
    ChannelPanel
  },

  data: () => ({
    isLoading: true,
    mnemoListFlag:true,
    searchBlockFlag:false,
    tableName: 'MNEMO',
    findedItems:[],
    dialog:{
        addNewMnemoschemas:false
      },
  }),

  computed: {
    ...mapState('mnemoschema', ['list', 'selectedMnemoschema']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

    checkMnemoList(){
      return this.list.length && this.mnemoListFlag
    },

    mnemoschemasMenu(){
      let act = [
        { title: this.$t('module.mnemoschemas.create'), action: this.showAddNewMnemoschemasDialog }
      ]
      return act
    }
  },

  beforeMount() {
    // this.selectedMnemoschema = this.list[0]
    this.updateList().then(() => {
      this.isLoading = false
    })
  },

  methods: {
    ...mapMutations('mnemoschema', ['setSelectedMnemoschema', 'clear']),
    ...mapActions('mnemoschema', ['updateList']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    mnemoschemaSelect(schema){
      this.setSelectedMnemoschema(schema)
    },

    handleClose() {
      this.setActiveGlobalTab('')
    },

    shutDownGraphViewer(){
      if(!this.mnemoListFlag){
        this.mnemoListFlag = true
      }
      this.setSelectedMnemoschema(null)
    },

    shutDownMnemoList(){
      this.mnemoListFlag = !this.mnemoListFlag
    },

    findMnemo(value){
      this.filterBy(value)
    },
    
    filterBy(text){
      text = text.trim().toLowerCase()
      if (text.length) {
        this.searchBlockFlag = true
        this.findedItems = this.list.filter(el => el.title.toLowerCase().includes(text))
      }

      else{
        this.searchBlockFlag = false
      }
    },

    openFindedMnemoschems(mnemoschema){
      this.setSelectedMnemoschema(mnemoschema)
      this.searchBlockFlag = false
    },

    showAddNewMnemoschemasDialog(){
      this.dialog.addNewMnemoschemas = true
    },

    saveNewMnemoschema(){
      this.dialog.addNewMnemoschemas = false
    }

  },
  // destroyed(){
  //   this.clear()
  // }
}
</script>

<style scoped>
  #mnemoschemas {
    width: 100%;
    height: 100% !important;
  }

  .new-schema-fab {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .flex-1 {
    flex: 1;
  }
  .v-tab {
  opacity:0.5;
  /* color:#fff; */
  color: var(--v-primary-darken2) !important;
  text-transform: none;
  }
  .v-tab--active{
    opacity:1;
  }
  .grapheditorFull{
    padding-left: 0;
  }
  .mnemoschemaBlock{
    width: 100%;
    display: flex;
  }
  .mnemoschemaListHalfScreen{
    max-width: 100%;
    padding: 0px 10px 0px 0px;
    height: 100%;
  }
  .mnemoschemaListFullScreen{
    width: 100%;
    position: relative;
    border-right: none;
  }
  .searchBlockFullScreen{
    position: relative;
    color: #fff;
    width: 100%;
  }
  .searchBlockHalfScreen{
    max-width: 300px;
    height: 100%;
  }
  .findedMnenoItem{
    padding: 6px 6px 6px 8px;
    border-radius: 5px;
    /* background: #6B6B6B; */
    cursor: pointer;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .findedMnenoItem:hover{
    transform: scale(1.01);
    box-shadow: 0 0 10px grey;
  }

  .mnemoschemas-list {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

  }

  .list {
    flex: 1;
    position: relative;
  }

  .graph-viewer {
    height: 100%;
    flex: 1;
  }

  .grapheditorHalf{
    padding-left: 10px;
  }
</style>