<template lang="pug">
  .task-smeta-details-container#task-smeta-details
    .task-smeta-details-header#task-smeta-header.py-3.align-center
      .font-weight-thin(@click="changeDateInterval") {{ getDateStartAndEnd }}

      task-progress-bar.ml-2(ref="progressBar" :task='showSmetaTask' percent @click='showProgressBar' @click.native='$emit("showTaskReport")')
      task-status.ml-4(ref="taskStatus" :task='showSmetaTask' useMenu=true @click='showStatusMenu')
      v-btn.ml-auto(small icon @click='$emit("close")')
        v-icon clear
    .task-smeta-details-body.pt-1( v-if="dataTask && dataTask.smeta && dataTask.smeta.node" )
      .font-weight-regular.mx-2(v-if='!loading') {{ dataTask.smeta.node.title }} 
      v-skeleton-loader.mt-2.mr-9(v-if='loading' type='text' light)
      .volumes.d-flex.flex-column.px-2
        .volume-unit(v-if='!loading') {{ $t("module.task.smeta.volumes.text") + " " + volume.quantityAll }}
        .volume-unit(v-if='!loading') {{ $t("module.task.smeta.volumes.spent") + " " + volume.spent }}
        .volume-unit(v-if='!loading') {{ $t("module.task.smeta.volumes.projected") + " " + volume.projected }}
        v-skeleton-loader.mb-1.mr-9.mt-2(v-if='loading' type='text' light)
        v-skeleton-loader.mb-1.mr-6(v-if='loading' type='text' light)
        v-skeleton-loader.mb-1.mr-1(v-if='loading' type='text' light)
      .d-flex.mb-2.px-2
        button.show-fact.mr-5.px-1.py-1(@click="showSmetaPanel()") {{ $t('module.task.smeta.openQuote')}}
        button.to-task.px-1(@click="toggleTask(showSmetaTask)") {{ $t('module.task.smeta.toTask') }}
      .d-flex.px-2
        v-tabs(background-color='white' height='24' color='#757575' slider-color='#3B93AF')
          v-tab.px-0.v-tab-smeta-fonts(style='color:#757575' :class="{'v-tab-selected':isTabSelected('WORKS')}" 
            @click='tableName="WORKS"; totalTable = 0') {{ $t('module.task.smeta.works') }}
          v-tab.mx-2.px-2.v-tab-smeta-fonts(style='color:#757575' :class="{'v-tab-selected':isTabSelected('MATERIALS')}" 
            @click='tableName="MATERIALS"; totalTable = 0') {{ $t('module.task.smeta.materials') }}
          v-tab.px-2.v-tab-smeta-fonts(style='color:#757575' :class="{'v-tab-selected':isTabSelected('MACHINES')}" 
            @click='tableName="MACHINES"; totalTable = 0') {{ $t('module.task.smeta.machines') }}
      div.d-flex.flex-column.mt-1( v-if='!loading' style='height:100%; overflow:auto')
        v-data-table#smeta-info.mx-2( :headers='headers' :items='itemsData' :hide-default-footer="true" light :fixed-header='true' :no-data-text='$t("module.task.smeta.table.noData")')
        div(v-if='dataTask.smeta.grandSmetaPosition')
          .total.mx-2
            span {{ $t('module.task.smeta.total') }}
            span {{ totalTable }}
          .footer
            .d-flex.justify-space-between.pr-0
              span {{ $t('module.task.smeta.table.totalVolume', {volume: dataTask.smeta.grandSmetaPosition.quantity.result}) }}
              .font-weight-regular {{ totalVolume }}
            .d-flex.justify-space-between.pr-0
              span {{ $t('module.task.smeta.table.overheads', {volume: wageFund}) }}
              .font-weight-regular {{ overheads }}
            .d-flex.justify-space-between.pr-0
              span {{ $t('module.task.smeta.table.estimatedProfit', {volume: wageFund}) }}
              .font-weight-regular {{ estimatedProfit }}
            .d-flex.justify-space-between.pr-0
              span {{ $t('module.task.smeta.table.total') }}
              .font-weight-regular {{ total }}
        .d-flex.justify-center.py-3(v-else)
          .font-weight-thin {{ $t('module.task.smeta.table.noSmeta') }}
      v-skeleton-loader( v-if='loading' type='table-heading, list-item-two-line, list-item-two-line' light)
    .task-smeta-details-body.px-3.py-1( v-else )
      .font-weight-regular.task-smeta-details-empty {{ $t('module.task.smeta.notFound') }}
      button.big-to-task(@click="toggleTask(showSmetaTask)") {{ $t('module.task.smeta.toTask') }}
    task-date-interval(ref="taskDateInterval" :task='showSmetaTask')

</template>

<script>
import { Task } from '@/assets/model/task/Task'
import { mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import TaskProgressBar from '@/components/task/TaskProgressBar.vue'
import TaskStatus from './TaskStatus'
import TaskDateInterval from '@/components/task/TaskDateInterval'
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";

import { toNumber } from "lodash"

export default {
  name: "TaskSmetaDetails",
  components: { TaskProgressBar, TaskStatus, TaskDateInterval },
  props: {
    task: {
      type: Task,
      required: true,
    },
    show: Boolean,
    startPos: {
      x: null,
      y: null
    }
  },
  watch:{
    task(){
      this.loading = true
      this.smetaInfo()
    },
    isTreeInPreparation(val, oldVal){
      if(oldVal==true && val == false && this.dataTask.smeta){
        this.expandTo(this.dataTask.smeta.node.uuid)
        this.setEditRule(this.dataTask.smeta.node)
      }
    },
  },
  data() {
    return { 
      dataTask: null,
      pos1: "",
      pos2: "",
      pos3: "",
      pos4: "",
      tableName: "WORKS",
      loading: true,
      volume: {
        quantityAll: "",
        spent: "",
        projected: ""
      },
      totalVolume: null,
      overheads: null,
      estimatedProfit: null,
      total: null,
      totalTable: null,
      wageFund: null
    }
  },
  computed: {
    ...mapState('task', ['showSmetaTask', 'priorities', 'completeStatuses', 'selectedTask']),
    ...mapGetters('layoutControler', ['panelDataById']),
    ...mapState('smeta', ['items', 'isTreeInPreparation']),
    ...mapGetters('smeta', ['getNode']),

    headers(){
      if(this.tableName == "WORKS") {
        if(this.dataTask.smeta.grandSmetaPosition){
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.worksTitle'), value: 'title', sortable: false},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false, },
            { text: this.$t('module.task.smeta.table.count'), value: 'count', sortable: false, },
            { text: this.$t('module.task.smeta.table.totalCost'), value: 'totalCost', sortable: false, },
          ]
        } else{
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.worksTitle'), value: 'title', sortable: false,},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
          ]
        }
      }
      else if(this.tableName == "MATERIALS")  {
        if(this.dataTask.smeta.grandSmetaPosition){
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.materialsTitle'), value: 'title', sortable: false},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
            { text: this.$t('module.task.smeta.table.count'), value: 'count', sortable: false, },
            { text: this.$t('module.task.smeta.table.totalCost'), value: 'totalCost', sortable: false, },
          ]
        } else{
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.materialsTitle'), value: 'title', sortable: false,},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
          ]
        }
      }
      else if(this.tableName == "MACHINES")  {
        if(this.dataTask.smeta.grandSmetaPosition){
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.machinesTitle'), value: 'title', sortable: false},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
            { text: this.$t('module.task.smeta.table.count'), value: 'count', sortable: false, },
            { text: this.$t('module.task.smeta.table.totalCost'), value: 'totalCost', sortable: false, },
          ]
        } else{
          return [
            { text: '№', value: 'index', sortable: false,},
            { text: this.$t('module.task.smeta.table.machinesTitle'), value: 'title', sortable: false,},
            { text: this.$t('module.task.smeta.table.unit'), value: 'unitValue', sortable: false,},
          ]
        }
      }
      else{
        return []
      }
    },
    itemsData(){
      if(this.tableName == 'WORKS') {
        if(this.dataTask.smeta.grandSmetaPosition){
          return this.dataTask.smeta.grandSmetaPosition.resources.filter(resource =>
            resource.type.name == 'TZR'
          ).map((work, index) => {
            let count = this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result) * this.toFloat(work.quantity)
            let item =  {
              index: index + 1,
              title: work.caption,
              unitValue: work.units,
              count: this.decimalAdjust(count),
              totalCost: work.priceBase != null ? isNaN(this.decimalAdjust(this.toFloat(work.priceBase.value) * count))? "—": this.decimalAdjust(this.toFloat(work.priceBase.value) * count) : "—"
            }
            this.totalTable += typeof item.totalCost == 'string'? 0: item.totalCost
            return item
          })
        } else{
          return this.dataTask.smeta.works.map((work, index) => {
            return {
              index: index + 1,
              title: work.title,
              unitValue: work.unitValue + work.unitType.title
            }
          })
        }
      }
      else if(this.tableName == 'MATERIALS') {
        if(this.dataTask.smeta.grandSmetaPosition){
          return this.dataTask.smeta.grandSmetaPosition.resources.filter(resource =>
            resource.type.name == 'MAT'
          ).map((material, index) => {
            let count = this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result) * this.toFloat(material.quantity)
            let item =  {
              index: index + 1,
              title: material.caption,
              unitValue: material.units,
              count: this.decimalAdjust(count),
              totalCost: isNaN(this.decimalAdjust(this.toFloat(material.priceBase?.value) * count)) ? "—": this.decimalAdjust(this.toFloat(material.priceBase?.value) * count)
            }
            this.totalTable += typeof item.totalCost == 'string'? 0: item.totalCost
            return item
          })
        } else {
          return this.dataTask.smeta.materials.map((material, index) => {
            return {
              index: index + 1,
              title: material.title,
              unitValue: material.unitValue + material.unitType.title
            }
          })
        }
      }
      else if(this.tableName == 'MACHINES') {
        if(this.dataTask.smeta.grandSmetaPosition){
          return this.dataTask.smeta.grandSmetaPosition.resources.filter(resource => 
            resource.type.name == 'MCH'
          ).map((machine, index) => {
            let count = this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result) * this.toFloat(machine.quantity)
            let item = {
              index: index + 1,
              title: machine.caption,
              unitValue: machine.units,
              count: this.decimalAdjust(count),
              totalCost: machine.priceBase ? isNaN(this.decimalAdjust(this.toFloat(machine.priceBase?.value) + this.toFloat(machine.priceBase?.zm ?? "0") * count)) ? "—": this.decimalAdjust((this.toFloat(machine.priceBase?.value) + this.toFloat(machine.priceBase?.zm ?? 0)) * count) : "—"
            }
            this.totalTable += typeof item.totalCost == 'string'? 0: item.totalCost
            return item
          })
        } else{
          return this.dataTask.smeta.machines.map((machine, index) => {
            return {
              index: index + 1,
              title: machine.title,
              unitValue: machine.unitValue + machine.unitType.title
            }
          })
        }
      }
      else{
        return []
      }
    },
    getDateStartAndEnd(){
      let startDate = this.getPrettyDate(this.task.startDate)
      let endDate = this.getPrettyDate(this.task.endDate)
      
      return this.$t('module.task.time.from') + startDate + this.$t('module.task.time.to') + endDate
    }
  },
  
  methods: {
    ...mapActions('task', ['getSmetasByTaskUuid', 'getSmetaVolumes']),
    ...mapMutations('task', ['setTaskDisplay', 'setSelectedTask', 'addSubTaskOpen', 'delAllSubTaskOpen']),
    ...mapActions('smeta', ['LOAD_TREE', 'expandTo']),
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapMutations('smeta', ['setEditRuleItem']),

    showProgressBar(){
      this.$refs.progressBar.showEditProgress()
    },
    showStatusMenu (event) {
      this.$refs.taskStatus.showStatusMenu(event)
    },
    changeDateInterval(){ 
      this.$refs.taskDateInterval.showTaskDateInterval()
    },
    dragElement(elmnt) {
      let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById("task-smeta-header")) {
        document.getElementById("task-smeta-header").onmousedown = dragMouseDown;
      } else {
        elmnt.onmousedown = dragMouseDown;
      }

      let top = null
      if(this.startPos && this.startPos.y){  
        if(this.startPos.y < 400) top = (this.startPos.y - 54) + "px"
        else top = (this.startPos.y - 325) + "px"        
      } else top =  260 + "px"
      elmnt.style.top = top

      let left = null
      if(this.startPos && this.startPos.x) left = this.startPos.x - (document.documentElement.clientWidth - toNumber(this.panelDataById('tasks').width)) + 'px' //такое обращение к панели нежелательно
      else left = "calc(50% - 300px)"
      elmnt.style.left = left;
      
      function dragMouseDown(e) {
        e = e || window.event;
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },

    smetaInfo(){
      if (this.showSmetaTask) {
        this.getSmetasByTaskUuid(this.showSmetaTask.uuid).then((data) => {
          if(!data) return
          this.dataTask = new Task(this.task, this.task.parent, this.task.level, data)
          this.getSmetaVolumes({ taskUuid: this.showSmetaTask.uuid, dataTask: this.dataTask }).then(volume => {
            this.volume = volume
            this.loading = false
          })
          .catch(() => {
            console.log('SMETA not found');
            this.dataTask = null
          })
          if (this.dataTask?.smeta?.grandSmetaPosition){
            this.totalVolume = this.dataTask.smeta.grandSmetaPosition.priceBase ? this.decimalAdjust(this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.pz) * this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result)) : 0
            this.wageFund = this.dataTask.smeta.grandSmetaPosition.priceBase ? this.decimalAdjust((this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.oz) + this.toFloat(this.dataTask.smeta.grandSmetaPosition.priceBase.zm)) * this.toFloat(this.dataTask.smeta.grandSmetaPosition.quantity.result)) : 0
            this.overheads = this.decimalAdjust(this.wageFund * 1.02)
            this.estimatedProfit = this.decimalAdjust(this.wageFund * 0.58)
            this.total = this.decimalAdjust(this.totalVolume + this.overheads + this.estimatedProfit)
          }
        })
      }
    },
    isTabSelected(selectedTableName){
      return this.tableName==selectedTableName
    },
    toggleTask (t) { 
      this.$store.dispatch('selected/dropSmetaHighlights')
      this.setTaskDisplay('tree')
      this.$emit("close")
      this.setSelectedTask(t)
      this.delAllSubTaskOpen()
      this.addSubTaskOpen(t.uuid)
    },
    showSmetaPanel(){
      this.setActiveGlobalTab('smeta')
      setTimeout(()=>{
        if(!this.isTreeInPreparation){
          if(this.items.length==0){
          this.LOAD_TREE(this.projectSettings.projectClassificator)
          }
          else{
            this.expandTo(this.dataTask.smeta.node.uuid)
            this.setEditRule(this.dataTask.smeta.node)
          }
        }
        else{
          this.expandTo(this.dataTask.smeta.node.uuid)
          this.setEditRule(this.dataTask.smeta.node)
        }
      },250)
    },

    setEditRule(node){
      let fullNode = this.getNode(node.uuid)
      this.setEditRuleItem(fullNode.children[0])
    },

    toFloat(number){
      if(typeof number == 'string')
        return parseFloat(number?.replace(/[,]/g, '.'))
      return number
    },
    decimalAdjust(value, exp = -2) {
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math['ceil'](value);
      }
      value = +value;
      exp = +exp;
      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      value = value.toString().split('e');
      value = Math['ceil'](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
      value = value.toString().split('e');
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    },

    getPrettyDate(date) {
      date = new Date(date)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      day = day < 10 ? '0' + day : day
      month = month < 10 ? '0' + month : month

      return day + "." + month + "." + year
    }
  },
  beforeMount() {
    this.smetaInfo()
  },
  mounted() {
    this.dragElement(document.getElementById("task-smeta-details"))
  },
  beforeDestroy() {
    XeokitMediator.ElementsSelection.selectElements([])
  }
  
}
</script>

<style lang='scss' scoped>
.task-smeta-details-empty {
  margin: 30px auto;
}

  .task-smeta-details-container{
    background: #4D4D4D;
    padding: 0px 4px 8px 4px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 635px;
    /* height: 450px; */
    max-height: calc(100% - 150px);
    min-height: 350px;
    border-radius: 3px;
    z-index: 100000;
    box-shadow: 5px 5px 5px black;
    font-size: 14px;
  }
  .task-smeta-details-header{
    display: flex;
    color: #E5E5E5;
    font-weight: 300;
    padding: 0px 0px 0px 8px;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .task-smeta-details-body{
    background: white;
    color: #262626;
    flex: 1;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    gap: 4px;
    overflow: hidden;
  }
  .volumes{
    color: #757575
  }
  #smeta-info {
    & .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
      height: 26px;
      white-space: nowrap;
      background: #E5E5E5 !important;
    }
  }
  td{
    font-size: 14px;
  }
  .v-tab-selected{
    border-radius: 3px 3px 0px 0px;
    background-color: #e5e5e5;
  }
  .v-tab-smeta-fonts{
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-transform: none;
  }
  .v-slide-group__prev{
    min-width:0px;
    width:0px;
    flex:none;
  }
  .v-slide-group__next{
    min-width:0px;
    width:0px;
    flex:none; 
  }
  .v-slide-group__content{
    flex: 0 0 auto;
  }
  .v-tab{
    min-width: 68px;
    flex: 0 0 auto;
  }
  .show-fact{
    font: normal 12px/16px Roboto, sans-serif;
    color: #3B93AF;
  }
  .show-fact:hover{
    background: #E5E5E5;
    border-radius: 3px;
  }
  .to-task{
    font: normal 12px/16px Roboto, sans-serif;
    color: #3B93AF;
  }
  .to-task:hover{
    background: #E5E5E5;
    border-radius: 3px;
  }
  .big-to-task{
    font: normal 18px/16px Roboto, sans-serif;
    color: #3B93AF;
    width: fit-content;
    margin: 5px auto;
    padding: 5px 10px 5px 10px;
  }
  .big-to-task:hover{
    background: #E5E5E5;
    border-radius: 3px;
  }
  .total{
    justify-content: space-between;
    display: flex;
    color: #262626;
    font-size: 14px;
    font-weight: bold;
    padding: 12px 38px 12px 60px;
    border-top: 1px solid #E5E5E5;
  }
  .footer{
    background: #E5E5E5;
    display: flex;
    flex-direction: column;
    padding: 12px 24px 12px 24px;
    color: #262626;
    gap: 4px;
  }
</style>