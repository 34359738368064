<template lang="pug">
  app-dialog-confirm( v-model="visible" delete @confirm="onDeletingGroup") 
    app-text {{ $t('section.collision.rulesFounder.delGroup', {group: group.groupTitle}) }}
</template>

<script>
import { mapActions } from 'vuex'

export default {

  data() {
    return {
      group: {
        groupTitle: '',
      },
      
      visible: false,
    }
  },

  methods: {
    ...mapActions('collision/table', ['deleteGroup']),

    show(group) {
      this.group = group
      this.visible = true
    },

    onDeletingGroup () {
      this.deleteGroup(this.group.groupUuid)
    }
  }
}
</script>