<template lang="pug">
  .task-tree-executor-row
    div.d-flex.align-center(v-if='task.workers.length > 0')
      .rounded-circle.dark.mr-1
        v-icon mdi-account
      .task-workers-name
        span {{ task.workers[0].name }}
      //- v-btn(x-small icon color='#4d4d4d')
      //-   v-icon mdi-menu-down
    div.mx-auto(v-else-if="hasTaskAppointment")
      app-button-add(width='100%' :text="$t('section.structure.model.addTeam')" @click="showWorkersDialog")
      
    task-appointed-section(ref="taskAppointed" :selectedTask="task" :onlyDialogs="true")
</template>

<script>
import { Task } from '@/assets/model/task/Task'
import TaskAppointedSection from '@/components/task/taskDetailsSections/TaskAppointedSection'
import { mapGetters } from 'vuex'
export default {
  name: "TreeExecutor",
  components:{
    TaskAppointedSection
  },
  props: {
    task: {
      type: Task,
      required: true
    }
  },

  computed: {
    ...mapGetters('projectPermissions', ['hasTaskAppointment']),
  },

  methods:{
    showWorkersDialog(event){
      this.$refs.taskAppointed.showWorkersDialog(event)
    }
  }
}
</script>

<style lang="scss" scoped>
  .task-tree-executor-row{
    display: flex;
    color: #4D4D4D;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0px 4px 0px 6px;
    min-width:150px; 
    flex:0;
  }
  .task-workers-name{
    max-width: 100px; 
    text-overflow: ellipsis; 
    overflow: hidden;
    white-space: nowrap;
  }
</style>