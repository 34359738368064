import { defineStore } from 'pinia'
import { isGridsAvailable, isGridsActive, toggleDisplayOfGrids } from '@/facades/grids.facade.js'
import i18n from '@/plugins/i18n'

export const useGridsViewerToolState = defineStore('grid-viewer-tool-state', {
  getters: {
    isGridsAvailable: () => isGridsAvailable(),
    isGridsActive: () => isGridsActive(),

    gridsTitle: () => {
      let title = i18n.t('headerToolbar.showIfcGrids')
      let availableText = isGridsAvailable ? 'Доступны' : 'Недоступны'
      return `${title} (${availableText})`
    }
  },

  actions: {
    toggleDisplayOfGrids() {
      toggleDisplayOfGrids()
    }
  }
})