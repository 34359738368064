<template lang="pug">
  div
    .task-row.mt-2( v-if="!report.edit" )
      .font-weight-regular.pl-2 {{ (report.reportDate || report.createDate) |  prettyDate}}
      v-tooltip( bottom )
        template( v-slot:activator="{ on }" )
          .font-weight-regular.pl-2.size( v-on="on") {{ getNumber(report.value) }} 
        span {{ getNumber(report.value) }}
      v-tooltip( bottom )
        template( v-slot:activator="{ on }" )
          .font-weight-regular.percent( v-on="on" ) {{ percentageСalculation(report.value).toLocaleString() }} 
        span {{ percentageСalculation(report.value).toLocaleString() }}
  
      task-button-action.mr-3.min-width.pl-2(v-if="hasTaskVolumes" icon="project/tools/edit.svg" test-id="task-report-table-row-edit" @click.stop="$emit('showEditReport', report)" )
      task-button-action.mr-3.min-width-2(v-if="hasTaskVolumes" icon="project/tools/delete_fill.svg" test-id="task-report-table-row-delete" @click.stop="confirmDeleteReport(report)")
      
    .task-row.mt-2( v-else )
      v-menu(
        ref="expired"  
        :close-on-content-click="hasTaskVolumes" 
        transition="scale-transition" offset-y min-width="auto"
        v-model="show.pickerStart"
      )
        template(v-slot:activator='{on, attrs}')
          .report-font.pl-2.date-input.mr-1.pt-1( test-id="task-report-table-row-date-input" v-bind='attrs' v-on='on' ) {{ (report.reportDate || report.createDate) | prettyDate}}
        v-date-picker( v-model='report.reportDate' @change='closeDatePicker()' first-day-of-week=1 no-title test-id="task-report-table-row-picker")
      input.report-value-input.report-font.pl-2.mr-2( v-model="report.value" maxlength="17" size="5" test-id="task-report-table-row-input-value" :disabled="!hasTaskVolumes") 
      .font-weight-regular {{ percentageСalculation(report.value).toLocaleString() }}
      task-button-action.mr-3.min-width-2(v-if="hasTaskVolumes" icon="project/tools/save.svg" @click.stop="$emit('saveReport', report)" test-id="task-report-table-row-save")
      task-button-action.mr-3.min-width-2(v-if="hasTaskVolumes" icon="project/tools/delete_fill.svg" @click.stop="confirmDeleteReport(report)")

    v-divider.mt-1( light )
    
    app-dialog-confirm(
      v-model="dialog.confirmDelReport" 
      @confirm="deleteSelectedReport"
    ) 
      app-text {{ $t('module.task.reports.delete', { date: datePrettier(report.reportDate || report.createDate), value: report.value}) }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaskButtonAction from '@/components/task/taskDetailsSections/TaskButtonAction.vue'


export default {
  name: "TaskReportTableRow",

  components: {
    TaskButtonAction  
  },

  filters: {
    prettyDate: (fulldate) => {
      let [date] = fulldate.split(' ')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
  },

  props: {
    report: null,
    estimation: null,
    selectedTask: null,
    viewPercent: null
  },

  data() {
    return{
      show: {
        pickerStart: false,
      },

      dialog:{
        confirmDelReport: false,
      },
      
      choosedReport: new Date( Date.now() ),
    }
  },

  computed: {
    ...mapGetters('projectPermissions', ['hasTaskVolumes']),
  },

  methods:{
    ...mapActions('task', ['editTaskReportVolume', 'deleteTaskReportVolume', 'createTaskReportVolume']),

    showDatePicker (report) {
      this.choosedReport=report
      this.show.pickerStart=true
    },

    closeDatePicker () {
      this.show.pickerStart=false
    },

    confirmDeleteReport(){
      this.dialog.confirmDelReport=true
    },

    deleteSelectedReport(){
      this.$emit('deleteReport', this.report)
    },

    datePrettier: (fulldate) => {
      let [date] = fulldate.split(' ')
      let [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    percentageСalculation(value){
      let reportValue = Number(value)
      let estimationValue = this.estimation
      if (isNaN(reportValue)) return 0 + "%"
      if (isNaN(estimationValue)) estimationValue = 100
      if (estimationValue == 0) return 100 + "%"
      let percent = Number((reportValue / estimationValue * 100).toFixed(4))
      return percent + "%"
    }, 

    getNumber(value){
      return Number(Number(value).toFixed(4))
    }

//
  }
}
</script>

<style lang="scss" scoped>
  .size{
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .percent{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .task-tree{
    flex: 1;
    background: white;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 2px;
  }
  .header{
    height: 100%;
  }
  .task-details-tree{
    height: calc(100%);
    overflow: hidden;
  }
  .sort-icon{
    transform: rotate(180deg);
  }
  .sort-icon-reverse{
    transform: rotate(0deg);
  }
  .pointer{
    cursor: pointer;
  }
  .task-row{
    display: grid;
    grid-template-areas: "A B C D E F";
    grid-template-columns: 90px 90px 89px 35px 36px;
  }
  .report-value-input{
    background-color: #E5E5E5;
    border-radius: 3px;
    outline:none;
  }
  .date-input{
    background-color: #E5E5E5;
    border-radius: 3px;
  }
  .min-width{
    min-width: 30px
  }

  .min-width-2 {
    min-width: 20px;
  }
  .posit {
    margin-left: 9px;
  }
</style>