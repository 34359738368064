<template lang="pug">
app-dialog(v-model="dialog.sign" :header="isCheck ? $t('section.journal.verifySignature'): isParallelSign ? $t('section.journal.createParallelDS'):$t('section.journal.createSignature')" @close="handleClose" :width="isCheck ? '550px':'400px'")
  app-section()
    .d-flex.flex-column(v-if="isCheck")
      app-text-field.mb-2(v-model="item.name" disabled  :label="$t('section.journal.fileName')" hide-details)
      v-data-table(:headers="headers" :items="signs" :items-per-page="5" :footer-props="{ 'items-per-page-options': [5, 10, 15] }" light dense item-key="id" :no-data-text="$t('section.journal.noSignaturesFile')")         
        template(v-slot:item.name="{ item }")
          v-tooltip(bottom) 
            template(v-slot:activator="{ on }") 
              div(v-on="on") {{item.name}}
            span {{item.name}}
        template(v-slot:item.status="{ item }")
          div(:style="getStatusColor(item.status)") {{item.status}}
      v-progress-linear(v-if="loading" v-model="loading"  height="25" rounded)
        template(v-slot:default="{ value }")
          strong {{loadText}} {{ Math.ceil(value) }}%
      v-btn.align-self-end.mt-3(color='accent' :disabled="disabledBtn" light small @click="checkSignFile") {{ $t('section.journal.verifySignature') }}

    .d-flex.flex-column(v-else-if="isParallelSign")
      app-text-field.mb-2(v-model="item.name" disabled  :label="$t('section.journal.fileName')" hide-details)
      app-autocomplete.mb-2(v-model="certificate" clearable item-text="name" :label="$t('section.journal.selectCertificate')" 
      :items="certificates" :disabled="disableSelect" @change="changeCert")
      app-autocomplete.mb-2(v-model="sign" clearable item-text="name" :label="$t('section.journal.selectSignature')"
      :items="signs" :disabled="disableSelect")
      v-btn.align-self-start(v-if="certificate" color="#3B93AF"  @click="toggleDetails = !toggleDetails" dense x-small text) {{ $t('section.journal.aboutCertificate') }}
        v-icon(v-if="!toggleDetails" small).pl-1 mdi-menu-down
        v-icon(v-if="toggleDetails" small).pl-1 mdi-menu-up
      div.pl-2(v-if="toggleDetails && certificate")
        .text-info {{ $t('rights.shortCut.owner') }}: {{ownerName}}
        .text-info {{ $t('section.journal.editor') }}: {{issureName}}
        .text-info {{ $t('section.journal.print') }}: {{certificate.thumbprint}}
        .text-info {{ $t('section.journal.issued') }}: {{getNormalDate(certificate.validFrom)}}
        .text-info {{ $t('section.journal.expires') }}: {{getNormalDate(certificate.validTo)}}
      v-progress-linear.mt-2(v-if="loading" v-model="loading"  height="25" rounded)
        template(v-slot:default="{ value }")
          strong {{loadText}} {{ Math.ceil(value) }}%
      v-btn.align-self-end.mt-3(color='accent' :disabled="disabledParallelSign" light small @click="createParallelSign") Создать параллельную подпись
      
    .d-flex.flex-column(v-else)
      app-text-field.mb-2(v-model="item.name" disabled  :label="$t('section.journal.fileName')" hide-details)
      app-autocomplete.mb-2(v-model="certificate" clearable item-text="name" :label="$t('section.journal.selectCertificate')" dense
      :items="certificates"  :disabled="disableSelect" @change="changeCert")
      v-btn.align-self-start(v-if="certificate" color="#3B93AF"  @click="toggleDetails = !toggleDetails" dense x-small text) {{ $t('section.journal.aboutCertificate') }}
        v-icon(v-if="!toggleDetails" small).pl-1 mdi-menu-down
        v-icon(v-if="toggleDetails" small).pl-1 mdi-menu-up
      div.pl-2(v-if="toggleDetails && certificate")
        .text-info {{ $t('rights.shortCut.owner') }}: {{ownerName}}
        .text-info {{ $t('section.journal.editor') }}: {{issureName}}
        .text-info {{ $t('section.journal.print') }}: {{certificate.thumbprint}}
        .text-info {{ $t('section.journal.issued') }}: {{getNormalDate(certificate.validFrom)}}
        .text-info {{ $t('section.journal.expires') }}: {{getNormalDate(certificate.validTo)}}
      v-progress-linear.mt-2(v-if="loading" v-model="loading"  height="25" rounded)
        template(v-slot:default="{ value }")
          strong {{loadText}} {{ Math.ceil(value) }}%
      v-btn.align-self-end.mt-3(color='accent' :disabled="disabledBtn" light small @click="signFile") {{ $t('section.journal.signFile') }}
  
</template>

<script>
  import { getUserCertificates, createDetachedSignature, getSystemInfo } from 'crypto-pro'
  import { AlertService } from '@app/AlertService'
  import CryptoPro from '@@/project/panel/left/fileManager/cryptoPro'

  const STATUS = { good: 'Успех', bad: 'Ошибка загрузки', errorHash: 'Неверный хэш', notverified: 'Не проверено' }

  export default {
    name: 'FileSign',
    components: {},
    props: {
      item: Object,
      isCheck: {
        type: Boolean,
        default: false,
      },
      isParallelSign: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        dialog: {
          sign: true,
        },
        signs: [],
        sign: null,
        loadText: 'Загрузка файла',
        disabledBtn: true,
        disableSelect: false,
        certificates: [],
        certificate: null,
        file: null,
        key: null,
        hash: '',
        result: null,
        toggleDetails: false,
        loading: 0,

        crypto: new CryptoPro(),
      }
    },

    mounted() {
      getSystemInfo()
        .then((info) => {
          console.log(info)
          if (this.isCheck) this.getAllSignsId()
          else
            getUserCertificates()
              .then((el) => {
                this.certificates = el
                if (this.isParallelSign) this.getAllSignsId()
              })
              .catch(() => AlertService.error({ data: { error_description: this.$t('error.receivingCertificates') } }))
        })
        .catch(() =>
          AlertService.error({ data: { error_description: this.$t('error.accessingCryptoProPlugin') } })
        )
    },

    computed: {
      headers() {
        return [
          { text: this.$t('error.nameSignature'), value: 'name', sortable: false, cellClass: 'text-truncate max-size' },
          { text: this.$t('error.status'), value: 'status', sortable: false },
          { text: this.$t('error.dateSigning'), value: 'createDate', sortable: false },
        ]
      },

      disabledParallelSign() {
        return this.disabledBtn || !this.sign
      },

      issureName() {
        return this.certificate.issuerName.match(/CN=(?<name>.*?),/).groups.name || this.certificate.issuerName || ''
      },

      ownerName() {
        return this.certificate.subjectName.match(/CN=(?<name>.*?),/).groups.name || this.certificate.name || ''
      },
    },

    methods: {
      changeCert(cert) {
        if (cert) {
          cert
            .isValid()
            .then((valid) => (this.disabledBtn = !valid))
            .catch(() => (this.disabledBtn = true))
        } else this.disabledBtn = true
      },

      handleClose(event) {
        this.$emit('close', event)
      },

      getStatusColor(status) {
        let color = 'grey'
        switch (status) {
          case STATUS.good:
            color = 'green'
            break
          case STATUS.bad:
          case STATUS.errorHash:
            color = 'red'
            break
        }
        return `color:${color}`
      },

      getAllSignsId() {
        this.crypto
          .getAllSignsId(this.item.fileid)
          .then((response) => {
            this.signs.push(
              ...response.map((el) => {
                el.status = STATUS.notverified
                return el
              })
            )
            if (this.signs.length) this.disabledBtn = false
          })
          .catch(() => {
            AlertService.error({ data: { error_description: this.$t('error.receivingSignatureData') } })
          })
      },

      downloadFile() {
        return this.crypto
          .downloadFile(this.item.fileid, (el) => {
            if (el.lengthComputable) this.loading = (el.loaded / el.total) * 100
          })
          .then((file) => (this.file = file))
          .catch(() => {
            AlertService.error({ data: { error_description: this.$t('error.uploadingFile') } })
          })
      },

      downloadSign(sign) {
        this.crypto
          .downloadSign(sign.id)
          .then((file) => this.verifySign(sign, file))
          .catch(() => (sign.status = STATUS.bad))
      },

      signFile() {
        this.disabledBtn = true
        this.disableSelect = true
        this.downloadFile().then(() => {
          this.loading = 0
          this.loadText = this.$t('error.calculatingHashSum')
          this.crypto
            .hashFileAsChunks(this.file, (progress) => (this.loading = progress))
            .then((hash) => {
              createDetachedSignature(this.certificate.thumbprint, hash)
                .then((key) => {
                  this.key = key
                  this.uploadKey()
                })
                .catch(() => AlertService.error({ data: { error_description: this.$t('error.creatingSignature') } }))
            })
            .catch(() => AlertService.error({ data: { error_description: this.$t('error.errorCalculatingHashSum') } }))
        })
      },

      createParallelSign() {
        this.disabledBtn = true
        this.disableSelect = true
        this.crypto
          .downloadFile(this.sign.id)
          .then((key) => {
            this.downloadFile().then(async () => {
              this.loading = 0
              this.loadText = this.$t('error.calculatingHashSum')
              key = await key.text()
              this.crypto
                .hashFileAsChunks(this.file, (progress) => (this.loading = progress))
                .then((hash) => {
                  this.crypto.createCoSign(this.certificate, key, hash).then((key2) => {
                    if (key2) {
                      this.key = key2
                      this.uploadParallelKey()
                    } else
                      AlertService.error({ data: { error_description: this.$t('error.creatingParallelSignature') } })
                  })
                })
                .catch(() => AlertService.error({ data: { error_description: this.$t('error.errorCalculatingHashSum') } }))
            })
          })
          .catch(() => AlertService.error({ data: { error_description: this.$t('error.receivingSignature') } }))
      },

      checkSignFile() {
        this.disabledBtn = true
        this.downloadFile().then(() => {
          this.loading = 0
          this.loadText = this.$t('error.calculatingHashSum')
          this.crypto
            .hashFileAsChunks(this.file, (progress) => (this.loading = progress))
            .then((hash) => {
              this.hash = hash
              this.verifyDetachedSignature()
            })
            .catch(() => AlertService.error({ data: { error_description: this.$t('error.errorCalculatingHashSum') } }))
        })
      },

      verifyDetachedSignature() {
        this.loading = 0
        for (let sign of this.signs) {
          this.downloadSign(sign)
        }
      },

      getNormalDate(date) {
        return new Date(date).toLocaleString('ru')
      },

      async verifySign(sign, key) {
        let result = await this.crypto.verifySign(this.hash, key)
        if (result) {
          sign.status = STATUS.good
          sign.createDate = this.getNormalDate(result)
        } else {
          sign.status = STATUS.errorHash
        }
      },

      uploadKey() {
        this.loading = 0
        this.crypto
          .uploadKey(this.item.fileid, this.key, this.certificate.name)
          .then(() => {
            this.handleClose(true)
            AlertService.success({ info: this.$t('error.signatureUploadednextCloud') })
          })
          .catch(() => AlertService.error({ data: { error_description: this.$t('error.errorSignaturenextCloud') } }))
      },

      uploadParallelKey() {
        this.loading = 0
        // let name = this.sign.name.match(/^[^_]+/g)[0] //TODO: _ - Вызовет ошибку если имя Hne_HNE_docx
        let docName, ext
        docName = this.item.name.match(/(?<name>.+)\..+$/).groups.name
        'xs1', (ext = this.item.name.match(/\w+$/)[0])
        let regx = `_${ext}_${docName}.sig`
        regx = `(?<name>.+)${regx}`
        let name = this.sign.name.match(regx).groups.name
        this.crypto
          .uploadKey(this.item.fileid, this.key, name)
          .then(() => {
            this.handleClose(true)
            AlertService.success({ info: this.$t('error.signatureUploadednextCloud') })
          })
          .catch(() => AlertService.error({ data: { error_description: this.$t('error.errorSignaturenextCloud') } }))
      },
    },
  }
</script>

<style scoped>
  .text-info {
    color: black;
    font-size: 12px;
  }
  ::v-deep .max-size {
    max-width: 200px;
  }
</style>
<style>
  #cadesplugin_ovr {
    z-index: 9999;
  }
</style>
