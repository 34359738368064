<template lang="pug">
.node( :class="{ 'node--hightlighted': hightlighted }" @click="clickOnRow" )
  .node__root
    .node__level( v-for="index in level" :key="index" )
    
    .node__level( v-if="source.isEndpoint" )
    v-icon.node__toggle( v-else tag="button" :class="toggleClass" light @click.stop="toggleDisclosedNode(source)" ) arrow_drop_down
  
    .node__content
      .node__label <b v-if="source.classTitle">{{ source.classTitle }}:</b> {{ $t(getAlias(source.name)) }} 
      .node__stat( v-if="details" ) {{ details }}
      v-icon.node__checkbox( tag="button" light @click.stop="clickOnCheckbox" ) {{ checkboxIcon }}
</template>

<script>
import { AxisDataType } from '@/assets/model/axis'
import { mapActions, mapGetters, mapState } from 'vuex'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import { utils } from '@xeokit/xeokit-sdk'

export default {
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    ...mapState('axis/tree', ['node', 'dataType']),
    ...mapGetters('axis/tree', ['isTreeNodeDisclosed', 'isTreeEndpointSelected', 'numberOfSelectedEndpointsForNode']),

    hightlighted () {
      return this.node?.extraId == this.source.extraId
    },

    isOpen () {
      return this.isTreeNodeDisclosed(this.source)
    },

    toggleClass () {
      return this.isOpen ? 'node__toggle--open' : 'node__toggle--close'
    },

    level () {
      return this.source.level ?? 0
    },

    // isActive () {
    //   if (this.source.isEndpoint) {
    //     return this.isTreeEndpointSelected(this.source)
    //   }
    //   else {
    //     let num = this.numberOfSelectedEndpointsForNode(this.source)

    //     if (num > 0) {
    //       return num == this.source.numberOfEndpoints
    //     }
    //     return false
    //   }
    // },

    checkboxIcon () {
      if (this.source.isEndpoint) {
        let selected = this.isTreeEndpointSelected(this.source)
        let icon = selected ? 'check_box' : 'check_box_outline_blank'
        return icon
      }
      else {
        let icon = 'check_box_outline_blank'
        let num = this.numberOfSelectedEndpointsForNode(this.source)

        if (num > 0) {
          let selected = num == this.source.numberOfEndpoints
          icon = selected ? 'check_box' : 'indeterminate_check_box'
        }
        return icon
      }
    },

    details () {
      if (this.dataType == AxisDataType.COSTING) return this.source.numberOfEndpoints
      else if (this.dataType == AxisDataType.COLLISIONS) return `${this.source.countCollisions}/${this.source.countFullCollisions}`
      return null
    },
  },

  mounted () {
    let openGlobalTab = utils.queryString["open"]
    if (openGlobalTab && openGlobalTab !==undefined && openGlobalTab.indexOf('collision') != -1) {
      this.clickOnCheckbox()
    }
  },

  methods: {
    ...mapActions('axis/tree', ['toggleDisclosedNode', 'toggleNodeSelection', 'fetchElementByNode', 'selectNode']),

    getAlias (alias) {
      return NotificationService.NotificationReplace(alias)
    },

    clickOnCheckbox() {
      this.toggleNodeSelection(this.source)
    },

    clickOnRow () {
      this.fetchElementByNode(this.source)
    }
  }
}
</script>

<style lang="scss" scoped>
.node {
  color: rgba(0,0,0,.87);
}
.node:hover {
  background-color: var(--v-primary-lighten5);
}
.node--hightlighted {
  background-color: var(--v-primary-lighten5);
  box-shadow:inset 0px 0px 0px 1px var(--v-primary-lighten4);
}
.node__root {
  display: flex;
  align-items: center;
  min-height: 24px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.node__root::before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}

.node__root::after {
  content: "";
  font-size: 0;
  min-height: inherit;
}

.node__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}

.node__toggle::after {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.3);
  width: 100%;
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
}

.node__content {
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
  margin-left: 6px;
  padding-right: 4px;
  justify-content: space-between;
}

.node__label {
  flex: 1;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.node__stat {
  font-size: 12px;
  color: var(--v-accent-darken3);
  padding: 0 4px;
}

.node__level {
  width: 8px;
}

.node__checkbox {
  color: var(--v-primary-lighten4);
  caret-color: var(--v-primary-lighten4);
  font-size: 16px;
}
</style>
