<template lang="pug">
  v-expansion-panels.panels(focusable light)
    v-expansion-panel(v-for="(item,i) in headers" :key="i")
      v-expansion-panel-header
        div(style="display: inline-flex; align-items: center;") 
          v-icon(v-if="item.icon" style="margin-right: 10px;") {{ getIcon(item.type) }}
          app-text {{ item.title }}
      v-expansion-panel-content

        div(v-if="item.type === 'table' && !archived")
          wjDataTable(light :headers="item.headers" :items="items[item.itemName]" :parentName="item.parent" :tableName="item.itemName" 
            @newItem="newItem" @editItem="editItem")
          
        div(v-if="item.type === 'table' && archived")
          wjDataTableWithPagination(light :headers="item.headers" :items="items[item.itemName]" :itemsPerPage="5")

        div(v-if="item.type === 'input'")
          app-text-field.panel-field(v-model="items[item.itemName]" @change="checkValue(item.itemName)" light :label="item.input.label" :hint="item.input.hint")

        div(v-if="item.type === 'inputWithAutocomplete'")
          AppAutocomplete.panel-field(v-model="items[item.itemName.first]" @change="checkValue(item.itemName.first)" light :label="item.input.first.label" :hint="item.input.first.hint" 
            :items="objectType" item-text="title")
          app-text-field.panel-field(v-model="items[item.itemName.second]" @change="checkValue(item.itemName.second)" light :label="item.input.second.label" :hint="item.input.second.hint")
        
        div(v-if="item.type === 'datePicker'")
          wjDatePicker(:label="item.input.first.label" :item="items[item.itemName.first]" :name="item.itemName.first" @saveValue="saveAndCheckValue")
          wjDatePicker(:label="item.input.second.label" :item="items[item.itemName.second]" :name="item.itemName.second" @saveValue="saveAndCheckValue")
</template>

<script>
import wjDataTable from './DataTable'
import wjDataTableWithPagination from './DataTableWithPagination'
import wjDatePicker from './DatePicker'

import textJournal from '@/components/project/panel/left/documents/WorkJournal/textJournal'

export default {
  components: {
    wjDataTable,
    wjDataTableWithPagination,
    wjDatePicker
  },

  props: {
    headers: null,
    items: null,

    archived: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      objectType: textJournal.title.objectTypes,
    }
  },

  methods: {
    getIcon(type) {
      if(type == "table")
        return 'mdi-table-large'
      if(type == "input" || type == "datePicker" || type == "inputWithAutocomplete")
        return 'mdi-text-box-outline'
      
      return null
    },

    saveAndCheckValue(date, name) {
      this.items[name] = date
      this.checkValue(name)
    },
    checkValue(item) {
      this.$emit('checkValue', item)
    },
    editItem(item, tableName) {
      this.$emit('showEditItemDialog', item, tableName)
    },
    newItem(tableName, parentName) {
      this.$emit('showNewItemDialog', tableName, parentName)
    },
  },
}
</script>

<style lang="scss" scoped>
.panels {
  width: calc(100% - 2px);
  margin: 1px;
}
.panel-field {
  width: calc(100% - 40px);
  margin: 10px 0 0 20px;
}
</style>