import { render, staticRenderFns } from "./RightAxisElementDetails.vue?vue&type=template&id=e2fbe2ec&scoped=true&lang=pug&"
import script from "./RightAxisElementDetails.vue?vue&type=script&lang=js&"
export * from "./RightAxisElementDetails.vue?vue&type=script&lang=js&"
import style0 from "./RightAxisElementDetails.vue?vue&type=style&index=0&id=e2fbe2ec&prod&lang=sass&"
import style1 from "./RightAxisElementDetails.vue?vue&type=style&index=1&id=e2fbe2ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2fbe2ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VCol,VHover,VIcon,VProgressLinear,VRow,VTreeview})
