import { defineStore } from 'pinia'
import { fetchGroupElementTree } from '@/providers/data/groups.dataProvider'
import { AxisUtils } from '@/assets/model/axis'

export const useGroupsElementStore = defineStore('groups-element', {
  state: () => ({
    groupElements: {},
    elementTrees: {}
  }),

  getters: {
    topLevelElements: (state) => (groupUuid) => { 
      return state.elementTrees[groupUuid].map(item => (
        {
          name: item.name,
          classTitle: item.classTitle,
          uuid: item.uuid
        }
      ))
    },

    enpoints: (state) => (groupUuid, key) => {
      let tree = getSubtree(state.elementTrees[groupUuid], key) ?? []
      let endpoints = tree.reduce(AxisUtils.onlyEndpointsReducer, [])
      return endpoints
    },
  },

  actions: {
    /**
     * Подгрузка элементов группы - при первоначальной загрузке, при обновлении и при добавлении новой группы
     * @param {String} groupUuid 
     */
    async prepareGroupElements (groupUuid) {
      let tree = await fetchGroupElementTree(groupUuid)
      let onlyEndpoints = tree.reduce(AxisUtils.onlyEndpointsReducer, [])
      const elements = onlyEndpoints.map(item => item.uuid)

      this.elementTrees = { ...this.elementTrees, [groupUuid]: tree }
      this.groupElements = { ...this.groupElements, [groupUuid]: elements }
    },
    
    /**
     * Удаление элементов группы
     * @param {String} groupUuid 
     */
    async deleteGroupElements (groupUuid) {
      delete this.groupElements[groupUuid]
    },
  }
})


function getSubtree (tree, key) {
  return key && tree?.filter(element => {
    if (element.name == key) return element
  })
}