var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-section',{staticClass:"base-scroll-body mt-2",staticStyle:{"height":"calc(100% - 32px)","width":"100%","border-radius":"3px"}},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"tile":"","light":"","type":"list-item-avatar-two-line@3"}}),_c('v-skeleton-loader',{staticStyle:{"margin-left":"20px"},attrs:{"tile":"","light":"","type":"list-item-avatar-two-line@1"}}),_c('v-skeleton-loader',{staticStyle:{"margin-left":"40px"},attrs:{"tile":"","light":"","type":"list-item-avatar-two-line@2"}}),_c('v-skeleton-loader',{attrs:{"tile":"","light":"","type":"list-item-avatar-two-line@2"}}),_c('v-skeleton-loader',{staticStyle:{"margin-left":"20px"},attrs:{"tile":"","light":"","type":"list-item-avatar-two-line@3"}})],1):_vm._e(),(!_vm.loading)?_c('v-treeview',{attrs:{"items":_vm.cloudWebDavTree,"item-key":"fileid","activatable":"","light":"","hoverable":"","open-on-click":"","open":_vm.open,"active":_vm.active,"transition":"","load-children":_vm.openCloudTree,"search":_vm.search},on:{"update:open":function($event){_vm.open=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.type == 1)?_c('v-icon',[_vm._v(_vm._s(open ? 'mdi-folder-open' : 'mdi-folder'))]):_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(item)))])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"contextmenu":function($event){$event.stopPropagation();return _vm.onMenu(item,$event)}}},[_c('div',[_vm._v(_vm._s(item.name))]),(item.size > 0)?_c('div',{staticClass:"text-caption grey--text lighten-5--text"},[_c('div',{staticClass:"d-inline-flex"},[_vm._v(_vm._s(item.sizeText))]),(item.lastModify)?_c('div',{staticClass:"d-inline-flex ml-5"},[_vm._v(_vm._s(item.lastModify))]):_vm._e()]):_vm._e()])]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onMenu(item, $event)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2260452316),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}):_vm._e(),_c('toolbar-menu',{ref:"fileToolbar",attrs:{"menu":_vm.toolMenu,"fontSize":"10px","color":"#757575"}}),_c('app-dialog-confirm',{attrs:{"delete":""},on:{"confirm":_vm.deleteItem},model:{value:(_vm.dialog.confirm),callback:function ($$v) {_vm.$set(_vm.dialog, "confirm", $$v)},expression:"dialog.confirm"}},[_vm._v(" "+_vm._s(_vm.dialog.text)+" ")]),_c('app-dialog',{attrs:{"header":_vm.dialog.isFile ? _vm.$t('section.journal.addFile'):_vm.$t('section.journal.addFolder'),"confirmText":_vm.$t('button.add'),"confirmDisabled":_vm.disabled},on:{"confirm":_vm.createItem},model:{value:(_vm.dialog.create),callback:function ($$v) {_vm.$set(_vm.dialog, "create", $$v)},expression:"dialog.create"}},[_c('app-section',{staticClass:"d-flex flex-column"},[_c('v-form',{ref:"form"},[(_vm.dialog.isFile)?_c('div',[_c('app-file-input',{attrs:{"formats":[],"maxSize":1.5e7},model:{value:(_vm.dialog.file),callback:function ($$v) {_vm.$set(_vm.dialog, "file", $$v)},expression:"dialog.file"}})],1):_c('div',[_c('app-text-field',{attrs:{"label":_vm.$t('template.dialog.folderName'),"maxlength":50,"required":"","rules":[_vm.rules.required, _vm.rules.text]},model:{value:(_vm.dialog.folderName),callback:function ($$v) {_vm.$set(_vm.dialog, "folderName", $$v)},expression:"dialog.folderName"}})],1)])],1)],1),(_vm.dialog.sign)?_c('file-sign',{attrs:{"item":_vm.item,"isCheck":_vm.dialog.isCheck,"isParallelSign":_vm.dialog.isParallelSign},on:{"close":_vm.signDialogClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }