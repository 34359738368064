<template lang="pug"> 
app-panel.pt-1.format(v-if="!editTemplate" style="width:520px; " ident="templates" left  )
  task-header.mb-2(:title="$t('template.title')" headerItemsCenter mainTitle )
    template( v-slot:headerAfter )
      div.search-input-container()
        input.search-input(v-model.trim="searchText" @input="searchTemplate")
        v-icon.magnify mdi-magnify
      app-icon-button( icon="mdi-close" @click="handleClose" )
  template-folders(@templateSelect="openEditTemplate")  
  
//- editor
app-panel.format.ml-1.pt-1(v-else style="width:823px" padding="4px")
  task-header.mb-2(:title="selectedTemplate.name" headerItemsCenter bigTitle )
    template( v-slot:headerBefore )
      app-icon-button.mr-2.ml-1( icon="mdi-arrow-left" @click="editTemplate = false" )

  app-section( style="height: calc(100% - 32px); width: 100%; border-radius: 3px; padding: 10px;" )
    template-editor( ref="templateEditor" @clickSave="saveContent")
</template>

<script>
import { mapMutations, mapState,mapActions } from 'vuex'
import TaskHeader from '@/components/task/TaskHeader'
import TemplateUnit from './TemplateUnit'
import ToolbarMenu from '@/components/project/panel/left/floors/ToolbarMenu'
import TemplateEditor from './TemplateEditor'
import HeaderText from '/src/pages/admin/forms/HeaderText'
import { AlertService } from '@app/AlertService'
import { templateService } from '@/_services'
import templateFolders from './TemplateFolders'

export default {
  name: "Templates",

  components: {
    TaskHeader,
    TemplateUnit,
    ToolbarMenu,
    TemplateEditor,
    HeaderText,
    templateFolders

  },

  data(){
    return{
      editTemplate: false,
      selectedTemplate: null,
      templates:null,
      content: "",
      hoverElement:"",
      searchText:"",
    }
  },

  mounted(){
    this.INIT(this.project.uuid)
  },

  computed: {
    ...mapState('project', ['project']),

    templateMenu() {
      return [
        { title: this.$t('button.edit'), icon: "/img/project/tools/edit-nofill.svg", action:()=>this.openEditTemplate(this.hoverElement)},
        { title: this.$t('button.delete'), icon: "/img/project/tools/delete_fill.svg",action:()=>this.deleteTemplate(this.hoverElement.uuid)},
      ];
    },
  },

  methods:{
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapMutations('template', ['setContent','setProjectUuid','setSearchText','updatePreview']), 
    ...mapActions('template', ['INIT']),
    ...mapActions('project', ['postPanelsStatus']),

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },

    openEditTemplate( template ) {
      templateService.getTemplateContent(template.uuid).then((data)=>{
        this.setContent( data )
        if(this.editTemplate)
          this.$refs.templateEditor.updateContent()
        this.selectedTemplate = template
        this.editTemplate = true 
      }).catch(e => {
        e.response.data.error_description = this.$t('template.error.openTemplate')
        AlertService.error(e.response)
      })
    },

    searchTemplate(){
      this.setSearchText(this.searchText)
    },

    saveContent(){
      templateService.editTemplate( this.selectedTemplate.uuid,
      { content:this.$refs.templateEditor.getContent()} )
        .then(()=> {
          AlertService.success({info:"Сохранено"})
          this.updatePreview(this.selectedTemplate.uuid)
          //TODO: при изменении шаблона время не меняется, необходимо обновлять все шаблоны
        })
        .catch( e => {
          e.response.data.error_description = this.$t('template.error.saveTemplate')
          AlertService.error(e.response)
      })
    },
  }
}

</script>
<style scoped>
.format {
  height: 100% !important;
}

.search-input {
  background-color: var(--v-surface-base) !important;
  color: var(--v-surface-lighten3);
  border: 1px solid var(--v-primary-darken2);
  outline: none;
  width: 200px;
  border-radius: 3px;
  padding: 0px 32px 0px 6px;
}
.search-input-container {
  position: relative;
  right: 10px;
}

.magnify {
  position: absolute;
  right: 5px;
  color: var(--v-surface-lighten3);
}
</style>
