import { defineStore } from 'pinia'
import { isGroupsPrepared } from '@/facades/groups.facade'
import { GroupsPalettesAdapter } from '@/adapters/groupsPalettes.adapters'

const { allGroupsWithPalletes } = GroupsPalettesAdapter

export const useGroupsListState = defineStore('group-palette-groups-list-state', {
  state: () => ({
    filter: null,
  }),

  getters: {
    groupsList() {
      const regexp = new RegExp(`${this.filter}`, 'i')
      if (this.filter) return allGroupsWithPalletes().filter((item) => regexp.test(item.title))
      return allGroupsWithPalletes()
    },

    isGroupsPrepared() {
      return isGroupsPrepared()
    }
  },
})
