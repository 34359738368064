<template lang='pug'>
div {{ fps }} FPS
</template>

<script>
import { stats } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'
export default {
  name: "FPSMeter",

  data: () => ({
    fps: 0,
  }),

  created() {
    this.frame = 0
    this.lastDate = Date.now()

    this.loop()
  },

  methods: {
    calcFPS() {
      this.frame++
      const currentDate = Date.now()
      const currentTime = currentDate - this.lastDate

      if (currentTime >= 50) {
        this.fps = Math.floor(this.frame / (currentTime / 1000))

        this.lastDate = Date.now()
        this.frame = 0
      }
    },

    loop() {
      window.requestAnimationFrame(() => {
        // this.calcFPS()
        this.fps = stats.frame.fps
        this.loop()
      })
    },
  },
}
</script>

<style scoped>

</style>
