import { render, staticRenderFns } from "./ChannelPanel.vue?vue&type=template&id=1eab4cba&scoped=true&lang=pug&"
import script from "./ChannelPanel.vue?vue&type=script&lang=js&"
export * from "./ChannelPanel.vue?vue&type=script&lang=js&"
import style1 from "./ChannelPanel.vue?vue&type=style&index=1&id=1eab4cba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eab4cba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
