<template lang="pug">
AppTooltip( title="Открыть список комментариев" bottom )
  VMenu( v-if='drawingsStore.selectedDrawingPage' bottom nudge-bottom='25'
         :min-width='340' :max-width='340' :max-height='360'
         :close-on-content-click="false"
         content-class="menu-scroll")
    template(v-slot:activator='{on, attrs}')
      AppIconButton( v-bind='attrs' v-on='on' icon="mdi-text" iconColor="#4D4D4D" :disabled='!drawingsStore.selectedDrawingPage.attachments.length > 0' )
    DrawingsCommentsPanel
</template>

<script>
import DrawingsCommentsPanel from '../../comments/DrawingsCommentsPanel.vue';
import { useDrawingsStore } from '@/pinia';

export default {
  name: 'DrawingOpenCommentList',

  components: {
    DrawingsCommentsPanel
  },

  computed: {
    drawingsStore: () => useDrawingsStore(),
  }
}
</script>