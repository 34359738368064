<template lang="pug">
div
  DrawingCommentsLayout
  DrawingImportInfo( v-if='store.commentImportMode' )

  DrawingPageCommentCreateDialog( v-if='store.contextMenuPosition'
                                      ref='comment-create-dialog' 
                                      id="drawingCommentCreateDialog"
                                      :coords='store.contextMenuPosition.originalCoords' )
  DrawingEditCommentDialog
</template>

<script>
// import DrawingCommentsControl from './DrawingCommentsControl.vue'
import DrawingCommentsLayout from './DrawingCommentsLayout.vue'
import DrawingImportInfo from './DrawingImportInfo.vue'
import DrawingPageCommentCreateDialog from './DrawingPageCommentCreateDialog.vue'
import DrawingEditCommentDialog from './DrawingEditCommentDialog.vue'

import { useDrawingsStore } from '@/pinia'

export default {
  name: 'DrawingComments',

  components: {
    // DrawingCommentsControl,
    DrawingCommentsLayout,
    DrawingImportInfo, 
    DrawingPageCommentCreateDialog, 
    DrawingEditCommentDialog,
  },

  computed: {
    store: () => useDrawingsStore(),
  }
}
</script>