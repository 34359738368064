<template lang="pug">
  div( :class="pluginConsole ? 'element-details-hidden' : 'element-details'" )
    app-section( v-if="activeGlobalTab == 'iot' && linked!=null" )
      app-text {{ $t('section.iot.deviceLinked') }} 
        strong {{linked.name}} ({{linked.title}})

    details-position( ref = "detailsPosition" :element="element" )

    plugin-info-header( v-if="isPlugin" :menu="mode" @clear="$emit('clearPluginConsole')" @update="$emit('updatePluginConsole')" )
    details-section-header.head-panel-row( v-else :menu="mode" )

    .element-details-expand( v-if="selectedElementFilter==1 || selectedElementFilter==6" )
      v-btn.dict-button( v-if="elements.length" color="accent" dense text x-small @click="toggleAll" test-id="element-detail-collapse-expand" ) 
        v-icon.mr-1( color="accent" size="16" left ) unfold_more
        | {{ expandElementDetails ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}
      //- v-snackbar.snackbar-attrcopy(v-model="codeSnack" color="success" timeout="500" bottom rounded absolute ) Атрибут скопирован
    .element-details-section.base-scroll-body(id="scrollTo")
      v-treeview.__project_view_element(
        ref="elementDetailTree"
        v-if="this.selectedElementFilter!=5 && this.selectedElementFilter!=9"
        expand-icon="mdi-menu-up"
        :items="elements"
        open-on-click
        :open-all="expanded"
        :open.sync="visibleArray"
        item-key="uuid" 
        return-object
        light 
        dense
        :key="propertyMenuKey"
        @update:open="onOpen"
      )
        template( v-slot:label="{ item }")
          v-row.tree-row-panel.pr-1(no-gutters @contextmenu.prevent="copy(item)" test-id="details-row"  @click="showCreateProjectAxis(item)")
            v-col.text-node-right.pr-3.colwidth(:title="$t(item.name)" :class="isShort" test-id="details-name-col" ) 
              .d-flex
                div.short-text {{ item.name || $t(itemValue(item )) }}
                app-icon-button( v-if="(item.sourceType && item.sourceType.title) === 'MANUAL'" icon="$admin-icon" iconSize="14px" )
                app-icon-button( v-if="(item.sourceType && item.sourceType.title) === 'CLASSIFICATOR'" icon="$attribute-icon" iconSize="14px" )
            v-col.text-left.colwidth.brd.pl-2(v-if="item.name && item.stringValue != null && !item.fillwidth" :title="$t(itemValue(item ))" :class="isShort" test-id="details-value-col") 
              a(v-if="item.unit.name == 'LINKUNIT'" target="_blank" :href="itemValue(item )") {{ $t(itemValue(item ))}} 
              span(v-else) {{ $t(itemValue(item ))}} 
            v-col.text-left.short-text.colwidth.brd.pl-2(v-if="item.children && item.children.length == 0 && item.stringValue == null && !item.fillwidth" :title="''" ) 

      v-treeview.__project_view_element(
        v-if="this.selectedElementFilter==5"
        :items="elements"
        item-key="uuid" 
        light 
        dense)
        template( v-slot:label="{ item }")
          v-hover( #default="{ hover }" )
            v-row.pl-2.pr-1.py-1.long-text(no-gutters style='height:28px;')
              v-col.pointer.text-truncate( @click="downloadElementFile(item)" ) {{ item.name }}  
              v-col.text-left(v-if="item.stringValue") {{$t(itemValue(item)) }}
              v-btn.pb-1.file-use-btn(v-if="hover" icon @click="downloadElementFile(item)")
                v-icon() file_download
              v-btn.pb-1.file-use-btn(v-if="hover" icon @click="confirmDeleteElementFile(item)")
                v-icon() delete
              app-dialog-confirm(v-model="dialog.delElementFile" ref="delElementFile" @confirm="deleteElementFile(selectedElementFile.uuid)")
                app-text {{ $t('section.structure.model.deleteFileConfirm') }} "{{ selectedElementFile.name }}"?
      input(id="fileUpload" @change="onFileSelected" type="file" hidden)
      v-progress-linear(v-if="stateFileDownload" indeterminate color="var(--v-surface-lighten3)")
      v-btn.file-add-btn(v-if="this.selectedElementFilter == 5" small icon color="accent" @click="chooseFiles()" test-id="details-add-file")
        v-icon() mdi-plus
      
      element-rules(v-if="selectedElementFilter === 9" :element='element')

    link-device(v-if="activeGlobalTab == 'iot'" v-model="dialogLinkDevice" :element="element.globalId" :device="linked" @created="afterLinkDevice")

    right-axis-grouping-dialog( v-model="dialog.createAxisData" :group.sync="axisData" @edited="showCreateAxisData(false)")

</template>

<script>
import LinkDevice from "@/components/project/panel/left/components/iot/LinkDevice.vue";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import DetailsPosition from "@/components/project/panel/left/components/position/DetailsPosition.vue";
import i18n from "@/plugins/i18n";
import { NotificationService } from "@/assets/model/notifications/NotificationService";
import { api } from "@/api";
import { SourcePath } from '@app/SourcePath'
import PluginInfoHeader from '@/components/project/panel/left/plugin/PluginConsoleHeader.vue';
import RightAxisGroupingDialog from './RightAxisGroupingDialog.vue'
import DetailsSectionHeader from '@/components/project/common/DetailsSectionHeader.vue';
import ElementRules from '../../left/components/element/ElementRules.vue'

const trunc = (val) => {
  return Math.trunc ? Math.trunc(val) : Math.sign(val) * Math.floor(Math.abs(val))
};

const truncated = (num, decimalPlaces) => {
  let numPowerConverter = Math.pow(10, decimalPlaces);
  return trunc(num * numPowerConverter) / numPowerConverter
};

const findByName = (array, name, tail = []) => {
  for (const obj of array) {
    if (obj.name === name) {
      tail.push(obj);
      return tail;
    }
    if (obj.children) {
      const childrenTail = findByName(
        obj.children,
        name,
        [...tail].concat(obj)
      );
      if (childrenTail.length) return childrenTail;
    }
  }
  return [];
};

export default {
  name: "Details",
  components: { LinkDevice, DetailsPosition, PluginInfoHeader, RightAxisGroupingDialog, DetailsSectionHeader, ElementRules },
  data() {
    return {
      expanded: false,
      stateFileDownload: false,
      propertyMenuKey: 0,
      visibleArray: [],
      counter: 0,
      dialogLinkDevice: false,
      savePositionDialog: false,
      elementPick: false,
      elementPickMoreOne: false,
      device: null,
      dialog: {
        delElementFile: false,
        createAxisData: false
      },
      selectedElementFile: {
        uuid: null,
        name: null,
      },
      copyAttributeSnackBar: false,
      collisionDetails: [],
      axisData: null,
      pathToCoipiedElement: null,

      elementRules: []
    };
  },
  props: {
    element: null,
    isPlugin: {
      type: Boolean, 
      default: false,
    },
  },
  watch: {
    element(value) {
      if (value) { 
        this.infoLoad()
        this.stateFileDownload = false
        this.setExpandElementDetails(false)
        this.visibleArray = this.compareGroups(this.elements)

        this.elementRules = []
      }
    },
    visibleArray(){
      const elmnt = document.getElementById('scrollTo')
      let y = elmnt.scrollTop
      this.$nextTick().then(() => {
        elmnt.scrollTop = y
      })
    },

    copiedElementAttribute(val) {
      if (val) this.copyAttributeSnackBar = true;
      else this.copyAttributeSnackBar = false;
    },

    selectedElementFilter() {
      if(this.selectedElementFilter == 1) {
        this.visibleArray = this.getVisibleArray()
        this.propertyMenuKey++
      }
    }
  },
  computed: {
    ...mapState("project", ["selectedElementFilter", "activeGlobalTab", "project", "allOpenNames"]),
    ...mapState("selected", ["expandElementDetails"]),
    ...mapState("position", ["dataPositions"]),
    ...mapState("copyutil", ["copiedElementAttribute"]),
    ...mapGetters("iot", ["devices"]),
    ...mapState('collision/search', ['collisionSelectedGlobalIds']),
    ...mapState('plugins', ['pluginConsole']),
    ...mapGetters('rule/details', ['finders']),
    ...mapGetters('right-axis', ['editedProjectAxis']),
    ...mapGetters('project', ['axisEnum', 'conditionOperator']),
    ...mapGetters('floatingPanels', ['getVisibleArray']),


    isShort() {
      return this.element.elementClassName && this.element.elementClassName == 'IfcProject' ? '' : 'short-text'
    },

    darkItem() {
      return "background-color: #000"
    },
    infoElement(item) {
      var res = this.itemValue(item).split(" ");
      var locale = localStorage.getItem("locale");
      i18n.t(NotificationService.NotificationReplace(res[-1]), locale);
      return res[0] + " " + res[-1];
    },
    elementMenu() {
      let act = [];
      if (this.activeGlobalTab == "iot") {
        if (this.linked == null)
          act.push({
            title: this.$t("section.iot.elementMenu.linkDevice"),
            action: this.linkDevice,
          });
        else {
          act.push({
            title: this.$t("section.iot.elementMenu.editDevice"),
            action: this.linkDevice,
          });
          act.push({
            title: this.$t("section.iot.elementMenu.untieDevice"),
            action: this.unlinkDevice,
          });
        }
        act.push({
          title: this.$t("section.iot.elementMenu.newPosition"),
          action: this.savePosition,
        });
      } else
        act.push({
          title: this.$t("section.iot.elementMenu.action"),
          action: this.dev,
        });

        if (this.activeGlobalTab == "worm") {
          act.push({
            title: this.$t("section.iot.elementMenu.showWORM"),
            action: this.showInWorm,
          });
        }

      return act;
    },

    mode() {
      if (!this.selectedElementFilter) this.setSelectedElementFilter(1);
      let tabs = []
      if(this.isPlugin) {
        tabs.push({
          title: this.$t("plugin.console"), 
          action: () => this.filterElement(8),
          active: this.selectedElementFilter == 8
        })
      }
      if(this.element) {
        tabs.push(
        {
          title: this.$t("section.elementTree.mode.features"),
          action: () => this.filterElement(1),
          active: this.selectedElementFilter == 1,
        },
        {
          title: this.$t("section.elementTree.mode.located"),
          action: () => this.filterElement(2),
          active: this.selectedElementFilter == 2,
        },
        {
          title: this.$t("section.elementTree.mode.classification"),
          action: () => this.filterElement(6),
          active: this.selectedElementFilter == 6,
        },
        {
          title: this.$t("section.elementTree.mode.files"),
          action: () => this.filterElement(5),
          active: this.selectedElementFilter == 5,
        },
        {
          title: this.$t("section.elementTree.mode.smetaRules"),
          action: () => this.filterElement(9),
          active: this.selectedElementFilter == 9,
        },
        )
      }

      if (this.activeGlobalTab == "collision")
        tabs.push(        {
          title: this.$t("section.collision.title"),
          action: () => this.filterElement(7),
          active: this.selectedElementFilter == 7,
        });
    
      return tabs;
    },
    elementName() {
      return this.element != null
        ? this.$tc("section.elementTree.element", 1, {
            name: this.element.name,
          })
        : this.$tc("section.elementTree.element", 0);
    },
    authorNameLetter() {
      return this.element != null && this.element.author
        ? this.element.author.name.substring(0, 1)
        : "";
    },
    elements() {
      let res = [];
      switch (this.selectedElementFilter) {
        case 1: {
          this.setPluginConsole(false)
          res = this.element?.details.map(item => ({
            ...item,
            children: item.children.toSorted((a, b) => a.children > b.children)
          })).filter( (item) => {
            if (item.children.length > 0 ) {
              // item.children.sort((a,b) => a.children > b.children).map(item => { // infinite render loop
              item.children.map(item => {
                item.children.map(child => {
                  if (child.name && child.name.indexOf('[') != -1 && child.name.indexOf(']') != -1) {
                    child.name = null
                  }
                  return child
                })

                
                return item
              })
              return item
            } 
            else if ( item.children.length == 0 && (item.stringValue || item.stringValue == null) ) {
              return item
            }
          }
              
          );
    
          if(this.element && this.element.layerName && this.element.layerName != '') {
            res.unshift({
              name: this.$t("section.structure.layer.header"),
              stringValue: this.element.layerName,
              operand: "LAYER",
              uuid: this.element.layerUuid,
              topLevel: true,
              unit: "",
            });
          }
          
          if (this.element?.floorName) {
            res.unshift({
              name: this.$t('section.elementTree.condition.FLOOR'),
              stringValue: this.element.floorName,
              operand: "FLOOR",
              uuid: "FLOOR",
              topLevel: true,
              unit: "",
            });
          }
          if (this.element?.groupName) {
            res.unshift({
              name: this.$t('section.elementTree.condition.SYSTEMS'),
              stringValue: this.element.groupName,
              operand: "SYSTEMS",
              uuid: "SYSTEM",
              topLevel: true,
              unit: "",
            });
          }
          
          res.unshift({
            name: this.$t("section.structure.model.header"),
            stringValue: this.element.modelName,
            uuid: this.element.model,
            operand: "MODEL",
            topLevel: true,
            unit: "",
          });
          res.unshift({
            name: this.$t("section.elementTree.IdElement"),
            stringValue: this.element.uuid,
            operand: "IDPROP",
            uuid: "a1a6881f-4698-40dc-baae-54ad1c98c102",
            topLevel: true,
            unit: "",
          });
          res.unshift({
            name: this.$t("section.elementTree.IdIFC"),
            stringValue: this.element.ifcGlobalId??this.element.globalId,
            operand: "IDPROP",
            uuid: "a1a6881f-4698-40dc-baae-54ad1c98c103",
            topLevel: true,
            unit: "",
          });
          res.unshift({
            name: this.$t("section.elementTree.IFCType"),
            stringValue: this.element.elementClassName,
            uuid: this.element.elementClass,
            operand: "CLASS",
            topLevel: true,
            unit: "",
          });
          res.unshift({
            name: this.$tc("section.elementTree.element", 0),
            stringValue: this.element.name,
            operand: "IDPROP",
            uuid: "a1a6881f-4698-40dc-baae-54ad1c98c101",
            topLevel: true,
            unit: "",
          });

          if(this.element.elementHistory && this.element.elementHistory.length > 0){
            let h_item = {"name": this.$t("section.iot.elementMenu.elementHistory"), unit: "", children: []};
            this.element.elementHistory.forEach((h) => {
              if (h.modelRevision.modelUuid) {
                h_item.children.push({notCreateGroup: true, name: this.$t('section.iot.elementMenu.revision', {version: h.modelRevision.version}) + h.modelRevision.revisionDate, unit: "", stringValue: h.status.title}) 
              } else {
                h_item.children.push({notCreateGroup: true, name: this.$t('section.iot.elementMenu.replacement', {name: h.modelRevision.name}) + h.modelRevision.revisionDate, unit: "", stringValue: h.status.title})
              }
            })
            res.push(h_item);
          }

          if(this.element.elementTaskHistory && this.element.elementTaskHistory.length > 0){
            let h_item = {"name": this.$t("section.iot.elementMenu.itemStatusHistory"), unit: "", children: []};
            this.element.elementTaskHistory.forEach((h) => 
              h_item.children.push({"name": h.statusDate, unit: "", stringValue: h.status.title})
            )
            res.push(h_item);
          }
         
          break;
        }
        case 2:
          this.setPluginConsole(false)
          res = this.element.geometry;
          break;
        case 3:
          this.setPluginConsole(false)
          res = this.element.details.filter(
            (item) =>
              item.children.length > 0 &&
              item.name.indexOf("Ifc") != 0 &&
              item.name.indexOf("Pset") == 0
          );
          break;
        case 4:
          this.setPluginConsole(false)
          res = this.element.details.filter(
            (item) => item.children.length > 0 && item.name.indexOf("Ifc") == 0
          );
          break;
        case 5:
          this.setPluginConsole(false)
          if(this.element) {
            res = this.element.file
          }
          break;
        case 6:
          this.setPluginConsole(false)
          res = []
          if (this.element.classification) {
            res = this.element.classification.map(item => {
              let children = [];
              if (item.link) {
                children.push({ name: this.$t('section.iot.elementMenu.source'), unit: "", stringValue: item.link });
              }
              if (item.edition) {
                children.push({ name: this.$t('section.iot.elementMenu.publication'), unit: "", stringValue: item.edition });
              }
              if (item.editionDate) {
                children.push({ name: this.$t('section.iot.elementMenu.batePublication'), unit: "", stringValue: item.editionDate });
              }
              if (item.references) {
                item.references.forEach(reference => {
                  children.push({
                    name: `${this.$t('section.iot.elementMenu.meaning')} - ${reference.identification} ${reference.name}`,
                    unit: "",
                    children: [
                      {
                        name: this.$t('section.iot.elementMenu.identifier'),
                        unit: "",
                        stringValue: reference.identification
                      },
                      {
                        name: this.$t('section.elementTree.name'),
                        unit: "",
                        stringValue: reference.name
                      }
                    ]
                  });
                });
              }
              return {
                name: item.name,
                unit: "",
                children
              };
            });
          }
          break;
        case 7: {
          this.setPluginConsole(false)
          res = this.collisionDetails;
          break;
        }
        case 8: {
          this.setPluginConsole(true)
          break
        }
        default:
          res = this.element.details;
          this.setPluginConsole(false)
      }
      

      res ||= []
      
      /**
       * [ERROR] You may have an infinite update loop in a component render function
       * Нужно переделать, т.к res в итоге является ссылкой на массив из element - 
       * изменения в res вызывают обновление DOM
       */ 

      // ----- ВЛОЖЕННЫЕ ЭЛЕМЕНТЫ
      const NESTED_ELEMENTS = {
        name: this.$t("section.elementTree.nestedElements"),
        children: res.filter(item => item.uuid === null).map(item => ({...item, uuid: item.name, children: item.children.map(child => ({...child, operand: "MATERIALS", name: '', stringValue: child.name, fillwidth: true }))}))
      }
      res = res.filter(item => item.uuid !== null)
      NESTED_ELEMENTS.children.length && res.push(NESTED_ELEMENTS)
      // ----- ВЛОЖЕННЫЕ ЭЛЕМЕНТЫ
      
      return res;
    },
    copyColor() {
      return this.$vuetify.theme.themes.dark.primary.lighten3;
    },
    linked() {
      let device = null;
      if (this.activeGlobalTab == "iot") {
        device = this.devices.find((d) => d.element === this.element.globalId);
        if (device === undefined) return null;
      }
      return device;
    },
  },

  beforeMount() {
    if(this.isPlugin) this.setSelectedElementFilter(8)
  },

  mounted() {
    this.infoLoad()
    this.visibleArray = this.getVisibleArray()
    this.stateFileDownload = false
  },

  updated() {
    this.setArray(this.visibleArray)
  },

  beforeDestroy() {
    if(this.isPlugin) this.setSelectedElementFilter(1)
  },

  methods: {
    ...mapMutations('condition', ['setSearch', 'setManualOperand', 'setValue', 'setForm']),
    ...mapMutations("project", ["setSelectedElementFilter", "setOpenNames", "clearOpenNames"]),
    ...mapMutations("selected", ["setExpandElementDetails"]),
    ...mapMutations("plugins", ['setPluginConsole']),
    ...mapActions("position", ["getDataPositions"]),
    ...mapMutations("copyutil", ["setCopiedElementAttribute"]),
    ...mapActions('right-axis/tree', ['fetchElementByGlobalId', 'fetchElementByGlobalIdWithoutConditions']),
    ...mapMutations("floatingPanels", ["setVisibleArray"]),
    ...mapMutations("projectDict", ["setCopyMatchingAttributes"]),

    compareGroups(groups) {
      let result = []
      groups.forEach(group => {
        if(group.name && this.visibleArray.find(el => el.name === group.name)){
          result.push(group)
        }
        if(group.children && group.children.length > 0) {
          result = result.concat(this.compareGroups(group.children))
        }
      })
      return result
    },

    setArray(visibleArray) {
      this.setVisibleArray(visibleArray)
    },
    filterElement(filter) {
      this.setSelectedElementFilter(filter);
    },
    itemValue(item, withUnit = true) {
      let val = item.stringValue;
      let dig = item.doubleValue;
      let unit = this.unitName(item.unit);
      let unit_locale = "";
      var locale = localStorage.getItem("locale")

      if (item.power || (item.sourceType && item.sourceType.title !== "IFC")) {
        unit_locale = this.unitNameManual(item.unit, item.power)
      }
      else if(unit.includes("{") || unit.includes("}")) {
        let unitArray = unit.split("}{");
        if (unitArray.length > 1) {
          unit_locale = i18n.t(NotificationService.NotificationReplace(unitArray[0]), locale) + i18n.t(NotificationService.NotificationReplace("{"+unitArray[1]), locale)
        } else {
          unit_locale = i18n.t(NotificationService.NotificationReplace(unitArray[0]), locale)
        }
      }
      else {
        unit_locale = unit
      }
      val = this.truncatedUnit(unit, val, dig);
      return withUnit ? val + " " + unit_locale : "" + val;
    },
    truncatedUnit(unit, val, dig) {
      let value = val;
      if(unit.indexOf("square") != -1) return truncated(dig, 4);
      if(unit.indexOf("cub") != -1) return truncated(dig, 6);
      if(unit.indexOf("milli") != -1) return dig.toFixed();
      if(unit.indexOf("metre") != -1) return truncated(dig, 3);
      return value;
    },

    unitName(unit) {
      if (typeof unit == "string") return unit;
      let u = this.element.units.find((u) => u.unit.value == unit.value);
      if (u !== undefined ) {
        if (u.unitName) return u.unitName 
        else return u.shortName;
      }
      else return "";
    },

    unitNameManual(unit, power) {
      let powerShortName = power && power.prefixName ? this.$t(`notify.power.shortName.${power.prefixName}`) : ''
      let unitShortName = unit && unit.unitName ? this.$t(`notify.unit.shortName.${unit.unitName}`) : ''
      return powerShortName + unitShortName
    },

    showCreateProjectAxis(item) {
      if(item.unit?.name != 'LINKUNIT' && item.stringValue != null && !item.notCreateGroup && (this.selectedElementFilter == 1 || this.selectedElementFilter == 2)) {
        this.axisData = this.editedProjectAxis
        this.fillCopyData(this.axisData, item)
        this.dialog.createAxisData = true
      }
    },

    showCreateAxisData(show) {
      this.dialog.createAxisData = show
    },

    fillCopyData(data, item) {
      let topLevel = item.topLevel ? item.topLevel : false
      let cond = data.logicGroup.condition[0]
      let parent = this.elements.find(e => e.uuid == item.parent)
      let title = parent && !topLevel ? parent.name + "/" + item.name : item.name
      let operand = item.operand ? item.operand : 'USERPROP'

      if( operand !== "USERPROP" ) {
        cond.title = "'" + title + "'" + this.$t('section.iot.elementMenu.equally') + this.itemValue(item, false)
      } else {
        cond.title = "'" + this.elements[1].stringValue + "/" + title + "'" + this.$t('section.iot.elementMenu.equally') + this.itemValue(item, false)
      }
      cond.operandUuid = topLevel ? item.uuid : item.elementClass
      cond.value = this.itemValue(item, false)
      cond.operand = this.axisEnum.find(e => e.name == operand)
      cond.operator = this.conditionOperator.find(e => e.name == 'EQ')

      cond.manualOperand = operand == 'USERPROP' ? title : operand.indexOf('PROP') == -1 ? item.stringValue : null
    },

    copy(item) {
      this.setCopiedElementAttribute(item);
      
      let text =
      item.stringValue != null
      ? (item.name ? item.name + ": " : "") + this.itemValue(item)
      : item.name;
       
      if (navigator.clipboard != null)
      navigator.clipboard.writeText(text)
    
      let topLevel = item.topLevel ? item.topLevel : false
      let parent = this.elements.find(e => e.uuid == item.parent)
      let title = parent && !topLevel ? parent.name + "/" + item.name : item.name 

      item.title = "'" + this.elements[1].stringValue + "/" + title + "'" + this.$t('section.iot.elementMenu.equally') + this.itemValue(item, false)
      item.stringValue = this.itemValue(item, false);
      
      item.parentTitle = this.elements[1].stringValue

      this.recursionPickedElement( this.elements, item )
    },

    takeValueType() {
      for( let i = 0; i < this.elements.length; i++ ) {
        if( ( this.elements[i].name ==  this.$t('section.elementTree.IFCType') ) || ( this.elements[i].name == this.$t('section.elementTree.condition.CLASS') ) ) {
          return this.elements[i].stringValue
        }
      }
    },

    recursionPickedElement( objArray, item, currentPath = [] ) {
      for ( let i = 0; i < objArray.length; i++ ) {
        const newPath = [...currentPath, objArray[i].name];
        if ( ( objArray[i].name == item.name ) && ( objArray[i].stringValue == item.stringValue ) ) {
          this.pathToCoipiedElement = newPath.join('/') + '/' + objArray[i].stringValue
          this.setManualOperand( this.takeValueType() + '/' + newPath.join('/') )
          this.setValue( objArray[i].stringValue + this.unitName(objArray[i].unit) )
          return
        }

        if (objArray[i]?.children?.length) {
          this.recursionPickedElement(objArray[i].children, item, newPath);
        }
      }
    },

    linkDevice() {
      this.dialogLinkDevice = true;
    },
    unlinkDevice() {
      this.$store.dispatch("iot/unlinkDevice", this.linked.uuid);
    },
    checkPositions() {
      if (this.activeGlobalTab == "iot") {
        this.getDataPositions(this.element);
      }
    },
    savePosition() {
      this.$refs.detailsPosition.saverPosition();
    },

    afterLinkDevice() {
      this.dialogLinkDevice = false;
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },

    onFileSelected(event) {
      this.stateFileDownload = true
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return this.stateFileDownload = false;
      api.projects
        .uploadElementFile(this.project.uuid, this.element.globalId, files[0])
        .then(() => {
          this.$emit('loadElements')
          this.fetchElementByGlobalIdWithoutConditions(this.element.globalId)
        }).catch(() => {
          this.stateFileDownload = false
        });
    },

    downloadElementFile (file) {
      window.open(SourcePath.elementFile(file.uuid), '_blank')
    },

    deleteElementFile(elementFileUuid) {
      api.projects.deleteElementFile(elementFileUuid).then(() => {
        this.fetchElementByGlobalIdWithoutConditions(this.element.globalId);
      });
    },

    confirmDeleteElementFile(item) {
      this.selectedElementFile = item;
      this.dialog.delElementFile = true;
    },

    closeItem(all, part) {
      let items = []
      all.forEach((e) => {
        if(part.indexOf(e)==-1 && findByName(this.elements, e).length>0) 
            items.push(e)
      })
      return items
    },

    allItemChildren(el, allItem) {
      if(el && allItem.length > 0){
        let children = el.children.filter(el => el.children && el.children.length != 0)
        children.map(element => {
          allItem.push(element)
          if(element.children && element.children.length > 0){
            this.allItemChildren(element, allItem)
          }
        })
      }
      return allItem
    },

    onOpen(arr) {
      let names = {}
      let allItem = this.elements.filter(el => el.children && el.children.length != 0)
      names.open = arr.map((i) => i.name)
      names.close = this.closeItem(this.allOpenNames, names.open)
      this.setOpenNames(names);
      allItem.map(el => {
        if(el.children && el.children.length != 0){
          allItem.concat(this.allItemChildren(el, allItem))
        }
      })

      if(arr.length == allItem.length){
          this.expanded = true
          this.setExpandElementDetails(true) 
      } else {
          this.expanded = false
          this.setExpandElementDetails(false)
          this.clearOpenNames()
      }
    },

    fillCollisionDetails() {
      if( this.activeGlobalTab == "collision" && this.collisionSelectedGlobalIds.collisionUuid != null ) {
        this.collisionDetails = [];
        api.collisions.getDetails(this.collisionSelectedGlobalIds.collisionUuid).then( data => {
           data.forEach(c => {
             this.collisionDetails.push({"name": this.$t('section.iot.elementMenu.collisionFrom'), unit: "", stringValue: c.createDate})
             if( c.info ) this.collisionDetails.push({"name": this.$t('section.collision.table.rule'), unit: "", stringValue: c.info.ruleTitle})
             this.collisionDetails.push({"name": this.$t('section.iot.elementMenu.type'), unit: "", stringValue: c.finderRuleTitle})
             this.collisionDetails.push({"name": this.$t('section.structure.model.status'), unit: "", stringValue: c.status.title})
             if( c.finderRule == "AttributeClashRule" && c.atributes.length > 0 ){
               let atr_item = {"uuid": c.uuid, "name": this.$t('section.iot.elementMenu.invalidAttributes'), unit: "", children: []};
               c.atributes.forEach(a => {
                 let name = a.manual ? a.manualAtribute : a.atribute.path
                 atr_item.children.push({"name": name, unit: "", stringValue: this.$t('section.iot.elementMenu.expected') + "\"" + a.atributeType + "\""})
               })
               this.collisionDetails.push(atr_item)
             }
             if( c.finderRule == "IFCValidateRule" ){
               this.collisionDetails.push({"name": c.info.description, unit: "", stringValue: c.info.value})
             }
           })
        })
      }
    },

    toggleAll(){
      if(this.expandElementDetails) { 
        this.expanded = false
        this.setExpandElementDetails(false)
        this.clearOpenNames()
      } else {
        this.expanded = true
        this.setExpandElementDetails(true) 
      }
      this.$refs.elementDetailTree.updateAll(this.expandElementDetails)
      this.propertyMenuKey++
    },

    showInWorm(){
      this.$emit('showwormfromelement',this.element)
    },

    findRules(mode){
      console.log(this.element)
      console.log(mode)
      api.smeta.findElementRules(this.element.uuid,mode).then(data => {
        this.elementRules = data
      })
    },

    infoLoad() {
      this.checkPositions()
      this.fillCollisionDetails()
    },

  },
};
</script>

<style lang="sass">

.__project_view_element.v-treeview

  .v-treeview-node.v-treeview-node--click:not(.v-treeview-node--leaf)
    .v-treeview-node__children
      .v-treeview-node__root
        border-top: none

      .v-treeview-node--leaf
        .v-treeview-node__root
          border-top: 1px solid #e5e5e5 !important
          
    .v-treeview-node__children
      .v-treeview-node.v-treeview-node--click:not(.v-treeview-node--leaf)
        border-bottom: none !important

  .v-treeview-node.v-treeview-node--click:not(.v-treeview-node--leaf):last-child
    border-bottom: 1px solid #c4c4c4
  
  .v-treeview-node.v-treeview-node--click:not(.v-treeview-node--leaf)
    .v-treeview-node--leaf:last-of-type
      border-bottom: none !important

  .v-treeview-node__toggle
    margin:0

  .v-treeview-node__toggle--open::before
    transform: rotate(180deg) !important
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s

  .v-treeview-node__toggle::before
    transform: rotate(0deg)
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s

  .v-treeview-node__toggle
    height: 26px
    transform: rotate(180deg)

  .v-icon::after
    border-radius: 0%
    transform: scale(1)

  .v-treeview-node__toggle + div
    font-weight: bold

  .v-icon
    font-size: 16px
  
  .v-treeview-node
    font-weight: 500 

  .v-treeview-node--leaf
    border: none !important

  .v-treeview-node__root
    .v-treeview-node__level
      width: 0px

  .v-treeview-node--leaf:nth-of-type(odd)
    .v-treeview-node__content
      background: #F4F4F4 !important

  .v-treeview-node
    border-bottom: 1px solid #e5e5e5 !important

    .v-treeview-node
      font-weight: normal
      margin-left:16px

    .v-treeview-node__children
      margin-left:0
      border-bottom: 1px solid #e5e5e5 !important

      .v-treeview-node__content
        border-bottom: none !important
        
  .v-treeview-node__content
    margin: 0px

    .v-treeview-node__label
      white-space: normal
    
.snackbar-attrcopy
  margin: 0 auto

  .v-snack__wrapper
    max-width:180px
    min-width:180px
</style>


<style lang="scss" scoped>

.colwidth {
  // max-width: 70%;
  min-width: 10%;
}
.brd {
  border-left: 1px solid #bbb;
  
  
}
.author {
  width: 18px;
  // height: 16px;
  font-size: 10px;
  text-align: center;
  line-height: 18px;
}
.long-text {
  white-space: normal;
  overflow-wrap: break-word;
}

.short-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unit-action {
  position: absolute;
  right: 0px;
  top: 0px;
}
.file-use-btn {
  width: 24px;
  height: 24px;
}
.head-panel-row .pointer {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.element-details-expand {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #c4c4c4;
  background-color: #fff;
}
.element-details-section {
  padding: 0px 2px 0px 2px;
  background-color: #fff;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.element-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  // max-height: calc(100% - 50vh);
}
.element-details-hidden {
  display: flex;
  flex-direction: column;
}

.t-scroll-body {
  overflow-y: auto;
  height: 100%;
  // height: auto;
  // max-height: calc(100% - 85px);
  overflow-x: hidden;
  position: relative;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
}
.zebra:nth-child(odd) {
  background-color: #ccc;
}

.zebra::nth-child(even) {
  background-color: white;
}
.text-left {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #4D4D4D;
}
.text-node-right {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #757575;
}
.tree-row-panel {
  padding-left: 3px;
}

.dict-button {
  display: block;
  font: normal 10px/16px $roboto;
  text-transform: none;
  margin-top:2px;
  margin-bottom:2px;
}

</style>