<template lang="pug">
div
  app-section
    div.pb-2(style="border-bottom: 1px solid #e5e5e5;")
      .mb-2.filter-title
        p.mb-0 {{ $t('module.task.taskFilter') }}
      .mb-0
        v-btn(color="#3B93AF" class="filters-labels" @click="allTasks" x-small text) {{ $t('module.task.allTasks') }}
      .mb-0
        v-btn(color="#3B93AF" class="filters-labels" @click="tasksCreatedByMe" x-small text) {{ $t('module.task.createdMe') }}
      .mb-0
        v-btn(color="#3B93AF" class="filters-labels" @click="taskForMe" x-small text) {{ $t('module.task.taskForMe') }}
      .mb-0
        v-btn(color="#3B93AF" class="filters-labels" @click="sortDesTask" x-small text) {{ $t('module.task.sortDes') }}
      .mb-0
        v-btn(color="#3B93AF" class="filters-labels" @click="sortAscTask" x-small text) {{ $t('module.task.sortAsc') }}

    .my-2.pb-3(v-if="filterList.length > 0" style="border-bottom: 1px solid #e5e5e5;")
      //- app-section-header.mb-4( :title="$t('module.task.myFilters')" )
      .row.ma-0(v-for="(filter, index) in filterList" :key="index")
        .col.pa-0
          v-btn(class="filters-labels" color="#3B93AF" @click="setFilterShow(filter)" x-small text) {{ filter.name || $t('module.task.filter', {n: index}) }}
        .col-auto.pa-0
          .justify-end
            v-btn.px-0(color="grey" @click="clearFilter(filter)" x-small text)
              v-icon(x-small) clear

    .mb-0
      .row
        .col
          v-btn(class="filters-labels" :color="show.filter.menu?'#262626':'#3B93AF'" test-id="filter-new-btn" @click="showFilterMenu" x-small text) {{'+ '+$t('module.task.filterMenu.newFilter') }}
        .col-auto
          .justify-end
            v-btn.pl-0.pr-0( color="#6B6B6B" dense text x-small @click="showFilterMenu")
              v-icon(v-if="!show.filter.menu").pl-1 arrow_drop_down
              v-icon(v-if="show.filter.menu").pl-1 arrow_drop_up

    div.base-scroll-body.pa-2(v-if="show.filter.menu" style="margin-right: -7px; height: 400px; overflow-x: hidden;")
      app-text-field.mt-1(v-model="filter.text" :label="$t('module.task.containsPhrase')" clearable test-id="filter-phrase-input")
      app-select( v-model="filter.membership" :item-text="memberName" item-value="profile.uuid" :items="membershipOnlyProfile" :label="$t('module.task.membership')" multiple clearable  test-id="filter-member-input")
      app-select(v-if="isPluginLinked" v-model="filter.pluginStatuses" item-text="title" item-value="name" :items="pluginStatus" :label="$t('module.task.pluginStatuses')" multiple clearable )
      app-select(v-else v-model="filter.statuses" item-text="title" :items="translatedCompleteStatuses" :label="$t('module.task.statuses')" multiple clearable  test-id="filter-statuses-input")
      app-select( v-model="filter.priorities" item-text="title" :items="translatedPriorities" :label="$t('module.task.priorities')" multiple clearable  test-id="filter-priorities-input")
      app-select( v-model="filter.models" item-text="title" item-value="uuid" :items="flatlist" :label="$t('module.task.models')" multiple clearable test-id="filter-models-input")
      app-select( v-model="filter.creators" :item-text="memberName" item-value="profile.uuid" :items="membershipOnlyProfile" :label="$t('module.task.authors')" multiple clearable )


      v-menu(
        ref="menu1"  v-model="menu1"
        :close-on-content-click="false" :return-value.sync="filter.dateStart"
        transition="scale-transition" offset-y min-width="auto"
      )
        template(v-slot:activator="{ on }")
          v-text-field(
            v-model="filter.dateStart" :label="$t('module.task.dateFrom')" 
            prepend-icon="mdi-calendar" readonly
            v-on="on" light clearable
            test-id="filter-date-start-input"
          )
        v-date-picker( v-model="filter.dateStart" first-day-of-week=1 no-title scrollable @input="$refs.menu1.save(filter.dateStart + ' 00:00')")

      v-menu.py-0.my-0(
        ref="menu2"  v-model="menu2"
        :close-on-content-click="false" :return-value.sync="filter.dateEnd"
        transition="scale-transition" offset-y min-width="auto"
      )
        template(v-slot:activator="{ on }")
          v-text-field.py-0(
            v-model="filter.dateEnd" :label="$t('module.task.dateTo')" 
            prepend-icon="mdi-calendar" readonly
            v-on="on" light clearable
            test-id="filter-date-end-input"
          )
        v-date-picker( v-model="filter.dateEnd" first-day-of-week=1 no-title scrollable @input="$refs.menu2.save(filter.dateEnd + ' 23:59')")
      
      v-checkbox.mt-n2(v-model="filter.attachment" x-small light dense test-id="filter-attach-check")
        template(v-slot:label)
          span.text-caption.text-primary {{ $t('module.task.hasAttachment') }}

      //- v-divider.primary.my-4
      //- .row.mt-4.mb-2
      //-   .col-5.px-0
      div.d-flex.justify-space-between.mt-4
        .d-flex.align-center
          v-tooltip(bottom)
            template(v-slot:activator="{ on }")
              v-btn.pa-0(@click="allTasks()" color="grey" v-on="on" text small test-id="filter-clear-btn" style="min-width: fit-content !important") 
                v-icon delete
            span {{ $t('button.clear') }}             
          //- app-button.filters-labels.text-clr(color="#c4c4c4" @click="createFilter" small) {{ $t('button.save') }} 
          v-tooltip(bottom)
            template(v-slot:activator="{ on }")
              v-btn(@click="createFilter" color="grey" v-on="on" text small test-id="filter-save-btn" ) 
                v-icon save
            span {{ $t('button.save') }}  
        app-button.filters-labels.text-clr( action @click="showTasks()" light small test-id="filter-show-btn") {{ $t('button.show') }}
        //- showTasks  без скобок посылает event, а в функции проверка есть if (!isDefaultFilter), она не срабатывает
        

      app-dialog(v-model="dialog.filter" :header="$t('module.task.nameFilter')" :confirmText="$t('button.save')" @confirm="save")
        app-section
          app-text-field(v-model="filter.name" :label="$t('module.task.name')" counter maxlength=20)

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

let formatDate = (date) => {
  if (!date) return null

  const [year, month, day] = date.split('-')
  return `${day}.${month}.${year}`
}
export default {
  name: 'TaskFilterMenu',
  
  data () {
    return {
      dialog: {
        filter: false
      },
      show:{
        filter:{
          menu: false
        }
      },
      menu1: false,
      menu2: false,
    }
  },

  mounted() {
    console.log(this.projectSettings)
  },

  computed: {
    ...mapState('task', ['completeStatuses', 'priorities', 'filter', 'defaultForm', 'filterList','pluginStatus', 'sortDes', 'sortAsc']),
    ...mapState('project', ['project', 'projectSettings']),
    ...mapState('authUser', ['user']),
    ...mapGetters('workspace', ['workspaces']),
    ...mapGetters('project', ['membership', 'flatlist']),

    membershipOnlyProfile() {
      return this.membership.filter(m => m.profile != null)
    },

    formaterDateStart () {
      return formatDate(this.filter.dateStart)
    },

    formaterDateEnd () {
      return formatDate(this.filter.dateEnd)
    },

    isPluginLinked() {
      let ws = this.workspaces.find(ws => ws.uuid === this.projectSettings.workSpace)
      if (ws) {
        return this.pluginStatus && this.pluginStatus.length > 0 && (ws.plugin && ws.plugin?.pluginUuid)
      }

      return false
    },
    
    translatedCompleteStatuses() {
      const translatedStatuses = []
      
      this.completeStatuses.forEach(status => {
        translatedStatuses.push({
          title: this.$t('module.' + status.message), // Перевод
          message: status.message,
          name: status.name,
          value: status.value
        })
      })

      return translatedStatuses
    },
    translatedPriorities() {
      const translatedPriorities = []

      this.priorities.forEach(priority => {
        translatedPriorities.push({
          title: this.$t('module.task.priority.' + priority.name),
          color: priority.color,
          name: priority.name,
          value: priority.value
        })
      })
      return translatedPriorities
    },
  },

  methods: {
    ...mapActions('task', ['search', 'saveFilter', 'clearFilter', 'loadTasks' ]),
    ...mapMutations('task', ['setFilter', 'setFilterPanel', 'setSelectedTask', 'setSortDes', 'setSortAsc']),

    sortDesTask () {
      this.setSortDes(!this.sortDes)
      this.setSortAsc(false)
    },

    sortAscTask () {
      this.setSortAsc(!this.sortAsc)
      this.setSortDes(false)
    },

    allTasks () {
      this.setFilter(null)
      this.showTasks()
    },

    tasksCreatedByMe () {
      this.setFilter(null)
      this.filter.creator = true
      this.showTasks(true)
    },

    taskForMe () {
      this.setFilter(null)
      this.filter.forMe = true
      this.showTasks(true)
    },

    createFilter () {
      this.filter.name = null
      this.filter.uuid = null
      this.dialog.filter=true
    },

    save () {
      this.filter.uuid = this.uuidv4()
      this.filter.project = this.project.uuid
      this.setFilter(this.filter)
      this.saveFilter()
    },

    setFilterShow (filter) {
      let filterClone = Object.assign({}, filter)
      filterClone.uuid = this.uuidv4()
      filterClone.project = this.project.uuid
      this.setFilter(filterClone)
      this.showTasks(false)
    },

    showTasks (isDefaultFilter = false) {
      
      if (!isDefaultFilter) {
        this.filter.creator = false
        this.filter.forMe = false
      }
      
      let filterClone = Object.assign({}, this.filter)
      filterClone.project = this.project.uuid

      filterClone.dateStart = filterClone.dateStart ? filterClone.dateStart : null
      filterClone.dateEnd =  filterClone.dateEnd ? filterClone.dateEnd : null
      this.setSelectedTask(null)
      this.setFilter(filterClone)
      // this.search(filterClone)  двойной вызов this.search(filterClone) зачем??
      this.loadTasks()
    },

    showFilterMenu(){
      this.$emit('filterMoreClick')
      this.show.filter.menu = !this.show.filter.menu
    },

    memberName(member){
      return member.profile != null ? member.profile.name : member.orgGroup.title
    },

    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
    
  }
}
</script>

<style scoped>
#my-checkbox .v-input .v-label {
  font-size: 13px;
}

.filter-title{
  color:#757575;
  font-weight: 300;
  font-size: 10px;
  line-height: 0px;
  padding-left: 9px;
  padding-top: 5px;
  height: 10px;
}

.filters-labels{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
  letter-spacing: 0;
}

.text-clr{
  color: #fff;
}
</style>