<template lang="pug">
div
  app-page-header( opacity )
    template( v-slot:headerContentStart )
      .project-workspace(v-if="!$app.version.isCloud")
        v-select.ml-2(v-model="myworkspace" :items="listWorkspaces" color="var(--v-primary-darken2)" item-color="#fff" :menu-props="menuProps" item-value="uuid" :item-text="getTitle" dense hide-details test-id="workspace-list")

    template( v-slot:headerContentCenter )
      .project-title.justify-center
        div.d-flex.align-center
          span {{ projectShortcut.title }}
          app-toolbar-button.ml-3( v-if="!$app.version.isCloud" icon="mdi-cog-outline" :title="$t('module.task.settings')" :active="isActive" @click='editProject($event), isActive=!isActive' test-id="project-toolbar-gear-btn" )
                      
      
    template( v-slot:headerEnd )
      project-layer-top( id="top-panel" )
      
  toolbar-menu(ref="settingTools" :menu="tools" @close="isActive=false" :header='$t("module.task.settings")')
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ToolbarMenu from "@/components/project/panel/left/floors/ToolbarMenu"
import ProjectLayerTop from "@@/project/layer/ProjectLayerTop"
import AppPageHeader from '@/components/project/common/PageHeader.vue'

export default { 
  components: {
    ToolbarMenu,
    ProjectLayerTop, 
    AppPageHeader
  },
  
  props: {
    logo: String,
  },

  data() {
    return {
      isActive: false,
      menuProps: {
        bottom: true, offsetY: true, contentClass: 'menu-scroll', light: true
      },
    }
  },

  computed: {
    ...mapGetters("project", ["projectShortcut"]),
    ...mapState("project", ["projectSettings"]),
    ...mapGetters("theme", ['themeName']),
    ...mapState('task', ['taskIsLoad']),

    ...mapState("workspace", [ "workspace" ]),
    ...mapGetters("workspace", [ "workspaces" ]),

    ...mapGetters('authUser', ['canViewClassificator', 'canViewWorkSpaces']),

    myworkspace: {
      get: function () {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        return ps.workSpace ? ps.workSpace : null
      },
      set: function (newValue) {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        ps.workSpace = newValue
        this.setTaskIsLoad(false)
        this.setTasks([])
        this.setSelectedTask(null)
        this.setTopUnit('')
        this.$store.dispatch('project/updateSettings', ps)
      }
    },

    listWorkspaces() {
      let list = JSON.parse(JSON.stringify(this.workspaces))

      let base = list.find(e => e.title === '{module.task.baseWorkspace}')
      if (!base) {
        list.unshift({uuid:null, title:this.$t("module.task.baseWorkspace")})
      }

      return list
    },
    tools() {
      let tls = [
        {title: this.$t('toolbarMenu.passport'), action: this.openPassport, testID: "toolbar-menu-passport"},
        {title: this.$t('toolbarMenu.statistic'), action: this.openStatistics},
        {title: this.$t('toolbarMenu.structure'), action: this.openStructure},
        {title: this.$t('floor.floorLabel'), action:this.openFloors, testID: "toolbar-menu-floors"},
        {title: this.$t('toolbarMenu.forms'), action:this.openForms, testID: "toolbar-menu-forms"},
        {title: this.$t('toolbarMenu.patterns'), action:this.openTemplates},
        {title: "API", action:this.openApi},
        {title: this.$t('toolbarMenu.rights'), action:this.openRights},
        {title: this.$t('toolbarMenu.cloudSync'), action:this.openClouds},
      ]
      if (this.canViewWorkSpaces) tls.push({title: this.$t('toolbarMenu.workspaces'), action:this.openWorkspaces})

      if (this.canViewClassificator) tls.push({title: this.$t('toolbarMenu.smetaAndClassification'), action:this.openSmeta})

      tls.push({title: this.$t('toolbarMenu.extraOptions'), action: this.openModelVisualSettings})
      tls.push({title: this.$t('toolbarMenu.gridsSettings.settings'), action: this.openGridsSettings})

      return tls
    },
  },

  mounted () {
    if(!this.$app.version.isCloud)
      this.$store.dispatch('workspace/INIT')
  },

  beforeUpdate() {
    document.title = this.projectShortcut.title
  },

  methods: {
    ...mapMutations('project', ['setSelectedModel', 'setTopUnit', 'setActiveGlobalTab']),
    ...mapMutations('task', ['setSelectedTask', 'setTasks', 'setTaskIsLoad']),
    ...mapActions('project', ['postPanelsStatus']),

    toDashboard () {
      this.setSelectedModel(null)
      this.$router.push({ path: `/dashboard` })
    },

    openPassport(){
      this.setActiveGlobalTab("passport")
      this.postPanelsStatus("passport")
    },
    openStatistics(){
      this.setActiveGlobalTab("statistics")
      this.postPanelsStatus("statistics")
    },
    openStructure(){
      this.setActiveGlobalTab("setup")
      this.postPanelsStatus("setup")
    },
    openFloors(){
      this.setActiveGlobalTab("floors")
      this.postPanelsStatus("floors")
    },
    openWorkspaces(){
      this.setActiveGlobalTab("workspaces")
      this.postPanelsStatus("workspaces")
    },
    openSmeta(){
      if (this.canViewClassificator) {
        this.setActiveGlobalTab("smeta")
        this.postPanelsStatus("smeta")
      }
    },
    openForms(){
      this.setActiveGlobalTab("forms")
      this.postPanelsStatus("forms")
    },
    openApi(){
      this.setActiveGlobalTab("api")
      this.postPanelsStatus("api")
    },
    openClouds(){
      this.setActiveGlobalTab("clouds")
      this.postPanelsStatus("clouds")
    },
    openRights(){
      this.setActiveGlobalTab("rights")
      this.postPanelsStatus("rights")
    },
    openModelVisualSettings() {
      this.setActiveGlobalTab("modelVisualSettings")
      this.postPanelsStatus("modelVisualSettings")
    },
    editProject (event) {
      this.$refs.settingTools.show(event)
    },
    openTemplates(){
      this.setActiveGlobalTab("templates")
      this.postPanelsStatus("templates")
    },
    openGridsSettings(){
      this.setActiveGlobalTab("grids")
    },

    getTitle(item) {
      let title = item.title

      if (!title) return ""

      if (title.includes("{")) 
        return this.$t(title.replace("{", "").replace("}", ""))

      return title
    },
  }
}
</script>

<style lang="scss" scoped>
.project-toolbar-container {
  display: flex;
  overflow: hidden;
  text-align: center;
  flex-grow: 1;
  margin-left: -16px;
  margin-right: -16px;
}
.project-toolbar-container div:nth-child(1) {
  /* flex: 2;  */
}
.project-toolbar-container div:nth-child(2) {
  flex: 8;
}
.project-toolbar-container div:nth-child(3) {
  flex: 1;
}
.project-toolbar-container div:nth-child(4) {
  flex: 1;
}

.active{
  background-color: var(--v-surfaceColor-base);
}

.project-workspace {
  max-width: 200px;
}

::v-deep.v-select__selections,
::v-deep.theme--light.v-select .v-select__selection--comma,
::v-deep.theme--dark.v-select .v-select__selection--comma {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

::v-deep.v-text-field .v-input__control .v-input__slot:before{
  border-style: none;
}

.project-title {
  max-width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
    // font-weight: 700;
    font-size: 18px;
    
    max-width: 500px;
  }
}
/* SCROLL STYLE FOR V-SELECT */
  
</style>