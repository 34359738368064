<template lang="pug">
ToolButton( 
  :tooltip="state.gridsTitle" 
  :active="state.isGridsActive" 
  :disabled="!state.isGridsAvailable"  
  @click="state.toggleDisplayOfGrids" 
) $grid-icon
</template>

<script>
import ToolButton from './ToolButton.vue'
import { useGridsViewerToolState } from '@/states/grids/gridsViewerTool.state.js';

export default {
  components: {
    ToolButton,
  },

  computed: {
    state: () => useGridsViewerToolState()
  },
}
</script>