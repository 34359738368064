<template lang="pug">
.rectangle
  CroppingRectangleCorner.rectangle__top-left-corner
  CroppingRectangleCorner.rectangle__top-right-corner
  CroppingRectangleCorner.rectangle__bottom-left-corner
  CroppingRectangleCorner.rectangle__bottom-right-corner

  AnchorPoint( v-show="state.firstPointSetted" :number="1" id="drawingAnchorPoint1")
  AnchorPoint( v-show="state.secondPointSetted" :number="2" id="drawingAnchorPoint2")
</template>

<script>
import CroppingRectangleCorner from './CroppingRectangleCorner.vue';
import AnchorPoint from '../anchorPoints/AnchorPoint.vue'
import { useDrawingCroppingState } from '@/pinia';

export default {
  components: {
    CroppingRectangleCorner,
    AnchorPoint
  },

  computed: {
    state: () => useDrawingCroppingState()
  }
}
</script>

<style scoped>
.rectangle {
  position: absolute;
  border: solid;
  border-width: 1px;
  border-color: rgb(255, 0, 255);
  width: 100%;
  height: 100%;
  box-shadow: 0em 0em 0px 100vh rgba(0, 0, 0, 0.5);
}

.rectangle__top-left-corner {
  left: -1px;
  top: -1px;
}

.rectangle__top-right-corner {
  right: -1px;
  top: -1px;
}

.rectangle__bottom-left-corner {
  left: -1px;
  bottom: -1px;
}

.rectangle__bottom-right-corner {
  right: -1px;
  bottom: -1px;
}
</style>