<template lang="pug">
div
  app-autocomplete.task-type-selection(
    v-model="type"
    :items="typeItems"
    item-text="name"
    item-value="uuid"
    :label="$t('module.task.taskType')"
    @click="loadTaskTypes()"
    test-id="task-type-selection-input"
    @change="clearTaskType($event)"
  )
  app-section.task-forms-details.task-form-details-fonts(v-if="type && fields.length")
    div(v-for="(f, index) in fields")
      field.mt-1( :field="f" :key="f.type + ':' + f.uuid" :valueList="taskTypeFields" )
  
  .d-flex.justify-end.align-center.mt-2(v-if="!selectedTask.uuid" test-id="create-task-container")
    //- v-btn(color="red" @click='setSelectedTask(null); setTopUnit("task")' text x-small) {{ $t('button.hide') }}
    //- v-spacer
    app-button.ml-2( v-if="!creatingFlag" :disabled="noReadyNewTask" action test-id="task-form-detail-create-input" @click="createTask") {{ $t('button.create') }}

  .d-flex.justify-end.align-center.mt-2(v-else)
    app-button.ml-2( :disabled="checkForm" action @click="changeTaskType" test-id="task-form-details-button-save") {{ $t('button.save') }}
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import TaskFormsSection from '@/components/task/taskDetailsSections/TaskFormsSection'
import Field from '@/pages/admin/forms/Field.vue'

export default {
  name: "TaskFormDetails",

  props: {
    title: null,
    light: {
      type: Boolean,
      default: true
    }
  },

  components: {
    TaskFormsSection,
    Field
  },

  data() {
    const defaultTaskType = Object.freeze({
      uuid: null,
      bind: null,
      fields: [],
      form: { uuid: null }
    })

    return {
      formType: {
        TASK: 0
      },
      creatingFlag: false,
      taskType: Object.assign({}, defaultTaskType),
      taskTypeItems: [],
      taskTypeFields: new Map()
    }
  },

  mounted() {
    this.loadTaskTypes()
  },

  computed: {
    ...mapGetters('forms', ['form', 'forms', 'isFormsLoaded', 'formTypes', 'isFormTypesLoaded', 'fieldTypes', 'isFieldTypesLoaded']),
    ...mapGetters('project', ['projectUuid']),
    ...mapState('task', ['selectedTask']),
    ...mapGetters("theme", ['themeName']),

    noReadyNewTask() {
      return !this.title
    },

    checkForm() {
      return !this.taskType.form.uuid
    },

    fields() {
      return this.taskType.fields 
    },

    typeItems() {
      return this.taskTypeItems
    },

    type: {
      get(){
        return this.taskType
      },
      set(val){
        this.processTaskType(this.taskTypeItems.filter(item => item.uuid == val)[0])
      }
    }
  },

  methods: {
    ...mapMutations('task', ['setCreatingTaskType', 'setSelectedTask']),
    ...mapMutations('project', ['setTopUnit']),

    formsByType (type) {
      return this.forms.filter(f => f.formType != null && f.formType.value == type)
    },

    loadTaskTypes() {
      this.$store.dispatch('forms/INIT', { bind: this.projectUuid }).then(() => {
        if(this.isFormsLoaded){
          this.taskTypeItems = this.formsByType(this.formType.TASK)
        }
      })
    },

    processTaskType(taskType){
      if(taskType){
        this.taskTypeFields = new Map()
        this.taskType.form.uuid = taskType.uuid
        this.taskType.fields = taskType.fields
      } else{
        this.taskType.form.uuid = null
        this.taskType.fields = []
      }
    },

    getTaskTypeData(){
      let formData = {
        bind: null,
        fields: [],
        form: {"uuid": null},
        uuid: null,
      }
      formData.bind = this.selectedTask.uuid
      this.taskTypeFields.forEach((values) => {
        formData.fields.push(values)
      })
      formData.form.uuid = this.taskType.form.uuid
      formData.uuid = this.taskType.uuid
      return formData
    },

    createTask(){
      this.creatingFlag = true
      if(this.getTaskTypeData().form.uuid){
        this.$store.commit('task/setCreatingTaskType', {taskType: this.getTaskTypeData()})
      } else {
        this.$store.commit('task/setCreatingTaskType', {taskType: null})
      }
      this.$emit('createTask')
    },

    changeTaskType(){
      this.$emit('changeTaskType', {
        taskType: this.getTaskTypeData()
      })
    },
    clearTaskType(event) {
      if(!event) {
        this.processTaskType(null)
      }
    }
  },
}
</script>

<style lang="scss">

  .task-forms-details{
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .task-form-details-fonts{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: #4d4d4d;
    text-align: left;
  }

@-moz-document url-prefix() {
  .task-forms-details {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v-accent-base) var(--v-scroll-base);
  }
}

.task-forms-details::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}

.task-forms-details::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: var(--v-scroll-base);
}

.task-forms-details::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}
</style>