<template lang="pug">
  div
    div.pr-2.base-scroll-body.viewer(v-html="mdtext" :style="{ height }")
    app-dialog(v-model="show" :header="$t('app.markdownTextarea.help')" width="600")
        app-section.base-scroll-body
          .surface--text
            | {{ $t('app.markdownTextarea.helperLink') }}  
            a(href="https://markdown-it.github.io/" target="_blank") markdown-it
            | - {{ $t('app.markdownTextarea.helperLink1') }}
          .viewer(v-html="helperHtml")
              
</template>

<script>
  let MarkdownIt = require('markdown-it')
  let md = new MarkdownIt({ breaks: true })

  export default {
    name: 'MdTextarea',
    props: {
      text: String,
      height: {
        type: String,
        default: "150px"
      }
    },

    data() {
      return {
        show:false
      }
    },
    
    computed: {
      mdtext() {
        if (this.text) return md.render(this.text)
        else return ''
      },

      helperHtml() {
        let markdodwEample =
          `# ${this.$t('app.markdownTextarea.helperH1')}` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `# ${this.$t('app.markdownTextarea.helperH1')}` +
          `\n\`\`\`\n\n***\n\n\n` +
          `## ${this.$t('app.markdownTextarea.helperH2')}` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `## ${this.$t('app.markdownTextarea.helperExample')}` +
          `\n\`\`\`\n\n***\n\n` +
          `**${this.$t('app.markdownTextarea.helperBold')}**` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `**${this.$t('app.markdownTextarea.helperBold')}**` +
          `\n\`\`\`\n\n***\n\n` +
          `*${this.$t('app.markdownTextarea.helperItalic')}*` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `*${this.$t('app.markdownTextarea.helperItalic')}*` +
          `\n\`\`\`\n\n***\n\n` +
          `${this.$t('app.markdownTextarea.helperList')} \n\n\n\n` +
          `${this.$t('app.markdownTextarea.helperItem1')}\n${this.$t('app.markdownTextarea.helperItem2')}
          \n${this.$t('app.markdownTextarea.helperItem3')}` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `${this.$t('app.markdownTextarea.helperItem1')}\n${this.$t('app.markdownTextarea.helperItem2')}\n${this.$t(
            'app.markdownTextarea.helperItem3'
          )}` +
          `\n\`\`\`\n\n***\n\n` +
          `### [${this.$t('app.markdownTextarea.helperNameLink')}](https://www.google.com)\n\n` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `### [${this.$t('app.markdownTextarea.helperNameLink')}](https://www.google.com)` +
          `\n\`\`\`\n***\n\n\n` +
          `${this.$t(
            'app.markdownTextarea.helperImage'
          )}\n![Minion](https://octodex.github.com/images/minion.png)\n\n` +
          `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**` +
          `\n\n\`\`\`\n` +
          `![Minion](https://octodex.github.com/images/minion.png)` +
          `\n\`\`\``
        return md.render(markdodwEample)
      },
    },
    methods: {
      showInfo(){
        this.show = true
      }
    },
  }
</script>

<style scoped>
  .viewer {
    color: black;
    width: 100%;
    min-height: 20px;
    margin: 0 10px;
  }

  .t-scroll-body {
    overflow-y: auto;
    /* height: 75vh; */
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-primary-lighten3);
  }
  .t-scroll-body::-webkit-scrollbar-thumb {
    background-color: var(--v-surface-lighten3);
  }

  .viewer img,
  .onlyViewer img {
    width: 100px;
  }

  .viewer {
    word-wrap: break-word;
  }

  .viewer P,
  .onlyViewer p {
    margin-bottom: 0px;
  }

  .viewer:hover {
    cursor: pointer;
  }

  .hide-scrollbar textarea {
    overflow-y: hidden;
  }
  ::v-deep .viewer pre code {
    background-color: #f9f2f4;
  }
</style>
