<template lang="pug">
  app-dialog( v-model="visible" :header="$t('module.task.create')" :confirm-text="$t('button.save')" :confirm-disabled="validation" @confirm="createTaskWithCollisionsAttr" )
    app-section
      app-text-field( v-model="name" :label="$t('module.task.form.title')" test-id="collision-create-task-input" )
</template>

<script>
  import { api } from '@/api'
  import { mapMutations } from 'vuex'
  import { useAttributeCheckingTableStore } from '@/pinia'
  import { mapStores } from 'pinia'

  export default {
    data() {
      return {
        visible: false,
        single: false,
        name: '',
        selectedRow: null,
      }
    },

    computed: {
      ...mapStores(useAttributeCheckingTableStore),

      validation() {
        return !this.name.trim()
      }
    },

    methods: {
      ...mapMutations('task', ['setSelectedTask']),
      ...mapMutations('project', ['setTopUnit']),

      show({ single, item = null }) {
        this.name = ''
        this.single = single
        this.selectedRow = item
        this.visible = true
      },

      createTaskWithCollisionsAttr() {
        let type = this.selectedRow.level == 0 ? 'rules' : 'attributes'
        // let list = this.single ? [this.selectedRow.uuid] : this.selectedCollisionsUuid
        let list = [this.selectedRow.uuid]
        api.tasks.createTaskWithAttachments(this.name, list, type).then(async (task) => {
          this.openSelectedTaskPanel(task)
          this.attributeTableStore.changeItemsSource(true, task.uuid)
        })
      },

      openSelectedTaskPanel(task) {
        this.setSelectedTask(task)
        // this.loadTaskCollisions(task)
        this.setTopUnit('task')
        this.$store.dispatch('task/loadTasks')
      },
    },
  }
</script>
