import { useGroupsWindowAssembly, ModalComponent } from '@/states/groups/groupsWindow.assembly'
import { useGroupsAxisFormModalState } from '@/states/groups/groupsAxisFormModal.state'
import { AxisDataAdapter } from '@/adapters/axes.adapters'
import { showTreeOfGroup } from '@/facades/groupsTree.facade'
import { saveGroup } from '@/facades/groups.facade'
import { useActiveToolWindowStore } from '@/stores/viewerTools/activeToolWindow.store'

const assembly = useGroupsWindowAssembly()
const groupAxisForm = useGroupsAxisFormModalState()

export function showMenuForGroup ({ group, mouseEvent }) {
  assembly.menuPosition = { x: mouseEvent.clientX, y: mouseEvent.clientY }
  assembly.groupUuid = group.uuid
  assembly.activeModalComponent = ModalComponent.GroupMenu
}

export function closeMenu () {
  assembly.activeModalComponent = null
}

export async function showDialogToCreateGroup () {
  groupAxisForm.axisData = await AxisDataAdapter.getDefaultAxisData()
  // let axisData = await AxisDataAdapter.getDefaultAxisData()
  // groupAxisForm.setupAxisData(axisData)
  assembly.activeModalComponent = ModalComponent.EditGroup
}

export function showDialogToEditGroup (uuid) {
  groupAxisForm.axisData = AxisDataAdapter.findGroupByUuid(uuid)
  // let axisData = AxisDataAdapter.findGroupByUuid(uuid)
  // groupAxisForm.setupAxisData(axisData)
  assembly.activeModalComponent = ModalComponent.EditGroup
}

export async function showDialogToOverrideGroup (uuid, combine = false) {
  let axisData = await AxisDataAdapter.getDefaultAxisData()
  let group = AxisDataAdapter.findGroupByUuid(uuid)
  if (combine) {
    axisData.logicGroup = {
      ...group.logicGroup,
      condition: Object.keys(axisData.logicGroup).length ? axisData.logicGroup.condition.concat(group.logicGroup.condition) : group.logicGroup.condition,
      logicOperatorType: {
        value: 1,
        title: "ИЛИ",
        name: "OR"
      }
    }
  }
  
  mergeAxisDataWithGroup(axisData, group)
  groupAxisForm.axisData = axisData
  assembly.activeModalComponent = ModalComponent.EditGroup
}

function mergeAxisDataWithGroup (axisData, group) {
  axisData.title = group.title
  axisData.uuid = group.uuid
  axisData.axis = group.axis
  axisData.color = group.color
  axisData.colorize = group.colorize
  axisData.profile = group.profile
  axisData.project = group.project
  axisData.shared = group.shared
  axisData.showchild = group.showchild
}

export function showDialogToEditGroupPalette (uuid, item = false) {
  assembly.groupUuid = uuid
  assembly.activeModalComponent = ModalComponent.EditPalette
  assembly.disabledAllGroups = item.isDisabled
  assembly.selectedElement = item.element
}

export function showTreeForGroupWithUuid (uuid) {
  const store = useActiveToolWindowStore()
  store.closeWindow()

  const group = AxisDataAdapter.findGroupByUuid(uuid)
  showTreeOfGroup(group)
}

export async function closeGroupingDialog () {
  assembly.closeGroupingDialog()
}

export async function writeGroupInCache (group) {
  await saveGroup(group)
}