<template lang="pug">
  v-hover( #default="{ hover }" )
    .social-button
      v-img.social-button__icon( v-if="hover" :src="hoveredIconPath" contain )
      v-img.social-button__icon( v-else :src="iconPath" contain )
      slot
</template>

<script>
export default {
  props: {
    iconName: String
  },

  computed: {
    iconPath () {
      if (!this.iconName) return null
      return `img/social/icons/${this.iconName}.svg`
    },

    hoveredIconPath () {
      if (!this.iconName) return null
      return `img/social/icons/${this.iconName}_hover.svg`
    },
  }
}
</script>

<style lang="scss" scoped>
.social-button {
  width: 100%;
  height: 40px;
  position: relative;
  font: normal 14px/40px $roboto;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;

  background-color: #FFFFFF;
  color: #959595;
}
.social-button:hover {
  background-color: #3B93AF;
  color: #FFFFFF;
}
.social-button__icon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 8px;
}
</style>