<template lang="pug">
.node_label.pr-6( :title="source.title" :class="{ 'active-element': activeElement && activeElement == source.uuid }" )
  v-row.pointer( no-gutters @click="toggleElement(source)" )
    v-col( cols=10 :title="source.name" )
      div.text-overflow {{ source.title }}
    v-col( cols=2 :title="source.value ? source.value : '-'" ) 
      div.text-overflow {{ source.value ? source.value : '-'}}
  v-icon.node__checkbox( tag="button" size="17" light @click.stop="selectElement(source.uuid)" ) {{ isElementSelected ? 'check_box' : 'check_box_outline_blank' }}
</template>

<script>

export default {
  props: {
    source: { type: Object, default: null },
    activeElement: null,
    selectedGroupElements: [],
    toggleElement: Function,
    selectElement: Function
  },

  data() {
    return {
      numberFormatter: new Intl.NumberFormat("ru-RU",{ maximumFractionDigits: 3, style: 'decimal', useGrouping: false })
    }
  },

  computed: {
    isElementSelected() {
      return this.selectedGroupElements.includes(this.source.uuid)
    }
  }
}
</script>

<style scoped>
.node_label {
  white-space: nowrap;
  position: relative;
}
.node__checkbox {
  position: absolute;
  right: 10px;
  top: 0;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.active-element {
  background-color: #c0c0c0;
}
</style>