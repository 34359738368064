var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex flex-column px-2"},[_c('div',{staticClass:"group-name"},[_c('span',[_vm._v(_vm._s(_vm.drawing.name))]),_c('transition',{attrs:{"name":"contol-button"}},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"#C4C4C4","small":"","icon":""},on:{"click":_vm.showDrawingControlMenu}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('app-menu',{ref:"drawingControl",attrs:{"menu":_vm.drawingSettings}})],1)])],1),_c('div',{staticClass:"group-version d-flex align-center"},[_c('div',{staticClass:"actual-version"},[_vm._v(_vm._s(_vm.$t('module.drawing.actualVersionRow', {number: _vm.drawing.currentVersion.versionNum, date: _vm.prettyDate(_vm.drawing.currentVersion.createDate)})))]),_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#757575","x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('app-section',[(_vm.drawing.versions.length > 0)?_c('div',{staticClass:"d-flex flex-column black--text"},_vm._l((_vm.drawing.versions),function(version){return _c('div',{staticClass:"drawing-version-row"},[(_vm.isActualVersion(version))?_c('span',{style:(_vm.versionNameStyle(version))},[_vm._v(_vm._s(_vm.$t('module.drawing.actualVersionRow', { number: version.versionNum, date: _vm.prettyDate(version.createDate)})))]):_c('span',{style:(_vm.versionNameStyle(version))},[_vm._v(_vm._s(_vm.$t('module.drawing.versionRow', { number: version.versionNum, date: _vm.prettyDate(version.createDate)})))]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadSelectedVersion(version)}}},[_c('img',{attrs:{"src":"/img/project/tools/arrow-down-download.svg"}})])],1)}),0):_vm._e()])],1)],1),_c('app-dialog',{attrs:{"header":_vm.$t("module.drawing.editDrawing")},model:{value:(_vm.editDrawingDialog.show),callback:function ($$v) {_vm.$set(_vm.editDrawingDialog, "show", $$v)},expression:"editDrawingDialog.show"}},[_c('app-section',{staticClass:"py-4 d-flex flex-column"},[_c('v-menu',{attrs:{"nudge-bottom":"28","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"relative"},'div',attrs,false),on),[_c('input',{staticClass:"edit-drawing-input pointer",attrs:{"placeholder":_vm.$t("module.drawing.indicateSource"),"readonly":""},domProps:{"value":_vm.translateName(_vm.editDrawingDialog.drawing.model.name)}}),_c('v-btn',{staticClass:"select-button",attrs:{"x-small":"","icon":""}},[_c('v-icon',{attrs:{"color":"#757575"}},[_vm._v("mdi-menu-down")])],1)],1)]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.preparedModels),function(model){return _c('div',{staticClass:"model-select-row",on:{"click":function($event){_vm.editDrawingDialog.drawing.model = model}}},[_vm._v(_vm._s(_vm.translateName(model.name)))])}),0)]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editDrawingDialog.drawing.name),expression:"editDrawingDialog.drawing.name"}],staticClass:"edit-drawing-input",attrs:{"placeholder":_vm.$t("module.drawing.name")},domProps:{"value":(_vm.editDrawingDialog.drawing.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editDrawingDialog.drawing, "name", $event.target.value)}}}),_c('v-btn',{staticClass:"ml-auto mt-1",attrs:{"color":"accent","small":"","disabled":!_vm.editDrawingFieldsAreValid,"light":""},on:{"click":_vm.editDrawing}},[_vm._v(_vm._s(_vm.$t("button.save")))])],1)],1),_c('app-dialog-confirm',{on:{"confirm":_vm.deleteDrawing},model:{value:(_vm.deleteDrawingDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDrawingDialog, "show", $$v)},expression:"deleteDrawingDialog.show"}},[_c('app-text',[_vm._v(_vm._s(_vm.$t('module.drawing.deleteConfirm', {name: _vm.deleteDrawingDialog.drawing.name})))])],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileInput",attrs:{"type":"file","accept":".pdf"},on:{"change":_vm.addNewVersionForDrawing}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }