<template lang='pug'>
  app-dialog(v-model='show' :header='$t(headerTitle)')
    div.d-flex.flex-column(v-if='localSchema')
      app-section
        app-text-field(v-model='localSchema.title' :placeholder='$t("module.mnemoschemas.editor.mnemoschemaTitle")')

      app-button.ml-auto.mt-2.mr-2( :disabled='!localSchema.title' @click='save()' action ) {{ $t('button.save') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const defaultSchema = {
  title: null,
  project: null,
  schema: null
}

export default {
  name: "SaveSchemaDialog",
  
  props: {
    value: Boolean,
    schema: Object
  },

  data() {
    return {
      localSchema: null
    }
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),

    show: {
      get() {
        return this.value
      },
      
      set() {
        this.close()
      }
    },

    headerTitle() {
      return this.localSchema?.uuid ? 'module.mnemoschemas.edit' : 'module.mnemoschemas.create'
    }
  },

  beforeUpdate() {
    if (!this.initialized) {
      this.init()
    }
    
    this.initialized = this.show
  },

  methods: {
    ...mapActions('mnemoschema', ['saveMnemoschema']),

    save() {
      this.saveMnemoschema(this.localSchema)
      this.close()
    },

    close() {
      this.$emit('input', false)
    },

    init() {
      this.initialized = true
      this.localSchema = this.schema ? { ...this.schema } : { ...defaultSchema, project: this.projectUuid }
    }
  }
}
</script>

<style>

</style>