var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFile)?_c('div',{staticClass:"justify-end font-text pointer",on:{"click":function($event){return _vm.attachmentDownload()}}},[_vm._v(_vm._s(_vm.attach.name || _vm.$t('module.task.noName')))]):_c('v-hover',{staticClass:"attachment-container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"t-attach app-cursor--pointer relative",class:{ 'elevation-4': hover, 'active' : _vm.isCurrentAttach},on:{"click":function($event){return _vm.$emit('location')}}},[(_vm.isAttachHaveScreenShoot(_vm.attach))?_c('v-img',{staticClass:"attachment-img",attrs:{"src":_vm.filePath(_vm.attach),"width":_vm.editable ? 247 : 210,"height":126}}):(_vm.isAttachHaveImage(_vm.attach))?_c('v-img',{staticClass:"attachment-img",attrs:{"src":_vm.filePath(_vm.attach),"width":_vm.editable ? 247 : 210,"height":126}}):(_vm.isAttachHaveDrawing(_vm.attach))?_c('div',{ref:"drawingAttachmentWindow",staticClass:"scroll-hidden"},[_c('img',{ref:"drawingAttachment",staticClass:"attachment-img",attrs:{"width":_vm.editable ? 247 : 210,"height":126}})]):_vm._e(),_c('div',{staticClass:"attachment-menu-body",class:{ 'blackout-img': hover}},[(_vm.$app.device.isPortable ? true : hover)?_c('div',{staticClass:"d-flex img-buttons-flex"},[(_vm.showLocationBtn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"img-button ma-1",attrs:{"icon":"project/tools/my_location.svg"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('location')}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('module.task.goCoords'))+" ")]),(_vm.is3DAttach)?_c('span',[_vm._v("(3D)")]):_vm._e()]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"img-button ma-1",attrs:{"icon":"project/tools/cloud_download.svg"},on:{"click":function($event){$event.stopPropagation();return _vm.attachmentDownload()}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.save')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"img-button ma-1",attrs:{"icon":"project/tools/eye.svg"},on:{"click":function($event){$event.stopPropagation();return _vm.showImage()}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.open')))])])],1):_vm._e()])],1)]}}])}),_c('image-dialog',{attrs:{"withScrollPanel":"","width":"auto"},model:{value:(_vm.showFullImage),callback:function ($$v) {_vm.showFullImage=$$v},expression:"showFullImage"}},[_c('v-img',{attrs:{"src":_vm.imagePath}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }