var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-section',{staticClass:"ma-1 pa-0"},[_c('v-btn',{staticClass:"right",attrs:{"icon":"","text":"","depressed":"","x-small":""},on:{"click":_vm.swapFilter}},[_c('v-icon',{attrs:{"color":"var(--v-primary-darken2)"}},[_vm._v("mdi-filter")]),_c('span',{staticClass:"attachment"},[_vm._v(_vm._s(_vm.$t("section.equipment.filter.equipmentAttachments")))])],1),_c('input',{attrs:{"id":"fileUploadEquipment","type":"file","hidden":""},on:{"change":_vm.onFileSelected}}),_c('v-btn',{staticClass:"right",attrs:{"small":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.chooseFiles(_vm.equipment)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"attachment"},[_vm._v(_vm._s(_vm.$t("module.task.addAttachment")))])],1),_c('expansion-button',{staticClass:"short-text mb-1",attrs:{"buttonHeader":_vm.$t("section.equipment.sharedAttachments")}},_vm._l((_vm.equipment.attachments),function(attachment,index){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.equipment.attachments.length),expression:"equipment.attachments.length"}],key:index,staticClass:"mt-1",attrs:{"cols":"auto","no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('app-text',{staticClass:"attachment pt-1"},[_vm._v(_vm._s(attachment.title))])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.downloadEquipmentFile(attachment.uuid)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("file_download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("section.journal.downloadFile")))])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.confirmDeleteAttach(attachment)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("module.task.delAttachment")))])])],1)],1)}),1),_c('v-divider',{staticClass:"mb-2"}),_c('expansion-button',{staticClass:"short-text",attrs:{"buttonHeader":_vm.$t("patches.elements")}},_vm._l((_vm.filterElements),function(element){return _c('div',{key:element.globalId,staticClass:"d-block",attrs:{"no-gutters":""},on:{"click":function($event){(_vm.selectElement(element))}}},[_c('v-row',{staticClass:"my-1"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('expansion-button',{staticClass:"toggle-expansion",class:{ 'not-attach' : !element.file.length },nativeOn:{"click":function($event){return _vm.openElements(element)}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('app-text',{staticClass:"attachment short-text pt-1",class:{ 'not-attach' : !element.file.length },nativeOn:{"click":function($event){return _vm.openElements(element)}}},[_vm._v(_vm._s(element.name))])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('input',{attrs:{"id":"fileUploadElement","type":"file","hidden":""},on:{"change":_vm.onFileSelected}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 mb-1",attrs:{"x-small":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.chooseFiles(element)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("section.journal.addFile")))])])],1)],1),_vm._l((element.file),function(file,index){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(element.file.length && _vm.opened.includes(element)),expression:"element.file.length && opened.includes(element)"}],key:file.uuid,staticClass:"my-1",class:{'list_item' : index % 2 == 0},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('app-text',{staticClass:"attachment mt-1 pl-4"},[_vm._v(_vm._s(file.name))])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pb-1",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.downloadElementFile(file.uuid)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("file_download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("section.journal.downloadFile")))])])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pb-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.confirmDeleteFile(file)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("module.task.delAttachment")))])])],1)],1)}),_c('v-divider')],2)}),0),_c('app-dialog-confirm',{ref:"delElementFile",on:{"confirm":_vm.deleteEquipAttach},model:{value:(_vm.dialog.deleteAttach),callback:function ($$v) {_vm.$set(_vm.dialog, "deleteAttach", $$v)},expression:"dialog.deleteAttach"}},[_c('app-text',[_vm._v(_vm._s(_vm.$t('section.structure.model.deleteFileConfirm'))+" \""+_vm._s(_vm.deleteFile.title)+"\"?")])],1),_c('app-dialog-confirm',{ref:"delElementFile",on:{"confirm":_vm.deleteElemAttach},model:{value:(_vm.dialog.delElementFile),callback:function ($$v) {_vm.$set(_vm.dialog, "delElementFile", $$v)},expression:"dialog.delElementFile"}},[_c('app-text',[_vm._v(_vm._s(_vm.$t('section.structure.model.deleteFileConfirm'))+" \""+_vm._s(_vm.deleteFile.name)+"\"?")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }