<template lang="pug">
  div.ma-2
    template(v-if="equipment && equipment.uuid != null && isEventsLoaded" )
      warning-card(v-for="event in events.list" :key="event.uuid" :event="event" @click.native="setSelectedEvent(event)" :short="true" :lineView="true")
      app-text.ma-2(v-if="events.list.length == 0") {{ $t('section.equipment.details.eventsNotFound') }}
      paginator( v-if="events.totalPages > 1" v-model="filter.page" :totalPages="events.totalPages"  @input="getEventsByFilter()")
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import WarningCard from './WarningCard'
import Paginator from '@/components/project/panel/left/maintenance/components/Paginator.vue'

export default {
  components: {
    WarningCard,
    Paginator
  },

  props: {
    equipment: null,
  },

  data () {
    return {
      filter: {
        page: 1,
        perPage: 5,
        equipment: ''
      }
    }
  },


  watch:{
    equipment () {
     this.getEventsByFilter()
    }
  },

  mounted() {
    this.$store.dispatch('equipmentWarning/LOAD_PROFILES')
    this.getEventsByFilter()
  },

  computed: {
    ...mapState('equipmentWarning', ['events']),
    ...mapGetters('equipmentWarning', ['isEventsLoaded']),
  },

  methods: {
    getEventsByFilter() {
      if(this.equipment && this.equipment.uuid != null) {
        this.filter.equipment = this.equipment.uuid
        this.$store.dispatch('equipmentWarning/LOAD_EVENTS_BY_FILTER', this.filter)
      }
    }
  }
}
</script>

<style scoped>

.t-scroll-body {
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
    width: 5px;
    background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb  {
   background-color: var(--v-primary-lighten4);
}

.h100p {height: 100%;}
</style>

