<template lang="pug">
  v-text-field.field.darkFieldColor( 
    v-model="color"
    :label="$t(label)" 
    :readonly="!editable"
    light
    background-color="#FFFFFF"
    dense
    filled
    hide-details
  )
    template(v-slot:append)
      v-menu(v-model="menu" transition="scale-transition" top nudge-bottom="53" nudge-left="16" :close-on-content-click="false")
        template(v-slot:activator="{ on }")
          div(:style="swatchStyle" v-on="on")
        v-card
          v-card-text(class="pa-0")
            v-color-picker(v-model="color" hide-inputs @input="handleChange")
            div.text-center
              v-btn.mb-2( color="accent" dense small @click="select" )  {{ $t('button.choose') }}

</template>

<script>

export default {
  inheritAttrs: false,
  name: 'app-color-picker',
  props: {
    value: String,
    label: {
      type: String,
      default: "section.elementTree.selectColor"
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  
  data () {
    return {
      color: '',
      menu: false,
    }
  },

  watch: {
    value (val) {
      if( val != this.color ) this.setColor()
    },
  },

  mounted() {
    this.setColor()
  },

  computed: {
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },

  methods: {
    setColor() {
      this.color = this.value
    },

    handleChange (color) {
      this.$emit('input', color)
    },

    select () {
      this.menu = false
    }
  }
}
</script>

<style scoped>
.field {
  border-radius: 3px;
}
</style>

<style lang="scss">
.darkFieldColor, .v-text-field .v-label
{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

.darkFieldColor {
  color: #4D4D4D;
}

.v-text-field .v-label {
  color: #C4C4C4;
}

</style>

