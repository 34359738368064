export default {
  config: {
    // wordPattern: /(-?\d*\.\d\w*)|([^\`\~\!\@\#\%\^\&\*\(\)\-\=\+\[\{\]\}\\\|\;\:\'\"\,\.\<\>\/\?\s]+)/,
    brackets: [
      ["{", "}"],
      ["[", "]"],
      ["(", ")"]
    ],
    comments: {
      lineComment: "//",
      blockComment: ["/*", "*/"]
    },
    autoClosingPairs: [
      { open: "{", close: "}" },
      { open: "[", close: "]" },
      { open: "(", close: ")" },
      { open: '"', close: '"', notIn: ["string"] },
      { open: "'", close: "'", notIn: ["string", "comment"] },
      { open: "/*", close: " */", notIn: ["string"] }
    ],
  },
  language: {
    // Set defaultToken to invalid to see what you do not tokenize yet
    defaultToken: 'invalid',
    tokenPostfix: '.groovy',

    brackets: [
      { open: '{', close: '}', token: 'delimiter.curly' },
      { open: '[', close: ']', token: 'delimiter.square' },
      { open: '(', close: ')', token: 'delimiter.parenthesis' }
    ],
    
    keywords: [
      "assert", "with", "abstract", "continue", "for", "new", "switch",
      "assert", "default", "goto", "package", "synchronized",
      "do", "if", "private", "this", "break", "implements", 
      "protected", "throw", "else", "import", "public", "throws",
      "case", "enum", "instanceof", "return", "transient",
      "catch", "extends", "try", "final", "interface", 
      "static", "void", "class", "finally", "strictfp", 
      "volatile", "def", "native", "super", "while"
    ],

    bimClass: [
      'console', 'params', 'bim', 'file', 'collision', 'calc', 'table', 'tempalte', 'notify'
    ],
    
    typeKeywords: [
      'boolean', 'double', 'byte', 'int', 'short', 'char', 'void', 'long', 'float', 'UUID'
    ],
    
    operators: [
      '.','.&','.@','?.','*','*.','*:','~','!',
      '++','--','**','+','-','*','/','%','<<','>>',
      '>>>','..','..<','<','<=','>','>','==','!=',
      '<=>','===','!==','=~','==~','^','|','&&','||',
      '?',':','?:','=','**=','*=','/=','%=','+=',
      '-=','<<=','>>=','>>>=','&=','^=','|=','?='
    ],
    
    symbols: /[=><!~?:&|+\-*/^%]+/,
    escapes: /\\(?:[abfnrtv\\"'`]|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

    regexpctl: /[(){}[\]$^|\-*+?.]/,
    regexpesc: /\\(?:[bBdDfnrstvwWn0\\/]|@regexpctl|c[A-Z]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4})/,
    
    // The main tokenizer for our languages
    tokenizer: {
      root: [
        [/@\s*[a-zA-Z_$][\w$]*/,  { token: 'annotation'}],

        { include: '@whitespace' },
        [/\/(?=([^\\/]|\\.)+\/([dgimsuy]*)(\s*)(\.|;|,|\)|\]|\}|$))/, 
          { token: 'regexp', bracket: '@open', next: '@regexp' }
        ],
        { include: '@comments' },
        { include: '@numbers' },
        { include: 'common' },
        [/[;,.]/, 'delimiter'],
        [/[(){}[\]]/, '@brackets'],
        [/[a-zA-Z_$]\w*/,
          {
            cases: {
            '@keywords': 'keyword',
            '@typeKeywords': 'type',
            '@bimClass': 'type.identifier',
            '@default': 'identifier',
            },
          },
        ],
        [/@symbols/,
          {
            cases: {
            '@operators': 'operator',
            '@default': '',
            },
          },
        ],
      ],
      common: [
        // delimiters and operators
        [/[()[\]]/, '@brackets'],
        [/@symbols/,
          {
            cases: {
            '@operators': 'delimiter',
            '@default': '',
            },
          },
        ],
    
        [/\/(?=([^\\/]|\\.)+\/([gimsuy]*)(\s*)(\.|;|\/|,|\)|\]|\}|$))/, 
          { token: 'regexp', bracket: '@open', next: '@regexp' }
        ],
    
        // delimiter: after number because of .\d floats
        [/[;,.]/, 'delimiter'],
    
        // strings
        [/"([^"\\]|\\.)*$/, 'string.invalid'],
        [/'([^'\\]|\\.)*$/, 'string.invalid'],
        [/"/, 'string', '@string_double'],
        [/'/, 'string', '@string_single'],
      ],
      whitespace: [[/\s+/, 'white']],
      comments: [
        [/\/\/.*/, 'comment'],
        [/\/\*/,
          {
            token: 'comment.quote',
            next: '@comment',
          },
        ],
      ],
      comment: [
        [/[^*/]+/, 'comment'],
        [/\*\//,
          {
            token: 'comment.quote',
            next: '@pop',
          },
        ],
        [/./, 'comment'],
      ],
      commentAnsi: [
        [/\/\*/,
          {
            token: 'comment.quote',
            next: '@comment',
          },
        ],
        [/[^*/]+/, 'comment'],
        [/\*\//,
          {
            token: 'comment.quote',
            next: '@pop',
          },
        ],
        [/./, 'comment'],
      ],
      numbers: [
        [/[+-]?\d+(?:(?:\.\d*)?(?:[eE][+-]?\d+)?)?f?\b/, 'number.float'],
        [/[+-]?(?:0[obx])?\d+(?:u?[lst]?)?\b/, 'number'],
      ],
      regexp: [
        [/(\{)(\d+(?:,\d*)?)(\})/, ['regexp.escape.control', 'regexp.escape.control', 'regexp.escape.control']],
        [/(\[)(\^?)(?=(?:[^\]\\/]|\\.)+)/,
          ['regexp.escape.control', { token: 'regexp.escape.control', next: '@regexrange' }],
        ],
        [/(\()(\?:|\?=|\?!)/, ['regexp.escape.control', 'regexp.escape.control']],
        [/[()]/, 'regexp.escape.control'],
        [/@regexpctl/, 'regexp.escape.control'],
        [/[^\\/]/, 'regexp'],
        [/@regexpesc/, 'regexp.escape'],
        [/\\\./, 'regexp.invalid'],
        [/(\/)([gimsuy]*)/, [{ token: 'regexp', bracket: '@close', next: '@pop' }, 'keyword.other']],
      ],
    
      regexrange: [
        [/-/, 'regexp.escape.control'],
        [/\^/, 'regexp.invalid'],
        [/@regexpesc/, 'regexp.escape'],
        [/[^\]]/, 'regexp'],
        [/\]/, { token: 'regexp.escape.control', next: '@pop', bracket: '@close' }],
      ],
      embedded: [
        [/([^@]|^)([@]{4})*[@]{2}([@]([^@]|$)|[^@]|$)/,
          {
            token: '@rematch',
            next: '@pop',
            nextEmbedded: '@pop',
          },
        ],
      ],
      string_double: [
        [/\$\{/, { token: 'delimiter.bracket', next: '@bracketCounting' }],
        [/[^\\"$]+/, 'string'],
        [/[^\\"]+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/"/, 'string', '@pop'],
      ],
      string_single: [
        [/[^\\']+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/'/, 'string', '@pop'],
      ],
      string_backtick: [
        [/\$\{/, { token: 'delimiter.bracket', next: '@bracketCounting' }],
        [/[^\\"$]+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/"/, 'string', '@pop'],
      ],
      bracketCounting: [
        [/\{/, 'delimiter.bracket', '@bracketCounting'], 
        [/\}/, 'delimiter.bracket', '@pop'], 
        { include: 'common' }
      ],
    },
  }
}