<template lang="pug">
div
  .d-flex.align-center.justify-space-between
    v-btn.dict-button( color="accent" dense text x-small @click="onSwitchActivationPatch" ) {{ $t('patches.activation') }}
    v-switch.view-switch( v-model="switchOn" :value="isActivated" light color="accent" dense @click="onSwitchActivationPatch")

  app-dialog-confirm( v-model="patchActivating" @confirm="activatePatch" @close="cancelActive" )
    app-text {{ isActivated ? $t('patch.dialog.deactivate') : $t('patch.dialog.activate') }} {{ $t('patch.dialog.rec') }} "{{ selectedPatch.name }}"?
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'PatchActivation', 
  data() {
    return {
      patchActivating: false,
      switchOn: false
    }
  }, 

  computed: {
    ...mapState('patches', ['selectedPatch']),

    isActivated() {
      return this.selectedPatch.state.value == 1 // READY - 1
    }, 
  },
 
  mounted() {
    this.switchOn = this.selectedPatch.state.value == 1 ? true : false
  },

  methods: {
    ...mapActions('patches', ['switchActivationPatch']),

    onSwitchActivationPatch() {
      this.patchActivating = true
    },

    activatePatch() {
      this.patchActivating = false
      this.switchActivationPatch(this.selectedPatch.uuid)
    }, 

    cancelActive() {
      this.patchActivating = false
      this.switchOn = this.selectedPatch.state.value == 1 ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.view-switch {
  padding-top: 0px;
  margin-top: 0px;
}
.dict-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none;
}
</style>