<template lang="pug">
  app-dialog(v-model="showDialog" width="600px" :header="$t('app.markdownTextarea.help')" :persistent="false")
      app-section.t-scroll-body.pr-5
        //- .surface--text
        //-   | {{ $t('app.markdownTextarea.helperLink') }}  
        //-   a(href="https://markdown-it.github.io/" target="_blank") marksown-it
        //-   | - {{ $t('app.markdownTextarea.helperLink1') }}
        //- hr(style="width:650px")
        .viewer(v-html="helperHtml")
        .surface--text.pl-2
          | {{ 'Полная документация на ангийском языке -' }}  
          a(href="https://velocity.apache.org/engine/2.3/user-guide.html" target="_blank")  velocity
</template>
<script>
var MarkdownIt = require('markdown-it'),
    md = new MarkdownIt({breaks: true,});
export default {
  data(){
    return{
    showDialog:false
  }},
  computed:{
    helperHtml () {
      let markdodwEample = `**В данном редакторе используются три типа ссылок: переменные, свойства и методы.**`+
      `\n\n\`\`\`\n`+
      `\n\`\`\`\n\n---\n\n\n`+
      `## Переменные`+
      `\n\n**Сокращенное обозначение переменной состоит из начального символа «$», за которым следует идентификатор:**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `**$variable1; $variable2; $variable3**`+
      `\n\`\`\`\n\n---\n\n`+
      `## Свойства`+
      `\n\n**Сокращенное обозначение состоит из начального символа $, за которым следует идентификатор, за которым следует символ точки ("".") и еще один идентификатор, который и является свойством:**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `$customer.Address-(Адрес покупателя)
$purchase.Total-(Итог покупки)`+
      `\n\`\`\`\n\n---\n\n`+
      `## Методы`+
      `\n\n**Методы — это функции которые позволяют получить какой либо результат, значение, так же метод может возвращать пустой результат. Состоит из начального символа «$», за которым следует идентификатор, за которым следует тело метода.
      Тело метода состоит из идентификатора, за которым следует символ левой скобки ("("), за которым следует необязательный список параметров, за которым следует символ правой скобки ("")".**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `$customer.getAddress()-(Получает адрес покупателя)
$purchase.getTotal()-(Получает итог покупки)
$page.setTitle( "My Home Page" )-(Дает странице заголовок)
$person.setAttributes( ["Strange", "Weird"] )-(Добавляет атрибуты)`+
      `\n\`\`\`\n\n---\n\n`+
      `## Директива #set `+
      `\n\n**Директива #set используется для установки значения ссылки. Значение может быть присвоено либо ссылке на переменную, либо ссылке на свойство:**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `#set( $people = "proper" )
#set( $customer.Behavior = $people )`+
      `\n\`\`\`\n\n---\n\n`+
      `## Директива #if/#elif/#elseif`+
      `\n\n**Директива #if позволяет выполнять действия при условии, что оператор if верен:**`+
      `\n\n**${this.$t('app.markdownTextarea.helperExample')}:**`+
      `\n\n\`\`\`\n`+
      `#if( $foo )-(Начало директивы #if)
  <strong>Velocity!</strong>-(При условии что переменная $foo = true, 
  выполняется данное действие)
#end-(Конец директивы #if)`+
      `\n\`\`\`\n\n---\n\n\n`
      return md.render(markdodwEample)
    },
  },
  methods:{
    show(){
      this.showDialog = true
    }
  }
}
</script>
