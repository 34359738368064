<template lang="pug">
  div.ma-2
    app-text
    v-row.app-cursor--pointer(v-for="task in equipmentTasks" :key="task.uuid" no-gutters @click="openCheck(task)")
      v-col(cols="auto")
        app-text {{task.title}} 
      v-col.ml-4
        app-text {{task.maintenanceDate}} 
          span.pl-4(:class="'task-status--' + task.status.name") {{task.status.title}}

    maintenance-check-dialog(v-model="dialog.saveCheck" :maintenanceTask="currentTask" @updateMDate="loadTasks()")
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { api } from '@/api'
import MaintenanceCheckDialog from '@/components/project/panel/left/maintenance/dialogs/MaintenanceCheck.vue'

export default {
  components: {
    MaintenanceCheckDialog,
  },

  data() {
    return {
      currentTask: null,
      dialog: {
        saveCheck: false,
      },
    }
  },

  watch: {
    selectedEquipment: 'loadTasks',
  },

  mounted() {
    this.loadTasks()
  },

  computed: {
    ...mapState('maintenance', ['selectedEquipment', 'equipmentTasks']),
    ...mapGetters('project', ['projectUuid']),
  },

  methods: {
    ...mapMutations('maintenance', ['setEquipmentTasks']),

    loadTasks() {
      if (this.selectedEquipment && this.selectedEquipment.uuid != null) {
        api.maintenance.loadTasks(this.selectedEquipment.uuid).then((data) => {
          this.setEquipmentTasks(data)
        })
      } else this.setEquipmentTasks([])
    },

    openCheck(task) {
      this.currentTask = task
      this.dialog.saveCheck = true
    },
  },
}
</script>

<style scoped>
.task-status--DONE {
  color: green;
}
.task-status--SKIP {
  color: red;
}
.task-status--PROGRESS {
  color: #cddd16;
}

.t-scroll-body {
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
  scrollbar-color: var(--v-primary-lighten4) var(--v-surface-lighten5);
}

.t-scroll-body::-webkit-scrollbar {
  width: 5px;
  background-color: var(--v-surface-lighten5);
}
.t-scroll-body::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-lighten4);
}

.h100p {
  height: 100%;
}
</style>
