import vuexStore from '@/store'
import { api } from '@/api'
import { projectService } from '@/_services'
import { fetchProjectUuid, fetchProjectHash } from './project.dataProvider'

export const getAxisEnum = () => vuexStore.getters['project/axisEnum']
export const getConditionOperators = () => vuexStore.getters['project/conditionOperator']

export async function fetchIfcGuidsByElementUuids(uuids) {
  if (!uuids?.length) {
    return Promise.resolve([])
  }
  let projectUuid = fetchProjectUuid()
  return api.axes.fetchIfcGUIDs(projectUuid, uuids)
}

export async function getIfcGlobalAttrId () {
  let projectUuid = fetchProjectUuid()
  let hashProject = fetchProjectHash()
  let params = await projectService.loadParamForCondition(projectUuid, 'IDPROP', '', hashProject)

  let item = params.find(({ attr }) => attr === 'ifcGlobalId')
  return item?.uuid
}
