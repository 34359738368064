<template lang="pug">
  div( style="height: 100%;")
    app-panel-header(v-if="title" :title="title" :menu="headerMenu" )

    axis-section( @menu="onAxisMenu" @addingaxisgroup="showAddingAxisDialog()" showShare style="height: 100%;")
      axis-section-treeview(v-if="!isAxisGroupsSelected")
      NextcloudConverting.mt-5(v-if="$app.version.isCloud")

    app-menu( ref="axisMenu" :menu="axisMenu" )

    axis-grouping-dialog( v-model="dialog.editingAxisData" :group.sync="axisDataInWIP" @edited="onEditingAxisData" )
    app-dialog-confirm( v-if="axisDataInWIP" v-model="dialog.deletingAxisData" delete @confirm="onDeletingAxisData") 
      app-text {{ $t('section.elementTree.delGroup', {group:  axisDataInWIP.title}) }}
    v-snackbar(v-model="dialog.snack" color="success" timeout="1000" bottom rounded absolute ) OK
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import { SourcePath } from '@app/SourcePath'

import AxisSection from './AxisSection'
import AxisSectionTreeview from './AxisSectionTreeview'
import AxisGroupingDialog from '../element/Grouping'
import NextcloudConverting from '@/components/project/panel/left/components/cloud/NextcloudConverting'
import { projectService } from '@/_services'

export default {
  name: 'AxisBlock',

  components: { 
    AxisSection,
    AxisSectionTreeview,
    AxisGroupingDialog,
    NextcloudConverting
  },

  props: {
    title: String,
    menu: Array
  },

  data: () => ({
    axisDataInWIP: null,
    dialog: {
      editingAxisData: false,
      deletingAxisData: false,
      snack: false,
    }
  }),

  computed: {
    ...mapState('axis', ['selectedAxisGroup']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('axis/tree', ['element']),
    ...mapGetters('axis', ['selectedAxis']),

    defaultMenu () {
      return [
        { title: this.$t('section.elementTree.menu.addGroup'), action: this.showAddingAxisDialog },
//        { title: this.$t('section.elementTree.menu.makeFloor'), action: this.makeFloor}
      ]
    },

    headerMenu() {
      return this.menu ? this.defaultMenu.concat(this.menu) : this.defaultMenu
    },

    axisMenu () {
      return [
        { title: this.$t('button.edit'), action: this.showEditingAxisDialog },
        { title: this.$t('button.delete'), action: this.showDeletingAxisDialog },
        { title: this.$t('button.export'), action: this.exportGroup },
      ]
    },

    isAxisGroupsSelected() {
      return this.selectedAxis.value === 5
    }
  },

  methods: {
    ...mapActions('axis', ['loadAxisDataList', 'deleteAxis']),
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),
    ...mapMutations('project', [ 'toggleIotAdmin']),
    ...mapMutations('axis', [ 'setSelectedAxisGroup']),

    showAddingAxisDialog () {
      this.axisDataInWIP = null
      this.dialog.editingAxisData = true
    },

    showEditingAxisDialog () {
      this.dialog.editingAxisData = true
    },

    showDeletingAxisDialog () {
      this.dialog.deletingAxisData = true
    },

    onAxisMenu (item, event) {
      this.axisDataInWIP = item.data
      this.$refs.axisMenu.show(event)
    },

    onEditingAxisData(uuid) {
      this.loadAxisDataList(uuid)
      this.dialog.editingAxisData = false
      this.$emit("oneditdialogclose")
    },

    onDeletingAxisData() {
      if( this.selectedAxisGroup?.value == this.axisDataInWIP.uuid ) {
        this.setSelectedAxisGroup(null)
      }
      this.deleteAxis(this.axisDataInWIP)
      this.dialog.deletingAxisData = false
    },

    exportGroup(){
      // window.open(SourcePath.exportGroup( { uuid: this.axisDataInWIP.uuid, title: this.axisDataInWIP.title }), '_blank')
      fetch (SourcePath.exportGroup( { uuid: this.axisDataInWIP.uuid, title: this.axisDataInWIP.title })).then(response => response.blob()).then(blob => {
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = this.axisDataInWIP.title + '.xlsx'
        
        link.click()
      })
    },

    makeFloor () {
      projectService.makeFloor(this.projectUuid).then(() => {
         this.dialog.snack= true
      })
    },

  }
  
}
</script>