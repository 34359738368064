<template lang="pug">
  app-dialog( v-model="$_show" :header="$t('rights.header.name')" )
    app-section.base-scroll-body.mh400
      div.mb-5(v-for="role in findFormRole()")
        app-text.mb-1.text-subtitle-1.primary--text.font-weight-bold {{ role.title }}
        div.pl-2(v-for="functionGroup in role.children")
          app-text.text-subtitle-2.mt-1 {{ $t(functionGroup.title) }}
          div.pl-3(v-for="roleFunction in functionGroup.children")
            app-text.text-subtitle-3 {{ roleFunction.title }}
            div.pl-3(v-for="role in roleFunction.controlRole")
              app-text.text-subtitle-3 - {{ role }}
    
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapGetters } from 'vuex';

export default {
  name: 'rule-dialog',

  mixins: [DialogToggable],

  props: {
    formRole: null,
  },

  computed: {
    ...mapGetters('roles', ['roles']),
    ...mapGetters('corp', {orgRoles:'roles'})
  },

  methods: {
    findFormRole() {
      if(!this.formRole) return []
      const rolesWithFunctions = this.formRole.roles.map(role => this.roles.find(functionRole => functionRole.uuid === role.uuid) ).concat(this.formRole.orgRoles.map(role => this.orgRoles.find(functionRole => functionRole.uuid === role.uuid)))
      const _rolesWithFunctions = rolesWithFunctions.filter(item => item !== undefined)  
      
      const tree = _rolesWithFunctions.map(roleWithFunction => ({
        title: roleWithFunction.title,
        children: roleWithFunction.rolefunction.filter(item => item.functionType !== null).map(item => ({
          title: item.functionType.title,
          group: item.functionType.group,
          controlRole: item.roleUuids?.map(role => this.roles.concat(this.orgRoles).find(r => r.uuid === role)?.title) ?? []
        })),
      }))
      
      tree.forEach(role => {
        const dist = {}

        role.children.forEach(rolefunction => {
          dist[rolefunction.group] ||= []
          dist[rolefunction.group].push(rolefunction)
        })

        role.children = Object.entries(dist).map(([key, value]) => ({
          title: key === "ARCHIVE" ? " " : "roleFunction.group" + "." + key, // временно пока не уберут ARCHIVE 
          children: value,
        }))
      })

      return tree
    }
  }
}
</script>

<style scoped>
.mh400 {
  height: 400px;
  overflow-x: hidden;
}

</style>