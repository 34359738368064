<template lang="pug">
div.d-flex.align-center.flex(:class='{ "pointer": $listeners.click }' v-on='$listeners' test-id="task-progress-bar" )
  div.progress.flex.mr-1
    v-progress-linear.flex( :value='task.progress' height='3' color='var(--v-primary-lighten4)' background-color='var(--v-primary-lighten5)' )
  
  span.caption {{ task.progress + '%' }}

</template>

<script>
export default {
  name: 'TaskProgressBar',

  props: {
    task: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
  .progress {
    border: 1px solid var(--v-primary-lighten4);
  }
</style>