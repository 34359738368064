<template lang="pug">
  app-panel( ident="blueprintslist" left flex width="465" ref="blueprints" )
    task-header( :title='$t(panelHeader)' headerItemsCenter mainTitle )
      template( v-slot:headerBefore v-if='store.selectedDrawingPage' )
        v-btn( small icon @click='backToPageSelecting' )
          app-icon-action( icon="mdi-arrow-left" ) 
        //- Заглушка невидимой иконкой
        app-icon-action( v-if='!store.selectedDrawingPage' icon="mdi-arrow-left" size='28' ) 
      template( v-slot:headerAfter )

        //- Панель Управления
        .d-flex( v-if='store.selectedDrawingPage' )
          v-btn.mr-1( icon small @click='hidePanelWithPages' )
            app-icon-action( icon="mdi-format-list-bulleted" )
            
          v-menu( bottom nudge-bottom='26px' :nudge-left='isActualVersionChoose? "52px": "92px"' open-on-hover )
            template( v-slot:activator='{on, attrs }' )
              v-btn( v-bind="attrs" v-on='on' small icon )
                app-icon-action( icon="mdi-information" ) 
            .version-information
              .non-actual-version-row( v-if="!isActualVersionChoose" v-html="$t('module.drawing.nonActualVersion', {number: store.selectedDrawingPage.version.versionNum, date: getPrettyDate(store.selectedDrawingPage.version.createDate)})" )
              .d-flex.align-center.px-3.py-1.justify-center( v-html="$t('module.drawing.actualVersionDrawing', {number: store.selectedDrawingPage.drawing.currentVersion.versionNum, date: store.selectedDrawingPage.drawing.currentVersion.createDate})" )

        //- Поисковая строка
        div.search-input-container( v-else )
          input.search-input( @input='drawingSearch' )
          v-icon.magnify mdi-magnify
        app-icon-button.ml-1( icon="mdi-close" @click="handleClose" )
    .t-scroll-body.mt-2
      drawings-by-models( ref="drawings" )
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TaskHeader from '@/components/task/TaskHeader'
import DrawingsByModels from '@/components/drawings/DrawingsByModels.vue'
import { useDrawingsStore } from '@/pinia'

export default {
  name: "DrawingsList",

  components: { 
    TaskHeader, 
    DrawingsByModels
  },

  data() {
    return {
      selectedPreviewPage: null,
      searchTimeout: null,
    }
  },

  computed: {
    ...mapGetters('project', ['projectUuid']),
    
    store: () => useDrawingsStore(),

    panelHeader() {
      if (this.store.selectedDrawingPage){
        return this.store.selectedDrawingPage.drawing.name
      }
      return "module.drawing.drawings"
    },

    isActualVersionChoose() {
      return this.store.selectedDrawingPage.version.uuid == this.store.selectedDrawingPage.drawing.currentVersion.uuid
    }
  },

  beforeMount() {
    this.loadGroupsWithPages(this.projectUuid)
    this.store.setDrawingsPanel(this)
  },

  beforeDestroy() {
    if (this.$parent._isDestroyed) {
      this.store.setPageForRecovery(null)
      this.store.selectedDrawingPage = null
    }
    else {
      this.store.setPageForRecovery(this.store.selectedDrawingPage)
    }
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab']),

    loadGroupsWithPages() {
      return this.store.loadDrawings(this.projectUuid).then(() => {
        this.$root.$emit('pagesIsLoading')
      })
    },

    handleClose() {
      this.setActiveGlobalTab('')
    },

    hidePanelWithPages() {
      this.store.sidePanelVisible = this.$refs.drawings.hideSidePanel()
    },

    backToPageSelecting() {
      this.store.selectedDrawingPage = null
    },

    scrollDrawingPageToComment(comment) {
      const drawingByModels = this.$refs.drawings
      if (!drawingByModels) {
        this.$root.$on('drawingsByModelsInit', rootDrawingsByModels => {
          rootDrawingsByModels.scrollPageToComment(comment)
          this.$root.$off('drawingsByModelsInit')
        })
      }
      else {
        drawingByModels.scrollPageToComment(comment)
      }
    },

    drawingSearch(event) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(() => {
          this.store.setSearchFilter(event.target.value)
          this.searchTimeout = null
        }, 750)
      }
      else {
        this.searchTimeout = setTimeout(() => {
          this.store.setSearchFilter(event.target.value)
          this.searchTimeout = null
        }, 750)
      }
    },
    
    getPrettyDate(date) {
      let prettyDate = new Date(date)
      let month = prettyDate.getUTCMonth() + 1
      month = month < 10 ? "0" + month : month
      return prettyDate.getUTCDate() + "." + month + "." + prettyDate.getUTCFullYear()
    }
  }
}
</script>

<style scoped>
.t-scroll-body {
  overflow-y: hidden;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
  position: relative;
}
.version-information{
  color: #262626;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
  border-radius: 3px;
  font-size: 12px;
  width: auto;
  text-align: center;
  padding: 0px 4px;
}
.non-actual-version-row{
  color: #EB5757;
  font-weight: bold;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 4px 12px 4px 12px;
  border-bottom: 1px solid #C4C4C4;
}
.search-input {
  background-color: var(--v-surface-base);
  border: 1px solid var(--v-surface-darken2);
  color: var(--v-primary-darken2);
  outline: none;
  width: 240px;
  border-radius: 3px;
  padding: 0px 32px 0px 6px;
}
.search-input-container{
  position: relative;
}
.magnify{
  position: absolute;
  right: 4px;
  color: var(--v-surface-lighten3);
}
</style>

<style>
#blueprintslist{
  max-height: 100% !important;
}
</style>