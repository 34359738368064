<template lang="pug">
.comments-panel.elevation-2
  .no-one-comment.px-3( v-if='store.selectedDrawingPage.attachments == 0' ) {{ $t('module.drawing.addComment.noOneComment') }}
  DrawingCommentCard( v-else )
</template>

<script>
import { useDrawingsStore } from '@/pinia';
import DrawingCommentCard from '@/components/drawings/comments/DrawingCommentCard.vue';

export default {
  name: "DrawingsCommentsPanel",

  components: {
    DrawingCommentCard
  },

  computed: {
    store: () => useDrawingsStore(),
  },
}
</script>

<style scoped>
.comments-panel{
  color: #505254;
  width: 340px;
  background: white;
  border-radius: 3px;
}
</style>