<template lang="pug">
  app-panel(ident="locationcontrol" left v-bind="$attrs") 
    app-section
      .inline
        h4.h-workers-style {{$t('section.locationControl.keeper')}} 
      .inline.sim
        h6.h-style sim        
      div(v-for="keeper in keepers")
        .font-style.inline.size-keepers(:id="keeper.id" :style="selectedKeeperId == keeper.id ? 'background-color: darkgray !important' : 'background-color: gray !important'" @click="selectKeeper(keeper.id)") 
          span {{ keeper.name }}
        .inline
          v-switch(dense  @change="startStopSimulation(keeper.id)")


      h4.h-style {{$t('section.locationControl.anchor')}} 
      div(v-for="anchor in anchors")
        .font-style(:style="selectedAnchorId == anchor.id ? 'background-color: darkgray !important' : 'background-color: gray !important'" @click="selectAnchor(anchor.id)") 
          span() {{ anchor.properties.label }}

      h4.h-style {{$t('section.locationControl.area')}} 
      div(v-for="area in areas")
        .font-style(:style="selectedAreaId == area.id ? 'background-color: darkgray !important' : 'background-color: gray !important'" @click="selectArea(area.id)") 
          span() {{ area.properties.name }}


</template>

<script>
  import { api } from '@/api'
  // import { LocationControlBimAnnotations } from '@/components/project/panel/left/locationControl/locationControlBimAnnotations'
  import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
  import { ReadableGeometry, buildBoxGeometry } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'
  import { ObjectsDrawable }  from '@/plugins/xeokit/LocationControlObjectsDrawable/ObjectsDrawable'
  import { useSelectedElementsStore } from '@/pinia'
  import { mapState } from 'pinia'
  import { mapActions} from 'vuex'

  let objectsDrawable = new ObjectsDrawable
  export default {
    name: 'LocationControl',

    data () {
      return {
        counterAreas: 0,
        counterKeepers: 0,
        pointPosAnnotation: [],
        locationControlAnnotation: [],
        clickedModel: null,
        pickedElementUuid: null,
        pickedKeeper: null,
        employees: [],
        anchors: [],
        maps: [],
        reservedMap: {},
        dangerZones: [],
        areas:[],
        keepers: [],
        keepersId: [],
        keepersMovement: [],

        selectedKeeperId: null,
        selectedAnchorId: null,
        selectedAreaId: null,

        // блок с мусором - разгрести
        boxGeometry: new ReadableGeometry(XeokitMediator.viewer.scene, buildBoxGeometry({
          xSize: 0.1,
          ySize: 0.1,
          zSize: 0.1
        })),

        polygon1: [],
        polygon2: [],
        polygon3: [],
        polygon4: [],

        areaId: null,
        zoneEntered: null,
        inArea: [],

        simulations: new Map(),
        enteredAreaKeepers: new Map(),
        keepersMovementSubscribes: new Map(), 
      }
    },

    computed: {
      ...mapState(useSelectedElementsStore, ['pickedElement', 'selectedKeeper', 'selectedBoxElements'])
    },

    methods: {
      ...mapActions('locationControl',['getMaps', 'getReservedMap', 'getReportsDangerZone','getAreasForMap','getAnchorsForMap','getKeepers']),

      startStopSimulation(id) {
        if (!this.simulations) return
        if (this.simulations.get(id))  {
          clearInterval(this.simulations.get(id))
          this.simulations.delete(id)
          this.keepersMovementSubscribes.set(id, setInterval(this.loadKeeperAndSetPosition, 1800, id))
          return
        }
        clearInterval(this.keepersMovementSubscribes.get(id))

        let intervalId = setInterval(this.positionGenerator, 1000, id)
        this.simulations.set(id, intervalId) 
      },

      getRandomInt (max) {
        return Math.floor(Math.random() * max);
      },
      
      // drawAnnotation() {
        //по поводу размера метки - можно попробовать поставить слушателя и при изменении приближения менять размер

      //   for(let i = 0; i < this.counterKeepers; i++) {
      //     LocationControlBimAnnotations.showLocationControlBimAnnotation(this.locationControlAnnotation[i], this.pointPosAnnotation[i], this.keepers[i].name)
      //   }
      // },

      positionGenerator(id) {
        // LocationControlBimAnnotations.clearLocationControlBimAnnotation(this.locationControlAnnotation[id - 1], this.pointPosAnnotation[id - 1])

        let coords = [this.getRandomInt(objectsDrawable.getPosition(id)[0] + 5) + 1, this.getRandomInt(objectsDrawable.getPosition(id)[1] + 5) + 1, 11]
        this.setPosition(id, coords)

        this.keepersMovement.splice(0)
        this.keepersMovement.push(coords)

        this.pointPosAnnotation[id - 1]  = this.keepersMovement[0] ? this.keepersMovement[0] : this.pointPosAnnotation[id - 1]
        this.drawAnnotation(id)

      },

      setPosition(id, coords) {
        let newPos = coords
        objectsDrawable.setPosition(id, newPos) 
        if (this.determineDefinitionPointToPlane(this.polygon1[0], this.polygon1[1], this.polygon2[0], this.polygon2[1], this.polygon3[0], this.polygon3[1], this.polygon4[0], this.polygon4[1], newPos,  XeokitMediator.viewer.scene.objects[this.areaId].position[2], coords[2])) {
          if (this.enteredAreaKeepers.get(this.areaId).length == 0) {
            if (!this.zoneEntered)
              this.zoneEntered = setInterval(this.colorChanger, 100, this.areaId)
          }
          let index = this.enteredAreaKeepers.get(this.areaId).indexOf(id)
          document.getElementById(id).style = 'background-color: #E32636 !important'
          if (index == -1) { 
            this.enteredAreaKeepers.get(this.areaId).push(id)
            this.inArea.push(id)
          }
        } else {
          let index = this.enteredAreaKeepers.get(this.areaId).indexOf(id)
          if (index != -1) {
            this.enteredAreaKeepers.get(this.areaId).splice(index, 1)
            this.inArea.splice(index, 1)
            document.getElementById(id).style = 'background-color: gray !important'
            
            if (this.enteredAreaKeepers.get(this.areaId).length <= 0) {   
              clearInterval(this.zoneEntered)
              this.zoneEntered = null
              objectsDrawable.setDiffuse(this.areaId, [1, 1, 0])
            }
          }
        }
      },

      colorChanger(id) {
        if (objectsDrawable.getDiffuse(id)[1] == 1){
          objectsDrawable.setDiffuse(id,[1, 0, 0] )
        }
        else {
          objectsDrawable.setDiffuse(id,[1, 1, 0])
        }
      },

      selectKeeper(id) {
        let i = id - 1
        this.keepers.forEach(keeper => {
          objectsDrawable.setDiffuse(this.selectedKeeper.head[keeper.id - 1], [1, 1, 0])
          objectsDrawable.setDiffuse(this.selectedKeeper.body[keeper.id - 1], [0.07, 0.74, 0.07])
        })

        if(this.pickedKeeper == id) {
          objectsDrawable.setDiffuse(this.selectedKeeper.head[i], [1, 1, 0])
          objectsDrawable.setDiffuse(this.selectedKeeper.body[i], [0.07, 0.74, 0.07]) 
          this.pickedKeeper = null
          this.selectedKeeperId = null
        } else {
          this.pickedKeeper = id 
          this.selectedKeeperId = id
          objectsDrawable.setDiffuse(this.selectedKeeper.head[i], [1, 0, 0])
          objectsDrawable.setDiffuse(this.selectedKeeper.body[i], [1, 0, 0]) 
        }
      },

      selectAnchor(label) {
        if (this.selectedAnchorId != null) objectsDrawable.setDiffuse(this.selectedAnchorId, [0, 1, 0])
     
        if (label == this.selectedAnchorId) {
          objectsDrawable.setDiffuse(this.selectedAnchorId, [0, 1, 0])
          this.selectedAnchorId = null
          return
        }

        objectsDrawable.setDiffuse(label, [1, 0, 0])
        this.selectedAnchorId = label
      },

      selectArea(id) {
        objectsDrawable.setDiffuse(id, [0, 0, 0])
        if(this.selectedAreaId !== id) {
          objectsDrawable.setDiffuse(id, [1, 0, 0])
          objectsDrawable.setOpacity(id, 0.9)
          this.selectedAreaId = id
        } else {
          objectsDrawable.setDiffuse(id, [1, 1, 0])
          objectsDrawable.setOpacity(id, 0.5)
          this.selectedAreaId = null
        }
      },

      determineDefinitionPointToPlane(point1, point2, point3, point4, point5, point6, point7, point8, determinedPoint, areaHeight, keeperHeight) {
        if (!this.determineDefinitionPointToStraight(point1, point2, determinedPoint)) return false
        if (!this.determineDefinitionPointToStraight(point3, point4, determinedPoint)) return false
        if (this.determineDefinitionPointToStraight(point5, point6, determinedPoint)) return false
        if (this.determineDefinitionPointToStraight(point7, point8, determinedPoint)) return false
        if (!(keeperHeight - areaHeight <= 5 && keeperHeight - areaHeight >= 0)) return false 
        return true;
      },

      loadKeeperAndSetPosition(id) {
        api.locationControl.getKeeperById(id).then(keeper => {
          // let index = keepers.items.findIndex(keep => keep.id == id)
          // let keeper = null
          // if (index != -1) keeper = keepers.items[index]  
          //   console.log("bebebe", keeper)

          if (keeper && keeper._embedded && keeper._embedded.tag && keeper._embedded.tag.coords_type == 'LOCAL' &&
          keeper._embedded.tag.x != null && keeper._embedded.tag.y != null && keeper._embedded.tag.z != null) {
            let position = [keeper._embedded.tag.x, keeper._embedded.tag.y, keeper._embedded.tag.z + 1.5]
            this.pointPosAnnotation[id - 1] = position
            this.setPosition(id, position)
            this.keepersMovement.splice(0) 
            // for(let i = 1; i <= id; i++) {
            //   LocationControlBimAnnotations.clearLocationControlBimAnnotation(this.locationControlAnnotation[id], this.keepersMovement[i]) //проверить номера элементов массива 
            //   this.drawAnnotation(i)
            // }
          }
        })
      },
      
      determineDefinitionPointToStraight(point1, point2, determinedPoint) {
        return (((determinedPoint[0] - point1[0] + 0.001) / (point2[0] - point1[0] + 0.001)) - ((determinedPoint[1] - point1[1] + 0.001) / (point2[1] - point1[1] + 0.001))) <   0
      }
    },

    mounted() {
      this.getMaps() 
      this.getReservedMap() 
      this.getReportsDangerZone()
      this.getAreasForMap().then(areas => {
        this.areas = areas.features
        areas.features.forEach(area => {

          let geometry = area.geometry
          let absoluteHeight = this.areas[this.counterAreas].properties.z ? this.areas[this.counterAreas].properties.z : 0
          let relativeHeight = this.areas[this.counterAreas].properties.site_fk ? this.areas[this.counterAreas].properties.site_fk : 5
          this.counterAreas++

          if (geometry.type == "Polygon") {         
            geometry.coordinates.forEach(points => {

                this.polygon1 = points.slice(0, 2) 
                this.polygon2 = points.slice(1, 3) 
                this.polygon3 = points.slice(2, 4) 
                this.polygon4 = points.slice(3, 5) 

                let length = Math.sqrt(Math.pow(Math.abs(this.polygon1[0][0]) - Math.abs(this.polygon1[1][0]), 2) + Math.pow(Math.abs(this.polygon1[0][1]) - Math.abs(this.polygon1[1][1]), 2))
                let width = Math.sqrt(Math.pow(Math.abs(this.polygon2[0][0]) - Math.abs(this.polygon2[1][0]), 2) + Math.pow(Math.abs(this.polygon2[0][1]) - Math.abs(this.polygon2[1][1]), 2))
                
                this.areaId = area.id
                objectsDrawable.drawCube(
                  area.id,
                  true,
                  [points[0][0], points[0][1], absoluteHeight],
                  [0.5, 0.5, 0.5],
                  [length / 2, width / 2, relativeHeight / 2],
                  [0, 0, 0],
                  0.5, 
                  [1, 1, 0], 
                  length,
                  width,
                  relativeHeight,
                )
                
                this.enteredAreaKeepers.set(area.id, [])
            })
          }
        }) 

      })

      this.getAnchorsForMap ().then(anchorsForMap => {
        this.anchors = anchorsForMap.features
        anchorsForMap.features.forEach(anchor => {
          objectsDrawable.drawCube(
            anchor.id,
            true,
            [anchor.geometry.coordinates[0], anchor.geometry.coordinates[1], anchor.properties.z],
            [1, 3, 1],
            [0, 0, 0],
            [90, 0, 0],
            1,
            [0, 1, 0],
            0.1,
            0.1,
            0.1,
          )
        })
      })
      
      this.getKeepers().then(keepers => {
        this.keepers = keepers.items
        keepers.items.forEach(keeper => {
          if (keeper._embedded && keeper._embedded.tag && keeper._embedded.tag.coords_type == 'LOCAL') {
            this.counterKeepers++
            this.keepersId.push(keeper.id)
            objectsDrawable.drawKeepers(
              keeper.id,
              true,
              [keeper._embedded.tag.x, keeper._embedded.tag.y, keeper._embedded.tag.z],
              [1, 1, 1],
              [90, 0, 0],
              [1, 1, 0],
            )

            // api.locationControl.getTagById(keeper._embedded.tag.id).then(tag => console.log("TAG", tag))
            let value = setInterval(this.loadKeeperAndSetPosition, 1800, keeper.id)
            this.keepersMovementSubscribes.set(keeper.id, value)

            this.pointPosAnnotation.push([keeper._embedded.tag.x, keeper._embedded.tag.y, keeper._embedded.tag.z])
            this.locationControlAnnotation.push(
              { uuid: "_locationControl" +  keeper.id },
            )
            this.drawAnnotation(keeper.id)
          } else { 
            this.counterKeepers++
            let randX = this.getRandomInt(0)
            let randY = this.getRandomInt(0)

            this.keepersId.push(keeper.id)

            objectsDrawable.drawKeepers(
              keeper.id,
              true,
              [randX, randY, 10],
              [1, 1, 1],
              [90, 0, 0],
              [1, 1, 0],
            )

            let value = setInterval(this.loadKeeperAndSetPosition, 1800, keeper.id)
            this.keepersMovementSubscribes.set(keeper.id, value)

            this.pointPosAnnotation.push([randX, randY, 10])
            this.locationControlAnnotation.push(
              { uuid: "_locationControl" +  keeper.id },
            )
            this.drawAnnotation(keeper.id)
          }
        })
      })
      
      // LocationControlBimAnnotations.checkAnnotationSize(this.pointPosAnnotation)
    },

    watch: {
      pickedElement: function () {
        this.pickedElementUuid = this.pickedElement

        if(this.clickedModel) {
          this.clickedModel = false
          this.selectedKeeperId = null
          this.selectedAreaId = null
          this.selectedAnchorId = null
          return 
        }

        if(this.selectedKeeper.head.some(el => el == this.pickedElementUuid)) {
          this.selectedKeeperId = this.selectedKeeper.head.indexOf(this.pickedElementUuid, 0) + 1
          this.clickedModel = true
        }
        
        if(this.selectedKeeper.body.some(el => el == this.pickedElementUuid)) {
          this.selectedKeeperId = this.selectedKeeper.body.indexOf(this.pickedElementUuid, 0) + 1
          this.clickedModel = true
        }

        if(this.selectedBoxElements.some(el => el == this.pickedElementUuid)) {
          this.selectedAreaId = this.pickedElementUuid
          this.selectedAnchorId = this.pickedElementUuid
          this.clickedModel = true
        }
      },
    },

    beforeDestroy() {
      this.anchors.forEach(anchor => {
        objectsDrawable.callDestroy(anchor.id)
      }) 
      this.keepers.forEach(keeper => {
        objectsDrawable.callDestroy(keeper.id)
        objectsDrawable.callDestroy(this.selectedKeeper.head[keeper.id - 1])
        objectsDrawable.callDestroy(this.selectedKeeper.body[keeper.id - 1])
      })
      if (this.areaId != null) {
        objectsDrawable.callDestroy(this.areaId)        
      }
      if (this.zoneEntered != null) { 
        clearInterval(this.zoneEntered)
      }

      this.simulations.keys().forEach(key => {
        clearInterval(this.simulations.get(key))
      })
      this.simulations = new Map()

      this.keepersMovementSubscribes.keys().forEach(key => {
        clearInterval(this.keepersMovementSubscribes.get(key))
      })
      this.keepersMovementSubscribes = new Map()
      this.selectedKeeper.head.splice(0, this.selectedKeeper.head.length)
      this.selectedKeeper.body.splice(0, this.selectedKeeper.body.length)
      // LocationControlBimAnnotations.clearLocationControlBimAnnotation()
      // LocationControlBimAnnotations.unbindEvents()
    }
  }
</script>

<style>
/* это стиль для меток */
.annotation-marker22 {
  opacity: 0.5;
  width: 50px; 
  height: 150px;
  clip-path: polygon(50% 50%, 100% 50%, 50% 100%, 0% 50%);
  color: #ffffff;
  line-height: 1.8;
  text-align: center;
  font-family: "monospace";
  font-weight: bold;
  position: absolute;
  border: 2px solid #ffffff;
  background: black;
  visibility: hidden;
  padding-top: 4px;
  z-index: 1 !important;
  font-size: 13px;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: -94px;
  /* margin-left: -6px; */
}
</style>

<style lang="scss" scoped>
.font-style {
  border-radius: 5px;
  margin: 0.6vh;
  padding: 0.6vh;
  color: gainsboro;
  cursor: pointer;
}
.h-style {
  color: rgb(113, 113, 113);
}

.h-workers-style {
  margin-left: -5px;
  color: rgb(113, 113, 113);
}

.inline {
  display: inline-block;
  padding-left: 5px
}
.size-keepers {
  width: 80%
}
.sim-setting {
  float: right;
  font-size:5px
}
.sim {
  float: right;
  padding-right: 37px;
  padding-top: 0.6vh;

}
</style>