<template lang='pug'>
div.task-tree-row-executor
  div.d-flex.align-center.task-tree-row-executor-width( v-if='taskHasOnlyWorkers' )
    //- v-icon(:color='getBgColor(task.workers[0].uuid).color') mdi-account-circle
    app-avatar( :url="filePath" :colorObj="getBgColor(getResponder.uuid)" :text="getResponder.name" )
    div.ml-1.name-worker {{ getResponder.name }} 
    div ({{ countTaskWorkers }})
    v-btn.ml-1( x-small icon @click='showTeam($event)')
      v-icon( color='#4D4D4D') mdi-pencil
  div.d-flex.align-center.task-tree-row-executor-width( v-else-if='taskHasGroup' )
    div.ml-1.name-worker {{ getGroupResponder.title }} 
    div ({{ countTaskWorkers }})
    v-btn.ml-1( x-small icon @click='showTeam($event)' )
      v-icon( color='#4D4D4D' ) mdi-pencil  
  div.add-button( v-else-if="hasTaskAppointment" )
    app-button-add( :text="$t('section.structure.model.addTeam')" :style='{width: "100%"}' @click='showTeam($event)' )
  
  task-appointed-section( ref="task-appointed" :selectedTask="task" :onlyDialogs='true' )
</template>

<script>
import { Task } from '@/assets/model/task/Task'
import { SourcePath } from '@app/SourcePath'
import uniqolor from 'uniqolor'
import TaskAppointedSection from '@/components/task/taskDetailsSections/TaskAppointedSection'
import { mapGetters } from 'vuex'

export default {
  name: "TaskTreeRowExecutor",
  
  components: { TaskAppointedSection },

  props: {
    task: Task
  },

  computed: {
    ...mapGetters('projectPermissions', ['hasTaskAppointment']),

    getResponder() {
      return this.task.responder ?? this.task.workers[0]
    },

    getGroupResponder() {
      return this.task.groupResponder ?? this.task.groupWorkers[0] 
    },

    filePath () {
      return SourcePath.avatar(this.getResponder.avatar) ?? null
    },

    countTaskWorkers(){
      return this.task.workers.length + this.task.groupWorkers.length
    },

    taskHasOnlyWorkers() {
      return this.task.workers.length && !this.task.groupResponder
    },

    taskHasGroup() {
      return (!this.task.workers.length && this.task.groupWorkers.length) || this.task.groupResponder
    }
  },

  methods: {
    showTeam(event) {
      this.$refs['task-appointed'].showWorkersDialog(event)
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },
  }
}
</script>

<style lang='scss' scoped>
  .task-tree-row-executor {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 140px;
  }
  .expand-btn {
    background: #E5E5E5;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
  }

  .add-button {
    width: 100%;
    min-width: 140px;
  }
  .name-worker{
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    width:50%;
  }
  .task-tree-row-executor-width{
    width: 100%
  }
</style>