<template lang="pug">
.mx-2
  .bg-palette
    .primary--text.text--lighten-2.align-self-center.pa-2( v-if="!groupId" ) Выберите группу
    div(v-else)
      .b-bottom.py-3
        GroupPaletteItem(v-if="palette" :item="palette")

      .adding-color_fullheight.overflow-y-auto.b-bottom
        .pt-2(v-for="item in paletteItems" :key="item.uuid" )
          GroupPaletteItem(:item="item" @click="showElement(item)" :is-selected="element && element.key === item.key"  is-pointer)

      GroupPaletteItemSelected(v-if="element" :elementSelected="element")
      GroupPaletteButtons(v-else)
</template>

<script>
import { useGroupPaletteListState } from '@/states/groupPalette/groupPaletteList.state'
import { useGroupsWindowAssembly } from '@/states/groups/groupsWindow.assembly'

const assembly = useGroupsWindowAssembly()

export default {
  props: {
    groupId: String,
  },

  components: {
    GroupPaletteItem: () => import('./GroupPaletteItem.vue'),
    GroupPaletteButtons: () => import('./GroupPaletteButtons.vue'),
    GroupPaletteItemSelected: () => import('./GroupPaletteItemSelected.vue'),
  },

  data: () => ({ 
    element: null
  }),

  watch: {
    groupId (value) {
      if (value !== this.store.palette)
        this.element = null
    },
  },

  mounted() {
    this.element = assembly.selectedElement
    this.store.resetPalette()
  },
  
  computed: {
    store: () => useGroupPaletteListState(),
    
    palette () {
      return this.store.fetchGroupPaletteList(this.groupId)
    },

    paletteItems () {
      return this.palette?.paletteItems
    },
  },

  methods: {
    showElement (element) {
      if (this.element && this.element.key === element.key) this.element = null
      else this.element = element
    },

  },

}
</script>

<style>
.b-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.adding-color_fullheight {
  /* height: 200px; */
  height: 150px;
  overflow-x: hidden ;
}

.bg-palette {
  background: #f0f0f0;
  border-radius: 4px;
  height: 260px;
}

.close-icon {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #303030;
  position: relative;
  cursor: pointer;
}

.line {
  position: absolute;
  width: 80%;
  height: 1px; 
  background-color: black; 
  top: 50%;
  left: 10%;
  transform: translateY(-50%); 
}

.line:first-child {
  transform: translateY(-50%) rotate(45deg); 
}

.line:last-child {
  transform: translateY(-50%) rotate(-45deg); 
}
</style>