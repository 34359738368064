<template lang="pug">
div  
  .table-top.py-1.d-flex.justify-space-between
    .left-container
      .align-self-center(@click="openTableAction")
        app-icon-button( icon="$task-menu-icon" iconSize="16")
      span.header-text.ml-2 {{ $tc('section.grouping.tableTitle') }}

    .right-container
      app-toolbar( horizontal )
        app-toolbar-button( icon="mdi-format-color-fill" iconSize="22" :title="$t('section.groupingTable.toolbar.colorGrouping')" @click="showGroupColorSetting" )
        app-toolbar-button( icon="mdi-view-column" iconSize="22" :title="$t('section.groupingTable.columnSetting')" @click="openColumnSettingsDialog")
        app-toolbar-button( icon="settings" iconSize="20" :title="$t('section.elementTree.editGrouping')" @click="editingAxisData = true")

  app-menu(ref="actionMenu" :menu="groupTableActions")
  
  group-palette-window( v-if="isPaletteDialogVisible" :group-uuid="groupUuid" )
  axis-grouping-dialog( v-model="editingAxisData" :group="selectedAxis" @edited="onEditingAxisData" )
  column-settings-dialog( ref="columnSettingsDialog" @addColumn="openAvailableColumnsDialog")
  available-columns-dialog( ref="availableColumnsDialog" )
  
  app-dialog-confirm(v-model="confirmReport" @confirm="store.createReport()")
    app-text {{ $t('section.groupingTable.report.confirm') }}
</template>

<script>
import { useAxisGroupTableStore } from '@/pinia'
import AvailableColumnsDialog from './AvailableColumnsDialog.vue'
import AxisGroupingDialog from '../Grouping.vue'
import ColumnSettingsDialog from './GroupingListColumnSettingsDialog.vue'
import GroupPaletteWindow from '@/views/GroupPaletteWindow/GroupPaletteWindow.vue'

import { showDialogToEditGroupPalette } from '@/interactors/groupsWindow.interactor'
import { useGroupsWindowAssembly } from '@/states/groups/groupsWindow.assembly'

const groupsAssebly = useGroupsWindowAssembly()

export default {
  components: {
    AxisGroupingDialog,
    ColumnSettingsDialog,
    AvailableColumnsDialog,
    GroupPaletteWindow,
  },

  data: () => ({
    editingAxisData: false,
    confirmReport: false,
  }),

  computed: {
    store: () => useAxisGroupTableStore(),

    isPaletteDialogVisible: () => groupsAssebly.isPaletteDialogVisible,
    groupUuid: () => groupsAssebly.groupUuid,

    groupTableActions() {
      return [
        { title: this.$t('plugin.table.export'), action: () => (this.confirmReport = true) },
        // { title: this.$t('group.table.exportBCF'), action: () => console.log('asdasd') }
      ]
    },

    selectedAxis() {
      return this.store.selectedAxisGroupTable ? this.store.selectedAxisGroupTable.data : null
    },
  },

  methods: {
    showGroupColorSetting() {
      showDialogToEditGroupPalette(this.store.selectedAxisGroupTable.value)
    },

    openAvailableColumnsDialog() {
      this.$refs.availableColumnsDialog.show()
    },

    openTableAction(event) {
      this.$refs.actionMenu.show(event)
    },

    openColumnSettingsDialog() {
      this.$refs.columnSettingsDialog.show()
    },

    onEditingAxisData(uuid) {
      this.store.loadAxisDataList(uuid)
      this.store.getAxisTreeFromTable(this.store.selectedAxisGroupTable.value).then(() => {
        this.$emit('updateAxisList')
      })
      this.editingAxisData = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-top {
  background-color: var(--v-surface-base);
}

.left-container {
  display: flex;
  justify-content: flex-start;
}

.right-container {
  display: flex;
  justify-content: flex-end;
}

.header-text {
  font: normal 14px/32px $roboto;
  color: var(--v-primary-darken2);
}
</style>
