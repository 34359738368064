import { defineStore } from 'pinia'

export const useGroupsAxisFormModalState = defineStore('groups-axis-form-modal-state', {
  state: () => ({
    axisData: null,
    isVisible: false,
  }),

  actions: {
    setupAxisData (data) {
      this.axisData = data
      setTimeout(() => {
        this.isVisible = true
      }, 100)
    },

    refetchGroup (uuid) {
      console.log('🆑', this.name, uuid)
    },
  }
})
