var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-2",class:_vm.checkTasksExist()},[_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"align-self-center"},[_c('app-icon-action',{staticClass:"menu",attrs:{"icon":"$task-menu-icon","color":"var(--v-primary-darken2)","size":"16","test-id":"menu-btn"},on:{"click":_vm.openMenu}})],1),_c('span',{staticClass:"header-text ml-2"},[_vm._v(_vm._s(_vm.$t('section.collision.attrTable.header')))])]),(_vm.taskId)?_c('div',{staticClass:"middle-container"},[_c('v-btn',{attrs:{"x-small":"","color":_vm.getColorCollision},on:{"click":function($event){return _vm.changeActiveItems(false)}}},[_vm._v(_vm._s(_vm.$t('section.collision.title')))]),_c('v-btn',{staticClass:"ml-1",attrs:{"x-small":"","color":_vm.getColorTask},on:{"click":function($event){return _vm.changeActiveItems(true)}}},[_vm._v(_vm._s(_vm.$t('module.task.inTask')))])],1):_vm._e(),_c('div',{staticClass:"right-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleAllGroup(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"var(--v-primary-darken2)"}},[_vm._v("mdi-arrow-expand-vertical")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("section.collision.table.expandRules")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleAllGroup(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"var(--v-primary-darken2)"}},[_vm._v("mdi-arrow-collapse-vertical")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("section.collision.table.collapseGroups"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.showSettings}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"var(--v-primary-darken2)"}},[_vm._v("settings")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('section.collision.settings')))])])],1)]),_c('column-settings-dialog',{ref:"settings"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }