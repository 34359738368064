<template lang="pug">
div
  .comments-panel-header
    .font-weight-bold {{ $t('module.task.comments') }}
    AppTooltip( title="Импортировать все" bottom )
      AppIconButton( icon="$drawings-import-all-icon" @click='state.importAllComments' )
  .comments-panel-body
    v-hover( v-slot:default='{ hover }' v-for='comment in state.currentComments' :key='comment.uuid' )
      .comment-item( @click.stop='state.scrollPageToComment(comment)' )
        .d-flex.align-center
          .user-avatar
            span.ma-auto {{ comment.user.name[0].toUpperCase() }}
          .ml-2
            .comment-user-name {{ comment.user.name }}
            .comment-create-date {{ state.dateFormat(comment.createDate) }}
          .d-flex.align-center.ml-auto
            transition( name='button' )
              AppIconButton( v-if="hover" iconSize="18" icon="$drawings-import-icon" @click.stop='state.importOneComment(comment)' )
            transition( name='button' )
              AppIconButton( v-if="hover" icon="mdi-pencil" iconColor="#757575" @click.stop='state.showCommentChangeDialog(comment)' )
            transition( name='button' )
              AppIconButton( v-if="hover" icon="mdi-delete-outline" iconColor="#757575" @click.stop='state.removeComment(comment)' )
        .comment-text {{ comment.text }}
</template>

<script>
import { useDrawingCommentState } from '@/pinia';

export default {
  name: 'DrawingsCommentCard',

  computed: {
    state: () => useDrawingCommentState(),
  },
}
</script>

<style scoped>
.comments-panel-header{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  padding: 6px 12px 6px 12px;
  justify-content: space-between;
}

.comments-panel-body{
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 4px;
}

.comment-item{
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 4px;
  transition-duration: .4s;
}

.comment-item:hover{
  background: #e5e5e5;
  cursor: pointer;
}

.comment-item:active{
  background: #c7c7c7;
  transition-duration: .1s;
}

.user-avatar{
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #505254;
  display: flex;
  align-items: center;
  justify-items: center;
  color: white;
  font-size: 18px;
}

.comment-user-name{
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.comment-create-date{
  font-size: 12px;
  font-weight: thin;
  line-height: 16px;
}
.comment-text{
  padding: 0px 0px 0px 40px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>