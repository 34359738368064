<template lang="pug">
  app-panel.ml-2.px-1.py-2( width="256" )
    app-panel-header( :title="this.selectedPatch.name" hideClose)
      patch-export-file( v-if="this.selectedPatch.file && hasProjectReconstruction" )
      patch-import-file( v-if="loading || !this.selectedPatch.file && hasProjectReconstruction" @uploadPatch="setLoading" )
      patch-delete-file( v-if="this.selectedPatch.file && hasProjectReconstruction" )
    app-section.base-scroll-body( style="height: calc(100% - 29px) !important;" )
      div(v-if="!loading")
        revision-passport( v-if="this.selectedPatch.modelRevisionPassport.ifcFileName"  :buttonHeader="$t('patch.passportRec')"  :passportData="this.selectedPatch.modelRevisionPassport" )
        patch-coords(v-if="hasModelControlOffset || hasProjectReconstruction")
        patch-element-list
        patch-annotation.mt-2
        patch-activation(v-if="hasProjectReconstruction").mt-1
        suspect-elements(:isPatch="true")

      div.d-flex.align-center.mt-2( v-if="loading" )
        app-text.mr-4( dark ) {{ $t('patch.processingRec') }}
        v-progress-circular( indeterminate color="primary" :width="3" :size="20" )
</template>

<script>
import RevisionPassport from '@/components/project/panel/left/RevisionPassport'
import PatchAnnotation from '@/components/project/panel/left/modelPatch/components/PatchAnnotation'
import PatchActivation from '@/components/project/panel/left/modelPatch/components/PatchActivation'
import PatchElementList from '@/components/project/panel/left/modelPatch/components/PatchElementList'
import PatchCoords from '@/components/project/panel/left/modelPatch/components/PatchCoords'
import PatchImportFile from '@/components/project/panel/left/modelPatch/components/PatchImportFile'
import PatchDeleteFile from '@/components/project/panel/left/modelPatch/components/PatchDeleteFile'
import SuspectElements from '@/components/project/panel/left/suspectElements/SuspectElements'
import PatchExportFile from './components/PatchExportFile.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModelPatch',

  components: {
    RevisionPassport,
    PatchAnnotation,
    PatchActivation,
    PatchElementList,
    PatchCoords, 
    PatchImportFile,
    PatchDeleteFile,
    PatchExportFile,
    SuspectElements
  },

  data() {
    return {
      loading: false,
    }
  }, 

  computed: {
    ...mapState('patches', ['selectedPatch']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction", "hasModelControlOffset"])
  }, 

  watch: {
    selectedPatch: function() {
      if(this.selectedPatch.state.value == 0)
        this.loading = false
      this.waitLoading()
    }
  },
  
  beforeDestroy() {
    this.setSelectedPatch(null)
  },

  mounted() {
    if(this.selectedPatch.state.value == 0) {
      this.waitLoading()
    }
  },

  methods: {
    ...mapMutations('patches', ['setSelectedPatch']),
    ...mapActions('patches', ['getPatch']),

    setLoading(isActive) {
      this.loading = isActive
    }, 
    
    waitLoading() {
      if(this.selectedPatch.state.value == 1) {
        return this.loading = false
      }

      if(this.selectedPatch.state.value == 0 && this.selectedPatch.modelRevisionPassport == null) {
        return this.loading = false
      }

      if(this.selectedPatch.state.value == 0 && this.selectedPatch.file != '')
      {
        this.loading = true
        let token = setInterval( () => {
          this.getPatch(this.selectedPatch.uuid).then(data => {
            if(data.state.value === 1)  {
              clearInterval(token)
              return this.loading = false
            }
          })
        }, 4000)
        return this.loading
      }
    },
  },
}
</script>

<style scoped>
.asd{ 
  box-shadow: 0px 0px 0px;
}
</style>