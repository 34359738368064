<template lang="pug">
  div( ref="editor" class="monaco-editor" )
</template>

<script>
import loader from '@monaco-editor/loader'

import * as monaco from 'monaco-editor'

import { mapState, mapMutations } from 'vuex'

import groovy from './Groovy.js'

export default {
  mounted() {
    this.init()
  },

  beforeDestroy() {
    if (this.monacoModels) this.monacoModels.forEach((model) => model.dispose())

    if (this.completionItemProvider) this.completionItemProvider.dispose()

    if (this.provideHover) this.provideHover.dispose()
  },

  data() {
    return {
      monacoEditor: {},
      monacoModels: [],
      completionItemProvider: {},
      provideHover: {},
      theme: 'vs',
      minimapEnabled: false,
    }
  },

  computed: {
    ...mapState('plugins', ['loadedPluginCode', 'methods', 'apiModels']),

    syncLoadedPluginCode: {
      get() {
        return this.loadedPluginCode
      },
      set(value) {
        this.setLoadedPluginCode(value)
      },
    },

    groovyKeywords() {
      const g = groovy.language
      return new Set([...g.keywords, ...g.bimClass, ...g.typeKeywords])
    },
  },

  methods: {
    ...mapMutations('plugins', ['setLoadedPluginCode']),

    init() {
      loader.config({ monaco })

      loader.init().then((monaco) => {
        this.monacoModels = monaco.editor.getModels()
        if (this.monacoModels.length > 0) this.monacoModels.forEach((model) => model.dispose())

        if (!monaco.languages.getLanguages().find((lang) => lang.id === 'groovy')) {
          monaco.languages.register({ id: 'groovy' })
          monaco.languages.setLanguageConfiguration('groovy', groovy.config)
          monaco.languages.setMonarchTokensProvider('groovy', groovy.language)
        }

        this.$refs.editor.innerHTML = ''
        this.monacoEditor = monaco.editor.create(this.$refs.editor, {
          value: this.syncLoadedPluginCode,
          theme: this.theme,
          language: 'groovy',
          automaticLayout: true,
          autoClosingBrackets: true,
          wordBasedSuggestions: 'currentDocument',
          suggest: {
            insertMode: 'replace',
            selectionMode: 'always',
            preview: true,
            previewMode: 'subwordSmart',
            showStatusBar: true,
          },
          unicodeHighlight: {
            allowedLocales: [{ ru: true }],
          },
          minimap:{
            enabled: this.minimapEnabled
          }
        })

        this.monacoEditor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => this.$emit('save'))

        this.monacoEditor.addAction({
          id: 'show_guide',
          label: "Справочник",

          keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],

          contextMenuGroupId: 'navigation',
          contextMenuOrder: 1,

          run: () => this.$emit('methods'),
        })

        this.monacoEditor.addAction({
          id: 'change_theme',
          label: this.$t('plugin.theme'),

          keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Slash],

          contextMenuGroupId: 'navigation',
          contextMenuOrder: 2,

          run: () => {
            this.theme === 'vs' ? (this.theme = 'vs-dark') : (this.theme = 'vs')

            monaco.editor.setTheme(this.theme)
          },
        })

        this.monacoEditor.addAction({
          id: 'change_minimap_enabled',
          label: this.$t('plugin.minimap'),

          keybindings: [ monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM ],

          contextMenuGroupId: 'navigation',
          contextMenuOrder: 3,

          run: () => {
            this.minimapEnabled = !this.minimapEnabled

            this.monacoEditor.updateOptions({ minimap: { enabled: this.minimapEnabled } })
          },
        })

        this.provideHover = monaco.languages.registerHoverProvider('groovy', {
          provideHover: (model, position) => {
            let word = model?.getWordAtPosition(position)
            if (!word) return

            let prevPosition = new monaco.Position(position.lineNumber, word.startColumn - 1)
            let prevWord = model?.getWordAtPosition(prevPosition)?.word

            let classMethods = this.methods[prevWord]
            if (!classMethods) classMethods = this.methods[word.word]
            if (!classMethods) return

            let methods = classMethods.methods[word.word]

            if (word) {
              let result
              if (methods && classMethods.serviceName !== word.word) {
                let content = []
                let value = '```js\n'
                methods.forEach((method) => {
                  value += 'function ' + method.methodName + '(' + method.inputValue + '): ' + method.returnValue + '\n'
                  // content.push({ value:  })
                })
                value += '\n```'

                content.push({ value: value })
                content.push({ value: methods[0].description })

                result = {
                  range: position,
                  contents: content,
                }
              } else {
                result = {
                  renge: position,
                  contents: [{ value: '```js\nclass ' + classMethods.serviceName + '\n```' }, { value: classMethods.description }],
                }
              }

              return result
            }

            return
          },
        })

        this.completionItemProvider = monaco.languages.registerCompletionItemProvider('groovy', {
          triggerCharacters: ['.'],
          provideCompletionItems: (model, position) => {
            if (!this.methods || this.methods.length <= 0) return { suggestions: [] }

            let methodsArr = []

            let word = model?.getWordAtPosition(position)
            let prevPosition = new monaco.Position(position.lineNumber, word == null ? position.column - 1 : word.startColumn - 1)

            let prevWord = model?.getWordAtPosition(prevPosition)?.word

            let classMethods = this.methods[prevWord]

            if (prevWord && classMethods) {
              for (let methodName in classMethods.methods) {
                let methods = classMethods.methods[methodName]
                methods.forEach((method) => {
                  methodsArr.push({
                    label: method.methodName,
                    // label: {
                    //   label: method.methodName,
                    //   detail: "hnehne",
                    //   description: method.description,
                    // },
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: method.methodName + '()',
                    documentation: method.description,
                    detail: '(method) ' + method.methodName + '(' + method.inputValue + '): ' + method.returnValue,
                  })
                })
              }
            } else if (!prevWord && !classMethods) {
              for (let key in this.methods) {
                let clazz = this.methods[key]
                methodsArr.push({
                  label: clazz.serviceName,
                  kind: monaco.languages.CompletionItemKind.Class,
                  insertText: clazz.serviceName,
                  detail: '(class) ' + clazz.serviceName,
                  documentation: clazz.description,
                })

                let classMethods = clazz.methods
                for (let name in classMethods) {
                  let methods = classMethods[name]
                  methods.forEach((method) => {
                    methodsArr.push({
                      label: method.methodName,
                      kind: monaco.languages.CompletionItemKind.Function,
                      insertText: method.classType + '.' + method.methodName + '()',
                      documentation: method.description,
                      detail: '(method) ' + method.methodName + '(' + method.inputValue + '): ' + method.returnValue,
                    })
                  })
                }
              }

              groovy.language.keywords.forEach((keyWord) => {
                methodsArr.push({
                  label: keyWord,
                  kind: monaco.languages.CompletionItemKind.Field,
                  insertText: keyWord,
                })
              })

              groovy.language.typeKeywords.forEach((keyWord) => {
                methodsArr.push({
                  label: keyWord,
                  kind: monaco.languages.CompletionItemKind.Variable,
                  insertText: keyWord,
                })
              })
            }

            // Возможно страшная и опасная хрень, добавлена эксперементально
            // Эксплуатация покажет насколько это стабильно работает
            let words = new Set(model.getValue().matchAll(/\w+/g).map((x) => x[0]))
            this.groovyKeywords.forEach((el) => words.delete(el))
            if (!words.size) {
              return { suggestions: methodsArr }
            }

            const simpleSnippets = words.map((val) => {
              return {
                label: val,
                kind: monaco.languages.CompletionItemKind.Text,
                insertText: val,
              }
            })

            return { suggestions: [...simpleSnippets, ...methodsArr] }
          },
        })

        this.monacoEditor.getModel().onDidChangeContent(() => {
          this.syncLoadedPluginCode = this.monacoEditor.getValue()
        })
      })
    },

    insertText(text) {
      let selection = this.monacoEditor.getSelection()
      let id = { major: 1, minor: 1 }
      let op = { identifier: id, range: selection, text: text, forceMoveMarkers: true }
      this.monacoEditor.executeEdits('my-source', [op])
    },
  },
}
</script>

<style lang="scss" scoped>
.monaco-editor {
  margin: auto;
  width: 100%;
  height: 100%;
}

.pepe {
  color: #ff0000;
}
</style>
