<template lang="pug">
AppTooltip( title="Позиционировать изображение" bottom )
  AppIconButton( icon="$drawings-fit-icon" buttonSize="small" @click="state.toggleFitMode" :iconColor="state.isFittingModeActive ? '#8DAFFF' : '#4d4d4d'" ) 
</template>

<script>
import { useDrawingPageState } from '@/pinia'

export default {
  name: 'DrawingFit',

  computed: {
    state: () => useDrawingPageState()
  },
}
</script>