<template lang="pug">
  app-dialog-confirm( v-model="visible" :disabled="validation" @confirm="onAllowingGroup") {{ $t('section.collision.groupMenu.allow', {group: group.groupTitle}) }}
    app-section
      app-text-field( v-model="note" :label="$t('section.collision.allowNote')" )
</template>

<script>
import { mapActions } from 'vuex'

export default {

  data() {
    return {
      note: null,
      group: {
        groupTitle: '',
      },

      visible: false,
    }
  },

  computed: {
    validation() {
      return !this.note?.trim() 
    }
  },

  methods: {
    ...mapActions('collision/table', ['allowGroup']),

    show(group) {
      this.group = group
      this.visible = true
    },

    onAllowingGroup () {
      this.allowGroup({uuid: this.group.groupUuid, note: this.note})
    }
  }
}
</script>