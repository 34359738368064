<template lang="pug">
app-dialog-confirm( v-model="visible" @confirm="aproveDeletePlugin" @close="cancelDeletePlugin")
  app-section.mb-4 
    app-text {{ $t('plugin.info.deletePlugin') }} "{{ pluginToDelete.name }}"?
</template>

<script>

export default {
  props: {
    pluginToDelete: Object
  },

  data() {
    return {
      visible: true
    }
  },
  
  methods: {
    aproveDeletePlugin() {
      this.$emit('aproveDeletePlugin')
    },

    cancelDeletePlugin() {
      this.$emit('cancelDeletePlugin')
    }
  }
}
</script>
