<template lang="pug">
  .relative.gizmo
    .relative
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-btn.btn-list.spd-btn( v-bind='attrs' v-on='on' x-small fab @click="showSpeedList()" ) {{ speed.title }}
          div.mr-2(v-if="showSpeed" style="position :absolute; right: -240px")
            v-btn.btn-list.mr-2(v-for="itemSpeed in speedList" :key="itemSpeed.value" x-small @click="setSpeedControl(itemSpeed)" :class="{'selected-btn' : speed.value == itemSpeed.value}") {{ itemSpeed.title }}
      span {{ $t("section.structure.model.form.flightSpeed") }}
</template>

<script>
export default {
  data: () => ({
    showSpeed: false,
    speed: { value: 1, title: 'x1' },
    speedList: [
        { value: 1, title: 'x1' },
        { value: 2, title: 'x2' },
        { value: 3, title: 'x3' },
        { value: 4, title: 'x4' },
        { value: 5, title: 'x5' },
      ]
  }),

  methods: {
    showSpeedList () {
      this.showSpeed = !this.showSpeed
    },

    setSpeedControl(speed) {
      this.speed = speed
      localStorage.setItem("speedControl", speed.value)
      
      this.showSpeedList()
    }
  }
}
</script>

<style scoped lang="scss">
.spd-btn {
  left: 100px; 
  top: 120px;
}
#myAxisCanvas {
  width: 100px;
  height: 100px;
}
.relative {
  position: relative;
}
.btn-list{
  background-color: var(--v-surface-base) !important;
  color: var(--v-surface-lighten3);
}
.selected-btn {
  background-color: var(--v-surface-lighten3) !important;
  color: var(--v-surface-base) !important;
}
</style>