<template lang="pug">
  .d-flex.align-center.item
    v-hover( v-slot:default="{ hover }" )
      .item__content( @click.stop="onClick" @contextmenu.stop="onMenu" ) 
        span( :class="{ 'item__content--selected': selected }") {{ element.name }}
        v-icon.item__more-action( v-if="hover" @click.stop="onMenu" style="color:black;") more_vert
    slot( v-if="$slots.default" )
</template>

<script>
export default {
  name: "TemplateUnit",

  props:{
    element: null,
    selected: Boolean,
  },

  methods: {
    onMenu (event) {
      event.stopPropagation()
      event.preventDefault()
      this.$emit('menu', this.element, event)
    },

    onClick () {
      this.$emit('select', this.element)
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  height: 24px;
  margin-right: -20px;
}
.item__content {
  position: relative;
  width: 100%;
  height: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  padding-left: 10px;
  margin-left: -10px;
  // overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  cursor: context-menu;
  line-height: 24px;

  background-color: #fff;
  // color: var(--v-primary-base);

  &--selected {
    font-weight: bold;
    border-bottom: 2px solid var(--v-surface-lighten3);
  }

  &--dark {
    background-color: var(--v-surface-lighten1);
    color: var(--v-primary-lighten5);

    .item__content--selected {
      border-bottom: 2px solid var(--v-surface-lighten3);
    }
  }
}
.item__content:hover{
  background-color: var(--v-primary-lighten5);
}
.item__more-action {
  position: absolute;
  right: 0;
  cursor: pointer;
}
</style>