<template lang="pug">
  social-button( @click.native="login" ref="button1") Bimit.ru
</template>

<script>
  import { App } from '@app/App'
  import SocialButton from '@/components/social/SocialButton.vue'


  export default {
    name: 'bimit',

    components: {
      SocialButton
    },
    
    data: () => ({
      // user: null,
      state: '',
      request: {},
      oauth: App.social['bimit'],
      url_token: ''
    }),

    created() {
      this.$root.$on('loginBimit', url => {
        this.loginbimit(url)
      })
      this.$root.$on('callLoginBimit', url => {
        this.login()
      })
    },
    mounted() {
      let redirect_uri = window.location.origin+'/login';
      let state = this.uuid();
      this.state = state;
      this.request.redirect_uri = redirect_uri;
      
      if (this.oauth) {
        this.url_token = App.social.authserver+`/oauth/authorize?client_id=${this.oauth.id}&response_type=code&scope=${this.oauth.scope}&prompt_force=yes&redirect_uri=${redirect_uri}`;
      }
    },
    methods: {
      token(code) {
          this.request.code = code
          this.$emit('getToken', this.request)
      },
      loginbimit(url) {
        let tokenCode = /code=([^&]+)/.exec(url)[1];
        this.token(tokenCode)
      },
      login() {
        let el = this;
        location.href = this.url_token;

        window.setBimitToken = function(url) {
          let tokenCode = /code=([^&]+)/.exec(url)[1];
          el.token(tokenCode)
        }
      },
      uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
    }
  }
</script>
