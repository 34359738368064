<template lang="pug">
  div
    app-dialog(v-model="$_show" :header="$t('signIn.title')" :width="noDialog ? 400 : 350" @close="handleClose" :closeButtonHidden="noDialog ? true : false" :hideOverlay="noDialog ? true : false" complex)
      app-section(v-if="checkLoginType('local')" :rounded="false")
        .home__login
          app-text-field.my-2(v-model="username" hideDetails :label="$t('signIn.login')" :focus="!hasSavedLogin" test-id="accessLogin-login-input" @keyup.enter="login")
          app-text-field(v-model="password" hideDetails :label="$t('signIn.password')" :errorText="error"  type="password" :focus="hasSavedLogin" test-id="accessLogin-pass-input"  @change="resetError" @keyup.enter="login")
      
      .text-right(slot="footer")
        .d-flex.mt-4(v-if="checkLoginType('local')")
          .align-content-start
            v-btn.text-none.edit-project__footer-button( color="#fff" @click="$router.push('/forgot')" plain light) {{ $t('signIn.forgot') }}
            v-btn.text-none.edit-project__footer-button(color="#fff" @click="$router.push('/registration')" plain light) {{ $t('button.registration') }}
          v-spacer
          .align-content-end
            v-btn.text-none.dialog-confirm-action.mr-2( color="accent" height="32" small depressed light test-id="accessLogin-signIn-btn" @click="login") {{ $t('button.signIn') }}

        div.px-2.py-2
          Bimit.mt-2(@getToken="socialLogin")

        .d-flex.mt-4(v-if="lastLoginType != 'new'")
          v-btn.text-none.edit-project__footer-button( color="#fff" @click="showAlLoginType" plain light width="inherit") {{ $t('signIn.all') }}

      app-dialog(v-model="dialog.restore" :header="$t('signIn.forgot')" width="270")
        app-section
          .home__login
            app-text-field.mx-2(v-model="username" :label="$t('button.signIn')" :focus="!hasSavedLogin" @keyup.enter="restore")
        .d-flex.mt-4
          .align-content-end
            v-btn.text-none.dialog-confirm-action( color="accent" height="32" small depressed light @click="restore") {{ $t('button.send') }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import social from '@/components/social'
import { authUserService } from '@/_services'
import { App } from '@app/App'

import { AlertService } from '@app/AlertService'
import { mapActions } from 'vuex'

export default {
  name: 'AccessLogin',
  components: {
    Bimit: social.Bimit,
  },
  props: {
    noDialog: {
      default: false,
      type: Boolean
    },
  },
  mixins: [DialogToggable],
  data () {
    return {
      username: '',
      password: '',
      error: null,
      dialog: {
        restore: false,
        alLoginType: false
      }
    } 
  },

  created () {
    this.username = localStorage.getItem("loginName")
    this.password = ''
  },

  mounted () {
    this.$_show = false
    let code = /code=([^&]+)/.exec(location)?.[1];
    if (code !== "" && code) this.$root.$emit('loginBimit', location)
    else  this.$root.$emit('callLoginBimit', location)

    // TODO: вырезаем fromext, при переходе на /login -> редиректим на /authserver/login
    // else if (lc.indexOf("fromext") !== -1)  this.$root.$emit('callLoginBimit', location)
  },

  computed: {
    lastLoginType () {
      return this.dialog.alLoginType ? "new" : App.auth.loginType
    },
    hasSavedLogin () {
      return localStorage.getItem("loginName") ? true : false
    }
  },
  methods: {
    ...mapActions('languages', ['init']),

    checkLoginType(type) {
      return this.lastLoginType == 'new' || this.lastLoginType == type
    },
    showAlLoginType() {
      this.dialog.alLoginType = true
    },
    login () {
      let { username, password } = this

      authUserService.login(username, password).then(status => {
        this.auth(status, username)
      })
    },

    async socialLogin(req, social_type) {
      await authUserService.getToken(req).then(status => {
        this.auth(status, 'social ' + social_type)
      })
    },

    async auth(status, username) {
      if (status) {
        localStorage.setItem("loginName", username)
        this.$metrika.reachGoal('SIGN_IN', {user_email: username})

        if (App.version.isCloud || location.href.indexOf('/cloud/') != -1) {
          this.$_show = false
          return true
        }

        await this.$store.dispatch('authUser/LOAD_CURRENT_USER').then(data => {
          this._setTheme(data?.name || 'dark', false)
        })
        
        await this.init().then(() => {
          if (localStorage.getItem("locationHref")) {
            location.href = localStorage.getItem("locationHref")
            localStorage.removeItem("locationHref")
            return true
          }
          return this.$router.push('/dashboard')
        })
        
      } else {
        this.error = this.$t('error.loginPassword')
      }
    },

    showRestore () {
      this.$_show = false
      this.dialog.restore = true
    },

    restore() {
      authUserService.restore(this.username).then(status => {
        if (status) {
          AlertService.info({info: this.$t('info.passwordRestore', {username: this.username} ) })
          this.dialog.restore = false
        }
      })
    },
    
    resetError () {
      this.error = null
    },

    handleClose() {
      this.$emit('close')
    }
  },
  
}
</script>