<template lang="pug">
ToolButton( 
  :tooltip="$t('viewer.tools.clearSelObjs')" 
  @click="store.showAllObjects" 
) $viewer-reset-clear-icon
</template>

<script>
import { useSelectionStore } from '@/stores/viewerTools/selection.store'
import ToolButton from './ToolButton.vue'

export default {
  components: {
    ToolButton,
  },

  computed: {
    store: () => useSelectionStore(),
  },
}
</script>