var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasAttachments)?_c('task-section-expansion',{attrs:{"title":_vm.sectionTitle,"showToggle":_vm.getAttachmentPanel,"additionalIcon":_vm.addIconButton,"headerColorDark":"","headerWrap":"","changeHeaderHeight":""},on:{"additionalIconClick":_vm.attachmentPick,"openPanel":function($event){return _vm.setAttachmentPanel($event)}},scopedSlots:_vm._u([{key:"titleAfter",fn:function(){return [(!_vm.isTaskManager)?_c('div',{staticClass:"d-flex align-center"},[_c('app-text',[_vm._v(_vm._s(_vm.flyCount + '/' + _vm.attachWithAnnotation.length))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"ml-1",attrs:{"size":"20","icon":_vm.isVisibleTaskAnnotations ? 'project/task/location_on.svg' : 'project/task/location_off.svg'},on:{"click":function($event){$event.stopPropagation();return _vm.showMarkerAnnotation(!_vm.isVisibleTaskAnnotations)}}},on))]}}],null,false,3582560505)},[_c('span',[_vm._v(_vm._s(_vm.isVisibleTaskAnnotations ? _vm.$t('viewer.tools.switchOff') : _vm.$t('viewer.tools.turnOn'))+" "+_vm._s(_vm.$t('module.task.taskManager.markers')))])]),(_vm.playFlyTo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"ml-1",attrs:{"size":"20","icon":"project/task/play_circle_black.svg"},on:{"click":function($event){$event.stopPropagation();return _vm.startFlyItemAnnotation()}}},on))]}}],null,false,3000158769)},[_c('span',[_vm._v(_vm._s(_vm.$t('viewer.tools.flyAround')))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"ml-1",attrs:{"size":"20","icon":"project/task/pause_circle.svg"},on:{"click":function($event){$event.stopPropagation();return _vm.stopFlyByAnnotation()}}},on))]}}],null,false,2334453545)},[_c('span',[_vm._v(_vm._s(_vm.$t('viewer.tools.stop')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('task-button-action',_vm._g({staticClass:"ml-1",attrs:{"size":"20","icon":_vm.repeat ? 'project/task/loop_on.svg' : 'project/task/loop_off.svg'},on:{"click":function($event){$event.stopPropagation();_vm.repeat = !_vm.repeat}}},on))]}}],null,false,801406238)},[_c('span',[_vm._v(_vm._s(_vm.repeat ? _vm.$t('viewer.tools.switchOff') : _vm.$t('viewer.tools.turnOn'))+" "+_vm._s(_vm.$t('viewer.tools.repeat')))])])],1):_vm._e()]},proxy:true}],null,false,1004493674)},[_c('div',{staticClass:"d-flex flex-wrap mx-1",staticStyle:{"gap":"8px"}},_vm._l((_vm.attachmentsParseCam),function(attach){return _c('task-attachment',{key:attach.uuid,staticStyle:{"width":"250px"},attrs:{"attach":attach,"currentAttach":_vm.currentAttach,"test-id":"task-attachment"},on:{"location":function($event){return _vm.showLocation(attach)}}})}),1),_c('task-comment-attachment-dialog',{attrs:{"task":_vm.selectedTask},model:{value:(_vm.dialog.attachment),callback:function ($$v) {_vm.$set(_vm.dialog, "attachment", $$v)},expression:"dialog.attachment"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }