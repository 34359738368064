<template lang="pug">
  div.d-flex.justify-center
    div
      v-btn.d-flex.pag-btn.ml-1(:min-width="25" @click="onClickFirstPage" :disabled="isInFirstPage" class="pagination-item" ) 
        v-icon mdi-chevron-double-left
      
    div
      v-btn.d-flex.pag-btn.ml-1(:min-width="25" @click="onClickPreviousPage" :disabled="isInFirstPage" class="pagination-item")
        v-icon mdi-chevron-left 

  
    div(v-for="page in pages" :key="page.name")
    
      v-btn.d-flex.pag-btn.ml-1(:min-width="25" @click="onClickPage(page.name)" :class="{ 'pag-active-btn': page.isDisabled}" )  {{ page.name }}

    div
      v-btn.d-flex.pag-btn.ml-1(:min-width="25" @click="onClickNextPage" :disabled="isInLastPage")
        v-icon mdi-chevron-right

     
    div
      v-btn.d-flex.pag-btn.ml-1(:min-width="25" @click="onClickLastPage" :disabled="isInLastPage") 
        v-icon mdi-chevron-double-right

  
</template>

<script>
export default {
  data(){
    return {
      pageNumber: 0,
    }
  },
  props:{
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    currentGroupId: {
      type: Number,
    }
  },
  mounted(){
    this.btnWidth = 30
    this.btnHeight = 18
  },

  computed:{
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if(this.currentPage === this.totalPages){
        if(this.totalPages===2){
          return this.totalPages - (this.maxVisibleButtons-2);
        }
        else return this.totalPages - (this.maxVisibleButtons-1);
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },


  },
  methods:{
    onClickFirstPage() {
      this.$emit('pagechanged', 1, this.currentGroupId);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1, this.currentGroupId);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page, this.currentGroupId);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1, this.currentGroupId);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages, this.currentGroupId);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }

  }
    
}
</script>

<style scoped>
.pagination {
  list-style-type: none;
}
.pagination-item {
  display: inline-block;
}

.pag-active-btn {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
  color: #fff !important;
}

.pag-btn {
  width: 10px;
  height: 5px;
}
</style>