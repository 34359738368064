<template lang="pug">
.node( :class="{ 'node--hightlighted': hightlighted }" @click="clickOnRow" )
  .node__root
    .node__level( v-for="index in level" :key="index" )
    
    .node__level( v-if="source.isEndpoint || !source.isGroup" )
    v-icon.node__toggle( v-else tag="button" :class="toggleClass" light @click.stop="toggleDisclosedNode(source)" ) arrow_drop_down


    v-hover( v-slot:default="{ hover }" close-delay="150" )
      .node__content
        .node__label( @contextmenu.stop="showContextMenu" :class="{ 'node--group': source.isGroup }")  
          | {{ sourceTitle }}
        v-icon.node__more( v-if="hover && hasMaintainceMenu" tag="button" color="#000" @click.stop="showContextMenu" ) more_vert

</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    showMenu: Function
  },

  computed: {
    ...mapState('maintenance', ['selectedGroup', 'selectedEquipment']),
    ...mapGetters('maintenance', ['isTreeNodeDisclosed']),
    ...mapGetters('projectPermissions', ['hasMaintainceEquipment', 'hasMaintainceCalculateTO']),

    hasMaintainceMenu () {
      return this.hasMaintainceEquipment || this.hasMaintainceCalculateTO
    },

    hightlighted () {
      return this.source.isGroup ? this.selectedGroup?.uuid == this.source.uuid : this.selectedEquipment?.uuid == this.source.uuid
    },

    isOpen () {
      return this.isTreeNodeDisclosed(this.source)
    },

    toggleClass () {
      return this.isOpen ? 'node__toggle--open' : 'node__toggle--close'
    },

    level () {
      return this.source.level ?? 0
    },

    sourceTitle() {
      return this.source.isGroup ? this.source.title : this.source.name
    }
  },

  mounted () {
  },

  methods: {
    ...mapActions('maintenance', ['toggleDisclosedNode']),
    ...mapMutations('maintenance', ['setSelectedEquipment']),

    showContextMenu (event) {
      this.showMenu(event, this.source)
    },

    clickOnRow () {
      if(this.source.isGroup) this.toggleDisclosedNode(this.source)
      else this.setSelectedEquipment(this.source)
    }

  }
}
</script>

<style lang="scss" scoped>
.node {
  color: rgba(0,0,0,.87);
}
.node:hover {
  background-color: var(--v-primary-lighten5);
}
.node--hightlighted {
  background-color: var(--v-primary-lighten5);
  box-shadow:inset 0px 0px 0px 1px var(--v-primary-lighten4);
}
.node__root {
  display: flex;
  align-items: center;
  min-height: 24px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.node__root::before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}

.node__root::after {
  content: "";
  font-size: 0;
  min-height: inherit;
}

.node__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}

.node__toggle::after {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.3);
  width: 100%;
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
}

.node__content {
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
  margin-left: 6px;
  padding-right: 4px;
  justify-content: space-between;
}

.node__label {
  flex: 1;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.node__level {
  width: 8px;
}

.node__more {
  color: var(--v-primary-lighten4);
  caret-color: var(--v-primary-lighten4);
  font-size: 16px;
}

.node--group {
  font-weight: bold;
}

</style>
