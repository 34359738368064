<template lang="pug">
  app-dialog.app-dialog_body__complex.no-padding-dialog(class="no-padding-dialog" v-model="dialogVisible" withScrollPanel complex width="40%" :header="$t('toolbarMenu.importStructure.importProject')")
    v-row.no-gutters.align-items-center(@click="triggerFolderSelect" class="clickable-row")
      v-icon(color="accent" size="30" left) mdi-folder
      span.d-flex.align-center(style="margin-left: 10px; color: var(--v-primary-base);") {{ $t('importStructure.uploadFilesOrFolders.uploadFolder') }}
    input(type="file" ref="folderInput" webkitdirectory @change="handleFolderChange" style="display: none" )
    v-row.no-gutters.align-items-center(@click="triggerFileSelect" class="clickable-row")
      v-icon(color="accent" size="30" left) mdi-file
      span.d-flex.align-center(style="margin-left: 10px; color: var(--v-primary-base);") {{ $t('importStructure.uploadFilesOrFolders.uploadFile') }}
    input(type="file" ref="fileInput" multiple accept=".laz, .e57, .ifc, .ifczip, .pdf" @change="handleFileChange" style="display: none")
</template>

<script>
export default {
  name: "UploadFilesOrFolders",
  data() {
    return {
      dialogVisible: false,
      selectedFilesOrFolders: [],
      itemKey: 1,
      fileNodesMap: new Map(),
      flatListFolders: [],
    };
  },
  
  methods: {
    triggerFolderSelect() {
      this.$refs.folderInput.click()
    },

    triggerFileSelect() {
      this.$refs.fileInput.click()
    },

    handleFileChange(event) {
      const files = event.target.files
      const validFiles = []
      for(const file of files) {
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (this.isValidFile(fileExtension)) {
          validFiles.push(this.createNodeData(file.name, true, fileExtension, file))
        }
      }

      this.selectedFilesOrFolders = validFiles
      this.confirmSelection()
    },

    handleFolderChange(event) {
      const files = event.target.files
      this.selectedFilesOrFolders = this.buildDirectoryStructure(Array.from(files).reverse())
      this.confirmSelection()
    },

    isValidFile(fileExtension) {
      return ['pdf','laz', 'e57', 'ifc', 'ifczip'].includes(fileExtension)
    },

    buildDirectoryStructure(files) {
      const structure = []
      
      for (const file of files) {
        const parts = file.webkitRelativePath.split('/')
        let currentLevel = structure
        const fileExtension = file.name.split('.').pop().toLowerCase()
        if (this.isValidFile(fileExtension)) {
          const partsLenght = parts.length
          for (let index = 0; index < partsLenght; index++) {
            let part = parts[index]
            if (index === partsLenght - 1) {
                currentLevel.push(this.createNodeData(file.name, true, fileExtension, file))
            } else {
              let folder = currentLevel.find(item => item.nameModel === part && !item.isFile)
              if (!folder) {
                folder = this.createNodeData(part, false, null)
                currentLevel.push(folder)
              }
              currentLevel = folder.projectStructureDTO
            }
          }
        }
      }

      return structure;
    },

    createNodeData(nameModel, isFile, extension, file = null) {
      const nodeData = {
        itemKey: ++this.itemKey,
        nameModel,
        projectStructureDTO: [],
        getIfcFile: null,
        selected: false,
        opened: false,
        isFile,
        extension,
      }

      isFile? this.fileNodesMap.set(nodeData.itemKey, file): this.flatListFolders.push(nodeData.itemKey)

      return nodeData
    },

    confirmSelection() {
      this.$emit("selectedFilesOrFolders", this.selectedFilesOrFolders)
      this.$emit("fileNodesMap", this.fileNodesMap)
      this.$emit("flatListFolders", this.flatListFolders)
      this.resetData()
    },

    resetData() {
      this.$refs.folderInput.value = null
      this.$refs.fileInput.value = null
      this.selectedFilesOrFolders = []
      this.dialogVisible = false
    },
 
    resetFileMapAndFlatListFolders() {
      this.fileNodesMap.clear()
      this.flatListFolders = []
    },

    openDialog() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.clickable-row {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
  background-color: white;
}

.clickable-row:hover {
  background-color: var(--v-secondary-lighten5);
}

.no-padding-dialog {
  padding: 0 !important;
}
</style>
