<template lang="pug">
  .drawing-pages-selecting(@click='startOrBreakRenaming')

    //- Боковая панель выбора чертежей 
    .drawings-pages.ml-auto.pb-5.base-scroll-body(v-if='store.sidePanelVisible || store.commentImportMode' ref='previews')
      .header
        span {{ allIsChoose? $t("module.drawing.breakAll"): $t("module.drawing.selectAll") }}
        v-switch.ma-0.pa-0.mr-5.ml-2(v-model="allIsChoose" :disabled="disabledSwitch" color="accent" @click="includeAll()" dense light hide-details)
      .mt-3
        .page.mb-5(v-for='(page, index) in pagesByDrawingVersion')
          .page-preview(:class='{"selected-preview-page": (store.selectedDrawingPage && page.uuid == store.selectedDrawingPage.uuid), "disactive": !page.included}' @click='selectPagePreview(page)')
            drawing-preview(:pageUuid='page.uuid')
            .page-number {{ page.number }}
          .d-flex.align-center
            v-hover(v-slot:default='{hover}')
              v-btn(v-if='!(renamingPage && page.uuid == renamingPage.uuid)' x-small icon @click.stop='startOrBreakRenaming(page)')
                img(:src="hover ? '/img/project/tools/writing-pencil-dark.svg': '/img/project/tools/writing-pencil.svg'")
              v-btn(v-else x-small icon @click='pageRename(page)')
                v-icon(color='#757575') mdi-check
            input.mx-1.page-rename(v-if='renamingPage && renamingPage && page.uuid == renamingPage.uuid' v-model='page.name' @keydown='confirmRenaming' @input='pageRename(page)' @click.stop)
            .overflow-hidden.mx-1(v-else) {{ page.name }}
            v-switch.ml-auto.my-0( v-model="page.included" color="accent" @change="pageIncluding(page)" dense light)
    
    //- Выбранный чертеж
    drawing-loader( v-if='store.pageIsLoading' )
    drawing-page.ml-1(v-show='store.selectedDrawingPage && !store.pageIsLoading' ref='new-drawing-page')
</template>

<script>
import DrawingPreview from '@/components/drawings/DrawingPreview.vue'
import DrawingPage from '@/components/drawings/page/DrawingPage.vue'
import DrawingLoader from './DrawingLoader.vue';
import { useDrawingsStore } from '@/pinia';
import { useDrawingPageState } from '@/pinia';
import { selectPagePreview } from './facades/drawingByModelList.facade';

export default {
  name: "DrawingPageSelecting",

  components: { DrawingPreview, DrawingPage, DrawingLoader },

  data() {
    return {
      includeTimeout: null,
      renameTimeout: null,
      pagesToInclude: [],
      renamingPage: null,
      arr: [],
      disabledSwitch: false,
    }
  },

  computed: {
    store: () => useDrawingsStore(),
    pageState: () => useDrawingPageState(),

    allIsChoose: {
      get() {
        return this.pagesByDrawingVersion.every(page => {
          this.store.setDrawingsPages(this.pagesByDrawingVersion)
          this.store.setFlagDrawingsPages(true)
          return page.included
        })
      },
      set() {}
    },

    pagesByDrawingVersion() {
      return this.store.getPagesByDrawingVersion(this.store.selectedDrawingPage.version.uuid)
    }
  },
  
  mounted() {
    if (this.store.pageForRecovery) {
      this.selectPagePreview(this.store.pageForRecovery)
      this.store.setPageForRecovery(null)
    }
    this.$refs.previews?.scrollTo(0, 142 * this.pagesByDrawingVersion.findIndex(page => page.uuid == this.store.selectedDrawingPage.uuid))
  },

  methods: {
    selectPagePreview(page, comment = null, withSidePanel = false) {
      selectPagePreview(page, comment, withSidePanel)
    },

    scaleSelectedDrawingPage() {
      this.$refs.drawing?.drawingPageScale()
    },

    selectedPageFitSize() {
      this.$refs.drawing?.pageFitSize()
    },

    scrollPageToComment(comment) {
      this.$refs['new-drawing-page']?.scrollPageToComment(comment)
    },

    pageRename(page) {
      if (this.renameTimeout) {
        clearTimeout(this.renameTimeout)
        this.renameTimeout = setTimeout(() => {
          this.store.renamePage(page)
          this.renameTimeout = null
        }, 1000)
      }
      else {
        this.renameTimeout = setTimeout(() => {
          this.store.renamePage(page)
          this.renameTimeout = null
        }, 1000)
      }
    },

    pageIncluding(page) {
      const existingPageIndex = this.pagesToInclude.findIndex(p => p.uuid == page.uuid)
      if (existingPageIndex >= 0){
        this.pagesToInclude[existingPageIndex] = page
      } 
      else {
        this.pagesToInclude.push(page)
      }

      if (this.includeTimeout) {
        clearTimeout(this.includeTimeout)
        this.includeTimeout = setTimeout(() => {
          this.store.includePages(this.pagesToInclude)
          this.pagesToInclude = []
          this.includeTimeout = null
        }, 2000)
      }
      else {
        this.includeTimeout = setTimeout(() => {
          this.store.includePages(this.pagesToInclude)
          this.pagesToInclude = []
          this.includeTimeout = null
        }, 2000)
      }
    },

    includeAll() {
      if (this.allIsChoose) {
        this.store.includePages(this.pagesByDrawingVersion.map(page => {
          page.included = !page.included
          return page
        }))
      }
      else {
        const pagesToInclude = this.pagesByDrawingVersion.filter(page => !page.included)
        this.store.includePages(pagesToInclude.map(page => {
          page.included = !page.included
          return page
        }))
      }
    },

    hideSidePanel() {
      this.store.sidePanelVisible = !this.store.sidePanelVisible
      return this.store.sidePanelVisible
    },

    startOrBreakRenaming(page = null) {
      this.renamingPage = page
    },
    
    confirmRenaming(event) {
      if(event.key === 'Enter') {
        this.renamingPage = null
      }
    },

    delayToSwitch() {
      setTimeout(() => this.disabledSwitch = false, 500)
    },
  },

  watch: {
    allIsChoose() {
      this.disabledSwitch = true
      this.delayToSwitch()
    },
  },
}
</script>

<style lang="scss" scoped>
.drawings-pages{
  background: white;
  display: flex;
  flex-direction: column;
  color: #262626;
  font-weight: 400;
  border-radius: 3px;
  place-items: center;
  min-width: 250px;
  position: relative;
  top: 0px; right: 0px; left: 0px; bottom: 0px;
  overflow: auto;
}
.drawing-pages-selecting{
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
}
.page{
  width: 210px;
}
.page-preview{
  background: white;
  position: relative;
  width: 210px;
  height: 110px;
  transition-duration: .2s;
  border: 1px solid black;
}
.overflow-hidden{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #757575;
  font-size: 12px;
}
.selected-preview-page{
  border: 2px solid #3B93AF;
}
.disactive{
  opacity: 0.4;
  transition-duration: .2s;
}
.header{
  min-height: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1;
  background: #e5e5e5;
  border-bottom: 1px solid #bbb;
  margin-bottom: 4px;
  box-shadow: 0px 2px 5px -2px #bbb;
  font-size: 12px;
  color: #757575;
  font-weight: 400;
}
.page-number{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  background: #C4C4C4;
  color: black;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-rename{
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #E5E5E5;
  font-size: 13px;
  color: #000000;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.65);
}
</style>