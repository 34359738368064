<template lang="pug">
app-dialog(v-model="$_show" header="Import IDS" width="500")
  app-section
    v-form(v-on:submit.prevent="saveFile")
      app-file-input( v-model="file" :formats="['.ids','.xml']" )
      .d-flex
        v-spacer
        app-button( action type="submit" :disabled="file == null ? true : false || importLoading") {{$t('button.import')}}
    
    v-progress-linear.mt-3(v-if="importLoading" indeterminate)
    div(v-if="importError")
      app-text {{ importError }}
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"
import { api } from "@/api"
export default {
  name: "RuleImportIDSDialog",
  mixins: [DialogToggable],

  props: {
  },

  data () {
    return {
      file: null,
      importLoading: false,
      importError: null
    }
  },

  watch: {
    $_show(){
      this.file = null
      this.importLoading = false
      this.importError = null
    }
  },

  computed: {
    ...mapState('project', ['project']),
    ...mapGetters('rule/details', ['rule']),
  },

  methods:{
    ...mapMutations('rule/details', ['SET_RULE']),

    saveFile(){
      this.importLoading = true
      let formData = new FormData()
      formData.append('rule', this.rule.uuid)
      formData.append('file', this.file)
      api.collisionRules.importIDS(formData).then((data) => {
        if(data.errors.length == 0 && data.rule) {
          this.importLoading = false
          this.$_show = false
          this.SET_RULE(data.rule)
        } else {
          this.importError = data.errors
        }
        
      }).catch(e => this.importError = e)
    }
  }
}
</script>