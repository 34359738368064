<template lang="pug">
  div 
    div.bar-rule-text(v-if="!editMode" :class="{'pointer': !disabled, 'gray-filter':disabled}" @click="switchEditMode(true)")
      div(v-html="logicGroupTitle")


    v-card(v-show="editMode && !disabled" light)
      v-card-text
        v-row(no-gutters v-for="(row, index) in conditionRows" :key="index")
          v-col(cols="auto")
            v-btn.mt-1.px-0( color="primary" dense text small min-width="28" disabled)
              span(v-if="row.start!=null && row.start!=''") {{ row.start }}
          v-col.overflow-hidden
            div.pseudoinput.d-flex.flex-row-reverse.flex-nowrap.mt-2.pa-1.rounded(:class="row.attr.locked ? 'not-allowed' : 'pointer'" @click="editCondition(row.attr)")
              app-text.text-nowrap {{row.attr.title}}
          v-col(cols="auto")
            v-btn.mt-1.px-0( color="primary" dense text small min-width="28" disabled)
              span(v-if="row.end!=null && row.end!=''") {{ row.end }}
          v-col(cols="auto")
            v-btn.mt-1.px-0( color="primary" dense text small min-width="28" disabled)
              span.caption(v-if="row.logic!=null && row.logic.title!=''") {{ row.logic.title }}
          v-col(cols="auto" v-if="row.attr.locked")
            v-icon.mt-2.ml-1( color="grey" small :title="$t('section.worm.blockingSubstitution')") mdi-lock
          v-col(cols="auto")
            v-btn.mt-1.px-0(v-if="row.isSubstitution" color="primary" dense text small min-width="28" :title="$t('section.worm.deleteCondition')")
              v-icon( color="grey" @click.stop="removeSubstitutionCond(row)" small) clear

      v-card-actions
        v-spacer
        .d-flex.align-center.pa-2
          v-btn.text-none.dialog-confirm-action.ml-auto.mr-2(fab small depressed light @click="switchEditMode(false)") 
            v-icon mdi-close
          v-btn.text-none.dialog-confirm-action(fab color="accent" small depressed light @click="checkLogicGroup()")
            v-icon mdi-check



    element-condition(v-if="!disabled" v-model="dialogCondition" :condition.sync="condition" @created="afterCreateCondition" :axisFilter="axisFilter" :simpleView="simpleView") 
    
</template>

<script>

let lo = {
  'AND':'И',
  'OR':'ИЛИ',
  'NOT':'НЕ'
}
import { mapState, mapGetters } from 'vuex'
import ElementCondition from '../../../components/logicGroup/Condition'

import WormConditionValid from '@/assets/model/condition/WormConditionValid'
import ElementWormConditionValid from '@/assets/model/condition/ElementWormConditionValid'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name:'ConditionSubstitution',

  components: {
    ElementCondition
  },

  props: {
    rule: Object,
    conditionLinks: Array,
    axisFilter: {
      type: Array,
      default: null
    },
    simpleView: {
      type: Boolean,
      default: false
    },
    wormUuid: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editMode: false,
      editLogicGroup: null,
      conditionRows: [],

      dialogCondition: false,
      condition: null,

      newConditionLinks:[]
    }
  },

  mounted() {
    this.createRule()
  },

  computed: {
    ...mapState('worm', ['checkLogicMode']),
    ...mapGetters('project', ['logicOperator', 'isLogicOperatorLoaded']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    wormConditionValid(){
      return WormConditionValid.find(this.wormUuid)
    },

    elementWormConditionValid(){
      return ElementWormConditionValid.find([this.wormUuid, this.pickedElement])
    },

    startBracket() {
      return ["", "(", "((", "((("]
    },
    endBracket() {
      return ["", ")", "))", ")))"]
    },
    logic() {
      let l = this.logicOperator.filter(lo => lo.name!='NOT').slice(0)
      l.unshift({"value": -1, "title": "", "name": ""})
      return l
    },
    logicGroupTitle() {
      if (this.rule && 
        (this.rule && this.rule.children && this.rule.children.length > 0)
        || (this.rule && this.rule.condition && this.rule.condition.length > 0)
      ) return this.makeLogicGroupText(this.rule, "", "", null)

      return this.$t('section.classification.menuClassification.setCondition')
    }
  },

  watch: {
    rule(v, ov) {
      if (v != null && ov != null && v.uuid != ov.uuid) this.editMode = false
      this.createRule()
    }
  },

  methods: {
    createRule() {
      this.editLogicGroup = JSON.parse(JSON.stringify(this.rule))
      this.newConditionLinks = this.conditionLinks == null ? [] : JSON.parse(JSON.stringify(this.conditionLinks))

      this.showingTitle = ''
      this.conditionRows = []
      this.makeConditionRow(this.editLogicGroup, "", "", null)
    },

    switchEditMode(flag) {
      if (!this.disabled) {
        this.createRule()
        this.editMode = flag
      }
    },

    checkLogicGroup() {
      this.$emit('updateConditionLinks',this.newConditionLinks)
      this.editMode = false
    },

    replaceOperator(o) {
      switch(o.name){
        case 'EQ': return '='
        case 'NOTEQ': return '!='
        case 'GT': return '>'
        case 'LT': return '<'
        case 'GTEQ': return '>='
        case 'LTEQ': return '<='
        default: return o.title
      }
    },

    makeConditionRow(logicGroup, start, end, prevOperatorType) {

      let rows = []
      if(logicGroup.condition)
        logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) {
        logicGroup.children.forEach(lg => rows.push(lg))
      }
      rows.sort((a, b) => a.sortOrder - b.sortOrder)

      
      rows.forEach((row, index) => {
        if(row.logicOperatorType !== undefined ){
            if(rows.length==1) 
                this.makeConditionRow(row, start+"(", end+")", prevOperatorType)
            else if(index==0) 
                this.makeConditionRow(row, start+"(", ")", logicGroup.logicOperatorType)
            else if(index<rows.length-1) 
                this.makeConditionRow(row, "(", ")", logicGroup.logicOperatorType)
            else                                  
                this.makeConditionRow(row, "(", end+")", prevOperatorType)
        } else {
            let newRow = Object.assign({}, this.defaultConditionRow)
            newRow.isSubstitution = false
            newRow.uuid = row.uuid

            if (!row.locked) {
              if (this.newConditionLinks != null && this.newConditionLinks.length > 0){
                let link = this.newConditionLinks.find(el => el.realConditionUuid == row.uuid) || null
                if (link != null && link.substituteCondition != null) {
                  row = link.substituteCondition
                  newRow.isSubstitution = true
                  newRow.uuid = link.substituteCondition.uuid
                }
              }
            }

            newRow.attr = row

            if(index==0 || index<rows.length-1) {
               newRow.logic = logicGroup.logicOperatorType
            } else if(prevOperatorType!=null) {
               newRow.logic = prevOperatorType
            }

            if(index==0 ) {
              newRow.start=start
              start=""
            }

            if(index==rows.length-1) {
              newRow.end=end
              end=""
            }

            this.conditionRows.push(newRow)
        }
      })
    },

    makeLogicGroupText(logicGroup, start, end, prevOperatorType) {
      let text = ''
      let rows = []
      logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) {
        logicGroup.children.forEach(lg => rows.push(lg))
      }
      rows.sort((a, b) => a.sortOrder - b.sortOrder)

      
      rows.forEach((row, index) => {
        if(row.logicOperatorType !== undefined) {
          if(rows.length==1) text += this.makeLogicGroupText(row, start+"(", end+")", prevOperatorType)
          else if(index==0) text += this.makeLogicGroupText(row, start+"(", ")", logicGroup.logicOperatorType)
          else if(index<rows.length-1) text += this.makeLogicGroupText(row, "(", ")", logicGroup.logicOperatorType)
          else text += this.makeLogicGroupText(row, "(", end+")", prevOperatorType)
        } else {
          
          let isSubstitute = false
          let uid = row.uuid
          if (!row.locked) {
            if (this.newConditionLinks != null && this.newConditionLinks.length > 0){
              let link = this.newConditionLinks.find(el => el.realConditionUuid == uid) || null
              if (link != null && link.substituteCondition != null) {
                uid = link.substituteCondition.uuid
                row = link.substituteCondition
                isSubstitute = true
              }
            }
          }
          
          if(index==0){
            text += ' '+start
            start=''
          }

          let cClass = []
          let validy = null
          if (this.checkLogicMode == 0) {
            validy = this.wormConditionValid?.conditionsValid.find(e => e.conditionUuid == uid)
          } else if (this.checkLogicMode == 1 && this.pickedElement) {
            validy = this.elementWormConditionValid?.conditionsValid.find(e => e.conditionUuid == row.uuid);
          } 

          if (isSubstitute) cClass.push('condition-substitute')
          if (validy && validy.valid) cClass.push('condition-validy-valid')
          else if (validy && !validy.valid) cClass.push('condition-validy-invalid')

          text += '<span class="'+cClass.join(' ')+'">'
          

          let op = row.operator.title.toLowerCase()
          let originalRp = row.title.substring(row.title.indexOf(op) + op.length + 1)
          let rp = (op === 'элемент из набора') 
          ? `<span style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color:#eb5757;">${originalRp}</span>`
          : `<span style="color:#eb5757;">${originalRp}</span>`
          let t = row.title.replace(originalRp, '<span style="color:#eb5757;">"' + rp + '"</span>')
          t = t.replace(row.operator.title.toLowerCase(),'<span style="color:#9b51e0;">'+this.replaceOperator(row.operator)+'</span>')
          text += t

          text += '</span>'

          if(index==rows.length-1) {
            text += end
            end=''
          }

          if((index==0 || index<rows.length-1) && logicGroup.logicOperatorType!=null) {
            if (!logicGroup.logicOperatorType.title) logicGroup.logicOperatorType.title = lo[logicGroup.logicOperatorType.value]
            text += ' <span style="color:#9b51e0;">'+logicGroup.logicOperatorType.title.toLowerCase()+'</span> '
          } else if(prevOperatorType!=null && prevOperatorType.title) text +=  ' <span style="color:#9b51e0;">'+prevOperatorType.title.toLowerCase()+'</span> '
        }
      })

      return text
    },

    editCondition(cond) {
      if (!cond.locked){
        this.condition = cond
        this.dialogCondition = true
      }
    },

    afterCreateCondition(cond){
      if (JSON.stringify(this.condition) != JSON.stringify(cond)) {
        let link = null
        if (this.newConditionLinks != null) {
          link = this.newConditionLinks.find(el => el.realConditionUuid == this.condition.uuid || el.substituteCondition.uuid == this.condition.uuid) || null
        }

        delete cond.uuid
        if (link == null) {
          this.newConditionLinks.push({realConditionUuid:this.condition.uuid,substituteCondition:cond})
        } else {
          cond.uuid = link.substituteCondition.uuid
          link.substituteCondition = cond
        }
        
        Object.assign(this.condition, cond)
        this.refreshView()
      }
      
      this.dialogCondition = false
    },

    removeSubstitutionCond(row){
      let ind = this.newConditionLinks.findIndex(el => el.substituteCondition.uuid == row.attr.uuid)
      if (ind > -1) {
        this.newConditionLinks.splice(ind, 1)
        this.refreshView()
      }
    },

    refreshView(){
      this.showingTitle = ''
      this.conditionRows = []
      this.makeConditionRow(this.editLogicGroup, "", "", null)
    }
  }
}
</script>

<style scoped>
  .not-allowed {cursor: not-allowed}
  .bar-rule-text {
    font-size:14px;
    background: #f0f0f0;
    padding:5px;
    color:#505254;
  }
  .gray-filter {
    filter:grayscale(50%);
  }

  .pseudoinput {
    background: #f0f0f0;
  }

  .theme--light.v-btn.v-btn--disabled {
    color: black !important;
  }

.text-nowrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>