<template lang="pug">
div.d-flex
  div.d-flex.flex-column.align-start.justify-start.viewer-ruler-menu
    div.button.full-width( v-for="(regimeMeasurement, index) in regimeMeasurementsModes" 
                                                  :class="{ 'mb-2': index < regimeMeasurementsModes.length - 1, 'active-viewer-ruler-menu': regimeMeasurement.active }"
                                                  @click="regimeMeasurement.action" @mouseup="handleMouseUp(regimeMeasurement.action)")
      v-hover( #default="{ hover }" )
        div.full-width( :style="hover ? 'background-color: #c0c0c0;' : ''" )
          div.pl-3.d-flex.align-center.justify-start
            v-icon.style( :size="32" 
                          :class="{ 'active-viewer-ruler-menu__icon': regimeMeasurement.active }"
                          color="white" 
                          test-id="left-panel-btn" 
                          @click.native="$emit('click')" ) {{ regimeMeasurement.icon }}
            div.d-flex
              span.ml-3( v-html="regimeMeasurement.title" )
</template>

<script>
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

export default {
  data() {
    return {
      showPanel: false,
      menuActivator: {
        value: false
      },
      mouseDownTime: 0,
      openMenuTimeout: null
    }
  },

  computed: {
    RegimeMeasurement() {
      return XeokitMediator.RegimeMeasurement
    },

    regimeMeasurementsModes() {
      return [
        { menuIcon:"$viewer-ruler-edge-menu-icon", icon:"$viewer-ruler-edge-icon", size:"32", title: "Ребро", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.EdgeMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.EdgeMeasurement) },
        { menuIcon:"$viewer-ruler-edge-to-edge-menu-icon", icon:"$viewer-ruler-edge-to-edge-icon", size:"32", title: "Ребро к ребру", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.EdgeToEdgeMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.EdgeToEdgeMeasurement) },
        { menuIcon:"$viewer-ruler-point-to-edge-menu-icon", icon:"$viewer-ruler-point-to-edge-icon", size:"32", title: "Точка к ребру", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.PointToEdgeMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.PointToEdgeMeasurement) },
        { menuIcon:"$viewer-ruler-point-to-point-menu-icon", icon:"$viewer-ruler-point-to-point-icon", size:"32", title: "Точка к точке", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.PointToPointMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.PointToPointMeasurement) },
        { menuIcon:"$viewer-ruler-point-to-face-menu-icon", icon:"$viewer-ruler-point-to-face-icon", size:"32", title: "Точка к плоскости", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.PointToFaceMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.PointToFaceMeasurement) },
        { menuIcon:"$viewer-ruler-face-to-face-menu-icon", icon:"$viewer-ruler-face-to-face-icon", size:"32", title: "Плоскость к плоскости", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.FaceToFaceMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.FaceToFaceMeasurement) },
        { menuIcon:"$viewer-ruler-ruler-icon", icon:"$viewer-ruler-ruler-icon", size:"32", title: "Рулетка", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.Ruler, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.Ruler) },
      ]
    },

    isRegimeMeasurementActive() {
      return XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive
    },
  },

  methods: {
    toggleRegimeMeasurements(mode) {
      if (this.isRegimeMeasurementActive) {
        if (mode) XeokitMediator.RegimeMeasurement.setRegimeMeasurementMode(mode)
        else XeokitMediator.RegimeMeasurement.deactivate()
      }
      else {
        XeokitMediator.RegimeMeasurement.activate({ mode: mode })
      }
    },

    handleMouseDown(activator) {
      const currentTime = Date.now();
      const timeDiff = currentTime - this.mouseDownTime;
      this.mouseDownTime = currentTime;

      clearTimeout(this.openMenuTimeout);
      if (timeDiff < 300) {
        return;
      }

      activator.value = true;
      this.menuActivator = activator;
      this.openMenuTimeout = setTimeout(() => {
        if (activator.value) {
          this.openContextMenu();
        }
      }, 300); 
    },

    handleMouseUp(action) {
      clearTimeout(this.openMenuTimeout);
      const mouseUpTime = Date.now();
      const timeDiff = mouseUpTime - this.mouseDownTime;
      if (timeDiff >= 300) {
        action();
      }
      this.menuActivator.value = false;
      this.closeContextMenu();
    },

    toggleMeasurements () {
      if (!this.isDistanceMeasurementActive) {
        XeokitMediator.DistanceMeasurement.activate()
      }
      else {
        XeokitMediator.DistanceMeasurement.deactivate()
      }
    },

    openContextMenu () {
      this.showPanel = !this.showPanel
      if (this.showPanel) {
        document.addEventListener('click', this.closeContextMenu);
      } else {
        document.removeEventListener('click', this.closeContextMenu);
      }
    },
    closeContextMenu() {
      this.showPanel = false;
      document.removeEventListener('click', this.closeContextMenu);
    },
  }
}
</script>

<style lang="scss" scoped>
  .v-menu__content {
    background-color: transparent;
    box-shadow: none;
  }

  .viewer-ruler-menu {
    // margin-top: 32px;
    // border-left: 1px solid #535353;
    background: white;
    background-color: white;
    width: 210px;
    // height: 297px;
    padding: 2px;

    border: 1px solid rgba(219, 219, 219, 1);

    color: #303030;

    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .active-viewer-ruler-menu {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  .active-viewer-ruler-menu__icon {
    background-color: #e6e6e6;
  }

  .full-width {
    width: 100%; 
    width: stretch;
    width: -moz-available; 
    width: -webkit-fill-available;
  }
</style>