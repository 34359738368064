<template lang='pug'>
app-dialog( v-model='$_show' :header="$t('module.task.assignResponder')" :confirm-text='$t("button.confirm")' :confirm-disabled='!selectedTeammate && !selectedGroup'  @confirm='setTaskResponder()' )
  app-section.base-scroll-body( style="height:50vh;" )
    app-section-header-tabs.mb-1( :items="tabItems" :value="teamTab" @input="(tab) => { teamTab = tab; tab === 0 ? selectedGroup = null : selectedTeammate = null }" light )

    template(v-if="teamTab == 0")
      teammate.pointer( v-for='teammate in allActiveTeammates' :key='teammate.uuid' :teammate='teammate' :class='{ "elevation-1": selectedTeammate === teammate }' @click.native='selectedTeammate = teammate' )
      
    template(v-else)
      v-row(v-for="group in availableOrgGroups" :key="group.uuid" dense)
        .group-title.text-14(@click="selectedGroup = group.uuid" :style="group.uuid === selectedGroup ? 'font-weight: bold' : ''") {{group.title}}

</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import Teammate from '@/components/project/common/Teammate.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "SetTaskResponderDialog",

  components: {
    Teammate,
  },

  mixins: [DialogToggable],

  props: {
    task: Object,
  },

  data() {
    return {
      teamTab: 0,
      selectedTeammate: null,
      selectedGroup: null
    }
  },

  computed: {
    ...mapGetters('project', ['allActiveTeammates']),
    ...mapGetters('corp', {orgGroups:'groups'}),

    tabItems() {
      return [
        { title: this.$t('drawer.users'), value: 0 },
        { title: this.$t('corp.group'), value: 1 }
      ]
    },

    taskOrgGroups() {
      return this.task.groupWorkers
    },

    availableOrgGroups() {
      return this.orgGroups
    },
  },

  watch: {
    $_show() {
      this.selectedTeammate = this.allActiveTeammates.find(teammate => teammate.profile.uuid === this.task.responder?.uuid)
      this.selectedGroup = this.task?.groupResponder?.uuid

      if (this.selectedTeammate) this.teamTab = 0
      if (this.selectedGroup) this.teamTab = 1
    }
  },

  methods: {
    ...mapActions('task', ['setResponder', 'setWorkers', 'setGroupWorkers', 'setGroupResponder']),

    setTaskResponder() {
      if (this.selectedTeammate) {
        const teammateProfileUuid = this.selectedTeammate.profile.uuid
        const taskWorkerUuids = this.task.workers.map(worker => worker.uuid)
        let inWorkers = taskWorkerUuids.includes(teammateProfileUuid)
        if (!inWorkers) {
          this.setWorkers({
            uuid: this.task.uuid,
            workerIds: taskWorkerUuids.concat(teammateProfileUuid)
          })
        }
        this.setResponder({ uuid: this.task.uuid, responderUuid: this.selectedTeammate.profile.uuid })
      } else {
        let selectGroupUuid = this.selectedGroup
        let groupsUuid = this.task.groupWorkers.map(group => group.uuid)
        let inWorkers = groupsUuid.includes(selectGroupUuid)
        if (!inWorkers) {
          this.setGroupWorkers({ 
            uuid: this.task.uuid,
            groupWorkerIds: groupsUuid.concat(selectGroupUuid)
          })
        }
        this.setGroupResponder({ uuid: this.task.uuid, wGroupUuid: this.selectedGroup })
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.group-title {
  margin: 4px;
  font-weight: 400; 
  line-height: 26px; 
  color: #262626;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
</style>