import { defineStore } from 'pinia'

export const useElementsColorStore = defineStore('elements-color', {
  state: () => ({
    colors: new Map(),
    colorizedItems: new Map(),
  }),

  actions: {
    setColorForItems (color, items) {
      items.forEach(item => {
        if (!this.colorizedItems.has(item)) {
          this.colorizedItems.set(item, color)
          this.addItemToColor(item, color)
        }
      })
    },

    addItemToColor (item, color) {
      let items = this.colors.get(color) ?? new Set()
      items.add(item)
      this.colors.set(color, items)
    }
  }
})
