<template lang="pug">
.anchor-point
  .anchor-point__vertical-line
  .anchor-point__gorizontal-line
  .anchor-point__text {{ number }}
</template>

<script>
export default {
  props: {
    number: Number
  }
}
</script>

<style scoped>
.anchor-point {
  width: 20px;
  height: 20px;
}

.anchor-point__vertical-line {
  height: 20px;
  width: 10px;
  border-right: solid;
  border-width: 1px;
  border-color: rgb(255, 0, 255);
  position: absolute;
  top: 0px;
  left: 0px;
}

.anchor-point__gorizontal-line {
  height: 10px;
  width: 20px;
  border-bottom: solid;
  border-width: 1px;
  border-color: rgb(255, 0, 255);
  position: absolute;
  top: 0px;
  left: 0px;
}

.anchor-point__text {
  position: absolute;
  top: 8px; 
  left: 0px;
  color: rgb(255, 0, 255);
  font-size: 14px; 
  line-height: 22px;
  font-weight: 500;
}
</style>