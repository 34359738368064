<template lang="pug">
img.image_style( ref='drawing-page-image' id="drawingPageImage" )
</template>

<script>

export default {
  name: 'DrawingImage',
}
</script>

<style scoped>
.image_style {
  position: absolute;
}
</style>