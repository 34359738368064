<template lang="pug">
  app-dialog(v-model='store.dialogVisible' :header="$t('module.drawing.addComment.header')" :confirmText="$t('button.add')" @confirm='createNewComment()' :confirmDisabled='store.tasksIsLoading')
    app-section.comment-create-dialog-section
      app-textarea.mt-3.fill-height.overflow-auto( v-model="store.newCommentForm.text" :label="$t('module.drawing.addComment.placeholder')" no-resize rows="6" outlined hide-details test-id="drawing-page-comment-input")
      v-checkbox(v-if="hasTaskCreate" v-model="store.showCommentTaskBinding" :label="$t('module.drawing.task.addToTask')" color="accent" light test-id="add-comment-to-task")
      //- app-select( v-if="hasTaskCreate && showCommentTaskBinding" v-model="newCommentForm.task" :items="tasks" :label="$t('module.drawing.task.chooseTask')"  item-text="title" test-id="drawing-page-bind-task" )
      app-autocomplete(v-if="hasTaskCreate && store.showCommentTaskBinding" v-model='store.newCommentForm.task' :items='tasks' :label="$t('module.drawing.task.chooseTask')" item-text='title' test-id='drawing-page-bind-task')

      .tasks-loading-canopy( v-if='store.tasksIsLoading' )
        v-progress-circular(color='accent' indeterminate)
    app-menu(ref='drawing' test-id="drawing-page-list")
</template>

<script>
import { useDrawingsStore } from '@/pinia';
import { mapGetters, mapState } from 'vuex'

export default {
  name: "DrawingPageCommentCreateDialog",
  
  props: {
    coords: Object
  },

  computed: {
    ...mapState('task', ['tasks', 'selectedTask']),
    ...mapState('authUser', ['user']),
    ...mapGetters('projectPermissions', ['hasTaskCreate']),

    store: () => useDrawingsStore()
  },

  methods: {
    createNewComment() {
      let commentForm = {
        user: this.user,
        page: this.store.selectedDrawingPage,
        src: '',

        x: this.coords.xCoord,
        y: this.coords.yCoord,
        scale: this.store.scaleDrawing,

        task: this.store.showCommentTaskBinding ? this.store.newCommentForm.task : "",
        text: this.store.newCommentForm.text
      }
      
      this.store.newComment(commentForm)
    }
  }
}
</script>

<style lang="scss" scoped>
  .comment-create-dialog-section {
    position: relative;
  }

  .overflow-auto {
    overflow-x: hidden !important;
    margin: 2px 2px 2px 0px;
  }

  .tasks-loading-canopy {
    width: 100%;
    height: 100%;
    
    position: absolute;

    opacity: .8;
    background: white;

    top: 0px;
    left: 0px;

    pointer-events: all;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>