import { prepareAxisCache } from './axis.facade'
import { resetGridsCache } from './grids.facade'
import { prepareGroupsCache, resetGroupsCache } from './groups.facade'
import { resetSceneCache } from './scene.facade'

export async function prepareProjectCache () {
  prepareAxisCache()
  prepareGroupsCache()
}

export function resetProjectCache () {
  resetGroupsCache()
  resetSceneCache()
  resetGridsCache()
}