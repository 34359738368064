<template lang="pug">
div( ref="highlight-treeview-container" style="height: 100%" )
  virtual-list.base-scroll-body.v-list(
    :keeps="treeElementKeeps",
    :estimate-size="treeElementHeight"
    data-key="uuid"
    :data-component="highlightGroupItemComponent"
    :data-sources="elements"
    :extra-props="{ toggleGroup: toggleGroup, selectGroup: selectGroup, selectedGroups: selectedGroups }"
  )
</template>

<script>
import HighlightGroupItem from './HighlightGroupItem.vue'
import VirtualList from 'vue-virtual-scroll-list'

export default {
  components: {
    HighlightGroupItem,
    VirtualList
  },

  props: {
    elements: [],
    selectedGroups: []
  },

  data() {
    return {
      treeElementKeeps: 20,
      treeElementHeight: 18,
      highlightGroupItemComponent: HighlightGroupItem
    }
  },

  methods: {
    toggleGroup(item) {
      this.$emit('toggleGroup', item)
    },

    selectGroup(uuid) {
      this.$emit('selectGroup', uuid)
    },

    changeKeepElements() {
      const smetaTreeContainer = this.$refs['highlight-treeview-container']
      if(smetaTreeContainer != undefined){
        this.pageResizeObserver = new ResizeObserver(() => {
          this.treeElementKeeps = Math.ceil(smetaTreeContainer.clientHeight / this.treeElementHeight) + 10
        })
        this.pageResizeObserver.observe(smetaTreeContainer)
      }
    },
  },

  mounted() {
    this.changeKeepElements()
  }
}
</script>

<style scoped>
.v-list {
  color: rgba(0,0,0,.87);
  font-size:12px;
  height: calc(100% - 32px);
}
</style>