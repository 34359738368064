var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.importProjStruct)?_c('div',{staticClass:"unit"},[_c('v-tooltip',{attrs:{"right":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"unit__offset-marker",style:({ borderColor: _vm.modelGroup.color }),on:{"click":_vm.cameraFlyToModelGroup}},on))]}}],null,false,47508105)},[(_vm.modelGroup.offset[0] == 'noRevision')?_c('div',[_vm._v(_vm._s(_vm.$t('section.structure.model.offsetGroup.noRevision')))]):_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('section.structure.model.offsetGroup.title')))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s("x: " + _vm.modelGroup.offset[0] + ", "))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s("y: " + _vm.modelGroup.offset[1] + ", "))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s("z: " + _vm.modelGroup.offset[2]))])])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"800"},scopedSlots:_vm._u([(_vm.model.archived == _vm.archived || _vm.archived == true)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"unit__row",style:({ borderColor: _vm.modelGroup.color })},on),[_c('v-hover',{attrs:{"close-delay":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"unit__bar app-cursor--context",class:_vm.unitBarHoveringStyle(hover),attrs:{"test-id":"tree-unit-item"},on:{"click":_vm.toggle,"contextmenu":function($event){$event.stopPropagation();return _vm.showMenu.apply(null, arguments)}}},[(hover && _vm.hasProjectStruct)?_c('v-icon',{staticClass:"unit__drag",attrs:{"color":_vm.unitButtonsColor,"size":"18"}},[_vm._v("drag_indicator")]):_vm._e(),_c('span',{staticClass:"unit__title"},[_vm._v(_vm._s(_vm.isAlias ? _vm.$t(_vm.model.title) : _vm.model.title))]),(hover && _vm.checkRole)?_c('v-icon',{staticClass:"unit__action",attrs:{"color":_vm.unitButtonsColor},on:{"click":function($event){$event.stopPropagation();return _vm.showMenu.apply(null, arguments)}}},[_vm._v("more_vert")]):_vm._e()],1)]}}],null,true)}),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSwitch),expression:"showSwitch"}],staticClass:"unit__switch",attrs:{"value":_vm.model.uuid,"color":"accent","dense":"","light":""},model:{value:(_vm.models),callback:function ($$v) {_vm.models=$$v},expression:"models"}})],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isAlias ? _vm.$t(_vm.model.title) : _vm.model.title)+" ")])])],1):_c('div',{staticClass:"unit"},[_c('v-tooltip',{attrs:{"right":"","open-delay":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"unit__row",style:({ borderColor: '#ff7070' })},on),[_c('v-hover',{attrs:{"close-delay":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"unit__bar app-cursor--context",class:{'elevation-5': hover, 'title-padding': hover},style:(_vm.unitBarColor)},[(hover && _vm.hasProjectStruct && !_vm.disabled && !_vm.model.model)?_c('v-icon',{staticClass:"unit__drag",attrs:{"color":_vm.unitButtonsColor,"size":"18"}},[_vm._v("drag_indicator")]):_vm._e(),_c('span',{staticClass:"unit__title"},[_vm._v(_vm._s(_vm.$t(_vm.model.nameModel? _vm.model.nameModel: _vm.model.title )))])],1)]}}],null,true)})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.model.nameModel? _vm.model.nameModel: _vm.model.title))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }