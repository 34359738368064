export const SortEnum = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC',
})

export class DateTransform {

  static sortTaskDate(sortedTask, type) {
    const sortOrder = type === SortEnum.ASC ? 1 : -1;

    return sortedTask.sort((a, b) => {
      const dateA = new Date(a.startDate.replaceAll('-', '/'));
      const dateB = new Date(b.startDate.replaceAll('-', '/'));
  
      return sortOrder * (dateA - dateB);
    });
  }

  static splitDateWithoutTime(date) {
    const splitedDate = date.split('-')
    return splitedDate[2].split(' ')[0] + '.' + splitedDate[1] + '.' + splitedDate[0]
  }
}