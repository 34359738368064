<template lang="pug">
div
  div.mx-2( :class="checkTasksExist()" )
    div.left-container
      div.align-self-center
        app-icon-button( icon="$task-menu-icon" iconSize="16" @click="showMenu" test-id="menu-btn")
      span.header-text.ml-2 {{ $tc('section.collision.tableTitle', [countCollision]) }}

    div.middle-container( v-if="preparedCollisionsTask.length > 0" )
      v-btn( x-small test-id="all-collisions-list" :color="getColorCollision" @click="changeActiveItems(false)" ) {{ $t('section.collision.title') }}
      v-btn.ml-1( x-small test-id="in-task-list" :color="getColorTask" @click="changeActiveItems(true)" ) {{ $t('module.task.inTask') }}

    div.right-container
      app-toolbar( horizontal )
        app-toolbar-button( :icon="getVectorIcon" iconSize="20" :title="$t('section.collision.changeView')" @click="changeCollisionVisible" )
        app-toolbar-button( v-if="!taskCollision.isActive" icon="mdi-arrow-expand-vertical" iconSize="20"
                            :title="$t('section.collision.table.expandGroups')" @click="toggleAllGroup(true)" )
        app-toolbar-button( v-if="!taskCollision.isActive" icon="mdi-arrow-collapse-vertical" iconSize="20"
                            :title="$t('section.collision.table.collapseGroups')" @click="toggleAllGroup(false)" )
        app-toolbar-button( v-if="isElementSelected" icon="mdi-autorenew" iconSize="20"
                            :title="$t('section.collision.table.switchElements')" @click="switchElements")
        app-toolbar-button( icon="settings" iconSize="20" :title="$t('section.collision.settings')" @click="showColumnSettingsDialog" )
        app-toolbar-button( :icon="chevron" iconSize="20" :title="$t('section.collision.table.showHideTable')" @click="showHideTable")

  column-settings-dialog( ref="columnSettingsDialog" )
  
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ColumnSettingsDialog from '../dialogs/CollisionListColumnSettingsDialog.vue'
import CollisionHighlightService from '@/components/project/panel/left/components/collision/CollisionHighlightService.js'
import { PanelController } from '@/pages/panelController'

export default {
  components: {
    ColumnSettingsDialog,
  },

  data() {
    return {
      chevron: 'mdi-chevron-down'
    }
  },

  computed: {
    ...mapGetters('collision/search', ['preparedCollisionsTask', 'choosenId']),
    ...mapGetters('collision/table', ['countCollision']),

    ...mapState('collision/table', ['taskCollision','selectedType']),
    ...mapState('collision/search', ['intersectionModel']),

    isElementSelected() {
      return !!this.choosenId
    },

    getVectorIcon() {
      let src
      switch (this.selectedType) {
        case 0:
          src = "$vector-link-icon"
          break;
        case 1:
          src = "$vector-difference-ab-icon"
          break;
        case 2:
          src = "$vector-difference-ba-icon"
          break;
        case 3:
          src = "$vector-intersection-icon"
          break;    
      }
      return src
    },

    getColorCollision() {
      return this.taskCollision.isActive ? '' : 'accent'
    },
    getColorTask() {
      return this.taskCollision.isActive ? 'accent' : ''
    },
  },

  destroyed(){
    this.SET_SELECTED_TYPE(0)
    CollisionHighlightService.settingSelectedObjects()
  },

  methods: {
    ...mapActions('collision/table', ['selectCollisions', 'tableItems', 'toggleAllGroup']),
    ...mapMutations('collision/table', ['SET_HIDDEN_ITEMS','SET_SELECTED_TYPE']),

    showMenu(event) {
      this.$emit('showMenu', event)
    },

    resetSelectedCollisions() {
      this.selectCollisions([])
    },

    showColumnSettingsDialog() {
      this.$refs.columnSettingsDialog.show()
    },

    changeCollisionVisible(){ 
      let type = (this.selectedType + 1) % 4
      // if (!this.intersectionModel && type === 3) type = 0

      this.SET_SELECTED_TYPE(type)
      CollisionHighlightService.settingSelectedObjects()
    },

    switchElements() {
      CollisionHighlightService.changeSelectedElement()
    },

    showHideTable() {
      let table = document.querySelector('.v-data-table__wrapper')
      if (table.style.display === 'block' || table.style.display === '') {
        table.style.display = 'none'
        this.chevron = 'mdi-chevron-up'
        this.$emit('hide', true)
      } 
       else {
        table.style.display = 'block'
        this.chevron = 'mdi-chevron-down'
        this.$emit('hide', false)
      } 
      this.$nextTick(() => {
        PanelController.setPanelPadding(true)
      })
    },

    changeActiveItems(type) {
      if (this.taskCollision.isActive != type) {
        this.isTask = type
        this.$emit('changeItems', type)
      }
    },

    checkTasksExist() {
      return {
        'main-container-diff': this.preparedCollisionsTask.length == 0,
        'main-container': this.preparedCollisionsTask.length > 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.main-container-diff {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.left-container {
  display: flex;
  justify-content: flex-start;
}

.middle-container {
  display: flex;
  justify-content: center;
}

.right-container {
  display: flex;
  justify-content: flex-end;
}

.header-text {
  font: normal 14px/32px $roboto;
  color: var(--v-primary-darken2);
}
</style>
