<template lang='pug'>
app-dialog( v-model='$_show' :header='$t("module.task.settings")' )
  app-section
    draggable( v-model='taskTreeHeaders' handle='.tree-settings-item_handle' draggable=".draggable" )
      div.d-flex( v-for='header in taskTreeHeaders' :class='{ "draggable": !header.required }' test-id="task-list-settings-title" )
        v-checkbox.pa-0.ma-0( v-model='header.show' :label='$t(header.text)' color='accent' :disabled='header.required' light )
        v-icon.mt-1.ml-auto.tree-settings-item_handle( v-if='!header.required' light dense test-id="task-list-drag-icon" ) dehaze
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable.vue'
import draggable from 'vuedraggable'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'TaskTreeColumnsSettingsDialog',

  components: {
    draggable,
  },

  mixins: [DialogToggable],

  computed: {
    ...mapState('task', ['treeHeaders', 'taskColumns', ]),
    ...mapState('taskTreeSettings', ['columnType']),

    taskTreeHeaders: {
      get() { 
        return this.treeHeaders 
      },

      set(headers) {
        this.updateHeaders(headers)
      }
    },
  },

  methods: {
    ...mapActions('taskTreeSettings', ['setTaskColumns']),
    ...mapMutations('task', ['setTaskHeaders']),

    updateHeaders(headers) {
      this.setTaskHeaders(headers)
      // this.setTaskColumns(headers).then(() => {
      //   this.$store.dispatch('task/sortTreeHeaders', { columnType: this.columnType, taskColumns: this.taskColumns })
      // })
    }
  }
}
</script>

<style>

</style>