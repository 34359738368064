<template lang="pug">
task-section-expansion( v-if="paramsSize > 0" :title="`${$t('module.task.params')} (${paramsSize})`" headerColorDark noBorder test-id="task-details-section-body" )
  div.mx-2.mt-2
    div.param-card.mb-2.pa-2.flex(v-for='param in listParam' )
      app-text.mt-1
        div(style="font-weight: bold; font-size: 14px;") {{ getName(param.name) }}
        div(v-if="checkIsObject(param.value)" v-html="getObject(param.value)") 
        div(v-else) {{ getValue(param.value) }}

</template>

<script>
import {api} from '@/api'
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';


export default {

  components: {
    TaskSectionExpansion
  },

  props: {
    selectedTask: null,
  },
 
  data() {
    return {
      listParam: [],
    }
  },

  watch: {
    selectedTask() {
      this.getParamsList()
    }
  },

  mounted() {
    this.getParamsList()
  },

  computed: {
    paramsSize() {
      return this.listParam?.length || 0
    }
  },

  methods: {

    getParamsList() {
      api.tasks.getTaskParam(this.selectedTask.uuid).then(data => {
        this.listParam = data
      })
    },

    getName(name) {
      if (name === 'isLate') return "Просрочена"

      return name
    },

    getValue(value) {
      return value
    },

    checkIsObject(value) {
      return typeof value === 'object'
    },

    getObject(object) {
      let list = Object.keys(object).map(
        name => ({ 
          name, 
          value: object[name], 
        })
      )

      let text = ""
      list.forEach(el => {
        text += "<b>" + el.name + "</b>: " + el.value + "<br>"
      })
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.param-card {
  border: 1px solid var(--v-primary-lighten4);
  border-radius: 3px;

  &.active {
    background: var(--v-primary-lighten5);
  }
}
</style>
