<template lang="pug">
  app-dialog( v-model="visible" :header='$t("section.collision.addToGroup")' :confirm-text='$t("button.confirm")' :confirm-disabled='!selectedGroup' @confirm='addToGroup')
    app-section
      app-select( v-model="selectedGroup" :label='$t("section.collision.selectGroup")' :items="collisionGroups" item-text="groupTitle" item-value="groupUuid")
  
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      visible: false, 
      selectedGroup: null,
      selectedRow: null,
      single: false,
    }
  }, 

  computed: {
    ...mapGetters('collision/table', ['selectedCollisionsUuid', 'collisionGroups']),
  },
  
  methods: {
    ...mapActions('collision/table', ['addCollisionToGroup']),

    show({ single, item }) {
      this.single = single
      this.selectedRow = item
      this.visible = true
    },
    
    addToGroup() {
      let list = this.single ? [this.selectedRow.uuid] : this.selectedCollisionsUuid
      this.addCollisionToGroup({groupUuid: this.selectedGroup, collisionList: list})
    },
  }
}
</script>