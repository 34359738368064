<template lang="pug">
div
  app-text-field.px-2( v-model="title" :label="$t('section.collision.rulesFounder.ruleSetting.sample')" test-id="rule-name-input" )
  .d-flex
    .conditions
      .subtitle-1.primary--text.text--darken2.px-2 {{$t("section.classification.clipboard.selectionConditions")}} ({{ hasOptions ? $t("section.collision.rulesFounder.secondElements") : $t("section.collision.rulesFounder.baseElements") }})
      logic-group.mt-1( ref="logicGroupComp" :logic-group.sync="logicGroup" :axisFilter="axisFilter" :operatorFilter="operatorFilter") 

  .text-right.mt-2
    app-button( action @click="onSave" :disabled="!hasCollisionCreateRule" test-id="collision-rule-save") {{ $t('button.save') }}
</template>

<script>
import { mapGetters } from 'vuex'
import LogicGroup from '@/components/project/panel/left/components/logicGroup/LogicGroup.vue'

export default {
  components: { LogicGroup },

  props: {
    value: Object,
    hasOptions: Boolean,
    edit: Boolean,
    finderClass: null
  },

  data: () => ({
    isIntersectionsAllowed: false,
    title: null
  }),
  
  computed: {
    ...mapGetters('projectPermissions', ['hasCollisionCreateRule', 'hasCollisionView']),

    logicGroup () {
      return this.value && this.value.logicGroup || {}
    },

    axisFilter () {
      return this.finderClass == "IFCValidateRule" ? ['MODEL'] : []
    },

    operatorFilter () {
      return this.finderClass == "IFCValidateRule" ? ['EQ'] : []
    }

  },

  watch: {
    value: {
      handler ({ title }) {
        this.title = title
      },
      immediate: true
    }
  },

  methods: {
    onSave () {
      let logicGroup = this.$refs.logicGroupComp.makeLogicGroup()

      if (!logicGroup)
        return

      let options = []
      if (this.value.options) options = this.value.options
      
      let title = this.title || this.value.title || 'Не указано'
      let deleteUuid = null

      if (!this.value.uuid) {
        deleteUuid = this.value.deleteUuid || this.generateUuid()
      }

      let sample = { ...this.value, title, logicGroup, options, deleteUuid }

      this.$emit('input', sample)
      this.$emit('close')
    },

    generateUuid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
}
</script>

<style scoped>
.conditions {
  width: 100%;
}
</style>
