import { render, staticRenderFns } from "./BimAxisGizmoWidget.vue?vue&type=template&id=7a38c2fb&scoped=true&lang=pug&"
import script from "./BimAxisGizmoWidget.vue?vue&type=script&lang=js&"
export * from "./BimAxisGizmoWidget.vue?vue&type=script&lang=js&"
import style0 from "./BimAxisGizmoWidget.vue?vue&type=style&index=0&id=7a38c2fb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a38c2fb",
  null
  
)

export default component.exports