<template lang="pug">
app-dialog( v-model="$_show" v-bind='$attrs' :confirm-disabled="!ruleReady" @confirm="onSavingRule" :confirmText="$t('button.save')" )
  app-section
    app-text-field( v-model="title" :label='$t("section.collision.rulesFounder.ruleName")' test-id="collision-rule-name-input")
    app-select( v-model="folderUuid" :items="preparedFolders" item-text='title' item-value="uuid" :label="$t('section.collision.rulesFounder.ruleLocation')" test-id="collision-rule-group-input" )
    app-select( v-model="type" :items="findersPlusPlugins" item-text="title" return-object test-id="collision-rule-mode" hideDetails)
    //- app-text-field( v-else v-model="folderTitle" label="Расположить правило в группе" )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogToggable from "@/components/app/DialogToggable"

export default {
  mixins: [DialogToggable],

  props: {
    rule: Object,
    folder: Object,
  },

  data: () => ({ 
    uuid: null, 
    title: null, 
    folderUuid: null,
    folderTitle: null,
    type: null,
  }),

  mounted(){
    if(this.rule) {
      this.type = this.rule.finder 
      this.folderUuid = this.getFolder.uuid
    }
    else {
      if(this.findersPlusPlugins.length)
        this.type = this.findersPlusPlugins[0]
    }
  },

  computed: {
    ...mapGetters('rule', ['folderFlatList']),
    ...mapGetters('rule/details', ['finders']),
    ...mapGetters('plugins', ['colisionPlugins']),

    hasFolders () {
      return this.folderFlatList.length > 0
    },

    ruleReady () {
      return !!this.title && !!this.title.trim() && this.folderUuid
    },

    preparedFolders () {
      return this.folderFlatList.map(one => {
        let text = Array(+one.level).join('| - ').concat(one.title)
        return { ...one, text, disabled: false }
      })
    },

    findersPlusPlugins() {
      let arr = this.finders || []
      arr = arr.concat(this.colisionPlugins.map(e => {
        return {uuid:e.uuid, title:e.name, className:"com.pirsbim.collision.rule.PluginClashRule"}
      }))    

      return arr
    },

    getFolder() {
      return this.preparedFolders.find(folder => {
        let rules = folder.rules.find(rule => rule.uuid === this.rule.uuid)
        if (rules)
          return true
      })
    },
  },

  watch: {
    rule: {
      handler (val) { 
        this.title = val && val.title
        this.uuid = val && val.uuid
      }, 
      immediate: true
    },
    folder: {
      handler (val) {
        this.folderUuid = val?.uuid || this.preparedFolders[0]?.uuid

        if (!this.folderUuid) {
          this.folderTitle = this.$t('section.collision.rulesFounder.unsortedRules')
        }

        // let uuid = val && val.uuid
        // this.folderUuid = uuid || this.preparedFolders[0].uuid
      }, 
      immediate: true
    }
  },

  methods: {
    ...mapActions('rule', ['saveRule']),

    onSavingRule () {
      let data = { title: this.title, folder: this.folderUuid, uuid: this.uuid, finder: this.type}
      this.saveRule(data)
    },
  }
}
</script>

