<template lang="pug">
  app-dialog(v-if="dialog" v-model="dialog" width="400" :header='$t("section.maintenance.dialog.recalByDateHeader")' :confirm-text='$t("button.start")' @confirm="addMaintenance()" :confirmDisabled='confirmDisabled')
    app-section
      v-menu(ref='date' :close-on-content-click="false" :return-value.sync='date' transition='scale-transition' min-width='auto' offset-y )
        template( v-slot:activator='{ on }' )
          v-text-field.mr-2( v-model='date' v-on='on' :label='$t("section.maintenance.dialog.recalDate")' prepend-icon='mdi-calendar' readonly light :rules="[rules.required, rules.date]")
        v-date-picker( v-model="date" first-day-of-week=1 no-title scrollable @input="$refs.date.save(date)") 
      app-select(:label='$t("section.maintenance.dialog.selectedRule")' v-model="selectedRuleUuid" :items="maintenanceRules" item-text="title" item-value="uuid" hide-details )  
      v-radio-group(v-model="action" hide-details)   
        .d-flex
          v-radio(:label='$t("section.maintenance.dialog.clearMaintenanceLabel")' light value='clear') 
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-icon.align-self-start( light x-small v-bind="attrs" v-on="on") mdi-help-circle-outline   
            span {{ $t("section.maintenance.dialog.clearMaintenance") }}
       
        .d-flex
          v-radio( :label='$t("section.maintenance.dialog.recalcMaintenanceLabel")' light value='recalc') 
          v-tooltip(top)
              template(v-slot:activator="{ on, attrs }")
                v-icon.align-self-start( light x-small v-bind="attrs" v-on="on") mdi-help-circle-outline   
              span {{ $t("section.maintenance.dialog.recalcMaintenance") }}
        
        .d-flex
          v-radio( :label='$t("section.maintenance.dialog.clearRecalcMaintenanceLabel")' light value='clear-recalc') 
          v-tooltip(top)
              template(v-slot:activator="{ on, attrs }")
                v-icon.align-self-start( light x-small v-bind="attrs" v-on="on") mdi-help-circle-outline   
              span {{ $t("section.maintenance.dialog.clearRecalcMaintenance") }}
</template>

<script>
import { useMaintenanceDialogStore, useMaintenanceScheduleStore } from '@/pinia'

export default {
  data() {
    return {
      date: null,
      maintenanceRules: [],
      selectedRuleUuid: null,
      dialog: false,
      action: 'clear',

      rules: {
        required: (value) => !!value || 'Обязательно к заполнению.',
        date: (value) =>
          new Date(value).getTime() >= new Date(this.store.maintenanceTaskSettings.schedulerStartDate).getTime() ||
          'Дата ТО не должна быть меньше даты начала отчета',
      },
    }
  },

  computed: {
    store: () => useMaintenanceDialogStore(),
    sheduleStore: () => useMaintenanceScheduleStore(),

    confirmDisabled() {
      return (
        !this.date ||
        new Date(this.date).getTime() < new Date(this.store.maintenanceTaskSettings.schedulerStartDate).getTime() ||
        !this.selectedRuleUuid
      )
    },
  },

  methods: {
    addMaintenance() {
      let clear = false
      let recalc = false

      switch (this.action) {
        case 'clear':
          clear = true
          break
        case 'recalc':
          recalc = true
          break
        case 'clear-recalc':
          clear = true
          recalc = true
          break
      }

      const params = {
        rule: this.selectedRuleUuid,
        startdate: this.date,
        delete: clear,
        recalc: recalc,
      }
      this.store.recalcTaskPlanWithParam(this.store.selectedEquipment.uuid, params)
      this.dialog = false
    },

    showDialog() {
      this.maintenanceRules = []
      this.selectedRuleUuid = null
      this.action = 'clear'
      this.date = new Date().toLocaleDateString('ru').split('.').reverse().join('-')
      this.store.loadEquipmentRules(this.store.specificationUuid).then((rules) => (this.maintenanceRules = rules))
      this.dialog = true
    },
  },
}
</script>
