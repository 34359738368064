<template lang="pug">
  div
    version-tree-item(v-for="item in items" :key="item.uuid" :item="item" :compare-to="compareTo")
</template>

<script>
import VersionTreeItem from './VersionTreeItem'
export default {
  name:'version-tree',
  
  components: {
    VersionTreeItem
  },

  props: {
    items:{
      type: Array,
      default: () => []
    },
    compareTo: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      
    }
  },

  mounted() {
    
  },

  computed: {
    
  },

  watch: {
    
  },

  methods: {
    
  }
}
</script>