<template lang="pug">
.wrapper
  v-tooltip( bottom open-delay=300 )
    template( v-slot:activator="{ on, attrs }" )
      .button.mx-2.pa-2( :class="[ active ? 'active': '' ]" v-on="on" )
        //- v-img( v-if="icon" :src="path" dark)
        v-icon( v-if="icon" :size="size" v-bind="$attrs" v-on="$listeners" color="var(--v-primary-darken2)") {{ icon }}
        span.text( v-else ) {{ text }}
        slot
    span {{ hint }}
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
  name: 'ToolLayerUnit',
  props: {
    icon: String,
    text: {
      type: String,
      // default: 'BIMit'
    },
    active: Boolean,
    hint: String,
    size: {
      type: [Number, String],
      default: 32
    },
  },
  computed: {
    ...mapGetters('theme', ['themeName']),
  },

  methods: {
    ...mapActions('project', ['setProjectPreview']),
  }
}
</script>

<style lang="scss" scoped>
.button {
  width: 48px;
  height: 48px;
  background-color: var(--v-surface-base);
  cursor: pointer;
  margin-bottom: 2px;
  &:hover {
    background-color: var(--v-surface-darken3);
    opacity: 0.7;
    // border: 1px solid rgb(117, 117, 117, .9);
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.text {
  font: normal 20px/32px $roboto;
  color: #959595;
  //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  left: 3px;
}

.base {
  fill: #959595;
}

.active {
  background-color: var(--v-surface-darken3);
  // opacity: 0.4;
  // border: 1px solid #C4C4C4;
}

// .app-theme--grey:hover {
//   -webkit-filter:  brightness(70%);
//   filter:  brightness(40%);
// }
</style>