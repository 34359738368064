<template lang='pug'>
  div(v-if='value' class='loading-modal')
    div.base.elevation-5.rounded.modal-content
      strong.center-container
        span.mr-3 {{$t('importStructure.loadingProgress.modelsUploaded')}}:
        span.mr-3 {{ numberLoadedNodes }} / {{countNodes}}
        v-progress-circular(color='accent' indeterminate size='16' width='2')
</template>
  
  <script>

  
  export default {
    name: "LoadingProgressBatchUpload",
    data: () => ({}),

    props: {
      value: {
        default: false,
        type: Boolean
      },
      numberLoadedNodes: Number,
      countNodes: Number
    },
  
  }
  </script>
  

<style scoped>
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1); 
  z-index: 1;
  border-radius: 8px;
  
}

.modal-content {
  width: 350px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.center-container {
  color: var(--v-surface-base);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>