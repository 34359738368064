import { defineStore } from 'pinia'
import { isModeOrthogonal, setPerspectiveMode, setOrthogonalMode } from '@/facades/scene.facade'

export const useSceneProjectionState = defineStore('scene-projection-state', {
  getters: {
    isModeOrthogonal,
  },

  actions: {
    toggleProjection () {
      this.isModeOrthogonal ? setPerspectiveMode() : setOrthogonalMode()
    },
  }
})