<template lang="pug">
  div.fix-panel-container( ref="floatingPanelContainer" :style="style" )
    floating-panel-header( :title="title" :height="headerHeight" 
            :closeable="false"
            :backgroundColor="headerColor" 
            :openedPanel="true" 
            :menu="menu"
            :titleColor="headerTitleColor" 
            :titleFont="headerTitleFont"
            :borderSize="headerBorderSize" 
            :borderColor="headerBorderColor"
            :borderRadius="headerBorderRadius"
            test-id="fix-panel-header")
      template( v-slot:headerButton )
        slot(name="headerButton")

    floating-panel-body( :resizable="false" :overflow="overflowBody" :style="'height: calc(100% + 6px - ' + headerHeight + 'px) !important;'"
      :minHeight="bodyMinHeight" :backgroundColor="bodyColor" :headerBorderSize="headerBorderSize"
      :startingHeight="bodyStartingHeight" test-id="fix-panel-body")
      slot
</template>

<script>
import FloatingPanelHeader from "@/components/project/common/floatingPanels/FloatingPanelHeader.vue"
import FloatingPanelBody from "@/components/project/common/floatingPanels/FloatingPanelBody.vue"

export default {
  name: "FixPanel",

  components: {
    FloatingPanelHeader,
    FloatingPanelBody
  },

  props: {
    menu: Array,

    title: {
      type: String,
      default: ""
    },

    minHeight: {
      type: [String, Number],
      default: 100
    },

    headerHeight: {
      type: [String, Number],
      default: 35
    },

    headerColor: {
      type: String,
      default: "var(--v-surface-lighten1)" 
    },

    headerTitleColor: {
      type: String,
      default: "var(--v-primary-darken2)"
    },

    headerTitleFont: {
      type: String,
      default: "14px 'Roboto' bold"
    },
    
    headerBorderSize: {
      type: [String, Number],
      default: 1
    },

    headerBorderColor: {
      type: [String, Number],
      default: "#C4C4C4" 
    },

    headerBorderRadius: {
      type: [String, Number],
      default: "3"
    },

    headerDropDownIcon: {
      type: [String, Number],
      default: ""
    },

    bodyMinHeight: {
      type: [String, Number],
      default: 25
    },

    bodyColor: {
      type: [String],
      default: "white",
    },

    marginBottom: {
      type: [String, Number],
      default: 3,
    },

    overflowBody: {
      type: String,
      default: "auto"
    },

    bodyStartingHeight: {
      type: [Number],
    },

    startHeight: {
      type: [Number, String],
      default: 25
    },

  },
  
  computed: {
    style() {
      return {
        'margin-bottom': this.marginBottom + "px",
      }  
    },

  },
}
</script>