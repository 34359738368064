<template lang="pug">
div
  v-data-table(
    :light="light" 
    :dark="dark"
    :headers="headers" 
    :items="items" 
    :items-per-page="itemsPerPage" 
    hide-default-footer
    :page.sync="paginationItems.page" 
    @page-count="paginationItems.pageCount = $event"
  )
  div(class="text-center pt-2")
    v-pagination(
      v-model="paginationItems.page" 
      :length="paginationItems.pageCount"
    )
</template>

<script>
export default {
  name: 'wj-dt-pagination',

  data() {
    return {
      paginationItems: {
        page: 1,
        pageCount: 0,
      }
    }
  },

  props: {
    light: {
      default: true,
      type: Boolean
    },
    dark: {
      default: false,
      type: Boolean
    },
    headers: null,
    items: null,
    itemsPerPage: {
      default: 5,
      type: Number
    },
  },
}
</script>

<style>

</style>