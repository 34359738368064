<template lang="pug">
task-section-expansion( :title="`${$t('module.task.comments')} (${allComments})`"
:showToggle="getCommentPanel" headerColorDark noBorder test-id="task-details-section-body" @openPanel="openPanel" )
  div
    div( style="height:4px" )
    task-comment-card( v-for="(comment, index) in actionsList" :key="comment.uuid" :id="comment.uuid" :class='{"mb-1": index < actionsList.length - 1}' :comment="comment" @quotedComment="quotedComment" )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TaskCommentCard from "@/components/task/taskDetailsSections/taskComments/TaskCommentCard.vue";
import TaskSectionExpansion from '@/components/task/taskDetailsSections/TaskSectionExpansion.vue';

export default {
  name: "TaskCommentsSection",

  props: {
    selectedTask: null,
  },

  components: {
    TaskCommentCard,
    TaskSectionExpansion
  },
  computed: {
    ...mapGetters("taskDetails", ["getCommentPanel"]),

    allComments() {
      let attachments = this.attachmentsParseCam || [];
      let commentsLength = 0;
      if (this.selectedTask.comment) {
        commentsLength += this.selectedTask.comment.length;
      }
      commentsLength += attachments.length;
      return commentsLength;
    },

    hasAttachments() {
      return (
        this.selectedTask.attachments && this.selectedTask.attachments.length
      );
    },

    attachmentsParseCam() {
      return this.hasAttachments
        ? this.selectedTask.attachments
            .map((item) => {
              if (item.sourceData) item.cameraParse = item.sourceData;
              return item;
            })
            .sort((a, b) => a.createDate.localeCompare(b.createDate))
        : [];
    },

    actionsList() {
      let comments = this.selectedTask.comment || [];
      let attachments = this.attachmentsParseCam || [];
      let list = [...attachments, ...comments];
      list.sort((a, b) => a.createDate.localeCompare(b.createDate));
      list.forEach((comment) => {
        if (comment.quotedCommentUuid) {
          comment.quotedComment = list.find(
            (quoted) => quoted.uuid === comment.quotedCommentUuid
          );
        }
      });
      return list;
    },
  },

  methods: {
    ...mapActions("taskDetails", ["setCommentPanel"]),

    openPanel(show) {
      this.setCommentPanel(show);
    },

    quotedComment(comment) {
      this.$emit("quotedComment", comment);
    },
  },
};
</script>

<style scoped>

</style>