<template lang="pug">
div
  HintMenu(ref="hintmenu")
  FunctionMenu(ref="menu" :menu="suggests" @menu:select="insertValue")
  //- vue-editor(
  //-   v-model="text",
  //-   :customModules="customModules",
  //-   :editorOptions="editorSettings",
  //-   :editorToolbar="customToolbar",
  //-   @ready="(el)=>this.quill=el",
  //- )
  //- div(style="color:black") {{text}}
  editor.editor(v-model="text" v-resize="onResize" ref="editor" api-key="1532a96p3vkvt21t82hdv26pkyyv4m4cjha622l7i15zvf6m" :init="editorSettings")
</template>

<script>
  import Editor from '@tinymce/tinymce-vue'
  import { mapState } from 'vuex'
  import FunctionMenu from '@/components/project/panel/left/templates/FunctionMenu'
  import HintMenu from '@/components/project/panel/left/templates/HintMenu'
  export default {
    name: 'TemplateEditor',

    components: {
      Editor,
      FunctionMenu,
      HintMenu,
    },

    data() {
      return {
        showHint: false,
        table: null,
        text: null,
        canSave: true,
        quill: null,
        range: { index: 0, length: 0 },
        editorSettings: {
          language: 'ru',
          plugins:
            'print preview paste importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link table charmap pagebreak nonbreaking toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars',
          imagetools_cors_hosts: ['picsum.photos'],
          menubar: 'file edit view insert format tools table help',
          menu: {
            file: { title: 'Файл', items: 'saveDocMenu preview print' },
          },
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 42pt 48pt',
          pagebreak_separator: '<p style="page-break-after: always;"><!-- pagebreak --></p>',
          resize: false,
          toolbar:
            'hintMenu funcMenu | saveDocBar undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen  preview print | insertfile image media link | ltr rtl',
          setup: (editor) => {
            let saveItemText = this.$t('app.markdownTextarea.saveTemplate')

            editor.on('init', () => {
              this.onResize()
            })

            editor.ui.registry.addButton('saveDocBar', {
              icon: 'save',
              tooltip: saveItemText,
              onAction: () => this.saveContent(),
            })

            editor.addShortcut('meta+s', saveItemText, () => this.saveContent(), this)

            editor.addShortcut('alt+x', 'Тест', () => this.showMenu())

            editor.ui.registry.addMenuItem('saveDocMenu', {
              text: saveItemText,
              icon: 'save',
              onAction: () => this.saveContent(),
            })

            editor.ui.registry.addButton('hintMenu', {
              tooltip: this.$t('app.markdownTextarea.help'),
              icon: 'help',
              onAction: () => this.$refs.hintmenu.show(),
            })

            editor.ui.registry.addButton('funcMenu', {
              tooltip: this.$t('app.markdownTextarea.help'),
              icon: 'comment-add',
              onAction: () => this.showMenu(),
            })
          },
        },
      }
    },
    mounted() {
      this.onResize()
      this.updateContent()
      // console.log(
      //   this.$refs.editor.editor.on('click', function () {
      //     console.log('The Editor has initialized.')
      //   })
      // )
      // this.bindEvents()
      // this.addButtons()
      // this.table = this.quill.getModule('better-table')
      // this.registerComponents()
      // let a = document.querySelector(".ql-picker-options").childNodes
      //     for (let ch of a)
      //       ch.style.content="sds"
    },
    computed: {
      ...mapState('template', ['content', 'suggests']),
    },
    methods: {
      saveContent() {
        this.$emit('clickSave')
      },
      updateContent() {
        this.text = this.content
      },
      getContent() {
        return this.text
      },
      onResize() {
        this.$nextTick(() => {
          let height = document.getElementsByClassName('section')?.item(0).offsetHeight
          let editor = document.getElementsByClassName('tox')?.item(0)
          if (editor && height) {
            editor.style['height'] = height - 20 + 'px'
          }
        })
      },
      showMenu() {
        let range = this.$refs.editor.editor.selection.getSel().getRangeAt(0).getBoundingClientRect()
        this.$refs.menu.show({
          clientX: range.x + 100,
          clientY: range.y + 200,
          preventDefault: () => {},
        })
      },
      insertValue(el) {
        // this.quill.insertText(this.range.index, el.value)
        this.$refs.editor.editor.execCommand('mceInsertContent', false, el.value)
      },
      //   registerComponents() {
      //     let Parchment = Quill.import('parchment')
      //     let lineHeight = new Parchment.StyleAttributor('line-height', 'line-height', {
      //       scope: Parchment.Scope.BLOCK,
      //     })
      //     Quill.register(lineHeight, true)
      //     let fontSize = new Parchment.StyleAttributor('font-size', 'font-size', {
      //       scope: Parchment.Scope.INLINE,
      //     })
      //     Quill.register(fontSize, true)
      //   },
      //   addButtons() {
      //     Items.hint.onClick = () => {
      //       this.$refs.hintmenu.show()
      //     }
      //     Items.table.onClick = () => {
      //       this.click()
      //     }
      //     Items.button.onClick = () => {
      //       this.showMenu()
      //     }
      //     Items.hide.onClick = () => {
      //       this.hideToolbar()
      //     }
      //     Items.lineHeight.onSelect = function (label, value) {
      //       this.quill.format('line-height', value)
      //     }
      //     Items.fontSize.onSelect = function (label, value) {
      //       this.quill.format('font-size', value + 'pt')
      //       Items.fontSize.setValue(value)
      //     }
      //     Items.fontSize.onChange = function (value) {
      //       if (value > 100) value = 100
      //       else if (value < 1) value = 1
      //       Items.fontSize.setValue(value)
      //       this.quill.format('font-size', value + 'pt')
      //     }
      //     Items.fontSize.attach(this.quill)
      //     Items.lineHeight.attach(this.quill)
      //     Items.table.attach(this.quill)
      //     Items.button.attach(this.quill)
      //     Items.hint.attach(this.quill)
      //     Items.hide.attach(this.quill)
      //   },

      //   bindEvents() {
      //     document.querySelector('.ql-editor').addEventListener('keydown', this.save)
      //     this.quill.on('selection-change', (range) => {
      //       if (range) {
      //         this.range = range
      //         let size = this.quill.getFormat(range)['font-size']
      //         if (size) Items.fontSize.setValue(/\d+/.exec(size)[0])
      //       }
      //     })
      //     this.quill.keyboard.addBinding(
      //       {
      //         key: ' ',
      //         shortKey: true,
      //       },
      //       (range) => {
      //         let coord = this.quill.getBounds(range.index)
      //         this.$refs.menu.show({
      //           clientX: coord.left + 90,
      //           clientY: coord.top + 200,
      //           preventDefault: () => {},
      //         })
      //       }
      //     )
      //   },
      //   hideToolbar() {
      //     let el = document.querySelectorAll('.ql-formats')
      //     for (let i = 7; i < el.length - 1; i++) {
      //       if (i == 8) continue
      //       el[i].classList.toggle('hidden')
      //     }
      //     el[el.length - 1].classList.toggle('rotate')
      //   },
      //   save(event) {
      //     if (event.code == 'KeyS' && (event.ctrlKey || event.metaKey)) {
      //       event.preventDefault()
      //       if (!event.repeat && this.canSave) {
      //         this.canSave = false
      //         setTimeout(() => (this.canSave = true), 1000)
      //         this.$emit('clickSave')
      //       }
      //     }
      //   },
      //   click() {
      //     this.table.insertTable(3, 3)
      //   },
    },
  }
</script>
<style scoped>
  /* .editor{
  height: 100vh;
}
.tox {
    height: 100% !important;
  } */
</style>
<style>
  /* .ql-formats:last-child {
    float: right;
    padding-right: 10px;
  }
  .quillWrapper {
    max-width: 21cm;
  }
  .hidden {
    display: none !important;
  }
  .rotate {
    transform: rotate(180deg);
    margin: 4px 20px 0px 0px !important;
  }
  .ql-editor {
    padding: 20mm 20mm 20mm 30mm !important;
    color: black;
    margin: 0 !important;
    font-family: Arial;
  }
  .ql-toolbar {
    position: relative;
    top: 0;
    background-color: #fff;
  }
  .ql-editor {
    padding: 10px 2cm 1cm 2cm !important;
    margin: 0 !important;
    min-height: 100%;
    font-size: 12pt;
    line-height: 1.25 !important;
  }
  .ql-container {
    height: calc(100vh - 200px) !important;
  }
  .panel--huge {
    overflow: hidden !important;
  }

  .ql-picker-options-custom {
    display: none;
    margin-top: -12px;
    margin-left: -10px;
    top: 100%;
    z-index: 100;
    background-color: #fff;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
    border: 1px solid #ccc;
    box-shadow: rgb(0 0 0 / 20%) 0 2px 8px;
  }
  .ql-picker-options-custom .ql-picker-item {
    cursor: pointer;
    display: block;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #444;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .show {
    display: block;
  }
  .ql-input-number {
    width: 50px;
    color: #444;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    transform: translateY(2px);
  }
  .ql-input-number:focus {
    border: 1px solid #ccc;
    outline: 0;
    border-radius: 0px;
  }
  .ql-input-number ~ .ql-picker-options-custom {
    margin-left: 0px;
    margin-top: -13px;
  }
  .ql-header {
    width: 110px !important;
  }
  #svg8 {
    margin-left: -5px;
    stroke: rgba(63, 63, 63, 0.95);
  }
  #svg8:hover {
    stroke: #06c;
  }
  #path4506 {
    stroke: rgba(63, 63, 63, 0.95);
  }
  #svg8:hover #path4506 {
    stroke: #06c;
  }
  #rect4508 {
    fill: rgba(63, 63, 63, 0.95);
  }

  #svg8:hover #rect4508 {
    fill: #06c;
  } */
</style>
